import { useLocale } from '@hooks/useLocale';
import { EntityType } from '@lightdash/common';
import { Button, Flex, Menu } from '@mantine/core';
import { CaretDown, Check } from '@phosphor-icons/react';
import React from 'react';
import { ButtonVariant } from '../../mantineTheme';

type AuditLogsResourceTypeProps = {
    onResourceTypeChange: (newResourceType: EntityType | null) => void;
    resourceType: EntityType | null;
};

const AuditLogsResourceType = ({
    onResourceTypeChange,
    resourceType,
}: AuditLogsResourceTypeProps) => {
    const { t } = useLocale();
    return (
        <Menu
            shadow="md"
            width={150}
            classNames={{ dropdown: 'h-[200px] overflow-y-auto' }}
        >
            <Menu.Target>
                <Button
                    variant={ButtonVariant.OUTLINED}
                    rightIcon={<CaretDown weight="duotone" />}
                >
                    {resourceType
                        ? resourceType
                        : t('audit_logs.resource_type')}
                </Button>
            </Menu.Target>

            <Menu.Dropdown>
                {Object.values(EntityType).map((entityType) => (
                    <Menu.Item
                        key={entityType}
                        onClick={() => {
                            if (resourceType === entityType) {
                                onResourceTypeChange(null);
                                return;
                            }
                            onResourceTypeChange(entityType);
                        }}
                    >
                        <Flex
                            className="w-full text-gray-800"
                            justify="space-between"
                        >
                            {entityType}
                            {resourceType === entityType && (
                                <Check weight="bold" />
                            )}
                        </Flex>
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
};

export default AuditLogsResourceType;
