import { subject } from '@casl/ability';
import { Can } from '@components/common/Authorization';
import ErrorState from '@components/common/ErrorState';
import PageSpinner from '@components/PageSpinner';
import { useCommunicationChannels } from '@hooks/useCommunicationChannels';
import { useProject } from '@hooks/useProject';
import { useProjects } from '@hooks/useProjects';
import useApp from '@providers/App/useApp';
import ProjectProvider from '@providers/Project/ProjectProvider';
import { type ComponentProps, type FC } from 'react';
import { Navigate, type Route, useLocation, useParams } from 'react-router';
import { RelationProvider } from '../../providers/Relation/RelationProvider';

const AuthorizedProjectRoute: FC<
    React.PropsWithChildren<ComponentProps<typeof Route>>
> = ({ children }) => {
    const { projectUuid } = useParams<{ projectUuid?: string }>();
    const location = useLocation();
    const { isLoading, data: projectData } = useProject(projectUuid);
    const {
        isInitialLoading: communicationChannelsLoading,
        data: communicationChannels,
    } = useCommunicationChannels(
        projectData && projectData.needsRelation ? false : true,
    );

    if (isLoading || communicationChannelsLoading) {
        return <PageSpinner />;
    }

    if (!projectData) {
        return <Navigate to="/no-access" />;
    }

    const isRootProjectPath = location.pathname === `/projects/${projectUuid}`;
    if (isRootProjectPath) {
        return <Navigate to={`/projects/${projectUuid}/relations`} />;
    }

    return (
        <ProjectProvider
            projectData={projectData}
            communicationChannels={communicationChannels}
        >
            <RelationProvider>{children}</RelationProvider>
        </ProjectProvider>
    );
};

const ProjectRoute: FC<
    React.PropsWithChildren<ComponentProps<typeof Route>>
> = ({ children }) => {
    const { user } = useApp();
    const { data: projects, isInitialLoading, isError, error } = useProjects();
    const { projectUuid } = useParams();

    if (isInitialLoading) {
        return <PageSpinner />;
    }

    if (isError && error) {
        return <ErrorState error={error.error} />;
    }

    if (!projects || projects.length <= 0) {
        return <Navigate to="/no-access" />;
    }

    return (
        <Can
            I="view"
            this={subject('Project', {
                organizationUuid: user.data?.organizationUuid,
                projectUuid: projectUuid,
            })}
            passThrough
        >
            {(isAllowed) => {
                return isAllowed ? (
                    <AuthorizedProjectRoute>{children}</AuthorizedProjectRoute>
                ) : (
                    <Navigate to="/no-project-access" />
                );
            }}
        </Can>
    );
};

export default ProjectRoute;
