import { NavLink, type NavLinkProps } from '@mantine/core';
import { type FC } from 'react';
import {
    NavLink as ReactRouterNavLink,
    useLocation,
    type NavLinkProps as ReactRouterNavLinkProps,
} from 'react-router';

type RouterNavLinkProps = Omit<NavLinkProps, 'component' | 'active'> & {
    exact?: boolean;
} & Omit<ReactRouterNavLinkProps, 'component'> &
    Partial<{
        activeclass: string;
        inactiveclass: string;
    }>;

const RouterNavLink: FC<RouterNavLinkProps> = (props) => {
    const path = props.to.toString();
    const location = useLocation();
    const isActive = location.pathname.includes(path);
    const {
        activeclass = 'active-nav-link',
        inactiveclass = 'inactive-nav-link',
    } = props;

    return (
        <NavLink
            className={`${
                isActive ? activeclass : inactiveclass
            } rounded-lg hover:rounded-lg`}
            {...props}
            component={ReactRouterNavLink}
            active={isActive}
        />
    );
};

export default RouterNavLink;
