import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { type AdditionalMetric } from '@lightdash/common';
import useCustomMetricContext from '@providers/CustomMetric/useCustomMetricContext';
import { useEffect } from 'react';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import { type CreateCustomMetricProps } from '../utils';

const useCreateCustomMetric = ({
    openBaseTable,
    tableName,
    customMetricData,
    isDuplicate,
    isViewMode,
    onModalClose,
    customMetricId,
    disableTableChange,
}: CreateCustomMetricProps) => {
    const { actions } = useCustomMetricContext((context) => context);
    const { activeRelation } = useRelationContext();
    const {
        openCustomMetricBaseTableModal,
        openCustomMetricManagerModal,
        selectTable,
        addBaseTableDetails,
        selectDimension,
        toogleIsDuplicated,
        toggleIsViewMode,
        setCustomMetricData,
        toggleIsBackButtonDisabled,
    } = actions;
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });

    useEffect(() => {
        if (openBaseTable && !isDuplicate) {
            openCustomMetricBaseTableModal();
            onModalClose?.();
        }
    }, [
        openBaseTable,
        openCustomMetricBaseTableModal,
        isDuplicate,
        onModalClose,
    ]);

    useEffect(() => {
        if (tableName) {
            selectTable(tableName);
            addBaseTableDetails(tableName);
            openCustomMetricManagerModal();
            onModalClose?.();
        }
    }, [
        tableName,
        selectTable,
        addBaseTableDetails,
        openCustomMetricManagerModal,
        onModalClose,
        toggleIsBackButtonDisabled,
    ]);
    useEffect(() => {
        if (disableTableChange) {
            toggleIsBackButtonDisabled(true);
        }
    }, [disableTableChange, toggleIsBackButtonDisabled]);
    useEffect(() => {
        if (
            isDuplicate &&
            customMetricId &&
            customMetricData &&
            typeof customMetricData === 'object' &&
            !Array.isArray(customMetricData) &&
            customMetricId === customMetricData.uuid &&
            activeRelation
        ) {
            const metricSelectedTableName = (
                customMetricData.definition as AdditionalMetric
            )?.table;
            const metricSelectedDimension = (
                customMetricData.definition as AdditionalMetric
            )?.baseDimensionName;
            selectDimension(
                fieldsWithSuggestions[
                    `${metricSelectedTableName}_${metricSelectedDimension?.replaceAll(
                        '.',
                        '__',
                    )}`
                ],
            );
            setCustomMetricData(customMetricData);
            selectTable(activeRelation.tables[customMetricData.srcTable]);
            toogleIsDuplicated(true);
            openCustomMetricManagerModal();
            onModalClose?.();
        }
    }, [
        activeRelation,
        selectDimension,
        selectTable,
        fieldsWithSuggestions,
        toogleIsDuplicated,
        openCustomMetricManagerModal,
        isDuplicate,
        customMetricData,
        setCustomMetricData,
        onModalClose,
        customMetricId,
    ]);
    useEffect(() => {
        if (
            isViewMode &&
            customMetricData &&
            typeof customMetricData === 'object' &&
            !Array.isArray(customMetricData) &&
            customMetricId === customMetricData.uuid &&
            activeRelation &&
            Object.keys(fieldsWithSuggestions).length > 0
        ) {
            selectTable(activeRelation?.tables[customMetricData.srcTable]);
            selectDimension(
                fieldsWithSuggestions[
                    `${
                        (customMetricData.definition as AdditionalMetric)?.table
                    }_${(
                        customMetricData.definition as AdditionalMetric
                    )?.baseDimensionName?.replaceAll('.', '__')}`
                ],
            );
            setCustomMetricData(customMetricData);
            openCustomMetricManagerModal();
            toggleIsViewMode(true);
            toggleIsBackButtonDisabled(true);
            onModalClose?.();
        }
    }, [
        activeRelation,
        customMetricData,
        selectDimension,
        selectTable,
        openCustomMetricManagerModal,
        fieldsWithSuggestions,
        toggleIsViewMode,
        isViewMode,
        setCustomMetricData,
        customMetricId,
        toggleIsBackButtonDisabled,
        onModalClose,
    ]);

    return null;
};

export default useCreateCustomMetric;
