import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Modal from '@components/common/modal/Modal';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import {
    useDuplicateTemplate,
    useUpdateTemplateDetails,
} from '@hooks/useTemplate';
import { CommonReservedTags, type ReducedTemplate } from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    Archive,
    ArrowCounterClockwise,
    CaretRight,
    Copy,
    Pencil,
} from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import { type Row } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';

import TextInput from '@components/common/Inputs/TextInput';
import ModalFooter from '@components/common/modal/ModalFooter';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';

interface TemplateMenuItemProps {
    rowData: Row<ReducedTemplate>;
}

export default function TemplateMenuItem({ rowData }: TemplateMenuItemProps) {
    const { t } = useLocale();
    const [
        openedHideConfirmModal,
        { open: openHideConfirmModal, close: closeHideConfirmModal },
    ] = useDisclosure();
    const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
    const [renameValue, setRenameValue] = useState<string>('');
    const { mutateAsync: updateTemplateDetails, isLoading } =
        useUpdateTemplateDetails();
    const query = useQueryClient();
    const { showToastSuccess, showToastError } = useNotify();
    const { mutateAsync: duplicateTemplate } = useDuplicateTemplate();

    const handleDuplicate = useCallback(async () => {
        await duplicateTemplate({
            templateId: rowData.original.id,
        });
        await query.invalidateQueries([QueryKeys.GET_TEMPLATE]);
    }, [duplicateTemplate, query, rowData.original.id]);

    const hideItem = useMemo(
        () => ({
            leftSection: (
                <Archive
                    weight="duotone"
                    color={'rgb(var(--color-halt-800))'}
                />
            ),
            children: <Text className="text-halt-800">{t('common.hide')}</Text>,
            onClick: () => openHideConfirmModal(),
        }),
        [t, openHideConfirmModal],
    );
    const restoreItem = useMemo(
        () => ({
            leftSection: <ArrowCounterClockwise weight="duotone" />,
            children: t('common.restore'),
            onClick: async () => {
                await updateTemplateDetails(
                    {
                        templateId: rowData.original.id,
                        body: {
                            tags: [
                                ...(rowData.original.tags ?? []).filter(
                                    (tag) => tag !== CommonReservedTags.HIDDEN,
                                ),
                            ],
                        },
                    },
                    {
                        onSuccess: async () => {
                            showToastSuccess({
                                title: t('common.hidden_restore_success', {
                                    entityName: t('common.template'),
                                }),
                            });
                            await query.invalidateQueries([
                                QueryKeys.GET_TEMPLATE,
                            ]);
                        },
                        onError: (error) => {
                            showToastError({
                                title: t('common.hidden_restore_error', {
                                    entityName: t('common.template'),
                                }),
                                subtitle: error.error.message,
                            });
                        },
                    },
                );
            },
        }),
        [
            t,
            updateTemplateDetails,
            rowData.original.tags,
            showToastError,
            showToastSuccess,
            query,
            rowData.original.id,
        ],
    );

    const handleRename = async () => {
        await updateTemplateDetails(
            {
                templateId: rowData.original.id,
                body: {
                    name: renameValue,
                },
            },
            {
                onSuccess: async () => {
                    await query.invalidateQueries([QueryKeys.GET_TEMPLATE]);
                },
            },
        );
    };

    const duplicateItem = useMemo(
        () => ({
            leftSection: (
                <Copy weight="duotone" color={'rgb(var(--color-gray-600))'} />
            ),
            children: (
                <Text className="text-gray-600">{t('common.duplicate')}</Text>
            ),
            onClick: handleDuplicate,
        }),
        [handleDuplicate, t],
    );

    const renameItem = useMemo(
        () => ({
            leftSection: (
                <Pencil weight="duotone" color={'rgb(var(--color-gray-600))'} />
            ),
            children: (
                <Text className="text-gray-600">{t('common.rename')}</Text>
            ),
            onClick: () => setShowRenameModal(true),
        }),
        [t, setShowRenameModal],
    );

    const menuItems = useMemo(() => {
        const items: CustomMenuItemProps<ReducedTemplate>[] = [
            (rowData.original.tags ?? []).includes(CommonReservedTags.HIDDEN)
                ? restoreItem
                : hideItem,
        ];
        return [...items, duplicateItem, renameItem];
    }, [
        duplicateItem,
        hideItem,
        restoreItem,
        renameItem,
        rowData.original.tags,
    ]);

    return (
        <>
            <CustomMenu<ReducedTemplate>
                menuItems={menuItems}
                data={rowData.original}
            />
            <Modal
                opened={showRenameModal}
                onClose={() => setShowRenameModal(false)}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                title={
                    <Box>
                        <Text>{t('common.rename')}</Text>
                        <Text className="text-sm font-normal text-gray-600 max-w-[300px] truncate">
                            {rowData.original.name}
                        </Text>
                    </Box>
                }
                footerRightSection={
                    <ModalFooter
                        primaryButtonClick={handleRename}
                        primaryText={t('common.save')}
                        secondaryButtonClick={() => setShowRenameModal(false)}
                        secondaryText={t('common.cancel')}
                        primaryButtonDisabled={!renameValue.trim()}
                        showPrimaryButton
                        showSecondaryButton
                        primaryLeftIcon={undefined}
                        primaryRightIcon={undefined}
                        secondaryLeftIcon={undefined}
                        secondaryRightIcon={undefined}
                        primaryButtonVariant={ButtonVariant.FILLED}
                        secondaryButtonVariant={ButtonVariant.OUTLINED}
                        isLoading={isLoading}
                        primaryButtonCustomClass={undefined}
                    />
                }
            >
                <Box>
                    <TextInput
                        placeholder={rowData.original.name}
                        value={renameValue}
                        onChange={(e) => setRenameValue(e.target.value)}
                    />
                </Box>
            </Modal>
            <Modal
                opened={openedHideConfirmModal}
                onClose={closeHideConfirmModal}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                title={
                    <Box>
                        <Text>{t('template_manager.hide_modal_title')}</Text>
                        <Text className="text-sm font-normal text-gray-600">
                            {rowData.original.name}
                        </Text>
                    </Box>
                }
                footerRightSection={
                    <ButtonGroup
                        primaryButtonLabel={t(
                            'audience_manager.confirm_hide_prim_button',
                        )}
                        secondaryButtonLabel={t('common.no')}
                        isUpdating={isLoading}
                        handlePrimaryButtonClick={async () => {
                            await updateTemplateDetails(
                                {
                                    templateId: rowData.original.id,
                                    body: {
                                        tags: [
                                            ...(rowData.original.tags ?? []),
                                            CommonReservedTags.HIDDEN,
                                        ],
                                    },
                                },
                                {
                                    onSuccess: async () => {
                                        showToastSuccess({
                                            title: t('common.hidden_success', {
                                                entityName:
                                                    t('common.template'),
                                            }),
                                        });
                                        await query.invalidateQueries([
                                            QueryKeys.GET_TEMPLATE,
                                        ]);
                                    },
                                    onError: (error) => {
                                        showToastError({
                                            title: t('common.hidden_error', {
                                                entityName:
                                                    t('common.template'),
                                            }),
                                            subtitle: error.error.message,
                                        });
                                    },
                                },
                            );
                            closeHideConfirmModal();
                        }}
                        handleSecondaryButtonClick={closeHideConfirmModal}
                        primaryButtonVariant={ButtonVariant.FILLED_DESTRUCTIVE}
                        primaryButtonRightIcon={<CaretRight color="white" />}
                    />
                }
                closeButtonProps={{
                    className: 'absolute top-3 right-3',
                }}
            >
                <Flex direction={'column'} gap={12}>
                    <Text className="text-sm font-medium text-gray-800">
                        {t('template_manager.hide_modal_header')}
                    </Text>
                </Flex>
            </Modal>
        </>
    );
}
