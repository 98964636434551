import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import { buttonInputStyles } from '@components/common/Inputs/ButtonInput/ButtonInput.styles';
import TextInputVariableWidth from '@components/common/Inputs/TextInputVariableWidth';
import Timestamp from '@components/common/Timestamp';
import { useUnpublishCampaign, useUpdateCampaign } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    CampaignScheduleType,
    CampaignStatus,
    CampaignType,
    // type AudienceType,
    type Campaign,
    type CampaignUpdateRequest,
    type CommunicationChannel,
} from '@lightdash/common';
import { Box, Button, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CheckCircle, ClockCountdown } from '@phosphor-icons/react';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDebounce } from 'react-use';
import { ButtonVariant, FontSizes, FontWeights } from '../../../mantineTheme';
import ViewRunsModal from './ViewRunsModal';

interface PreviewSubtitleProps {
    campaignData: Campaign;
    isEditable: boolean;
}

const PreviewSubtitle: React.FC<PreviewSubtitleProps> = ({
    campaignData,
    isEditable,
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [showViewModal, { open: viewModalOpen, close: viewModalClose }] =
        useDisclosure(false);
    const { t } = useLocale();
    const { mutateAsync: updateCampaign } = useUpdateCampaign(
        campaignData?.id ?? '',
        false,
    );
    const [description, setDescription] = useState<string>(
        campaignData.description ?? '',
    );
    const { mutateAsync: unpublishCampaign, isLoading } =
        useUnpublishCampaign();
    const navigate = useNavigate();
    const handleCampaignDescriptionChange = useCallback(
        async (descriptionValue: string) => {
            if (!campaignData.id) return;

            const payload: CampaignUpdateRequest = {
                description: descriptionValue,
                // audienceType: campaignData?.audienceType as AudienceType,
            };

            await updateCampaign(payload);
        },
        [campaignData.id, updateCampaign],
    );

    const handleMoveToDraft = () => {
        unpublishCampaign({
            campaignId: campaignData?.id,
        })
            .then(() => {
                void navigate(
                    `/projects/${projectUuid}/campaigns/${campaignData?.id}/edit?channel=${campaignData?.channel}`,
                );
            })
            .catch(() => {});
    };

    useDebounce(
        () => {
            if (description !== campaignData.description) {
                void handleCampaignDescriptionChange(description);
            }
        },
        500,
        [description],
    );

    const renderStatusBox = useMemo(() => {
        if (
            campaignData.schedule?.executionTime &&
            campaignData.status === CampaignStatus.COMPLETED
        ) {
            let additionalText: React.ReactNode;
            if (campaignData.schedule.type === CampaignScheduleType.MANUAL) {
                additionalText = (
                    <Text className="text-sm text-gray-600">
                        {t('preview_subtitle.run_manually')}
                    </Text>
                );
            }
            if (
                campaignData.schedule.type === CampaignScheduleType.DELAYED &&
                campaignData.type === CampaignType.RECURRING
            ) {
                additionalText = (
                    <Text
                        onClick={() => {
                            viewModalOpen();
                        }}
                        className="text-sm text-gray-600 cursor-pointer hover:text-gray-800"
                    >
                        {t('preview_subtitle.view_runs')}
                    </Text>
                );
            }

            return (
                <Box className="flex items-center gap-1">
                    <Text className="text-gray-600">{' · '}</Text>
                    <CheckCircle
                        size={14}
                        weight="duotone"
                        color="rgb(var(--color-gray-600))"
                    />
                    <Text className="text-sm text-gray-600">
                        {t('preview_subtitle.finished')} {' · '}{' '}
                        {t('preview_subtitle.ended')}
                    </Text>
                    {campaignData.updatedAt && (
                        <Timestamp
                            timestamp={campaignData.updatedAt.toString()}
                            customClass="text-gray-600"
                        />
                    )}
                    {additionalText && (
                        <Box className="flex items-center gap-1">
                            <Text className="text-gray-600">{' · '}</Text>
                            <Text>{additionalText}</Text>
                        </Box>
                    )}
                </Box>
            );
        }

        return null;
    }, [
        campaignData.schedule?.executionTime,
        campaignData.schedule?.type,
        campaignData.status,
        campaignData.type,
        campaignData.updatedAt,
        t,
        viewModalOpen,
    ]);

    const renderDescription = useMemo(() => {
        if (campaignData.description === undefined) return null;
        if (isEditable) {
            return (
                <Box className="flex flex-row items-center gap-1">
                    <Text className="text-xs text-gray-600">{'·'}</Text>
                    <TextInputVariableWidth
                        key={campaignData.description}
                        value={description}
                        maxWidth={576}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Click to add description"
                        styles={{
                            input: {
                                ...buttonInputStyles({
                                    fontSize: FontSizes.xs,
                                    fontWeight: FontWeights.normal,
                                    textColor: 'rgb(var(--color-gray-600))',
                                    placeholderColor:
                                        'rgb(var(--color-gray-400))',
                                }).input,
                                '&:focus-within': {
                                    background: 'transparent',
                                    boxShadow: 'none',
                                },
                            },
                        }}
                    />
                </Box>
            );
        }
        if (description.length > 0) {
            return (
                <Box className="flex flex-row items-center gap-1">
                    <Text className="text-xs text-gray-600">{'·'}</Text>
                    <Tooltip
                        label={campaignData.description}
                        position="bottom"
                        withArrow
                        multiline
                        width={700}
                    >
                        <Text
                            size={'xs'}
                            className="text-xs text-gray-600 w-28 truncate"
                        >
                            {campaignData.description}
                        </Text>
                    </Tooltip>
                </Box>
            );
        }
    }, [campaignData.description, isEditable, description]);

    return (
        <Box className="flex flex-row items-center w-full gap-3 pt-1">
            <ChannelIcon
                channel={campaignData?.channel as CommunicationChannel}
                color={'rgb(var(--color-gray-500)'}
            />

            {renderStatusBox}

            {renderDescription}

            {campaignData.status === CampaignStatus.READY && (
                <Box className="flex flex-row items-center gap-1">
                    <Text className="text-gray-600">{'·'}</Text>
                    <CheckCircle
                        size={14}
                        weight="duotone"
                        color="rgb(var(--color-blu-800))"
                    />
                    <Text className="text-sm text-blu-800">
                        {t('preview_subtitle.ready')}
                    </Text>
                </Box>
            )}

            {campaignData.status === CampaignStatus.SCHEDULED && (
                <>
                    <Box className="flex items-center gap-1">
                        <Text className="text-gray-600">{'·'}</Text>
                        <ClockCountdown
                            size={14}
                            weight="duotone"
                            color="rgb(var(--color-orange-800))"
                        />
                        <Text className="flex items-center gap-1 text-sm text-orange-800">
                            {campaignData.type === CampaignType.ONE_TIME &&
                                campaignData.schedule?.executionTime && (
                                    <>
                                        {t('preview_subtitle.scheduled_for')}
                                        <Timestamp
                                            timestamp={campaignData.schedule.executionTime.toString()}
                                        />
                                    </>
                                )}
                            {campaignData.type === CampaignType.RECURRING &&
                                campaignData.schedule?.executionTime && (
                                    <>
                                        {t('preview_subtitle.recurring')}{' '}
                                        {' · '}
                                        {new Date(
                                            campaignData.schedule.executionTime,
                                        ) > new Date() ? (
                                            <>
                                                {t(
                                                    'preview_subtitle.starts_on',
                                                )}
                                                <Timestamp
                                                    timestamp={campaignData.schedule.executionTime.toString()}
                                                />
                                            </>
                                        ) : campaignData.schedule
                                              .nextExecution ? (
                                            <>
                                                {t('next run on')}
                                                <Timestamp
                                                    timestamp={campaignData.schedule.nextExecution.toString()}
                                                />
                                            </>
                                        ) : (
                                            <>{t('next run in progress')}</>
                                        )}
                                    </>
                                )}
                        </Text>
                    </Box>
                </>
            )}
            {([CampaignStatus.READY].includes(
                campaignData?.status ?? CampaignStatus.DRAFT,
            ) ||
                (campaignData?.status === CampaignStatus.SCHEDULED &&
                    campaignData.type === CampaignType.ONE_TIME) ||
                (campaignData?.status === CampaignStatus.SCHEDULED &&
                    campaignData.type === CampaignType.RECURRING &&
                    !campaignData.schedule?.children)) && (
                <Button
                    variant={ButtonVariant.OUTLINED}
                    loading={isLoading}
                    onClick={handleMoveToDraft}
                >
                    {t('campaign_preview.edit')}
                </Button>
            )}

            {campaignData.status === CampaignStatus.SCHEDULED &&
                !!campaignData?.schedule?.children &&
                campaignData?.schedule?.children > 1 && (
                    <Text
                        onClick={viewModalOpen}
                        className="text-sm text-gray-700 cursor-pointer hover:text-gray-800"
                    >
                        {' · '}
                        {t('preview_subtitle.view_runs')}
                    </Text>
                )}

            {showViewModal && (
                <ViewRunsModal
                    showViewModal={showViewModal}
                    viewModalClose={viewModalClose}
                />
            )}
        </Box>
    );
};

export default PreviewSubtitle;
