import FilterOperatorSelect from '@components/common/Select/FilterOperatorSelect';
import {
    addFilterRule,
    FilterType,
    getFilterRuleWithDefaultValue,
    RelationTableType,
    ReservedAudienceKeys,
    type FilterableField,
    type FilterRule,
    type Filters,
} from '@lightdash/common';
import React, { useCallback, useMemo } from 'react';
import { getFilterOperatorOptions } from '../FilterInputs/utils';
import FilterRuleFormPropertySelect from '../FilterRuleFormPropertySelect';
import { MenuForDimensionFilterRule } from '../FilterRuleMenuItem';
import FilterRuleFormWrapper from './FilterRuleFormWrapper';

interface AudienceFilterRuleFormProps {
    filterRule: FilterRule;
    onChange: (value: FilterRule) => void;
    isEditMode: boolean;
    fields: FilterableField[];
    onDelete: () => void;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    groupIndex: number;
    filters: Filters;
}

const AudienceFilterRuleForm = ({
    filterRule,
    onChange,
    isEditMode,
    fields,
    onDelete,
    setFilters,
    groupIndex,
    filters,
}: AudienceFilterRuleFormProps) => {
    const filterOperatorOptions = useMemo(() => {
        return getFilterOperatorOptions(FilterType.AUDIENCE);
    }, []);

    const activeField = useMemo(() => {
        const filterRuleValue = filterRule.values?.[0];

        //Info: Checking for filterRuleValue, which is the audienceId, in the fields since we added audience as a field
        //with audienceId as tableId against each audience field
        return fields.find((field) => {
            return (
                'tableType' in field &&
                field.tableType === RelationTableType.AUDIENCE &&
                field.table === filterRuleValue.split(':')[1]
            );
        });
    }, [filterRule, fields]);

    const audienceFields = useMemo(() => {
        return fields.filter(
            (field) =>
                'tableType' in field &&
                field.tableType === RelationTableType.AUDIENCE,
        );
    }, [fields]);

    const duplicateAudienceFilter = useCallback(() => {
        if (!activeField) return;
        setFilters(
            addFilterRule({
                filters,
                field: activeField,
                ...(filterRule.values?.length && {
                    value: filterRule.values,
                }),
            }),
            false,
            groupIndex,
        );
    }, [activeField, filterRule.values, filters, groupIndex, setFilters]);

    const onFieldChange = useCallback(
        (field: FilterableField) => {
            const value = `${ReservedAudienceKeys.SRT_AUDIENCE}${field.table}`;
            onChange(getFilterRuleWithDefaultValue(field, filterRule, [value]));
        },
        [onChange, filterRule],
    );

    if (!activeField) return null;

    return (
        <FilterRuleFormWrapper>
            <FilterOperatorSelect
                isDisabled={!isEditMode}
                filterOperatorOptions={filterOperatorOptions}
                selectedOperator={filterRule.operator}
                onChange={(value) => {
                    if (!value) return;
                    onChange(
                        getFilterRuleWithDefaultValue(
                            activeField,
                            {
                                ...filterRule,
                                operator: value as FilterRule['operator'],
                            },
                            (filterRule.values?.length || 0) > 0
                                ? filterRule.values
                                : [1],
                        ),
                    );
                }}
            />
            <FilterRuleFormPropertySelect
                fields={audienceFields}
                onSubmit={(items) => {
                    onFieldChange(items[0]);
                }}
                isEditMode={isEditMode}
                activeField={activeField}
                withinPortal={false}
                showFieldSource={false}
            />
            <MenuForDimensionFilterRule
                onDelete={onDelete}
                duplicateFilter={duplicateAudienceFilter}
            />
        </FilterRuleFormWrapper>
    );
};

export default React.memo(AudienceFilterRuleForm);
