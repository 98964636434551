import IntegrationOption from '@components/Campaigns/Builder/IntegrationOption';
import Modal from '@components/common/modal/Modal';
import SkeletonLoader from '@components/common/SkeletonLoader';
import {
    useGetIntegratedProviderConfig,
    useGetProviderConfig,
} from '@hooks/useChannels';
import { useLocale } from '@hooks/useLocale';
import {
    type Campaign,
    type CommunicationChannel,
    type Integration,
} from '@lightdash/common';
import { Box, Button, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ChannelPage } from '@pages/Channel';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import ActionTitle from '../../ActionTitle';

interface CampaignActionChannelProps {
    communicationDetails: Partial<Campaign['communicationDetails']> | undefined;
    channel: CommunicationChannel | null;
    setJourneyChannelPayload: (channel: Integration | null) => void;
}

const CampaignActionChannel: React.FC<CampaignActionChannelProps> = ({
    communicationDetails,
    setJourneyChannelPayload,
    channel,
}) => {
    const { t } = useLocale();

    const {
        data: integrationConfig,
        isInitialLoading: isIntegrationConfigLoading,
    } = useGetIntegratedProviderConfig(
        communicationDetails?.providerId,
        communicationDetails?.id,
    );

    const { data: providerConfig, isInitialLoading: isProviderConfigLoading } =
        useGetProviderConfig(communicationDetails?.providerId);

    const [opened, { open: openChannelSelector, close: closeChannelSelector }] =
        useDisclosure(false);

    const { setCampaignChannelPayload } = useCampaignContext(
        (context) => context.actions,
    );

    const { isEditable } = useJourneyBuilderContext((context) => context.state);

    const onProviderChange = useCallback(
        (provider: Integration) => {
            closeChannelSelector();
            setCampaignChannelPayload(provider);
            setJourneyChannelPayload(provider);
        },
        [
            setJourneyChannelPayload,
            closeChannelSelector,
            setCampaignChannelPayload,
        ],
    );

    const renderChannelElement = useMemo(() => {
        if (!communicationDetails || isEmpty(communicationDetails)) {
            return (
                <Button
                    variant={ButtonVariant.DEFAULT}
                    className="w-fit"
                    onClick={openChannelSelector}
                    disabled={!isEditable}
                >
                    {t(
                        'journey_builder.channel_element_select_integration_btn',
                    )}
                </Button>
            );
        }

        if (isIntegrationConfigLoading || isProviderConfigLoading)
            return (
                <Box className="p-3">
                    <SkeletonLoader height={70} />
                </Box>
            );
        if (!integrationConfig || !providerConfig) return <></>;

        return (
            <IntegrationOption
                title={integrationConfig?.customName}
                providerName={providerConfig.providerName}
                providerLogoUrl={providerConfig.logoUrl}
                integrationId={integrationConfig.integrationId ?? ''}
                isMutable={false}
                showRightSection={true}
                channel={channel}
                customClass="w-full border-none !cursor-default hover:bg-white"
                rightSection={
                    isEditable ? (
                        <Text
                            className="text-sm font-semibold cursor-pointer text-blu-800"
                            onClick={openChannelSelector}
                        >
                            {t('common.change')}
                        </Text>
                    ) : null
                }
                boxShadow={false}
            />
        );
    }, [
        communicationDetails,
        isIntegrationConfigLoading,
        isProviderConfigLoading,
        integrationConfig,
        providerConfig,
        channel,
        openChannelSelector,
        isEditable,
        t,
    ]);

    return (
        <>
            <Stack>
                <ActionTitle
                    title={t('journey_builder.integration_element_title')}
                />
                {renderChannelElement}
            </Stack>
            <Modal
                opened={opened}
                onClose={closeChannelSelector}
                title={t(
                    'journey_builder.channel_element_select_integration_btn',
                )}
                size="lg"
            >
                <ChannelPage
                    channel={channel ?? undefined}
                    allowChannelSetup={false}
                    handleProviderSelect={onProviderChange}
                    showSetupLabels={false}
                    showChannelList={false}
                />
            </Modal>
        </>
    );
};

export default CampaignActionChannel;
