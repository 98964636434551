import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import MantineIcon from '@components/common/MantineIcon';
import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Modal from '@components/common/modal/Modal';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import useNotify from '@hooks/toaster/useNotify';
import {
    useAudienceScheduleMutation,
    useAudienceUpdation,
} from '@hooks/useAudience';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceBuilderHashParams,
    AudienceRunStatus,
    AudienceRunTypes,
    AudienceStatus,
    CommonReservedTags,
    QueryGenerationStrategy,
    type Audience,
} from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    Archive,
    ArrowCounterClockwise,
    ArrowElbowDownLeft,
    CaretRight,
    Clock,
    CopySimple,
    DotsThree,
    Play,
    Spinner,
} from '@phosphor-icons/react';
import useApp from '@providers/App/useApp';
import { useQueryClient } from '@tanstack/react-query';
import { type Row } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';
import useRelationContext from '../../../providers/Relation/useRelationContext';

interface AudienceMenuItemProps {
    rowData: Row<Audience>;
    setModalOpen: () => void;
    setAudienceData: (data: Audience) => void;
}

export const AudienceMenuItem: React.FC<AudienceMenuItemProps> = ({
    rowData,
    setModalOpen,
    setAudienceData,
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { mutateAsync: mutateAudienceSchedule } =
        useAudienceScheduleMutation();
    const queryClient = useQueryClient();
    const { user } = useApp();
    const { t } = useLocale();
    const ability = useAbilityContext();
    const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } =
        useAudienceUpdation(true); // INFO: hide toast for now as we want to customise mesage here.
    const { activeRelationUuid } = useRelationContext();
    const navigate = useNavigate();
    const { showToastSuccess, showToastError } = useNotify();
    const [
        openedHideConfirmModal,
        { open: openHideConfirmModal, close: closeHideConfirmModal },
    ] = useDisclosure();
    const canEditAudience = ability.can(
        'manage',
        subject('VisualAudience', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const handleViewEdit = useCallback(
        (id: string, type: string) => {
            void navigate(`/projects/${projectUuid}/audiences/${id}/${type}`);
        },
        [projectUuid, navigate],
    );

    const handleDuplicate = useCallback(
        (value: Audience) => {
            void navigate(
                `/projects/${projectUuid}/audiences/create?templateId=${
                    value.id
                }#${
                    value.generationStrategy ===
                    QueryGenerationStrategy.AUDIENCE_BUILDER
                        ? AudienceBuilderHashParams.VISUAL
                        : AudienceBuilderHashParams.SQL
                }`,
            );
        },
        [projectUuid, navigate],
    );

    const handleRunnow = useCallback(
        async (id: string) => {
            await mutateAudienceSchedule({
                audienceId: id,
                data: { runType: AudienceRunTypes.MANUAL },
            });

            await queryClient.invalidateQueries([QueryKeys.GET_AUDIENCE]);
        },
        [queryClient, mutateAudienceSchedule],
    );
    const viewAudienceItem = useCallback(
        () => ({
            leftSection: (
                <ArrowElbowDownLeft
                    size={14}
                    weight="duotone"
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('audience_manager.menu_item_view'),
            onClick: (values: Audience) => handleViewEdit(values.id, 'view'),
        }),
        [handleViewEdit, t],
    );
    const duplicateAudienceItem = useCallback(
        (isDividerRequired: boolean) => ({
            leftSection: (
                <CopySimple
                    size={14}
                    weight="duotone"
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('audience_manager.menu_item_duplicate'),
            onClick: (values: Audience) => handleDuplicate(values),
            isDivider: isDividerRequired,
        }),
        [handleDuplicate, t],
    );
    const runnowItem = useCallback(
        (isRunning: boolean) => ({
            leftSection: isRunning ? (
                <Spinner weight="duotone" size={13} />
            ) : (
                <Play
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: isRunning ? (
                <Text className="text-blu-800">
                    {t('audience_manager.menu_item_running')}
                </Text>
            ) : (
                <Text>{t('audience_manager.menu_item_run')}</Text>
            ),
            onClick: (values: Audience) => {
                if (isRunning) return;
                return handleRunnow(values.id);
            },
        }),
        [handleRunnow, t],
    );

    const changeRunScheduleItem = useCallback(
        (isDividerRequired: boolean) => {
            const menuItem = {
                leftSection: (
                    <Clock
                        size={14}
                        color={'rgb(var(--color-gray-600))'}
                        weight="duotone"
                        strokeWidth={2.5}
                    />
                ),
                children:
                    rowData.original.runType === AudienceRunTypes.SCHEDULED ||
                    rowData.original.runType === AudienceRunTypes.CRON
                        ? t('audience_manager.menu_item_change_schedule')
                        : t('audience_manager.menu_item_add_schedule'),
                onClick: (values: Audience) => {
                    setAudienceData(values);
                    setModalOpen();
                },
                isDivider: isDividerRequired,
            };

            return menuItem;
        },
        [setAudienceData, setModalOpen, t, rowData.original.runType],
    );

    const editItems = useMemo(
        () => ({
            leftSection: (
                <ArrowElbowDownLeft
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('audience_manager.menu_item_edit'),
            onClick: (audience: Audience) =>
                handleViewEdit(audience.id, 'edit'),
        }),
        [t, handleViewEdit],
    );
    const hideItems = useMemo(
        () => ({
            leftSection: (
                <Archive
                    weight="duotone"
                    color={'rgb(var(--color-halt-800))'}
                />
            ),
            children: <Text className="text-halt-800">{t('common.hide')}</Text>,
            customClass: 'hover:bg-halt-800/6',
            onClick: () => openHideConfirmModal(),
        }),
        [t, openHideConfirmModal],
    );
    const restoreItems = useMemo(
        () => ({
            leftSection: <ArrowCounterClockwise weight="duotone" />,
            children: t('common.restore'),
            onClick: async (audience: Audience) => {
                await mutateAsyncUpdate(
                    {
                        audienceId: audience.id,
                        relationUuid: activeRelationUuid,
                        payload: {
                            tags: [
                                ...(audience.tags?.filter(
                                    (tag) => tag !== CommonReservedTags.HIDDEN,
                                ) || []),
                            ],
                        },
                    },
                    {
                        onSuccess: () => {
                            showToastSuccess({
                                title: t('common.hidden_restore_success', {
                                    entityName: t('common.audience'),
                                }),
                            });
                        },
                        onError: (error) => {
                            showToastError({
                                title: t('common.hidden_restore_error', {
                                    entityName: t('common.audience'),
                                }),
                                subtitle: error.error.message,
                            });
                        },
                    },
                );
            },
        }),
        [
            t,
            mutateAsyncUpdate,
            activeRelationUuid,
            showToastError,
            showToastSuccess,
        ], // Ensure all dependencies are listed and separated by commas
    );
    const menuItems = useMemo(() => {
        let items: CustomMenuItemProps<Audience>[] = [];
        if (rowData.original.status === AudienceStatus.DRAFT) {
            items = [editItems, duplicateAudienceItem(true)];
        } else {
            items = [
                viewAudienceItem(),
                duplicateAudienceItem(true),
                runnowItem(
                    rowData.original.lastRunStatus ===
                        AudienceRunStatus.RUNNING,
                ),
                changeRunScheduleItem(true),
            ];
        }
        if (rowData.original.tags?.includes(CommonReservedTags.HIDDEN)) {
            items.push(restoreItems);
        } else {
            items.push(hideItems);
        }
        return items;
    }, [
        rowData.original.lastRunStatus,
        changeRunScheduleItem,
        duplicateAudienceItem,
        editItems,
        rowData.original.status,
        runnowItem,
        viewAudienceItem,
        restoreItems,
        hideItems,
        rowData.original.tags,
    ]);

    return (
        <>
            {canEditAudience && (
                <>
                    <CustomMenu<Audience>
                        menuItems={menuItems}
                        data={rowData.original}
                        icon={
                            <MantineIcon
                                icon={() => (
                                    <DotsThree
                                        size={14}
                                        weight="bold"
                                        color="rgb(var(--color-gray-600))"
                                    />
                                )}
                            />
                        }
                    />
                    <Modal
                        opened={openedHideConfirmModal}
                        onClose={closeHideConfirmModal}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        title={
                            <Box>
                                <Text>
                                    {t(
                                        'audinece_manager.confirm_hide_modal_title',
                                    )}
                                </Text>
                                <Text className="text-sm font-normal text-gray-600">
                                    {rowData.original.name}
                                </Text>
                            </Box>
                        }
                        footerRightSection={
                            <ButtonGroup
                                primaryButtonLabel={t(
                                    'audience_manager.confirm_hide_prim_button',
                                )}
                                secondaryButtonLabel={t('common.no')}
                                isUpdating={isUpdating}
                                handlePrimaryButtonClick={async () => {
                                    await mutateAsyncUpdate(
                                        {
                                            audienceId: rowData.original.id,
                                            relationUuid: activeRelationUuid,
                                            payload: {
                                                tags: [
                                                    ...(rowData.original.tags ||
                                                        []),
                                                    CommonReservedTags.HIDDEN,
                                                ],
                                            },
                                        },
                                        {
                                            onSuccess: () => {
                                                showToastSuccess({
                                                    title: t(
                                                        'common.hidden_success',
                                                        {
                                                            entityName:
                                                                t(
                                                                    'common.audience',
                                                                ),
                                                        },
                                                    ),
                                                });
                                            },
                                            onError: (error) => {
                                                showToastError({
                                                    title: t(
                                                        'common.hidden_error',
                                                        {
                                                            entityName:
                                                                t(
                                                                    'common.audience',
                                                                ),
                                                        },
                                                    ),
                                                    subtitle:
                                                        error.error.message,
                                                });
                                            },
                                        },
                                    );
                                    closeHideConfirmModal();
                                }}
                                handleSecondaryButtonClick={
                                    closeHideConfirmModal
                                }
                                primaryButtonVariant={
                                    ButtonVariant.FILLED_DESTRUCTIVE
                                }
                                primaryButtonRightIcon={
                                    <CaretRight color="white" />
                                }
                            />
                        }
                        closeButtonProps={{
                            className: 'absolute top-3 right-3',
                        }}
                    >
                        <Flex direction={'column'} gap={12}>
                            <Text className="text-sm font-semibold text-gray-800">
                                {t(
                                    'audinece_manager.confirm_hide_modal_header',
                                )}
                            </Text>
                            <Text className="text-sm text-gray-800">
                                {t(
                                    'audinece_manager.confirm_hide_modal_description',
                                )}
                            </Text>
                        </Flex>
                    </Modal>
                </>
            )}
        </>
    );
};
