import { Box, Text } from '@mantine/core';

import { forwardRef } from 'react';
import { Check } from 'react-feather';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    label: string;
    description: string;
}
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, description, ...others }: ItemProps, ref) => (
        <Box ref={ref} {...others}>
            <Box className="flex justify-between">
                <Text className="text-sm font-medium text-gray-800">
                    {label}
                </Text>

                <Check
                    size={13}
                    strokeWidth={2.5}
                    color={'rgb(var(--color-gray-600))'}
                    className="selected__item--icon-selected"
                />
            </Box>
            <Text className="text-sm text-gray-600 break-normal">
                {description}
            </Text>
        </Box>
    ),
);

export default SelectItem;
