import RecursiveMenu from '@components/EventsManager/Steps/EventBuilderProperties/CreatableSelect/RecursiveMenu';
import { EventLabelDataTypeOptions } from '@components/EventsManager/utils';
import { useLocale } from '@hooks/useLocale';
import { DimensionType } from '@lightdash/common';
import { Box, Button, Flex, Menu, Text } from '@mantine/core';
import { CaretDown } from '@phosphor-icons/react';
import React, { forwardRef, type CSSProperties } from 'react';
import { ButtonVariant } from '../../../../../../../../mantineTheme';

interface DataTypeMenuProps {
    onSelect: (type: string) => void;
    value: string;
    disabled: boolean;
    className?: string;
    styles?: Record<string, CSSProperties>;
}
export const GetIconAndLabel = forwardRef<
    HTMLDivElement,
    { type: DimensionType; isComplexDataType: boolean }
>(({ type, isComplexDataType }, ref) => {
    const isEventLabelDataType = (dataType: string) => {
        return Object.keys(EventLabelDataTypeOptions).includes(dataType);
    };

    if (!isComplexDataType && isEventLabelDataType(type)) {
        const { icon, label } = EventLabelDataTypeOptions[type];
        return (
            <Flex gap={8} align="center">
                {icon}
                <Text>{label}</Text>
            </Flex>
        );
    }

    const { icon } = EventLabelDataTypeOptions.array;
    const sub = type?.split(' ');
    const subType =
        EventLabelDataTypeOptions[sub[sub.length - 1] as DimensionType];
    return (
        <Flex gap={8} align="center" ref={ref}>
            {icon}
            {subType?.icon}
            <Text>
                {subType?.type !== DimensionType.TIMESTAMP
                    ? `${subType?.label}s`
                    : subType?.label}
            </Text>
        </Flex>
    );
});

export const DataTypeMenu = ({
    onSelect,
    value,
    disabled,
    className,
    styles,
}: DataTypeMenuProps) => {
    const { t } = useLocale();
    return (
        <Menu disabled={disabled} styles={styles}>
            <Menu.Target>
                <Button
                    variant={ButtonVariant.SUBTLE}
                    className="p-2"
                    rightIcon={<CaretDown weight="bold" />}
                >
                    <Box className={className}>
                        {value ? (
                            <GetIconAndLabel
                                type={value as DimensionType}
                                isComplexDataType={false}
                            />
                        ) : (
                            <Text className="text-gray-500">
                                {t(
                                    'journey_builder.call_api_response_payload_mapper_table_type_placeholder',
                                )}
                            </Text>
                        )}
                    </Box>
                </Button>
            </Menu.Target>
            <RecursiveMenu
                handleClick={onSelect}
                level={0}
                nestedMenuOpened={false}
                setNestedMenuOpened={undefined}
            />
        </Menu>
    );
};
export default React.memo(DataTypeMenu);
