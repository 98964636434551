import { useContextSelector } from 'use-context-selector';
import ChannelProviderContext from './context';
import { type ChannelContextProps } from './types';

function useChannelContext<Selected>(
    selector: (value: ChannelContextProps) => Selected,
) {
    return useContextSelector(ChannelProviderContext, (context) => {
        if (context === undefined) {
            throw new Error(
                'useContextSelector; must be used within a ChannelProvider',
            );
        }
        return selector(context);
    });
}

export default useChannelContext;
