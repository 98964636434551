import { sortmentApi } from '@api/index';
import { type ApiError, type ApiSqlQueryResults } from '@lightdash/common';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router';
import useNotify from './toaster/useNotify';

const runSqlQuery = async (projectUuid: string, sql: string) =>
    sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectUuid}/sqlQuery`,
        method: 'POST',
        body: JSON.stringify({ sql }),
    });

export const useSqlQueryMutation = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastApiError } = useNotify();
    return useMutation<ApiSqlQueryResults, ApiError, string>(
        (sql) =>
            projectUuid ? runSqlQuery(projectUuid, sql) : Promise.reject(),
        {
            mutationKey: ['run_sql_query', projectUuid],
            onError: ({ error }) => {
                showToastApiError({
                    title: `Failed to run sql query`,
                    apiError: error,
                });
            },
        },
    );
};
