import { PeriodType } from '@lightdash/common';
export const PAGE_CONTENT_WIDTH = 1300;
export const PAGE_MIN_CONTENT_WIDTH = 1000;
export const FOOTER_HEIGHT = 70;
export const FOOTER_MARGIN = 32;
export const AUDIENCE_PREVIEW_LIMIT = 50;
export const AUDIENCE_PREVIEW_BY_ID_LIMIT = 50;
export const NAVBAR_HEIGHT = 50;
export const SIDEBAR_DEFAULT_WIDTH = 400;
export const SIDEBAR_MIN_WIDTH = 300;
export const SIDEBAR_MAX_WIDTH = 400;
export const CUSTOMNAME = 'customName';
export const LIST_REFETCH_TIME = 10000;
export const PAGE_HEADER_HEIGHT = 50;
export const REACT_ARBORIST_PARENT_ID = '__REACT_ARBORIST_INTERNAL_ROOT__';
export const SEARCH_INPUT_DEBOUNCE_TIME = 500;
export const CSV_AUDIENCE_PREVIEW_LIMIT = 20;
export const PROFILE_ACTIVITY_PAGE_SIZE = 10;
export const CAMPAIGN_EVENT_TABLE_NAME = 'srt_campaign_event';
export const CAMPAIGN_ID_FILTER_INDEX = 2;
export const CAMPAIGN_EVENT_EMAIL_OPENED = 'Email opened';
export const CAMPAIGN_EVENT_EMAIL_DELIVERED = 'Email delivered';
export const CAMPAIGN_EVENT_EMAIL_LINK_CLICKED = 'Email link clicked';
export const CAMPAIGN_EVENT_SMS_DELIVERED = 'SMS delivered';
export const CAMPAIGN_EVENT_WHATSAPP_DELIVERED = 'Whatsapp message delivered';
export const CAMPAIGN_EVENT_WHATSAPP_READ = 'Whatsapp message read';
export const CAMPAIGN_EVENT_SMS_LINK_CLICKED = 'SMS link clicked';
export const CAMPAIGN_EVENT_EMAIL_SENT = 'Email sent';
export const CAMPAIGN_EVENT_EMAIL_BOUNCED = 'Email bounced';
export const CAMPAIGN_EVENT_WHATSAPP_SENT = 'Whatsapp message sent';
export const CAMPAIGN_EVENT_SMS_SENT = 'SMS sent';

export const AI_BANNER_SESSION_KEY = 'aiBanner';

type DurationTypeOptions = {
    label: string;
    value: PeriodType;
    placeholder: string;
};

export const durationTypeOptions: DurationTypeOptions[] = [
    {
        label: 'Minutes',
        value: PeriodType.MINUTE,
        placeholder: 'No. of minutes',
    },
    {
        label: 'Hours',
        value: PeriodType.HOUR,
        placeholder: 'No. of hours',
    },
    {
        label: 'Days',
        value: PeriodType.DAY,
        placeholder: 'No. of days',
    },
    {
        label: 'Weeks',
        value: PeriodType.WEEK,
        placeholder: 'No. of weeks',
    },
];
export enum SearchParams {
    REDIRECT = 'redirect',
}
