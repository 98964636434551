import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider/FiltersProvider';
import SkeletonLoader from '@components/common/SkeletonLoader';
import useJourneyFiltersContext from '@components/Journeys/Builder/JourneyFilters/FiltersForm/JourneyFiltersProvider/useJourneyFiltersContext';
import { isCachedField } from '@components/Journeys/Builder/JourneyFilters/utils';
import { useNodeData } from '@components/Journeys/Builder/ReactFlow/Nodes/useNodeData';
import { Divider } from '@mantine/core';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import SplitOn from '../SplitOn';
import SplitPath from '../SplitPath';
import { getActiveFieldInSplitBlock } from '../utils';

interface SplitBlockProps {
    nodeId: string;
}

const SplitBlock: React.FC<SplitBlockProps> = ({ nodeId }) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { journeyNodeData } = useNodeData(nodeId);
    const { actions } = useJourneyBuilderContext((context) => context);
    const { updateSplitActiveFields } = actions;
    const {
        warehouseFieldsMap,
        eventsData,
        eventsTableNames,
        audienceData,
        isLoading,
        journeyDataSchema,
    } = useJourneyFiltersContext();
    useEffect(() => {
        const activeField = getActiveFieldInSplitBlock(
            journeyNodeData,
            warehouseFieldsMap,
        );
        if (
            activeField &&
            journeyDataSchema &&
            Object.keys(warehouseFieldsMap).length > 0
        ) {
            updateSplitActiveFields(
                nodeId,
                activeField,
                isCachedField(activeField, journeyDataSchema),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseFieldsMap, journeyDataSchema]);
    if (isLoading) {
        return (
            <>
                <SkeletonLoader height={50} />
                <SkeletonLoader height={50} />
            </>
        );
    }

    return (
        <FiltersProvider
            projectUuid={projectUuid}
            fieldsMap={warehouseFieldsMap}
            eventsMap={eventsData}
            eventTables={eventsTableNames}
            audienceData={audienceData}
            isLoading={isLoading}
        >
            <SplitOn nodeId={nodeId} />
            <Divider className="border-t-gray-200 " />
            <SplitPath nodeId={nodeId} />
        </FiltersProvider>
    );
};

export default React.memo(SplitBlock);
