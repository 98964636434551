import {
    DimensionType,
    UpdateTraitType,
    type DifferenceOperator,
    type UpdateNumberConfig,
    type UpdateTraitBaseConfig,
    type UpdateTraitDateConfig,
} from '@lightdash/common';
import { t as translate } from 'i18next';

export interface SetValueStringInUpdateTraitProp {
    [DimensionType.STRING]: string;
    [DimensionType.NUMBER]: string;
    [DimensionType.BOOLEAN]: string;
    [DimensionType.DATE]: string;
    [DimensionType.TIMESTAMP]: string;
    [DimensionType.EVENT]: string;
}

export const SetValueStringInUpdateTrait: SetValueStringInUpdateTraitProp = {
    [DimensionType.STRING]: translate('common.set_value_to'),
    [DimensionType.NUMBER]: translate('common.set_value_to'),
    [DimensionType.BOOLEAN]: translate('common.set_to'),
    [DimensionType.DATE]: translate('common.set_to'),
    [DimensionType.TIMESTAMP]: translate('common.set_to'),
    [DimensionType.EVENT]: translate('common.set_to'),
};

type GetUpdateTraitConfigProps = {
    type: DimensionType;
    value: any;
    differenceValue: any;
    operator: DifferenceOperator | undefined;
};

/**
 * this util function is used to  maintain  update trait config
 * if the operator is not defined, it will return the base config
 * if the operator is defined, it will return the number config
 * if the type is not number, it will return the base config
 * if the type is number and the operator is not defined, it will return the base config
 */
export const getUpdateTraitConfig = ({
    type,
    value,
    differenceValue,
    operator,
}: GetUpdateTraitConfigProps):
    | UpdateNumberConfig
    | UpdateTraitDateConfig
    | UpdateTraitBaseConfig => {
    switch (type) {
        case DimensionType.NUMBER: {
            if (!operator) {
                return {
                    updateTraitType: UpdateTraitType.BASE,
                    config: { value },
                };
            }
            return {
                updateTraitType: UpdateTraitType.NUMBER,
                config: {
                    value: '',
                    diffrence: {
                        value: differenceValue,
                        operator,
                    },
                },
            } as UpdateNumberConfig;
        }
        default:
            return {
                updateTraitType: UpdateTraitType.BASE,
                config: { value },
            };
    }
};

export enum DifferenceLabel {
    INCREASE_BY = 'Increase by',
    DECREASE_BY = 'Decrease by',
    SET_TO = 'Set to',
}
