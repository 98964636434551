import MantineLinkButton from '@components/common/MantineLinkButton';
import { Box, Stack, Text, Title } from '@mantine/core';

const MobileView = () => (
    <Box w="100vw" h="100vh" sx={{ background: '#ebf1f5' }}>
        <Stack align="center" spacing="xl" justify="start" p="5xl">
            <Title ta="center" order={4}>
                Sortment currently works best on bigger screens.
            </Title>
            <Text ta="center" color="gray.6">
                Sign in on a laptop or desktop to access this page!
            </Text>
            <MantineLinkButton href="https://www.sortment.com/" target="_blank">
                Check out our website
            </MantineLinkButton>
        </Stack>
    </Box>
);

export default MobileView;
