import { sortmentApi } from '@api/index';
import { type ApiError, type AuditLogEntryFilter } from '@lightdash/common';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';

const getAuditLogs = async (
    perPage: number,
    currentPage: number,
    projectId: string,
    query: string,
) =>
    sortmentApi<AuditLogEntryFilter>({
        url: `/projects/${projectId}/audit-logs?perPage=${perPage}&currentPage=${currentPage}&${query}`,
        method: 'GET',
        body: undefined,
    });

interface UseAuditLogsProps {
    perPage: number;
    currentPage: number;
    query: string | undefined;
}

export const useAuditLogs = ({
    perPage,
    currentPage,
    query,
}: UseAuditLogsProps) => {
    const params = useParams<{ projectUuid?: string }>();
    const auditLogs = useQuery<AuditLogEntryFilter, ApiError>({
        queryKey: [QueryKeys.GET_AUDIT_LOGS, perPage, currentPage, query],
        queryFn: () =>
            getAuditLogs(
                perPage,
                currentPage,
                params?.projectUuid || '',
                query || '',
            ),
    });
    return auditLogs;
};
