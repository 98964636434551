import { Box, Button, Card, Group, Text } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { PlusCircle } from '@phosphor-icons/react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import TimezoneText from '@components/TimezoneText';
import { useLocale } from '@hooks/useLocale';
import { Timezones, type DeliveryControl } from '@lightdash/common';
import useProjectContext from '@providers/Project/useProjectContext';
import { getTimeFromUtc, getTimeInUtc } from '@utils/helpers';
import moment from 'moment';
import { Clock } from 'react-feather';
import {
    useCreateDeliveryControl,
    useUpdateDeliveryControl,
} from '../../../../hooks/useDeliveryControlsApi';
import { ButtonVariant } from '../../../../mantineTheme';
import CardHeader from '../CardHeader';

type QuietHoursProps = {
    data: DeliveryControl | undefined;
    isEditAllowed: boolean;
};

const QuietHours: React.FC<QuietHoursProps> = ({ data, isEditAllowed }) => {
    const toRef = useRef<HTMLInputElement>(null);
    const fromRef = useRef<HTMLInputElement>(null);
    const [quietHoursInputMode, setQuietHoursInputMode] = useState(false);
    const [fromTime, setFromTime] = useState(data?.quietHours?.start ?? '');
    const [toTime, setToTime] = useState(data?.quietHours?.end ?? '');
    const { t } = useLocale();
    const {
        mutate: createDeliveryControl,
        isLoading: createLoading,
        isSuccess: createSuccess,
    } = useCreateDeliveryControl();
    const {
        mutate: updateDeliveryControl,
        isLoading: updateLoading,
        isSuccess: updateSuccess,
    } = useUpdateDeliveryControl();
    const { projectData } = useProjectContext();
    const timezone = projectData?.timezone ?? Timezones.UTC;
    const offset = moment().tz(timezone).format('Z');

    const setInitialData = useCallback(() => {
        setFromTime(getTimeFromUtc(data?.quietHours?.start, offset) ?? '');
        setToTime(getTimeFromUtc(data?.quietHours?.end, offset) ?? '');
    }, [data, offset]);

    useEffect(() => {
        setInitialData();
    }, [data, offset, setInitialData]);

    useEffect(() => {
        if (createSuccess || updateSuccess) {
            setQuietHoursInputMode(false);
        }
    }, [createSuccess, updateSuccess]);

    const handleSave = useCallback(() => {
        if (fromTime > toTime) return;

        const payload = {
            quietHours: {
                start: getTimeInUtc(fromTime, offset),
                end: getTimeInUtc(toTime, offset),
                enabled: !!fromTime && !!toTime,
            },
        };
        if (data) {
            updateDeliveryControl(payload);
        } else {
            createDeliveryControl({ ...payload, cappingRules: [] });
        }
    }, [
        fromTime,
        toTime,
        offset,
        data,
        createDeliveryControl,
        updateDeliveryControl,
    ]);

    const handleCancel = () => {
        setInitialData();
        setQuietHoursInputMode(false);
    };

    const renderCardBottomSection = useMemo(() => {
        if (data?.quietHours?.enabled || quietHoursInputMode) {
            return (
                <Group>
                    <Text className="text-gray-600">
                        {t(
                            'workspace_settings.delivery_controls.quite_hours_input_section',
                        )}
                    </Text>
                    <Box className="w-[120px]">
                        <TimeInput
                            ref={fromRef}
                            icon={
                                <Clock
                                    size={13}
                                    strokeWidth={2}
                                    color={'rgb(var(--color-gray-600))'}
                                />
                            }
                            onClick={() =>
                                quietHoursInputMode &&
                                fromRef?.current?.showPicker()
                            }
                            onChange={(event) =>
                                setFromTime(event.target.value)
                            }
                            readOnly={!quietHoursInputMode}
                            value={fromTime}
                            error={toTime && fromTime > toTime}
                        />
                    </Box>
                    <Text className="text-gray-600">to</Text>
                    <Box className="w-[120px]">
                        <TimeInput
                            ref={toRef}
                            icon={
                                <Clock
                                    size={13}
                                    strokeWidth={2}
                                    color={'rgb(var(--color-gray-600))'}
                                />
                            }
                            onClick={() =>
                                quietHoursInputMode &&
                                toRef?.current?.showPicker()
                            }
                            onChange={(event) => setToTime(event.target.value)}
                            readOnly={!quietHoursInputMode}
                            value={toTime}
                            error={fromTime && toTime < fromTime}
                        />
                    </Box>
                    <TimezoneText />
                </Group>
            );
        }
        if (!data?.quietHours?.enabled && isEditAllowed) {
            return (
                <Button
                    onClick={() => setQuietHoursInputMode(true)}
                    variant={ButtonVariant.OUTLINED_ACCENTED}
                    leftIcon={
                        <PlusCircle
                            size={14}
                            color="rgb(var(--color-blu-800))"
                        />
                    }
                >
                    {t(
                        'workspace_settings.delivery_controls.quite_hours_button',
                    )}
                </Button>
            );
        }
        return (
            <Text className="text-gray-800">
                {t('workspace_settings.delivery_controls.quite_hours_not_set')}
            </Text>
        );
    }, [fromTime, toTime, quietHoursInputMode, t, data, isEditAllowed]);

    const handleEditClick = () => setQuietHoursInputMode(true);

    const outerBorderColor = useMemo(
        () => (quietHoursInputMode ? 'border-blu-800/40' : 'border-shade-4'),
        [quietHoursInputMode],
    );

    const innerBorderColor = useMemo(
        () => (quietHoursInputMode ? 'border-blu-800' : 'border-gray-50'),
        [quietHoursInputMode],
    );

    return (
        <Box className={`border ${outerBorderColor} rounded-2xl p-0.5 mt-4`}>
            <Card
                className={`border ${innerBorderColor} rounded-1xl`}
                padding={3}
            >
                <CardHeader
                    title={t(
                        'workspace_settings.delivery_controls.quite_hours_title',
                    )}
                    subtitle={t(
                        'workspace_settings.delivery_controls.quite_hours_subtitle',
                    )}
                    isActive={quietHoursInputMode}
                    handlePrimaryButtonClick={handleSave}
                    handleSecondaryButtonClick={handleCancel}
                    isUpdating={createLoading || updateLoading}
                    isEditAllowed={data?.quietHours?.enabled && isEditAllowed}
                    handleEditClick={handleEditClick}
                    primaryButtonLabel={undefined}
                    secondaryButtonLabel={undefined}
                />
                <Card.Section py="sm" px="sm">
                    {renderCardBottomSection}
                </Card.Section>
            </Card>
        </Box>
    );
};

export default QuietHours;
