import {
    JourneyTriggerType,
    PeriodType,
    type JourneyFiltersConfig,
} from '@lightdash/common';
import { Divider, Group, Stack } from '@mantine/core';
import { Cursor } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { t } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useNodeData } from '../../ReactFlow/Nodes/useNodeData';
import ActionTitle from '../Actions/ActionTitle';
import BaseTriggerEventFilter from '../Actions/BaseTriggerEventFilter';
import ReEntryBlock from '../Actions/ReEntry';
import BlockConfigContainer from '../BlockConfigPanel/BlockConfigContainer';
import JourneyAudienceFilter from '../JourneyAudienceFilter';

/*
Reentry logic 
- if contextTotal is -1, then user can reenter the journey infinite times
- if contextTotal is 1, then user can reenter the journey once
- if contextTotal is n, then user can reenter the journey n times
- if contextTotal is greater than 1, then cooldown time is mandatory
*/
interface TriggerConfigPanelProps {
    triggerId: string;
}

const TriggerConfigPanel: React.FC<TriggerConfigPanelProps> = ({
    triggerId,
}) => {
    const { journeyNodeData: triggerBlockData, icon } = useNodeData(triggerId);
    const { journeyPayload, isEditable } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const triggerData = useMemo(
        () => journeyPayload.triggers?.entry[0],
        [journeyPayload.triggers],
    );

    const { closeControlPanel, setEntryLogic, updateTriggerNode } =
        useJourneyBuilderContext((context) => context.actions);

    const { entryLogic } = journeyPayload;

    const cooldown = entryLogic!.cooldown;
    const contextTotal = entryLogic?.contextTotal ?? -1;
    const cooldownDuration = cooldown >= 0 ? cooldown : undefined;
    const cooldownType = entryLogic?.uiConfig?.cooldownType ?? PeriodType.DAY;
    const isReEntryBlockChecked = contextTotal !== 1 && contextTotal !== -1;

    const onDescriptionChange = useCallback(
        (description: string) => {
            if (!triggerBlockData) return;
            updateTriggerNode({
                metadata: {
                    description,
                },
            });
        },
        [triggerBlockData, updateTriggerNode],
    );

    const onBlockClose = () => {
        closeControlPanel();
    };

    const handleDurationChange = useCallback(
        (duration: number, granularity: PeriodType) => {
            setEntryLogic({
                cooldown: duration,
                uiConfig: {
                    cooldownType: granularity,
                },
            });
        },
        [setEntryLogic],
    );

    const handleReEntryChange = useCallback(
        (checked: boolean) => {
            setEntryLogic({
                cooldown: checked ? undefined : -1,
                contextTotal: checked ? -1 : 1,
            });
        },
        [setEntryLogic],
    );

    const handleEventChange = useCallback(
        (eventName: string, eventSource: string) => {
            updateTriggerNode({
                eventName,
                eventSource,
                filterConfig: undefined,
            });
        },
        [updateTriggerNode],
    );

    const handleFiltersChange = useCallback(
        (filters: JourneyFiltersConfig) => {
            updateTriggerNode({
                filterConfig: filters,
            });
        },
        [updateTriggerNode],
    );

    if (!triggerId || !triggerBlockData || !triggerData) return null;
    return (
        <BlockConfigContainer
            nodeData={triggerBlockData}
            icon={icon}
            onDescriptionChange={onDescriptionChange}
            onBlockClose={onBlockClose}
            showModifyButtons={false}
            isEditable={isEditable}
        >
            <Stack className="w-full">
                <Group className="gap-1">
                    <Cursor color={'rgb(var(--color-gray-500))'} />
                    <ActionTitle
                        title={t('journey_builder.event_action_title')}
                    />
                </Group>

                <BaseTriggerEventFilter
                    nodeId={JourneyTriggerType.ENTRY}
                    eventConfig={{
                        eventName: triggerData?.eventName,
                        eventSource: triggerData?.eventSource,
                    }}
                    setEvent={handleEventChange}
                    filters={triggerData?.filterConfig}
                    setFilters={handleFiltersChange}
                />
                <Divider className="border-t-gray-200" />

                <JourneyAudienceFilter
                    onUpdate={(metricQuery) => {
                        updateTriggerNode({
                            associatedAudienceFilterConfig: metricQuery,
                        });
                    }}
                    metricQuery={triggerData?.associatedAudienceFilterConfig}
                />

                <Divider className="border-t-gray-200" />

                <ReEntryBlock
                    checked={isReEntryBlockChecked}
                    duration={cooldownDuration}
                    granularity={cooldownType}
                    onDurationChange={handleDurationChange}
                    onReEntryChange={handleReEntryChange}
                    isEditable={isEditable}
                />
            </Stack>
        </BlockConfigContainer>
    );
};

export default React.memo(TriggerConfigPanel);
