import LinkButton from '@components/common/LinkButton';
import { useLocale } from '@hooks/useLocale';
import {
    CampaignStatus,
    CommunicationChannel,
    CommunicationChannelName,
} from '@lightdash/common';
import { Group, Title } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../../mantineTheme';
import CampaignBuilderProgress from './Progress';

interface CampaignBuilderHeaderProps {
    isNew: boolean;
    isViewOnly: boolean;
    status: CampaignStatus;
    channel: CommunicationChannel | undefined;
}

const CampaignBuilderHeader: React.FC<CampaignBuilderHeaderProps> = ({
    isNew,
    isViewOnly,
    status,
    channel,
}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const queryClient = useQueryClient();

    const title = useMemo(() => {
        if (isNew) {
            return t('campaign.create.title');
        }

        if (isViewOnly) {
            return t('campaign.view.title');
        }

        if (status === CampaignStatus.DRAFT) {
            return t('campaign.draft.title', {
                channel:
                    CommunicationChannelName[
                        channel ?? CommunicationChannel.EMAIL
                    ],
            });
        }

        return t('campaign.edit.title', {
            channel:
                CommunicationChannelName[channel ?? CommunicationChannel.EMAIL],
        });
    }, [isNew, isViewOnly, t, status, channel]);

    const handleExit = () => {
        void queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
    };

    return (
        <Group position="apart" className="w-full">
            <Title className="text-base font-medium text-gray-800">
                {title}
            </Title>
            <CampaignBuilderProgress />
            <LinkButton
                variant={ButtonVariant.OUTLINED}
                href={`/projects/${projectUuid}/campaigns`}
                onClick={handleExit}
            >
                {t('common.exit')}
            </LinkButton>
        </Group>
    );
};

export default React.memo(CampaignBuilderHeader);
