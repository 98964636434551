import { useLocale } from '@hooks/useLocale';
import {
    type CategoriesWithGroups,
    type CategoriesWithGroupsAndUserPreference,
    type CommunicationChannel,
} from '@lightdash/common';
import { Button, Container, Flex, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PlusCircle, Subtract } from '@phosphor-icons/react';
import SubscriptionDetails from './SubscriptionDetails';

interface SubscriptionComponentProps {
    showTitle: boolean;
    panelWidth: number;
    isViewOnly: boolean;
    allGroups: CategoriesWithGroupsAndUserPreference | CategoriesWithGroups;
    activeTab: CommunicationChannel;
    setActiveTab: (tab: CommunicationChannel) => void;
    isFetchingData: boolean;
    globalPreference: boolean;
    showGlobalPreference: boolean;
}

const SubscriptionComponent: React.FC<SubscriptionComponentProps> = ({
    showTitle,
    panelWidth,
    isViewOnly,
    allGroups,
    activeTab,
    setActiveTab,
    isFetchingData,
    globalPreference,
    showGlobalPreference,
}) => {
    const { t } = useLocale();

    const [opened, { open: OpenModal, close: closeModal }] =
        useDisclosure(false);

    return (
        <Container className="mx-0 !p-0">
            {showTitle && (
                <Flex
                    direction={'row'}
                    justify={'space-between'}
                    align="center"
                    className="mb-4"
                >
                    <Flex align="center" gap="xs">
                        <Subtract weight="duotone" />
                        <Title
                            className="text-lg font-medium text-gray-800 "
                            order={3}
                        >
                            {t('subscription_group')}
                        </Title>
                    </Flex>

                    {!isViewOnly && allGroups && allGroups.length && (
                        <Flex align="center" gap="xs">
                            {/* <Button
                            variant="default"
                            leftIcon={<Question weight="duotone" />}
                        >
                            {t('subscription_groups.preference_center')}
                        </Button> */}
                            <Button
                                variant="filled"
                                leftIcon={<PlusCircle color="#FFF" />}
                                onClick={() => OpenModal()}
                            >
                                {t('subscription_groups.new_group')}
                            </Button>
                        </Flex>
                    )}
                </Flex>
            )}
            <SubscriptionDetails
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                onGroupCreate={() => OpenModal()}
                onClose={() => closeModal()}
                isOpen={opened}
                panelWidth={panelWidth}
                isViewOnly={isViewOnly}
                allGroups={allGroups}
                isFetchingData={isFetchingData}
                globalPreference={globalPreference}
                showGlobalPreference={showGlobalPreference}
            />
        </Container>
    );
};

export default SubscriptionComponent;
