import { useLocale } from '@hooks/useLocale';
import { useContextSelector } from 'use-context-selector';
import AudienceProviderContext from './context';
import { type AudienceContext } from './types';

function useAudienceContext<Selected>(
    selector: (value: AudienceContext) => Selected,
) {
    const { t } = useLocale();
    return useContextSelector(AudienceProviderContext, (context) => {
        if (context === undefined) {
            throw new Error(t('error.audience_context_not_found'));
        }
        return selector(context);
    });
}

export default useAudienceContext;
