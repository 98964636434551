import {
    type SchemaBuilderStep,
    type SchemaConfigStep,
} from '@components/SchemaBuilder/Builder/types';
import {
    type Project,
    type RelationSchemaTableResponse,
    type RelationTableType,
} from '@lightdash/common';

export enum ActionType {
    SET_DATABASE,
    SET_SCHEMA,
    SET_TABLE_NAME,
    SET_CURRENT_TABLE,
    SET_CURRENT_BUILDER_STEP,
    SET_CURRENT_CONFIG_STEP,
    ADD_TABLE,
    EDIT_TABLE,
    TOGGLE_DRAWER,
}

export interface SchemaContext {
    state: SchemaReducerState;
    actions: {
        setDatabase: (database: string) => void;
        setSchema: (schema: string) => void;
        setTableName: (name: string) => void;
        setCurrentTable: (tableDetail: RelationSchemaTableResponse) => void;
        setCurrentBuilderStep: (value: SchemaBuilderStep) => void;
        setCurrentConfigStep: (value: SchemaConfigStep) => void;
        addTable: (table: RelationTableType) => void;
        editTable: (table: RelationSchemaTableResponse) => void;
        toggleDrawer: (open?: boolean) => void;
    };
}

export interface SchemaReducerState {
    schemaPayload: any;
    activeProject: Project | null;
    currentBuilderStep: SchemaBuilderStep;
    currentConfigStep: SchemaConfigStep;
    isEditMode: boolean;
    isDrawerOpen: boolean;
}
