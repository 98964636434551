import Page from '@components/Page/Page';
import PageSpinner from '@components/PageSpinner';
import ProjectSettingsSidebar from '@components/ProjectSettings/Sidebar';
import { useOrganization } from '@hooks/organization/useOrganization';
import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import { Box } from '@mantine/core';
import React from 'react';
import { Outlet } from 'react-router';
import ErrorState from '../components/common/ErrorState';
import { useProject } from '../hooks/useProject';

const ProjectSettings: React.FC = () => {
    const {
        data: organization,
        isInitialLoading: isOrganizationLoading,
        error: organizationError,
    } = useOrganization();
    const { activeProjectUuid, isLoading: isActiveProjectUuidLoading } =
        useActiveProjectUuid();
    const {
        data: project,
        isInitialLoading: isProjectLoading,
        error: projectError,
    } = useProject(activeProjectUuid);
    const { t } = useLocale();

    if (
        isOrganizationLoading ||
        isActiveProjectUuidLoading ||
        isProjectLoading
    ) {
        return <PageSpinner />;
    }

    if (organizationError || projectError) {
        return <ErrorState error={projectError?.error} />;
    }

    if (!organization || !project) return null;

    return (
        <Page
            withFullHeight
            withPaddedContent
            title={t('project_settings.page_title')}
            sidebar={
                <ProjectSettingsSidebar projectUuid={project.projectUuid} />
            }
        >
            <Box className="!w-[57vw]">
                <Outlet />
            </Box>
        </Page>
    );
};

export default React.memo(ProjectSettings);
