import FieldListItem from '@components/Audience/Filters/FieldListItem';
import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import {
    type AddditionalPropertySelectListProps,
    type PropertySelectListType,
} from '@components/common/Select/PropertySelect/type';
import { type JourneyProperty } from '@components/Journeys/Builder/JourneyFilters/types';
import { getItemId } from '@lightdash/common';
import React, { useMemo } from 'react';

interface MentionChipProps {
    id: string;
    warehouseFieldsMap: FieldsWithSuggestions;
    journeyProperties: PropertySelectListType<JourneyProperty>[];
}

const MentionChip: React.FC<MentionChipProps> = ({
    id,
    warehouseFieldsMap,
    journeyProperties,
}) => {
    const idWithoutHandlebars = id.replace('{{', '').replace('}}', '');
    const activeField = useMemo(
        () =>
            warehouseFieldsMap[idWithoutHandlebars] ||
            journeyProperties
                .reduce(
                    (acc, prop) => [...acc, ...prop.items],
                    [] as (JourneyProperty &
                        AddditionalPropertySelectListProps)[],
                )
                .find(
                    (
                        field: JourneyProperty &
                            AddditionalPropertySelectListProps,
                    ) => getItemId(field) === idWithoutHandlebars,
                ),
        [idWithoutHandlebars, warehouseFieldsMap, journeyProperties],
    );

    return (
        <span
            className="mention inline-flex border rounded-lg text-gray-800 bg-white cursor-pointer selection:bg-white selection:shadow-2xl selection:shadow-blu-800 !selection:border-2 "
            style={{
                fontFamily: 'Hanken Grotesk, sans-serif',
            }}
        >
            {activeField?.defaultFieldRender ? (
                <span className="!whitespace-nowrap w-max">
                    {activeField?.defaultFieldRender}
                </span>
            ) : (
                <FieldListItem
                    item={activeField}
                    checked={false}
                    disabled={false}
                    showCheckbox={false}
                    showHoverIcon={false}
                    className="py-0.5"
                />
            )}
        </span>
    );
};

export default MentionChip;
