import {
    convertFieldsIntoPropertySelectListType,
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider/FiltersProvider';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import SkeletonLoader from '@components/common/SkeletonLoader';
import useJourneyFiltersContext from '@components/Journeys/Builder/JourneyFilters/FiltersForm/JourneyFiltersProvider/useJourneyFiltersContext';
import { type JourneyProperty } from '@components/Journeys/Builder/JourneyFilters/types';
import { getJourneyEventPropertySelectList } from '@components/Journeys/Builder/JourneyFilters/useJourneyProperties';
import SearchInput from '@components/SearchInput';
import UpdateTraitsModal from '@components/UpdateTraits/UpdateTraitsModal';
import { sanitizeName } from '@components/UpdateTraits/utils';
import { useLocale } from '@hooks/useLocale';
import { useUpdateTraits } from '@hooks/useUpdateTrait';
import {
    DifferenceOperator,
    type DimensionType,
    type UpdateTraitConfig,
    type UserTraitFieldConfig,
    type UserTraits,
} from '@lightdash/common';
import { Box, Button, Flex, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDebounce } from 'react-use';
import { ButtonVariant } from '../../../../../../mantineTheme';
import EmptyDynamicTraitPage from './EmptyDynamicTraitPage';
import UpdateTraitMenu from './UpdateTraitMenu';
import UpdateTraitValueForm from './UpdateTraitValueForm';
import UpdateTraitListItem from './UpdateTraitValueForm/UpdateTraitListItem';
import { getUpdateTraitConfig } from './utils';

interface TraitSelectionProps {
    searchString: string;
    setSearchString: (value: string) => void;
    openUpdateTraitsModal: () => void;
    filteredTraits: UserTraitFieldConfig[];
    handleChangeTrait: (trait: UserTraitFieldConfig) => void;
}

interface TraitConfigurationProps {
    selectedTrait: UserTraitFieldConfig | undefined;
    updateTraits: UserTraits | undefined;
    isEditable: boolean;
    onFlyCreatedTrait?: UserTraitFieldConfig;
    handleChangeTrait: (trait: UserTraitFieldConfig) => void;
    journeyPropertiesItems: (
        | PropertySelectListType<JourneyProperty>
        | PropertySelectListType<FieldWithSelectStatus, PropertySelectGroupType>
    )[];
    traitConfig: UpdateTraitConfig;
    onUpdateConfigChange: (config: UpdateTraitConfig) => void;
}

export interface UpdateTraitProps {
    traitConfig: UpdateTraitConfig;
    onUpdateConfigChange: (config: UpdateTraitConfig) => void;
    nodeId: string;
}

const TraitSelection: React.FC<TraitSelectionProps> = ({
    searchString,
    setSearchString,
    openUpdateTraitsModal,
    filteredTraits,
    handleChangeTrait,
}) => {
    const { t } = useLocale();
    return (
        <>
            <Flex justify={'space-between'}>
                <SearchInput
                    size="xs"
                    placeholder={t(
                        'journey_builder.update_trait_search_placeholder',
                    )}
                    value={searchString}
                    onChange={(event) =>
                        setSearchString(event.currentTarget.value)
                    }
                />
                <Button
                    variant={ButtonVariant.OUTLINED}
                    leftIcon={<PlusCircle />}
                    onClick={openUpdateTraitsModal}
                >
                    {t('common.new')}
                </Button>
            </Flex>
            <Box className="max-h-[400px] overflow-y-auto">
                {filteredTraits.map((trait) => (
                    <UpdateTraitListItem
                        trait={trait}
                        onSelectTrait={handleChangeTrait}
                        key={trait.label}
                    />
                ))}
            </Box>
        </>
    );
};

const TraitConfiguration: React.FC<TraitConfigurationProps> = ({
    selectedTrait,
    updateTraits,
    isEditable,
    onFlyCreatedTrait,
    handleChangeTrait,
    journeyPropertiesItems,
    traitConfig,
    onUpdateConfigChange,
}) => {
    const { t } = useLocale();
    return (
        <Stack>
            <Text className={'uppercase font-semibold text-sm text-gray-500'}>
                {t('journey_builder.update_trait_select_property_text')}
            </Text>
            {updateTraits && selectedTrait && (
                <UpdateTraitMenu
                    selectedTrait={selectedTrait}
                    onSelectTrait={handleChangeTrait}
                    userTraits={updateTraits}
                    isDisabled={!isEditable}
                    isCreatedOnFly={
                        sanitizeName(onFlyCreatedTrait?.label ?? '') ===
                        sanitizeName(selectedTrait?.label ?? '')
                    }
                />
            )}
            <Text className={'uppercase font-semibold text-sm text-gray-500'}>
                {t('common.update')}
            </Text>
            <UpdateTraitValueForm
                propertyItems={journeyPropertiesItems}
                traitConfig={traitConfig}
                updateTraits={updateTraits}
                onUpdateConfigChange={onUpdateConfigChange}
            />
        </Stack>
    );
};

const UpdateTraitBlock: React.FC<UpdateTraitProps> = ({
    nodeId,
    onUpdateConfigChange,
    traitConfig,
}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [searchString, setSearchString] = useState<string>('');
    const [searchDebounce, setSearchDebounce] = useState<string>('');
    const [onFlyCreatedTrait, setOnFlyCreatedTrait] = useState<
        UserTraitFieldConfig | undefined
    >(undefined);
    const [
        openedUpdateTraitsModal,
        { open: openUpdateTraitsModal, close: closeUpdateTraitsModal },
    ] = useDisclosure(false);
    const {
        warehouseFieldsMap,
        eventsData,
        eventsTableNames,
        audienceData,
        journeyDataSchema,
        warehouseFields,
        isLoading,
    } = useJourneyFiltersContext();
    const { nodes, edges, journeyPayload, isEditable } =
        useJourneyBuilderContext((context) => context.state);
    const { data: updateTraits } = useUpdateTraits(projectUuid);

    const selectedTrait = useMemo(() => {
        return updateTraits?.config.fieldConfig[traitConfig[0].name];
    }, [updateTraits, traitConfig]);
    useDebounce(
        () => {
            setSearchDebounce(searchString);
        },
        300,
        [searchString],
    );
    const journeyProperties = useMemo(
        () =>
            getJourneyEventPropertySelectList({
                journeyDataSchema,
                nodes,
                edges,
                nodeId: nodeId,
                journeyNodes: journeyPayload.config?.nodes ?? [],
            }),
        [journeyDataSchema, nodes, edges, nodeId, journeyPayload],
    );

    const journeyPropertiesItems = useMemo(
        () => [
            ...convertFieldsIntoPropertySelectListType(
                Object.values(warehouseFields),
                false,
            ),
            ...journeyProperties,
        ],
        [warehouseFields, journeyProperties],
    );
    const filteredTraits = useMemo(
        () =>
            Object.values(updateTraits?.config.fieldConfig ?? {}).filter(
                (trait: UserTraitFieldConfig) =>
                    trait.label
                        .toLowerCase()
                        .includes(searchDebounce?.toLowerCase()),
            ),
        [searchDebounce, updateTraits],
    );

    const handleChangeTrait = useCallback(
        (trait: UserTraitFieldConfig) => {
            onUpdateConfigChange([
                {
                    name: sanitizeName(trait.label ?? ''),
                    updateConfig: getUpdateTraitConfig({
                        type: selectedTrait?.type as DimensionType,
                        value: '',
                        differenceValue: '',
                        operator: DifferenceOperator.ADD,
                    }),
                },
            ]);
        },
        [onUpdateConfigChange, selectedTrait?.type],
    );
    const handleTraitCreated = useCallback(
        (trait: UserTraitFieldConfig) => {
            setOnFlyCreatedTrait(trait);
            handleChangeTrait(trait);
        },
        [handleChangeTrait],
    );

    const hasTraitConfig = Boolean(traitConfig?.length && traitConfig[0]?.name);

    if (!projectUuid) return null;

    if (isLoading || (!journeyPropertiesItems.length && !isLoading)) {
        return (
            <Stack className="gap-4 w-[30vw]">
                <SkeletonLoader height={70} />
            </Stack>
        );
    }

    return (
        <FiltersProvider
            projectUuid={projectUuid}
            fieldsMap={warehouseFieldsMap}
            eventsMap={eventsData}
            eventTables={eventsTableNames}
            audienceData={audienceData}
            isLoading={isLoading}
        >
            <Stack>
                {!hasTraitConfig && (
                    <Text
                        className={
                            'uppercase font-semibold text-sm text-gray-500'
                        }
                    >
                        {t('journey_builder.update_trait_select_title_text')}
                    </Text>
                )}
                {filteredTraits.length === 0 ? (
                    <EmptyDynamicTraitPage items={journeyPropertiesItems} />
                ) : (
                    <>
                        {!hasTraitConfig ? (
                            <TraitSelection
                                searchString={searchString}
                                setSearchString={setSearchString}
                                openUpdateTraitsModal={openUpdateTraitsModal}
                                filteredTraits={filteredTraits}
                                handleChangeTrait={handleChangeTrait}
                            />
                        ) : (
                            <TraitConfiguration
                                selectedTrait={selectedTrait}
                                updateTraits={updateTraits}
                                isEditable={isEditable}
                                onFlyCreatedTrait={onFlyCreatedTrait}
                                handleChangeTrait={handleChangeTrait}
                                journeyPropertiesItems={journeyPropertiesItems}
                                traitConfig={traitConfig}
                                onUpdateConfigChange={onUpdateConfigChange}
                            />
                        )}
                    </>
                )}
                <UpdateTraitsModal
                    opened={openedUpdateTraitsModal}
                    onClose={closeUpdateTraitsModal}
                    items={undefined}
                    updateTrait={updateTraits}
                    updateTraitFieldConfig={undefined}
                    isEditMode={isEditable}
                    onTraitCreated={handleTraitCreated}
                />
            </Stack>
        </FiltersProvider>
    );
};

export default UpdateTraitBlock;
