import { Button, type ButtonProps } from '@mantine/core';
import React, { type FC } from 'react';
import { ButtonVariant } from '../../mantineTheme';
import { useNavigate } from 'react-router';
import { type EventData } from '../../providers/Tracking/types';
import useTracking from '../../providers/Tracking/useTracking';

export interface LinkButtonProps extends ButtonProps {
    href: string;
    trackingEvent?: EventData;
    target?: React.HTMLAttributeAnchorTarget;
    forceRefresh?: boolean;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    variant?: ButtonVariant;
    leftIcon?: React.ReactNode;
    className?: string;
}

const LinkButton: FC<LinkButtonProps> = ({
    href,
    target,
    trackingEvent,
    forceRefresh = false,
    onClick,
    variant = ButtonVariant.UNSTYLED,
    leftIcon,
    className,
    ...rest
}) => {
    const navigate = useNavigate();
    const { track } = useTracking();

    return (
        <Button
            component="a"
            compact
            variant={variant}
            href={href}
            target={target}
            leftIcon={leftIcon}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                if (
                    !forceRefresh &&
                    !e.ctrlKey &&
                    !e.metaKey &&
                    target !== '_blank'
                ) {
                    e.preventDefault();
                    void navigate(href);
                }

                onClick?.(e);

                if (trackingEvent) track(trackingEvent);
            }}
            className={className}
            {...rest}
        />
    );
};

export default LinkButton;
