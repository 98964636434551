import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type ApiSuccessEmpty,
    type SaveBlobCredentials,
} from '@lightdash/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';

const createBlob = async (projectId: string, data: SaveBlobCredentials) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/blob-configurations`,
        method: 'POST',
        body: JSON.stringify(data),
    });
const updateBlob = async (projectId: string, data: SaveBlobCredentials) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/blob-configurations`,
        method: 'PUT',
        body: JSON.stringify(data),
    });
export const useCreateBlobMutation = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastError, showToastSuccess } = useNotify();
    return useMutation<ApiSuccessEmpty, ApiError, SaveBlobCredentials>(
        (data) => createBlob(projectUuid, data),
        {
            mutationKey: [QueryKeys.BLOB_CREATE], //TODO: Move these signatures in a common file
            onSuccess: async () => {
                showToastSuccess({
                    title: 'Blob created successfully',
                });
            },
            onError: (error) => {
                showToastError({
                    title: `Failed to create blob`,
                    subtitle: error.error.message,
                });
            },
        },
    );
};

export const useUpdateBlobMutation = () => {
    const queryClient = useQueryClient();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastError, showToastSuccess } = useNotify();
    return useMutation<ApiSuccessEmpty, ApiError, SaveBlobCredentials>(
        (data) => updateBlob(projectUuid, data),
        {
            mutationKey: [QueryKeys.BLOB_UPDATE],
            onSuccess: async () => {
                await queryClient.invalidateQueries(['project', projectUuid]);
                showToastSuccess({
                    title: 'Blob updated successfully',
                });
            },
            onError: (error) => {
                showToastError({
                    title: 'Failed to update blob',
                    subtitle: error.error.message,
                });
            },
        },
    );
};
