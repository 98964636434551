import { SortmentLogo } from '@assets/icons/Core';
import Page from '@components/Page/Page';
import { SuccessIconBounce } from '@components/RegisterForms/ProjectConnectFlow.styles';
import {
    Box,
    Button,
    Modal,
    Stack,
    Title,
    useMantineTheme,
} from '@mantine/core';
import { IconCircleCheckFilled } from '@tabler/icons-react';
import { type FC } from 'react';
import { useNavigate } from 'react-router';
import PageSpinner from '../components/PageSpinner';
import VerifyEmailForm from '../components/RegisterForms/VerifyEmailForm';
import { useEmailStatus } from '../hooks/useEmailVerification';
import useApp from '../providers/App/useApp';

const VerificationSuccess: FC<{
    isOpen: boolean;
    onClose: () => void;
    onContinue: () => void;
}> = ({ isOpen, onClose, onContinue }) => {
    const theme = useMantineTheme();
    return (
        <Modal
            size="sm"
            opened={isOpen}
            onClose={onClose}
            withCloseButton={false}
        >
            <Stack align="center" my="md">
                <Title order={3}>Great, you're verified! 🎉</Title>

                <SuccessIconBounce
                    icon={IconCircleCheckFilled}
                    size={64}
                    style={{
                        color: theme.colors.green[6],
                    }}
                />
                <Button onClick={onContinue}>Continue</Button>
            </Stack>
        </Modal>
    );
};

const VerifyEmailPage: FC = () => {
    const { health } = useApp();
    const { data, isInitialLoading: statusLoading } = useEmailStatus(
        !!health.data?.isAuthenticated,
    );
    // const { show: showIntercom } = useIntercom();
    const navigate = useNavigate();

    if (health.isInitialLoading || statusLoading) {
        return <PageSpinner />;
    }

    return (
        <Page
            title="Verify your email"
            withCenteredContent
            withNavbar={false}
            withFullHeight
        >
            <section className="min-w-[300px] px-3 pt-6 pb-3 bg-white flex flex-col items-center border-base gap-3.5 rounded-3xl">
                <div className="flex flex-col items-center w-full gap-2">
                    <SortmentLogo />
                </div>
                <VerifyEmailForm />

                {data && (
                    <VerificationSuccess
                        isOpen={data.isVerified}
                        onClose={() => {
                            void navigate('/');
                        }}
                        onContinue={() => {
                            void navigate('/');
                        }}
                    />
                )}
            </section>
        </Page>
    );
};

export const VerifyEmailModal: FC<{
    opened: boolean;
    onClose: () => void;
    isLoading: boolean;
}> = ({ opened, onClose, isLoading }) => {
    return (
        <Modal opened={opened} onClose={onClose}>
            <Box my="md">
                <VerifyEmailForm isLoading={isLoading} />
            </Box>
        </Modal>
    );
};

export default VerifyEmailPage;
