import { useContext } from 'react';
import JourneyFiltersProviderContext from './context';
import { type JourneyFiltersContext } from './types';

export default function useJourneyFiltersContext(): JourneyFiltersContext {
    const context = useContext(JourneyFiltersProviderContext);
    if (context === undefined) {
        throw new Error(
            'useJourneyFiltersContext must be used within a JourneyFiltersProvider',
        );
    }
    return context;
}
