import TextInput from '@components/common/Inputs/TextInput';
import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import { Source } from '@components/ProjectSettings/EventSourcePanel/types';
import { useCreateEventSource } from '@hooks/useEvents';
import { useLocale } from '@hooks/useLocale';
import { type SourceEventKeyMapping } from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { CheckCircle } from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';

interface WebhookModalProps {
    showWebhookModal: boolean;
    webhookModalClose: () => void;
    eventSource: Source;
    onSourceAdded: (item: SourceEventKeyMapping) => void;
}

interface FormData {
    sourceName: string;
    description: string;
    eventKey: string;
}

enum ReservedSourceNames {
    FYNO = 'fyno',
}

const WebhookModal: React.FC<WebhookModalProps> = ({
    showWebhookModal,
    webhookModalClose,
    eventSource,
    onSourceAdded,
}) => {
    const { t } = useLocale();
    const queryClient = useQueryClient();
    const {
        mutateAsync: mutateAddSource,
        isLoading: isAddSourceLoading,
        data: addedSourceData,
    } = useCreateEventSource();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<FormData>();

    useEffect(() => {
        if (addedSourceData) {
            onSourceAdded(addedSourceData);
        }
    }, [addedSourceData, onSourceAdded]);

    const onSubmit = useCallback(
        async (data: FormData) => {
            if (
                data.sourceName.trim().length === 0 ||
                data.sourceName.toLowerCase() === ReservedSourceNames.FYNO
            ) {
                setError('sourceName', {});
                return;
            }

            if (data.eventKey.trim().length === 0) {
                setError('eventKey', {});
                return;
            }

            await mutateAddSource({
                payload: {
                    description: data.description.trim(),
                    source: data.sourceName.trim(),
                    eventNameKeys: [`$.${data.eventKey.trim()}`],
                },
            });
            await queryClient.invalidateQueries([
                QueryKeys.GET_ALL_EVENT_SOURCES,
            ]);
            webhookModalClose();
        },
        [mutateAddSource, queryClient, setError, webhookModalClose],
    );

    const getContent = useMemo(() => {
        switch (eventSource) {
            case Source.WEBHOOK:
                return (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box className="mb-3">
                            <Text className="mb-1.5">
                                {t('event_source.name_this_source')}
                            </Text>
                            <TextInput
                                {...register('sourceName', { required: true })}
                                error={
                                    errors.sourceName &&
                                    t('subscription_group.invalid_name')
                                }
                                placeholder={t('event_source.name_this_source')}
                            />
                        </Box>
                        <Box className="mb-3">
                            <Flex className="mb-1.5">
                                <Text>
                                    {t(
                                        'custom_metric.dimension_modal_description_label',
                                    )}
                                </Text>
                                <Text className="ml-1 text-gray-600">
                                    {t(
                                        'custom_metric.dimension_modal_description_label_optional',
                                    )}
                                </Text>
                            </Flex>
                            <TextInput
                                {...register('description')}
                                placeholder={t(
                                    'event_source.add_a_description',
                                )}
                            />
                        </Box>
                        <Box className="w-full">
                            <Text className="mb-1.5">
                                {t('event_source.event_name_key_path')}
                            </Text>
                            <TextInput
                                {...register('eventKey', { required: true })}
                                error={
                                    errors.eventKey &&
                                    t('event_source.invalid_key')
                                }
                                className="w-full mr-2"
                                placeholder={t(
                                    'event_source.enter_event_key_name',
                                )}
                            />
                        </Box>
                    </form>
                );

            default:
                break;
        }
    }, [
        eventSource,
        handleSubmit,
        onSubmit,
        t,
        register,
        errors.sourceName,
        errors.eventKey,
    ]);

    return (
        <Modal
            opened={showWebhookModal}
            onClose={() => webhookModalClose()}
            keepMounted={false}
            title={t('event_source.connect_to_a_webhook')}
            size="lg"
            footerRightSection={
                <ModalFooter
                    showSecondaryButton={true}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    secondaryButtonClick={webhookModalClose}
                    secondaryText={t(
                        'custom_metric.dimension_modal_cancel_button',
                    )}
                    showPrimaryButton={true}
                    primaryButtonClick={handleSubmit(onSubmit)}
                    primaryText={t('common.save')}
                    primaryLeftIcon={
                        <CheckCircle
                            color="white"
                            size={14}
                            strokeWidth={2.5}
                        />
                    }
                    isLoading={isAddSourceLoading}
                    primaryRightIcon={undefined}
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={undefined}
                    primaryButtonDisabled={undefined}
                    primaryButtonCustomClass={undefined}
                />
            }
        >
            {getContent}
        </Modal>
    );
};

export default WebhookModal;
