import { useLocale } from '@hooks/useLocale';
import {
    isCustomSqlDimension,
    isTableCalculation,
    QueryGenerationStrategy,
    type DimensionType,
} from '@lightdash/common';
import { type ColumnDef } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import { useFieldsWithSuggestions } from '../Filters/FiltersCard/useFieldsWithSuggestions';
import AudiencePreviewCell from './AudiencePreviewCell';

export function useAudiencePreviewColumns(
    fields: Record<string, { type: DimensionType }>,
    generationStrategy: string,
) {
    const [columns, setColumns] =
        useState<ColumnDef<Record<string, unknown>>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });

    useEffect(() => {
        if (projectUuid && fields) {
            if (!fieldsWithSuggestions || isEmpty(fieldsWithSuggestions))
                return;

            setColumns(
                Object.keys(fields).map((item) => {
                    const field = fieldsWithSuggestions[item];

                    return {
                        accessorKey: item,
                        header:
                            generationStrategy ===
                            QueryGenerationStrategy.AUDIENCE_BUILDER
                                ? isTableCalculation(field) ||
                                  isCustomSqlDimension(field)
                                    ? item
                                    : field?.label ?? item
                                : item,
                        cell: ({ getValue }) => {
                            const value = getValue();

                            return (
                                <AudiencePreviewCell<typeof value>
                                    value={value}
                                />
                            );
                        },
                    };
                }),
            );
        }
    }, [projectUuid, t, fields, fieldsWithSuggestions, generationStrategy]);

    return { columns, setColumns };
}
