import { FileCsv, FolderOpen, Subtract } from '@phosphor-icons/react';
import React from 'react';
import { LabelIconType } from './types';

const LabelIcon: React.FC<{
    name: LabelIconType | undefined;
    className?: string;
}> = ({ name, className }) => {
    switch (name) {
        case LabelIconType.CSV:
            return (
                <FileCsv
                    className={className}
                    size={14}
                    color="rgb(var(--color-gray-700)"
                />
            );
        case LabelIconType.CATEGORY:
            return <FolderOpen className={className} weight="duotone" />;
        case LabelIconType.GROUP:
        default:
            return <Subtract className={className} weight="duotone" />;
    }
};

export default LabelIcon;
