import { useContextSelector } from 'use-context-selector';
import SchemaProviderContext from './context';
import { type SchemaContext } from './types';

function useSchemaContext<Selected>(
    selector: (value: SchemaContext) => Selected,
) {
    return useContextSelector(SchemaProviderContext, (context) => {
        if (context === undefined) {
            throw new Error(
                'useExplorer must be used within a ExplorerProvider',
            );
        }

        return selector(context);
    });
}

export default useSchemaContext;
