import {
    type CommunicationChannel,
    type CreateTemplateRequest,
    type Integration,
    type ProviderTemplateDetails,
} from '@lightdash/common';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
import TemplateBuilderProviderContext from './context';
import {
    ActionType,
    type EmailBuilderType,
    type TemplateBuilderState,
} from './types';

type Action =
    | { type: ActionType.SET_OPEN_LIBRARY_MODAL; payload: boolean }
    | { type: ActionType.SET_OPEN_IMPORT_MODAL; payload: boolean }
    | { type: ActionType.SET_OPEN_CREATE_TEMPLATE_BUILDER; payload: boolean }
    | {
          type: ActionType.SET_TEMPLATE_PAYLOAD;
          payload: Partial<CreateTemplateRequest>;
      }
    | { type: ActionType.SET_SELECTED_INTEGRATION; payload: Integration | null }
    | {
          type: ActionType.SET_SELECTED_TEMPLATE;
          payload: ProviderTemplateDetails | null;
      }
    | {
          type: ActionType.SET_EMAIL_BUILDER_TYPE;
          payload: EmailBuilderType;
      };

function reducer(
    state: TemplateBuilderState,
    action: Action,
): TemplateBuilderState {
    switch (action.type) {
        case ActionType.SET_OPEN_LIBRARY_MODAL:
            return { ...state, openLibraryModal: action.payload };
        case ActionType.SET_OPEN_IMPORT_MODAL:
            return { ...state, openImportModal: action.payload };
        case ActionType.SET_OPEN_CREATE_TEMPLATE_BUILDER:
            return { ...state, openCreateTemplateModal: action.payload };
        case ActionType.SET_TEMPLATE_PAYLOAD:
            return {
                ...state,
                templatePayload: {
                    ...state.templatePayload,
                    ...action.payload,
                },
            };
        case ActionType.SET_SELECTED_INTEGRATION: {
            return {
                ...state,
                selectedIntegration: action.payload,
            };
        }
        case ActionType.SET_SELECTED_TEMPLATE: {
            return {
                ...state,
                selectedTemplate: action.payload,
            };
        }
        case ActionType.SET_EMAIL_BUILDER_TYPE: {
            return {
                ...state,
                emailBuilderType: action.payload,
            };
        }
        default:
            throw new Error('Unhandled action type');
    }
}

const TemplateBuilderProvider: React.FC<
    React.PropsWithChildren<{
        initialState: TemplateBuilderState;
        channel: CommunicationChannel;
        templateId: string;
        handleTemplateSelectCallback: (value: ProviderTemplateDetails) => void;
    }>
> = ({
    initialState,
    children,
    channel,
    templateId,
    handleTemplateSelectCallback,
}) => {
    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const [templateUuid, setTemplateUuid] = useState<string>(templateId);

    const setOpenLibraryModal = useCallback((open: boolean) => {
        dispatch({
            type: ActionType.SET_OPEN_LIBRARY_MODAL,
            payload: open,
        });
    }, []);

    const setOpenImportModal = useCallback((open: boolean) => {
        dispatch({ type: ActionType.SET_OPEN_IMPORT_MODAL, payload: open });
    }, []);

    const setOpenCreateTemplateModal = useCallback((open: boolean) => {
        dispatch({
            type: ActionType.SET_OPEN_CREATE_TEMPLATE_BUILDER,
            payload: open,
        });
    }, []);

    const setTemplatePayload = useCallback(
        (values: Partial<CreateTemplateRequest>) => {
            dispatch({
                type: ActionType.SET_TEMPLATE_PAYLOAD,
                payload: values,
            });
        },
        [],
    );

    const setTemplateId = useCallback((value: string) => {
        setTemplateUuid(value);
    }, []);

    const setSelectedIntegration = useCallback(
        (payload: Integration | null) => {
            dispatch({
                type: ActionType.SET_SELECTED_INTEGRATION,
                payload,
            });
        },
        [],
    );

    const setSelectedTemplate = useCallback(
        (payload: ProviderTemplateDetails | null) => {
            dispatch({
                type: ActionType.SET_SELECTED_TEMPLATE,
                payload,
            });
        },
        [],
    );

    const setEmailBuilderType = useCallback((payload: EmailBuilderType) => {
        dispatch({
            type: ActionType.SET_EMAIL_BUILDER_TYPE,
            payload,
        });
    }, []);

    const state = useMemo(
        () => ({
            ...reducerState,
            channel,
            templateId: templateUuid,
            initialPayload: initialState.templatePayload,
        }),
        [reducerState, channel, templateUuid, initialState],
    );

    const actions = useMemo(
        () => ({
            setOpenLibraryModal,
            setOpenImportModal,
            setOpenCreateTemplateModal,
            setTemplatePayload,
            setTemplateId,
            handleTemplateSelect: handleTemplateSelectCallback,
            setSelectedIntegration,
            setSelectedTemplate,
            setEmailBuilderType,
        }),
        [
            setOpenLibraryModal,
            setOpenImportModal,
            setOpenCreateTemplateModal,
            setTemplatePayload,
            setTemplateId,
            handleTemplateSelectCallback,
            setSelectedIntegration,
            setSelectedTemplate,
            setEmailBuilderType,
        ],
    );

    const value = useMemo(
        () => ({
            state,
            actions,
        }),
        [actions, state],
    );

    return (
        <TemplateBuilderProviderContext.Provider value={value}>
            {children}
        </TemplateBuilderProviderContext.Provider>
    );
};

export default TemplateBuilderProvider;
