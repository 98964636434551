import TimeInputWithOptions from '@components/common/Time/TimeInputWithOptions';
import { useLocale } from '@hooks/useLocale';
import {
    PeriodType as PeriodTypeEnum,
    type BaseTrigger,
    type ConversionTrigger,
    type PeriodType,
} from '@lightdash/common';
import { Group, Stack, Text } from '@mantine/core';
import { Gauge } from '@phosphor-icons/react';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import BaseTriggerEventFilter from '../BaseTriggerEventFilter';

export interface ConversionTrackingProps {
    nodeId: string;
    conversionEvent: ConversionTrigger;
    onConversionTrackingChange: (config: ConversionTrigger) => void;
}

const ConversionTracking: React.FC<ConversionTrackingProps> = ({
    nodeId,
    conversionEvent,
    onConversionTrackingChange,
}) => {
    const { t } = useLocale();

    const errorMessage = useMemo(() => {
        if (
            conversionEvent.deadline === null ||
            conversionEvent.deadline === undefined ||
            Number.isNaN(conversionEvent.deadline)
        ) {
            return t('common.error.empty_text_input');
        }
        if (conversionEvent.deadline === 0) {
            return t('duration_delay.enter_a_valid_value');
        }
        return '';
    }, [conversionEvent.deadline, t]);

    const handleConversionEventChange = useCallback(
        ({ eventName, eventSource }: BaseTrigger) => {
            onConversionTrackingChange({
                ...conversionEvent,
                eventName,
                eventSource,
            });
        },
        [onConversionTrackingChange, conversionEvent],
    );

    const handleConversionWindowChange = useCallback(
        (deadline: number, granularity: PeriodType) => {
            onConversionTrackingChange({
                ...conversionEvent,
                deadline,
                uiConfig: {
                    granularity,
                },
            });
        },
        [onConversionTrackingChange, conversionEvent],
    );

    return (
        <Stack className="gap-3">
            <Group className="gap-1">
                <Gauge size={14} color="rgb(var(--color-gray-500))" />
                <Text className="text-xs font-medium text-gray-500 uppercase">
                    {t(
                        'journey_builder.experiment.conversion_tracking_section_header',
                    )}
                </Text>
            </Group>

            <Stack className="gap-1">
                <Text className="text-sm font-medium text-gray-800">
                    {t(
                        'journey_builder.experiment.conversion_tracking_section_conversion_event',
                    )}
                </Text>
                <BaseTriggerEventFilter
                    nodeId={nodeId}
                    eventConfig={{
                        eventName: conversionEvent.eventName,
                        eventSource: conversionEvent.eventSource,
                    }}
                    setEvent={(eventName, eventSource) => {
                        handleConversionEventChange({
                            id: uuidv4(),
                            eventName,
                            eventSource,
                        });
                    }}
                    filters={undefined}
                    setFilters={() => {}}
                />
            </Stack>

            <Stack className="gap-1">
                <Text className="text-sm font-medium text-gray-800">
                    {t(
                        'journey_builder.experiment.conversion_tracking_section_tracking_window',
                    )}
                </Text>
                <TimeInputWithOptions
                    selectedDuration={conversionEvent.deadline}
                    onDurationChange={handleConversionWindowChange}
                    selectedGranularity={
                        conversionEvent.uiConfig?.granularity ??
                        PeriodTypeEnum.DAY
                    }
                    granularityOptions={[
                        PeriodTypeEnum.MINUTE,
                        PeriodTypeEnum.HOUR,
                        PeriodTypeEnum.DAY,
                        PeriodTypeEnum.WEEK,
                    ]}
                    focusOnMount={false}
                    onBlur={(value) => {
                        if (Number.isNaN(value)) {
                            console.log('onBlur', value);
                        }
                    }}
                    error={errorMessage}
                    disabled={false}
                />
            </Stack>
        </Stack>
    );
};

export default React.memo(ConversionTracking);
