import { useContext } from 'react';
import ProjectProviderContext from './context';
import { type ProjectContextType } from './types';

function useProjectContext(): ProjectContextType {
    const context = useContext(ProjectProviderContext);
    if (context === undefined) {
        throw new Error(
            'useProjectContext must be used within a ProjectProvider',
        );
    }
    return context;
}

export default useProjectContext;
