import {
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import FieldSelect from '@components/common/Select/FieldSelect';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import useJourneyFiltersContext from '@components/Journeys/Builder/JourneyFilters/FiltersForm/JourneyFiltersProvider/useJourneyFiltersContext';
import { type JourneyProperty } from '@components/Journeys/Builder/JourneyFilters/types';
import { getJourneyEventPropertySelectList } from '@components/Journeys/Builder/JourneyFilters/useJourneyProperties';
import { isCachedField } from '@components/Journeys/Builder/JourneyFilters/utils';
import { useNodeData } from '@components/Journeys/Builder/ReactFlow/Nodes/useNodeData';
import { useFilterFields } from '@hooks/useFilterFields';
import { useLocale } from '@hooks/useLocale';
import { createFilterRuleFromField } from '@lightdash/common';
import { Flex, Text } from '@mantine/core';
import { SplitHorizontal } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { sortRelationFields } from '@utils/relation';
import React, { useCallback, useMemo } from 'react';
import { updateAllFilterRulesInBranchConfig } from '../utils';

interface SplitOnProps {
    nodeId: string;
}

const SplitOn: React.FC<SplitOnProps> = ({ nodeId }) => {
    const { t } = useLocale();
    const { dimensions } = useFilterFields();
    const { journeyDataSchema, eventsData } = useJourneyFiltersContext();
    const { journeyNodeData } = useNodeData(nodeId);
    const { state, actions } = useJourneyBuilderContext((context) => context);
    const { updateBranchConfig, updateSplitActiveFields } = actions;
    const { splitActiveFields } = state;

    const { nodes, edges, journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const handleChangeInField = useCallback(
        (field: FieldWithSuggestions) => {
            const filterRule = createFilterRuleFromField(field);

            const updatedBranchConfig = updateAllFilterRulesInBranchConfig(
                journeyNodeData?.branchConfig,
                filterRule,
                journeyDataSchema
                    ? isCachedField(field as JourneyProperty, journeyDataSchema)
                    : false,
            );
            console.log('updatedBranchConfig', updatedBranchConfig);
            if (updatedBranchConfig) {
                updateBranchConfig(nodeId, updatedBranchConfig);

                updateSplitActiveFields(
                    nodeId,
                    field,
                    journeyDataSchema
                        ? isCachedField(
                              field as JourneyProperty,
                              journeyDataSchema,
                          )
                        : false,
                );
            }
        },
        [
            nodeId,
            updateSplitActiveFields,
            updateBranchConfig,
            journeyNodeData?.branchConfig,
            journeyDataSchema,
        ],
    );
    const journeyEventsPropertySelectList = useMemo(() => {
        if (!eventsData || !eventsData.length || !journeyDataSchema) return [];
        return getJourneyEventPropertySelectList({
            journeyDataSchema,
            nodes,
            edges,
            nodeId,
            journeyNodes: journeyPayload.config?.nodes ?? [],
        });
    }, [eventsData, journeyDataSchema, nodes, edges, journeyPayload, nodeId]);
    return (
        <Flex direction="column" className="gap-2">
            <Flex gap={4} align="center" className="p-3">
                <SplitHorizontal weight="duotone" />
                <Text className="text-sm font-medium text-gray-500 uppercase">
                    {t('journey_builder.split_on_title')}
                </Text>
            </Flex>
            <FieldSelect
                size="xs"
                item={splitActiveFields?.[nodeId]?.field}
                items={sortRelationFields([...dimensions])}
                onChange={handleChangeInField}
                placeholder={t('journey_builder.journey_filters_select_field')}
                isDisabled={true}
                additionalPropertySelectList={
                    journeyEventsPropertySelectList as PropertySelectListType<
                        FieldWithSelectStatus,
                        PropertySelectGroupType
                    >[]
                }
            />
        </Flex>
    );
};

export default React.memo(SplitOn);
