import {
    type CommunicationChannel,
    type CreateTemplateRequest,
    type Integration,
    type ProviderTemplateDetails,
} from '@lightdash/common';

export enum EmailBuilderType {
    BEEFREE = 'BEEFREE',
    GRAPES = 'GRAPES',
}

export interface TemplateBuilderContext {
    state: TemplateBuilderState;
    actions: {
        setOpenLibraryModal: (open: boolean) => void;
        setOpenImportModal: (open: boolean) => void;
        setOpenCreateTemplateModal: (open: boolean) => void;
        setTemplatePayload: (values: Partial<CreateTemplateRequest>) => void;
        setTemplateId: (templateId: string) => void;
        handleTemplateSelect: (value: ProviderTemplateDetails) => void;
        setSelectedIntegration: (value: Integration | null) => void;
        setSelectedTemplate: (value: ProviderTemplateDetails | null) => void;
        setEmailBuilderType: (value: EmailBuilderType) => void;
    };
}

export interface TemplateBuilderState {
    openLibraryModal: boolean;
    openImportModal: boolean;
    openCreateTemplateModal: boolean;
    templatePayload: CreateTemplateRequest;
    channel: CommunicationChannel;
    templateId: string;
    initialPayload: CreateTemplateRequest;
    selectedIntegration: Integration | null;
    selectedTemplate: ProviderTemplateDetails | null;
    version: number | undefined;
    emailBuilderType: EmailBuilderType;
}

export enum ActionType {
    SET_OPEN_LIBRARY_MODAL,
    SET_OPEN_IMPORT_MODAL,
    SET_OPEN_EMAIL_BUILDER,
    SET_OPEN_CREATE_TEMPLATE_BUILDER,
    SET_TEMPLATE_PAYLOAD,
    HANDLE_TEMPLATE_SELECT,
    SET_SELECTED_INTEGRATION,
    SET_SELECTED_TEMPLATE,
    SET_EMAIL_BUILDER_TYPE,
}
