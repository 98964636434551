import Timestamp from '@components/common/Timestamp';
import UserCount from '@components/common/UserCount';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceRunStatus,
    AudienceStatus,
    CommonReservedTags,
    type Audience,
} from '@lightdash/common';
import { Box, Flex, Group, Loader as LoaderIcon, Text } from '@mantine/core';
import { Archive } from '@phosphor-icons/react';
import { type ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AudienceMenuItem } from './AudienceMenuItem';

const AUDIENCE_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const AUDIENCE_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;
const AUDIENCE_TABLE_CELL_DESCRIPTION = `text-xs font-normal text-gray-600 truncate !w-56`;

export function useAudienceColumns(
    setModalOpen: () => void,
    setAudienceData: (data: Audience) => void,
) {
    const [columns, setColumns] = useState<ColumnDef<Audience>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('audience_manager.th_audience_name'),
                    cell: ({ row, getValue }) => (
                        <Box className={AUDIENCE_TABLE_CELL_CLASSNAME}>
                            <Text>
                                <Flex align={'center'} gap={6}>
                                    {row.original.tags?.includes(
                                        CommonReservedTags.HIDDEN,
                                    ) && <Archive weight="duotone" />}
                                    <Box className={AUDIENCE_TABLE_CELL_NAME}>
                                        {row.original.status ===
                                            AudienceStatus.DRAFT && (
                                            <span className="text-pink-800">
                                                {t(
                                                    'audience_manager.tr_audience_status_draft',
                                                )}{' '}
                                            </span>
                                        )}

                                        {getValue<string>()}
                                    </Box>
                                </Flex>
                            </Text>
                            {row.original.description && (
                                <Text
                                    className={AUDIENCE_TABLE_CELL_DESCRIPTION}
                                >
                                    {row.original.description}
                                </Text>
                            )}
                        </Box>
                    ),
                },
                {
                    accessorKey: 'totalCount',
                    header: t('audience_manager.th_audience_count'),
                    cell: ({ getValue }) => (
                        <Box className="">
                            <UserCount
                                count={getValue<string>()}
                                withRightSection={false}
                            />
                        </Box>
                    ),
                },
                {
                    accessorKey: 'lastRunAt',
                    header: t('audience_manager.th_last_run'),
                    cell: ({ row, getValue }) => {
                        const { original } = row;
                        const { lastRunStatus } = original;

                        let content;
                        switch (lastRunStatus) {
                            case AudienceRunStatus.RUNNING:
                                content = (
                                    <Group className="gap-1.5">
                                        <LoaderIcon
                                            color="rgb(var(--color-blu-800))"
                                            size={14}
                                        />
                                        <Text className="text-sm font-normal text-blu-800">
                                            {t(
                                                'campaign_manager.tr_status_progress',
                                            )}
                                        </Text>
                                    </Group>
                                );
                                break;
                            case AudienceRunStatus.NEVER:
                                content = (
                                    <Text className="text-sm font-normal text-gray-600">
                                        {t('timestamp.undefined')}
                                    </Text>
                                );
                                break;
                            case AudienceRunStatus.FAILED:
                                content = (
                                    <Box className="flex flex-row items-center justify-start gap-1">
                                        <Text className="text-sm font-normal text-halt-800">
                                            {t(
                                                'audience_manager.tr_last_run_failed',
                                            )}
                                        </Text>
                                        <Timestamp
                                            timestamp={getValue<string>()}
                                        />
                                    </Box>
                                );
                                break;
                            case AudienceRunStatus.SUCCESS:
                                content = (
                                    <Timestamp timestamp={getValue<string>()} />
                                );
                                break;
                            case AudienceRunStatus.SCHEDULED:
                                content = (
                                    <Timestamp timestamp={getValue<string>()} />
                                );
                                break;
                            default:
                                content = (
                                    <Text className="text-sm font-normal text-gray-600">
                                        {t('timestamp.undefined')}
                                    </Text>
                                );
                        }
                        if (!original.lastRunAt) {
                            content = (
                                <Text className="text-sm font-normal text-gray-600">
                                    {t('timestamp.undefined')}
                                </Text>
                            );
                        }
                        return (
                            <Box className="text-sm text-gray-800">
                                {content}
                            </Box>
                        );
                    },
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`${
                                    row.original.createdBy?.firstName ||
                                    row.original.createdBy?.lastName
                                        ? 'by'
                                        : ''
                                } ${row.original.createdBy?.firstName || ''} ${
                                    row.original.createdBy?.lastName || ''
                                }`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'updatedAt',
                    header: t('common.modified_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />

                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`${
                                    row.original.updatedBy?.firstName ||
                                    row.original.updatedBy?.lastName
                                        ? 'by'
                                        : ''
                                } ${row.original.updatedBy?.firstName || ''} ${
                                    row.original.updatedBy?.lastName || ''
                                }`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => (
                        <AudienceMenuItem
                            setModalOpen={setModalOpen}
                            rowData={row}
                            setAudienceData={setAudienceData}
                        />
                    ),
                },
            ]);
        }
    }, [projectUuid, t, setAudienceData, setModalOpen]);

    return columns;
}
