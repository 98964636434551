import useSchemaContext from '@providers/Schema/useSchemaContext';
import React, { useMemo } from 'react';
import { SchemaBuilderSteps } from '../../utils';

export type TableConfigurationForm = {
    name: string;
    label: string;
    description: string | undefined;
    userId: string;
    primaryKey: string;
};

const SchemaTableConfiguration: React.FC<{}> = () => {
    const { currentBuilderStep } = useSchemaContext((context) => context.state);

    const CurrentStepComponent = useMemo(
        () =>
            SchemaBuilderSteps.find((step) => step.key === currentBuilderStep)
                ?.component,
        [currentBuilderStep],
    );

    if (!CurrentStepComponent) {
        return null;
    }

    return <CurrentStepComponent />;
};

export default React.memo(SchemaTableConfiguration);
