import { useContext } from 'react';
import FiltersProviderContext from './context';
import { type FiltersContext } from './types';

function useFiltersContext(): FiltersContext {
    const context = useContext(FiltersProviderContext);
    if (context === undefined) {
        throw new Error(
            'useFiltersContext must be used within a FiltersProvider',
        );
    }
    return context;
}

export default useFiltersContext;
