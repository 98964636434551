import { sortmentApi } from '@api/index';
import { type ApiResponse } from '@lightdash/common';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const useFileUpload = <T extends ApiResponse>(
    url = '/templates/upload-file',
) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    const { t } = useLocale();

    return useMutation({
        mutationFn: (file: FileList) => {
            const formData = new FormData();
            formData.append('file', file[0]);
            return sortmentApi<T>({
                url: `/projects/${projectUuid}${url}`,
                method: 'POST',
                body: formData,
                headers: null,
            });
        },
        mutationKey: [QueryKeys.UPLOAD_FILE, projectUuid],
        onError: () => {
            showToastError({
                title: t('use_file_upload.error'),
            });
        },
    });
};

export default useFileUpload;
