export type HtmlTemplateDetails = {
    id?: string | null;
    name: string;
    html: string;
    unsubscribeBlock: boolean;
    json?: Record<string, any>;
    description?: string;
    tags?: string[];
};

export enum CssValues {
    NORMAL = 'normal',
    ITALIC = 'italic',
    UNDERLINE = 'underline',
    LINE_THROUGH = 'line-through',
    JUSTIFY = 'justify',
    RIGHT = 'right',
    LEFT = 'left',
    CENTER = 'center',
    NONE = 'none',
}

export enum CssProperties {
    COLOR = 'color',
    FONT_FAMILY = 'font-family',
    FONT_WEIGHT = 'font-weight',
    FONT_SIZE = 'font-size',
    TEXT_ALIGN = 'text-align',
    TEXT_DECORATION = 'text-decoration',
    FONT_STYLE = 'font-style',
    VERTICAL_ALIGN = 'vertical-align',
    BACKGROUND_COLOR = 'background-color',
    BORDER = 'border',
    BORDER_WIDTH = 'border-width',
    BORDER_COLOR = 'border-color',
    BORDER_RADIUS = 'border-radius',
    BORDER_BOTTOM_LEFT_RADIUS = 'border-bottom-left-radius',
    BORDER_TOP_LEFT_RADIUS = 'border-top-left-radius',
    BORDER_TOP_RIGHT_RADIUS = 'border-top-right-radius',
    BORDER_BOTTOM_RIGHT_RADIUS = 'border-bottom-right-radius',
    LETTER_SPACING = 'letter-spacing',
    LINE_HEIGHT = 'line-height',
    MAX_HEIGHT = 'max-height',
    MAX_WIDTH = 'max-width',
    PADDING = 'padding',
    MARGIN = 'margin',
    PADDING_LEFT = 'padding-left',
    PADDING_RIGHT = 'padding-right',
    PADDING_TOP = 'padding-top',
    PADDING_BOTTOM = 'padding-bottom',
    MARGIN_LEFT = 'margin-left',
    MARGIN_RIGHT = 'margin-right',
    MARGIN_TOP = 'margin-top',
    MARGIN_BOTTOM = 'margin-bottom',
    HEIGHT = 'height',
    WIDTH = 'width',
}

export enum GrapesFieldTypes {
    COLOR = 'color',
    NUMBER = 'number',
    RADIO = 'radio',
    SELECT = 'select',
    COMPOSITE = 'composite',
    STACK = 'stack',
}
