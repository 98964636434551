import { CampaignAnalyticsDashboardRefreshButton } from '@components/Campaigns/Analytics/CampaignAnalyticsDashboardRefreshButton';
import SegmentedTimeFilter from '@components/common/SegmentedTimeFilter';
import { useGetCampaignById } from '@hooks/useCampaigns';
import {
    CampaignType,
    ConditionalOperator,
    createDashboardFilterRuleFromField,
    formatTimestamp,
    isCustomSqlDimension,
    isTableCalculation,
    TimeFrames,
    type FilterableField,
} from '@lightdash/common';
import { Flex } from '@mantine/core';
import useDashboardContext from '@providers/Dashboard/useDashboardContext';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import AllRunsFilter from './AllRunsFilter';

const CampaignAnalyticsFilter = () => {
    const addDimensionDashboardFilter = useDashboardContext(
        (c) => c.addDimensionDashboardFilter,
    );

    const filterableFieldsByTileUuid = useDashboardContext(
        (c) => c.filterableFieldsByTileUuid,
    );

    const dashboardTemporaryFilters = useDashboardContext(
        (c) => c.dashboardTemporaryFilters,
    );

    const updateDimensionDashboardFilter = useDashboardContext(
        (c) => c.updateDimensionDashboardFilter,
    );

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { data: campaignData } = useGetCampaignById(
        queryParams.get('campaignUuid') ?? '',
    );

    const availableTimestampFields = useMemo(() => {
        if (!filterableFieldsByTileUuid) return [];
        const extractedItems: FilterableField[] = [];

        Object.values(filterableFieldsByTileUuid).forEach((items) => {
            items.forEach((item) => {
                if (isTableCalculation(item)) return;
                if (item.name === 'created_at' && item.type === 'timestamp') {
                    const isAlreadyAdded = extractedItems.some((ei) => {
                        if (isTableCalculation(ei)) return false;
                        return ei.table === item.table;
                    });
                    if (!isAlreadyAdded) {
                        extractedItems.push(item);
                    }
                }
            });
        });

        return extractedItems;
    }, [filterableFieldsByTileUuid]);

    const appliedFilterDimensionsByTimestampField = useCallback(() => {
        const timestampFieldIdentifiers = availableTimestampFields.map(
            (field) => {
                if (isTableCalculation(field)) return '';
                return `${field.table}_${field.name}`;
            },
        );
        return dashboardTemporaryFilters.dimensions.filter((dimension) =>
            timestampFieldIdentifiers.includes(dimension.target.fieldId),
        );
    }, [availableTimestampFields, dashboardTemporaryFilters]);

    const handleDateRangeChange = useCallback(
        (
            startTimestamp: number | undefined,
            endTimestamp: number | undefined,
        ) => {
            if (!filterableFieldsByTileUuid) return [];

            const defaultAppliedTimestampFields =
                appliedFilterDimensionsByTimestampField();
            const isUpdate = defaultAppliedTimestampFields.length > 0;

            const fields = isUpdate
                ? defaultAppliedTimestampFields
                : availableTimestampFields.map((field) => {
                      if (
                          isTableCalculation(field) ||
                          isCustomSqlDimension(field)
                      )
                          return;
                      return createDashboardFilterRuleFromField({
                          field,
                          availableTileFilters: filterableFieldsByTileUuid,
                          isTemporary: true,
                      });
                  });
            fields.forEach((field) => {
                if (!field) return;
                field.operator = ConditionalOperator.IN_BETWEEN;
                field.values = [
                    formatTimestamp(startTimestamp, TimeFrames.SECOND),
                    formatTimestamp(endTimestamp, TimeFrames.SECOND),
                ];
                field.tileTargets = {};
            });

            fields.forEach((field) => {
                if (!field) return;
                if (isUpdate) {
                    const fieldIndex =
                        dashboardTemporaryFilters.dimensions.findIndex(
                            (value) =>
                                value.target.fieldId === field.target.fieldId &&
                                value.target.tableName ===
                                    field.target.tableName,
                        );

                    updateDimensionDashboardFilter(
                        field,
                        fieldIndex,
                        true,
                        false,
                    );
                    return;
                }
                addDimensionDashboardFilter(field, true);
            });
        },
        [
            addDimensionDashboardFilter,
            availableTimestampFields,
            dashboardTemporaryFilters,
            filterableFieldsByTileUuid,
            appliedFilterDimensionsByTimestampField,
            updateDimensionDashboardFilter,
        ],
    );

    const appliedTimestampFields = useMemo(() => {
        const timestampFields = appliedFilterDimensionsByTimestampField();
        if (!timestampFields || timestampFields.length === 0) return;
        const startTimestamp = new Date(timestampFields[0].values?.[0]);
        const endTimestamp = new Date(timestampFields[0].values?.[1]);
        return [startTimestamp, endTimestamp];
    }, [appliedFilterDimensionsByTimestampField]);
    return (
        <Flex
            justify={
                campaignData && campaignData.type === CampaignType.RECURRING
                    ? 'space-between'
                    : 'flex-end'
            }
            className="w-full"
        >
            {campaignData && campaignData.type === CampaignType.RECURRING && (
                <AllRunsFilter />
            )}
            <SegmentedTimeFilter
                onOptionChange={(value) => {
                    if (!value) return;
                    handleDateRangeChange(
                        value.startTimestamp,
                        value.endTimestamp,
                    );
                }}
                defaultRange={
                    appliedTimestampFields
                        ? [appliedTimestampFields[0], appliedTimestampFields[1]]
                        : undefined
                }
                className="hidden"
            />

            <CampaignAnalyticsDashboardRefreshButton />
        </Flex>
    );
};

export default React.memo(CampaignAnalyticsFilter);
