import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import PageSpinner from '@components/PageSpinner';
import { useGetEventById } from '@hooks/useEvents';
import useApp from '@providers/App/useApp';
import EventProvider from '@providers/Events/EventsProvider';
import { useNavigate, useLocation, useParams } from 'react-router';
import EventsBuilderForm from '../components/EventsManager/Builder';
import { useEventInitialData } from '../components/EventsManager/Table/useEventsInitialData';
import useSearchParams from '../hooks/useSearchParams';

const EventsBuilder: React.FC<{}> = ({}) => {
    const { eventsUuid, source, mode } = useParams<{
        eventsUuid: string;
        source: string;
        mode: string;
    }>();
    const location = useLocation();
    const isNewMode = location.pathname.includes('create');
    const isEditMode = mode === 'edit';
    const isViewMode = mode === 'view';
    const eventTemplateId = useSearchParams('templateId');
    const templateSource = useSearchParams('source');
    const cloneId = useSearchParams('cloneId');
    const { data, isInitialLoading, isError } = useGetEventById({
        eventId: (cloneId ?? eventsUuid ?? eventTemplateId) || '',
        source: (source ?? templateSource) || '',
    });
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { user } = useApp();
    const ability = useAbilityContext();
    const cannotEditEventsMapper = ability.cannot(
        'update',
        subject('EventsMapper', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const navigate = useNavigate();
    const initialState = useEventInitialData(
        data,
        Boolean(cloneId),
        Boolean(eventTemplateId),
    );
    if (!isViewMode && cannotEditEventsMapper) {
        void navigate(`/projects/${projectUuid}/events`);
    }
    if (isInitialLoading) {
        return <PageSpinner />;
    }
    if (isError) return null;
    return (
        <EventProvider
            initialState={initialState}
            uuid={data?.uuid ?? ''}
            source={data?.source ?? ''}
            isNewMode={isNewMode}
            isEditMode={isEditMode}
            isViewMode={isViewMode}
        >
            <EventsBuilderForm />
        </EventProvider>
    );
};
export default EventsBuilder;
