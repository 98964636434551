import Select from '@components/common/Select';
import InviteSuccess from '@components/UserSettings/InviteSuccess';
import { useCreateInviteLinkMutation } from '@hooks/useInviteLink';
import { useLocale } from '@hooks/useLocale';
import {
    OrganizationMemberRole,
    OrganizationMemberRoleDescriptions,
    OrganizationMemberRoleLabels,
    validateEmail,
    type CreateInviteLink,
} from '@lightdash/common';
import { Box, Button, Group, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import useApp from '@providers/App/useApp';
import React, { forwardRef, useMemo, type FC } from 'react';
import { Check, ChevronRight } from 'react-feather';
import { ButtonVariant } from '../../mantineTheme';
import Modal from '../common/modal/Modal';
import { OrganizationMemberRoleFilter } from './type';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    label: string;
    description: string;
}
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, description, ...others }: ItemProps, ref) => (
        <Box ref={ref} {...others}>
            <Box className="flex justify-between">
                <Text className="text-sm font-medium text-gray-800">
                    {label}
                </Text>

                <Check
                    size={13}
                    strokeWidth={2.5}
                    color={'rgb(var(--color-gray-600))'}
                    className="selected__item--icon-selected"
                />
            </Box>
            <Text className="text-sm text-gray-600 break-normal">
                {description}
            </Text>
        </Box>
    ),
);

type SendInviteFormProps = Omit<CreateInviteLink, 'expiresAt'>;

const InvitesModal: FC<{
    opened: boolean;
    onClose: () => void;
}> = ({ opened, onClose }) => {
    const { t } = useLocale();
    const form = useForm<SendInviteFormProps>({
        initialValues: {
            email: '',
            role: OrganizationMemberRole.MEMBER,
        },
        validate: {
            email: (value: string) =>
                validateEmail(value) ? null : 'Invalid email address',
        },
    });
    const { health, user } = useApp();
    const {
        data: inviteLink,
        mutateAsync,
        isLoading,
    } = useCreateInviteLinkMutation();
    const handleSubmit = async (data: SendInviteFormProps) => {
        await mutateAsync(data);
        form.reset();
    };
    // FIXME: Role access is currently handled on the frontend, but it should be in ability rules from the backend.
    // To address this issue, we have a ticket on backend: https://shopflo.atlassian.net/browse/SORT-516
    //If we have role hierarchy in the ability rules we just filter the role before got displayed.
    const roleAccess = useMemo(() => {
        if (!user?.data?.userUuid) return OrganizationMemberRoleFilter;
        switch (user.data?.role) {
            case OrganizationMemberRole.MANAGER:
                return OrganizationMemberRoleFilter.shift();
            case OrganizationMemberRole.MEMBER:
                return OrganizationMemberRoleFilter.slice(2);
            default:
                return OrganizationMemberRoleFilter;
        }
    }, [user]);
    return (
        <Modal
            opened={opened}
            onClose={onClose}
            keepMounted={false}
            title={t('organization_member_invite_modal.title')}
            size="lg"
            footerRightSection={
                <Group>
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={onClose}
                        disabled={isLoading}
                    >
                        {t('workspace_member_invite_modal.secondary_btn_text')}
                    </Button>
                    <Button
                        form="invite_user"
                        disabled={isLoading}
                        type="submit"
                        className="w-fit"
                        rightIcon={<ChevronRight size={13} strokeWidth={2.5} />}
                    >
                        {health.data?.hasEmailClient
                            ? t(
                                  'workspace_member_invite_modal.primary_btn_text',
                              )
                            : t(
                                  'organization_member_invite_modal.primary_alternative_btn_text',
                              )}
                    </Button>
                </Group>
            }
        >
            <form
                name="invite_user"
                id="invite_user"
                onSubmit={form.onSubmit((values: SendInviteFormProps) =>
                    handleSubmit(values),
                )}
            >
                <Stack>
                    <TextInput
                        name="email"
                        label="Invite by email"
                        placeholder={t(
                            'organization_member_invite_modal.email_label',
                        )}
                        disabled={isLoading}
                        required
                        className="!w-2/5"
                        withAsterisk={false}
                        {...form.getInputProps('email')}
                    />

                    <Stack className="gap-2">
                        {user.data?.ability?.can('manage', 'Organization') && (
                            <Select
                                label={t(
                                    'organization_member_invite_modal.role_label',
                                )}
                                itemComponent={SelectItem}
                                data={(
                                    roleAccess as OrganizationMemberRole[]
                                ).map(
                                    (
                                        orgMemberRole: OrganizationMemberRole,
                                    ) => ({
                                        value: orgMemberRole,
                                        label: OrganizationMemberRoleLabels[
                                            orgMemberRole
                                        ].replace('_', ' '),
                                        description:
                                            OrganizationMemberRoleDescriptions[
                                                orgMemberRole
                                            ],
                                    }),
                                )}
                                disabled={isLoading}
                                required
                                placeholder="Select role"
                                dropdownPosition="bottom"
                                maxDropdownHeight={400}
                                withinPortal
                                className="!w-2/5"
                                withAsterisk={false}
                                {...form.getInputProps('role')}
                            />
                        )}
                    </Stack>
                </Stack>
            </form>
            {inviteLink && <InviteSuccess invite={inviteLink} hasMarginTop />}
        </Modal>
    );
};

export default InvitesModal;
