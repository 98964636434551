import { subject } from '@casl/ability';
import AudienceContainer from '@components/Audience';
import { getUnsavedChartVersion } from '@components/Audience/utils';
import ErrorState from '@components/common/ErrorState';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import ForbiddenPanel from '@components/ForbiddenPanel';
import Page from '@components/Page/Page';
import PageBreadcrumbs from '@components/PageBreadcrumbs';
import { useQueryResults } from '@hooks/useAudienceQueryResults';
import { useLocale } from '@hooks/useLocale';
import { useSavedAudience } from '@hooks/useSavedAudience';
import useSearchParams from '@hooks/useSearchParams';
import {
    FilterGroupOperator,
    QueryGenerationStrategy,
    type FilterGroupOperatorType,
    type NestedMetricQueryGroup,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import useApp from '@providers/App/useApp';
import AudienceProvider from '@providers/Audience/AudienceProvider';
import { metricQueryDefaultState } from '@providers/Audience/utils';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';

const AudienceBuilder: React.FC<{}> = ({}) => {
    const { projectUuid, mode, audienceUuid } = useParams<{
        projectUuid: string;
        audienceUuid: string;
        mode?: string;
    }>();
    const { user } = useApp();
    const queryResults = useQueryResults();
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const location = useLocation();
    const audienceTemplateUuid = useSearchParams('templateId');

    const isNewMode = location.pathname.includes('create');
    const isEditMode = isNewMode || mode === 'edit';

    const { t } = useLocale();

    const { data, isInitialLoading, error } = useSavedAudience({
        audienceUuid: (audienceUuid ?? audienceTemplateUuid) || '',
        relationId: activeRelationUuid,
    });

    const cannotViewProject = user.data?.ability?.cannot(
        'view',
        subject('Project', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const groupOperator = useMemo(() => {
        if (data && data?.nestedMetricQuery) {
            const nestedKeys = Object.keys(
                data?.nestedMetricQuery as NestedMetricQueryGroup,
            );
            if (nestedKeys.length <= 1) return FilterGroupOperator.and;
            if (nestedKeys[1] !== 'id') return nestedKeys[1];
            return nestedKeys[0];
        }
    }, [data]);

    if (cannotViewProject) {
        return <ForbiddenPanel />;
    }

    if (isInitialLoading) {
        return (
            <div style={{ marginTop: '20px' }}>
                <SuboptimalState title="Loading..." loading />
            </div>
        );
    }
    if (error) {
        return <ErrorState error={error.error} />;
    }

    return (
        <>
            <AudienceProvider
                isEditMode={isEditMode}
                queryResults={queryResults}
                initialState={
                    data
                        ? {
                              shouldFetchResults: false,
                              unsavedChartVersion: getUnsavedChartVersion(
                                  data.nestedMetricQuery,
                              ) ?? [metricQueryDefaultState],
                              modals: {
                                  additionalMetric: {
                                      isOpen: false,
                                  },
                                  customDimension: {
                                      isOpen: false,
                                  },
                              },
                              globalFilterCondition:
                                  groupOperator as FilterGroupOperatorType,

                              audiencePayload: {
                                  name:
                                      isNewMode && audienceTemplateUuid
                                          ? `Copy of ${data.name}`
                                          : data.name,
                                  description: data.description,
                                  generationStrategy: data.generationStrategy,
                                  sqlQuery: data.sqlQuery,
                                  userAlias: data.userAlias ?? '',
                                  previewConfig: data.previewConfig,
                                  insights:
                                      data.insights === null
                                          ? undefined
                                          : data.insights,
                                  ...(!(isNewMode && audienceTemplateUuid) && {
                                      tags: data.tags,
                                  }),
                                  ...(data.generationStrategy !==
                                  QueryGenerationStrategy.MANUAL
                                      ? {
                                            nestedMetricQuery:
                                                data.nestedMetricQuery,
                                        }
                                      : {}),
                              },
                              initialAudiencePayload: {
                                  name:
                                      isNewMode && audienceTemplateUuid
                                          ? `Copy of ${data.name}`
                                          : data.name,
                                  description: data.description,
                                  generationStrategy: data.generationStrategy,
                                  sqlQuery: data.sqlQuery,
                                  userAlias: data.userAlias ?? '',
                                  previewConfig: data.previewConfig,
                                  insights:
                                      data.insights === null
                                          ? undefined
                                          : data.insights,
                                  ...(data.generationStrategy !==
                                  QueryGenerationStrategy.MANUAL
                                      ? {
                                            nestedMetricQuery:
                                                data.nestedMetricQuery,
                                        }
                                      : {}),
                                  ...(!(isNewMode && audienceTemplateUuid) && {
                                      tags: data.tags,
                                  }),
                              },
                          }
                        : undefined
                }
            >
                <Page
                    title={
                        activeRelation
                            ? isNewMode
                                ? t('audience.create.title')
                                : data && data.name
                            : t('audiences')
                    }
                    withFullHeight
                    withNavbar
                    backgroundColor="gray"
                    withFixedContent
                    contentClassName=" w-full mt-3 pl-8 pr-4 pb-16"
                >
                    <Box component="div" className="mb-3.5">
                        <PageBreadcrumbs
                            items={[
                                {
                                    title: t('audiences'),
                                    to: `/projects/${projectUuid}/audiences`,
                                },
                                {
                                    title: isNewMode
                                        ? t('audience_builder.create')
                                        : isEditMode
                                        ? t('campaign_manager.menu_item_edit')
                                        : t('audience_manager.menu_item_view'),
                                    active: true,
                                },
                            ]}
                        />
                    </Box>

                    <AudienceContainer
                        audienceData={data}
                        isEditMode={isEditMode}
                        audienceUuid={audienceUuid}
                    />
                </Page>
            </AudienceProvider>
        </>
    );
};

export default AudienceBuilder;
