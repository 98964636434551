import PropertySelect from '@components/common/Select/PropertySelect';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { FiltersPropertySelectorFieldItem } from '@components/Journeys/Builder/JourneyFilters/FiltersForm/FiltersPropertySelector';
import { type JourneyProperty } from '@components/Journeys/Builder/JourneyFilters/types';
import { useLocale } from '@hooks/useLocale';
import { getItemId } from '@lightdash/common';
import { Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { type SuggestionKeyDownProps } from '@tiptap/suggestion';
import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

export interface MentionListProps {
    items: string[];
    journeyPropertiesItems: PropertySelectListType<JourneyProperty, string>[];
    command: (props: { id: string }) => void;
    setIsSuggestionFocused: (isFocused: boolean) => void;
}
export const MentionList = forwardRef((props: MentionListProps, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [opened, { open, close }] = useDisclosure(true);
    const { t } = useLocale();
    const selectItem = useCallback(
        (index: number) => {
            const item = props.items[index];
            if (item) {
                props.command({ id: item });
            }
        },
        [props],
    );

    const upHandler = useCallback(() => {
        setSelectedIndex(
            (selectedIndex + props.items.length - 1) % props.items.length,
        );
    }, [selectedIndex, props.items.length]);

    const downHandler = useCallback(() => {
        setSelectedIndex((selectedIndex + 1) % props.items.length);
    }, [selectedIndex, props.items.length]);

    const enterHandler = useCallback(() => {
        selectItem(selectedIndex);
    }, [selectedIndex, selectItem]);

    useEffect(() => setSelectedIndex(0), [props.items]);

    useImperativeHandle(
        ref,
        () => ({
            onKeyDown: ({ event }: SuggestionKeyDownProps) => {
                switch (event.key) {
                    case 'ArrowUp':
                        upHandler();
                        return true;
                    case 'ArrowDown':
                        downHandler();
                        return true;
                    case 'Enter':
                        enterHandler();
                        return true;
                    default:
                        return false;
                }
            },
        }),
        [downHandler, enterHandler, upHandler],
    );

    return (
        <Box
            className="dropdown-menu"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                e.stopPropagation()
            }
            onMouseEnter={() => props.setIsSuggestionFocused(true)}
            onMouseLeave={() => props.setIsSuggestionFocused(false)}
        >
            <PropertySelect<JourneyProperty>
                items={props.journeyPropertiesItems}
                showGroup={true}
                headerRightSection={null}
                onSubmit={(field: JourneyProperty[]) => {
                    if (!field[0]) return;
                    const fieldId = getItemId(field[0]);
                    selectItem(props.items.indexOf(`{{${fieldId}}}`));
                }}
                itemTemplate={({ item }) => {
                    return <FiltersPropertySelectorFieldItem item={item} />;
                }}
                opened={opened}
                close={close}
                open={open}
                withinPortal={true}
                targetButton={null}
                showSearch={true}
                searchKeys={['label', 'name', 'tableLabel']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={false}
                showAllItemsGroup={true}
                width={500}
                height={350}
            />
        </Box>
    );
});

MentionList.displayName = 'MentionList';
