import InputErrorText from '@components/common/InputErrorText';
import {
    NumberInput as MantineNumberInput,
    Stack,
    Text,
    type NumberInputProps as MantineNumberInputProps,
} from '@mantine/core';
interface NumberInputProps extends MantineNumberInputProps {}
const NumberInput = (props: NumberInputProps) => {
    const { description, error, ...rest } = props;
    return (
        <Stack spacing="xs">
            <MantineNumberInput {...rest} />
            {error && <InputErrorText value={error} />}
            {description && (
                <Text className="text-sm font-medium text-gray-500">
                    {description}
                </Text>
            )}
        </Stack>
    );
};

export default NumberInput;
