import { type FC } from 'react';
import ProjectProviderContext from './context';
import { type ProjectContextType } from './types';

const ProjectProvider: FC<React.PropsWithChildren<ProjectContextType>> = ({
    projectData,
    communicationChannels,
    children,
}) => {
    return (
        <ProjectProviderContext.Provider
            value={{ projectData, communicationChannels }}
        >
            {children}
        </ProjectProviderContext.Provider>
    );
};

export default ProjectProvider;
