import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import {
    ActionType,
    createFilterRuleFromField,
    type AndFilterGroup,
    type AndJourneyFilterGroup,
    type AndNestedMetricQuery,
    type BranchConfig,
    type FilterableField,
    type FilterRule,
    type JourneyFilterRule,
    type JourneyFiltersConfig,
    type JourneyNode,
    type MetricQuery,
} from '@lightdash/common';
import { t as translate } from 'i18next';
import { type Node } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';

/*** This function takes conditions of JourneyFiltersConfig tyhpe and return a filterrule from it.
 * @params conditions: JourneyFiltersConfig
 * @returns JourneyFilterRule | FilterRule
 */
export const getFilterRuleFromConditionsInSplit = (
    conditions: JourneyFiltersConfig | undefined,
) => {
    if (!conditions) return;
    const { journeyFilters, audienceFilters } = conditions;

    if (journeyFilters) {
        return (journeyFilters as AndJourneyFilterGroup)?.and[0];
    }

    if (audienceFilters) {
        return (
            (
                (audienceFilters.filterConfig as AndNestedMetricQuery)
                    ?.and[0] as MetricQuery
            ).filters?.dimensions as AndFilterGroup
        )?.and[0];
    }
};

/**
 * this function is used to update the filter rule in the journey filter config
 * @param conditions: JourneyFiltersConfig
 * @param updatedFilterRule: JourneyFilterRule | FilterRule
 * @returns JourneyFiltersConfig
 */
export const updateFilterRuleInJourneyFilterConfig = (
    conditions: JourneyFiltersConfig,
    updatedFilterRule: JourneyFilterRule | FilterRule,
    isJourneyField: boolean,
): JourneyFiltersConfig => {
    if (isJourneyField) {
        return {
            ...conditions,
            journeyFilters: {
                and: [updatedFilterRule as JourneyFilterRule],
            },
        };
    } else {
        return {
            ...conditions,
            audienceFilters: {
                filterConfig: {
                    id: uuidv4(),
                    and: [
                        {
                            id: uuidv4(),
                            exploreName: '',
                            dimensions: [],
                            metrics: [],
                            filters: {
                                dimensions: {
                                    id: uuidv4(),
                                    and: [updatedFilterRule as FilterRule],
                                },
                            },
                            sorts: [],
                            limit: 0,
                            tableCalculations: [],
                        },
                    ],
                },
                filterJoinType: 'and',
                compiledAudienceId: undefined,
            },
        };
    }
};

/**
 * this function use to give the activefield in the branchconfig
 * @param jounreNodeData
 * @param fieldsMap
 * @returns
 */
export const getActiveFieldInSplitBlock = (
    jounreNodeData: JourneyNode | null,
    fieldsMap: FieldsWithSuggestions,
) => {
    const conditions =
        jounreNodeData?.branchConfig?.children.branches[0]?.conditions;
    if (!conditions) return undefined;

    const { journeyFilters, audienceFilters } = conditions;
    if (journeyFilters) {
        const id = (
            (journeyFilters as AndJourneyFilterGroup)
                .and[0] as JourneyFilterRule
        ).target.fieldId;
        return fieldsMap[id];
    }
    if (audienceFilters) {
        const id = (
            (
                (
                    (audienceFilters.filterConfig as AndNestedMetricQuery)
                        .and[0] as MetricQuery
                ).filters?.dimensions as AndFilterGroup
            )?.and[0] as FilterRule
        )?.target?.fieldId;
        return fieldsMap[id];
    }
    return undefined;
};

/**
 * this function all filterrules in branchConfig
 *@param branchConfig
 * @param filterRule
 * @returns it returns the updated branchConfig
 */
export const updateAllFilterRulesInBranchConfig = (
    branchConfig: BranchConfig | undefined,
    filterRule: JourneyFilterRule | FilterRule,
    isJourneyField: boolean,
): BranchConfig | undefined => {
    if (!branchConfig) return;
    return {
        ...branchConfig,
        children: {
            ...branchConfig?.children,
            branches: branchConfig?.children.branches.map((branch) => {
                if (!branch.conditions) return branch;
                return {
                    ...branch,
                    conditions: updateFilterRuleInJourneyFilterConfig(
                        branch.conditions,
                        filterRule,
                        isJourneyField,
                    ) as JourneyFiltersConfig,
                };
            }),
        },
    };
};

/**
 * filtergroup from filterablefield
 * @param filterableField
 * @returns
 */
export const createJourneyFilterGroupFromFilterableField = (
    filterableField: FilterableField | undefined,
    isJourneyField: boolean,
): JourneyFiltersConfig => {
    if (isJourneyField) {
        return {
            id: uuidv4(),
            journeyFilters: {
                and: [
                    createFilterRuleFromField(
                        filterableField as unknown as FilterableField,
                    ) as unknown as JourneyFilterRule,
                ],
            },
            audienceFilters: undefined,
        };
    }

    return {
        id: uuidv4(),
        audienceFilters: {
            filterConfig: {
                id: uuidv4(),
                and: [
                    {
                        id: uuidv4(),
                        exploreName: '',
                        dimensions: [],
                        metrics: [],
                        filters: {
                            dimensions: {
                                id: uuidv4(),
                                and: [
                                    createFilterRuleFromField(
                                        filterableField as unknown as FilterableField,
                                    ) as FilterRule,
                                ],
                            },
                        },
                        sorts: [],
                        limit: 0,
                        tableCalculations: [],
                    },
                ],
            },
            filterJoinType: 'and',
            compiledAudienceId: undefined,
        },
        journeyFilters: undefined,
    };
};

/**this function takes the journeyNodeData and tells weather it is a split block or not
 * @param journeyNodeData
 * @returns
 */
export const isSplitBlock = (journeyNodeData: JourneyNode | undefined) => {
    if (!journeyNodeData) return false;
    return journeyNodeData.actions.some(
        (action) => action.type === ActionType.SPLIT,
    );
};

/**
 * this function gives the label for the edge in the split block
 * @param nodes
 * @param parentNodeId,
 * @param childNodeId,
 * @returns label for the edge
 */
export const getLabelForChildOfSplitBlock = (
    nodes: JourneyNode[],
    parentNodeId: string | null,
    childNodeId: string,
    edgeName: string | undefined,
) => {
    if (!parentNodeId) return undefined;

    const branches = nodes.find((node) => node.id === parentNodeId)
        ?.branchConfig?.children.branches;
    let desiredIndex = undefined;
    branches?.forEach((eachBranch, index) => {
        if (eachBranch.destination === childNodeId) {
            desiredIndex = index;
        }
    });
    if (desiredIndex !== undefined && desiredIndex >= 0)
        return `${desiredIndex + 1} ${
            edgeName ??
            translate('common.path', {
                index: desiredIndex + 1,
            })
        }`;
    const containsEveryoneElse = branches?.some((branch) => branch.isDefault);

    return branches?.length
        ? `${branches.length + (containsEveryoneElse ? 0 : 1)} ${
              edgeName ??
              translate('common.path', {
                  index: branches.length + 1,
              })
          }`
        : `1 ${
              edgeName ??
              translate('common.path', {
                  index: 1,
              })
          }`;
};
/**
 * to check weather given node is present in the nodes or not
 * @params it takes nodeId and nodes in it
 * @returns it returns boolean weather we have the nodeid in nodes or not
 */
export const isNodeInNodes = (nodeId: string, nodes: Node[]) => {
    return nodes.some((eachNode) => eachNode.id === nodeId);
};

/**this function takes the journeyNodeData and tells weather it is a experiment block or not
 * @param journeyNodeData
 * @returns
 */
export const isExperimentBlock = (journeyNodeData: JourneyNode | undefined) => {
    if (!journeyNodeData) return false;
    return journeyNodeData.actions.some(
        (action) => action.type === ActionType.EXPERIMENT,
    );
};
