import { sortmentApi } from '@api/index';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import {
    type ApiError,
    type ApiResponse,
    type CommunicationChannel,
    type UserGlobalPreferenceUpdateRequest,
    type UserPreference,
    type UserPreferenceUpdateRequest,
} from '@lightdash/common';
import {
    type SubscriptionPreferenceCreateOrUpdateRequest,
    type UserSubscriptionPreference,
} from '@lightdash/common/src/types/userSubscriptionPreference';
import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';

const unsubscribeGroup = async (
    data: SubscriptionPreferenceCreateOrUpdateRequest,
) =>
    sortmentApi<ApiResponse>({
        url: `/user-subscription-preferences/`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useUnsubscribeGroup = () => {
    return useMutation<
        ApiResponse,
        ApiError,
        { payload: SubscriptionPreferenceCreateOrUpdateRequest }
    >(({ payload }) => unsubscribeGroup(payload), {
        mutationKey: [QueryKeys.UNSUBSCRIBE_GROUP],
    });
};

const userSubscriptionPreferencesForChannel = async (
    channel: CommunicationChannel,
    userId: string | undefined,
    projectId: string | undefined,
) => {
    return sortmentApi<ApiResponse>({
        url: `/user-subscription-preferences/channel?channel=${channel}&user-id=${userId}&project-id=${projectId}`,
        method: 'GET',
        body: undefined,
    });
};

export const useUserSubscriptionPreferencesForChannel = ({
    channel,
    userId,
    projectId,
}: {
    channel: CommunicationChannel;
    userId: string | undefined;
    projectId: string | undefined;
}) => {
    const subscriptionPrefences = useQuery<
        ApiResponse,
        ApiError,
        UserPreference
    >({
        queryKey: [
            QueryKeys.GET_SUBSCRIPTION_PREFERENCE_FOR_CHANNEL,
            channel,
            projectId,
            userId,
        ],
        queryFn: () =>
            userSubscriptionPreferencesForChannel(channel, userId, projectId),
        enabled: !!projectId && !!userId,
    });
    return subscriptionPrefences;
};

const userSubscriptionPreference = async (
    channel: CommunicationChannel,
    userId: string,
    subGroupId: string,
) => {
    return sortmentApi<ApiResponse>({
        url: `/user-subscription-preferences/category?user-id=${userId}&subscription-group-id=${subGroupId}&channel=${channel}`,
        method: 'GET',
        body: undefined,
    });
};

export const useUserSubscriptionPreference = ({
    channel,
    userId,
    subGroupId,
}: {
    channel: CommunicationChannel;
    userId: string;
    subGroupId: string;
}) => {
    const subscriptionPrefences = useQuery<
        ApiResponse,
        ApiError,
        UserSubscriptionPreference
    >({
        queryKey: [QueryKeys.GET_USER_SUBSCRIPTION_PREFERENCE, channel],
        queryFn: () => userSubscriptionPreference(channel, userId, subGroupId),
        retry: false,
    });
    return subscriptionPrefences;
};

const updateSubscriptionPreferenceForChannel = async (
    data: UserPreferenceUpdateRequest,
) =>
    sortmentApi<ApiResponse>({
        url: `/user-subscription-preferences/channel`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useUpdateSubscriptionPreferenceForChannel = () => {
    const { showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<
        ApiResponse,
        ApiError,
        { payload: UserPreferenceUpdateRequest }
    >(({ payload }) => updateSubscriptionPreferenceForChannel(payload), {
        mutationKey: [QueryKeys.UPDATE_USER_SUBSCRIPTION_PREFERENCE],

        onError: () => {
            showToastError({
                title: t('subscription_preference.update_error'),
            });
        },
    });
};

const updateGlobalPreference = async (
    data: UserGlobalPreferenceUpdateRequest,
) =>
    sortmentApi<ApiResponse>({
        url: `/user-subscription-preferences/global-preference`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useUpdateGlobalPreference = () => {
    const { showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<
        ApiResponse,
        ApiError,
        { payload: UserGlobalPreferenceUpdateRequest }
    >(({ payload }) => updateGlobalPreference(payload), {
        mutationKey: [QueryKeys.UPDATE_GLOBAL_PREFERENCE],

        onError: () => {
            showToastError({
                title: t('subscription_preference.update_error'),
            });
        },
    });
};
