import BuilderFooter from '@components/common/BuilderContainer/BuilderFooter';
import BuilderHeader from '@components/common/BuilderContainer/BuilderHeader';
import Page from '@components/Page/Page';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import useEventContext from '@providers/Events/useEventContext';
import { useParams } from 'react-router';
import { type EventBuilderStep } from '../types';
import { EventBuilderSteps } from '../utils';

const EventsBuilderForm: React.FC<{}> = ({}) => {
    const { state, actions } = useEventContext((context) => context);
    const {
        currentStep,
        isValidStep,
        showFooterButtons,
        currentStepCallback,
        previousStepCallback,
        eventPayload,
        initialEventPayload,
    } = state;
    const { setCurrentStep, setShowFooterButtons } = actions;
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const isPayloadChange = useIsEqual(initialEventPayload, eventPayload);
    const CurrentStepComponent = EventBuilderSteps.find((step) => {
        return step.key === currentStep;
    })?.component;
    if (!CurrentStepComponent) {
        return null;
    }
    const showFooter = showFooterButtons.back || showFooterButtons.next;

    return (
        <Page
            withFullHeight={false}
            withPaddedContent
            backgroundColor="white"
            withFixedContent
            withNavbar={false}
            header={
                <BuilderHeader<EventBuilderStep>
                    title={t('event.create_title')}
                    exitLink={`/projects/${projectUuid}/events`}
                    currentStep={currentStep}
                    isValidStep={isValidStep}
                    setCurrentStep={setCurrentStep}
                    builderSteps={EventBuilderSteps}
                    showModal={isPayloadChange}
                />
            }
            footer={
                showFooter && (
                    <BuilderFooter<EventBuilderStep>
                        currentStep={currentStep}
                        builderSteps={EventBuilderSteps}
                        currentStepCallback={currentStepCallback}
                        previousStepCallback={previousStepCallback}
                        showFooterButtons={showFooterButtons}
                        setShowFooterButtons={setShowFooterButtons}
                        setCurrentStep={setCurrentStep}
                    />
                )
            }
            withFixedFooter={showFooter}
        >
            <CurrentStepComponent />
        </Page>
    );
};
export default EventsBuilderForm;
