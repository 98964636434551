import { JourneyNodeEnum } from '@components/Journeys/Builder/types';
import { useLocale } from '@hooks/useLocale';
import {
    JourneyStatus,
    PeriodType,
    ReservedEventColumns,
    type JourneyAndVersionConfig,
    type JourneyBlocksList,
} from '@lightdash/common';
import { type JourneyReducerState } from '@providers/Journey/types';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ControlPanel, JourneyBuilderMode } from './types';
import { useJourneyNodesAndEdges } from './useJourneyNodesAndEdges';
interface InitialDataProps {
    mode: JourneyBuilderMode;
    blocksList: JourneyBlocksList;
    projectUuid: string;
    journeyDataById: JourneyAndVersionConfig | undefined;
}

const useJourneyInitialData = ({
    mode,
    blocksList,
    projectUuid,
    journeyDataById,
}: InitialDataProps): JourneyReducerState => {
    const { t } = useLocale();
    const { nodes, edges } = useJourneyNodesAndEdges(
        journeyDataById,
        blocksList,
    );

    return useMemo(() => {
        let builderState: JourneyReducerState = {
            nodes,
            edges,
            splitActiveFields: {},
            controlPanel: {
                isOpen: false,
            },
            journeyPayload: {
                startDate:
                    mode === JourneyBuilderMode.CREATE && journeyDataById
                        ? undefined
                        : journeyDataById?.startDate ?? undefined,
                endDate:
                    mode === JourneyBuilderMode.CREATE && journeyDataById
                        ? undefined
                        : journeyDataById?.endDate ?? undefined,
                entryLogic: journeyDataById?.entryLogic ?? {
                    cooldown: -1,
                    contextId: ReservedEventColumns.USER_ID,
                    contextTotal: 1,
                    killExisting: true,
                    contextConcurrency: -1,
                    uiConfig: {
                        cooldownType: PeriodType.DAY,
                    },
                },
                name:
                    mode === JourneyBuilderMode.CREATE && journeyDataById
                        ? `copy of ${journeyDataById.name}`
                        : journeyDataById?.name ?? 'Untitled Journey',
                projectId: projectUuid,
                displayName: journeyDataById?.displayName ?? '',
                config: journeyDataById?.config ?? {
                    nodes: [],
                },
                triggers: journeyDataById?.triggers ?? {
                    entry: [],
                    exit: [
                        {
                            id: uuidv4(),
                            eventName: '',
                            eventSource: '',
                            filterConfig: undefined,
                            metadata: {
                                title: t(
                                    'journey_builder.exit_trigger_placeholder_title',
                                    { triggerNumber: 1 },
                                ),
                            },
                        },
                    ],
                    conversion: [
                        {
                            id: uuidv4(),
                            eventName: '',
                            eventSource: '',
                            filterConfig: undefined,
                            metadata: undefined,
                            deadline: 0,
                        },
                    ],
                    signals: undefined,
                },
                ...(mode === JourneyBuilderMode.EDIT && {
                    tags: journeyDataById?.tags ?? [],
                }),
            },
            journeyStatus:
                mode === JourneyBuilderMode.CREATE
                    ? JourneyStatus.DRAFT
                    : journeyDataById?.status ?? JourneyStatus.DRAFT,
            blocksList: blocksList,
        };

        if (
            (mode === JourneyBuilderMode.CREATE &&
                (!journeyDataById ||
                    journeyDataById.triggers.entry.length === 0)) ||
            (mode === JourneyBuilderMode.EDIT &&
                journeyDataById &&
                journeyDataById.triggers.entry.length === 0)
        ) {
            builderState = {
                ...builderState,
                nodes: [
                    {
                        id: '1',
                        position: { x: 0, y: 0 },
                        type: JourneyNodeEnum.PLACEHOLDER,
                        data: {
                            type: JourneyNodeEnum.PLACEHOLDER,
                            placeHolderType: JourneyNodeEnum.TRIGGER,
                        },
                    }, // The first node is the placeholder node to add a trigger block
                ],
                controlPanel: {
                    isOpen: true,
                    type: ControlPanel.TRIGGERS,
                },
            };
        }

        return builderState;
    }, [nodes, edges, mode, journeyDataById, projectUuid, t, blocksList]);
};

export default useJourneyInitialData;
