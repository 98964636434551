import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import { type EventMapperSchema } from '@lightdash/common';
import { CopySimple, PencilSimpleLine } from '@phosphor-icons/react';
import { ArrowBendDownLeft } from '@phosphor-icons/react/dist/ssr';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocale } from '../../../hooks/useLocale';

interface EventsMenuItemProps {
    rowData: EventMapperSchema;
}

const EventsMenuItem: React.FC<EventsMenuItemProps> = ({ rowData }) => {
    const { t } = useLocale();
    const navigate = useNavigate();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const handleView = useCallback(
        (uuid: string, source: string) => {
            void navigate(
                `/projects/${projectUuid}/events/${uuid}/${source}/view`,
            );
        },
        [navigate, projectUuid],
    );
    const handleDuplicate = useCallback(
        (uuid: string, source: string) => {
            void navigate(
                `/projects/${projectUuid}/events/create?templateId=${uuid}&source=${source}`,
            );
        },
        [navigate, projectUuid],
    );
    const handleEdit = useCallback(
        (uuid: string, source: string) => {
            void navigate(
                `/projects/${projectUuid}/events/${uuid}/${source}/edit`,
            );
        },
        [navigate, projectUuid],
    );
    const menuItems = useMemo(() => {
        const items: CustomMenuItemProps<EventMapperSchema>[] = [
            {
                leftSection: <ArrowBendDownLeft weight={'duotone'} />,
                children: t('event_manager.menu.view_details'),
                onClick: (values) => handleView(values.uuid, values.source),
            },
            {
                leftSection: <CopySimple weight="duotone" />,
                children: t('event_manager.menu.duplicate'),
                onClick: (values) => {
                    handleDuplicate(values.uuid, values.source);
                },
            },
            {
                leftSection: <PencilSimpleLine weight="duotone" />,
                children: t('event_manager.menu.edit_details'),
                onClick: (values) => {
                    handleEdit(values.uuid, values.source);
                },
            },
        ];
        return items;
    }, [handleEdit, handleDuplicate, handleView, t]);

    return (
        <CustomMenu<EventMapperSchema> menuItems={menuItems} data={rowData} />
    );
};

export default EventsMenuItem;
