import CustomMenu from '@components/common/MenuItem/MenuItem';
import UpdateTraitsModal from '@components/UpdateTraits/UpdateTraitsModal';
import { useLocale } from '@hooks/useLocale';
import {
    type CustomAttribute,
    type UserTraitFieldConfig,
    type UserTraits,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Copy } from '@phosphor-icons/react';
import { type Row } from '@tanstack/react-table';
import React, { useCallback, useMemo, useState } from 'react';

interface CustomMetricUpdateTraitMenuItemProps {
    rowData: Row<CustomAttribute | UserTraitFieldConfig>;
    userTraits: UserTraits | undefined;
}

const CustomMetricUpdateTraitMenuItem: React.FC<
    CustomMetricUpdateTraitMenuItemProps
> = ({ rowData, userTraits }) => {
    const { t } = useLocale();
    const [
        openedUpdateTraitModal,
        { open: openUpdateTraitModal, close: closeUpdateTraitModal },
    ] = useDisclosure();
    const [selectedFieldConfig, setSelectedFieldConfig] = useState<
        UserTraitFieldConfig | undefined
    >(undefined);
    const handleDuplicate = useCallback(
        (values: UserTraitFieldConfig) => {
            setSelectedFieldConfig({
                ...values,
                label: t('custom_metric_manager.menu_item_duplicate_copy', {
                    label: values.label,
                }),
            });
            openUpdateTraitModal();
        },
        [setSelectedFieldConfig, openUpdateTraitModal, t],
    );
    const menuItems = useMemo(() => {
        return [
            {
                leftSection: <Copy color={'rgb(var(--color-gray-500))'} />,
                children: t('custom_metric_manager.menu_item_duplicate'),
                onClick: (values: UserTraitFieldConfig) =>
                    handleDuplicate(values),
            },
        ];
    }, [handleDuplicate, t]);
    return (
        <Box
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
            }}
        >
            <CustomMenu<UserTraitFieldConfig>
                menuItems={menuItems}
                data={rowData.original as UserTraitFieldConfig}
            />
            <UpdateTraitsModal
                opened={openedUpdateTraitModal}
                onClose={closeUpdateTraitModal}
                isEditMode={true}
                items={undefined}
                updateTrait={userTraits}
                updateTraitFieldConfig={selectedFieldConfig}
            />
        </Box>
    );
};

export default CustomMetricUpdateTraitMenuItem;
