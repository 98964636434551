import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { type JourneyProperty } from '@components/Journeys/Builder/JourneyFilters/types';
import { getItemId } from '@lightdash/common';

/**
 * this function is to create array of fieldId for suggestions
 */
export const getFieldIdForSuggestionsWithHandlebars = (
    warehouseFields: FieldsWithSuggestions,
    journeyProperties: PropertySelectListType<JourneyProperty>[],
) => {
    const warehouseFieldsId = Object.keys(warehouseFields).map(
        (field) => `{{${field}}}`,
    );
    const journeyPropertiesIds = journeyProperties.map((property) =>
        property.items.map((item) => getItemId(item)),
    );

    // Flatten the array of arrays and filter out undefined or null values
    const flatJourneyPropertiesIds = journeyPropertiesIds
        .flat()
        .filter((id) => id != null)
        .map((item) => `{{${item}}}`);

    const fieldIds = [...warehouseFieldsId, ...flatJourneyPropertiesIds];

    return fieldIds;
};
