import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import { Box } from '@mantine/core';
import Channel from '@pages/Channel';
import useApp from '@providers/App/useApp';
import React from 'react';
import { Navigate, useParams } from 'react-router';

const Channels: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    const ability = useAbilityContext();
    const { user } = useApp();
    const canViewChannel = ability.can(
        'view',
        subject('ProjectSettings', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    if (!canViewChannel) {
        showToastError({ title: t('authorization_access_error.message') });
        return <Navigate to="/no-access" />;
    }
    return (
        <Box>
            <Channel
                allowChannelSetup={true}
                handleProviderSelect={undefined}
                isOnboarding={false}
                title={t('channels.title')}
            />
        </Box>
    );
};

export default React.memo(Channels);
