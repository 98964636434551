import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import { type CommunicationChannel } from '@lightdash/common';
import { Tabs } from '@mantine/core';
import useProjectContext from '@providers/Project/useProjectContext';
import React, { useState } from 'react';
import { activeTabClass, tabClass } from './utils';

const ChannelTabs: React.FC<{
    activeTab: string;
}> = ({ activeTab }) => {
    const { communicationChannels } = useProjectContext();
    const [hoveredTab, setHoveredTab] = useState<string>('');

    if (!communicationChannels) return null;
    return (
        <Tabs.List className="pb-3">
            {communicationChannels.map((tab) => {
                return (
                    <Tabs.Tab
                        className={`${tabClass} ${
                            activeTab === tab.value && activeTabClass
                        }`}
                        key={tab.value}
                        value={tab.value}
                        onMouseEnter={() => setHoveredTab(tab.value)}
                        onMouseLeave={() => setHoveredTab('')}
                    >
                        <ChannelIcon
                            channel={tab.value as CommunicationChannel}
                            color={
                                activeTab === tab.value ||
                                hoveredTab === tab.value
                                    ? 'rgb(var(--color-gray-800))'
                                    : 'rgb(var(--color-gray-700))'
                            }
                        />
                    </Tabs.Tab>
                );
            })}
        </Tabs.List>
    );
};

export default ChannelTabs;
