import { Box } from '@mantine/core';
import { X } from '@phosphor-icons/react';
import React, { useCallback, useMemo, useState } from 'react';
import JourneySettingsSidebar from './Sidebar';
import JourneySettingsMenuGroups, {
    type SettingListItemType,
} from './Sidebar/JourneySettingsMenuLists';
import { JourneySettingType } from './Sidebar/types';
interface JourneysSettingsProps {
    onClose: () => void;
}

const JourneysSettings: React.FC<JourneysSettingsProps> = ({ onClose }) => {
    const [settingsType, setSettingsType] = useState<JourneySettingType>(
        JourneySettingType.OVERVIEW,
    );

    const onSettingChange = useCallback((type: JourneySettingType) => {
        setSettingsType(type);
    }, []);

    const renderSettingComponent = useCallback(
        (settings: SettingListItemType): React.ReactNode => {
            if (settings.type === settingsType) {
                return settings.children;
            }
        },
        [settingsType],
    );

    const renderSettingComponents = useMemo(
        () =>
            JourneySettingsMenuGroups.flatMap((group) =>
                group.map(renderSettingComponent),
            ),
        [renderSettingComponent],
    );

    return (
        <Box className="flex w-full h-full">
            <Box className="min-w-[200px] w-[18%] bg-gray-50">
                <JourneySettingsSidebar
                    settingsType={settingsType}
                    onSettingChange={onSettingChange}
                />
            </Box>
            <Box className="flex-grow">{renderSettingComponents}</Box>

            <X
                className="absolute text-gray-700 cursor-pointer right-5 top-5"
                onClick={onClose}
                weight="bold"
                width={14}
                height={20}
            />
        </Box>
    );
};

export default JourneysSettings;
