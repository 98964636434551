import {
    type IBeeConfig,
    type IPluginRow,
} from '@beefree.io/sdk/dist/types/bee';
import { unsubPreferencesUrl, unsubUrl } from '@lightdash/common';
import { useParams } from 'react-router';

export const starterTemplate = {
    page: {
        body: {
            container: {
                style: {
                    'background-color': '#FFFFFF',
                },
            },
            content: {
                computedStyle: {
                    linkColor: '#0068A5',
                    messageBackgroundColor: 'transparent',
                    messageWidth: '500px',
                },
                style: {
                    color: '#000000',
                    'font-family':
                        'Arial, Helvetica Neue, Helvetica, sans-serif',
                },
            },
            webFonts: [],
        },
        description: '',
        rows: [
            {
                columns: [
                    {
                        'grid-columns': 12,
                        modules: [],
                        style: {
                            'background-color': 'transparent',
                            'border-bottom': '0 solid transparent',
                            'border-left': '0 solid transparent',
                            'border-right': '0 solid transparent',
                            'border-top': '0 solid transparent',
                            'padding-bottom': '5px',
                            'padding-left': '0px',
                            'padding-right': '0px',
                            'padding-top': '5px',
                        },
                        uuid: '1136212c-fb6b-4d18-90dc-43731c926110',
                    },
                ],
                container: {
                    style: {
                        'background-color': 'transparent',
                        'background-image': 'none',
                        'background-position': 'top left',
                        'background-repeat': 'no-repeat',
                    },
                },
                content: {
                    computedStyle: {
                        hideContentOnDesktop: false,
                        hideContentOnMobile: false,
                        rowColStackOnMobile: true,
                        rowReverseColStackOnMobile: false,
                        verticalAlign: 'top',
                    },
                    style: {
                        'background-color': 'transparent',
                        'background-image': 'none',
                        'background-position': 'top left',
                        'background-repeat': 'no-repeat',
                        color: '#000000',
                        width: '500px',
                    },
                },
                empty: false,
                locked: false,
                synced: false,
                type: 'one-column-empty',
                uuid: 'c738fb29-e88f-4b24-bd0c-deb9a414ff5e',
            },
            {
                columns: [
                    {
                        'grid-columns': 6,
                        modules: [
                            {
                                align: 'left',
                                descriptor: {
                                    computedStyle: {
                                        hideContentOnMobile: false,
                                    },
                                    style: {
                                        'padding-bottom': '10px',
                                        'padding-left': '10px',
                                        'padding-right': '10px',
                                        'padding-top': '10px',
                                    },
                                    text: {
                                        computedStyle: {
                                            linkColor: '#0068A5',
                                        },
                                        html: '<div class="txtTinyMce-wrapper" style="font-size:14px;line-height:16px;font-family:inherit;" data-mce-style="font-size:14px;line-height:16px;font-family:inherit;"><p style="font-size:14px;line-height:16px;word-break:break-word;text-align:right;" data-mce-style="font-size:14px;line-height:16px;word-break:break-word;text-align:right;"><code data-bee-type="speciallink" data-bee-code=""><a href="{{site_url}}/unsubscribe?campaign={{campaign_id}}&amp;channel={{campaign_channel}}&amp;projectId={{project_id}}&amp;subGroupId={{subscription_group_id}}&amp;userId={{distinct_id}}" target="_blank" rel="noopener">Unsubscribe</a></code>&nbsp;</p></div>',
                                        style: {
                                            color: '#555555',
                                            'font-family': 'inherit',
                                            'line-height': '120%',
                                        },
                                    },
                                },
                                locked: false,
                                type: 'mailup-bee-newsletter-modules-text',
                                uuid: 'd1ca5ff7-6af7-4cdc-b3e8-6175d31c3419',
                            },
                        ],
                        style: {
                            'background-color': 'transparent',
                            'border-bottom': '0px solid transparent',
                            'border-left': '0px solid transparent',
                            'border-right': '0px solid transparent',
                            'border-top': '0px solid transparent',
                            'padding-bottom': '5px',
                            'padding-left': '0px',
                            'padding-right': '0px',
                            'padding-top': '5px',
                        },
                        uuid: 'c98ff138-e7b7-49e0-ad76-85e91b8d5a7c',
                    },
                    {
                        'grid-columns': 6,
                        modules: [
                            {
                                align: 'left',
                                descriptor: {
                                    computedStyle: {
                                        hideContentOnMobile: false,
                                    },
                                    style: {
                                        'padding-bottom': '10px',
                                        'padding-left': '10px',
                                        'padding-right': '10px',
                                        'padding-top': '10px',
                                    },
                                    text: {
                                        computedStyle: {
                                            linkColor: '#0068A5',
                                        },
                                        html: '<div class="txtTinyMce-wrapper" style="font-size:14px;line-height:16px;font-family:inherit;" data-mce-style="font-size:14px;line-height:16px;font-family:inherit;"><p style="font-size:14px;line-height:16px;word-break:break-word;" data-mce-style="font-size:14px;line-height:16px;word-break:break-word;"><code data-bee-type="speciallink" data-bee-code=""><a href="{{site_url}}/subscription-preferences?campaign={{campaign_id}}&amp;channel={{campaign_channel}}&amp;projectId={{project_id}}&amp;subGroupId={{subscription_group_id}}&amp;userId={{distinct_id}}" target="_blank" rel="noopener">Unsubscribe Preferences</a></code>&nbsp;</p></div>',
                                        style: {
                                            color: '#555555',
                                            'font-family': 'inherit',
                                            'line-height': '120%',
                                        },
                                    },
                                },
                                locked: false,
                                type: 'mailup-bee-newsletter-modules-text',
                                uuid: '2f14f66b-c8bd-426f-8602-df134d425128',
                            },
                        ],
                        style: {
                            'background-color': 'transparent',
                            'border-bottom': '0px solid transparent',
                            'border-left': '0px solid transparent',
                            'border-right': '0px solid transparent',
                            'border-top': '0px solid transparent',
                            'padding-bottom': '5px',
                            'padding-left': '0px',
                            'padding-right': '0px',
                            'padding-top': '5px',
                        },
                        uuid: '689da66b-add9-43d5-9731-da8a941e35e8',
                    },
                ],
                container: {
                    style: {
                        'background-color': 'transparent',
                        'background-image': 'none',
                        'background-position': 'top left',
                        'background-repeat': 'no-repeat',
                    },
                },
                content: {
                    computedStyle: {
                        hideContentOnDesktop: false,
                        hideContentOnMobile: false,
                        rowColStackOnMobile: true,
                        rowReverseColStackOnMobile: false,
                        verticalAlign: 'top',
                    },
                    style: {
                        'background-color': 'transparent',
                        'background-image': 'none',
                        'background-position': 'top left',
                        'background-repeat': 'no-repeat',
                        'border-bottom': '0px solid transparent',
                        'border-left': '0px solid transparent',
                        'border-radius': '0px',
                        'border-right': '0px solid transparent',
                        'border-top': '0px solid transparent',
                        color: '#000000',
                        width: '500px',
                    },
                },
                empty: false,
                locked: false,
                metadata: {
                    categoryId: 142,
                    name: 'Unsubscribe',
                    rowIdentifier: 'ed1f4f6e-f427-40e9-a8c2-ff5914c5fb5b',
                },
                synced: false,
                type: 'Unsubscribe',
                uuid: 'd4f80f01-b613-4d11-892f-e7abba8eb9c0',
            },
        ],
        template: {
            version: '2.0.0',
        },
        title: '',
    },
    comments: {},
};

const useBeeFreeConfig = ({
    handleSave,
}: {
    handleSave: (jsonFile: unknown, htmlFile: unknown) => void;
}) => {
    const { projectId } = useParams<{ projectId: string }>();
    return {
        uid: projectId, //needed for identify resources of the that user and billing stuff
        language: 'en-US',
        sidebarPosition: 'left',
        // autosave: true,
        container: 'beefree-sdk-container',
        onSave: (jsonFile: unknown, htmlFile: unknown) => {
            // console.log('onSave', jsonFile, htmlFile);
            void handleSave(jsonFile, htmlFile);
        },
        onSaveAsTemplate: (jsonFile: unknown) => {
            console.log('onSaveAsTemplate', jsonFile);
        },
        onSend: (htmlFile: unknown) => {
            console.log('onSend', htmlFile);
        },
        onError: (errorMessage: unknown) => {
            console.log('onError ', errorMessage);
        },
        hooks: {
            getRows: {
                handler: async (resolve) => {
                    resolve(starterTemplate.page.rows.slice(1) as IPluginRow[]);
                },
            },
        },
        rowsConfiguration: {
            emptyRows: true,
            defaultRows: true,
            externalContentURLs: [
                {
                    name: 'Unsubscribe Block',
                    value: 'unsub',
                    handle: 'unsub',
                    isLocal: true,
                    behaviors: {
                        canEdit: false,
                        canDelete: false,
                    },
                },
            ],
        },
        // customAttributes:{
        //   attributes: [
        //    {
        //       key: "Deeplink",
        //      value: true,
        //       target: "link"
        //     },
        //    {
        //       key: "data-segment",
        //      value: ['travel', 'luxury'],
        //       target: "link"
        //     },
        //    {
        //       key: "class",
        //       target: "tag"
        //     }
        //   ]
        // },
        specialLinks: [
            {
                type: 'Frequently used',
                label: 'Unsubscribe',
                link: unsubUrl,
            },
            {
                type: 'Frequently used',
                label: 'Unsubscribe Preferences',
                link: unsubPreferencesUrl,
            },
            /* Other special links */
        ],
        //   mergeTags: [
        //     {
        //       name: 'First Name',
        //       value: '[first-name]'
        //     }, {
        //       name: 'Latest order date',
        //       value: '[order-date]'
        //     }
        //   ],
        //   mergeContents: [
        //     {
        //       name: 'Headline news',
        //       value: '{headline}'
        //     }, {
        //       name: 'Image of last product viewed',
        //       value: '{last-product-viewed}'
        //     }
        //   ],
        //   contentDialog: {
        //     specialLinks: {
        //       label: 'Add an Example Link',
        //       handler: function(resolve, reject) {
        //         resolve({
        //           type: 'custom',
        //           label: 'external special link',
        //           link: 'http://www.example.com'
        //         })
        //       }
        //     },
        //     mergeTags: {
        //       label: 'Apply dynamic syntax',
        //       handler: function(resolve, reject) {
        //           //your function goes here
        //       }
        //   },
        //   }
    } as IBeeConfig;
};

export default useBeeFreeConfig;
