import { useLocale } from '@hooks/useLocale';
import {
    Box,
    Flex,
    Select as MantineSelect,
    Stack,
    Text,
    type SelectItem as SelectItemType,
    type SelectProps as MantineSelectProps,
} from '@mantine/core';
import { CaretDown, Check, X } from '@phosphor-icons/react';
import React, { forwardRef, useMemo } from 'react';
import InputErrorText from '../InputErrorText';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    label: string;
}
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, ...others }: ItemProps, ref) => (
        <Box ref={ref} {...others}>
            <Box className="flex justify-between items-center gap-1 truncate max-w-[330px] ">
                <Text className="text-sm font-medium text-gray-800">
                    {label}
                </Text>

                <Check
                    size={13}
                    strokeWidth={2.5}
                    color={'rgb(var(--color-gray-600))'}
                    className="selected__item--icon-selected"
                />
            </Box>
        </Box>
    ),
);
interface SelectProps extends MantineSelectProps {
    SelectItem?: React.ReactNode;
    readOnlyClasses?: string;
    newOptionLabel?: string;
}

const Select = React.forwardRef<HTMLInputElement, SelectProps>(
    (
        {
            error,
            description,
            readOnly,
            readOnlyClasses,
            newOptionLabel,
            clearable,
            ...rest
        },
        ref,
    ) => {
        const { t } = useLocale();
        const selectedValue = useMemo(() => {
            return rest.data.find(
                (option) => (option as SelectItemType).value === rest.value,
            ) as SelectItemType;
        }, [rest]);
        const rightSection = useMemo(() => {
            return clearable && selectedValue?.label ? (
                <X
                    color={'rgb(var(--color-gray-600))'}
                    className="cursor-pointer selected__item--icon-selected"
                    weight="bold"
                    onClick={(event) => {
                        event.stopPropagation();
                        rest.onChange?.(null);
                        if (ref && 'current' in ref && ref.current) {
                            ref.current.value = '';
                        }
                    }}
                />
            ) : rest.disabled ? null : (
                <CaretDown
                    size={13}
                    strokeWidth={2.5}
                    color={'rgb(var(--color-gray-600))'}
                    className="selected__item--icon-selected"
                />
            );
        }, [clearable, rest, selectedValue, ref]);
        // INFO - Select does not support readonly attribute
        if (readOnly) {
            if (selectedValue) {
                return (
                    <Stack spacing={'xs'}>
                        <Text className="text-sm font-medium text-gray-800 ">
                            {rest.label}
                        </Text>
                        <Box
                            className={`bg-gray-50 p-2 rounded-lg ${readOnlyClasses}`}
                        >
                            <Text className="!text-gray-800">
                                {selectedValue.label}
                            </Text>
                        </Box>
                        {error && <InputErrorText value={error} />}
                        {description && (
                            <Text className="text-sm font-medium text-gray-500">
                                {description}
                            </Text>
                        )}
                    </Stack>
                );
            }
        }
        return (
            <Stack spacing={'xs'}>
                <MantineSelect
                    rightSection={rightSection}
                    rightSectionWidth={30}
                    itemComponent={SelectItem}
                    styles={{
                        rightSection: {
                            pointerEvents:
                                clearable && selectedValue?.label
                                    ? 'auto'
                                    : 'none',
                        },
                    }}
                    ref={ref}
                    getCreateLabel={(query) => {
                        if (!query) return null;
                        return (
                            <Flex>
                                <Flex
                                    justify={'center'}
                                    align={'center'}
                                    className="h-5 px-1 mr-1 text-xs text-white bg-blue-500 border rounded-md"
                                >
                                    {newOptionLabel ?? t('common.new')}
                                </Flex>
                                <Text>{query}</Text>
                            </Flex>
                        );
                    }}
                    {...rest}
                />
                {error && <InputErrorText value={error} />}
                {description && (
                    <Text className="text-sm font-medium text-gray-500">
                        {description}
                    </Text>
                )}
            </Stack>
        );
    },
);

export default React.memo(Select);
