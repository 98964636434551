import { isInvalidCronExpression } from '@utils/fieldValidators';
import cronstrue from 'cronstrue';

export const cronHelperText = (cron: string) => {
    const validationError = isInvalidCronExpression('Cron expression')(cron);
    const cronHumanString = cronstrue.toString(cron, {
        verbose: true,
        throwExceptionOnParseError: false,
    });
    return validationError ?? cronHumanString;
};
