import { sortmentApi } from '@api/index';
import { type CustomMaterialisation } from '@lightdash/common';
import {
    useIsFetching,
    useMutation,
    useQuery,
    useQueryClient,
    type Query,
} from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';

const QUERIES_TO_REFRESH = [
    'savedChartResults',
    'saved_query',
    'saved_dashboard_query',
    'dashboards',
];

const queryPredicate = (query: Query) => {
    return QUERIES_TO_REFRESH.some((key) => {
        const firstQueryKey =
            typeof query.queryKey === 'string'
                ? query.queryKey
                : query.queryKey?.[0];
        return firstQueryKey === key;
    });
};

export const useDashboardRefresh = () => {
    const queryClient = useQueryClient();

    const isFetching = useIsFetching({ predicate: queryPredicate });

    const invalidateDashboardRelatedQueries = useCallback(() => {
        return queryClient.invalidateQueries({
            predicate: queryPredicate,
        });
    }, [queryClient]);

    return useMemo(
        () => ({
            invalidateDashboardRelatedQueries,
            isFetching,
        }),
        [invalidateDashboardRelatedQueries, isFetching],
    );
};

const getLastRunStatus = (projectUuid: string, query: string) => {
    return sortmentApi<CustomMaterialisation[]>({
        url: `/projects/${projectUuid}/custom-materialisations${query}`,
        method: 'GET',
        body: undefined,
    });
};

export const useGetLastRunStatus = (query: string) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const queryClient = useQueryClient();

    return useQuery<CustomMaterialisation[]>({
        queryKey: [QueryKeys.GET_LAST_RUN_STATUS, projectUuid, query],
        queryFn: () => getLastRunStatus(projectUuid, query),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                predicate: queryPredicate,
            });
        },
    });
};

const refreshCampaignAnalytics = (projectUuid: string) => {
    return sortmentApi<any>({
        url: `/projects/${projectUuid}/events/ingest-to-wh`,
        method: 'POST',
        body: undefined,
    });
};

export const useRefreshCampaignAnalytics = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useMutation({
        mutationKey: [QueryKeys.REFRESH_CAMPAIGN_ANALYTICS, projectUuid],
        mutationFn: () => refreshCampaignAnalytics(projectUuid),
    });
};
