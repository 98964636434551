import { type MetricFilterRuleWithFieldId } from '@components/Explorer/CustomMetricModal/FilterForm';
import { getCustomMetricName } from '@components/Explorer/CustomMetricModal/utils';
import {
    DEDUPED_EVENTS_TABLE_PREFIX,
    DimensionType,
    FilterOperator,
    friendlyName,
    getFieldRef,
    getItemId as getFieldId,
    isAdditionalMetric,
    isAndNestedMetricQuery,
    isDimension,
    isFilterGroup,
    isMetric,
    JoinType,
    RelationTableType,
    ReservedCampaignEventColumns,
    ReservedEventColumns,
    type AdditionalMetric,
    type AdditionalMetricPreview,
    type AndFilterGroup,
    type CompiledRelation,
    type CreateSavedChartVersion,
    type FilterableDimension,
    type FilterableField,
    type FilterGroup,
    type FilterGroupItem,
    type FilterRule,
    type MetricFilterRule,
    type MetricQuery,
    type MetricType,
    type NestedMetricQueryGroup,
    type OrFilterGroup,
    isAndFilterGroup,
    isOrFilterGroup,
} from '@lightdash/common';
import { metricQueryDefaultState } from '@providers/Audience/utils';
import { CAMPAIGN_EVENT_TABLE_NAME } from '@utils/constants';
import { generateShortUUID } from '@utils/helpers';
import { getTableRelationType } from '@utils/relation';
import { v4 as uuidv4 } from 'uuid';
import type {
    FieldsWithSuggestions,
    FieldWithSuggestions,
} from './Filters/FiltersProvider/types';

export const getUnsavedChartVersion = (
    nestedMetricQuery: NestedMetricQueryGroup | undefined,
) => {
    if (!nestedMetricQuery) return [];
    let metricQueries: CreateSavedChartVersion[] = [];

    if (nestedMetricQuery) {
        const savedMetricQueries = isAndNestedMetricQuery(nestedMetricQuery)
            ? nestedMetricQuery.and
            : nestedMetricQuery.or;
        savedMetricQueries.forEach((metricQuery) => {
            metricQueries.push({
                ...metricQueryDefaultState,
                metricQuery: metricQuery as MetricQuery,
            });
        });
    }
    return metricQueries;
};

/**
 * this function is used to check if the filter is an event
 * @param filter it is the filter that is passed to the function
 * @param fieldsMap it is the fields map that is passed to the function
 * @returns it returns true if the filter is an event
 */
const isEventField = (
    filter: FilterGroupItem,
    fieldsMap: FieldsWithSuggestions,
) => {
    if (isFilterGroup(filter)) return false;
    const fieldId = filter.target.fieldId;
    return fieldsMap[fieldId]?.tableType === RelationTableType.EVENT;
};

/**
 * this function is used to check if the filter is an event name
 * @param filter it is the filter that is passed to the function
 * @param eventsMap it is the events map that is passed to the function
 * @returns it returns true if the filter is an event name
 */
const isEventNameProperty = (
    filter: FilterGroupItem,
    eventsMap: FieldWithSuggestions[],
) => {
    if (filter && isFilterGroup(filter)) return false;
    const filterValue = filter?.values?.[0] ?? '';
    return eventsMap.some(
        (event) =>
            (event as FilterableDimension).fieldReference === filterValue,
    );
};

/**
 * this function used to place all the events at top if the first filter is an event and if the first filter is dimension it places all the dimensions at top
 * @param filterGroup it is the filter group that is passed to the function
 * @param eventFieldIds it is the event field ids that is passed to the function
 * @returns it returns the categorized filters we have the filter group in filter group so in eeach nested groups we tries to arrange the dimensions or events at their desired places.
 */
export const categorizeDimensionsAndEvents = (
    filterGroup: FilterGroup | undefined,
    fieldsMap: FieldsWithSuggestions,
    eventsMap: FieldWithSuggestions[],
): FilterGroup | undefined => {
    const categorizeFilters = (
        filters: FilterGroupItem[],
    ): FilterGroupItem[] => {
        const nonGroupFilters = filters.filter(
            (filter) => !isFilterGroup(filter),
        );
        const eventsName = nonGroupFilters
            .filter((filter) => isEventField(filter, fieldsMap))
            .filter((event) => isEventNameProperty(event, eventsMap));
        const eventDimensions = nonGroupFilters
            .filter((filter) => isEventField(filter, fieldsMap))
            .filter((event) => !isEventNameProperty(event, eventsMap));
        const dimensions = nonGroupFilters.filter(
            (filter) => !isEventField(filter, fieldsMap),
        );
        const groups = filters.filter(isFilterGroup);

        return [
            ...eventsName,
            ...eventDimensions,
            ...dimensions,
            ...groups
                .map((group) =>
                    categorizeDimensionsAndEvents(group, fieldsMap, eventsMap),
                )
                .filter((item): item is FilterGroup => item !== undefined),
        ];
    };

    if (!filterGroup) return undefined;

    if ('or' in filterGroup) {
        const orFilters = (filterGroup as OrFilterGroup).or;
        return {
            ...filterGroup,
            or: categorizeFilters(orFilters as FilterGroupItem[]),
        };
    } else if ('and' in filterGroup) {
        const andFilters = (filterGroup as AndFilterGroup).and;
        return {
            ...filterGroup,
            and: categorizeFilters(andFilters as FilterGroupItem[]),
        };
    }

    return filterGroup;
};

/**
 * this function is used to check if the filter is an event dimension
 * @param filter it is the filter that is passed to the function
 * @param fieldsMap it is the fields map that is passed to the function
 * @param eventsMap it is the events map that is passed to the function
 * @returns it returns true if the filter is an event dimension
 */
export const isEventDimension = (
    filter: FilterGroupItem,
    fieldsMap: FieldsWithSuggestions,
    eventsMap: FieldWithSuggestions[],
) => {
    if (isFilterGroup(filter)) return false;
    const filterValue = filter?.values?.[0] ?? '';
    return (
        fieldsMap[filter.target.fieldId]?.tableType ===
            RelationTableType.EVENT &&
        !eventsMap.find(
            (event) =>
                isDimension(event) && event.fieldReference === filterValue,
        )
    );
};

/**
 * this function is used to check if the filter is an event metric
 * @param filter it is the filter that is passed to the function
 * @param eventsMap it is the events map that is passed to the function
 * @returns it returns true if the filter is an event metric
 */
export const isEventMetricCreatedByAdditionalMetric = (
    field: FilterableField | undefined,
    eventsMap: FieldWithSuggestions[],
    additionalMetrics: AdditionalMetric[] | undefined,
) => {
    if (!field) return false;
    if (
        isMetric(field) &&
        additionalMetrics?.some((metric) => metric.name === field.name)
    ) {
        const subFilters = field.filters?.[0];
        return isEventNameProperty(subFilters as FilterRule, eventsMap);
    }
    return false;
};

/**
 * this function is used to create filter rule from event name field
 * @param field
 * @returns
 */
export const createFilterRuleFromEventNameField = (
    field: FilterableDimension,
    value: string,
) => {
    return {
        id: uuidv4(),
        target: {
            fieldId: getFieldId(field),
            fieldRef: getFieldRef(field),
        },
        operator: FilterOperator.EQUALS,
        values: [value],
    };
};
/**
 * this function is used to get the deduped event table name
 * @param projectUuid
 * @returns
 */
export const getDedupedEventTableName = (projectUuid: string) =>
    `${DEDUPED_EVENTS_TABLE_PREFIX}${projectUuid.split('-')[0]}`;
/**
 * this function is used to create the field id for campaign event
 * @param projectUuid
 * @returns
 */
export const campaignEventFieldId = (
    projectUuid: string,
    dimensionName: string,
) => `${getDedupedEventTableName(projectUuid)}_${dimensionName}`;
/**
 * this function is used to create the field ref for campaign event
 * @param projectUuid
 * @returns
 */
export const campaignEventFieldRef = (
    projectUuid: string,
    dimensionName: string,
) => `${getDedupedEventTableName(projectUuid)}.${dimensionName}`;

/**
 * this function is used to create filter rule from campaign event
 * @param field
 * @param value
 * @returns
 */
export const createFilterRuleFromCampaignEvent = (
    field: FieldWithSuggestions,
    projectUuid: string,
) => {
    return [
        {
            id: uuidv4(),
            target: {
                fieldId: campaignEventFieldId(
                    projectUuid,
                    ReservedEventColumns.EVENT_NAME,
                ),
                fieldRef: campaignEventFieldRef(
                    projectUuid,
                    ReservedEventColumns.EVENT_NAME,
                ),
            },
            operator: FilterOperator.EQUALS,
            values: [field.name],
        },
        {
            id: uuidv4(),
            target: {
                fieldId: campaignEventFieldId(
                    projectUuid,
                    ReservedCampaignEventColumns.CHANNEL,
                ),
                fieldRef: campaignEventFieldRef(
                    projectUuid,
                    ReservedCampaignEventColumns.CHANNEL,
                ),
            },
            operator: FilterOperator.EQUALS,
            values: [field.uniqueIdentifier],
        },
    ];
};

/**
 * this function is used to get all the metrics in the filters
 * @param metricsFilterGroup
 * @param eventPropertiesFilterGroup
 * @returns it returns the all metrics in the filters
 */
export const getAllMetrics = (
    metricsFilterGroup: FilterGroup | undefined,
    eventPropertiesFilterGroup: FilterGroup | undefined,
) => {
    if (!metricsFilterGroup || !eventPropertiesFilterGroup) return undefined;
    if (
        isAndFilterGroup(metricsFilterGroup) &&
        isAndFilterGroup(eventPropertiesFilterGroup)
    ) {
        return {
            ...metricsFilterGroup,
            and: [...metricsFilterGroup.and, ...eventPropertiesFilterGroup.and],
        };
    } else if (
        isOrFilterGroup(metricsFilterGroup) &&
        isOrFilterGroup(eventPropertiesFilterGroup)
    ) {
        return {
            ...metricsFilterGroup,
            or: [...metricsFilterGroup.or, ...eventPropertiesFilterGroup.or],
        };
    }
    return undefined;
};
/**
 * this function is to tell weather event is a campaign event
 * @param item
 * @returns
 */
export const isCampaignEvent = (item: FilterableField) => {
    return item.table === CAMPAIGN_EVENT_TABLE_NAME;
};

/**
 * this function is used to check if the field can be converted to an additional metric to achieve records in related tables, and events.
 * @param {FilterableField} field
 * @param {CompiledRelation} activeRelation
 * @returns {boolean} it returns true if the field can be converted to an additional metric
 */
export const shouldConvertFieldToAdditionalMetric = (
    field: FilterableField,
    activeRelation: CompiledRelation,
): boolean => {
    if (!field || !field.table) return false;
    if (
        activeRelation.tables[field.table]?.type === RelationTableType.EVENT &&
        field.type === DimensionType.EVENT
    )
        return true;
    const joinType = getTableRelationType(field.table, activeRelation);
    if (joinType === JoinType.one_many) return true;
    if (isCampaignEvent(field)) return true;
    return false;
};

const getCustomMetricDescription = (
    metricType: MetricType,
    label: string,
    tableLabel: string,
    filters: MetricFilterRule[],
) =>
    `${friendlyName(metricType)} of ${label} on the table ${tableLabel} ${
        filters.length > 0
            ? `with filters ${filters
                  .map((filter) => filter.target.fieldRef)
                  .join(', ')}`
            : ''
    }`;

/**
 * this function is used to create the additional metric from campaign event
 * @param field
 * @param type
 * @param metricFilters
 * @param activeRelation
 * @returns it returns the additional metric from campaign event
 */
export const createAdditionalMetricFromCampaignEvent = (
    field: FieldWithSuggestions,
    type: MetricType,
    metricFilters: MetricFilterRuleWithFieldId[],
    projectUuid: string,
) => {
    const customMetricFilters: MetricFilterRule[] = metricFilters.map(
        ({ target: { fieldId, ...restTarget }, ...customMetricFilter }) => ({
            ...customMetricFilter,
            target: restTarget,
        }),
    );
    let name =
        getCustomMetricName(
            CAMPAIGN_EVENT_TABLE_NAME,
            ReservedEventColumns.EVENT_ID,
            isAdditionalMetric(field) &&
                isAdditionalMetric(field) &&
                'baseDimensionName' in field &&
                field.baseDimensionName
                ? field.baseDimensionName
                : ReservedEventColumns.EVENT_ID,
        ) +
        '_' +
        generateShortUUID();

    // Some warehouses don't support long names, so we need to truncate these custom metrics if the name is too long and make it unique
    if (name.length >= 64) {
        name = name.slice(0, 52) + '_' + generateShortUUID();
    }
    return {
        uuid: uuidv4(),
        baseDimensionName: ReservedEventColumns.EVENT_ID,
        table: getDedupedEventTableName(projectUuid),
        sql: `$\{TABLE}.${ReservedEventColumns.EVENT_ID}`,
        type,
        filters: customMetricFilters.length > 0 ? customMetricFilters : [],
        label: field.label,
        name,

        description: getCustomMetricDescription(
            type,
            ReservedEventColumns.EVENT_ID,
            field.label ?? '',
            customMetricFilters,
        ),
    };
};

/**
 * this function is used to prepare the additional metric data
 * @param item
 * @param type
 * @param metricFilters
 * @param activeRelation
 * @returns it returns the additional metric data
 */
export const prepareAdditionalMetricData = ({
    item,
    type,
    metricFilters,
    activeRelation,
    projectUuid,
}: {
    item: FilterableField;
    type: MetricType;
    metricFilters: MetricFilterRuleWithFieldId[];
    activeRelation: CompiledRelation;
    projectUuid: string;
}): AdditionalMetric | undefined => {
    if (isCampaignEvent(item)) {
        return createAdditionalMetricFromCampaignEvent(
            item,
            type,
            metricFilters,
            projectUuid,
        );
    }
    if (!item.table || !item.name || !activeRelation.tables[item.table]) return;
    const customMetricFilters: MetricFilterRule[] = metricFilters.map(
        ({ target: { fieldId, ...restTarget }, ...customMetricFilter }) => ({
            ...customMetricFilter,
            target: restTarget,
        }),
    );
    const table = item.table && activeRelation.tables[item.table];
    if (!table || !item.name || !table.name || !table.primaryKey) return;
    let name =
        getCustomMetricName(
            item.table,
            table.primaryKey,
            isAdditionalMetric(item) &&
                isAdditionalMetric(item) &&
                'baseDimensionName' in item &&
                item.baseDimensionName
                ? item.baseDimensionName
                : table.primaryKey,
        ) +
        '_' +
        generateShortUUID();

    // Some warehouses don't support long names, so we need to truncate these custom metrics if the name is too long and make it unique
    if (name.length >= 64) {
        name = name.slice(0, 52) + '_' + generateShortUUID();
    }
    return {
        uuid: uuidv4(),
        baseDimensionName: table.primaryKey,
        table: item.table,
        sql: item.sql,
        type,
        filters: customMetricFilters.length > 0 ? customMetricFilters : [],
        label: table.primaryKey,
        name: name,

        description: getCustomMetricDescription(
            type,
            table.primaryKey,
            table.label,
            customMetricFilters,
        ),
    };
};

export const isDerivedAdditionalMetric = (
    field: FilterableField,
    additionalMetrics: AdditionalMetric[],
) => {
    return additionalMetrics.some(
        (metric) => metric.name === field.name && metric.table === field.table,
    );
};

/**
 * this function takes the fieldsIds and give back dimensions array and metrics in it
 * @params fieldsIds
 * @params fields
 * @returns dimensions array and metrics array
 */
export const getDimensionsAndMetrics = (
    fieldsIds: string[],
    fields: FieldsWithSuggestions,
) => {
    const dimensions: string[] = [];
    const metrics: AdditionalMetricPreview[] = [];
    fieldsIds.forEach((fieldId) => {
        if (isDimension(fields[fieldId])) {
            dimensions.push(fieldId);
        } else if (isMetric(fields[fieldId])) {
            metrics.push({
                metric: fields[fieldId].name,
                table: fields[fieldId].table ?? '',
            });
        }
    });
    return { dimensions, metrics };
};
