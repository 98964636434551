import { useLocale } from '@hooks/useLocale';
import { Box, Text } from '@mantine/core';
import { CursorClick, Scroll } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { useCallback, useState } from 'react';
import { ControlPanel, TriggerType } from '../../types';

interface TriggerListProps {
    customClass?: string;
    setShowEventsList: (show: boolean) => void;
    setTriggerType: (triggerType: TriggerType) => void;
}

const TriggerList = ({
    setShowEventsList,
    customClass,
    setTriggerType,
}: TriggerListProps) => {
    const { t } = useLocale();

    const { openControlPanel } = useJourneyBuilderContext(
        (context) => context.actions,
    );

    const [isHovered, setIsHovered] = useState({
        userAction: false,
        businessEvent: false,
    });

    const handleActionClick = useCallback(
        (triggerType: TriggerType) => {
            setShowEventsList(true);
            setTriggerType(triggerType);
            openControlPanel({
                type: ControlPanel.TRIGGERS,
            });
        },
        [openControlPanel, setShowEventsList, setTriggerType],
    );

    return (
        <Box className={`${customClass}`}>
            <Box
                onClick={() => handleActionClick(TriggerType.USER_ACTION)}
                onMouseEnter={() =>
                    setIsHovered({ userAction: true, businessEvent: false })
                }
                onMouseLeave={() =>
                    setIsHovered({ userAction: false, businessEvent: false })
                }
                className="group gap-1 p-3 border border-gray-200 m-3 rounded-lg cursor-pointer hover:border-blu-800 hover:bg-blu-800/4"
            >
                <Box className="p-2 border border-gray-250 rounded-md w-fit group-hover:border-blu-800">
                    <CursorClick
                        size={18}
                        color={
                            isHovered.userAction
                                ? 'rgb(var(--color-blu-800))'
                                : 'rgb(var(--color-gray-500))'
                        }
                    />
                </Box>
                <Text className="text-sm font-semibold text-gray-800 mb-1 mt-3">
                    {t('trigger_list.user_action')}
                </Text>
                <Text className="text-xs font-normal text-gray-600">
                    {t('trigger_list.user_action_description')}
                </Text>
            </Box>
            <Box
                onClick={() => handleActionClick(TriggerType.BUSINESS_EVENT)}
                onMouseEnter={() =>
                    setIsHovered({ userAction: false, businessEvent: true })
                }
                onMouseLeave={() =>
                    setIsHovered({ userAction: false, businessEvent: false })
                }
                className="group gap-1 p-3 border border-gray-200 m-3 rounded-lg cursor-pointer hover:border-blu-800 hover:bg-blu-800/4"
            >
                <Box className="p-2 border border-gray-250 rounded-md w-fit group-hover:border-blu-800">
                    <Scroll
                        size={18}
                        color={
                            isHovered.businessEvent
                                ? 'rgb(var(--color-blu-800))'
                                : 'rgb(var(--color-gray-500))'
                        }
                    />
                </Box>
                <Text className="text-sm font-semibold text-gray-800 mb-1 mt-3">
                    {t('trigger_list.business_event')}
                </Text>
                <Text className="text-xs font-normal text-gray-600">
                    {t('trigger_list.business_event_description')}
                </Text>
            </Box>
        </Box>
    );
};

export default TriggerList;
