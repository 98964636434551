import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import useRelation from '@hooks/useRelation';
import { useRelations } from '@hooks/useRelations';
import {
    RelationTableType,
    type CompiledRelationTable,
    type JoinType,
} from '@lightdash/common';
import useProjectContext from '@providers/Project/useProjectContext';
import { useCallback, useEffect, useState, type FC } from 'react';
import { useParams } from 'react-router';
import RelationProviderContext from './context';
import { type TableRelationProps } from './types';

export const RelationProvider: FC<React.PropsWithChildren> = ({ children }) => {
    const [defaultRelationUuid, setDefaultRelationUuid] = useState<string>('');
    const { projectData } = useProjectContext();
    const params = useParams<{ projectUuid?: string }>();

    const { data: relations, isInitialLoading } = useRelations({
        projectId: params.projectUuid,
        isEnabled:
            projectData?.needsRelation === undefined
                ? true
                : !projectData.needsRelation,
    });

    const { data: activeRelationData } = useRelation(defaultRelationUuid);

    useEffect(() => {
        if (!relations) return;
        const defaultRelation = relations[0];
        setDefaultRelationUuid(defaultRelation?.uuid);
    }, [relations]);

    const { t } = useLocale();
    const { showToastError } = useNotify();

    const getTableRelation = useCallback(
        (relationTypes: JoinType[]) => {
            if (activeRelationData) {
                const { tables, joinedTables } = activeRelationData;
                const result: TableRelationProps[] = [];
                const primaryTable = Object.values(tables).find(
                    (table) => table.type === RelationTableType.PRIMARY,
                );

                if (!primaryTable) {
                    showToastError({
                        title: t('relation_provider.tables_not_found'),
                    });

                    return result;
                }

                const queue: CompiledRelationTable[] = [primaryTable];

                while (queue.length > 0) {
                    const currentTable = queue.shift();
                    if (currentTable) {
                        const filteredDimensions = Object.values(
                            currentTable.dimensions,
                        ).filter((dimension) => !dimension.hidden);
                        result.push({
                            name: currentTable.name,
                            dimensions: filteredDimensions,
                            description: currentTable.description ?? '',
                            type: currentTable.type,
                            metrics: Object.values(currentTable.metrics),
                        });
                    }

                    joinedTables.forEach((join) => {
                        if (
                            join.source.table === currentTable?.name &&
                            relationTypes.includes(join.relationType)
                        ) {
                            const targetTableName = join.target.table;

                            const targetTableObject = Object.values(
                                tables,
                            ).find((table) => table.name === targetTableName);

                            if (targetTableObject) {
                                if (!queue.includes(targetTableObject)) {
                                    queue.push(targetTableObject);
                                }
                            }
                        }
                    });
                }
                return result;
            }
        },
        [activeRelationData, showToastError, t],
    );

    const value = {
        relations,
        activeRelation: activeRelationData,
        activeRelationUuid: defaultRelationUuid,
        getTableRelation,
        tables: Object.values(activeRelationData?.tables ?? {}).map(
            ({
                database,
                dimensions,
                label,
                name,
                schema,
                type,
                isConfigured,
            }) => {
                return {
                    database,
                    columns: Object.values(dimensions).map((item) => {
                        return {
                            label: item.label,
                            name: item.name,
                            type: item.type,
                            hidden: item.hidden,
                        };
                    }),
                    label,
                    tableLabel: label,
                    name,
                    schema,
                    type,
                    isConfigured,
                };
            },
        ),
    };
    if (isInitialLoading) return null;
    return (
        <RelationProviderContext.Provider value={value}>
            {children}
        </RelationProviderContext.Provider>
    );
};
