import {
    convertFieldsIntoPropertySelectListType,
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import { ListSelectItem } from '@components/Audience/Filters/FilterSelect';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import FilterSelectWrapper from '@components/common/Filters/FilterInputs/FilterSelectWrapper';
import PropertySelect from '@components/common/Select/PropertySelect';
import { FiltersPropertySelectorFieldItem } from '@components/Journeys/Builder/JourneyFilters/FiltersForm/FiltersPropertySelector';
import { useLocale } from '@hooks/useLocale';
import { RelationTableType } from '@lightdash/common';
import { Flex, Group, Stack, Text, type SelectProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { WarningCircle } from '@phosphor-icons/react';
import { useMemo } from 'react';
import { ChevronDown } from 'react-feather';
import {
    type AddditionalPropertySelectListProps,
    type PropertySelectListType,
} from '../PropertySelect/type';

const WIDTH = 450;

type FieldSelectItem = FieldWithSuggestions &
    AddditionalPropertySelectListProps;

type FieldSelectProps = Omit<SelectProps, 'value' | 'data' | 'onChange'> & {
    item: FieldWithSuggestions | undefined;
    items: FieldWithSuggestions[];
    inactiveItemIds?: string[];
    onChange: (value: FieldWithSuggestions) => void;
    onClosed?: () => void;
    hasGrouping?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    error?: string;
    hideLabel?: boolean;
    hideTableLabel?: boolean;
    labelCustomClasses?: string;
    tableLabelCustomClasses?: string;
    tableLabelInListCustomClasses?: string;
    additionalPropertySelectList?: PropertySelectListType<
        FieldWithSelectStatus,
        PropertySelectGroupType
    >[];
};

const FieldSelect = ({
    item,
    items,
    onChange,
    isDisabled = false,
    placeholder = '',
    error,
    hideLabel = false,
    hideTableLabel = false,
    labelCustomClasses,
    tableLabelCustomClasses,
    tableLabelInListCustomClasses,
    additionalPropertySelectList,
}: FieldSelectProps) => {
    const [opened, { open, close }] = useDisclosure();

    const { t } = useLocale();

    const targetButton = useMemo(() => {
        return (
            <Stack className="gap-1.5 w-fit">
                <FilterSelectWrapper isEditMode={!isDisabled}>
                    <Flex justify="space-between" align="center">
                        {item?.defaultFieldRender ?? (
                            <ListSelectItem
                                item={item}
                                divider={
                                    <span className="text-sm text-gray-600">
                                        in
                                    </span>
                                }
                                placeholder={placeholder}
                                hideLabel={hideLabel}
                                hideTableLabel={hideTableLabel}
                                labelCustomClasses={labelCustomClasses}
                                tableLabelCustomClasses={
                                    tableLabelCustomClasses
                                }
                            />
                        )}

                        <ChevronDown
                            color={'rgb(var(--color-gray-600)'}
                            size={13}
                            strokeWidth={2}
                        />
                    </Flex>
                </FilterSelectWrapper>
                {error && (
                    <Group className="gap-1">
                        <WarningCircle color={'rgb(var(--color-halt-800)'} />
                        <Text className="text-sm font-medium text-halt-800">
                            {error}
                        </Text>
                    </Group>
                )}
            </Stack>
        );
    }, [
        error,
        hideLabel,
        hideTableLabel,
        isDisabled,
        item,
        labelCustomClasses,
        placeholder,
        tableLabelCustomClasses,
    ]);

    return (
        <PropertySelect<FieldSelectItem>
            items={[
                ...convertFieldsIntoPropertySelectListType([...items], true),
                ...(additionalPropertySelectList ?? []),
            ]}
            showGroup={true}
            headerRightSection={null}
            onSubmit={(field: FieldSelectItem[]) => {
                if (!field[0]) return;
                close();
                onChange(field[0]);
            }}
            itemTemplate={({ item: field }) => {
                return (
                    <FiltersPropertySelectorFieldItem
                        item={field}
                        tableLabelCustomClasses={tableLabelInListCustomClasses}
                        showSourceTable={
                            field.tableType === RelationTableType.EVENT
                        }
                    />
                );
            }}
            opened={opened}
            close={close}
            open={open}
            withinPortal={true}
            targetButton={targetButton}
            showSearch={true}
            searchKeys={['label', 'name', 'tableLabel']}
            searchPlaceholder={t('audience_filters.search_filter_label')}
            allowMultipleSelection={false}
            showAllItemsGroup={true}
            width={WIDTH}
            height={250}
        />
    );
};

export default FieldSelect;
