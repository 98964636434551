import { useLocale } from '@hooks/useLocale';
import { type ProjectMemberProfileWithInviteInformation } from '@lightdash/common';
import { Button, Flex, Menu } from '@mantine/core';
import { CaretDown, Check } from '@phosphor-icons/react';
import { useMemo, useState } from 'react';
import { ButtonVariant } from '../../mantineTheme';

interface AuditLogsUserProps {
    selectedUser: string;
    users: ProjectMemberProfileWithInviteInformation[];
    onUserChange: (newUser: string) => void;
}

const AuditLogsUser = ({
    users,
    onUserChange,
    selectedUser,
}: AuditLogsUserProps) => {
    const { t } = useLocale();
    const userDetail = useMemo(() => {
        if (selectedUser === '') {
            return null;
        }
        const data = users.find((user) => user.userUuid === selectedUser);
        return `${data?.firstName} ${data?.lastName}`;
    }, [users, selectedUser]);

    const [currentSelectedUser, setCurrentSelectedUser] = useState<
        string | null
    >(userDetail);
    return (
        <Menu
            shadow="md"
            width={150}
            classNames={{ dropdown: 'h-[200px] overflow-y-auto' }}
        >
            <Menu.Target>
                <Button
                    variant={ButtonVariant.OUTLINED}
                    rightIcon={<CaretDown weight="duotone" />}
                >
                    {currentSelectedUser
                        ? currentSelectedUser
                        : t('alert_settings.th_user')}
                </Button>
            </Menu.Target>

            <Menu.Dropdown>
                {users.map((user) => (
                    <Menu.Item
                        key={user.userUuid}
                        onClick={() => {
                            onUserChange(user.userUuid);
                            setCurrentSelectedUser(
                                user.firstName + ' ' + user.lastName,
                            );
                        }}
                    >
                        <Flex
                            className="w-full text-gray-800"
                            justify="space-between"
                        >
                            {user.firstName} {user.lastName}
                            {user.userUuid === selectedUser && (
                                <Check weight="bold" />
                            )}
                        </Flex>
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
};

export default AuditLogsUser;
