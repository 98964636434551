import {
    CampaignThrottleType,
    type ChannelIntegrationDetails,
    type Throttle,
} from '@lightdash/common';
import { Box, Group, Radio, Text } from '@mantine/core';
import React, { useMemo, useState } from 'react';

import Checkbox from '@components/common/Checkbox';
import InputErrorText from '@components/common/InputErrorText';
import TextInputVariableWidth from '@components/common/Inputs/TextInputVariableWidth';
import { useLocale } from '@hooks/useLocale';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';

const MIN_INTERVAL_VALUE = 15;
const MAX_INTERVAL_VALUE = 180;
const MIN_BATCH_SIZE = 100;

const ThrottleConfig = () => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload } = state;
    const { setThrottleConfig } = actions;
    const [error, setError] = useState({
        interval: '',
        batch: '',
    });
    const queryClient = useQueryClient();

    const integrationDetails = useMemo(
        () =>
            queryClient.getQueryData([
                QueryKeys.INTEGRATED_CHANNELS,
                campaignPayload.communicationDetails?.providerId,
                projectUuid,
            ]) as ChannelIntegrationDetails & { throttle: Throttle },
        [
            campaignPayload.communicationDetails?.providerId,
            projectUuid,
            queryClient,
        ],
    );

    const handleRadioInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === CampaignThrottleType.BATCH) {
            setThrottleConfig({
                type: CampaignThrottleType.BATCH,
                batch: 100,
            });
            if (error.interval) {
                setError({
                    ...error,
                    interval: '',
                });
            }
            return;
        }
        if (e.target.value === CampaignThrottleType.TIME_INTERVAL) {
            setThrottleConfig({
                type: CampaignThrottleType.TIME_INTERVAL,
                interval: 60,
            });
            if (error.batch) {
                setError({
                    ...error,
                    batch: '',
                });
            }
        }
    };

    const handleThrottleInput = (value: number) => {
        if (
            campaignPayload.throttleConfig?.type === CampaignThrottleType.BATCH
        ) {
            setThrottleConfig({
                batch: value,
                type: CampaignThrottleType.BATCH,
            });
            if (integrationDetails) {
                const integrationBatchPerMin =
                    (integrationDetails.throttle.batch /
                        integrationDetails.throttle.interval) *
                    60;
                if (integrationBatchPerMin < value) {
                    setError({
                        ...error,
                        batch: t(
                            'campaign_builder.throttling.batch_error.max',
                            { value: Math.floor(integrationBatchPerMin) },
                        ),
                    });
                    return;
                }
            }
            if (value < MIN_BATCH_SIZE) {
                setError({
                    ...error,
                    batch: t('campaign_builder.throttling.batch_error.min', {
                        value: MIN_BATCH_SIZE,
                    }),
                });
                return;
            }
            if (error.batch) {
                setError({
                    ...error,
                    batch: '',
                });
            }
            return;
        }
        if (
            campaignPayload.throttleConfig?.type ===
            CampaignThrottleType.TIME_INTERVAL
        ) {
            setThrottleConfig({
                interval: value,
                type: CampaignThrottleType.TIME_INTERVAL,
            });
            if (value < MIN_INTERVAL_VALUE) {
                setError({
                    ...error,
                    interval: t(
                        'campaign_builder.throttling.interval_error.min',
                        { value: MIN_INTERVAL_VALUE },
                    ),
                });
                return;
            }
            if (value > MAX_INTERVAL_VALUE) {
                setError({
                    ...error,
                    interval: t(
                        'campaign_builder.throttling.interval_error.max',
                        { value: MAX_INTERVAL_VALUE },
                    ),
                });
                return;
            }
            if (error.interval) {
                setError({
                    ...error,
                    interval: '',
                });
            }
        }
    };

    return (
        <Box className="w-[36rem] mt-6">
            <Text className="mb-6 text-lg text-gray-800">
                Advanced Settings
            </Text>
            <Checkbox
                value={
                    Boolean(campaignPayload.throttleConfig) as unknown as string
                }
                onChange={(e) => {
                    if (e.target.checked) {
                        setThrottleConfig({
                            type: CampaignThrottleType.TIME_INTERVAL,
                            interval: 60,
                        });
                    } else {
                        setThrottleConfig(undefined);
                    }
                }}
                label="Throttle send speed"
            />
            {campaignPayload.throttleConfig && (
                <Box className="p-2 mt-2 border-base rounded-xl">
                    <Text className="mb-2 font-medium text-gray-800">
                        Custom throttle setting
                    </Text>
                    <Box className="px-3 py-2 mb-2 border-base">
                        <Radio
                            checked={
                                campaignPayload.throttleConfig.type ===
                                CampaignThrottleType.TIME_INTERVAL
                            }
                            value={CampaignThrottleType.TIME_INTERVAL}
                            onChange={handleRadioInput}
                            label="Spread across a time period"
                        />
                        {campaignPayload.throttleConfig.type ===
                            CampaignThrottleType.TIME_INTERVAL && (
                            <Group className="mt-2">
                                <Text className="text-gray-600">
                                    Send messages for
                                </Text>
                                <TextInputVariableWidth
                                    onChange={(e) =>
                                        handleThrottleInput(
                                            Number(e.target.value),
                                        )
                                    }
                                    value={
                                        campaignPayload.throttleConfig.type ===
                                        CampaignThrottleType.TIME_INTERVAL
                                            ? campaignPayload.throttleConfig
                                                  .interval
                                            : 0
                                    }
                                />
                                <Text className="text-gray-600">minutes</Text>
                            </Group>
                        )}
                        {error.interval && (
                            <InputErrorText
                                className="mt-2"
                                value={error.interval}
                            />
                        )}
                    </Box>
                    <Box className="px-3 py-2 border-base">
                        <Radio
                            checked={
                                campaignPayload.throttleConfig.type ===
                                CampaignThrottleType.BATCH
                            }
                            value={CampaignThrottleType.BATCH}
                            onChange={handleRadioInput}
                            label="Spread in batches"
                        />
                        {campaignPayload.throttleConfig.type ===
                            CampaignThrottleType.BATCH && (
                            <Group className="mt-2">
                                <Text className="text-gray-600">Send</Text>
                                <TextInputVariableWidth
                                    onChange={(e) =>
                                        handleThrottleInput(
                                            Number(e.target.value),
                                        )
                                    }
                                    value={
                                        campaignPayload.throttleConfig.type ===
                                        CampaignThrottleType.BATCH
                                            ? campaignPayload.throttleConfig
                                                  .batch
                                            : 0
                                    }
                                />
                                <Text className="text-gray-600">
                                    messages every minute
                                </Text>
                            </Group>
                        )}
                        {error.batch && (
                            <InputErrorText
                                className="mt-2"
                                value={error.batch}
                            />
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ThrottleConfig;
