import FieldListItem from '@components/Audience/Filters/FieldListItem';
import { convertFieldsIntoPropertySelectListType } from '@components/Audience/Filters/FieldListItem/utils';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import { type FieldWithSuggestions } from '@components/Explorer/FiltersCard/useFieldsWithSuggestions';
import { toFieldsWithSuggestions } from '@components/Profiles/utils';
import { useLocale } from '@hooks/useLocale';
import { JoinType } from '@lightdash/common';
import useRelationContext from '../../../providers/Relation/useRelationContext';

interface VariablePropertySelectProps {
    onSubmit: (item: FieldWithSuggestions) => void;
    opened: boolean;
    close: () => void;
    open: () => void;
    targetButton: React.ReactNode;
}
const VariablePropertySelect = ({
    onSubmit,
    opened,
    close,
    open,
    targetButton,
}: VariablePropertySelectProps) => {
    const { t } = useLocale();
    const { getTableRelation, activeRelation } = useRelationContext();

    const filteredRelation = getTableRelation([
        JoinType.one_one,
        JoinType.many_one,
    ]);
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });
    const fields = toFieldsWithSuggestions(
        filteredRelation,
        fieldsWithSuggestions,
    );
    return (
        <>
            <PropertySelect<
                FieldWithSuggestions & AddditionalPropertySelectListProps
            >
                items={convertFieldsIntoPropertySelectListType(
                    Object.values(fields),
                    true,
                )}
                showGroup={true}
                onSubmit={(items: FieldWithSuggestions[]) => {
                    onSubmit(items[0]);
                }}
                headerRightSection={null}
                itemTemplate={({ item }) => {
                    return (
                        <FieldListItem
                            item={item}
                            checked={false}
                            showCheckbox={false}
                            disabled={item.isDisabled ?? false}
                        />
                    );
                }}
                opened={opened}
                close={close}
                open={open}
                targetButton={targetButton}
                showSearch={true}
                searchKeys={['label', 'tableLabel']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={false}
            />
        </>
    );
};

export default VariablePropertySelect;
