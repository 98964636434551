import LinkButton from '@components/common/LinkButton';
import { useLocale } from '@hooks/useLocale';
import { Box, createStyles, Flex, Menu, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Gear, SignOut, UserPlus } from '@phosphor-icons/react';
import posthog from 'posthog-js';
import { useMemo, type FC } from 'react';
import InviteMemberModal from '../Workspace/WorkspaceMembers/InviteWorkspaceMemberModal';
const useStyles = createStyles((_theme) => ({
    dropdown: {
        width: '220px !important',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));
import { useParams } from 'react-router';

import useApp from '@providers/App/useApp';
import useLogoutMutation from '../../hooks/user/useUserLogoutMutation';
import { UserAvatar } from '../Avatar';

const UserMenu: FC = () => {
    const { user } = useApp();
    const { classes } = useStyles();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const userDetails = useMemo(() => {
        return {
            name: `${user.data?.firstName ?? ''} ${user.data?.lastName ?? ''}`,
            email: user.data?.email ?? '',
        };
    }, [user]);
    const { t } = useLocale();
    const { mutate: logout } = useLogoutMutation({
        onSuccess: () => {
            posthog.reset();
            window.location.href = '/login';
        },
    });

    const [openedInviteModal, { open, close }] = useDisclosure(false);

    if (!projectUuid) return null;
    return (
        <>
            <Menu
                shadow="lg"
                classNames={classes}
                position="bottom-end"
                arrowOffset={16}
                offset={6}
            >
                <Menu.Target>
                    <UserAvatar />
                </Menu.Target>

                <Menu.Dropdown className="overflow-visible">
                    <Menu.Item className="cursor-default ">
                        <Flex className="flex items-center justify-between w-full gap-2 py-1 ">
                            <Box>
                                <UserAvatar className="w-8 h-8 cursor-default" />
                            </Box>
                            <Box>
                                <Text className="text-sm font-medium text-gray-800 truncate w-[150px]">
                                    {userDetails.name}
                                </Text>
                                <Tooltip
                                    label={userDetails.email}
                                    position="bottom"
                                    withArrow
                                    color="blue"
                                    transitionProps={{
                                        transition: 'pop',
                                        duration: 1000,
                                    }}
                                >
                                    <Text className="text-xs font-semibold text-gray-600 truncate w-[150px]">
                                        {userDetails.email}
                                    </Text>
                                </Tooltip>
                            </Box>
                        </Flex>
                    </Menu.Item>

                    <Box className="px-3">
                        <Menu.Divider className="border border-shade-2" />
                    </Box>

                    <Menu.Item
                        role="menuitem"
                        icon={
                            <Gear
                                size={14}
                                strokeWidth={2.5}
                                color={'rgb(var(--color-gray-600))'}
                            />
                        }
                        className="text-sm font-medium text-gray-800 h-[36px]"
                    >
                        <LinkButton
                            href={'/account/profile'}
                            className="text-sm font-medium text-gray-800"
                        >
                            {t('account_settings.menu_item_account_setting')}
                        </LinkButton>
                    </Menu.Item>

                    {user.data?.ability?.can('create', 'InviteLink') ? (
                        <Menu.Item
                            role="menuitem"
                            icon={
                                <UserPlus
                                    size={14}
                                    strokeWidth={2.5}
                                    color={'rgb(var(--color-gray-600))'}
                                />
                            }
                            onClick={open}
                            className="text-sm font-medium text-gray-800"
                        >
                            {t('account_settings.menu_item_invite_teammate')}
                        </Menu.Item>
                    ) : null}
                    <Box>
                        <Menu.Divider className="border border-shade-2" />
                    </Box>
                    <Menu.Item
                        role="menuitem"
                        onClick={() => logout()}
                        icon={
                            <SignOut
                                size={14}
                                strokeWidth={2.5}
                                color={'rgb(var(--color-gray-600))'}
                            />
                        }
                        className="text-sm font-medium text-gray-800"
                    >
                        {t('account_settings.menu_item_sign_out')}
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
            <InviteMemberModal
                projectUuid={projectUuid}
                opened={openedInviteModal}
                onClose={close}
            />
        </>
    );
};

export default UserMenu;
