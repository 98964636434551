import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import Timestamp from '@components/common/Timestamp';
import {
    AudienceType,
    CampaignScheduleType,
    CampaignStatus,
    CampaignType,
    CommonReservedTags,
    type CommunicationChannel,
    type ReducedCampaign,
} from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { Archive } from '@phosphor-icons/react';
import { type ColumnDef } from '@tanstack/react-table';
import { t as translate } from 'i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import AudienceAndAnalyticsCell from './AudienceAndAnalyticsCell';
import CampaignCurrentStatus from './CampaignCurrentStatus';
import CampaignMenuItem from './CampaignMenuItem';

const CAMPAIGN_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const CAMPAIGN_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;

export const useCampaignColumns = () => {
    const [columns, setColumns] = useState<ColumnDef<ReducedCampaign>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: translate('campaign_manager.th_campaign_name'),
                    cell: ({ row, getValue }) => (
                        <Box className={CAMPAIGN_TABLE_CELL_CLASSNAME}>
                            <Flex align={'center'} gap={6}>
                                {row.original.tags?.includes(
                                    CommonReservedTags.HIDDEN,
                                ) && <Archive weight="duotone" />}
                                <Text className={CAMPAIGN_TABLE_CELL_NAME}>
                                    {getValue<string>()}
                                </Text>
                            </Flex>
                            <ChannelIcon
                                channel={
                                    row?.original
                                        ?.channel as CommunicationChannel
                                }
                                color={'rgb(var(--color-gray-600)'}
                                className="text-xs"
                            />
                        </Box>
                    ),
                },
                {
                    accessorKey: 'scheduledAt',
                    header: translate('campaign_manager.th_status'),
                    cell: ({ row }) => {
                        const { original } = row;
                        const status = original?.status;
                        return (
                            <CampaignCurrentStatus
                                status={status}
                                onFailureReasonClick={undefined}
                            />
                        );
                    },
                },
                {
                    accessorKey: '',
                    header: translate('campaign_manager.th_scheduled_date'),
                    cell: ({ row }) => (
                        <Box className={CAMPAIGN_TABLE_CELL_CLASSNAME}>
                            <Text className={CAMPAIGN_TABLE_CELL_NAME}>
                                {row.original.status ===
                                    CampaignStatus.DRAFT && <Text>-</Text>}
                                {row.original.schedule &&
                                    row.original.schedule.type ===
                                        CampaignScheduleType.MANUAL &&
                                    (row.original.schedule.executionTime ? (
                                        <Box className="flex flex-col">
                                            <Timestamp
                                                timestamp={row.original.schedule.executionTime.toString()}
                                                customClass="!text-gray-800"
                                            />
                                            <Text className="text-xs font-normal text-gray-600">
                                                {translate(
                                                    'campaign_manager.tr_sent_once',
                                                )}
                                            </Text>
                                        </Box>
                                    ) : (
                                        <Text>-</Text>
                                    ))}

                                {row.original.schedule &&
                                    row.original.schedule.type ===
                                        CampaignScheduleType.DELAYED &&
                                    row.original.schedule.executionTime && (
                                        <Box className="flex flex-col">
                                            <Timestamp
                                                timestamp={row.original.schedule.executionTime.toString()}
                                                customClass="!text-gray-800"
                                            />
                                            {row.original.type ===
                                                CampaignType.RECURRING && (
                                                <Box className="flex">
                                                    <Text className="text-xs font-normal text-gray-600">
                                                        {translate(
                                                            'campaign_manager.tr_recurring_schedule',
                                                        )}
                                                    </Text>
                                                    <Text className="text-xs font-normal text-gray-600">
                                                        {'·'}
                                                        {row.original.schedule
                                                            .children &&
                                                        row.original.schedule
                                                            .children > 1
                                                            ? `${translate(
                                                                  'campaign_manager.tr_sent',
                                                              )} ${
                                                                  row.original
                                                                      .schedule
                                                                      .children -
                                                                  1
                                                              } ${translate(
                                                                  'campaign_manager.tr_times',
                                                              )}`
                                                            : translate(
                                                                  'campaign_manager.tr_yet_to_start',
                                                              )}
                                                    </Text>
                                                </Box>
                                            )}
                                            {row.original.type ===
                                                CampaignType.ONE_TIME && (
                                                <Text className="text-xs font-normal text-gray-600">
                                                    {translate(
                                                        'campaign_manager.tr_one_time',
                                                    )}
                                                </Text>
                                            )}
                                        </Box>
                                    )}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'audienceCount',
                    header: translate('campaign_manager.th_audience_count'),
                    cell: ({ getValue, row }) => (
                        <AudienceAndAnalyticsCell
                            row={row}
                            count={
                                (row.original.audienceType ===
                                AudienceType.WAREHOUSE
                                    ? getValue<number>()
                                    : row.original.csvUploadDetails
                                          ?.audienceCount) || 0
                            }
                        />
                    ),
                },
                {
                    accessorKey: 'createdAt',
                    header: translate('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`by ${
                                    row?.original?.createdBy?.firstName || ''
                                } ${row?.original?.createdBy?.lastName || ''}`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => (
                        <CampaignMenuItem rowData={row?.original} />
                    ),
                },
            ]);
        }
    }, [projectUuid]);

    return columns;
};
