import BuilderStepTitle from '@components/common/BuilderContainer/BuilderStepTitle';
import { EventBuilderStep } from '@components/EventsManager/types';
import { EventBuilderSteps } from '@components/EventsManager/utils';
import { EventType } from '@lightdash/common';
import { Box } from '@mantine/core';
import useEventContext from '@providers/Events/useEventContext';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import MultipleEventReview from './MultipleEventReview';
import SingleEventReview from './SingleEventReview';

const EventBuilderReview: React.FC<{}> = ({}) => {
    const [type, _setType] = useState<EventType>(EventType.SINGLE);
    const title = EventBuilderSteps.find(
        (step) => step.key === EventBuilderStep.REVIEW,
    )?.pageHeader;
    const { state, actions } = useEventContext((context) => context);
    const { eventPayload, isViewMode, initialEventPayload } = state;
    const { setShowFooterButtons } = actions;
    useEffect(() => {
        if (
            eventPayload.eventName.length > 0 &&
            eventPayload.label &&
            eventPayload.label.length > 0 &&
            !isViewMode
        ) {
            if (_.isEqual(initialEventPayload, eventPayload))
                setShowFooterButtons({
                    next: true,
                    disableNext: true,
                    back: true,
                });
            else {
                setShowFooterButtons({
                    next: true,
                    disableNext: false,
                    back: true,
                });
            }
        } else {
            setShowFooterButtons({
                next: false,
                disableNext: false,
                back: true,
            });
        }
    }, [eventPayload, initialEventPayload, isViewMode, setShowFooterButtons]);
    return (
        <Box>
            <BuilderStepTitle title={title ?? ''} />
            {
                //TODO: disabled uploading csv file into the event mapper for now.
            }

            {/* <Radio.Group
                value={type}
                onChange={(data) => setType(data as EventType)}
                className="mt-4 mb-6"
            >
                <Flex
                    direction={'column'}
                    gap={12}
                    className="font-medium text-gray-800"
                >
                    <Radio
                        label={t('event_create.review_single_radio_label')}
                        value={EventType.SINGLE}
                    />
                    <Radio
                        label={t('event_create.review_multiple_radio_label')}
                        value={EventType.CLICKSTREAM}
                    />
                </Flex>
            </Radio.Group> */}
            <Box className="mt-4">
                {type !== EventType.CLICKSTREAM ? (
                    <SingleEventReview />
                ) : (
                    <MultipleEventReview />
                )}
            </Box>
        </Box>
    );
};
export default EventBuilderReview;
