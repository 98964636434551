import PasswordModal from '@components/AccountSettings/ProfilePanel/PasswordModal';
import MantineIcon from '@components/common/MantineIcon';
import SettingsTitle from '@components/common/Settings/SettingsTitle';
import useNotify from '@hooks/toaster/useNotify';
import {
    useEmailStatus,
    useOneTimePassword,
} from '@hooks/useEmailVerification';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import { useUserUpdateMutation } from '@hooks/user/useUser';
import { validateEmail, type UpdateUserArgs } from '@lightdash/common';
import {
    Anchor,
    Box,
    Button,
    Flex,
    Stack,
    Text,
    TextInput,
    Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { VerifyEmailModal } from '@pages/VerifyEmail';
import useApp from '@providers/App/useApp';
import { IconAlertCircle, IconCircleCheck } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { ButtonVariant } from '../../../mantineTheme';
import UnsavedChangesModal from '../../common/modal/UnsavedChangesModal';

type ProfileForm = Pick<UpdateUserArgs, 'firstName' | 'lastName' | 'email'>;

const Profile: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { user, health } = useApp();
    const { showToastError } = useNotify();

    const isEmailServerConfigured = health.data?.hasEmailClient;
    const { data, isLoading: statusLoading } = useEmailStatus(
        !!health.data?.isAuthenticated,
    );
    const {
        mutate: sendVerificationEmail,
        error: sendVerificationEmailError,
        isLoading: emailLoading,
    } = useOneTimePassword();

    const defaultValues: ProfileForm = useMemo(() => {
        return {
            firstName: user.data?.firstName ?? '',
            lastName: user.data?.lastName ?? '',
            email: user.data?.email ?? '',
        };
    }, [user.data?.firstName, user.data?.lastName, user.data?.email]);

    const form = useForm<ProfileForm>({
        defaultValues,
    });

    const { control, handleSubmit, register, reset } = form;
    const watchFields = useWatch<ProfileForm>({ control });
    const hasFormChanged = useIsEqual<ProfileForm>(defaultValues, watchFields);

    const [opened, { toggle, close }] = useDisclosure(false);

    const [showVerifyEmailModal, setShowVerifyEmailModal] =
        useState<boolean>(false);

    const { isLoading: isUpdateUserLoading, mutate: updateUser } =
        useUserUpdateMutation();

    useEffect(() => {
        if (
            sendVerificationEmailError ||
            data?.isVerified ||
            !isEmailServerConfigured
        ) {
            setShowVerifyEmailModal(false);
        }
    }, [data?.isVerified, isEmailServerConfigured, sendVerificationEmailError]);

    const submitForm = (formData: ProfileForm) => {
        const { firstName, lastName, email } = formData;
        if (firstName && lastName && email && validateEmail(email)) {
            updateUser({
                firstName,
                lastName,
                email,
            });
        } else {
            const title =
                email && !validateEmail(email)
                    ? t('common.invalid_email')
                    : t('account_settings.form_validation_error');
            showToastError({
                title,
            });
        }
    };

    const onSubmit = () => {
        void form.handleSubmit(submitForm)();
    };

    return (
        <>
            <Box>
                <Flex className="items-center justify-between w-full">
                    <SettingsTitle
                        title={t('account_settings.profile_title')}
                    />
                </Flex>

                <Box className="max-w-lg my-6">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack mt="md">
                            <TextInput
                                withAsterisk={false}
                                id="first-name-input"
                                placeholder={t(
                                    'account_settings.first_name_placeholder',
                                )}
                                label={t('account_settings.first_name_label')}
                                type="text"
                                required
                                disabled={isUpdateUserLoading}
                                data-cy="first-name-input"
                                {...register('firstName')}
                            />

                            <TextInput
                                withAsterisk={false}
                                id="last-name-input"
                                placeholder={t(
                                    'account_settings.last_name_placeholder',
                                )}
                                label={t('account_settings.last_name_label')}
                                type="text"
                                required
                                disabled={isUpdateUserLoading}
                                data-cy="last-name-input"
                                {...register('lastName')}
                            />

                            <TextInput
                                withAsterisk={false}
                                id="email-input"
                                placeholder={t(
                                    'account_settings.email_placeholder',
                                )}
                                label={t('account_settings.email_label')}
                                type="email"
                                required
                                disabled={true}
                                inputWrapperOrder={[
                                    'label',
                                    'input',
                                    'error',
                                    'description',
                                ]}
                                {...register('email')}
                                data-cy="email-input"
                                rightSection={
                                    isEmailServerConfigured &&
                                    data?.isVerified ? (
                                        <Tooltip
                                            label={t(
                                                'account_settings.verified_email',
                                            )}
                                        >
                                            <MantineIcon
                                                size="lg"
                                                icon={IconCircleCheck}
                                                color="green.6"
                                            />
                                        </Tooltip>
                                    ) : (
                                        <MantineIcon
                                            size="lg"
                                            icon={IconAlertCircle}
                                            color="gray.6"
                                        />
                                    )
                                }
                                descriptionProps={{ mt: 'xs' }}
                                description={
                                    isEmailServerConfigured &&
                                    !data?.isVerified ? (
                                        <Text color="dimmed">
                                            {t(
                                                'account_settings.unverified_email',
                                            )}{' '}
                                            <Anchor
                                                component="span"
                                                onClick={() => {
                                                    if (!data?.otp) {
                                                        sendVerificationEmail();
                                                    }
                                                    setShowVerifyEmailModal(
                                                        true,
                                                    );
                                                }}
                                            >
                                                {t(
                                                    'account_settings.verify_anchor',
                                                )}
                                            </Anchor>
                                            .
                                        </Text>
                                    ) : null
                                }
                            />

                            <Stack className="gap-2">
                                <Text className="text-sm font-medium text-gray-800">
                                    {t('account_settings.password_label')}
                                </Text>
                                <Button
                                    variant={ButtonVariant.OUTLINED}
                                    className="w-fit"
                                    onClick={toggle}
                                >
                                    {t(
                                        'account_settings.change_password_button',
                                    )}
                                </Button>
                            </Stack>

                            <VerifyEmailModal
                                opened={showVerifyEmailModal}
                                onClose={() => {
                                    setShowVerifyEmailModal(false);
                                }}
                                isLoading={statusLoading || emailLoading}
                            />
                        </Stack>
                    </form>
                </Box>
            </Box>
            <PasswordModal opened={opened} onClose={close} />
            <UnsavedChangesModal
                opened={hasFormChanged}
                primaryActionButtonClick={() => onSubmit()}
                secondaryActionButtonClick={() => reset()}
                disableButtons={isUpdateUserLoading}
            />
        </>
    );
};

export default React.memo(Profile);
