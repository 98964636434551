import TextArea from '@components/common/Inputs/TextArea';
import TextInput from '@components/common/Inputs/TextInput';
import TagsMultiSelect from '@components/common/Tags/TagsMultiSelect';
import { useGenerateCampaignNameDescription } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    CommunicationChannel,
    type CampaignCreateRequest,
} from '@lightdash/common';
import { Stack, Text } from '@mantine/core';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
type CampaignDetailsPayloadType = Pick<
    CampaignCreateRequest,
    'name' | 'description' | 'tags'
>;

interface CampaignDetailsProps {
    validateFormRef: React.MutableRefObject<() => Promise<boolean>>;
}

const CampaignDetails: React.FC<CampaignDetailsProps> = ({
    validateFormRef,
}) => {
    const { t } = useLocale();
    const { campaignPayload, isEditMode } = useCampaignContext(
        (context) => context.state,
    );
    const {
        data: nameDescription,
        mutateAsync: mutateAsyncNameDescription,
        isLoading: isLoadingNameDescription,
    } = useGenerateCampaignNameDescription();
    const { setCampaignName, setCampaignDescription, setCampaignTags } =
        useCampaignContext((context) => context.actions);
    const defaultValues: CampaignDetailsPayloadType = useMemo(() => {
        return {
            name: campaignPayload?.name || '',
            description: campaignPayload?.description || '',
            tags: campaignPayload?.tags || [],
        };
    }, [campaignPayload]);

    const form = useForm<CampaignDetailsPayloadType>({
        defaultValues,
    });

    const {
        trigger,
        register,
        formState: { errors },
        clearErrors,
    } = form;

    useEffect(() => {
        if (
            (!campaignPayload.name || !campaignPayload.description) &&
            !nameDescription
        ) {
            void mutateAsyncNameDescription({
                audienceId: campaignPayload?.audienceId || '',
                templateId: campaignPayload?.templateDetails?.id || '',
                channel: campaignPayload.channel ?? CommunicationChannel.EMAIL,
                schedule: campaignPayload.schedule ?? {},
            }).then((res) => {
                if (!campaignPayload.name) {
                    setCampaignName(res.name);
                    form.setValue('name', res.name);
                }
                if (!campaignPayload.description) {
                    setCampaignDescription(res.description);
                    form.setValue('description', res.description);
                }
            });
        }
    }, [
        campaignPayload,
        form,
        mutateAsyncNameDescription,
        nameDescription,
        setCampaignDescription,
        setCampaignName,
    ]);

    useEffect(() => {
        validateFormRef.current = async () => {
            const result = await trigger();
            return result && Object.keys(errors).length === 0;
        };
    }, [trigger, errors, validateFormRef]);

    return (
        <form name="campaign_details_form" id="campaign_details_form">
            <Stack>
                <TextInput
                    defaultValue={form.watch('name')}
                    {...register('name', { required: 'Name is required' })}
                    label={t('campaigns_builder_details.campaign_name_label')}
                    required
                    disabled={!isEditMode}
                    labelProps={{ style: { marginTop: '8px' } }}
                    placeholder={t(
                        'campaigns_builder_details.campaign_name_placeholder',
                    )}
                    onChange={(event) => {
                        const name = event.currentTarget.value;
                        setCampaignName(name);
                        if (name.length > 0) {
                            clearErrors('name');
                            return;
                        }
                    }}
                    withAsterisk={false}
                    error={errors.name?.message}
                    data-gramm="false" //To disable grammarly
                    data-gramm_editor="false" //To disable grammarly
                    data-enable-grammarly="false" //To disable grammarly
                    isAiGeneratingData={isLoadingNameDescription}
                    aiGeneratedData={nameDescription?.name}
                    aiGenerationCustomClass="mt-2"
                />
                <TextArea
                    label={
                        <Text>
                            {t(
                                'campaigns_builder_details.campaign_description_label',
                            )}{' '}
                            <span className="text-gray-600">(optional)</span>
                        </Text>
                    }
                    disabled={!isEditMode}
                    labelProps={{ style: { marginTop: '8px' } }}
                    placeholder={t(
                        'campaigns_builder_details.campaign_description_placeholder',
                    )}
                    {...register('description')}
                    onChange={(event) =>
                        setCampaignDescription(event.currentTarget.value)
                    }
                    data-gramm="false" //To disable grammarly
                    data-gramm_editor="false" //To disable grammarly
                    data-enable-grammarly="false" //To disable grammarly
                    isAiGeneratingData={isLoadingNameDescription}
                    aiGeneratedData={nameDescription?.description}
                    aiGenerationCustomClass="mt-2"
                />
                <TagsMultiSelect
                    tags={campaignPayload?.tags || []}
                    onChange={setCampaignTags}
                    isDisabled={!isEditMode}
                />
            </Stack>
        </form>
    );
};

export default React.memo(CampaignDetails);
