import HiddenButton, { type HideProps } from '@components/Hidden/HiddenButton';
import DataTable from '@components/Table';
import { type TabsProps } from '@components/Table/type';
import { useLocale } from '@hooks/useLocale';
import {
    CampaignStatus,
    ShowDataType,
    type CampaignList,
    type ReducedCampaign,
} from '@lightdash/common';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { type CampaignTab } from './types';
import { useCampaignColumns } from './useCampaignTableUtils';

type CampaignsTableProps = HideProps & {
    campaigns: CampaignList | undefined;
    onPageChange: (page: number) => void;
    onSearchChange: (newSearchString: string) => void;
    searchValue: string;
    setSelectedTab: (tab: CampaignTab) => void;
    selectedTab: CampaignTab;
    isDataFetching: boolean;
    tabs: TabsProps<CampaignTab>[];
};

const CampaignsTable: React.FC<CampaignsTableProps> = ({
    campaigns,
    onPageChange,
    onSearchChange,
    searchValue,
    setSelectedTab,
    selectedTab,
    isDataFetching,
    isHiddenActive,
    onHiddenChange,
    hideHiddenButton,
    tabs,
}) => {
    const campaignsColumns = useCampaignColumns();
    const { t } = useLocale();
    const navigate = useNavigate();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    return (
        <div>
            {campaigns?.data && campaignsColumns && (
                <DataTable<ReducedCampaign, CampaignTab>
                    name={t('campaign_manager.th_campaign_name')}
                    tableData={campaigns.data}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: campaignsColumns,
                        },
                    ]}
                    tabs={tabs}
                    onTabChange={(tab) => {
                        setSelectedTab(tab);
                        onPageChange(1);
                    }}
                    selectedTab={selectedTab}
                    searchPlaceholder={t(
                        'campaign_manager.table_search_placeholder',
                    )}
                    paginationText={t('campaign_manager.pagination_text')}
                    isManualPagination={true}
                    isDataFetching={isDataFetching}
                    onPageChange={onPageChange}
                    pagination={campaigns.paginate!}
                    onSearchChange={onSearchChange}
                    isEditable={false}
                    handleRowClick={(row) => {
                        if (row.original.status === CampaignStatus.DRAFT) {
                            void navigate(
                                `/projects/${projectUuid}/campaigns/${row.original?.id}/edit?channel=${row.original.channel}`,
                            );
                        } else {
                            void navigate(
                                `/projects/${projectUuid}/campaigns/${row.original?.id}/preview`,
                            );
                        }
                    }}
                    searchValue={searchValue}
                    leftSection={
                        !hideHiddenButton && (
                            <HiddenButton
                                isHiddenActive={isHiddenActive}
                                onHiddenChange={onHiddenChange}
                                activeHideText={t(
                                    'campaign_manager.active_hide_button_text',
                                )}
                            />
                        )
                    }
                />
            )}
        </div>
    );
};

export default React.memo(CampaignsTable);
