import {
    TimeConfigType,
    type DifferenceOperator,
    type PeriodType,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import React, { useMemo } from 'react';
import DateTimeDelay from './DateTimeDelay';
import DurationDelay from './DurationDelay';

export interface DelayActionPropsBase {
    actionTitle: React.ReactNode;
    withLeftPadding?: boolean;
    granularity: PeriodType;
    durationOptions: PeriodType[];
    nodeId: string;
}

export interface DelayActionPropsDuration extends DelayActionPropsBase {
    selectedDuration: number;
    onDurationChange: (duration: number, granularity: PeriodType) => void;
}

export interface DelayActionPropsDateTime extends DelayActionPropsBase {
    selectedValue: string | number;
    time: string | undefined;
    timeDiffOperator: DifferenceOperator;
    timeDiffValue: number;
    onDataChange: (
        value: string | number,
        timeDiffValue: number,
        timeDiffOperator: DifferenceOperator,
        time: string,
        granularity: PeriodType,
    ) => void;
}

export type DelayActionProps = DelayActionPropsBase &
    (
        | ({ type: TimeConfigType.DURATION } & DelayActionPropsDuration)
        | ({ type: TimeConfigType.DATE_TIME } & DelayActionPropsDateTime)
    );

const DelayAction: React.FC<DelayActionProps> = ({
    granularity,
    durationOptions,
    actionTitle,
    withLeftPadding = true,
    type,
    nodeId,
    ...rest
}) => {
    const renderComponent = useMemo(() => {
        if (type === TimeConfigType.DURATION) {
            const { selectedDuration, onDurationChange } =
                rest as DelayActionPropsDuration;
            return (
                <DurationDelay
                    selectedDuration={selectedDuration}
                    onDurationChange={onDurationChange}
                    granularity={granularity}
                    durationOptions={durationOptions}
                    actionTitle={actionTitle}
                    withLeftPadding={withLeftPadding}
                />
            );
        }
        if (type === TimeConfigType.DATE_TIME) {
            const {
                selectedValue,
                time,
                timeDiffOperator,
                timeDiffValue,
                onDataChange,
            } = rest as DelayActionPropsDateTime;
            return (
                <DateTimeDelay
                    selectedValue={selectedValue}
                    time={time}
                    timeDiffOperator={timeDiffOperator}
                    timeDiffValue={timeDiffValue}
                    actionTitle={actionTitle}
                    onDataChange={onDataChange}
                    withLeftPadding={withLeftPadding}
                    granularity={granularity}
                    durationOptions={durationOptions}
                    nodeId={nodeId}
                />
            );
        }
    }, [
        actionTitle,
        rest,
        type,
        withLeftPadding,
        granularity,
        durationOptions,
        nodeId,
    ]);

    return <Box>{renderComponent}</Box>;
};

export default React.memo(DelayAction);
