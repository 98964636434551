import FieldIcon from '@components/common/IconPack/FieldIcon';
import { TextWithTooltip } from '@components/common/TextWithTooltip';
import { type UserTraitFieldConfig } from '@lightdash/common';
import { Box, Flex } from '@mantine/core';
import { AsteriskSimple, Textbox, UserCircle } from '@phosphor-icons/react';

interface UpdateTraitListItemProps {
    trait: UserTraitFieldConfig;
    onSelectTrait: ((trait: UserTraitFieldConfig) => void) | undefined;
}

const UpdateTraitListItem = ({
    trait,
    onSelectTrait,
}: UpdateTraitListItemProps) => {
    return (
        <Flex
            key={trait.label}
            align="center"
            gap={4}
            onClick={() => onSelectTrait && onSelectTrait(trait)}
            className={
                'cursor-pointer  rounded-md p-2 ' +
                (onSelectTrait
                    ? 'cursor-pointer hover:bg-gray-100'
                    : 'cursor-default')
            }
        >
            <>
                <UserCircle size={14} color="rgb(var(--color-blu-800))" />
                <Textbox size={14} color="rgb(var(--color-halt-800))" />
                <AsteriskSimple size={14} color="rgb(var(--color-purple))" />
                <FieldIcon type={trait.type} size={14} />
                <Box
                    className={`font-medium max-w-[120px] truncate text-gray-800`}
                >
                    {trait.label && <TextWithTooltip text={trait.label} />}
                </Box>
            </>
        </Flex>
    );
};

export default UpdateTraitListItem;
