import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import SettingsTitle from '@components/common/Settings/SettingsTitle';
import { UpdateProjectConnection } from '@components/ProjectConnection';
import SetupPage from '@components/SetupPage';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import { useProject } from '@hooks/useProject';
import { Box, Flex } from '@mantine/core';
import useApp from '@providers/App/useApp';
import React from 'react';
import { Navigate, useParams } from 'react-router';

const DataConnection: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const ability = useAbilityContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { data: activeProject } = useProject(projectUuid);
    const { showToastError } = useNotify();
    const { user } = useApp();
    const canViewWarehouse = ability.can(
        'view',
        subject('ProjectSettings', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    if (!canViewWarehouse) {
        showToastError({ title: t('authorization_access_error.message') });
        return <Navigate to="/no-access" />;
    }
    if (activeProject && !Boolean(activeProject.warehouseConnection)) {
        return (
            <SetupPage
                description={t('set_up_page.description', {
                    type_description: t(
                        'set_up_page.type_description.warehouse',
                    ),
                })}
            />
        );
    }

    if (!projectUuid) return null;

    return (
        <>
            <Box>
                <Flex className="items-center justify-between w-full">
                    <SettingsTitle
                        title={t('workspace_settings.data_connection_title')}
                    />
                </Flex>

                <Box className="my-6">
                    <UpdateProjectConnection projectUuid={projectUuid} />
                </Box>
            </Box>
        </>
    );
};

export default React.memo(DataConnection);
