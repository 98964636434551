import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type OnboardingStatus,
    type ProjectSavedChartStatus,
} from '@lightdash/common';
import { useQuery } from '@tanstack/react-query';

const getOnboardingStatus = async () =>
    sortmentApi<OnboardingStatus>({
        url: `/org/onboardingStatus`,
        method: 'GET',
        body: undefined,
    });

export const useOnboardingStatus = () =>
    useQuery<OnboardingStatus, ApiError>({
        queryKey: ['onboarding-status'],
        queryFn: getOnboardingStatus,
        retry: false,
        refetchOnMount: true,
    });

const getProjectSavedChartStatus = async (projectUuid: string) =>
    sortmentApi<ProjectSavedChartStatus>({
        url: `/projects/${projectUuid}/hasSavedCharts`,
        method: 'GET',
        body: undefined,
    });

export const useProjectSavedChartStatus = (projectUuid: string | undefined) =>
    useQuery<ProjectSavedChartStatus, ApiError>({
        queryKey: [projectUuid, 'project-saved-chart-status'],
        queryFn: () => getProjectSavedChartStatus(projectUuid!),
        retry: false,
        refetchOnMount: true,
        enabled: !!projectUuid,
    });
