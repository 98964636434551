import { SortmentLogo } from '@assets/icons/Core';
import { subject } from '@casl/ability';
import AuthorizationError from '@components/AuthorizationError';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import TimezoneSelect from '@components/common/Select/TimezoneSelect';
import OnBoardingFooter from '@components/Onboarding/OnboardingFooter';
import Page from '@components/Page/Page';
import PageSpinner from '@components/PageSpinner';
import useNotify from '@hooks/toaster/useNotify';
import { useUpdateActiveProjectMutation } from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import { useProjectCreationMutation } from '@hooks/useProjectCreationMutation';
import { useProjects } from '@hooks/useProjects';
import { Timezones, type CreateProjectRequest } from '@lightdash/common';
import { Box, Button, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import useApp from '@providers/App/useApp';
import React, { useCallback } from 'react';
import { ChevronLeft } from 'react-feather';
import { useNavigate } from 'react-router';
import { ButtonVariant } from '../mantineTheme';

const CreateWorkspace: React.FC<{}> = ({}) => {
    const { health } = useApp();
    const { t } = useLocale();
    const navigate = useNavigate();
    const { showToastError } = useNotify();
    const { user } = useApp();
    const {
        isLoading,
        mutateAsync: createProject,
        isSuccess,
    } = useProjectCreationMutation();
    const { mutate: setLastProjectMutation } = useUpdateActiveProjectMutation();
    const form = useForm<CreateProjectRequest>({
        initialValues: {
            name: '',
            timezone: Timezones.UTC,
        },
    });
    const ability = useAbilityContext();
    const canCreateWorkspace = ability.can(
        'create',
        subject('Project', { organizationUuid: user.data?.organizationUuid }),
    );
    const onBackButtonClick = useCallback(() => {
        void navigate(-1);
    }, [navigate]);
    const { data: projectsData } = useProjects();

    const handleSubmit = useCallback(
        async (values: CreateProjectRequest) => {
            const data = await createProject(values);
            if (data?.projectUuid) {
                setLastProjectMutation(data?.projectUuid);
                void navigate(`/projects/${data?.projectUuid}/warehouse`);
            }
        },
        [createProject, setLastProjectMutation, navigate],
    );

    if (health.isInitialLoading) {
        return <PageSpinner />;
    }
    if (!canCreateWorkspace) {
        showToastError({ title: t('authorization_access_error.message') });
        return (
            <AuthorizationError
                message={t('organization_creation.member_limbo_description')}
            />
        );
    }
    return (
        <>
            <Page
                title="create workspace"
                withCenteredContent
                withFullHeight
                backgroundColor="gray"
            >
                {' '}
                <SortmentLogo />
                <section className="w-[300px] px-3 pt-6 pb-3 bg-white rounded-3xl flex flex-col items-center border-base gap-3 shadow-card mt-7">
                    <div className="flex items-center w-full gap-7">
                        <Button
                            variant={ButtonVariant.UNSTYLED}
                            className="w-5 h-5 p-1 hover:bg-shade-2 hover:rounded"
                            onClick={onBackButtonClick}
                        >
                            <ChevronLeft
                                size={13}
                                strokeWidth={2.5}
                                color={'rgb(var(--color-gray-600))'}
                            />
                        </Button>
                        <p className="text-sm font-medium text-gray-800">
                            {projectsData?.length === 0
                                ? t(
                                      'workspace_creation.create_workspace_header',
                                  )
                                : t(
                                      'workspace_creation.create_workspace_header_alter',
                                  )}
                        </p>
                    </div>
                    <form
                        name="create_workspace"
                        onSubmit={form.onSubmit(handleSubmit)}
                        className="w-full"
                    >
                        <Stack className="w-full gap-6">
                            <Stack className="w-full gap-3.5">
                                <TextInput
                                    label={t(
                                        'workspace_creation.workspace_name_label',
                                    )}
                                    name="workspace name"
                                    disabled={isLoading || isSuccess}
                                    placeholder={t(
                                        'workspace_creation.workspace_name_placeholder',
                                    )}
                                    required
                                    {...form.getInputProps('name')}
                                    withAsterisk={false}
                                />
                            </Stack>

                            <Stack className="w-full gap-1">
                                <TimezoneSelect
                                    data={[]}
                                    disabled={isLoading}
                                    required
                                    maxDropdownHeight={400}
                                    withinPortal
                                    className="!w-[274px]"
                                    searchable
                                    {...form.getInputProps('timezone')}
                                />
                            </Stack>
                            <Button
                                type="submit"
                                loading={isLoading}
                                disabled={isLoading || isSuccess}
                                data-cy="signin-button"
                                className="py-3 rounded-lg"
                            >
                                {t('common.continue_button')}
                            </Button>
                            <Box className="flex justify-center">
                                <Text className="text-sm font-medium text-gray-500">
                                    {t('organization_creation.footnote')}
                                </Text>
                            </Box>
                        </Stack>
                    </form>
                </section>
            </Page>
            <Box className="flex justify-center">
                <OnBoardingFooter />
            </Box>
        </>
    );
};
export default CreateWorkspace;
