import DataTable from '@components/Table';
import { useIsTruncated } from '@hooks/useIsTruncated';
import { useLocale } from '@hooks/useLocale';
import { useRefreshDimensions } from '@hooks/useRelation';
import { useUpdateTableDetail } from '@hooks/useSchemaBuilder';
import {
    ShowDataType,
    Timezones,
    type RelationSchemaColumn,
} from '@lightdash/common';
import { Box, Flex, Switch, Text, TextInput, Tooltip } from '@mantine/core';
import { ArrowsCounterClockwise } from '@phosphor-icons/react';
import useProjectContext from '@providers/Project/useProjectContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import useSchemaContext from '@providers/Schema/useSchemaContext';
import { useQueryClient } from '@tanstack/react-query';
import Fuse from 'fuse.js';
import { t as translate } from 'i18next';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';

const InputBox = ({
    defaultValue,
    onChange,
    width = 250,
    placeholder = translate('common.click_to_add', { value: 'description' }),
    ...rest
}: {
    defaultValue: string;
    onChange: (value: string) => void;
    width?: number;
    placeholder?: string;
}) => {
    const { ref, isTruncated } = useIsTruncated();
    const [value, setValue] = useState<string>(defaultValue);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    if (!isFocused) {
        return (
            <Tooltip label={value} disabled={!isTruncated}>
                <Box onClick={() => setIsFocused(true)}>
                    <Text
                        ref={ref}
                        className={
                            value
                                ? `max-w-[${width}px] truncate`
                                : 'text-gray-500'
                        }
                    >
                        {value || placeholder}
                    </Text>
                </Box>
            </Tooltip>
        );
    }

    return (
        <TextInput
            onBlur={() => setIsFocused(false)}
            autoFocus
            defaultValue={value}
            value={value}
            onChange={(e) => {
                setValue(e.currentTarget.value);
                onChange(e.currentTarget.value);
            }}
            placeholder={placeholder}
            sx={{
                input: {
                    border: 0,
                    boxShadow: 'none',
                    padding: 0,

                    '&:focus-within': {
                        border: 0,
                        boxShadow: 'none',
                    },
                    maxWidth: width,
                },
            }}
            {...rest}
        />
    );
};

const TableProperties: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { schemaPayload } = useSchemaContext((context) => context.state);
    const { activeRelationUuid } = useRelationContext();
    const { mutateAsync: updateTableDetail } =
        useUpdateTableDetail(activeRelationUuid);
    const queryClient = useQueryClient();
    const { mutate } = useRefreshDimensions();
    const { projectData } = useProjectContext();

    const [searchDebounce, setSearchDebounce] = useState<string>('');

    const lastSyncedAt = useMemo(() => {
        const col = schemaPayload?.columns?.find(
            (column: { lastSyncedAt: Date }) => column.lastSyncedAt,
        );
        if (col) {
            return moment(col.lastSyncedAt)
                .tz(projectData?.timezone ?? Timezones.UTC)
                .format('HH:MM a');
        }
        return '';
    }, [schemaPayload, projectData]);

    const handleUpdate = useCallback(
        async ({ name, ...rest }: { name: string; [key: string]: any }) => {
            await updateTableDetail({
                tableName: schemaPayload.name,
                payload: {
                    type: schemaPayload.type,
                    dimensions: {
                        [name]: { ...rest },
                    },
                },
            });

            await queryClient.invalidateQueries([
                QueryKeys.RELATION_SCHEMA,
                projectUuid,
                activeRelationUuid,
            ]);
        },
        [
            activeRelationUuid,
            projectUuid,
            queryClient,
            schemaPayload,
            updateTableDetail,
        ],
    );

    const onSearchChange = (newSearchString: string) => {
        setSearchDebounce(newSearchString);
    };

    const debouncedHandleUpdate = debounce(async (row, key, value) => {
        await handleUpdate({ name: row.name, [key]: value });
    }, 1000);

    const handleChange = useCallback(debouncedHandleUpdate, [
        debouncedHandleUpdate,
    ]);

    const onToggle = async (row: any, field: string) => {
        await handleUpdate({ name: row.name, [field]: row[field] });
        if (field === 'masked' && row?.cached === true) {
            await handleUpdate({ name: row.name, ['cached']: false });
        } else if (field === 'cached' && row?.masked === true) {
            await handleUpdate({ name: row.name, ['masked']: false });
        }
    };

    const filterItems = useMemo(() => {
        let fieldsData = schemaPayload.columns;

        if (searchDebounce !== '' && fieldsData) {
            const fuse = new Fuse(fieldsData, {
                keys: ['name'],
                threshold: 0.3,
            });

            fieldsData = fuse.search(searchDebounce).map((res) => res.item);
        }

        return fieldsData;
    }, [schemaPayload.columns, searchDebounce]);

    return (
        <DataTable<RelationSchemaColumn>
            searchPlaceholder="Search properties"
            tableBackgroundColor=""
            customClass="!h-[28rem] !max-w-[53rem] overflow-scroll "
            cellHeight="h-[60px]"
            leftSection={
                <Flex
                    className="ml-[22rem] w-[15rem]"
                    justify={'flex-end'}
                    align={'center'}
                    gap={'sm'}
                >
                    {lastSyncedAt && (
                        <Text className="text-xs text-gray-600">
                            last synced at {lastSyncedAt} (GMT{' '}
                            {moment()
                                .tz(projectData?.timezone ?? Timezones.UTC)
                                .format('Z')}
                            )
                        </Text>
                    )}
                    <ArrowsCounterClockwise
                        size={14}
                        weight="regular"
                        className="hover:cursor-pointer"
                        onClick={() =>
                            mutate({
                                tableName: schemaPayload?.name,
                                relationId: activeRelationUuid,
                            })
                        }
                    />
                </Flex>
            }
            tableData={filterItems || []}
            onSearchChange={onSearchChange}
            options={[
                {
                    format: ShowDataType.LIST,
                    formatData: [
                        {
                            accessorKey: '',
                            header: '-',
                            cell: ({ row }) => (
                                <Box className="">
                                    <Switch
                                        size="xs"
                                        defaultChecked={!row?.original?.hidden}
                                        onChange={async (event) => {
                                            row.original.hidden =
                                                !event.currentTarget.checked;
                                            await onToggle(
                                                {
                                                    ...row.original,
                                                    hidden: !event.currentTarget
                                                        .checked,
                                                },
                                                'hidden',
                                            );
                                        }}
                                        styles={(_params) => ({
                                            track: {
                                                width: '1.5rem !important',
                                            },
                                        })}
                                    />
                                </Box>
                            ),
                        },
                        {
                            accessorKey: 'name',
                            header: 'COLUMNS',
                            cell: ({ row }) => (
                                <Box className="">{row?.original?.name}</Box>
                            ),
                        },
                        {
                            accessorKey: 'label',
                            header: 'LABEL',
                            cell: ({ row }) => (
                                <Box className="" key={row.id}>
                                    <InputBox
                                        defaultValue={
                                            row?.original?.label || ''
                                        }
                                        onChange={(value: string) => {
                                            void handleChange(
                                                row.original,
                                                'label',
                                                value,
                                            );

                                            row.original.label = value;
                                        }}
                                        width={100}
                                        placeholder={t('common.click_to_add', {
                                            value: 'label',
                                        })}
                                    />
                                </Box>
                            ),
                        },
                        {
                            accessorKey: '',
                            header: 'DESCRIPTION',
                            cell: ({ row }) => (
                                <Box className="" key={row.id}>
                                    <InputBox
                                        defaultValue={
                                            row?.original?.description || ''
                                        }
                                        onChange={(value: string) => {
                                            void handleChange(
                                                row.original,
                                                'description',
                                                value,
                                            );

                                            row.original.description = value;
                                        }}
                                        placeholder={t('common.click_to_add', {
                                            value: 'description',
                                        })}
                                    />
                                </Box>
                            ),
                        },
                        {
                            accessorKey: '',
                            header: 'MASKED',
                            cell: ({ row }) => (
                                <Box className="">
                                    <Switch
                                        size="xs"
                                        defaultChecked={row?.original?.masked}
                                        disabled={
                                            row?.original?.hidden ||
                                            row?.original?.cached
                                        }
                                        onChange={async (event) => {
                                            row.original.masked =
                                                event.currentTarget.checked;
                                            await onToggle(
                                                {
                                                    ...row.original,
                                                    masked: event.currentTarget
                                                        .checked,
                                                },
                                                'masked',
                                            );
                                        }}
                                        styles={(_params) => ({
                                            track: {
                                                width: '1.5rem !important',
                                            },
                                        })}
                                    />{' '}
                                </Box>
                            ),
                        },
                        {
                            accessorKey: '',
                            header: 'CACHED',
                            cell: ({ row }) => (
                                <Box className="">
                                    <Switch
                                        size="xs"
                                        defaultChecked={row?.original?.cached}
                                        disabled={
                                            row?.original?.hidden ||
                                            row?.original?.masked
                                        }
                                        onChange={async (event) => {
                                            row.original.cached =
                                                event.currentTarget.checked;
                                            await onToggle(
                                                {
                                                    ...row.original,
                                                    cached: event.currentTarget
                                                        .checked,
                                                },
                                                'cached',
                                            );
                                        }}
                                        styles={(_params) => ({
                                            track: {
                                                width: '1.5rem !important',
                                            },
                                        })}
                                    />{' '}
                                </Box>
                            ),
                        },
                    ],
                },
            ]}
            isEditable={false}
            disablePagination
        />
    );
};

export default TableProperties;
