import { Box, Flex, Text } from '@mantine/core';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { type ContentStepComponentProps } from '@components/Campaigns/Builder/types';
import {
    getNextContentStep,
    getPreviousContentStep,
} from '@components/Campaigns/Builder/utils';
import { useGetUtmKeys, useUpdateCampaign } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { type CampaignUpdateRequest } from '@lightdash/common';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { isEqual } from 'lodash';
import { useParams } from 'react-router';
import UtmTracking, { type UtmTableData } from './UtmTracking';

const resolveVariable = (variable: string, values: CampaignUpdateRequest) => {
    switch (variable) {
        case '{{channel_name}}':
            return values.channel;
        case '{{campaign_name}}':
            return variable;
        default:
            return values.name;
    }
};

const Tracking: React.FC<ContentStepComponentProps> = ({
    setActiveContentStep,
    activeContentStep,
    templateMetadata,
}) => {
    const initialData = useRef<any>(null);
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const initialRender = useRef(false);
    const [utmTrackingState, setUtmTrackingState] = useState<UtmTableData[]>(
        [],
    );

    const { t } = useLocale();
    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload } = state;
    const { campaignTrackingParameters } = campaignPayload;
    const { setCurrentStepCallback, setPreviousStepCallback, setUtmTracking } =
        actions;

    const { data: utmKeys } = useGetUtmKeys();
    const { mutate: updateCampaign } = useUpdateCampaign(campaignUuid);

    const internalKeys = useMemo(() => {
        if (utmKeys) {
            return utmKeys
                ?.filter((utmKey) => utmKey.type === 'internal')
                .map((utmKey) => utmKey.key);
        }
        return [];
    }, [utmKeys]);

    useEffect(() => {
        if (initialData.current === null) {
            initialData.current = campaignPayload;
        }
    }, [campaignPayload]);

    useEffect(() => {
        if (!initialRender.current) {
            const data: UtmTableData[] = [];
            if (
                campaignTrackingParameters &&
                Object.keys(campaignTrackingParameters).length > 0
            ) {
                Object.entries(campaignTrackingParameters).forEach(
                    ([key, value]) => {
                        const isInternalKey = internalKeys.includes(key);
                        data.push({
                            key: key,
                            value: value,
                            type: isInternalKey ? 'internal' : 'external',
                            required: isInternalKey,
                            error: '',
                        });
                    },
                );
                data.sort((a) => (a.type === 'external' ? 1 : -1));
                setUtmTrackingState([...data]);
                initialRender.current = true;
                return;
            }
            if (utmKeys) {
                utmKeys.forEach((utmKey) => {
                    if (utmKey.type === 'internal') {
                        data.push({
                            key: utmKey.key,
                            value: (utmKey.value.type === 'static'
                                ? utmKey.value.value
                                : resolveVariable(
                                      utmKey.value.value,
                                      campaignPayload,
                                  )) as string,
                            type: utmKey.type,
                            required: utmKey.required,
                            error: '',
                        });
                    }
                });
                setUtmTrackingState([...data]);
                initialRender.current = true;
            }
        }
    }, [campaignPayload, campaignTrackingParameters, internalKeys, utmKeys]);

    const nextStep = useMemo(
        () =>
            getNextContentStep(
                activeContentStep,
                campaignPayload.channel,
                templateMetadata,
            ),
        [activeContentStep, campaignPayload.channel, templateMetadata],
    );

    const prevStep = useMemo(
        () =>
            getPreviousContentStep(
                activeContentStep,
                campaignPayload.channel,
                templateMetadata,
            ),
        [activeContentStep, campaignPayload.channel, templateMetadata],
    );

    const nextStepCallback = useCallback(() => {
        if (nextStep) {
            if (utmTrackingState.some((utm) => !utm.value || !utm.key)) {
                const newStateWithErrors: UtmTableData[] = [];
                utmTrackingState.forEach((utm) => {
                    newStateWithErrors.push({
                        ...utm,
                        error:
                            utm.value && utm.key
                                ? ''
                                : t('common.error.empty_text_input'),
                    });
                });
                setUtmTrackingState([...newStateWithErrors]);
                return;
            }
            setActiveContentStep(nextStep);
            const utmTrackingPayload = utmTrackingState.reduce(
                (accum, curr) => {
                    if (accum) {
                        return { ...accum, [curr.key]: curr.value };
                    }
                    return { [curr.key]: curr.value };
                },
                {},
            );
            if (
                campaignUuid &&
                !isEqual(
                    utmTrackingPayload,
                    initialData.current.campaignTrackingParameters,
                )
            ) {
                updateCampaign({
                    campaignTrackingParameters: utmTrackingPayload,
                });
            }
            setUtmTracking(utmTrackingPayload);
        }
    }, [
        campaignUuid,
        nextStep,
        setActiveContentStep,
        setUtmTracking,
        t,
        updateCampaign,
        utmTrackingState,
    ]);

    const prevStepCallback = useCallback(() => {
        if (prevStep) {
            setActiveContentStep(prevStep);
        }
    }, [prevStep, setActiveContentStep]);

    useEffect(() => {
        setCurrentStepCallback({
            callback: nextStepCallback,
            skipExecutionAfterCallback: Boolean(nextStep),
        });
    }, [nextStepCallback, nextStep, setCurrentStepCallback]);

    useEffect(() => {
        setPreviousStepCallback({
            callback: prevStepCallback,
            skipExecutionAfterCallback: Boolean(prevStep),
        });
    }, [prevStepCallback, prevStep, setPreviousStepCallback]);

    return (
        <Box className="h-full overflow-scroll">
            <Flex justify={'space-between'} align={'center'} className="mb-3">
                <Text className="font-medium text-gray-500">
                    {t('campaigns_builder.tracking.utm_tracking')}
                </Text>
            </Flex>
            <UtmTracking
                data={utmTrackingState}
                setData={setUtmTrackingState}
            />
        </Box>
    );
};

export default Tracking;
