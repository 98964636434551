import NoResultsFound from '@components/Channels/NoResultsFound';
import GenericOption from '@components/common/Card/GenericOption';
import SkeletonLoader from '@components/common/SkeletonLoader';
import SearchInput from '@components/SearchInput';
import { useGetChannelIntegrations, useGetChannels } from '@hooks/useChannels';
import { useLocale } from '@hooks/useLocale';
import { useImportTemplateFromProvider } from '@hooks/useTemplate';
import { Box, Button, Grid, Image, Loader as LoaderIcon } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import useTemplateBuilderContext from '@providers/TemplateBuilder/useTemplateBuilderContext';
import React, { useEffect, useMemo, useState } from 'react';
import { createIntegratedProviderObject } from '../Campaigns/utils';

const ImportFromProvider = () => {
    const { state, actions } = useTemplateBuilderContext((context) => context);
    const { channel, selectedIntegration } = state;
    const { setSelectedIntegration, setSelectedTemplate } = actions;

    const [searchText, setSearchText] = useState('');
    const { t } = useLocale();

    const { data: integratedProviders, isLoading: integratedProvidersLoading } =
        useGetChannelIntegrations(channel);
    const { data: allChannels } = useGetChannels();

    const {
        mutate: mutateImportTemplateFromProvider,
        data: searchedTemplateData,
        isLoading: searchingTemplate,
        isSuccess: searchTemplateSuccess,
    } = useImportTemplateFromProvider(
        selectedIntegration?.providerId,
        selectedIntegration?.integrationId,
    );

    useEffect(() => {
        if (searchedTemplateData?.templates?.length) {
            setSelectedTemplate(searchedTemplateData?.templates[0]);
        }
    }, [searchedTemplateData, setSelectedTemplate]);

    const integratedProvidersWithIcons = useMemo(() => {
        if (channel && allChannels?.[channel] && integratedProviders) {
            const obj = createIntegratedProviderObject(
                allChannels?.[channel],
                integratedProviders,
                false,
            );
            return obj;
        }
        return [];
    }, [allChannels, integratedProviders, channel]);

    const filteredIntegrations = useMemo(() => {
        if (searchText) {
            const formattedSearchText = searchText?.toLowerCase();
            return integratedProvidersWithIcons?.filter(
                (item) =>
                    item?.customName
                        ?.toLowerCase()
                        ?.includes(formattedSearchText) ||
                    item?.providerName
                        ?.toLowerCase()
                        ?.includes(formattedSearchText),
            );
        }
        return integratedProvidersWithIcons;
    }, [searchText, integratedProvidersWithIcons]);

    const handleSearchFromProvider = () => {
        if (searchText) {
            const payload = {
                templateIds: searchText?.split(',')?.map((id) => id.trim()),
            };
            mutateImportTemplateFromProvider(payload);
        }
    };

    const renderSearchedTemplateData = () => {
        if (searchingTemplate) {
            return (
                <GenericOption
                    boxShadow={false}
                    title={<SkeletonLoader height={12} width={100} />}
                    subtitle={<SkeletonLoader height={12} width={250} />}
                />
            );
        }
        if (searchedTemplateData?.templates?.length) {
            return searchedTemplateData?.templates?.map((template) => (
                <GenericOption
                    key={template.id}
                    title={template.name}
                    subtitle={t('templates_importer.id_with_modified_at_time', {
                        id: template.id,
                        updatedAt: template.updatedAt,
                    })}
                    customClass="!w-[100%]"
                    boxShadow={false}
                />
            ));
        }
        if (searchText && searchTemplateSuccess) {
            return <NoResultsFound searchValue={searchText} />;
        }
        return null;
    };

    if (integratedProvidersLoading) {
        // TODO - Add a proper loader here
        return <Box>Loading</Box>;
    }

    if (selectedIntegration) {
        return (
            <Box>
                <Grid>
                    <Grid.Col span={10}>
                        <SearchInput
                            placeholder={t(
                                'campaigns.import_template.search_template_id',
                            )}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => setSearchText(e.target.value)}
                        />
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Button
                            className="h-[35px] w-full"
                            leftIcon={
                                searchingTemplate && (
                                    <LoaderIcon size="14" color="white" />
                                )
                            }
                            onClick={handleSearchFromProvider}
                            disabled={searchText?.length === 0}
                        >
                            {t('table.search_placeholder')}
                        </Button>
                    </Grid.Col>
                </Grid>
                <Box className="h-[300px] mt-3">
                    {renderSearchedTemplateData()}
                </Box>
            </Box>
        );
    }

    return (
        <Box>
            <SearchInput
                placeholder={'Search Integrations'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchText(e.target.value)
                }
            />
            <Box>
                {filteredIntegrations?.map((item) => (
                    <GenericOption
                        key={item.integrationId}
                        title={item?.providerName}
                        subtitle={item?.customName}
                        border={false}
                        boxShadow={false}
                        rightSection={<CaretRight weight="regular" />}
                        customClass="!w-[100%]"
                        onClick={() => setSelectedIntegration(item)}
                        iconComponent={
                            <Image
                                src={item.logoUrl}
                                alt={item.integrationId}
                                width={14}
                                height={14}
                            />
                        }
                    />
                ))}
            </Box>
        </Box>
    );
};

export default ImportFromProvider;
