import { JourneyFiltersProvider } from '@components/Journeys/Builder/JourneyFilters/FiltersForm/JourneyFiltersProvider/JourneyFiltersProvider';
import UpdateTraitBlock, { type UpdateTraitProps } from './UpdateTraitBlock';

const UpdateTrait: React.FC<UpdateTraitProps> = ({
    traitConfig,
    nodeId,
    onUpdateConfigChange,
}) => {
    return (
        <JourneyFiltersProvider nodeId={nodeId}>
            <UpdateTraitBlock
                nodeId={nodeId}
                onUpdateConfigChange={onUpdateConfigChange}
                traitConfig={traitConfig}
            />
        </JourneyFiltersProvider>
    );
};

export default UpdateTrait;
