import { SortmentLogo } from '@assets/icons/Core';
import Select from '@components/common/Select';
import OnBoardingFooter from '@components/Onboarding/OnboardingFooter';
import Page from '@components/Page/Page';
import PageSpinner from '@components/PageSpinner';
import { useLocale } from '@hooks/useLocale';
import { useProjects } from '@hooks/useProjects';
import { useUserCompleteMutation } from '@hooks/user/useUserCompleteMutation';
import { type CompleteUserArgs } from '@lightdash/common';
import { Box, Button, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import useApp from '@providers/App/useApp';
import { shuffle } from 'lodash';
import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router';
const jobTitles = [
    ...shuffle([
        'Data/Analytics Leader',
        'Data Scientist',
        'Data Analyst',
        'Data Engineer',
        'Analytics Engineer',
        'Software Engineer',
        'Sales',
        'Marketing',
        'Marketing Leader',
        'Product',
        'Operations',
        'Customer Service',
    ]),
    'Other',
];

const CreateOrganization: React.FC<{}> = ({}) => {
    const { health } = useApp();
    const { t } = useLocale();
    const navigate = useNavigate();
    const { isLoading, mutate, isSuccess } = useUserCompleteMutation();
    const { data: projects } = useProjects();
    const form = useForm<CompleteUserArgs>({
        initialValues: {
            organizationName: '',
            jobTitle: '',
            enableEmailDomainAccess: true,
            isMarketingOptedIn: true,
            isTrackingAnonymized: false,
        },
    });
    useEffect(() => {
        if (isSuccess) {
            void navigate('/create-workspace');
        }
    }, [isSuccess, navigate]);
    if (health.isInitialLoading) {
        return <PageSpinner />;
    }
    /**
     * Redirect to the root if the organization has at least one project/workspace already setup
     */
    if (projects && projects.length > 0) {
        return <Navigate to="/" />;
    }
    return (
        <>
            <Page
                title={t('organization_creation.page_title')}
                withCenteredContent
                withFullHeight
                backgroundColor="gray"
            >
                <SortmentLogo />
                <section className="w-[300px] px-3 pt-6 pb-3 bg-white rounded-3xl flex flex-col items-center border-base gap-3 shadow-card mt-7">
                    <div className="flex flex-col items-center w-full gap-2">
                        <p className="text-sm font-medium text-gray-800">
                            {t(
                                'organization_creation.join_organization_header',
                            )}
                        </p>
                    </div>
                    <form
                        name="create_organization"
                        onSubmit={form.onSubmit((values) => {
                            mutate(values);
                        })}
                        className="w-full"
                    >
                        <Stack className="w-full gap-6">
                            <Stack className="w-full gap-3.5">
                                <TextInput
                                    label={t(
                                        'organization_creation.organization_name_label',
                                    )}
                                    name="organisation name"
                                    placeholder={t(
                                        'organization_creation.organization_name_placeholder',
                                    )}
                                    required
                                    {...form.getInputProps('organizationName')}
                                    disabled={isLoading || isSuccess}
                                    withAsterisk={false}
                                />
                            </Stack>
                            <Stack className="w-full gap-1">
                                <Select
                                    label={t(
                                        'organization_creation.select_role_label',
                                    )}
                                    data={jobTitles.map((role) => ({
                                        value: role,
                                        label: role,
                                    }))}
                                    placeholder={t(
                                        'organization_creation.select_role_placeholder',
                                    )}
                                    disabled={isLoading}
                                    required
                                    dropdownPosition="bottom"
                                    maxDropdownHeight={400}
                                    withinPortal
                                    className="!w-[274px]"
                                    withAsterisk={false}
                                    {...form.getInputProps('jobTitle')}
                                ></Select>
                            </Stack>
                            <Button
                                type="submit"
                                data-cy="signin-button"
                                className="py-3 rounded-lg"
                                loading={isLoading}
                                disabled={isLoading || isSuccess}
                            >
                                {t('common.continue_button')}
                            </Button>
                            <Box className="flex justify-center">
                                <Text className="text-sm font-medium text-gray-500">
                                    {t('organization_creation.footnote')}
                                </Text>
                            </Box>
                        </Stack>
                    </form>
                </section>
            </Page>
            <Box className="flex justify-center">
                <OnBoardingFooter />
            </Box>
        </>
    );
};
export default CreateOrganization;
