import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import TextInput from '@components/common/Inputs/TextInput';
import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import UnsavedChangesModal from '@components/common/modal/UnsavedChangesModal';
import TruncatedText from '@components/common/TruncateAtMiddleText';
import {
    useCreateEntityKey,
    useDeleteEntityKey,
    useDeleteEventSource,
    useGetAllEntityKeys,
    useUpdateEventSource,
} from '@hooks/useEvents';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import { AllowedEntities, type SourceEventKeyMapping } from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { Check, Copy, PlusCircle, Trash } from '@phosphor-icons/react';
import useApp from '@providers/App/useApp';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';

interface FormData {
    name: string;
    description: string | undefined;
    eventNameKey: string;
}
interface SourceDataModalProps {
    showSourceData: boolean;
    sourceDataClose: () => void;
    sourceData: SourceEventKeyMapping;
}

const SourceDataModal: React.FC<SourceDataModalProps> = ({
    showSourceData,
    sourceDataClose,
    sourceData,
}) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { t } = useLocale();
    const queryClient = useQueryClient();
    const clipboard = useClipboard({ timeout: 1000 });
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();
    const { data: entityKeys } = useGetAllEntityKeys(sourceData.uuid);
    const { mutateAsync: mutateDeleteEntity, isLoading: isDeletingEntity } =
        useDeleteEntityKey();

    const {
        mutateAsync: mutateAddEntity,
        isLoading: isAddEntityLoading,
        data: entityData,
        reset: entityDataReset,
    } = useCreateEntityKey();

    const { mutateAsync: updateEventSource } = useUpdateEventSource();

    const {
        mutateAsync: mutateDeleteEventSource,
        isLoading: isDeletingEventSource,
    } = useDeleteEventSource();

    const { user } = useApp();
    const ability = useAbilityContext();
    const isDisabled = ability.cannot(
        'manage',
        subject('Project', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const [urlCopied, setUrlCopied] = useState(false);
    const [bearerCopied, setBearerCopied] = useState(false);

    const [defaultValues, setDefaultValues] = useState<FormData>({
        name: sourceData.source,
        description: sourceData.description,
        eventNameKey: sourceData.eventNameKeys[0].slice(2),
    });

    const {
        register,
        handleSubmit,
        control,
        reset,
        setError,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues,
    });

    const watchFields = useWatch({ control });

    const hasFormChanged = useIsEqual(defaultValues, watchFields);

    const handleClick = useCallback(async () => {
        await queryClient.invalidateQueries([QueryKeys.GET_ALL_ENTITY_KEYS]);
        await mutateAddEntity({
            payload: {
                entityId: sourceData.uuid,
                entityType: AllowedEntities.EVENT_INGESTION_SOURCE,
            },
        });
    }, [mutateAddEntity, queryClient, sourceData.uuid]);

    const handleDelete = useCallback(
        async (keyId: string) => {
            await mutateDeleteEntity({ keyId });
            entityDataReset();
            await queryClient.invalidateQueries([
                QueryKeys.GET_ALL_ENTITY_KEYS,
            ]);
        },
        [entityDataReset, mutateDeleteEntity, queryClient],
    );

    const handleCopy = useCallback(
        (
            value: string,
            setCopied: React.Dispatch<React.SetStateAction<boolean>>,
        ) => {
            clipboard.copy(value);
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
        },
        [clipboard],
    );

    const renderCopy = useCallback(
        (
            data: string,
            copied: boolean,
            setCopied: React.Dispatch<React.SetStateAction<boolean>>,
        ) => {
            if (copied)
                return <Check className="mx-2" weight="regular" size={14} />;

            return (
                <Copy
                    className="cursor-pointer mx-2"
                    onClick={() => {
                        handleCopy(data, setCopied);
                    }}
                    size={14}
                    weight="duotone"
                />
            );
        },
        [handleCopy],
    );

    const onSubmit = useCallback(
        async (data: FormData) => {
            if (data.name.trim() === '') {
                setError('name', {});
                return;
            }
            if (data.eventNameKey.trim() === '') {
                setError('eventNameKey', {});
                return;
            }
            setDefaultValues(data);
            reset(data);
            await updateEventSource({
                payload: {
                    description: data.description,
                    eventNameKeys: [`$.${data.eventNameKey}`],
                },
                sourceId: sourceData.uuid,
            });
        },
        [reset, updateEventSource, sourceData.uuid, setError],
    );

    const handleDeleteEventSource = useCallback(async () => {
        await mutateDeleteEventSource(sourceData.uuid);
        setShowDeleteModal(false);
        sourceDataClose();
    }, [
        mutateDeleteEventSource,
        sourceData.uuid,
        setShowDeleteModal,
        sourceDataClose,
    ]);

    return (
        <>
            <Modal
                opened={showSourceData}
                onClose={() => sourceDataClose()}
                keepMounted={false}
                title={t('event_source.view_event_source')}
                size="calc(45rem)"
                footerLeftSection={
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={() => setShowDeleteModal(true)}
                    >
                        {t('common.delete')}
                    </Button>
                }
                footerRightSection={
                    <ModalFooter
                        showSecondaryButton={true}
                        secondaryButtonVariant={ButtonVariant.OUTLINED}
                        secondaryText={t(
                            'custom_metric.dimension_modal_cancel_button',
                        )}
                        secondaryButtonClick={sourceDataClose}
                        primaryText={undefined}
                        primaryButtonClick={undefined}
                        showPrimaryButton={undefined}
                        primaryLeftIcon={undefined}
                        primaryRightIcon={undefined}
                        secondaryLeftIcon={undefined}
                        secondaryRightIcon={undefined}
                        primaryButtonVariant={undefined}
                        isLoading={undefined}
                        primaryButtonDisabled={undefined}
                        primaryButtonCustomClass={undefined}
                    />
                }
                styles={(_params) => ({
                    content: {
                        maxHeight: 'unset !important',
                    },
                })}
            >
                <form
                    name="update_source_data"
                    id="update_source_data"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Text className="text-sm font-medium text-gray-800">
                        {t('event_source.name_this_source')}
                    </Text>
                    <TextInput
                        {...register('name')}
                        readOnly
                        error={
                            errors.name && t('subscription_group.invalid_name')
                        }
                        className="mt-1.5"
                    />
                    <Flex className="gap-1 mt-3">
                        <Text className="text-sm font-medium text-gray-800">
                            {t('journey_settings_overview.description')}
                        </Text>
                        <Text className="text-sm font-medium text-gray-600">
                            {t(
                                'custom_metric.dimension_modal_description_label_optional',
                            )}
                        </Text>
                    </Flex>
                    <TextInput
                        {...register('description')}
                        error={
                            errors.description &&
                            t('journey_settings_overview.invalid_description')
                        }
                        className="mt-1.5"
                    />
                    <Text className="text-sm font-medium text-gray-800 mt-3">
                        {t('event_source.event_name_key_path')}
                    </Text>
                    <TextInput
                        {...register('eventNameKey')}
                        className="mt-1.5"
                        error={
                            errors.eventNameKey && t('event_source.invalid_key')
                        }
                    />
                </form>

                <Text className="text-gray-800 font-medium text-sm mt-3">
                    {t('event_source.url')}
                </Text>
                <Flex className="items-center mt-1.5 mb-3">
                    <Text className="border border-shade-6 rounded-l-lg px-3.5 py-2 text-gray-500 text-sm font-medium">
                        {t('event_source.post')}
                    </Text>
                    <TruncatedText
                        text={`${window.location.origin}/api/v1/projects/${projectUuid}/events`}
                        maxWidth={400}
                        customClass="text-gray-800 border border-gray-50 text-sm bg-gray-50 px-3.5 py-2 rounded-r-lg font-medium"
                    />
                    {renderCopy(
                        `${window.location.origin}/api/v1/projects/${projectUuid}/events`,
                        urlCopied,
                        setUrlCopied,
                    )}
                </Flex>
                <Box className="border-b-4 border-shade-2 mx-4" />
                <Text className="text-gray-800 font-medium text-sm pt-3">
                    {t('event_source.authorization_keys')}
                </Text>
                <Box className="max-h-40 overflow-y-scroll mt-1.5">
                    {entityKeys &&
                        entityKeys.map((item) => (
                            <Box
                                className="flex items-center w-full"
                                key={item.uuid}
                            >
                                <TextInput
                                    className="my-1 w-[25rem]"
                                    readOnly
                                    key={item.uuid}
                                    value={`Bearer ${item.entityAPIKey}`}
                                />
                                {!isDisabled && (
                                    <Trash
                                        onClick={() => handleDelete(item.uuid)}
                                        className={`mx-2 cursor-pointer ${
                                            isAddEntityLoading
                                                ? 'opacity-50 pointer-events-none'
                                                : ''
                                        }`}
                                        size={14}
                                        weight="duotone"
                                    />
                                )}
                            </Box>
                        ))}
                </Box>

                {entityData && (
                    <Box className="flex items-center">
                        <TextInput
                            className="w-[25rem]"
                            value={`${t('event_source.bearer')} ${
                                entityData.entityAPIKey
                            }`}
                        />

                        {renderCopy(
                            `${t('event_source.bearer')} ${
                                entityData.entityAPIKey
                            }`,
                            bearerCopied,
                            setBearerCopied,
                        )}
                    </Box>
                )}

                {!isDisabled && (
                    <Button
                        className="mt-2 text-white font-normal text-sm"
                        loading={isAddEntityLoading}
                        disabled={isDeletingEntity}
                        onClick={() => handleClick()}
                        variant={ButtonVariant.FILLED}
                        leftIcon={
                            <PlusCircle
                                color="white"
                                size={14}
                                weight="duotone"
                            />
                        }
                    >
                        {t('event_source.add_key')}
                    </Button>
                )}
                <UnsavedChangesModal
                    opened={hasFormChanged}
                    secondaryActionButtonClick={() => {
                        setDefaultValues({
                            name: sourceData.source,
                            description: sourceData.description
                                ? sourceData.description
                                : '',
                            eventNameKey: sourceData.eventNameKeys[0].slice(2),
                        });
                        reset({
                            name: sourceData.source,
                            description: sourceData.description
                                ? sourceData.description
                                : '',
                            eventNameKey: sourceData.eventNameKeys[0].slice(2),
                        });
                    }}
                    disableButtons={false}
                    form="update_source_data"
                    type="submit"
                />
            </Modal>
            {showDeleteModal && (
                <Modal
                    title={t('event_source.delete_event_source.title')}
                    opened={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    footerLeftSection={
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={() => setShowDeleteModal(false)}
                        >
                            {t('common.cancel')}
                        </Button>
                    }
                    footerRightSection={
                        <Button
                            variant={ButtonVariant.FILLED_DESTRUCTIVE}
                            onClick={handleDeleteEventSource}
                            loading={isDeletingEventSource}
                        >
                            {isDeletingEventSource
                                ? t('common.deleting')
                                : t('common.delete')}
                        </Button>
                    }
                >
                    <Text>
                        {t('event_source.delete_event_source.description')}
                    </Text>
                </Modal>
            )}
        </>
    );
};

export default SourceDataModal;
