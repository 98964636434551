import ChannelTabs from '@components/ChannelTabs';
import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import Modal from '@components/common/modal/Modal';
import Page from '@components/Page/Page';
import SyncedWhatsappTemplates from '@components/TemplateManager/Table/SyncedWhatsappTemplates';
import TemplateTable from '@components/TemplateManager/Table/TemplateTable';
import TemplateBuilderManager from '@components/Templates/Builder/TemplateBuilderManager';
import TemplateOptionsModal from '@components/Templates/TemplateOptionsModal';
import WhatsappPreview from '@components/Templates/WhatsappManager/Preview';
import { useGetChannelIntegrations } from '@hooks/useChannels';
import { useTemplates } from '@hooks/useGetTemplate';
import { useLocale } from '@hooks/useLocale';
import {
    CommonReservedTags,
    CommunicationChannel,
    type SyncedTemplateBasicDetails,
} from '@lightdash/common';
import { Box, Button, Tabs, Text } from '@mantine/core';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonVariant } from '../mantineTheme';

const DEFAULT_PROVIDER = CommunicationChannel.EMAIL;

const TemplateBuilderContainer: React.FC<{}> = ({}) => {
    // TODO - LOOK FOR AN ALTERNATE SOLUTION
    const [editMode, setEditMode] = useState(false);
    const [searchValue, setSearchValue] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [activePill, setActivePill] =
        useState<CommunicationChannel>(DEFAULT_PROVIDER);
    const handlePillChange = (pill: CommunicationChannel) => {
        setActivePill(pill);
    };
    const { t } = useLocale();
    const [isHiddenActive, setIsHiddenActive] = useState<boolean>(false);
    let { data: channelIntegration } = useGetChannelIntegrations(activePill);
    const [currentPage, setCurrentPage] = useState(1);

    const { data: templates, isFetching } = useTemplates(
        activePill,
        8,
        currentPage,
        searchValue,
        activePill !== CommunicationChannel.WHATSAPP,
        isHiddenActive
            ? `includesTags=${CommonReservedTags.HIDDEN}`
            : `excludesTags=${CommonReservedTags.HIDDEN}`,
    );
    const { data: hiddenTemplates } = useTemplates(
        activePill,
        10,
        1,
        searchValue,
        activePill !== CommunicationChannel.WHATSAPP,
        `includesTags=${CommonReservedTags.HIDDEN}`,
    );
    useEffect(() => {
        if ((hiddenTemplates?.data?.length ?? 0) === 0) {
            setIsHiddenActive(false);
        }
    }, [hiddenTemplates]);
    const [opened, { toggle, close }] = useDisclosure(false);
    const [whatsappPreview, setWhatsappPreview] = useState<boolean>(false);
    // TODO - Fix this type with Naimish BE is sending the response in a different type from what we have in controller
    const [selectedWhatsappTemplate, setSelectedWhatsappTemplate] =
        useState<any>(undefined);

    const [selectedTemplate, setSelectedTemplate] = useState<
        string | undefined
    >(undefined);
    const previousActivePill = useRef(activePill);
    const fetchData = useCallback(async () => {
        if (activePill !== previousActivePill.current) {
            previousActivePill.current = activePill;
            setCurrentPage(1);
        }
    }, [activePill]);
    useEffect(() => {
        void fetchData();
    }, [fetchData, currentPage, activePill]);

    const handleTemplateSelect = (id: string) => {
        if (activePill === CommunicationChannel.WHATSAPP) return;
        setSelectedTemplate(id);
        setEditMode(true);
        toggle();
    };

    const handleWhatsappTemplateSelect = (
        payload: SyncedTemplateBasicDetails,
    ) => {
        setSelectedTemplate(payload.name);
        setEditMode(true);
        setSelectedWhatsappTemplate(payload);
        setWhatsappPreview(true);
    };

    const handleSearchInput = (value: string) => {
        setSearchValue(value);
        setCurrentPage(1);
    };

    const getContent = () => {
        if (activePill === CommunicationChannel.WHATSAPP) {
            return (
                <SyncedWhatsappTemplates
                    integrationId=""
                    handleRowClick={handleWhatsappTemplateSelect}
                />
            );
        }
        if (templates?.data) {
            return (
                <TemplateTable
                    onPageChange={async (newPage) => {
                        setCurrentPage(newPage);
                    }}
                    pagination={templates.pagination}
                    templates={templates?.data}
                    isDataFetching={isFetching}
                    handleRowPress={handleTemplateSelect}
                    handleSearchInput={handleSearchInput}
                    searchValue={searchValue}
                    activeTab={activePill}
                    isManager={true}
                    isHiddenActive={isHiddenActive}
                    onHiddenChange={(isHiddenFlag: boolean) => {
                        setIsHiddenActive(isHiddenFlag);
                        setCurrentPage(1);
                    }}
                    hideHiddenButton={
                        (hiddenTemplates?.data?.length ?? 0) === 0
                    }
                />
            );
        }
        if (channelIntegration?.length) {
            <TemplateBuilderManager
                integration={channelIntegration[0]}
                isSingleSelect={true}
                handleTemplateSelect={() => {}}
                integrationId={''}
                channel={activePill}
                onClose={() => {
                    close();
                    setEditMode(false);
                }}
                editMode={editMode}
            />;
        }
        return null;
    };

    return (
        <>
            <ManagerBuilderContainer
                withContentPadding={false}
                title={t('templates')}
                rightSection={
                    activePill !== CommunicationChannel.WHATSAPP && (
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            leftIcon={<PlusCircle color="white" />}
                            onClick={() => {
                                setSelectedTemplate(undefined);
                                toggle();
                            }}
                        >
                            <Text className="text-sm font-semibold">
                                {t('template.create.title')}
                            </Text>
                        </Button>
                    )
                }
            >
                <Tabs
                    keepMounted={false}
                    variant="pills"
                    radius="lg"
                    defaultValue={activePill}
                    onTabChange={handlePillChange}
                    className="pl-2 bg-gray-100/60"
                >
                    <ChannelTabs activeTab={activePill} />
                </Tabs>

                {getContent()}

                <TemplateOptionsModal
                    opened={opened}
                    onClose={() => {
                        close();
                        setEditMode(false);
                    }}
                    providerMetadata={{} as any}
                    integrationId={''}
                    handleTemplateSelect={() => {}}
                    channel={activePill}
                    showCreateEditTemplate={true}
                    templateId={selectedTemplate}
                    isTemplateLibrary={true}
                    editMode={editMode}
                />
            </ManagerBuilderContainer>
            <Modal
                styles={() => ({
                    content: {
                        height: 'fit-content !important',
                        maxHeight: '100vh !important',
                    },
                })}
                title={selectedWhatsappTemplate?.name}
                size={'xl'}
                opened={whatsappPreview}
                onClose={() => {
                    setWhatsappPreview(false);
                    setSelectedWhatsappTemplate(undefined);
                    setEditMode(false);
                    setSelectedTemplate(undefined);
                }}
            >
                <Box>
                    {selectedWhatsappTemplate?.content && (
                        <WhatsappPreview
                            content={selectedWhatsappTemplate?.content}
                            name={selectedWhatsappTemplate?.name}
                            onModify={() => {}}
                            showModifyButton={false}
                            isSetupComplete={false}
                            openContentSetupManager={() => {}}
                            showSetupStrip={false}
                            showHeader={true}
                        />
                    )}
                </Box>
            </Modal>
        </>
    );
};

const Templates = () => {
    const { t } = useLocale();

    return (
        <Page
            title={t('templates')}
            withFullHeight
            withNavbar
            withFixedContent
            withPaddedContent
        >
            <TemplateBuilderContainer />
        </Page>
    );
};

export default Templates;
