import SearchInput from '@components/SearchInput';
import { Box, Divider, Flex, Menu, Stack } from '@mantine/core';
import { CaretDown, Check } from '@phosphor-icons/react';
import Fuse from 'fuse.js';
import React, { useMemo, useState } from 'react';

import InputErrorText from '@components/common/InputErrorText';
import Label from './Label';
import { type LabelIconType } from './types';

export type Option = {
    leftLabel: string | undefined;
    rightLabel: string | undefined;
    leftLabelIcon: LabelIconType | undefined;
    rightLabelIcon: LabelIconType | undefined;
    value: string;
    divider: boolean;
};

type SubscriptionGroupFilterProps = {
    options: Option[];
    value: string | undefined | null;
    onChange: (value: string) => void;
    error: string;
    label: string | undefined;
    width: string | undefined;
    anchorClass?: string;
};

const SubscriptionGroupFilter: React.FC<SubscriptionGroupFilterProps> = ({
    options,
    value,
    onChange,
    error,
    label,
    width,
    anchorClass,
}) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');

    const selectedItem = useMemo(() => {
        return options?.find((option) => option?.value === value);
    }, [options, value]);

    const filteredItems = useMemo(() => {
        const fuse = new Fuse(options, {
            keys: ['leftLabel', 'rightLabel'],
            threshold: 0.3,
        });

        if (searchText) {
            return fuse.search(searchText).map((option) => option.item);
        }
        return options;
    }, [options, searchText]);

    const handleClick = (val: string) => {
        onChange(val);
        setOpened(false);
    };

    const marginBottom = error ? '' : 'mb-2';

    return (
        <Menu width={width ?? '33rem'} position="bottom-start" withinPortal>
            <Stack spacing={'sm'}>
                {label && <Box>{label}</Box>}
                <Menu.Target>
                    <Flex
                        justify="space-between"
                        align="center"
                        onClick={() => setOpened(!opened)}
                        className={`${anchorClass} w-full h-9 border rounded-md p-2 ${marginBottom} hover:cursor-pointer shadow-card`}
                    >
                        <Label data={selectedItem} className="p-2" />
                        <CaretDown weight="regular" />
                    </Flex>
                </Menu.Target>
                <Menu.Dropdown className="p-0 rounded-lg shadow-card">
                    <Box className="p-3 border-b border-b-gray-200">
                        <SearchInput
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </Box>
                    <Box className="max-h-[20rem] overflow-scroll px-2">
                        {filteredItems.map((option) => (
                            <>
                                <Menu.Item
                                    key={option.value}
                                    component="div"
                                    className={`h-fit hover:rounded-md hover:bg-gray-50 hover:cursor-pointer p-0`}
                                >
                                    <Flex
                                        className="h-[2.375rem]"
                                        onClick={() =>
                                            handleClick(option.value)
                                        }
                                        key={option.value}
                                        justify="space-between"
                                        align="center"
                                    >
                                        <Label
                                            className="h-full p-2.5"
                                            data={option}
                                        />
                                        {value === option.value && (
                                            <Check
                                                className="mr-2"
                                                weight="regular"
                                            />
                                        )}
                                    </Flex>
                                </Menu.Item>
                                {option.divider && (
                                    <Divider
                                        size="md"
                                        className="mt-2 mb-2 border-t-shade-2"
                                    />
                                )}
                            </>
                        ))}
                    </Box>
                </Menu.Dropdown>
                {error && <InputErrorText value={error} />}
            </Stack>
        </Menu>
    );
};

export default SubscriptionGroupFilter;
