import useTimestamp from '@hooks/useTimestamp';
import { t as translate } from 'i18next';
import React, { useEffect, useState } from 'react';

const formatTimestamp = (timestamp: string): string => {
    const inputDate = new Date(timestamp);
    const currentDate = new Date();

    const timeDifferenceInMilliseconds =
        currentDate.getTime() - inputDate.getTime();
    const timeDifferenceInSeconds = Math.floor(
        timeDifferenceInMilliseconds / 1000,
    );

    if (timeDifferenceInSeconds < 0) {
        // Future timestamp
        const futureTimeDifferenceInSeconds = Math.abs(timeDifferenceInSeconds);
        if (futureTimeDifferenceInSeconds < 60) {
            return translate('timestamp.just_now');
        } else if (futureTimeDifferenceInSeconds < 3600) {
            const minutes = Math.floor(futureTimeDifferenceInSeconds / 60);
            return `in ${minutes} min`;
        } else if (futureTimeDifferenceInSeconds < 86400) {
            const hours = Math.floor(futureTimeDifferenceInSeconds / 3600);
            return `in ${hours === 1 ? '1 hour' : `${hours} hours`}`;
        } else {
            const formattedTime = `${inputDate.getHours() % 12 || 12}:${String(
                inputDate.getMinutes(),
            ).padStart(2, '0')} ${inputDate.getHours() >= 12 ? 'PM' : 'AM'}`;

            const formattedDate = `${inputDate.getDate()} ${inputDate.toLocaleString(
                'en-US',
                { month: 'short' },
            )} ${inputDate.getFullYear()}`;

            return `at ${formattedDate}, ${formattedTime}`;
        }
    } else {
        // Past timestamp
        if (timeDifferenceInSeconds < 60) {
            return translate('timestamp.just_now');
        } else if (timeDifferenceInSeconds < 3600) {
            const minutes = Math.floor(timeDifferenceInSeconds / 60);
            return translate('timestamp.x_min', { minutes: minutes });
        } else if (timeDifferenceInSeconds < 86400) {
            const hours = Math.floor(timeDifferenceInSeconds / 3600);
            return `${
                hours === 1
                    ? translate('timestamp.x_hour_one')
                    : translate('timestamp.x_hour_many', { hours: hours })
            }`;
        } else {
            const formattedTime = `${inputDate.getHours() % 12 || 12}:${String(
                inputDate.getMinutes(),
            ).padStart(2, '0')} ${inputDate.getHours() >= 12 ? 'PM' : 'AM'}`;

            const formattedDate = `${inputDate.getDate()} ${inputDate.toLocaleString(
                'en-US',
                { month: 'short' },
            )} ${inputDate.getFullYear()}`;

            return `${formattedDate}, ${formattedTime}`;
        }
    }
};

interface TimestampProps {
    timestamp: string;
    customClass?: string;
}

const Timestamp: React.FC<TimestampProps> = ({ timestamp, customClass }) => {
    const [formattedString, setFormattedString] = useState<string>('');
    const { getTimestamp } = useTimestamp();

    useEffect(() => {
        setFormattedString(formatTimestamp(getTimestamp(timestamp)));
    }, [getTimestamp, timestamp]);

    return (
        <div className={`${customClass ? customClass : ''} text-sm`}>
            {formattedString}
        </div>
    );
};

export default Timestamp;
