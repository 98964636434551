import { useContextSelector } from 'use-context-selector';
import TemplateBuilderProviderContext from './context';
import { type TemplateBuilderContext } from './types';

function useTemplateBuilderContext<Selected>(
    selector: (value: TemplateBuilderContext) => Selected,
): Selected {
    return useContextSelector(TemplateBuilderProviderContext, (context) => {
        if (context === undefined) {
            throw new Error(
                'useContext must be used within Template Builder Provider',
            );
        }
        return selector(context);
    });
}

export default useTemplateBuilderContext;
