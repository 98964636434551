import { Timezones } from '@lightdash/common';
import { Text } from '@mantine/core';
import useProjectContext from '@providers/Project/useProjectContext';
import moment from 'moment';
import React from 'react';

type TimezoneTextProps = {
    value?: string;
};

const TimezoneText: React.FC<TimezoneTextProps> = ({ value }) => {
    const { projectData } = useProjectContext();
    const timezone = value || (projectData?.timezone ?? Timezones.UTC);
    const offset = moment().tz(timezone).format('Z');

    return (
        <Text className="text-gray-600">{`${timezone} (GMT${offset})`}</Text>
    );
};

export default TimezoneText;
