import { SortmentLogo } from '@assets/icons/Core';
import PasswordInput from '@components/common/Inputs/PasswordInput';
import Page from '@components/Page/Page';
import PageSpinner from '@components/PageSpinner';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import { useForm } from '@mantine/form';
import { type FC } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router';
import {
    usePasswordResetLink,
    usePasswordResetMutation,
} from '@hooks/usePasswordReset';
import { Anchor, Button, Text, Title } from '@mantine/core';
import { Edit } from 'react-feather';
import useApp from '../providers/App/useApp';

type ResetPasswordForm = { password: string; confirmPassword: string };

const PasswordReset: FC = () => {
    const navigate = useNavigate();
    const { code } = useParams<{ code: string }>();
    const { health } = useApp();
    const { isInitialLoading, data } = usePasswordResetLink(code);
    const passwordResetMutation = usePasswordResetMutation();
    const { t } = useLocale();
    const form = useForm<ResetPasswordForm>({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
    });
    const { showToastError } = useNotify();

    if (health.isInitialLoading || isInitialLoading) {
        return <PageSpinner />;
    }

    if (health.status === 'success' && health.data?.isAuthenticated) {
        return <Navigate to="/" />;
    }

    //TODO: Handle error if reset link fails

    return (
        <Page
            title="New password"
            withCenteredContent
            withFullHeight
            backgroundColor="gray"
        >
            <section className="w-[300px] px-3 pt-6 pb-3 bg-white flex flex-col items-center border-base gap-3 shadow-card rounded-3xl">
                <SortmentLogo />

                <>
                    {!passwordResetMutation.isSuccess ? (
                        <>
                            <div className="flex flex-col items-center w-full gap-2">
                                <p className="text-sm font-medium text-gray-600">
                                    {t('password_reset.header')}
                                </p>
                                <Anchor
                                    component={Link}
                                    to="/recover-password"
                                    className="hover:no-underline"
                                >
                                    <div className="flex flex-row items-center gap-1.5 text-xs justify-center text-gray-600 font-medium bg-shade-4 px-1.5 py-0.5 rounded cursor-pointer">
                                        <p>{data?.email}</p>
                                        <Edit size={11} strokeWidth={2} />
                                    </div>
                                </Anchor>
                            </div>
                            <form
                                name="password-reset"
                                onSubmit={form.onSubmit(
                                    ({ password, confirmPassword }) => {
                                        if (password !== confirmPassword) {
                                            showToastError({
                                                title: `Password mismatch`,
                                                subtitle:
                                                    'Please make sure the passwords match',
                                            });
                                            return;
                                        }
                                        if (code) {
                                            passwordResetMutation.mutate({
                                                code,
                                                newPassword: password,
                                            });
                                        }
                                    },
                                )}
                                className="flex flex-col w-full gap-3 mt-3"
                            >
                                <PasswordInput
                                    label={t(
                                        'password_reset.password_new_label',
                                    )}
                                    name="password"
                                    placeholder={t(
                                        'password_reset.password_new_placeholder',
                                    )}
                                    disabled={passwordResetMutation.isLoading}
                                    required
                                    {...form.getInputProps('password')}
                                    withAsterisk={false}
                                />

                                <PasswordInput
                                    label={t(
                                        'password_reset.password_confirm_label',
                                    )}
                                    name="confirmPassword"
                                    placeholder={t(
                                        'password_reset.password_confirm_placeholder',
                                    )}
                                    disabled={passwordResetMutation.isLoading}
                                    required
                                    {...form.getInputProps('confirmPassword')}
                                    withAsterisk={false}
                                />

                                <Button
                                    type="submit"
                                    loading={passwordResetMutation.isLoading}
                                    className="py-3 rounded-lg"
                                >
                                    {t('password_reset.reset_button')}
                                </Button>
                            </form>
                        </>
                    ) : (
                        <>
                            <Title order={3} ta="center" mb="md">
                                Success!
                            </Title>
                            <Text ta="center" mb="lg" color="dimmed">
                                Your password has been successfully updated.
                                <br /> Use your new password to log in.
                            </Text>

                            <Button
                                fullWidth
                                onClick={() => navigate('/login')}
                                className="py-3 rounded-lg"
                            >
                                {t('common.login')}
                            </Button>
                        </>
                    )}
                </>
            </section>
        </Page>
    );
};

export default PasswordReset;
