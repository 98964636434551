import IntegrationOption from '@components/Campaigns/Builder/IntegrationOption';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import { type Integration } from '@lightdash/common';
import { Accordion, Box, Tabs } from '@mantine/core';
import { CHANNEL_SETUP_STATES } from '@providers/Channel/types';
import useChannelContext from '@providers/Channel/useChannelContext';
import React, { useMemo, useState } from 'react';
import NoResultsFound from '../NoResultsFound';

interface TabBodyProps {
    activePill: string;
    provider: Integration[];
}

const TabBody: React.FC<TabBodyProps> = ({ activePill, provider }) => {
    const [searchText, setSearchText] = useState('');
    const { t } = useLocale();
    const { state, actions } = useChannelContext((context) => context);
    const {
        handleProviderSelect,
        handleShowSetUpModal,
        updateChannelSetupStateAction,
    } = actions;

    const handleClick = (obj: Integration) => {
        handleProviderSelect(obj, true);
        handleShowSetUpModal(true);
        updateChannelSetupStateAction(
            CHANNEL_SETUP_STATES.UPDATE_CHANNEL_DETAILS,
        );
    };

    const filteredIntegrations = useMemo(() => {
        if (searchText) {
            const formattedSearchText = searchText?.toLowerCase();
            return provider?.filter(
                (channel) =>
                    channel?.providerName
                        ?.toLowerCase()
                        ?.includes(formattedSearchText) ||
                    channel?.customName
                        ?.toLowerCase()
                        ?.includes(formattedSearchText),
            );
        }
        return provider;
    }, [searchText, provider]);

    return (
        <Tabs.Panel key={state?.selectedProvider?.channelId} value={activePill}>
            <SearchInput
                placeholder={t('channel_settings.set_up_placeholder')?.replace(
                    '{{channel}}',
                    activePill,
                )}
                onChange={(e) => setSearchText(e.target.value)}
                className="mb-3.5 w-[240px]"
            />
            {filteredIntegrations?.length ? (
                <Accordion>
                    {filteredIntegrations.map((channel: Integration) => {
                        return (
                            <Box
                                key={channel.integrationId}
                                onClick={() => handleClick(channel)}
                            >
                                <IntegrationOption
                                    title={channel?.customName}
                                    providerName={channel?.providerName}
                                    providerLogoUrl={channel?.logoUrl}
                                    integrationId={channel.integrationId}
                                    isMutable={false}
                                    customClass={'!w-full !mb-3.5'}
                                />
                            </Box>
                        );
                    })}
                </Accordion>
            ) : (
                <NoResultsFound searchValue={searchText} />
            )}
        </Tabs.Panel>
    );
};

export default TabBody;
