import { subject } from '@casl/ability';
import UnsavedChangesModal from '@components/common/modal/UnsavedChangesModal';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import { useUpdateMutation } from '@hooks/useProject';
import {
    friendlyName,
    ProjectSettings,
    ProjectType,
    type DbtProjectConfig,
    type DbtProjectType,
    type SupportedDbtVersions,
    type UpdateProject,
    type WarehouseCredentials,
    type WarehouseTypes,
} from '@lightdash/common';
import { Button, Stack, TextInput, Title } from '@mantine/core';
import useProjectContext from '@providers/Project/useProjectContext';
import { useQueryClient } from '@tanstack/react-query';
import { removeEmptyParams } from '@utils/helpers';
import { useEffect, useMemo, useState, type FC } from 'react';
import { useNavigate } from 'react-router';
import {
    useForm,
    useFormContext,
    useWatch,
    type FieldErrors,
} from 'react-hook-form';
import {
    type SubmitErrorHandler,
    type SubmitHandler,
} from 'react-hook-form/dist/types/form';
import { QueryKeys } from 'types/UseQuery';
import useNotify from '../../hooks/toaster/useNotify';
import useActiveJob from '../../providers/ActiveJob/useActiveJob';
import useApp from '../../providers/App/useApp';
import useTracking from '../../providers/Tracking/useTracking';
import { EventName } from '../../types/Events';
import { useAbilityContext } from '../common/Authorization/useAbilityContext';
import { SettingsGridCard } from '../common/Settings/SettingsCard';
import { FormContainer } from './ProjectConnection.styles';
import WarehouseSettingsForm from './WarehouseSettingsForm';

type ProjectConnectionForm = {
    name: string;
    dbt?: DbtProjectConfig | undefined;
    warehouse?: WarehouseCredentials | null;
    dbtVersion?: SupportedDbtVersions | undefined;
};

interface Props {
    showGeneralSettings: boolean;
    disabled: boolean;
    defaultType?: DbtProjectType;
    selectedWarehouse?: WarehouseTypes;
    isProjectUpdate?: boolean;
}

const ProjectForm: FC<Props> = ({
    showGeneralSettings,
    disabled,
    selectedWarehouse,
    isProjectUpdate,
}) => {
    const [warehouse, setWarehouse] = useState(selectedWarehouse);
    const { register } = useFormContext<ProjectConnectionForm>();
    return (
        <Stack spacing="xl">
            {showGeneralSettings && (
                <SettingsGridCard>
                    <div>
                        <Title order={5}>General settings</Title>
                    </div>

                    <div>
                        <TextInput
                            label="Project name"
                            required
                            disabled={disabled}
                            {...register('name')}
                        />
                    </div>
                </SettingsGridCard>
            )}

            <div>
                <WarehouseSettingsForm
                    disabled={disabled}
                    setSelectedWarehouse={setWarehouse}
                    selectedWarehouse={warehouse}
                    isProjectUpdate={isProjectUpdate}
                />
            </div>
        </Stack>
    );
};

const useOnProjectError = (): SubmitErrorHandler<ProjectConnectionForm> => {
    const { showToastError } = useNotify();
    return async (errors: FieldErrors<ProjectConnectionForm>) => {
        if (!errors) {
            showToastError({
                title: 'Form error',
                subtitle: 'Unexpected error, please contact support',
            });
        } else {
            const errorMessages: string[] = Object.values(errors).reduce<
                string[]
            >((acc, section) => {
                const sectionErrors = Object.entries(section || {}).map(
                    ([key, { message }]) => `${friendlyName(key)}: ${message}`,
                );
                return [...acc, ...sectionErrors];
            }, []);
            showToastError({
                title: 'Form errors',
                subtitle: errorMessages.join('\n\n'),
            });
        }
    };
};

export const UpdateProjectConnection: FC<{
    projectUuid: string;
}> = ({ projectUuid }) => {
    const { user } = useApp();
    const ability = useAbilityContext();
    const onError = useOnProjectError();
    const { projectData } = useProjectContext();
    const createMutation = useUpdateMutation(projectUuid);
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading: isSaving } = createMutation;
    const defaultValues: ProjectConnectionForm = useMemo(() => {
        return {
            name: projectData?.name,
            dbt: projectData?.dbtConnection,
            warehouse: {
                ...projectData?.warehouseConnection,
            },
            dbtVersion: projectData?.dbtVersion,
        } as ProjectConnectionForm;
    }, [projectData]);

    const methods = useForm<ProjectConnectionForm>({
        defaultValues,
    });
    const { control, handleSubmit, reset } = methods;
    const isDisabled =
        isSaving ||
        ability.cannot(
            'update',
            subject(ProjectSettings.dataConnection, {
                organizationUuid: user.data?.organizationUuid,
                projectUuid,
            }),
        );
    const watchFields = useWatch<ProjectConnectionForm>({
        control,
    });

    const hasFormChanged = useIsEqual(
        removeEmptyParams({ warehouse: defaultValues.warehouse }),
        removeEmptyParams(watchFields),
    );
    const { track } = useTracking();

    const submitForm: SubmitHandler<ProjectConnectionForm> = async (
        formData,
    ) => {
        const { dbt, warehouse: warehouseConnection } = formData;

        if (user.data) {
            track({
                name: EventName.UPDATE_PROJECT_BUTTON_CLICKED,
            });
            await mutateAsync({
                dbtConnection: dbt,
                warehouseConnection,
            } as UpdateProject);
            await queryClient.invalidateQueries([QueryKeys.PROJECT]);
        }
    };

    const onSubmit = () => {
        void handleSubmit(submitForm)();
    };

    return (
        <>
            <FormContainer
                name="update_project"
                id="update_project"
                methods={methods}
                onError={onError}
                onSubmit={onSubmit}
            >
                <ProjectForm
                    showGeneralSettings={false}
                    isProjectUpdate={true}
                    disabled={isDisabled}
                />
            </FormContainer>
            <UnsavedChangesModal
                opened={hasFormChanged}
                secondaryActionButtonClick={() => reset()}
                disableButtons={isSaving}
                form="update_project"
                type="submit"
            />
        </>
    );
};

interface CreateProjectConnectionProps {
    isCreatingFirstProject: boolean;
    selectedWarehouse?: WarehouseTypes | undefined;
    projectUuid: string;
}

export const CreateProjectConnection: FC<CreateProjectConnectionProps> = ({
    isCreatingFirstProject,
    selectedWarehouse,
    projectUuid,
}) => {
    const navigate = useNavigate();
    const { user, health } = useApp();
    const [createProjectJobId, setCreateProjectJobId] = useState<string>();
    const { activeJobIsRunning, activeJobId, activeJob } = useActiveJob();
    const onError = useOnProjectError();

    const createMutation = useUpdateMutation(projectUuid);
    const queryClient = useQueryClient();
    const { t } = useLocale();
    const { isLoading: isSaving, mutateAsync } = createMutation;
    const methods = useForm<ProjectConnectionForm>({
        shouldUnregister: true,
        defaultValues: {
            name: user.data?.organizationName,
            dbt: health.data?.defaultProject,
            warehouse: { type: selectedWarehouse },
        },
    });
    const { track } = useTracking();
    const onSubmit = async ({
        warehouse: warehouseConnection,
    }: Required<ProjectConnectionForm>) => {
        track({
            name: EventName.CREATE_PROJECT_BUTTON_CLICKED,
        });
        if (selectedWarehouse) {
            const data = await mutateAsync({
                type: ProjectType.DEFAULT,
                dbtConnection: {
                    //@ts-ignore
                    type: 'none',
                    environment: [],
                },
                //@ts-ignore
                dbtVersion: 'v1.6',
                //@ts-ignore
                warehouseConnection: {
                    ...warehouseConnection,
                    type: selectedWarehouse,
                },
            });
            setCreateProjectJobId(data.jobUuid);
        }
    };
    useEffect(() => {
        if (
            createProjectJobId &&
            createProjectJobId === activeJob?.jobUuid &&
            activeJob?.jobResults?.projectUuid
        ) {
            void queryClient.invalidateQueries([QueryKeys.PROJECT]);
            void navigate({
                pathname: `/projects/${projectUuid}/blob`,
            });
        }
    }, [activeJob, createProjectJobId, queryClient, projectUuid, navigate]);

    const isSavingProject = useMemo<boolean>(
        () =>
            isSaving ||
            (!!activeJobIsRunning && activeJobId === createProjectJobId),
        [activeJobId, activeJobIsRunning, createProjectJobId, isSaving],
    );
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isSavingProject) {
                event.preventDefault();
                const message = t('warehouse_updation.confirm_reload_message');
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isSavingProject, t]);

    return (
        <FormContainer
            name="create_project"
            methods={methods}
            onSubmit={onSubmit}
            onError={onError}
        >
            <ProjectForm
                showGeneralSettings={!isCreatingFirstProject}
                disabled={isSavingProject}
                defaultType={health.data?.defaultProject?.type}
                selectedWarehouse={selectedWarehouse}
            />
            <Button
                sx={{ alignSelf: 'end' }}
                type="submit"
                loading={isSavingProject}
            >
                Test & compile project
            </Button>
        </FormContainer>
    );
};
