import TransparentInput from '@components/common/Inputs/TransparentInput';
import { type PropertiesType } from '@components/EventsManager/types';
import { canCreateNewLabel } from '@components/EventsManager/utils';
import useNotify from '@hooks/toaster/useNotify';
import { useCreateColumn } from '@hooks/useEvents';
import { useLocale } from '@hooks/useLocale';
import { type DimensionType } from '@lightdash/common';
import { Badge, Box, Button, Flex, Menu, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretRight, WarningCircle } from '@phosphor-icons/react';
import { type Row } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonVariant } from '../../../../../mantineTheme';
import RecursiveMenu from './RecursiveMenu';

type CreatableSelectProps = {
    labelData: { label: string; value: string; dataType: DimensionType }[];
    update: (
        rowIndex: number,
        updates: { [columnId: string]: unknown },
    ) => void;
    row: Row<PropertiesType>;

    canChangeDataType: boolean;
    labelMap: { [key: string]: number };
    highlightEmptyLabel: boolean;
};

const RESERVED_LABELS = ['user id', 'distinct id', 'timestamp'];

const CreatableSelect: React.FC<CreatableSelectProps> = ({
    labelData,
    update,
    row,
    canChangeDataType,
    labelMap,
    highlightEmptyLabel,
}) => {
    const { t } = useLocale();
    const [query, setQuery] = useState<string>(row.original.label);
    const [lastSavedLabel, setLastSavedLabel] = useState<string>(
        row.original.label,
    );
    const { showToastError } = useNotify();
    const inputRef = useRef<HTMLInputElement>(null);
    const { mutateAsync } = useCreateColumn();
    const [opened, { open, close }] = useDisclosure(false);
    const [innerMenuOpened, setInnerMenuOpened] = useState<boolean>(false);
    const [nestedMenuOpened, setNestedMenuOpened] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const [blurTimeoutId, setBlurTimeoutId] = useState<NodeJS.Timeout | null>(
        null,
    );

    useEffect(() => {
        setQuery(row.original.label);
        setLastSavedLabel(row.original.label);
    }, [row.original.label]);

    const isDuplicate = useMemo(() => {
        if (row.original.label)
            return labelMap[row.original.label?.trim()?.toLowerCase()] > 1;
        return false;
    }, [labelMap, row.original.label]);

    const handleCreate = useCallback(
        async (newProperty: string, type: string) => {
            if (RESERVED_LABELS.includes(newProperty.trim().toLowerCase())) {
                showToastError({
                    title: t('event_create.properties_reserved_label_error'),
                });
                return;
            }

            try {
                await mutateAsync({
                    label: newProperty,
                    columnDataType: type as DimensionType,
                    nullable: true,
                });
                update(row.index, {
                    label: newProperty,
                    type: type,
                    columnName: newProperty.toLowerCase().replaceAll(' ', '_'),
                });
                setQuery(newProperty);
                setLastSavedLabel(newProperty);
            } catch (error) {}
        },
        [update, row.index, mutateAsync, showToastError, t],
    );

    const filteredLabelData = useMemo(
        () =>
            labelData.filter((item) => {
                if (row.original.label !== lastSavedLabel) {
                    return item.label
                        ?.toLowerCase()
                        .includes(query?.toLowerCase());
                }
                return true;
            }),
        [labelData, query, lastSavedLabel, row.original.label],
    );

    const handleOuterMenuClick = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
    }, []);

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(e.currentTarget.value?.trim());
            if (!opened) open();
            setLastSavedLabel(e.currentTarget.value);
        },
        [open, opened],
    );

    const handleInputFocus = useCallback(() => {
        if (!opened) {
            open();
            setTimeout(() => {
                inputRef.current?.focus();
            }, 100);
        }
    }, [open, opened]);

    const handleInputBlur = useCallback(() => {
        if (blurTimeoutId) {
            clearTimeout(blurTimeoutId);
        }
        setBlurTimeoutId(
            setTimeout(() => {
                if (
                    menuRef.current &&
                    !menuRef.current.contains(document.activeElement)
                ) {
                    setLastSavedLabel(row.original.label);
                    if (opened) close();
                }
            }, 100),
        );
    }, [row.original.label, close, opened, blurTimeoutId]);

    const createLabel = useMemo(() => {
        if (
            filteredLabelData.some(
                (labelObj) =>
                    labelObj.label.trim().toLowerCase() ===
                    query.trim().toLowerCase(),
            )
        )
            return null;
        if (
            canCreateNewLabel(
                query,
                filteredLabelData.map((props) => props.value),
            ) &&
            !RESERVED_LABELS.includes(query.toLowerCase())
        ) {
            if (canChangeDataType) {
                return (
                    <Menu.Item onClick={handleOuterMenuClick}>
                        <Menu
                            width={172}
                            position="right-start"
                            shadow="md"
                            trigger="hover"
                            opened={innerMenuOpened || nestedMenuOpened}
                            onOpen={() => setInnerMenuOpened(true)}
                            onClose={() =>
                                !nestedMenuOpened && setInnerMenuOpened(false)
                            }
                            withinPortal={true}
                        >
                            <Menu.Target>
                                <Flex justify="space-between" align="center">
                                    <Flex gap={4} align="center">
                                        <Badge className="p-1 m-0 leading-4 rounded-md">
                                            {t(
                                                'event_create.properties_create_new_label',
                                            )}
                                        </Badge>
                                        <Text className="w-[10rem] overflow-auto text-sm text-gray-800 font-medium">
                                            {query}
                                        </Text>
                                    </Flex>
                                    <CaretRight weight="bold" />
                                </Flex>
                            </Menu.Target>
                            <RecursiveMenu
                                handleClick={async (type: string) => {
                                    await handleCreate(query, type);
                                }}
                                nestedMenuOpened={nestedMenuOpened}
                                setNestedMenuOpened={setNestedMenuOpened}
                                level={0}
                            />
                        </Menu>
                    </Menu.Item>
                );
            }

            return (
                <Menu.Item onClick={handleOuterMenuClick}>
                    <Flex
                        onClick={async () => {
                            await handleCreate(query, row.original.type);
                        }}
                        className="cursor-pointer"
                        justify="space-between"
                        align="center"
                    >
                        <Flex gap={4} align="center">
                            <Button
                                variant={ButtonVariant.FILLED}
                                className="p-1 m-0 leading-4 rounded-md"
                            >
                                {t('event_create.properties_create_new_label')}
                            </Button>
                            <Text className="w-[10rem] overflow-auto text-sm text-gray-800 font-medium">
                                {query}
                            </Text>
                        </Flex>
                        <CaretRight weight="bold" />
                    </Flex>
                </Menu.Item>
            );
        }
        return null;
    }, [
        query,
        filteredLabelData,
        canChangeDataType,
        innerMenuOpened,
        nestedMenuOpened,
        handleOuterMenuClick,
        handleCreate,
        row.original.type,
        t,
    ]);

    return (
        <Box className="z-50" ref={menuRef}>
            <Menu
                opened={opened || innerMenuOpened}
                position="top-start"
                styles={{
                    dropdown: {
                        padding: '0px !important',
                    },
                }}
            >
                <Menu.Target>
                    <Button variant={ButtonVariant.UNSTYLED}>
                        <Tooltip
                            label={t('event_create_properties_tooltip_label')}
                            disabled={!isDuplicate}
                            color="dark"
                            position="top"
                            styles={{
                                tooltip: {
                                    backgroundColor: 'black',
                                    color: 'white',
                                },
                            }}
                        >
                            <TransparentInput
                                ref={inputRef}
                                value={lastSavedLabel}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                disabled={row.original.reservedKey}
                                icon={
                                    isDuplicate ? (
                                        <WarningCircle color="rgb(var(--color-halt-800))" />
                                    ) : (
                                        ''
                                    )
                                }
                                placeholder={t(
                                    'event_create.properties_select_or_create_label',
                                )}
                                className={`text-gray-800 text-sm font-medium max-w-44 ${
                                    highlightEmptyLabel ? 'py-2' : ''
                                }`}
                                styles={{
                                    input: {
                                        border: 'none',
                                        boxShadow: 'none',
                                        ':focus-within': {
                                            boxShadow: 'none',
                                        },
                                        '::placeholder': {
                                            color: highlightEmptyLabel
                                                ? 'rgb(var(--color-halt-800)/0.6) !important'
                                                : '',
                                        },
                                        ':disabled': {
                                            color: 'rgb(var(--color-gray-800)) !important',
                                            fontWeight: 500,
                                            backgroundColor: 'white',
                                            opacity: 1,
                                        },
                                        backgroundColor: highlightEmptyLabel
                                            ? 'rgb(var(--color-halt-800)/0.06) !important'
                                            : '',
                                    },
                                }}
                            />
                        </Tooltip>
                    </Button>
                </Menu.Target>

                <Menu.Dropdown className="rounded-xl">
                    {createLabel}

                    <Flex
                        direction="column"
                        gap={10}
                        className="max-h-[14rem] overflow-auto"
                    >
                        {filteredLabelData.map((item) => (
                            <Menu.Item
                                key={item.value}
                                onClick={() => {
                                    update(row.index, {
                                        label: item.label,
                                        columnName: item.value
                                            .trim()
                                            .toLowerCase()
                                            .replaceAll(' ', '_'),
                                        type: item.dataType,
                                    });
                                    setQuery(item.label);
                                    setLastSavedLabel(item.label);
                                    close();
                                }}
                                className="w-[10rem]"
                            >
                                {item.label}
                            </Menu.Item>
                        ))}
                    </Flex>
                </Menu.Dropdown>
            </Menu>
        </Box>
    );
};

export default CreatableSelect;
