import { filterPropertyTypeMapper } from '@components/Audience/Filters/FilterSelect/useFilterSelectUtils';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import FieldIcon from '@components/common/IconPack/FieldIcon';
import TableIcon from '@components/common/IconPack/TableIcon';
import TraitIcon from '@components/common/IconPack/TraitIcon';
import ListSelect from '@components/common/Select/ListSelect';
import { useLocale } from '@hooks/useLocale';
import {
    DimensionType,
    FieldType,
    isCustomSqlDimension,
    isMetric,
    isTableCalculation,
    // isTableCalculation,
    RelationTableType,
} from '@lightdash/common';
import { Box, Group, Text } from '@mantine/core';
import { CaretLeft, MagnifyingGlass, X } from '@phosphor-icons/react';
import { sortRelationFields } from '@utils/relation';
import React, { useCallback, useMemo, useState } from 'react';
import { type ListSelectOptionsProp } from 'types/ListSelect';
import { GlobalStyles } from '../../../../mantineTheme';

interface FieldSelectProps {
    items: FieldWithSuggestions[];
    onChange: (field: FieldWithSuggestions) => void;
    onClosed: () => void;
}

type ListSelectItems = Array<FieldWithSuggestions & { uuid: string }>;

export const ListSelectItem: React.FC<{
    item: FieldWithSuggestions | undefined;
    divider?: React.ReactNode;
    placeholder?: string;
    hideLabel?: boolean;
    hideTableLabel?: boolean;
    labelCustomClasses?: string;
    tableLabelCustomClasses?: string;
}> = React.memo(
    ({
        item,
        divider,
        placeholder,
        hideLabel,
        hideTableLabel,
        labelCustomClasses,
        tableLabelCustomClasses,
    }) => {
        return (
            <Box className="flex flex-row items-center justify-between px-2 ">
                <Box className="flex flex-row items-center gap-1.5 ">
                    {item && Object.keys(item).length > 0 ? (
                        <>
                            {!hideLabel && (
                                <>
                                    {isMetric(item) && (
                                        <TraitIcon showFunction={true} />
                                    )}
                                    <FieldIcon
                                        type={item.type}
                                        strokeWidth={2.5}
                                    />
                                    <Text
                                        className={`text-sm text-left font-medium text-gray-800 whitespace-nowrap truncate
                                ${labelCustomClasses}
                                `}
                                    >
                                        {item.label}
                                    </Text>
                                </>
                            )}

                            {!hideLabel && !hideTableLabel && (
                                <>
                                    {divider ? (
                                        divider
                                    ) : (
                                        <CaretLeft
                                            color={'rgb(var(--color-gray-600)'}
                                            size={13}
                                            strokeWidth={2}
                                        />
                                    )}
                                </>
                            )}

                            {!hideTableLabel && (
                                <>
                                    <TableIcon
                                        type={
                                            item.tableType ??
                                            RelationTableType.RELATED
                                        }
                                        strokeWidth={2.5}
                                    />
                                    <Text
                                        className={`text-sm text-left font-medium truncate ${
                                            GlobalStyles.tableStyles[
                                                item.tableType ??
                                                    RelationTableType.RELATED
                                            ]?.textColorTailwind
                                        } ${tableLabelCustomClasses}`}
                                    >
                                        {isTableCalculation(item) ||
                                        isCustomSqlDimension(item)
                                            ? item.name
                                            : item.tableLabel}
                                    </Text>
                                </>
                            )}
                        </>
                    ) : (
                        <Text>{placeholder}</Text>
                    )}
                </Box>
            </Box>
        );
    },
);

const transformFieldsToListSelectOptions = (
    fields: ListSelectItems,
): ListSelectOptionsProp[] =>
    fields.map(({ uuid, ...rest }) => ({
        uuid,
        key: uuid,
        value: rest.label ?? '',
        renderComponent: <ListSelectItem item={rest} />,
    }));

const FilterSelect = ({ items, onChange, onClosed }: FieldSelectProps) => {
    const { t } = useLocale();
    const [selectedProperty, setSelectedProperty] = useState<
        RelationTableType | FieldType | undefined
    >(undefined);

    const sortedItems: ListSelectItems = useMemo(() => {
        return sortRelationFields(items).map((item, index) => ({
            ...item,
            uuid:
                item.tableType === RelationTableType.EVENT &&
                item.type === DimensionType.EVENT
                    ? `${item.index ?? index}_${item.fieldReference}_${
                          item.table
                      }`
                    : `${
                          isCustomSqlDimension(item)
                              ? item.id
                              : item.index ?? index
                      }_${item.name}_${
                          isTableCalculation(item)
                              ? item.displayName
                              : item.table
                      }`,
        }));
    }, [items]);

    const filteredFields = useMemo(
        () =>
            transformFieldsToListSelectOptions(
                selectedProperty === undefined
                    ? sortedItems
                    : sortedItems.filter((item) => {
                          if (
                              isMetric(item) &&
                              selectedProperty === FieldType.METRIC
                          ) {
                              return true;
                          }
                          return item.tableType === selectedProperty;
                      }),
            ),
        [sortedItems, selectedProperty],
    );

    const getFilterPropertyTypes = useMemo(() => {
        const order: Record<
            Partial<RelationTableType> | Partial<FieldType>,
            number
        > = {
            [RelationTableType.PRIMARY]: 1,
            [RelationTableType.RELATED]: 2,
            [RelationTableType.EVENT]: 3,
            [FieldType.METRIC]: 4,
            [RelationTableType.AUDIENCE]: 0,
            [FieldType.DIMENSION]: 0,
        };
        const sortedTypes = [
            ...new Set(
                items.map((item) => {
                    if (isMetric(item)) {
                        return item.fieldType;
                    }
                    return item.tableType;
                }),
            ),
        ].sort((a, b) => {
            if (a && b) {
                return order[a] - order[b];
            }
            return 0;
        });
        return sortedTypes;
    }, [items]);
    const handleFieldSelect = useCallback(
        (field: ListSelectOptionsProp) => {
            const selectedField = sortedItems.find(
                ({ uuid }) => uuid === field.uuid,
            );
            if (selectedField) {
                onChange(selectedField);
            }
        },
        [onChange, sortedItems],
    );

    if (!getFilterPropertyTypes || !sortedItems?.length) return null;

    return (
        <Box className="py-3">
            <Box className="p-2 border-t rounded-t-lg border-x shadow-[0px_-3px_4px_0px_rgba(240,240,240,0.50)]">
                <Box className="flex items-center justify-between p-2 text-sm text-gray-600 rounded bg-gradient-to-b from-gray-100 to-transparent">
                    {t('filter_select.adding_filter')}
                    <X
                        className="cursor-pointer"
                        onClick={onClosed}
                        weight="duotone"
                    />
                </Box>
            </Box>

            <Group className="flex items-start gap-0 pl-2">
                <Box className="pt-3 basis-1/5">
                    <ul className="flex flex-col gap-2">
                        <li
                            key={'ALL'}
                            className={`rounded-l-lg cursor-pointer flex flex-col items-start p-2.5  ${
                                selectedProperty === undefined
                                    ? 'bg-blu-100'
                                    : 'bg-white'
                            }`}
                            onClick={() => setSelectedProperty(undefined)}
                        >
                            <p
                                className={`text-sm font-semibold ${
                                    selectedProperty === undefined
                                        ? 'text-blu-800'
                                        : 'text-gray-800'
                                }`}
                            >
                                {t('filter_property_type.all_table_label')}
                            </p>
                            <p
                                className={`text-xs ${
                                    selectedProperty === undefined
                                        ? 'text-blu-800/60'
                                        : 'text-gray-600'
                                }`}
                            >
                                {t(
                                    'filter_property_type.all_table_description',
                                )}
                            </p>
                        </li>
                        {getFilterPropertyTypes.map((type) => {
                            const {
                                label,
                                description,
                                icon,
                                onActiveClasses,
                            } =
                                filterPropertyTypeMapper.find(
                                    (item) => item.propertyType === type,
                                ) || {};

                            return (
                                <li
                                    key={type}
                                    className={`rounded-l-lg cursor-pointer flex flex-col items-start p-2  ${
                                        selectedProperty === type
                                            ? onActiveClasses?.background
                                            : 'bg-white'
                                    }`}
                                    onClick={() => setSelectedProperty(type)}
                                >
                                    <div className="flex flex-row items-center gap-1.5">
                                        {icon}
                                        <p
                                            className={`text-sm font-semibold ${
                                                selectedProperty === type
                                                    ? onActiveClasses?.label
                                                    : 'text-gray-800'
                                            }`}
                                        >
                                            {label}
                                        </p>
                                    </div>

                                    <p
                                        className={`text-xs ${
                                            selectedProperty === type
                                                ? onActiveClasses?.description
                                                : 'text-gray-600'
                                        }`}
                                    >
                                        {description}
                                    </p>
                                </li>
                            );
                        })}
                    </ul>
                </Box>
                <Box className="flex-1 w-full h-96">
                    <ListSelect
                        leftSection={<MagnifyingGlass />}
                        placeholder={
                            selectedProperty
                                ? t('list_select.placeholder_table', {
                                      tableName: filterPropertyTypeMapper.find(
                                          (property) =>
                                              property.propertyType ===
                                              selectedProperty,
                                      )?.searchPlaceholder,
                                  })
                                : t('list_select.placeholder_all')
                        }
                        options={filteredFields ?? []}
                        onOptionSelect={handleFieldSelect}
                    />
                </Box>
            </Group>
        </Box>
    );
};

export default React.memo(FilterSelect);
