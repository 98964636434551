import {
    type CustomAttribute,
    type UserTraitFieldConfig,
} from '@lightdash/common';

export enum CustomMetricTab {
    CALCULATED = 'CALCULATED',
    DYNAMIC = 'DYNAMIC',
}
export type CustomMetric = {
    activePill: CustomMetricTab;
    calculatedMetrics: CustomAttribute[];
    dynamicMetrics: UserTraitFieldConfig[];
};
