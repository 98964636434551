import FieldListItem from '@components/Audience/Filters/FieldListItem';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import Select from '@components/common/Select';
import PropertySelect from '@components/common/Select/PropertySelect';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { FiltersPropertySelectorFieldItem } from '@components/Journeys/Builder/JourneyFilters/FiltersForm/FiltersPropertySelector';
import useJourneyFiltersContext from '@components/Journeys/Builder/JourneyFilters/FiltersForm/JourneyFiltersProvider/useJourneyFiltersContext';
import { type JourneyProperty } from '@components/Journeys/Builder/JourneyFilters/types';
import { useLocale } from '@hooks/useLocale';
import {
    DimensionType,
    getItemId,
    JOURNEY_DYNAMIC_VARIABLE_PREFIX,
} from '@lightdash/common';
import { ActionIcon, Button, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { At, XCircle } from '@phosphor-icons/react';
import { useCallback, useMemo, useState } from 'react';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import { extractJourneyFieldIds } from '../../CallAPI/utils';

interface TraitValueInputProps {
    propertyItems: PropertySelectListType<JourneyProperty>[];
    value: string;
    onChange: (value: any) => void;
    type?: DimensionType;
}

const TraitValueInput = ({
    propertyItems,
    value,
    onChange,
    type,
}: TraitValueInputProps) => {
    const { warehouseFieldsMap, journeyDataSchema, journeyEventFields } =
        useJourneyFiltersContext();
    const [editableState, setEditableState] = useState<boolean>(
        !value?.startsWith(JOURNEY_DYNAMIC_VARIABLE_PREFIX),
    );
    const journeyFieldIds = extractJourneyFieldIds(journeyDataSchema);
    const initialSelectedField = useMemo(() => {
        if (!value) return null;
        if (!value?.startsWith(JOURNEY_DYNAMIC_VARIABLE_PREFIX)) return null;
        const fieldId = value.replace(JOURNEY_DYNAMIC_VARIABLE_PREFIX, '');
        if (journeyFieldIds.includes(fieldId)) {
            return journeyEventFields.find(
                (field) => `${field.table}_${field.name}` === fieldId,
            ) as JourneyProperty;
        }
        return warehouseFieldsMap[fieldId];
    }, [value, warehouseFieldsMap, journeyFieldIds, journeyEventFields]);

    const [selectedField, setSelectedField] = useState<JourneyProperty | null>(
        initialSelectedField,
    );
    const [error, setError] = useState<string | null>(null);
    const [opened, { open, close }] = useDisclosure(false);
    const { t } = useLocale();

    const validateInput = useCallback(
        (inputValue: string) => {
            if (!type) return true;

            switch (type) {
                case DimensionType.NUMBER:
                    const isNumber = !isNaN(Number(inputValue));
                    setError(isNumber ? null : t('validation.must_be_number'));
                    return isNumber;

                case DimensionType.BOOLEAN:
                    const isBoolean =
                        inputValue.toLowerCase() === 'true' ||
                        inputValue.toLowerCase() === 'false';
                    setError(
                        isBoolean ? null : t('validation.must_be_boolean'),
                    );
                    return isBoolean;

                case DimensionType.DATE:
                    const isValidDate = !isNaN(Date.parse(inputValue));
                    setError(isValidDate ? null : t('validation.must_be_date'));
                    return isValidDate;

                default:
                    setError(null);
                    return true;
            }
        },
        [type, t],
    );

    const handleInputChange = useCallback(
        (inputValue: string) => {
            const isValid = validateInput(inputValue);
            if (isValid) {
                let processedValue = inputValue;
                if (type === DimensionType.NUMBER) {
                    processedValue = Number(inputValue).toString();
                } else if (type === DimensionType.BOOLEAN) {
                    processedValue = inputValue.toLowerCase();
                }

                onChange(processedValue);
            }
        },
        [onChange, validateInput, type],
    );

    const commonPropertySelectProps = useMemo(
        () => ({
            items: propertyItems,
            showGroup: true,
            headerRightSection: null,
            onSubmit: (field: JourneyProperty[]) => {
                if (!field[0]) return;
                setSelectedField(field[0]);
                close();
                setEditableState(false);
                onChange(
                    `${JOURNEY_DYNAMIC_VARIABLE_PREFIX}${getItemId(field[0])}`,
                );
            },
            itemTemplate: ({ item }: { item: JourneyProperty }) => (
                <FiltersPropertySelectorFieldItem item={item} />
            ),
            opened,
            close,
            open,
            withinPortal: true,
            showSearch: true,
            searchKeys: ['label', 'name', 'tableLabel'],
            searchPlaceholder: t('audience_filters.search_filter_label'),
            allowMultipleSelection: false,
            showAllItemsGroup: true,
            width: 500,
            height: 350,
        }),
        [propertyItems, opened, close, open, onChange, t],
    );

    const propertySelectWithAtIcon = useMemo(
        () => (
            <PropertySelect<JourneyProperty>
                {...commonPropertySelectProps}
                targetButton={
                    <ActionIcon
                        className="z-10"
                        onClick={() => {
                            setEditableState(true);
                        }}
                    >
                        <At weight="duotone" />
                    </ActionIcon>
                }
                allowMultipleSelection={false}
            />
        ),
        [commonPropertySelectProps],
    );

    const propertySelectWithFieldDisplay = useMemo(
        () => (
            <PropertySelect<JourneyProperty>
                {...commonPropertySelectProps}
                targetButton={
                    <Button
                        rightIcon={
                            <ActionIcon
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>,
                                ) => {
                                    e.stopPropagation();
                                    setEditableState(true);
                                    onChange('');
                                }}
                            >
                                <XCircle weight="duotone" />
                            </ActionIcon>
                        }
                        variant={ButtonVariant.UNSTYLED}
                        className="px-2 w-fit !h-9 rounded-lg bg-white border border-gray-100 shadow-inner text-sm text-gray-600 min-w-[12rem] min-h-[2rem]"
                        form="some_random"
                    >
                        {selectedField?.defaultFieldRender ? (
                            <span className="!whitespace-nowrap w-max">
                                {selectedField?.defaultFieldRender}
                            </span>
                        ) : (
                            <FieldListItem
                                item={selectedField as FieldWithSuggestions}
                                checked={false}
                                disabled={false}
                                showCheckbox={false}
                                showHoverIcon={false}
                                className="py-0.5"
                            />
                        )}
                    </Button>
                }
                allowMultipleSelection={false}
            />
        ),
        [commonPropertySelectProps, setEditableState, selectedField, onChange],
    );
    const inputComponent = useMemo(() => {
        const commonInputStyles = {
            input: { paddingRight: '2rem' },
        };

        if (type === DimensionType.BOOLEAN) {
            return (
                <Select
                    value={value}
                    onChange={(newValue: string) => onChange(newValue)}
                    data={[
                        { value: 'true', label: 'True' },
                        { value: 'false', label: 'False' },
                    ]}
                    styles={commonInputStyles}
                    rightSection={propertySelectWithAtIcon}
                />
            );
        }
        if (type === DimensionType.STRING) {
            return (
                <TextInput
                    value={value}
                    onChange={(e) => handleInputChange(e.target.value)}
                    error={error}
                    styles={{
                        ...commonInputStyles,
                        input: {
                            ...commonInputStyles.input,
                            width: 'fit-content',
                            minWidth: '100%',
                        },
                    }}
                    rightSection={propertySelectWithAtIcon}
                    classNames={{
                        input: 'pl-5 pr-7',
                        wrapper: 'w-full',
                        root: 'rounded-md shadow-sm',
                    }}
                />
            );
        }
        if (type === DimensionType.NUMBER) {
            return (
                <TextInput
                    value={value}
                    onChange={(e) => {
                        const numericValue = e.target.value.replace(
                            /[^0-9.-]/g,
                            '',
                        );
                        handleInputChange(numericValue);
                    }}
                    type="number"
                    styles={commonInputStyles}
                    rightSection={propertySelectWithAtIcon}
                />
            );
        }

        return (
            <TextInput
                value={value}
                onChange={(e) => handleInputChange(e.target.value)}
                error={error}
                styles={commonInputStyles}
                rightSection={propertySelectWithAtIcon}
            />
        );
    }, [
        type,
        value,
        onChange,
        error,
        handleInputChange,
        propertySelectWithAtIcon,
    ]);
    const isEditable = useMemo(
        () =>
            editableState ||
            !value?.startsWith(JOURNEY_DYNAMIC_VARIABLE_PREFIX),
        [editableState, value],
    );

    return isEditable ? inputComponent : propertySelectWithFieldDisplay;
};

export default TraitValueInput;
