import Modal from '@components/common/modal/Modal';
import DataTable from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import { AudienceType, ShowDataType } from '@lightdash/common';
import { Box } from '@mantine/core';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { type Cell } from '@tanstack/react-table';
import { CSV_AUDIENCE_PREVIEW_LIMIT } from '@utils/constants';
import React, { useEffect, useState } from 'react';

type CsvAudiencePreviewModalProps = {
    open: boolean;
    onClose: () => void;
    fileName: string | undefined;
    count: number | undefined;
};

const CsvAudiencePreviewModal: React.FC<CsvAudiencePreviewModalProps> = ({
    open,
    onClose,
    fileName,
    count,
}) => {
    const { t } = useLocale();
    const { state } = useCampaignContext((context) => context);
    const { audienceCsvData, campaignPayload } = state;
    const [columns, setColumns] = useState<any[]>([]);

    useEffect(() => {
        if (audienceCsvData && audienceCsvData.length > 0) {
            const headers = Object.keys(audienceCsvData[0] as 'object');
            const data = headers.map((header) => ({
                accessorKey: header,
                header,
                cell: (props: Cell<string, string>) => {
                    return <Box>{props.getValue()}</Box>;
                },
            }));
            setColumns([...data]);
        }
    }, [audienceCsvData]);

    return (
        <Modal
            opened={open}
            onClose={onClose}
            size={'xl'}
            title={`Users in ${
                campaignPayload.audienceType === AudienceType.CSV &&
                (campaignPayload.csvUploadDetails?.fileName || fileName)
            }`}
        >
            {audienceCsvData && (
                <DataTable<unknown>
                    tableData={audienceCsvData}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: columns,
                        },
                    ]}
                    isManualPagination={false}
                    paginationText={t('audience_manager.pagination_text')}
                    withBorder={true}
                    customClass={`overflow-y-scroll`}
                    showSearch={false}
                    isEditable={false}
                    totalResultCount={
                        campaignPayload.audienceType === AudienceType.CSV
                            ? count ||
                              campaignPayload.csvUploadDetails?.audienceCount
                            : undefined
                    }
                    previewLimit={CSV_AUDIENCE_PREVIEW_LIMIT}
                />
            )}
        </Modal>
    );
};

export default CsvAudiencePreviewModal;
