/**
 * Sanitize the name to be used as a key for a trait field
 * @param name `string`
 * @returns `string`
 */
export const sanitizeName = (name: string): string => {
    return name
        .replace(/[^a-zA-Z0-9_]/g, '_') // Replace special chars with underscore
        .replace(/^[0-9]/, '_$&') // Add underscore if name starts with number
        .toLowerCase(); // Convert to lowercase
};
