import ChannelTabs from '@components/ChannelTabs';
import GenericOption from '@components/common/Card/GenericOption';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import {
    type AllIntegrationChannels,
    type CommunicationChannel,
    type Integration,
} from '@lightdash/common';
import { Box, Image, Tabs } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import { CHANNEL_SETUP_STATES } from '@providers/Channel/types';
import useChannelContext from '@providers/Channel/useChannelContext';
import React, { useMemo, useState } from 'react';
import { DEFAULT_PROVIDER } from '../ChannelTabs/types';
import NoResultsFound from '../NoResultsFound';
import ProviderConfig from '../ProviderConfig';

const AddChannel: React.FC = () => {
    const { t } = useLocale();
    const [searchText, setSearchText] = useState('');
    const [activeTab, setActiveTab] = useState(DEFAULT_PROVIDER);
    const { state, actions } = useChannelContext((context) => context);
    const { channelSetupState, allChannels } = state;
    const {
        handleProviderSelect,
        handleShowSetUpModal,
        updateChannelSetupStateAction,
    } = actions;

    const filteredIntegrationProviders = useMemo(() => {
        const currentChannelProviders = (
            allChannels as AllIntegrationChannels
        )?.[activeTab];
        const formattedSearchText = searchText?.toLowerCase();
        if (searchText) {
            return currentChannelProviders?.filter((item) =>
                item?.providerName
                    ?.toLowerCase()
                    ?.includes(formattedSearchText),
            );
        }
        return currentChannelProviders;
    }, [activeTab, searchText, allChannels]);

    const handleTabChange = (tab: CommunicationChannel) => {
        setActiveTab(tab);
    };

    const handleClick = (item: Integration) => {
        handleProviderSelect(item, false);
        updateChannelSetupStateAction(CHANNEL_SETUP_STATES.ADD_CHANNEL_DETAILS);
    };

    if (channelSetupState !== CHANNEL_SETUP_STATES.SELECT_PROVIDER) {
        return (
            <Box>
                <ProviderConfig
                    providerId={state.selectedProvider?.providerId}
                    title={t('channel_settings.set_up_channel_title', {
                        channel: state?.selectedProvider?.providerName,
                    })}
                    description={t(
                        'channel_settings.set_up_channel_description',
                        {
                            channel: state?.selectedProvider?.displayName,
                        },
                    )}
                    handleExit={() => {
                        handleShowSetUpModal(false);
                        setTimeout(
                            () =>
                                updateChannelSetupStateAction(
                                    CHANNEL_SETUP_STATES.SELECT_PROVIDER,
                                ),
                            200,
                        );
                    }}
                    isSetupDone={state?.allowChannelSetup}
                    integrationId={state?.selectedProvider?.integrationId}
                />
            </Box>
        );
    }

    return (
        <Tabs
            keepMounted={false}
            variant="pills"
            radius="lg"
            defaultValue={activeTab}
            onTabChange={handleTabChange}
        >
            <ChannelTabs activeTab={activeTab} />
            <Tabs.Panel value={activeTab}>
                <Box>
                    <SearchInput
                        placeholder={t(
                            'channel_settings.set_up_placeholder',
                        )?.replace('{{channel}}', activeTab)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSearchText(e.target.value)
                        }
                        className="mb-3"
                    />
                    {filteredIntegrationProviders?.length ? (
                        <Box className="overflow-y-scroll rounded-lg h-96 border-base">
                            {filteredIntegrationProviders?.map((item) => (
                                <GenericOption
                                    key={item.integrationId}
                                    title={item?.providerName}
                                    border={false}
                                    boxShadow={false}
                                    rightSection={
                                        <CaretRight weight="regular" />
                                    }
                                    customClass="!w-[100%]"
                                    onClick={() => handleClick(item)}
                                    iconComponent={
                                        <Image
                                            src={item.logoUrl}
                                            alt={item.integrationId}
                                            width={14}
                                            height={14}
                                        />
                                    }
                                />
                            ))}
                        </Box>
                    ) : (
                        <NoResultsFound searchValue={searchText} />
                    )}
                </Box>
            </Tabs.Panel>
        </Tabs>
    );
};

export default AddChannel;
