import Select from '@components/common/Select';
import { type PeriodType } from '@lightdash/common';
import { Group, Stack, TextInput } from '@mantine/core';
import { durationTypeOptions } from '@utils/constants';
import React, { useEffect, useState } from 'react';
import InputErrorText from '../InputErrorText';
import { getDurationValue, getMilliseconds } from './utils';

export interface TimeInputWithOptionsProps {
    /**
     * The selected duration in milliseconds.
     */
    selectedDuration: number | undefined;
    /**
     * Callback function to handle duration change.
     * @param {number} duration - The new duration in milliseconds.
     * @param {PeriodType} granularity - The granularity type.
     */
    onDurationChange: (duration: number, granularity: PeriodType) => void;
    /**
     * The selected granularity type.
     */
    selectedGranularity: PeriodType | undefined;
    /**
     * The available granularity options.
     */
    granularityOptions: PeriodType[];

    /**
     * The error message to display.
     */
    error?: string;

    /**
     * Whether to focus on the input on mount.
     */
    focusOnMount?: boolean;

    /**
     * Callback function to handle blur event.
     */
    onBlur?: (value: number) => void;

    /**
     * Whether the input is disabled.
     */
    disabled: boolean;
}

/**
 * TimeInputWithOptions component allows users to set a delay duration with a specific granularity.
 * It takes milliseconds as the prop parameter and returns milliseconds.
 *
 * @component
 * @param {TimeInputWithOptionsProps} props - The properties for the TimeInputWithOptions component.
 * @returns {JSX.Element} - The rendered TimeInputWithOptions component.
 */
const TimeInputWithOptions = React.forwardRef<
    HTMLInputElement,
    TimeInputWithOptionsProps
>(
    (
        {
            selectedDuration,
            onDurationChange,
            selectedGranularity,
            granularityOptions,
            error,
            onBlur,
            focusOnMount = false,
            disabled = false,
        },
        ref,
    ) => {
        const defaultType = selectedGranularity ?? granularityOptions[0];

        const [duration, setDuration] = useState<number | undefined | null>(
            selectedDuration,
        );
        const [selectedType, setSelectedType] =
            useState<PeriodType>(defaultType);

        useEffect(() => {
            if (selectedDuration !== undefined && selectedDuration !== null) {
                setDuration(getDurationValue(selectedDuration, selectedType));
                return;
            }
            setDuration(undefined);
        }, [selectedDuration, selectedType]);

        useEffect(() => {
            if (selectedGranularity !== undefined) {
                setSelectedType(selectedGranularity);
            }
        }, [selectedGranularity]);

        const handleDurationChange = (value: string) => {
            const numericValue = parseFloat(value);
            setDuration(numericValue);
            onDurationChange(
                getMilliseconds(numericValue, selectedType),
                selectedType,
            );
        };

        const handleTypeChange = (value: PeriodType) => {
            setSelectedType(value);
            if (duration !== undefined && duration !== null) {
                onDurationChange(getMilliseconds(duration, value), value);
            }
        };

        const getPlaceholder = () => {
            const option = durationTypeOptions.find(
                (o) => o.value === selectedType,
            );
            return option ? option.placeholder : '#';
        };

        const filteredOptions = durationTypeOptions.filter((option) =>
            granularityOptions.includes(option.value),
        );

        return (
            <Stack className="gap-0.5">
                <Group className="gap-1">
                    <TextInput
                        ref={ref}
                        placeholder={getPlaceholder()}
                        type="number"
                        value={duration ?? ''}
                        onChange={(e) => handleDurationChange(e.target.value)}
                        className="max-w-[8.5rem]"
                        onBlur={(e) => onBlur?.(parseFloat(e.target.value))}
                        autoFocus={focusOnMount}
                        disabled={disabled}
                    />
                    <Select
                        withinPortal
                        dropdownPosition="bottom"
                        data={filteredOptions}
                        value={selectedType}
                        onChange={(val: PeriodType) => handleTypeChange(val)}
                        className="max-w-[8.5rem]"
                        disabled={disabled}
                    />
                </Group>
                {error && <InputErrorText value={error} />}
            </Stack>
        );
    },
);

export default React.memo(TimeInputWithOptions);
