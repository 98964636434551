import { RelationTableType } from '@lightdash/common';
import { Tabs, Text } from '@mantine/core';
import useSchemaContext from '@providers/Schema/useSchemaContext';
import { t as translate } from 'i18next';
import React, { useEffect, useState } from 'react';
import { FontWeights } from '../../../mantineTheme';
import { SchemaConfigStep } from './types';

enum SchemaBuilderTabTypes {
    table = 'table',
    relations = 'relations',
    events = 'events',
    properties = 'properties',
}

const TABS: {
    key: SchemaBuilderTabTypes;
    label: string;
    configStep: SchemaConfigStep;
}[] = [
    {
        key: SchemaBuilderTabTypes.table,
        label: translate('schema_builder_tabs.table'),
        configStep: SchemaConfigStep.CONFIGURE,
    },
    {
        key: SchemaBuilderTabTypes.relations,
        label: translate('schema_builder_tabs.relations'),
        configStep: SchemaConfigStep.RELATIONSHIPS,
    },
    {
        key: SchemaBuilderTabTypes.events,
        label: translate('schema_builder_tabs.events'),
        configStep: SchemaConfigStep.EVENTS,
    },
    {
        key: SchemaBuilderTabTypes.properties,
        label: translate('schema_builder_tabs.properties'),
        configStep: SchemaConfigStep.PROPERTIES,
    },
];

const SchemaBuilderTabs: React.FC = () => {
    const [activeTab, setActiveTab] = useState<SchemaBuilderTabTypes>(
        SchemaBuilderTabTypes.table,
    );
    const { schemaPayload } = useSchemaContext((context) => context.state);
    const { setCurrentConfigStep } = useSchemaContext(
        (context) => context.actions,
    );

    let tabs = TABS;
    if (schemaPayload.type !== RelationTableType.EVENT)
        tabs = TABS.filter((tab) => tab.key !== 'events');

    const onTabChange = (value: string) => {
        const currentTab = tabs.find((tab) => tab.key === value);
        if (!currentTab) return;

        setCurrentConfigStep(currentTab.configStep);
        setActiveTab(currentTab.key);
    };

    useEffect(() => {
        if (schemaPayload.name) {
            setActiveTab(SchemaBuilderTabTypes.table);
        }
    }, [schemaPayload.name]);

    return (
        <Tabs
            className="px-4 py-2"
            variant="pills"
            radius="xxl"
            styles={{
                tab: {
                    color: 'rgb(var(--color-gray-600))',
                    height: 30,
                    '&[data-active]': {
                        color: 'rgb(var(--color-gray-800))',
                        backgroundColor: 'rgb(var(--color-gray-200))',
                        fontWeight: FontWeights.semibold,
                    },
                    '&[data-active]:hover': {
                        backgroundColor: 'rgb(var(--color-gray-200))',
                    },
                },
            }}
            value={activeTab}
            onTabChange={onTabChange}
        >
            <Tabs.List className="gap-2.5">
                {tabs.map((step, index) => (
                    <Tabs.Tab
                        className="px-2 py-1 text-gray-600 font-medium text-sm rounded-lg hover:rounded-lg hover:text-gray-800"
                        key={index}
                        value={step.key}
                    >
                        <Text>{step.label}</Text>
                    </Tabs.Tab>
                ))}
            </Tabs.List>
        </Tabs>
    );
};

export default React.memo(SchemaBuilderTabs);
