import { subject } from '@casl/ability';
import FieldListItem from '@components/Audience/Filters/FieldListItem';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import CustomMenu from '@components/common/MenuItem/MenuItem';
import { TableTypeOptions } from '@components/Table';
import { fuzzyFilter } from '@components/Table/utils';
import { useLocale } from '@hooks/useLocale';
import {
    ProjectSettings,
    ShowDataType,
    type TableConfig,
} from '@lightdash/common';
import { Badge, Box, Flex } from '@mantine/core';
import { Backspace, Keyhole } from '@phosphor-icons/react';
import useApp from '@providers/App/useApp';
import useRelationContext from '@providers/Relation/useRelationContext';
import {
    getCoreRowModel,
    useReactTable,
    type ColumnDef,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { useParams } from 'react-router';

export interface TableConfigWithIsPrimary extends TableConfig {
    isPrimary?: boolean;
}

interface ReachabilityVariablesTableProps {
    data: TableConfigWithIsPrimary[];
    handlePrimaryVariableChange: (newConfigIndex: number) => void;
    handleRemoveVariable: (newConfigIndex: number) => void;
    isLoading: boolean;
}

const ReachabilityVariablesTable: React.FC<ReachabilityVariablesTableProps> = ({
    data,
    handlePrimaryVariableChange,
    handleRemoveVariable,
    isLoading,
}) => {
    const { activeRelation } = useRelationContext();
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });
    const ability = useAbilityContext();
    const { user } = useApp();
    const canEditReachabilityVariables = ability.can(
        'edit',
        subject(ProjectSettings.reachabilityVariables, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const columns: ColumnDef<TableConfigWithIsPrimary>[] = useMemo(
        () => [
            {
                header: 'Field',
                accessorKey: 'dimensionName',
                cell: ({ row }) => {
                    return (
                        <Flex gap={4}>
                            <Box className="text-sm font-medium text-gray-800">
                                <FieldListItem
                                    item={
                                        fieldsWithSuggestions[
                                            `${row.original.tableName}_${row.original.dimensionName}`
                                        ]
                                    }
                                    checked={false}
                                    showCheckbox={false}
                                    disabled={false}
                                    showHoverIcon={false}
                                />
                            </Box>
                            {row.original?.isPrimary && (
                                <Badge
                                    className="bg-blu-800 text-sm text-white rounded py-2.5 normal-case"
                                    size="xs"
                                >
                                    Primary
                                </Badge>
                            )}
                        </Flex>
                    );
                },
            },
            {
                accessorKey: 'menu',
                header: '',
                cell: ({ row }) => {
                    return row.original?.isPrimary &&
                        isLoading &&
                        !canEditReachabilityVariables ? null : (
                        <CustomMenu<TableConfigWithIsPrimary>
                            menuItems={[
                                {
                                    leftSection: <Keyhole />,
                                    children: t(
                                        'reachability_variables.set_to_primary',
                                    ),
                                    onClick: () => {
                                        handlePrimaryVariableChange(row.index);
                                    },
                                },
                                {
                                    leftSection: <Backspace />,
                                    children: t(
                                        'reachability_variables.remove_variable',
                                    ),
                                    onClick: () => {
                                        handleRemoveVariable(row.index);
                                    },
                                },
                            ]}
                            data={row.original}
                        />
                    );
                },
            },
        ],
        [
            fieldsWithSuggestions,
            t,
            handlePrimaryVariableChange,
            handleRemoveVariable,
            isLoading,
            canEditReachabilityVariables,
        ],
    );
    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        getCoreRowModel: getCoreRowModel(),
    });
    return (
        <Box className="overflow-hidden border rounded-xl border-gray-50">
            <Box className="max-h-[38rem] overflow-auto">
                <TableTypeOptions viewType={ShowDataType.LIST} table={table} />
            </Box>
        </Box>
    );
};

export default ReachabilityVariablesTable;
