import SkeletonLoader from '@components/common/SkeletonLoader';
import { CustomComponentType } from '@components/Templates/HTMLManager/HTMLEditor/types';
import HTMLPreview from '@components/Templates/HTMLManager/HTMLPreview';
import SMSPreview from '@components/Templates/SMSManager/SMSPreview';
import TemplateOptionsModal from '@components/Templates/TemplateOptionsModal';
import WhatsappPreview from '@components/Templates/WhatsappManager/Preview';
import { useUpdateCampaign } from '@hooks/useCampaigns';
import {
    useGetIntegratedProviderConfig,
    useGetProviderMetada,
} from '@hooks/useChannels';
import {
    AudienceType,
    CommunicationChannel,
    TemplateType,
    unsubUrl,
    type EmailTemplateContentDetails,
    type ProviderTemplateDetails,
    type SmsTemplateContentDetails,
    type Template,
} from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

interface TemplateContentPreviewProps {
    templateMetadata: Template;
    grapesContainerId: string;
    showDeviceAndThemeSwitcher: boolean;
    showModifyButton?: boolean;
    onTemplateSelect: ((value: ProviderTemplateDetails) => void) | undefined;
    isSetupComplete: boolean | undefined;
    openContentSetupManager: () => void;
    showSetupStrip?: boolean;
    showHeader?: boolean;
}

const TemplateContentPreview: React.FC<TemplateContentPreviewProps> = ({
    templateMetadata,
    grapesContainerId,
    showDeviceAndThemeSwitcher,
    showModifyButton = true,
    onTemplateSelect,
    isSetupComplete = false,
    openContentSetupManager,
    showSetupStrip = false,
    showHeader = true,
}) => {
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload } = state;
    const {
        resetContentMappings,
        setGrapesPreviewInstance,
        setTemplateDetails,
    } = actions;
    const { communicationDetails, contentDetails } = campaignPayload;

    const {
        data: integrationConfig,
        isInitialLoading: isIntegrationConfigLoading,
    } = useGetIntegratedProviderConfig(
        communicationDetails?.providerId ?? '',
        communicationDetails?.id ?? '',
    );

    const { mutate: updateCampaign } = useUpdateCampaign(campaignUuid);

    const [opened, { toggle, close }] = useDisclosure(false);

    const { data: providerMetadata, isInitialLoading } = useGetProviderMetada(
        communicationDetails?.providerId ?? '',
        campaignPayload?.channel ?? '',
    );

    const handleTemplateSelect = useCallback(
        (value: ProviderTemplateDetails) => {
            if (!value) return;
            if (campaignUuid) {
                updateCampaign({
                    templateDetails: {
                        id: value.id ?? value.name,
                        type:
                            campaignPayload?.channel ===
                            CommunicationChannel.WHATSAPP
                                ? TemplateType.EXTERNAL
                                : TemplateType.INTERNAL,
                    },
                    contentMappings: {},
                });
            }
            setTemplateDetails({
                id: value.id ?? value.name,
                type:
                    campaignPayload?.channel === CommunicationChannel.WHATSAPP
                        ? TemplateType.EXTERNAL
                        : TemplateType.INTERNAL,
            });
            resetContentMappings();
            if (onTemplateSelect) {
                onTemplateSelect(value);
            }
        },
        [
            campaignUuid,
            setTemplateDetails,
            campaignPayload?.channel,
            resetContentMappings,
            onTemplateSelect,
            updateCampaign,
        ],
    );

    const templateContentPreview = useMemo(() => {
        if (!templateMetadata) return null;

        switch (templateMetadata.channel) {
            case CommunicationChannel.EMAIL:
                const emailContent = templateMetadata.contents[0]
                    ?.content as EmailTemplateContentDetails;
                const templateDetail = {
                    id: templateMetadata.id,
                    name: templateMetadata.name,
                    html: emailContent?.html,
                    unsubscribeBlock:
                        campaignPayload.audienceType === AudienceType.WAREHOUSE
                            ? emailContent?.html?.includes(unsubUrl) ||
                              emailContent?.html?.includes(
                                  `data-srt-id="${CustomComponentType.UNSUBSCRIBE_BLOCK}"`,
                              )
                            : true,
                };

                if (!providerMetadata || !integrationConfig) return null;

                return (
                    <HTMLPreview
                        templateDetail={templateDetail}
                        subject={
                            (contentDetails as EmailTemplateContentDetails)
                                ?.subject ?? ''
                        }
                        senderName={
                            campaignPayload.senderDetails?.name ??
                            (integrationConfig?.fromName || '')
                        }
                        senderMail={
                            campaignPayload.senderDetails?.fromEmail ??
                            (integrationConfig?.fromEmail || '')
                        }
                        handleModifyTemplate={toggle}
                        setGrapesPreviewInstance={setGrapesPreviewInstance}
                        grapesContainerId={grapesContainerId}
                        showDeviceAndThemeSwitcher={showDeviceAndThemeSwitcher}
                        showModifyButton={showModifyButton}
                        isSetupComplete={isSetupComplete}
                        openContentSetupManager={openContentSetupManager}
                        showSetupStrip={showSetupStrip}
                        showHeader={showHeader}
                    />
                );
            case CommunicationChannel.SMS:
                const smsAppContent = templateMetadata.contents[0]
                    .content as SmsTemplateContentDetails;
                return (
                    <SMSPreview
                        content={smsAppContent.body}
                        isFlash={smsAppContent.flash || false}
                        allowModify={showModifyButton}
                        onModify={toggle}
                        templateName={templateMetadata.name}
                        isSetupComplete={isSetupComplete}
                        openContentSetupManager={openContentSetupManager}
                        showSetupStrip={showSetupStrip}
                        showHeader={showHeader}
                    />
                );
            case CommunicationChannel.WHATSAPP:
                const whatsappContent = (templateMetadata as any).contents;
                return (
                    <WhatsappPreview
                        content={whatsappContent}
                        name={templateMetadata.name}
                        onModify={toggle}
                        showModifyButton={showModifyButton}
                        isSetupComplete={isSetupComplete}
                        openContentSetupManager={openContentSetupManager}
                        showSetupStrip={showSetupStrip}
                        showHeader={showHeader}
                    />
                );
            default:
                return null;
        }
    }, [
        templateMetadata,
        campaignPayload.audienceType,
        providerMetadata,
        integrationConfig,
        contentDetails,
        toggle,
        setGrapesPreviewInstance,
        grapesContainerId,
        showDeviceAndThemeSwitcher,
        showModifyButton,
        isSetupComplete,
        openContentSetupManager,
        showSetupStrip,
        showHeader,
        campaignPayload.senderDetails,
    ]);

    if (isIntegrationConfigLoading || isInitialLoading)
        return <SkeletonLoader height={480} width={300} />;

    return (
        <>
            {templateContentPreview}

            {providerMetadata && (
                <TemplateOptionsModal
                    opened={opened}
                    onClose={close}
                    providerMetadata={providerMetadata}
                    integrationId={communicationDetails?.id ?? ''}
                    handleTemplateSelect={handleTemplateSelect}
                    channel={templateMetadata.channel}
                    showCreateEditTemplate={true}
                    templateId={templateMetadata?.id ?? undefined}
                    isTemplateLibrary={false}
                    editMode={false}
                />
            )}
        </>
    );
};

export default React.memo(TemplateContentPreview);
