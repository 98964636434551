import ListSelect from '@components/common/Select/ListSelect';
import SkeletonLoader from '@components/common/SkeletonLoader';
import Timestamp from '@components/common/Timestamp';
import { useLocale } from '@hooks/useLocale';
import { AudienceType, type Audience } from '@lightdash/common';
import { Box, Button, Group, Loader, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    ArrowsClockwise,
    ClockCountdown,
    FileArrowUp,
    MagnifyingGlass,
    UsersThree,
} from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import React, { useEffect, useMemo } from 'react';
import { type ListSelectOptionsProp } from 'types/ListSelect';
import { ButtonVariant } from '../../../../../mantineTheme';
import AudienceUploadModal from './AudienceUploadModal';

interface ItemComponentProp {
    name: string;
    lastRun: string | undefined;
    icon: React.ReactNode;
}

export const ItemComponent = ({ name, lastRun, icon }: ItemComponentProp) => {
    return (
        <Group className="items-center gap-1.5">
            {icon ?? <UsersThree color={'rgb(var(--color-pink-800)'} />}
            <Text className="text-sm font-medium text-gray-800">{name}</Text>
            {lastRun && (
                <Group className="flex items-center gap-1">
                    <ClockCountdown />
                    <Text className="text-sm text-gray-500">
                        <Timestamp timestamp={lastRun} />
                    </Text>
                </Group>
            )}
        </Group>
    );
};

const createdListItemObject = (obj: Audience) => {
    const lastRun = obj?.lastRunAt && obj?.lastRunAt.toString();
    return {
        uuid: obj?.id,
        key: obj?.name,
        value: obj?.name,
        renderComponent: (
            <Box className="px-1">
                <ItemComponent
                    icon={<UsersThree color={'rgb(var(--color-pink-800)'} />}
                    name={obj?.name}
                    lastRun={lastRun}
                />
            </Box>
        ),
    };
};

type SelectCampaignAudienceProps = {
    audiences: Audience[] | null | undefined;
    isLoading: boolean;
    isFetching: boolean;
    onRefresh: () => void;
};

const SelectCampaignAudience: React.FC<SelectCampaignAudienceProps> = ({
    audiences,
    isLoading,
    isFetching,
    onRefresh,
}) => {
    const { t } = useLocale();
    const { setCampaignAudience, setShowFooterButtons, setCampaignType } =
        useCampaignContext((context) => context.actions);
    const [
        open,
        { open: openAudienceUploadModal, close: closeAudienceUploadModal },
    ] = useDisclosure(false);

    useEffect(() => {
        setShowFooterButtons({
            next: false,
            back: true,
        });
    }, [setShowFooterButtons]);

    const options = useMemo(() => {
        return audiences?.map((obj: Audience) => {
            return createdListItemObject(obj);
        });
    }, [audiences]);

    const handleFieldSelect = (selectedOption: ListSelectOptionsProp) => {
        const selectedAudience = audiences?.find(
            (obj) => obj?.id === selectedOption.uuid,
        );
        if (selectedAudience) {
            setCampaignType(AudienceType.WAREHOUSE);
            setCampaignAudience(selectedAudience);
        }
    };

    const handleUploadClick = () => {
        setCampaignType(AudienceType.CSV);
        openAudienceUploadModal();
    };

    if (isLoading) {
        return <SkeletonLoader height={100} />;
    }

    return (
        <>
            <Box>
                <Stack className="gap-2">
                    <Group position="apart">
                        <Text className="text-sm font-medium text-gray-800">
                            {t('campaigns_builder_audience.list_select_label')}
                        </Text>
                        <Button
                            onClick={handleUploadClick}
                            leftIcon={
                                <FileArrowUp color="rgba(var(--color-blu-800))" />
                            }
                            variant={ButtonVariant.SUBTLE_ACCENTED}
                        >
                            Upload static list
                        </Button>
                    </Group>
                    <Box className="h-96">
                        <ListSelect
                            leftSection={<MagnifyingGlass />}
                            placeholder={t(
                                'campaigns_builder_audience.search_placeholder',
                            )}
                            options={options || []}
                            onOptionSelect={handleFieldSelect}
                            rightSection={
                                isFetching ? (
                                    <>
                                        <Loader
                                            size={13}
                                            color={'rgb(var(--color-gray-500))'}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <ArrowsClockwise
                                            weight="regular"
                                            onClick={onRefresh}
                                            className={`${
                                                isFetching
                                                    ? 'pointer-events-none'
                                                    : 'cursor-pointer'
                                            } `}
                                        />
                                    </>
                                )
                            }
                            withDivider
                        />
                    </Box>
                </Stack>
            </Box>
            <AudienceUploadModal
                open={open}
                onClose={closeAudienceUploadModal}
            />
        </>
    );
};

export default React.memo(SelectCampaignAudience);
