import {
    CommunicationChannel,
    CommunicationChannel as CommunicationChannelEnum,
} from '@lightdash/common';

export const getChannel = (channel: string) => {
    if (channel === 'email') {
        return CommunicationChannel.EMAIL;
    }
    if (channel === 'sms') {
        return CommunicationChannel.SMS;
    }
    if (channel === 'whatsapp') {
        return CommunicationChannel.WHATSAPP;
    }
    return CommunicationChannel.ANY;
};

export const getStyles = () => ({
    content: {
        maxWidth: '48vw !important',
    },
    header: {
        display: 'flex',
        alignItems: 'start',
    },
    body: {
        padding: '0px',
    },
});

export const DEFAULT_PROVIDER: CommunicationChannel =
    CommunicationChannelEnum.EMAIL;
