import { CommunicationChannel } from '../types/communications';
import {
    ActionType,
    JourneyGroup,
    TimeConfigType,
    type JourneyBlocksMetadata,
} from '../types/journeys';

export const journeyBlocks: JourneyBlocksMetadata[] = [
    {
        id: '1',
        title: 'Delay',
        description: undefined,
        group: JourneyGroup.DELAY,
        actions: [
            {
                actionType: ActionType.WAIT,
                config: {
                    type: TimeConfigType.DURATION,
                    allowEveryoneElse: false,
                },
            },
        ],
    },
    {
        id: '2',
        title: 'Wait for action',
        description: undefined,
        group: JourneyGroup.DELAY,
        actions: [
            {
                actionType: ActionType.WAIT_UNTIL,
                config: {
                    allowEveryoneElse: true,
                },
            },
        ],
    },
    {
        id: '3',
        title: 'Filter',
        description: undefined,
        group: JourneyGroup.FLOW_CONTROL,
        actions: [
            {
                actionType: ActionType.FILTER,
                config: {
                    allowEveryoneElse: true,
                },
            },
        ],
    },
    {
        id: '4',
        title: 'Split',
        description: undefined,
        group: JourneyGroup.FLOW_CONTROL,
        actions: [
            {
                actionType: ActionType.SPLIT,
                config: {
                    allowEveryoneElse: true,
                },
            },
        ],
    },
    // {
    //     id: '5',
    //     title: 'Experiment',
    //     description: undefined,
    //     group: JourneyGroup.FLOW_CONTROL,
    //     actions: [
    //         {
    //             actionType: ActionType.EXPERIMENT,
    //             config: {
    //                 allowEveryoneElse: false,
    //             },
    //         },
    //     ],
    // },
    {
        id: '6',
        title: 'Send Email',
        description: undefined,
        group: JourneyGroup.ACTION,
        actions: [
            {
                actionType: ActionType.SEND_MESSAGE,
                config: {
                    channelType: CommunicationChannel.EMAIL,
                    allowEveryoneElse: false,
                },
            },
        ],
    },
    {
        id: '7',
        title: 'Send SMS',
        description: undefined,
        group: JourneyGroup.ACTION,
        actions: [
            {
                actionType: ActionType.SEND_MESSAGE,
                config: {
                    channelType: CommunicationChannel.SMS,
                    allowEveryoneElse: false,
                },
            },
        ],
    },
    {
        id: '8',
        title: 'Send Whatsapp',
        description: undefined,
        group: JourneyGroup.ACTION,
        actions: [
            {
                actionType: ActionType.SEND_MESSAGE,
                config: {
                    channelType: CommunicationChannel.WHATSAPP,
                    allowEveryoneElse: false,
                },
            },
        ],
    },
    {
        id: '9',
        title: 'Call API',
        description: undefined,
        group: JourneyGroup.ACTION,
        actions: [
            {
                actionType: ActionType.API,
                config: {
                    allowEveryoneElse: false,
                },
            },
        ],
    },
    {
        id: '10',
        title: 'Wait till date',
        description: undefined,
        group: JourneyGroup.DELAY,
        actions: [
            {
                actionType: ActionType.WAIT,
                config: {
                    type: TimeConfigType.DATE_TIME,
                    allowEveryoneElse: false,
                },
            },
        ],
    },
    {
        id: '11',
        title: 'Update trait',
        description: undefined,
        group: JourneyGroup.ACTION,
        actions: [
            {
                actionType: ActionType.UPDATE_TRAIT,
            },
        ],
    },
];
