import { subject } from '@casl/ability';
import { getCampaignAnalyticsDashboardUrl } from '@components/Campaigns/campaignUtils';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Modal from '@components/common/modal/Modal';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import useNotify from '@hooks/toaster/useNotify';
import {
    useDeleteCampaign,
    useDuplicateCampaign,
    useTriggerCampaign,
    useUnpublishCampaign,
    useUpdateCampaignTags,
} from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceType,
    CampaignStatus,
    CampaignType,
    CommonReservedTags,
    CommunicationChannel,
    type ReducedCampaign,
} from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    Archive,
    ArrowCounterClockwise,
    CaretRight,
    CopySimple,
    PaperPlaneTilt,
    Pencil,
    Trash,
    TrendUp,
} from '@phosphor-icons/react';
import useApp from '@providers/App/useApp';
import useProjectContext from '@providers/Project/useProjectContext';
import { useQueryClient } from '@tanstack/react-query';
import { t as translate } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';
import { CampaignBuilderQueryParams } from '../Builder/types';
import { allowCampaignEdits } from '../utils';

interface CampaignMenuItemProps {
    rowData: ReducedCampaign;
}

const CampaignMenuItem: React.FC<CampaignMenuItemProps> = ({ rowData }) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { mutateAsync: mutateDeleteCampaign } = useDeleteCampaign();
    const { mutateAsync: mutateTriggerCampaign } = useTriggerCampaign();
    const { mutate: duplicateCampaign } = useDuplicateCampaign({
        id: rowData.id ?? '',
        name: rowData.name ?? '',
        channel: rowData.channel ?? CommunicationChannel.EMAIL,
        description: rowData.description ?? '',
    });
    const {
        mutateAsync: updateCampaignTags,
        isLoading: updateCampaignTagsLoading,
    } = useUpdateCampaignTags({ campaignId: rowData.id ?? '' });
    const queryClient = useQueryClient();
    const { projectData } = useProjectContext();
    const navigate = useNavigate();
    const { t } = useLocale();
    const { user } = useApp();
    const [opened, { open, close }] = useDisclosure(false);
    const ability = useAbilityContext();
    const { showToastSuccess, showToastError } = useNotify();
    const [
        openedHideConfirmModal,
        { open: openHideConfirmModal, close: closeHideConfirmModal },
    ] = useDisclosure();
    const { mutateAsync: unpublishCampaign } = useUnpublishCampaign();
    const canEditCampaigns = ability.can(
        'manage',
        subject('Campaign', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const handleDuplicate = useCallback(() => {
        duplicateCampaign();
    }, [duplicateCampaign]);

    const handleDelete = useCallback(
        async (id: string) => {
            await mutateDeleteCampaign({ campaignId: id });
            await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
            close();
        },
        [mutateDeleteCampaign, queryClient, close],
    );

    const handleTrigger = useCallback(
        async (id: string) => {
            await mutateTriggerCampaign({ campaignId: id });
            await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
        },
        [mutateTriggerCampaign, queryClient],
    );

    const handleViewOrEdit = useCallback(
        (id: string, type: string) => {
            void navigate(
                `/projects/${projectUuid}/campaigns/${id}/${type}?${CampaignBuilderQueryParams.CHANNEL}=${rowData.channel}`,
            );
        },
        [projectUuid, navigate, rowData.channel],
    );

    const handleMoveToDraft = useCallback(() => {
        if (rowData?.id) {
            unpublishCampaign({
                campaignId: rowData.id,
            })
                .then(() => {
                    void queryClient.invalidateQueries([
                        QueryKeys.GET_CAMPAIGNS,
                    ]);
                })
                .catch(() => {});
        }
    }, [queryClient, rowData.id, unpublishCampaign]);

    const isCampaignEditable = useMemo(
        () =>
            allowCampaignEdits({
                executionTime: rowData?.schedule?.executionTime,
                status: rowData?.status,
            }),
        [rowData],
    );
    const hideItem = useMemo(
        () => ({
            leftSection: (
                <Archive
                    weight="duotone"
                    color={
                        rowData?.status !== CampaignStatus.IN_PROGRESS
                            ? 'rgb(var(--color-halt-800))'
                            : 'rgb(var(--color-gray-600))'
                    }
                />
            ),
            children: (
                <Text
                    className={`${
                        rowData?.status !== CampaignStatus.IN_PROGRESS
                            ? 'text-halt-800'
                            : ''
                    }`}
                >
                    {t('common.hide')}
                </Text>
            ),
            customClass: ` ${
                rowData?.status === CampaignStatus.IN_PROGRESS
                    ? 'opacity-50'
                    : 'hover:bg-halt-800/6'
            }`,
            disabled: rowData?.status === CampaignStatus.IN_PROGRESS,
            onClick: () => openHideConfirmModal(),
        }),
        [t, openHideConfirmModal, rowData?.status],
    );
    const restoreItem = useMemo(
        () => ({
            leftSection: <ArrowCounterClockwise weight="duotone" />,
            children: t('common.restore'),
            onClick: async () => {
                await updateCampaignTags(
                    {
                        tags: [
                            ...(rowData?.tags ?? []).filter(
                                (tag) => tag !== CommonReservedTags.HIDDEN,
                            ),
                        ],
                    },
                    {
                        onSuccess: async () => {
                            showToastSuccess({
                                title: t('common.hidden_restore_success', {
                                    entityName: t('common.campaign'),
                                }),
                            });
                            await queryClient.invalidateQueries([
                                QueryKeys.GET_CAMPAIGNS,
                            ]);
                        },
                        onError: (error) => {
                            showToastError({
                                title: t('common.hidden_restore_error', {
                                    entityName: t('common.campaign'),
                                }),
                                subtitle: error.error.message,
                            });
                        },
                    },
                );
            },
        }),
        [
            t,
            updateCampaignTags,
            rowData?.tags,
            showToastError,
            showToastSuccess,
            queryClient,
        ],
    );

    const menuItems = useMemo(() => {
        let items: CustomMenuItemProps<ReducedCampaign>[] = [];
        if (rowData?.status === CampaignStatus.READY) {
            items = [
                {
                    leftSection: (
                        <PaperPlaneTilt
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-gray-600))'}
                            strokeWidth={2.5}
                        />
                    ),
                    children: translate('campaign_manager.menu_item_send'),
                    onClick: (values) => handleTrigger(values?.id ?? ''),
                    isDivider: true,
                },
                {
                    leftSection: (
                        <Pencil
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-gray-600))'}
                            strokeWidth={2.5}
                        />
                    ),
                    children: t('campaign_preview.edit'),
                    onClick: () => handleMoveToDraft(),
                },
            ];
        } else if (rowData.status === CampaignStatus.DRAFT) {
            items = [
                ...items,
                {
                    leftSection: (
                        <Pencil
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-gray-600))'}
                            strokeWidth={2.5}
                        />
                    ),
                    children: translate('campaign_manager.menu_item_edit'),
                    onClick: (values) =>
                        handleViewOrEdit(values?.id ?? '', 'edit'),
                },
            ];
        } else if (
            rowData?.status === CampaignStatus.COMPLETED ||
            (rowData?.status === CampaignStatus.SCHEDULED &&
                rowData?.type === CampaignType.RECURRING &&
                rowData?.schedule?.nextExecution)
        ) {
            items = [
                {
                    leftSection: (
                        <TrendUp
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-gray-600))'}
                            strokeWidth={2.5}
                        />
                    ),
                    children: translate(
                        'campaign_manager.menu_item_view_report',
                    ),
                    onClick: (_values) => {
                        const dashboardUrl = getCampaignAnalyticsDashboardUrl(
                            projectData,
                            rowData.id,
                        );
                        void navigate(dashboardUrl);
                    },
                    isDivider: true,
                },
            ];
        } else if (rowData?.status === CampaignStatus.SCHEDULED) {
            if (isCampaignEditable) {
                items = [
                    {
                        leftSection: (
                            <Pencil
                                size={14}
                                color={'rgb(var(--color-gray-600))'}
                                strokeWidth={2.5}
                                weight="duotone"
                            />
                        ),
                        children: translate('campaign_manager.menu_item_edit'),
                        onClick: (values) =>
                            handleViewOrEdit(values?.id ?? '', 'edit'),
                    },
                ];
            }
            if (
                rowData.type === CampaignType.ONE_TIME ||
                (rowData.type === CampaignType.RECURRING &&
                    !rowData.schedule?.children)
            ) {
                items = [
                    ...items,
                    {
                        leftSection: (
                            <Pencil
                                size={14}
                                weight="duotone"
                                color={'rgb(var(--color-gray-600))'}
                                strokeWidth={2.5}
                            />
                        ),
                        children: t('campaign_preview.edit'),
                        onClick: () => handleMoveToDraft(),
                    },
                ];
            }
        }
        if (rowData.audienceType === AudienceType.WAREHOUSE) {
            items.push({
                leftSection: (
                    <CopySimple
                        size={14}
                        weight="duotone"
                        color={'rgb(var(--color-gray-600))'}
                        strokeWidth={2.5}
                    />
                ),
                children: translate('campaign_manager.menu_item_duplicate'),
                onClick: () => handleDuplicate(),
            });
        }
        items = [
            ...items,
            {
                leftSection: (
                    <Trash
                        size={14}
                        weight="duotone"
                        color={'rgb(var(--color-gray-600))'}
                        strokeWidth={2.5}
                    />
                ),
                children: translate('campaign_manager.menu_item_delete'),
                onClick: () => open(),
            },
        ];
        if (rowData?.tags?.includes(CommonReservedTags.HIDDEN)) {
            items.push(restoreItem);
        } else {
            items.push(hideItem);
        }

        return items;
    }, [
        handleDuplicate,
        handleViewOrEdit,
        handleTrigger,
        navigate,
        open,
        projectData,
        rowData,
        isCampaignEditable,
        hideItem,
        restoreItem,
        handleMoveToDraft,
        t,
    ]);

    // Update the callback to use the stored initialCampaignData
    const handleHideConfirm = useCallback(async () => {
        await updateCampaignTags(
            {
                tags: [...(rowData?.tags ?? []), CommonReservedTags.HIDDEN],
            },
            {
                onSuccess: async () => {
                    showToastSuccess({
                        title: t('common.hidden_success', {
                            entityName: t('common.campaign'),
                        }),
                    });
                    await queryClient.invalidateQueries([
                        QueryKeys.GET_CAMPAIGNS,
                    ]);
                },
                onError: (error) => {
                    showToastError({
                        title: t('common.hidden_error', {
                            entityName: t('common.campaign'),
                        }),
                        subtitle: error.error.message,
                    });
                },
            },
        );
        closeHideConfirmModal();
    }, [
        closeHideConfirmModal,
        rowData?.tags,
        updateCampaignTags,
        showToastError,
        showToastSuccess,
        t,
        queryClient,
    ]);

    return (
        <>
            {canEditCampaigns && (
                <CustomMenu<ReducedCampaign>
                    menuItems={menuItems}
                    data={rowData}
                />
            )}
            <Modal
                opened={opened}
                onClose={close}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                title={
                    <Flex direction={'column'} align={'flex-start'}>
                        <Text>{t('campaign.delete_modal_title')}</Text>
                        <Text className="text-sm font-normal text-gray-600">
                            {rowData?.name}
                        </Text>
                    </Flex>
                }
                centered
                footerRightSection={
                    <Flex justify={'flex-end'}>
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            className="py-2 mr-2"
                            onClick={() => close()}
                        >
                            {t('campaign.delete.modal.dont_delete')}
                        </Button>
                        <Button
                            variant={ButtonVariant.FILLED_DESTRUCTIVE}
                            style={{
                                backgroundColor:
                                    'rgb(var(--color-halt-800)) !important',
                            }}
                            onClick={async (
                                e: React.MouseEvent<HTMLButtonElement>,
                            ) => {
                                e.stopPropagation();
                                if (rowData?.id)
                                    await handleDelete(rowData?.id);
                            }}
                        >
                            {t('campaign.delete.modal.confirm_button')}{' '}
                            <Trash
                                className="ml-1"
                                size={14}
                                weight="duotone"
                                color={'rgb(var(--color-white))'}
                            />
                        </Button>
                    </Flex>
                }
            >
                <Text className="text-sm">
                    {t('campaign.delete.modal.description')}
                </Text>
            </Modal>
            <Modal
                opened={openedHideConfirmModal}
                onClose={closeHideConfirmModal}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                title={
                    <Box>
                        <Text>{t('campaign_manager.hide_modal_title')}</Text>
                        <Text className="text-sm font-normal text-gray-600">
                            {rowData.name}
                        </Text>
                    </Box>
                }
                footerRightSection={
                    <ButtonGroup
                        primaryButtonLabel={t(
                            'audience_manager.confirm_hide_prim_button',
                        )}
                        secondaryButtonLabel={t('common.no')}
                        isUpdating={updateCampaignTagsLoading}
                        handlePrimaryButtonClick={handleHideConfirm}
                        handleSecondaryButtonClick={closeHideConfirmModal}
                        primaryButtonVariant={ButtonVariant.FILLED_DESTRUCTIVE}
                        primaryButtonRightIcon={<CaretRight color="white" />}
                    />
                }
                closeButtonProps={{
                    className: 'absolute top-3 right-3',
                }}
            >
                <Flex direction={'column'} gap={12}>
                    <Text className="text-sm font-medium text-gray-800">
                        {t('campaign_manager.hide_modal_header')}
                    </Text>
                </Flex>
            </Modal>
        </>
    );
};

export default CampaignMenuItem;
