import { useFieldsWithEvents } from '@components/Audience/Filters/FiltersCard/useFieldsWithEvents';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import { convertAudienceToFilterableDimension } from '@components/Audience/Filters/utils';
import { useAudiences } from '@hooks/useGetAudience';
import { useGetJourneyNodeParams } from '@hooks/useJourney';
import {
    AudienceRunStatus,
    AudienceStatus,
    JourneyParamsNames,
    JourneyTableType,
    RelationTableType,
    SupportedDbtAdapter,
    type CompiledRelation,
    type JourneyDataSchema,
    type JourneyTable,
} from '@lightdash/common';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    type FC,
} from 'react';
import { useParams } from 'react-router';
import JourneyFiltersProviderContext from './context';

type Props = {
    nodeId: string;
};

export const JourneyFiltersProvider: FC<React.PropsWithChildren<Props>> = ({
    nodeId,
    children,
}) => {
    const [relationData, setRelationData] = useState<
        CompiledRelation | undefined
    >();
    const [journeyEventTables, setJourneyEventTables] = useState<
        Record<string, JourneyTable> | undefined
    >();
    const { journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );
    const { mutateAsync, isLoading: isJourneyLoading } =
        useGetJourneyNodeParams(nodeId);

    const {
        data: fetchedAudience,
        isLoading,
        isFetching,
        isInitialLoading,
    } = useAudiences({
        query: `status=${AudienceStatus.ACTIVE}&lastRunStatus=${AudienceRunStatus.SUCCESS}`,
        perPage: 9999,
        currentPage: 1,
        polling: false,
    });

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: relationData,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });
    const { activeRelationUuid, activeRelation } = useRelationContext();

    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    const { data: eventsData, eventsTableNames } = useFieldsWithEvents({
        relationData: relationData,
        activeRelationUuid,
        projectUuid,
    });
    const [journeyDataSchema, setJourneyDataSchema] =
        useState<JourneyDataSchema>();
    const journeyPayloadRef = useRef(journeyPayload);

    useEffect(() => {
        journeyPayloadRef.current = journeyPayload;
    }, [journeyPayload]);

    const getJourneyNodeParams = useCallback(async () => {
        const { config } = journeyPayloadRef.current;
        if (!config || !activeRelation) return;
        const result = await mutateAsync(journeyPayloadRef.current);
        setRelationData({
            tables: result.relationTables,
            joinedTables: [],
            name: '',
            label: '',
            tags: [],
            baseTable: activeRelation.baseTable,
            targetDatabase: SupportedDbtAdapter.SNOWFLAKE,
        });

        const eventTables: Record<string, JourneyTable> = {};
        for (const [key, table] of Object.entries(result.tables)) {
            if (table.type === JourneyTableType.EVENT) {
                eventTables[key] = table;
            }
        }
        setJourneyEventTables(eventTables);
        setJourneyDataSchema(result);
    }, [activeRelation, mutateAsync]);

    const cachedFields = useMemo(() => {
        if (!relationData) return {};
        return (
            relationData.tables[JourneyParamsNames.CACHED_PROPERTY]
                ?.dimensions ?? {}
        );
    }, [relationData]);

    const journeyEventFields = useMemo(() => {
        if (!journeyEventTables) return {};

        const mappedFields = Object.entries(
            journeyEventTables,
        ).reduce<FieldsWithSuggestions>((acc, [tableId, table]) => {
            const dimensions = table.dimensions;
            const fields = Object.keys(dimensions).reduce((fieldAcc, key) => {
                const dimension = dimensions[key];
                const fieldId = `${tableId}_${key}`;
                return {
                    ...fieldAcc,
                    [fieldId]: {
                        ...dimension,
                        suggestions: [],
                        tableType: table.type,
                        table: tableId,
                        tableLabel: table.label,
                    },
                };
            }, {});
            return {
                ...acc,
                ...fields,
            };
        }, {});
        return mappedFields;
    }, [journeyEventTables]);

    const allFields = useMemo(
        () =>
            Object.values({ ...fieldsWithSuggestions, ...journeyEventFields }),
        [fieldsWithSuggestions, journeyEventFields],
    );

    const warehouseFields = useMemo(() => {
        if (!fieldsWithSuggestions) return [];

        //Info: Filter out event properties from warehouse fields since we are showing event names in the event selector
        return Object.values(fieldsWithSuggestions).filter(
            (field) =>
                field.tableType && field.tableType !== RelationTableType.EVENT,
        );
    }, [fieldsWithSuggestions]);

    const audienceFields = useMemo(() => {
        if (!fetchedAudience || !fetchedAudience.data) return [];
        return convertAudienceToFilterableDimension(fetchedAudience.data);
    }, [fetchedAudience]);

    useEffect(() => {
        void getJourneyNodeParams();
    }, [getJourneyNodeParams]);

    return (
        <JourneyFiltersProviderContext.Provider
            value={{
                journeyDataSchema,
                getJourneyNodeParams,
                warehouseFields: warehouseFields,
                warehouseFieldsMap: fieldsWithSuggestions,
                allFields: allFields,
                cachedFields: Object.values(cachedFields),
                journeyEventFields: Object.values(journeyEventFields),
                eventsData: Object.values(eventsData),
                eventsTableNames: eventsTableNames,
                isLoading:
                    isJourneyLoading ||
                    isLoading ||
                    isFetching ||
                    isInitialLoading,
                audienceFields: Object.values(audienceFields),
                nodeId: nodeId,
                audienceData: fetchedAudience?.data ?? [],
                warehouseRelation: relationData,
            }}
        >
            {children}
        </JourneyFiltersProviderContext.Provider>
    );
};
