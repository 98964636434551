import AuthorizationError from '@components/AuthorizationError';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import Page from '@components/Page/Page';
import { useLocale } from '@hooks/useLocale';
import useUser from '@hooks/user/useUser';
import { Stack, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { useEffect, type FC } from 'react';
import { useNavigate } from 'react-router';
import PageSpinner from '../components/PageSpinner';
import { useOrganizationCreateMutation } from '../hooks/organization/useOrganizationCreateMutation';
import useAllowedOrganizations from '../hooks/user/useAllowedOrganizations';
import useApp from '../providers/App/useApp';

const JoinOrganizationPage: FC = () => {
    const { health } = useApp();
    const user = useUser(!!health?.data?.isAuthenticated);
    const { t } = useLocale();
    const navigate = useNavigate();
    const { isInitialLoading: isLoadingAllowedOrgs, data: allowedOrgs } =
        useAllowedOrganizations();
    const {
        mutate: createOrg,
        isLoading: isCreatingOrg,
        isSuccess: hasCreatedOrg,
        error: createOrgError,
    } = useOrganizationCreateMutation();

    useEffect(() => {
        const isAllowedToJoinOrgs = allowedOrgs && allowedOrgs.length > 0;
        const userHasOrg = user.data && !!user.data.organizationUuid;
        if (
            !isCreatingOrg &&
            !isLoadingAllowedOrgs &&
            !userHasOrg &&
            !isAllowedToJoinOrgs &&
            !createOrgError
        ) {
            createOrg({ name: '' });
        }
    }, [
        health,
        allowedOrgs,
        createOrg,
        isCreatingOrg,
        user,
        isLoadingAllowedOrgs,
        createOrgError,
    ]);

    useEffect(() => {
        if (hasCreatedOrg && !createOrgError && !isCreatingOrg) {
            void navigate('/create-organization');
        }
    }, [createOrgError, hasCreatedOrg, isCreatingOrg, navigate]);

    if (health.isInitialLoading || isLoadingAllowedOrgs || isCreatingOrg) {
        return <PageSpinner />;
    }
    if (!createOrgError && !isCreatingOrg) {
        return (
            <AuthorizationError
                message={t('organization_creation.member_limbo_description')}
            />
        );
    }
    return (
        <Page
            title={t('organization_creation.join_page_title')}
            withCenteredContent
            withNavbar={false}
        >
            <Stack mt="4xl">
                <SuboptimalState
                    icon={IconAlertCircle}
                    title="Error"
                    description={createOrgError?.error?.message}
                    action={
                        <Text>
                            {t(
                                'organization_creattion.error_in_creation_organization',
                            )}
                        </Text>
                    }
                />
            </Stack>
        </Page>
    );
};

export default JoinOrganizationPage;
