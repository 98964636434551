import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import SettingsTitle from '@components/common/Settings/SettingsTitle';
import WorkspaceMembersTable from '@components/Workspace/WorkspaceMembers';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import useApp from '@providers/App/useApp';
import React from 'react';
import { Plus } from 'react-feather';
import { Navigate, useParams } from 'react-router';
import InviteMemberModal from '../../Workspace/WorkspaceMembers/InviteWorkspaceMemberModal';

const WorkspaceMembers: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { user } = useApp();
    const ability = useAbilityContext();
    const canViewProjectSettings = ability.can(
        'view',
        subject('ProjectSettings', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const [openedInviteModal, { open, close }] = useDisclosure(false);
    if (!canViewProjectSettings) {
        return <Navigate to="/no-access" />;
    }

    if (!projectUuid) return null;

    return (
        <>
            <Box>
                <Flex className="items-center justify-between w-full">
                    <SettingsTitle
                        title={t('workspace_settings.members_title')}
                    />
                    <Button
                        leftIcon={<Plus size={13} strokeWidth={2.5} />}
                        onClick={open}
                    >
                        {t('workspace_settings.invite_member_btn')}
                    </Button>
                </Flex>

                <Box className="my-6">
                    <WorkspaceMembersTable projectUuid={projectUuid} />
                </Box>
            </Box>

            <InviteMemberModal
                projectUuid={projectUuid}
                onClose={close}
                opened={openedInviteModal}
            />
        </>
    );
};

export default React.memo(WorkspaceMembers);
