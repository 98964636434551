import { sortmentApi } from '@api/index';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import {
    type ApiError,
    type ApiResponse,
    type DeliveryControl,
    type DeliveryControlCreateRequest,
    type DeliveryControlUpdateRequest,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';

const getDeliveryControl = ({ projectUuid }: { projectUuid: string }) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectUuid}/delivery-control`,
        method: 'GET',
        body: undefined,
    });

export const useGetDeliveryControl = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useQuery<ApiResponse, ApiError, DeliveryControl>({
        queryFn: () => getDeliveryControl({ projectUuid }),
        queryKey: [QueryKeys.GET_DELIVERY_CONTROLS, projectUuid],
        retry: false, // INFO - API gives 404 initially when no delivery controls are added in that case the initial load time increases because the API keeps on retrying
    });
};

const createDeliveryControl = ({
    projectUuid,
    payload,
}: {
    projectUuid: string;
    payload: DeliveryControlCreateRequest;
}) =>
    sortmentApi<DeliveryControl>({
        method: 'POST',
        url: `/projects/${projectUuid}/delivery-control`,
        body: JSON.stringify(payload),
    });

export const useCreateDeliveryControl = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();

    return useMutation<DeliveryControl, ApiError, DeliveryControlCreateRequest>(
        {
            mutationFn: (payload) =>
                createDeliveryControl({ projectUuid, payload }),
            mutationKey: [QueryKeys.CREATE_DELIVERY_CONTROLS, projectUuid],
            onSuccess: () => {
                showToastSuccess({
                    title: t(
                        'workspace_settings.deliver_controls.update_success',
                    ),
                    autoClose: 2000,
                });
            },
            onError: (error) => {
                showToastError({
                    title: error.error.message ?? 'Something went wrong',
                });
            },
        },
    );
};

const updateDeliveryControl = ({
    projectUuid,
    payload,
}: {
    projectUuid: string;
    payload: DeliveryControlUpdateRequest;
}) =>
    sortmentApi<DeliveryControl>({
        method: 'PUT',
        url: `/projects/${projectUuid}/delivery-control`,
        body: JSON.stringify(payload),
    });

export const useUpdateDeliveryControl = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const query = useQueryClient();

    return useMutation<DeliveryControl, ApiError, DeliveryControlUpdateRequest>(
        {
            mutationFn: (payload) =>
                updateDeliveryControl({ projectUuid, payload }),
            mutationKey: [QueryKeys.UPDATE_DELIVERY_CONTROLS, projectUuid],
            onSuccess: async () => {
                showToastSuccess({
                    title: t(
                        'workspace_settings.deliver_controls.update_success',
                    ),
                    autoClose: 2000,
                });
                await query.refetchQueries([
                    QueryKeys.GET_DELIVERY_CONTROLS,
                    projectUuid,
                ]);
            },
            onError: (error) => {
                showToastError({
                    title: error.error.message ?? 'Something went wrong',
                });
            },
        },
    );
};
