import { ContentMappingSection, type ContentMappings } from '@lightdash/common';
import { Stack } from '@mantine/core';
import React from 'react';
import LinkComponent from './LinkComponent';

const SmsLinkTracking = ({
    contentMappings,
    setContentMappings,
}: {
    contentMappings: ContentMappings;
    setContentMappings: (val: ContentMappings) => void;
}) => {
    const handleTrackLink = (checked: boolean, link: string) => {
        if (contentMappings && contentMappings[ContentMappingSection.BODY]) {
            setContentMappings({
                ...contentMappings,
                [ContentMappingSection.BODY]: {
                    ...contentMappings[ContentMappingSection.BODY],
                    [link]: {
                        ...contentMappings[ContentMappingSection.BODY][link],
                        enableTracking: checked,
                    },
                },
            });
        }
    };

    return (
        <Stack>
            <LinkComponent
                header={undefined}
                mappings={contentMappings?.[ContentMappingSection.BODY] ?? {}}
                handleChange={handleTrackLink}
            />
        </Stack>
    );
};

export default SmsLinkTracking;
