import JourneyBuilderControlPanel from '@components/Journeys/Builder/ControlPanel';
import ReactFlowCanvas from '@components/Journeys/Builder/ReactFlow/ReactFlowCanvas';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { PAGE_HEADER_HEIGHT } from '@utils/constants';
import React, { useState } from 'react';

import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

const JourneyBuilderCanvas: React.FC<{}> = () => {
    const { controlPanel } = useJourneyBuilderContext(
        (context) => context.state,
    );
    const { isOpen: opened } = controlPanel;

    const [panelDimensions, setPanelDimensions] = useState({
        width: 0,
        height: 0,
    });

    return (
        <PanelGroup
            direction="horizontal"
            style={{
                height: `calc(100vh - ${PAGE_HEADER_HEIGHT}px)`,
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            <Panel
                onResize={(width, height) => {
                    if (width && height) {
                        setPanelDimensions({ width, height });
                    }
                }}
            >
                <ReactFlowCanvas
                    parentDimensions={panelDimensions}
                    hasPanelChanged={opened}
                />
            </Panel>
            <PanelResizeHandle className="relative w-1 overflow-visible transition-colors bg-white group hover:bg-gray-100" />
            {opened && (
                <Panel
                    defaultSize={35}
                    minSize={35}
                    style={{
                        height: `calc(100vh - ${PAGE_HEADER_HEIGHT}px)`,
                    }}
                    className="bg-white border-l border-l-gray-200 overflow-y-auto overflow-x-hidden max-w-[50vw]"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <JourneyBuilderControlPanel />
                </Panel>
            )}
        </PanelGroup>
    );
};

export default React.memo(JourneyBuilderCanvas);
