import Page from '@components/Page/Page';

import React from 'react';

import ProfileBuilderContainer from '@components/Profiles/ProfileManager/ProfileBuilderContainer';
import { useParams } from 'react-router';

const Profiles: React.FC<{}> = ({}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    if (!projectUuid) return null;

    return (
        <Page
            title="Profiles"
            withFullHeight={false}
            withNavbar
            withFixedContent
            withPaddedContent
        >
            <ProfileBuilderContainer projectUuid={projectUuid} />
        </Page>
    );
};
export default Profiles;
