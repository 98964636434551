import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import Timestamp from '@components/common/Timestamp';
import { type EventMapperSchema } from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import useApp from '@providers/App/useApp';
import { type ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocale } from '../../../hooks/useLocale';
import { EventManagerType } from '../types';
import EventsMenuItem from './EventsMenuItem';

export function useEventColumns(managerType: EventManagerType) {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [columns, setColumns] = useState<ColumnDef<EventMapperSchema>[]>([]);
    const { t } = useLocale();
    const { user } = useApp();
    const ability = useAbilityContext();
    const canEditProject = ability.can(
        'update',
        subject('Project', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    useEffect(() => {
        if (projectUuid) {
            const baseColumns: ColumnDef<EventMapperSchema>[] = [
                {
                    accessorKey: 'eventName',
                    header: t('event_manager.th_event_name'),
                    cell: ({ row, getValue }) => (
                        <Box
                            className={`flex flex-col gap-0.5 justify-center items-start !w-56`}
                        >
                            <Text
                                className={`text-sm font-medium text-gray-800 truncate !w-56`}
                            >
                                {row.original.label}
                            </Text>
                            {row.original.label && (
                                <Text
                                    className={`text-xs font-normal text-gray-600 truncate !w-56`}
                                >
                                    {getValue<string>()}
                                </Text>
                            )}
                        </Box>
                    ),
                },
                {
                    accessorKey: 'source',
                    header: t('event_manager.th_event_source'),
                    cell: ({ getValue }) => (
                        <Text className="text-sm text-gray-800">
                            {getValue<string>()}
                        </Text>
                    ),
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {row.original.createdBy &&
                                    `by ${row.original.createdBy || ''}`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'updatedAt',
                    header: t('common.modified_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            {row.original.updatedAt ? (
                                <Timestamp timestamp={getValue<string>()} />
                            ) : (
                                '-'
                            )}

                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {row.original.updatedBy &&
                                    `by ${row.original.updatedBy || ''}`}
                            </Text>
                        </Box>
                    ),
                },
            ];

            if (managerType === EventManagerType.VIEW && canEditProject) {
                baseColumns.push({
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => (
                        <EventsMenuItem rowData={row.original} />
                    ),
                });
            }

            setColumns(baseColumns);
        }
    }, [projectUuid, t, managerType, canEditProject]);

    return columns;
}
