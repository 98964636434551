import UserCount from '@components/common/UserCount';
import { useLocale } from '@hooks/useLocale';
import { type CustomMaterialisation } from '@lightdash/common';
import { Flex, Text } from '@mantine/core';

interface ProfileTitleProps {
    profile: CustomMaterialisation;
    count: number | undefined;
}
const ProfileTitle: React.FC<ProfileTitleProps> = ({ count }) => {
    const { t } = useLocale();
    // const history = useHistory();
    // const [opened, { open, close }] = useDisclosure(false);
    // const MENU_TARGET_CLASSNAME = useMemo(() => {
    //     return `text-gray-700 font-semibold p-3 ${
    // !opened ? 'hover:!bg-shade-2 hover:!text-gray-700 ' : ''
    //     }  ${opened ? '!text-blu-800 ' : ''}`;
    // }, [opened]);
    // const cronHelperText = useMemo(() => {
    //     const validationError = isInvalidCronExpression('Cron expression')(
    //         profile.cron,
    //     );
    //     const cronHumanString = cronstrue.toString(profile?.cron ?? '', {
    //         verbose: true,
    //         throwExceptionOnParseError: false,
    //     });
    //     return validationError ?? cronHumanString;
    // }, [profile.cron]);

    return (
        <Flex align="center" gap={12}>
            <Text>{t('profiles')}</Text>
            <UserCount
                count={count}
                formatValue={false}
                withRightSection={false}
            />
            {/* <Menu
                opened={opened}
                onOpen={open}
                position="bottom-start"
                onClose={close}
                width={260}
            >
                <Menu.Target>
                    <Button
                        className={MENU_TARGET_CLASSNAME}
                        leftIcon={
                            <Question
                                color={`${
                                    opened
                                        ? 'rgb(var(--color-blu-800))'
                                        : 'rgb(var(--color-gray-700))'
                                }`}
                            />
                        }
                        variant={ButtonVariant.UNSTYLED}
                    >
                        {t('profile_manager.sync_schedule')}
                    </Button>
                </Menu.Target>
                <Menu.Dropdown className="p-1.5">
                    <Text className="text-sm font-medium text-gray-800">
                        {profile.cron &&
                            t('profiles_manager.cron_refresh_rate', {
                                cron:
                                    cronHelperText.charAt(0).toLowerCase() +
                                    cronHelperText.slice(1),
                            })}
                    </Text>
                    <Flex className="text-sm font-medium text-gray-600" gap={2}>
                        {t('profiles_manager.last_sync')}
                        {profile.lastSuccessfulRunAt && (
                            <Timestamp
                                timestamp={profile.lastSuccessfulRunAt.toString()}
                            />
                        )}
                    </Flex>
                    <Box className="flex justify-end">
                        <Button
                            variant={ButtonVariant.SUBTLE_ACCENTED}
                            onClick={() => {
                                history.push(
                                    `/projects/${projectUuid}/settings/sync-schedule`,
                                );
                            }}
                            className="m-1.5"
                            rightIcon={
                                <CaretRight
                                    color={'rgb(var(--color-blu-800))'}
                                />
                            }
                        >
                            {t('audience_manager.sync_confirm_button')}
                        </Button>
                    </Box>
                </Menu.Dropdown>
            </Menu> */}
        </Flex>
    );
};
export default ProfileTitle;
