import { getDedupedEventTableName } from '@components/Audience/utils';
import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import { addFieldIdToMetricFilterRule } from '@components/Explorer/CustomMetricModal/utils';
import { useFilterFields } from '@hooks/useFilterFields';
import { useLocale } from '@hooks/useLocale';
import {
    createFilterRuleFromField,
    FilterOperator,
    getFieldRef,
    getItemId,
    isField,
    isFilterableField,
    JoinType,
    RelationTableType,
    type AdditionalMetric,
    type FilterableField,
    type FilterRule,
    type Filters,
} from '@lightdash/common';
import { Box, Button, Group, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import useAudienceContext from '@providers/Audience/useAudienceContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { getJoinedTablesByJoinTypes } from '@utils/relation';
import React, { useCallback, useMemo } from 'react';
import { ButtonVariant } from '../../../../../mantineTheme';
import FieldListItem from '../../FieldListItem';
import { convertFieldsIntoPropertySelectListType } from '../../FieldListItem/utils';
import { MenuForDimensionFilterRule } from '../../FilterRuleMenuItem';
import FilterRuleForm from '../../FilterRules/FilterRuleForm';
import { type FieldWithSuggestions } from '../../FiltersProvider/types';
import { isFilterRuleEventNameMetric } from '../../utils';
import { createFilterRule } from '../../utils/createFilterRule';
import FilterRuleFormWrapper from '../FilterRuleFormWrapper';
import EventMetricFilterRuleForm from './EventMetricFilterRuleForm';
import RelatedRecordFilterRuleForm from './RelatedRecordFilterRuleForm';
import { useParams } from 'react-router';
import CampaignFilterRuleForm from './CampaignEventFilters/CampaignFilterRuleForm';

interface DerivedMetricFilterRuleFormProps {
    filterRule: FilterRule;
    onChange: (value: FilterRule) => void;
    isEditMode: boolean;
    fields: FilterableField[];
    onFieldChange: (value: FilterableField) => void;
    additionalMetrics: AdditionalMetric[];
    groupIndex: number;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    onDeleteItem: (index: number) => void;
}

const DerivedMetricFilterRuleForm = ({
    filterRule,
    onChange,
    isEditMode,
    fields,
    additionalMetrics,
    groupIndex,
    filters,
    setFilters,
    onDeleteItem,
}: DerivedMetricFilterRuleFormProps) => {
    const { t } = useLocale();
    const [opened, { open, close }] = useDisclosure(false);
    const { activeRelation } = useRelationContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    const activeField = useMemo(() => {
        return fields.find(
            (field) => getItemId(field) === filterRule.target.fieldId,
        );
    }, [filterRule, fields]);

    const matchedAdditionalMetric = additionalMetrics.find(
        (metric) =>
            metric.name === activeField?.name &&
            metric.table === activeField?.table,
    );

    const { dimensions } = useFilterFields();

    const setAdditionalMetrics = useAudienceContext(
        (context) => context.actions.setAdditionalMetrics,
    );
    const isEventNameMetric = useMemo(() => {
        if (!filterRule || !additionalMetrics || !activeRelation) return false;
        return isFilterRuleEventNameMetric({
            filterRule,
            additionalMetrics,
            activeRelation,
        });
    }, [filterRule, additionalMetrics, activeRelation]);

    const tableFields = useMemo(() => {
        if (!activeField?.table || !activeRelation) return [];
        const joinedTables = getJoinedTablesByJoinTypes(
            activeField.table,
            activeRelation,
            [JoinType.one_one, JoinType.many_one, JoinType.many_many],
        );
        const allowedTables = [activeField.table, ...joinedTables];
        const table = activeRelation.tables[activeField.table];
        let possibleFields = [...dimensions].filter((field) =>
            allowedTables.includes(field.table),
        );
        if (isEventNameMetric && table.type === RelationTableType.EVENT) {
            possibleFields = possibleFields.filter(
                (field) => field.name !== table.eventNameColumn,
            );
        }
        return possibleFields;
    }, [activeField, activeRelation, dimensions, isEventNameMetric]);

    const addFieldRule = useCallback(
        (field: FieldWithSuggestions) => {
            if (!field) return;

            if (isField(field) && isFilterableField(field)) {
                const newFilterRule = createFilterRuleFromField(field);
                const newAdditionalMetrics = [...additionalMetrics];

                const metricIndex = newAdditionalMetrics.findIndex(
                    (metric) =>
                        metric.name === activeField?.name &&
                        metric.table === activeField?.table,
                );

                if (metricIndex !== -1) {
                    newAdditionalMetrics[metricIndex].filters?.push({
                        ...newFilterRule,
                        target: {
                            fieldRef: getFieldRef(field),
                        },
                    });
                }

                setAdditionalMetrics(newAdditionalMetrics, false, groupIndex);
            }
        },
        [
            activeField?.name,
            activeField?.table,
            additionalMetrics,
            groupIndex,
            setAdditionalMetrics,
        ],
    );

    const customMetricFiltersWithIds = matchedAdditionalMetric?.filters?.map(
        (rule) => addFieldIdToMetricFilterRule(rule),
    );

    const selectedToggleOperator: FilterOperator = useMemo(() => {
        switch (filterRule.operator) {
            case FilterOperator.EQUALS:
                return FilterOperator.EQUALS;
            case FilterOperator.NOT_EQUALS:
                return FilterOperator.NOT_EQUALS;
            case FilterOperator.GREATER_THAN_OR_EQUAL:
                return FilterOperator.EQUALS;
            case FilterOperator.LESS_THAN:
                return FilterOperator.NOT_EQUALS;
            case FilterOperator.LESS_THAN_OR_EQUAL:
                return FilterOperator.EQUALS;
            case FilterOperator.GREATER_THAN:
                return FilterOperator.NOT_EQUALS;
            default:
                return filterRule.operator;
        }
    }, [filterRule.operator]);

    const selectedConditionalOperator = useMemo(() => {
        switch (filterRule.operator) {
            case FilterOperator.EQUALS:
                return FilterOperator.EQUALS;
            case FilterOperator.NOT_EQUALS:
                return FilterOperator.EQUALS;
            case FilterOperator.GREATER_THAN_OR_EQUAL:
                return FilterOperator.GREATER_THAN_OR_EQUAL;
            case FilterOperator.LESS_THAN:
                return FilterOperator.GREATER_THAN_OR_EQUAL;
            case FilterOperator.LESS_THAN_OR_EQUAL:
                return FilterOperator.LESS_THAN_OR_EQUAL;
            case FilterOperator.GREATER_THAN:
                return FilterOperator.LESS_THAN_OR_EQUAL;
            default:
                return filterRule.operator;
        }
    }, [filterRule.operator]);

    const handleOperatorChange = useCallback(
        (inputValue: FilterOperator) => {
            let newOperator = inputValue;
            if (selectedToggleOperator === FilterOperator.EQUALS) {
                if (inputValue === FilterOperator.EQUALS) {
                    newOperator = FilterOperator.EQUALS;
                } else if (
                    inputValue === FilterOperator.GREATER_THAN_OR_EQUAL
                ) {
                    newOperator = FilterOperator.GREATER_THAN_OR_EQUAL;
                } else if (inputValue === FilterOperator.LESS_THAN_OR_EQUAL) {
                    newOperator = FilterOperator.LESS_THAN_OR_EQUAL;
                }
            } else {
                if (inputValue === FilterOperator.EQUALS) {
                    newOperator = FilterOperator.NOT_EQUALS;
                } else if (
                    inputValue === FilterOperator.GREATER_THAN_OR_EQUAL
                ) {
                    newOperator = FilterOperator.LESS_THAN;
                } else if (inputValue === FilterOperator.LESS_THAN_OR_EQUAL) {
                    newOperator = FilterOperator.GREATER_THAN;
                }
            }

            onChange({
                ...filterRule,
                operator: newOperator,
            });
        },
        [selectedToggleOperator, onChange, filterRule],
    );

    const handleToggleChange = useCallback(
        (inputValue: string) => {
            let newOperator = inputValue as FilterOperator;
            if (selectedConditionalOperator === FilterOperator.EQUALS) {
                if (inputValue === FilterOperator.EQUALS) {
                    newOperator = FilterOperator.EQUALS;
                } else if (inputValue === FilterOperator.NOT_EQUALS) {
                    newOperator = FilterOperator.NOT_EQUALS;
                }
            } else if (
                selectedConditionalOperator ===
                FilterOperator.GREATER_THAN_OR_EQUAL
            ) {
                if (inputValue === FilterOperator.EQUALS) {
                    newOperator = FilterOperator.GREATER_THAN_OR_EQUAL;
                } else if (inputValue === FilterOperator.NOT_EQUALS) {
                    newOperator = FilterOperator.LESS_THAN;
                }
            } else if (
                selectedConditionalOperator ===
                FilterOperator.LESS_THAN_OR_EQUAL
            ) {
                if (inputValue === FilterOperator.EQUALS) {
                    newOperator = FilterOperator.LESS_THAN_OR_EQUAL;
                } else if (inputValue === FilterOperator.NOT_EQUALS) {
                    newOperator = FilterOperator.GREATER_THAN;
                }
            }
            onChange({
                ...filterRule,
                operator: newOperator,
            });
        },
        [selectedConditionalOperator, onChange, filterRule],
    );

    const handleValueChange = useCallback(
        (inputValue: number) => {
            onChange({
                ...filterRule,
                values: [inputValue],
            });
        },
        [filterRule, onChange],
    );

    const handleFieldChange = useCallback(
        (updatedFilterRule: FilterRule) => {
            onChange(updatedFilterRule);
        },
        [onChange],
    );

    const handleMetricGroupDelete = useCallback(() => {
        const newAdditionalMetrics = [...additionalMetrics];

        //Info: Remove the additional metric that is created on the fly for the derived metric filter rule
        const filteredAdditionalMetrics = newAdditionalMetrics.filter(
            (metric) =>
                !(
                    metric.name === activeField?.name &&
                    metric.table === activeField?.table
                ),
        );
        setAdditionalMetrics(filteredAdditionalMetrics, false, groupIndex);

        //Info: Remove the derived metric filter rule from the filters metrics
        onDeleteItem(groupIndex);
    }, [
        activeField?.name,
        activeField?.table,
        additionalMetrics,
        groupIndex,
        onDeleteItem,
        setAdditionalMetrics,
    ]);

    const handleAdditionalMetricFilterChange = useCallback(
        (metricFilterIndex: number, newFilterRule: FilterRule) => {
            const newAdditionalMetrics = additionalMetrics.map((metric) => {
                if (
                    metric.name === activeField?.name &&
                    metric.table === activeField?.table
                ) {
                    const metricFilters = metric.filters?.map(
                        (filter, filterIndex) => {
                            if (metricFilterIndex === filterIndex) {
                                const tableField = tableFields.find(
                                    (field) =>
                                        getItemId(field) ===
                                        newFilterRule.target.fieldId,
                                );
                                return {
                                    ...newFilterRule,
                                    target: {
                                        ...newFilterRule.target,
                                        fieldRef: `${tableField?.table}.${tableField?.name}`,
                                    },
                                };
                            }
                            return filter;
                        },
                    );
                    return {
                        ...metric,
                        filters: metricFilters,
                    };
                }
                return metric;
            });
            setAdditionalMetrics(newAdditionalMetrics, false, groupIndex);
        },
        [
            activeField?.name,
            activeField?.table,
            additionalMetrics,
            groupIndex,
            setAdditionalMetrics,
            tableFields,
        ],
    );

    const handleAdditionalMetricFilterDelete = useCallback(
        (metricIndex: number) => {
            const newAdditionalMetrics = additionalMetrics.map((metric) => {
                if (
                    metric.name === activeField?.name &&
                    metric.table === activeField?.table
                ) {
                    const metricFilters = metric.filters?.filter(
                        (_filter, filterIndex) => {
                            if (metricIndex === filterIndex) {
                                return false;
                            }
                            return true;
                        },
                    );
                    return {
                        ...metric,
                        filters: metricFilters,
                    };
                }
                return metric;
            });
            setAdditionalMetrics(newAdditionalMetrics, false, groupIndex);
        },
        [
            activeField?.name,
            activeField?.table,
            additionalMetrics,
            groupIndex,
            setAdditionalMetrics,
        ],
    );

    const handleDuplicateMetricFilter = useCallback(() => {
        if (!matchedAdditionalMetric || !projectUuid) return;

        //Info: Find the base dimension that is used to create the additional metric
        const matchedDimension = dimensions.find(
            (field) =>
                field.name === matchedAdditionalMetric.baseDimensionName &&
                field.table === matchedAdditionalMetric.table,
        );
        if (!matchedDimension) return;

        //Todo: This recreates the additional metric, we should find a way to reuse the existing additional metric
        createFilterRule({
            field: matchedDimension,
            additionalMetrics,
            setAdditionalMetrics,
            activeRelation,
            filters,
            setFilters,
            index: groupIndex,
            additionalMetricsFilters: matchedAdditionalMetric.filters,
            projectUuid,
        });
    }, [
        activeRelation,
        additionalMetrics,
        dimensions,
        filters,
        groupIndex,
        matchedAdditionalMetric,
        setAdditionalMetrics,
        setFilters,
        projectUuid,
    ]);

    const isCampaignEvent = useMemo(
        () =>
            projectUuid &&
            activeField?.table === getDedupedEventTableName(projectUuid),
        [activeField?.table, projectUuid],
    );

    const shouldSkipFilterRendering = useCallback(
        (filter: FilterRule): boolean => {
            if (
                isEventNameMetric &&
                activeField?.table &&
                activeRelation?.tables &&
                customMetricFiltersWithIds
            ) {
                //Info: When an event name is selected, we create an additional metric on the fly with the eventName column as the target field and the selected event as the value
                // We need to determine if the event name metric filter rule should be skipped while rendering in the UI
                const matchedTable = activeRelation.tables[activeField.table];
                if (!matchedTable) return false;
                if (matchedTable.type !== RelationTableType.EVENT) return false;
                const eventNameColumnId = matchedTable.eventNameColumn;
                if (!eventNameColumnId) return false;
                const eventNameField =
                    matchedTable.dimensions[eventNameColumnId];
                if (!eventNameField) return false;
                return filter.target.fieldId === getItemId(eventNameField);
            }
            return false;
        },
        [
            activeField,
            activeRelation,
            customMetricFiltersWithIds,
            isEventNameMetric,
        ],
    );

    const filterRuleForm = useMemo(() => {
        if (isCampaignEvent || isEventNameMetric) {
            return (
                <EventMetricFilterRuleForm
                    isEditMode={isEditMode}
                    selectedToggleOperator={selectedToggleOperator}
                    handleToggleChange={handleToggleChange}
                    selectedConditionalOperator={selectedConditionalOperator}
                    handleOperatorChange={handleOperatorChange}
                    filterRule={filterRule}
                    handleValueChange={handleValueChange}
                    filters={filters}
                    setFilters={setFilters}
                    groupIndex={groupIndex}
                    additionalMetrics={additionalMetrics}
                    fields={fields}
                    handleFieldChange={handleFieldChange}
                />
            );
        }
        return (
            <RelatedRecordFilterRuleForm
                isEditMode={isEditMode}
                selectedToggleOperator={selectedToggleOperator}
                handleToggleChange={handleToggleChange}
                selectedConditionalOperator={selectedConditionalOperator}
                handleOperatorChange={handleOperatorChange}
                filterRule={filterRule}
                handleValueChange={handleValueChange}
                filters={filters}
                setFilters={setFilters}
                groupIndex={groupIndex}
                additionalMetrics={additionalMetrics}
                fields={fields}
            />
        );
    }, [
        isEventNameMetric,
        additionalMetrics,
        fields,
        filterRule,
        filters,
        setFilters,
        groupIndex,
        isCampaignEvent,
        handleFieldChange,
        handleOperatorChange,
        handleToggleChange,
        handleValueChange,
        isEditMode,
        selectedConditionalOperator,
        selectedToggleOperator,
    ]);
    let renderedGroupCount = 0;
    const nestedFilters = useMemo(() => {
        if (isCampaignEvent) {
            if (!activeField) return null;
            return (
                <CampaignFilterRuleForm
                    campaignFilters={
                        matchedAdditionalMetric?.filters
                            ?.slice(2)
                            .map(addFieldIdToMetricFilterRule) ?? []
                    }
                    activeField={activeField}
                    isEditMode={isEditMode}
                    additionalMetrics={additionalMetrics}
                    setAdditionalMetrics={setAdditionalMetrics}
                    groupIndex={groupIndex}
                />
            );
        }
        return matchedAdditionalMetric?.filters?.length ? (
            <Stack className="gap-2 pl-4 mb-4 ml-4 border-l-4 border-shade-4">
                {customMetricFiltersWithIds?.map((rule, index) => {
                    if (shouldSkipFilterRendering(rule)) return null;
                    renderedGroupCount++;
                    return (
                        <Group key={rule.id} className="flex-nowrap">
                            <Box className="w-12 text-sm text-gray-600 text-end">
                                {Boolean(renderedGroupCount === 1)
                                    ? 'Where'
                                    : 'and'}
                            </Box>
                            <FilterRuleForm
                                filterRule={rule}
                                fields={tableFields}
                                isEditMode={isEditMode}
                                onChange={(value) =>
                                    handleAdditionalMetricFilterChange(
                                        index,
                                        value as FilterRule,
                                    )
                                }
                                onDelete={() =>
                                    handleAdditionalMetricFilterDelete(index)
                                }
                                filters={{}}
                                showFieldSource={false}
                                setFilters={() => {}}
                                groupIndex={groupIndex}
                                additionalMetrics={additionalMetrics}
                            />
                        </Group>
                    );
                })}

                {isEditMode && (
                    <PropertySelect<
                        FieldWithSuggestions &
                            AddditionalPropertySelectListProps
                    >
                        items={convertFieldsIntoPropertySelectListType(
                            tableFields,
                            false,
                        )}
                        showGroup={false}
                        onSubmit={(items: FieldWithSuggestions[]) => {
                            if (!items[0]) return;
                            addFieldRule(items[0]);
                            close();
                        }}
                        itemTemplate={({ item }) => {
                            return (
                                <FieldListItem
                                    item={item}
                                    checked={item.isChecked ?? false}
                                    showCheckbox={false}
                                    disabled={item.isDisabled ?? false}
                                    showFieldSource={true}
                                />
                            );
                        }}
                        headerRightSection={null}
                        opened={opened}
                        close={close}
                        open={open}
                        targetButton={
                            <Button
                                variant={ButtonVariant.SUBTLE}
                                className="text-gray-800 w-fit"
                                size="md"
                            >
                                <PlusCircle
                                    color="rgb(var(--color-gray-800))"
                                    className="mr-1"
                                />
                                {t('common.property')}
                            </Button>
                        }
                        showSearch={true}
                        searchKeys={['label', 'tableLabel']}
                        searchPlaceholder={'Search'}
                        allowMultipleSelection={false}
                        height={350}
                        width={300}
                    />
                )}
            </Stack>
        ) : (
            isEditMode && (
                <PropertySelect<
                    FieldWithSuggestions & AddditionalPropertySelectListProps
                >
                    items={convertFieldsIntoPropertySelectListType(
                        tableFields,
                        false,
                    )}
                    showGroup={false}
                    onSubmit={(items: FieldWithSuggestions[]) => {
                        if (!items[0]) return;
                        addFieldRule(items[0]);
                        close();
                    }}
                    itemTemplate={({ item }) => {
                        return (
                            <FieldListItem
                                item={item}
                                checked={item.isChecked ?? false}
                                showCheckbox={false}
                                disabled={item.isDisabled ?? false}
                                showFieldSource={true}
                            />
                        );
                    }}
                    headerRightSection={null}
                    opened={opened}
                    close={close}
                    open={open}
                    targetButton={
                        <Button
                            variant={ButtonVariant.SUBTLE}
                            className="mb-2 text-gray-800 w-fit"
                            size="md"
                        >
                            <PlusCircle
                                color="rgb(var(--color-gray-800))"
                                className="mr-1"
                            />
                            {t(
                                'audience_builder.derived_metric_filter_rule_form.new_property',
                            )}
                        </Button>
                    }
                    showSearch={true}
                    searchKeys={['label', 'tableLabel']}
                    searchPlaceholder={'Search'}
                    allowMultipleSelection={false}
                    height={350}
                    width={300}
                />
            )
        );
    }, [
        matchedAdditionalMetric,
        tableFields,
        opened,
        open,
        close,
        addFieldRule,
        handleAdditionalMetricFilterChange,
        handleAdditionalMetricFilterDelete,
        t,
        activeField,
        additionalMetrics,
        customMetricFiltersWithIds,
        groupIndex,
        isCampaignEvent,
        isEditMode,
        renderedGroupCount,
        shouldSkipFilterRendering,
        setAdditionalMetrics,
    ]);
    if (!activeField || !matchedAdditionalMetric) return null;

    return (
        <FilterRuleFormWrapper>
            <Stack className="gap-2">
                <Group className="gap-2">
                    {filterRuleForm}
                    {isEditMode && (
                        <MenuForDimensionFilterRule
                            onDelete={handleMetricGroupDelete}
                            duplicateFilter={handleDuplicateMetricFilter}
                        />
                    )}
                </Group>

                {nestedFilters}
            </Stack>
        </FilterRuleFormWrapper>
    );
};

export default React.memo(DerivedMetricFilterRuleForm);
