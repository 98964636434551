import { isSplitBlock } from '@components/Journeys/Builder/ControlPanel/Actions/Split/utils';
import { getEdgeLabel } from '@components/Journeys/Builder/ReactFlow/utils';
import {
    ControlPanel,
    JourneyBuilderMode,
    JourneyEdgeEnum,
    JourneyNodeEnum,
    TriggerType,
    type ControlPanelState,
    type JourneyNodeData,
    type JourneyNodeUpdatePayload,
} from '@components/Journeys/Builder/types';
import {
    getAllChildNodeIds,
    getEdgeId,
    hasEntryLogicError,
    hasNodeError,
    hasTriggerNodeError,
    processJourneyAnalytics,
} from '@components/Journeys/Builder/utils';
import {
    addNodeUtil,
    getGhostNodeAndEdge,
} from '@components/Journeys/providerUtils';
import useToaster from '@hooks/toaster/useToaster';
import {
    useActivateJourney,
    useCreateJourney,
    useGenerateNodeDescription,
    useGetJourneyAnalytics,
    useUpdateJourney,
} from '@hooks/useJourney';
import { useLocale } from '@hooks/useLocale';
import {
    ActionType as JourneyActionType,
    BranchConcurrencyTypes,
    BranchConditionalTypes,
    JourneyStatus,
    PeriodType,
    ReservedEventColumns,
    type BaseTrigger,
    type Branch,
    type BranchConfig,
    type ConversionTrigger,
    type FilterableField,
    type Journey,
    type JourneyAction,
    type JourneyEntryLogic,
    type JourneyEventMapperSchema,
    type JourneyNode,
    type JourneyNodeDescriptionRequest,
    type JourneyPublishConfig,
    type JourneyTriggerConfig,
} from '@lightdash/common';
import { type JourneyAnalytics } from '@pages/JourneyBuilder';
import { useQueryClient } from '@tanstack/react-query';
import { generateShortUUID } from '@utils/helpers';
import { t as translate } from 'i18next';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { type Edge, type Node } from 'reactflow';
import { QueryKeys } from 'types/UseQuery';
import JourneyBuilderProviderContext from './context';
import {
    ActionType,
    type JourneyBuilderState,
    type JourneyReducerState,
} from './types';

type Action =
    | {
          type: ActionType.ADD_NODE;
          payload: { blockId: string; reactFlowNodeId: string }; //Info: this is the id of the placeholder node or the id of the react flow node where the new block node is being added
      }
    | { type: ActionType.OPEN_CONTROL_PANEL; payload: ControlPanelState }
    | { type: ActionType.CLOSE_CONTROL_PANEL }
    | {
          type: ActionType.ADD_TRIGGER_NODE;
          payload: Pick<
              BaseTrigger,
              'eventName' | 'eventSource' | 'isBusinessEventTrigger'
          >;
      }
    | { type: ActionType.ADD_PLACEHOLDER_NODE; payload: { nodeId?: string } }
    | {
          type: ActionType.UPDATE_NODE_ACTION_CONFIG;
          payload: { nodeId: string; updatedAction: JourneyAction };
      }
    | {
          type: ActionType.UPDATE_EXIT_NODE;
          payload: { payload: Partial<BaseTrigger>; id: string };
      }
    | { type: ActionType.REMOVE_PLACEHOLDER_NODES }
    | {
          type: ActionType.ADD_PLACEHOLDER_NODE_BETWEEN;
          payload: { edgeId: string };
      }
    | {
          type: ActionType.UPDATE_JOURNEY_PAYLOAD;
          payload: Partial<Journey>;
      }
    | {
          type: ActionType.SET_EXIT_TRIGGERS;
          payload: JourneyTriggerConfig['exit'];
      }
    | {
          type: ActionType.SET_NODES;
          payload: Node<JourneyNodeData>[];
      }
    | {
          type: ActionType.SET_NODES_UNSELECTED;
          payload: Node<JourneyNodeData>[];
      }
    | {
          type: ActionType.SET_GOALS;
          payload: Partial<ConversionTrigger>;
      }
    | {
          type: ActionType.SET_ENTRY_LOGIC;
          payload: Partial<JourneyEntryLogic>;
      }
    | {
          type: ActionType.UPDATE_TRIGGER_NODE;
          payload: Partial<BaseTrigger>;
      }
    | {
          type: ActionType.UPDATE_NODE_CONFIG;
          payload: JourneyNodeUpdatePayload;
      }
    | {
          type: ActionType.DELETE_NODE;
          payload: { nodeId: string };
      }
    | {
          type: ActionType.ADD_EDGE_WITH_GHOST_NODE;
          payload: {
              nodeId: string;
              branch: Branch | undefined;
              edgeName: string | undefined;
          };
      }
    | {
          type: ActionType.UPDATE_BRANCH_CONFIG;
          payload: { nodeId: string; updatedBranchConfig: BranchConfig };
      }
    | {
          type: ActionType.UPDATE_SPLIT_ACTIVE_FIELDS;
          payload: {
              nodeId: string;
              activeField: FilterableField | undefined;
              isJourneyField: boolean;
          };
      }
    | {
          type: ActionType.DELETE_ALL_CHILD_BRANCHES;
          payload: { nodeId: string };
      }
    | {
          type: ActionType.UPDATE_BRANCHING_EDGE_LABEL;
          payload: { edgeId: string; label: string };
      }
    | {
          type: ActionType.UPDATE_NODE_DESCRIPTION;
          payload: { nodeId: string; nodeDescription: string };
      }
    | {
          type: ActionType.RESET_REDUCER_STATE;
          payload: JourneyReducerState;
      }
    | {
          type: ActionType.CREATE_EVERY_ONE_ELSE_PATH;
          payload: { nodeId: string; blockId: string };
      }
    | {
          type: ActionType.ADD_GHOST_NODE_WITH_BRANCHING_EDGE;
          payload: { nodeId: string; blockId: string };
      };

function reducer(
    state: JourneyReducerState,
    action: Action,
): JourneyReducerState {
    switch (action.type) {
        case ActionType.ADD_NODE: {
            return addNodeUtil({
                state,
                blockId: action.payload.blockId,
                reactFlowNodeId: action.payload.reactFlowNodeId,
            });
        }

        case ActionType.OPEN_CONTROL_PANEL: {
            let nodeId: string | undefined;
            switch (action.payload.type) {
                case ControlPanel.BLOCK_CONFIG:
                    nodeId = action.payload.nodeId;
                    break;
                case ControlPanel.TRIGGER_CONFIG:
                    nodeId = action.payload.triggerId;
                    break;
                case ControlPanel.BLOCKS_LIST:
                    nodeId = action.payload.reactFlowNodeId;
                    break;
                default:
                    nodeId = undefined;
            }

            return {
                ...state,
                nodes: state.nodes.map((node) => {
                    if (node.id === nodeId) {
                        return {
                            ...node,
                            selected: true,
                        };
                    }
                    return {
                        ...node,
                        selected: false,
                    };
                }),

                controlPanel: {
                    isOpen: true,
                    ...action.payload,
                },
            };
        }
        case ActionType.CLOSE_CONTROL_PANEL: {
            const { nodes } = state;
            const unselectedNodes = nodes.map((node) => ({
                ...node,
                selected: false,
            }));
            return {
                ...state,
                nodes: unselectedNodes,
                controlPanel: {
                    isOpen: false,
                },
            };
        }
        case ActionType.ADD_TRIGGER_NODE: {
            const nodes = state.nodes;

            //INFO: To ensure only one trigger node is present in the Journey. Remove this statement to allow multiple trigger nodes
            const nodesWithoutTrigger = nodes.filter(
                (node) =>
                    !(
                        node.data.type === JourneyNodeEnum.PLACEHOLDER &&
                        node.data.placeHolderType === JourneyNodeEnum.TRIGGER
                    ),
            );

            const newNodeId = generateShortUUID();
            const triggerNode: Node<JourneyNodeData> = {
                id: newNodeId,
                position: { x: 0, y: 0 },
                type: JourneyNodeEnum.TRIGGER,
                data: {
                    nodeId: newNodeId,
                    type: JourneyNodeEnum.TRIGGER,
                    blockId: JourneyNodeEnum.TRIGGER,
                },
            };

            const triggerPayload: JourneyTriggerConfig['entry'] = [
                {
                    eventName: action.payload.eventName,
                    eventSource: action.payload.eventSource,
                    id: newNodeId,
                    metadata: {
                        id: newNodeId,
                        title: translate(
                            'journey_builder.trigger_node_block_title',
                        ),
                    },
                    isBusinessEventTrigger:
                        action.payload.isBusinessEventTrigger,
                },
            ];

            return {
                ...state,
                nodes: [...nodesWithoutTrigger, triggerNode],
                journeyPayload: {
                    ...state.journeyPayload,
                    triggers: {
                        ...state.journeyPayload.triggers,
                        entry: triggerPayload,
                    },
                },
                controlPanel: {
                    isOpen: true,
                    type: ControlPanel.TRIGGER_CONFIG,
                    triggerId: newNodeId,
                },
            };
        }

        case ActionType.ADD_PLACEHOLDER_NODE: {
            const placeholderNodeId = generateShortUUID();
            const currentNodeId =
                state.nodes.length > 0
                    ? action.payload?.nodeId ??
                      state.nodes[state.nodes.length - 1].id
                    : '';

            if (!currentNodeId) return state;

            const currentNodes = state.nodes.map((node) => ({
                ...node,
                selected: false,
            }));

            const placeholderNode: Node<JourneyNodeData> = {
                id: placeholderNodeId,
                position: { x: 0, y: 0 },
                type: JourneyNodeEnum.PLACEHOLDER,
                data: {
                    type: JourneyNodeEnum.PLACEHOLDER,
                    placeHolderType: JourneyNodeEnum.BLOCK,
                },
            };

            const newEdge: Edge = {
                id: getEdgeId(currentNodeId, placeholderNodeId),
                source: currentNodeId,
                target: placeholderNodeId,
                type: JourneyEdgeEnum.BLOCK,
            };

            return {
                ...state,
                nodes: [...currentNodes, placeholderNode],
                edges: currentNodeId ? [...state.edges, newEdge] : state.edges,
                controlPanel: {
                    isOpen: true,
                    type: ControlPanel.BLOCKS_LIST,
                    reactFlowNodeId: placeholderNodeId,
                },
            };
        }

        case ActionType.UPDATE_NODE_ACTION_CONFIG: {
            const { nodeId, updatedAction } = action.payload;
            const nodes: Node<JourneyNodeData>[] = state.nodes.map((node) => {
                if (node.id === nodeId) {
                    const journeyActions =
                        state.journeyPayload.config?.nodes.find(
                            (n) => n.id === nodeId,
                        )?.actions ?? [];
                    const updatedActions = journeyActions.map((a) =>
                        a.type === updatedAction.type ? updatedAction : a,
                    );

                    return {
                        ...node,
                        data: {
                            ...node.data,
                            actions: updatedActions,
                        },
                    };
                }
                return node;
            });

            const journeyNodes: JourneyNode[] =
                state.journeyPayload.config?.nodes.map((node) =>
                    node.id === nodeId
                        ? {
                              ...node,
                              actions: node.actions.map((a) =>
                                  a.type === updatedAction.type
                                      ? updatedAction
                                      : a,
                              ),
                          }
                        : node,
                ) ?? [];

            return {
                ...state,
                nodes,
                journeyPayload: {
                    ...state.journeyPayload,
                    config: {
                        ...state.journeyPayload.config,
                        nodes: journeyNodes,
                    },
                },
            };
        }

        case ActionType.REMOVE_PLACEHOLDER_NODES: {
            const isPlaceholderNode = (node: Node<JourneyNodeData>) =>
                node.data.type === JourneyNodeEnum.PLACEHOLDER;
            const targetEdge = (node: Node<JourneyNodeData>) =>
                state.edges.find((edge) => edge.target === node.id);
            const sourceEdge = (node: Node<JourneyNodeData>) =>
                state.edges.find((edge) => edge.source === node.id);
            const nodeById = (nodeId: string) =>
                state.nodes.find((n) => n.id === nodeId);
            const hasTargetEdge = (node: Node<JourneyNodeData>) =>
                targetEdge(node) !== undefined;
            const hasSourceEdge = (node: Node<JourneyNodeData>) =>
                sourceEdge(node) !== undefined;

            const isBetweenPlaceholderNode = (node: Node<JourneyNodeData>) =>
                isPlaceholderNode(node) &&
                hasTargetEdge(node) &&
                hasSourceEdge(node);

            const isLeafPlaceholderNode = (node: Node<JourneyNodeData>) =>
                isPlaceholderNode(node) &&
                hasTargetEdge(node) &&
                !hasSourceEdge(node);

            const hasPlaceholderNodes = state.nodes.some(isPlaceholderNode);

            if (!hasPlaceholderNodes) {
                return state;
            }
            // Convert split and leaf placeholder nodes to ghost nodes
            const convertToGhostNode = state.nodes
                .filter((eachNode) => {
                    if (isPlaceholderNode(eachNode)) {
                        // Check if it's a leaf node
                        if (isLeafPlaceholderNode(eachNode)) {
                            return true;
                        }
                        // Check if it's under a split block
                        const edge = state.edges.find(
                            (eachEdge) => eachEdge.target === eachNode.id,
                        );
                        const parentNode =
                            state.journeyPayload.config?.nodes.find(
                                (node) => node.id === edge?.source,
                            );
                        if (isSplitBlock(parentNode)) {
                            return true;
                        }
                    }
                    return false;
                })
                .map((node) => ({
                    id: node.id,
                    type: JourneyNodeEnum.GHOSTNODE,
                    position: node.position,
                    data: {
                        type: JourneyNodeEnum.GHOSTNODE,
                        nodeId: node.id,
                    },
                }));

            // Collect IDs of nodes to be converted to ghost nodes
            const convertToGhostNodeIds = convertToGhostNode.map(
                (node) => node.id,
            );

            // Only remove placeholder nodes that aren't being converted to ghost nodes
            const nodesToRemoveIds = [
                ...state.nodes
                    .filter(
                        (eachNode) =>
                            isPlaceholderNode(eachNode) &&
                            !convertToGhostNodeIds.includes(eachNode.id),
                    )
                    .map((node) => node.id),
            ];

            // Filter out edges connected to nodes that should be removed
            const filteredNodes = state.nodes.filter(
                (node) =>
                    !isPlaceholderNode(node) ||
                    convertToGhostNodeIds.includes(node.id),
            );
            const placeholdeBetweenNodes = state.nodes.filter(
                isBetweenPlaceholderNode,
            );

            const filteredEdges = state.edges.filter((edge) => {
                return (
                    !nodesToRemoveIds.includes(edge.source) &&
                    !nodesToRemoveIds.includes(edge.target)
                );
            });

            const addionalEdges = placeholdeBetweenNodes
                .map((node) => {
                    const sourceNodeData = nodeById(
                        targetEdge(node)?.source ?? '',
                    );
                    const targetNodeData = nodeById(
                        sourceEdge(node)?.target ?? '',
                    );

                    if (!sourceNodeData || !targetNodeData) return undefined;
                    if (sourceNodeData.id === targetNodeData.id)
                        return undefined;
                    return {
                        id: getEdgeId(sourceNodeData.id, targetNodeData.id),
                        source: sourceNodeData.id,
                        target: targetNodeData.id,
                        type: JourneyEdgeEnum.BLOCK,
                    };
                })
                .filter((edge) => edge !== undefined);

            return {
                ...state,
                nodes: [
                    ...filteredNodes,
                    ...(convertToGhostNode as Node<JourneyNodeData>[]),
                ],
                edges: [...filteredEdges, ...addionalEdges] as Edge[],
            };
        }

        case ActionType.ADD_PLACEHOLDER_NODE_BETWEEN: {
            const { edgeId } = action.payload;

            const edge = state.edges.find((e) => e.id === edgeId);
            if (!edge) return state;

            const sourceNode = state.nodes.find((n) => n.id === edge.source);
            const targetNode = state.nodes.find((n) => n.id === edge.target);
            if (!sourceNode || !targetNode) return state;

            const placeholderNodeId = generateShortUUID();

            const placeholderNode: Node<JourneyNodeData> = {
                id: placeholderNodeId,
                position: {
                    x: (sourceNode.position.x + targetNode.position.x) / 2,
                    y: (sourceNode.position.y + targetNode.position.y) / 2,
                },
                type: JourneyNodeEnum.PLACEHOLDER,
                data: {
                    type: JourneyNodeEnum.PLACEHOLDER,
                    placeHolderType: JourneyNodeEnum.BLOCK,
                },
            };

            const newEdge1: Edge = {
                id: getEdgeId(edge.source, placeholderNodeId),
                source: edge.source,
                target: placeholderNodeId,
                type: JourneyEdgeEnum.BLOCK,
            };

            const newEdge2: Edge = {
                id: getEdgeId(placeholderNodeId, edge.target),
                source: placeholderNodeId,
                target: edge.target,
                type: JourneyEdgeEnum.BLOCK,
            };
            return {
                ...state,
                nodes: [...state.nodes, placeholderNode],
                edges: [
                    ...state.edges.filter((e) => e.id !== edgeId),
                    newEdge1,
                    newEdge2,
                ],
                controlPanel: {
                    isOpen: true,
                    type: ControlPanel.BLOCKS_LIST,
                    reactFlowNodeId: placeholderNodeId,
                },
            };
        }

        case ActionType.UPDATE_JOURNEY_PAYLOAD: {
            const { payload } = action;
            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    ...payload,
                },
            };
        }

        case ActionType.SET_EXIT_TRIGGERS: {
            const { payload } = action;
            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    triggers: {
                        ...state.journeyPayload.triggers!,
                        exit: payload,
                    },
                },
            };
        }

        case ActionType.UPDATE_EXIT_NODE: {
            const { payload } = action;

            const exitTriggers = state.journeyPayload.triggers?.exit;

            if (!exitTriggers) return state;

            const exitTriggerNode = exitTriggers.find(
                (trigger) => trigger.id === payload.id,
            );

            if (!exitTriggerNode) return state;

            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,

                    triggers: {
                        ...state.journeyPayload.triggers,
                        entry: [
                            ...(state.journeyPayload.triggers?.entry ?? []),
                        ],
                        exit: exitTriggers.map((trigger) =>
                            trigger.id === payload.id
                                ? { ...trigger, ...payload.payload }
                                : trigger,
                        ),
                    },
                },
            };
        }

        case ActionType.SET_GOALS: {
            const { payload } = action;
            const conversion = state.journeyPayload.triggers?.conversion?.[0];
            if (!conversion) return state;
            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    triggers: {
                        ...state.journeyPayload.triggers!,
                        conversion: [
                            {
                                ...conversion,
                                ...payload,
                            },
                        ],
                    },
                },
            };
        }

        case ActionType.SET_NODES: {
            const { payload } = action;
            return {
                ...state,
                nodes: payload,
            };
        }

        case ActionType.SET_NODES_UNSELECTED: {
            const { payload } = action;
            return {
                ...state,
                nodes: payload.map((node) => ({ ...node, selected: false })),
            };
        }

        case ActionType.SET_ENTRY_LOGIC: {
            const { payload } = action;
            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    entryLogic: {
                        ...(state.journeyPayload.entryLogic ?? {
                            cooldown: 0,
                            contextId: ReservedEventColumns.USER_ID,
                            contextTotal: -1,
                            killExisting: true,
                            contextConcurrency: -1,
                            uiConfig: {
                                cooldownType: PeriodType.HOUR,
                            },
                        }), //This defaulting can be removed once the CreateJourney type is updated
                        ...payload,
                    },
                },
            };
        }

        case ActionType.UPDATE_TRIGGER_NODE: {
            const { payload } = action;

            if (!payload) return state;

            // Find the trigger node in the state
            const triggerNode = state.journeyPayload.triggers!.entry[0];
            if (!triggerNode) return state;

            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    triggers: {
                        ...state.journeyPayload.triggers,
                        entry: [
                            {
                                ...triggerNode,
                                ...payload,
                            },
                        ],
                    },
                },
            };
        }

        case ActionType.UPDATE_NODE_CONFIG: {
            const { payload } = action;
            const { nodeId, nodePayload } = payload;
            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    config: {
                        ...state.journeyPayload.config,
                        nodes: state.journeyPayload.config!.nodes.map((node) =>
                            node.id === nodeId
                                ? { ...node, ...nodePayload }
                                : node,
                        ),
                    },
                },
            };
        }

        case ActionType.DELETE_NODE: {
            const { nodeId } = action.payload;

            // Find the node to be deleted
            const nodeToDelete = state.nodes.find((node) => node.id === nodeId);

            if (!nodeToDelete) return state;
            if (nodeToDelete.data.type === JourneyNodeEnum.TRIGGER)
                return state;

            // Find the parent node of the node to be deleted
            const parentEdge = state.edges.find(
                (edge) => edge.target === nodeId,
            );
            const parentNodeId = parentEdge ? parentEdge.source : null;

            // Find all children of the node to be deleted
            const childEdges = state.edges.filter(
                (edge) => edge.source === nodeId,
            );
            const childNodeIds = childEdges.map((edge) => edge.target);

            // Remove the node and its edges from the state
            const updatedNodes = state.nodes.filter(
                (node) => node.id !== nodeId,
            );
            const updatedEdges = state.edges.filter(
                (edge) => edge.source !== nodeId && edge.target !== nodeId,
            );

            // Attach all children to the parent node
            const newEdges = childNodeIds.map((childNodeId) => ({
                id: getEdgeId(parentNodeId!, childNodeId),
                source: parentNodeId!,
                target: childNodeId,
                type: JourneyEdgeEnum.BLOCK,
            }));

            // Update the branchConfig of the parent node to include the children of the deleted node
            const updatedConfigNodes: JourneyNode[] = state.journeyPayload
                .config!.nodes.map((node) => {
                    if (node.id === parentNodeId) {
                        const updatedBranchConfig = {
                            ...node.branchConfig,
                            type: BranchConditionalTypes.IFIF,
                            children: {
                                type: BranchConcurrencyTypes.SEQUENTIAL,
                                ...node.branchConfig?.children,
                                branches: [
                                    ...(node.branchConfig?.children.branches.filter(
                                        (branch) =>
                                            branch.destination !== nodeId, // Remove reference to deleted node
                                    ) ?? []),
                                    ...childNodeIds.map((childNodeId) => ({
                                        destination: childNodeId,
                                        isDefault: false,
                                    })),
                                ],
                            },
                        };
                        return {
                            ...node,
                            branchConfig: updatedBranchConfig,
                        };
                    }
                    return node;
                })
                .filter((node) => node.id !== nodeId);

            // Remove the deleted node from the branchConfig of its children
            const finalConfigNodes: JourneyNode[] = updatedConfigNodes.map(
                (node) => {
                    if (childNodeIds.includes(node.id)) {
                        const updatedBranchConfig = {
                            ...node.branchConfig,
                            type: BranchConditionalTypes.IFIF,
                            children: {
                                type: BranchConcurrencyTypes.SEQUENTIAL,
                                ...node.branchConfig?.children,
                                branches:
                                    node.branchConfig?.children?.branches.filter(
                                        (branch) =>
                                            branch.destination !== nodeId,
                                    ) ?? [],
                            },
                        };
                        return {
                            ...node,
                            branchConfig: updatedBranchConfig,
                        };
                    }
                    return node;
                },
            );

            return {
                ...state,
                nodes: updatedNodes,
                edges: [...updatedEdges, ...newEdges],
                journeyPayload: {
                    ...state.journeyPayload,
                    config: {
                        ...state.journeyPayload.config,
                        nodes: finalConfigNodes,
                    },
                },
            };
        }
        case ActionType.ADD_EDGE_WITH_GHOST_NODE: {
            const { nodeId, branch, edgeName } = action.payload;
            return getGhostNodeAndEdge({ nodeId, branch, state, edgeName });
        }
        case ActionType.UPDATE_BRANCH_CONFIG: {
            const { payload } = action;
            const { nodeId, updatedBranchConfig } = payload;
            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    config: {
                        ...state.journeyPayload.config,
                        nodes: state.journeyPayload.config!.nodes.map((node) =>
                            node.id === nodeId
                                ? { ...node, branchConfig: updatedBranchConfig }
                                : node,
                        ),
                    },
                },
            };
        }
        case ActionType.UPDATE_SPLIT_ACTIVE_FIELDS: {
            const { nodeId, activeField, isJourneyField } = action.payload;
            return {
                ...state,
                splitActiveFields: {
                    ...state.splitActiveFields,
                    [nodeId]: {
                        isJourneyField,
                        field: activeField,
                    },
                },
            };
        }
        case ActionType.DELETE_ALL_CHILD_BRANCHES: {
            const { nodeId } = action.payload;
            const { edges } = state;
            // Function to recursively collect all child node IDs

            // Get all child node IDs recursively
            const allChildNodeIds = getAllChildNodeIds(nodeId, edges);

            // Filter out edges that connect to any of the child nodes
            const newEdges = state.edges.filter(
                (edge) =>
                    !allChildNodeIds.includes(edge.source) &&
                    !allChildNodeIds.includes(edge.target),
            );

            // Filter out nodes that are in the list of child node IDs
            const newNodes = state.nodes.filter(
                (node) => !allChildNodeIds.includes(node.id),
            );

            // Remove nodes from journeyNodes if needed
            const newJourneyNodes = state.journeyPayload.config!.nodes.filter(
                (node) => !allChildNodeIds.includes(node.id),
            );

            return {
                ...state,
                edges: newEdges,
                nodes: newNodes,
                journeyPayload: {
                    ...state.journeyPayload,
                    config: {
                        ...state.journeyPayload.config,
                        nodes: newJourneyNodes,
                    },
                },
            };
        }
        case ActionType.UPDATE_BRANCHING_EDGE_LABEL: {
            const { edgeId, label } = action.payload;
            return {
                ...state,
                edges: state.edges.map((edge) =>
                    edge.id === edgeId ? { ...edge, label } : edge,
                ),
            };
        }
        case ActionType.UPDATE_NODE_DESCRIPTION: {
            const { nodeId, nodeDescription } = action.payload;
            const updatedNodes = state.journeyPayload.config!.nodes.map(
                (node) =>
                    node.id === nodeId
                        ? { ...node, description: nodeDescription }
                        : node,
            );
            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    config: {
                        ...state.journeyPayload.config,
                        nodes: updatedNodes,
                    },
                },
            };
        }

        case ActionType.RESET_REDUCER_STATE: {
            return action.payload;
        }
        case ActionType.CREATE_EVERY_ONE_ELSE_PATH: {
            const { nodeId, blockId } = action.payload;
            const sourceNode = state.nodes.find((node) => node.id === nodeId);
            const block = state.blocksList.find(
                (eachBlock) => eachBlock.id === blockId,
            );
            if (!sourceNode || !block) return state;
            const ghostNodeId = generateShortUUID();
            const ghostNode: Node<JourneyNodeData> = {
                id: ghostNodeId,
                position: {
                    x: sourceNode.position.x + 100,
                    y: sourceNode.position.y,
                },
                type: JourneyNodeEnum.GHOSTNODE,
                data: {
                    type: JourneyNodeEnum.EVERY_ONE_ELSE,
                    nodeId: ghostNodeId,
                },
            };

            const newEdge = {
                id: getEdgeId(nodeId, ghostNodeId),
                source: nodeId,
                target: ghostNodeId,
                type: JourneyEdgeEnum.DEFAULT,
                label:
                    getEdgeLabel(block?.actions)?.everyoneElseLabel.length > 0
                        ? `${
                              block.actions[0].actionType ===
                              JourneyActionType.SPLIT
                                  ? 1
                                  : 2
                          } ${getEdgeLabel(block?.actions)?.everyoneElseLabel}`
                        : '',
            };
            return {
                ...state,
                nodes: [...state.nodes, ghostNode],
                edges: [...state.edges, newEdge],
            };
        }
        case ActionType.ADD_GHOST_NODE_WITH_BRANCHING_EDGE: {
            const { nodeId, blockId } = action.payload;
            const block = state.blocksList.find(
                (eachBlock) => eachBlock.id === blockId,
            );
            const sourceNode = state.nodes.find((node) => node.id === nodeId);
            if (!sourceNode || !block) return state;
            const ghostNodeId = generateShortUUID();
            const ghostNode: Node<JourneyNodeData> = {
                id: ghostNodeId,
                position: {
                    x: sourceNode.position.x + 100,
                    y: sourceNode.position.y,
                },
                type: JourneyNodeEnum.GHOSTNODE,
                data: {
                    type: JourneyNodeEnum.GHOSTNODE,
                    nodeId: ghostNodeId,
                },
            };
            const newEdge = {
                id: getEdgeId(nodeId, ghostNodeId),
                source: nodeId,
                target: ghostNodeId,
                type: JourneyEdgeEnum.BRANCHING,
                label:
                    getEdgeLabel(block?.actions)?.ifLabel.length > 0
                        ? `1 ${getEdgeLabel(block?.actions)?.ifLabel}`
                        : '',
            };
            return {
                ...state,
                nodes: [...state.nodes, ghostNode],
                edges: [...state.edges, newEdge],
            };
        }

        default:
            return state;
    }
}

const JourneyBuilderProvider: React.FC<
    React.PropsWithChildren<{
        initialState: JourneyReducerState;
        isEditable: boolean;
        uuid: string | undefined;
        journeyEvents: JourneyEventMapperSchema[] | undefined;
        journeyStatus: JourneyStatus;
    }>
> = ({ initialState, children, isEditable, uuid, journeyEvents }) => {
    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const [journeyAnalytics, setJourneyAnalytics] = useState<
        JourneyAnalytics[] | undefined
    >(undefined);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();
    const { showToastError } = useToaster();
    const { t } = useLocale();

    const {
        mutateAsync: mutateAsyncCreateJourney,
        isLoading: isCreatingJourney,
    } = useCreateJourney();

    const {
        mutateAsync: mutateJourneyAnalytics,
        isLoading: isLoadingJourneyAnalytics,
    } = useGetJourneyAnalytics(uuid ?? '');

    const {
        mutateAsync: mutateAsyncUpdateJourney,
        isLoading: isUpdatingJourney,
    } = useUpdateJourney(uuid ?? '');

    const { mutateAsync: activateJourney, isLoading: isActivatingJourney } =
        useActivateJourney();

    const { mutateAsync: generateNodeDescription } =
        useGenerateNodeDescription();

    useEffect(() => {
        dispatch({
            type: ActionType.RESET_REDUCER_STATE,
            payload: initialState,
        });
    }, [initialState]);

    const addNode = useCallback((blockId: string, reactFlowNodeId: string) => {
        dispatch({
            type: ActionType.ADD_NODE,
            payload: { blockId, reactFlowNodeId },
        });
    }, []);

    const openControlPanel = useCallback((props: ControlPanelState) => {
        dispatch({ type: ActionType.OPEN_CONTROL_PANEL, payload: props });
    }, []);

    const callGenerateNodeDescription = useCallback(
        async (nodeId: string) => {
            if (!nodeId) return;
            if (!isEditable) return;
            const selectedNode = reducerState.journeyPayload.config?.nodes.find(
                (node) => node.id === nodeId,
            );
            if (!selectedNode) return;
            if (selectedNode.description) return;

            const nodeDescriptionPayload: JourneyNodeDescriptionRequest = {
                nodeId: nodeId,
                journeyDataSchema: reducerState.journeyPayload,
            };
            const response = await generateNodeDescription(
                nodeDescriptionPayload,
            );
            if (!response || !response.nodeDescription) return;
            dispatch({
                type: ActionType.UPDATE_NODE_DESCRIPTION,
                payload: {
                    nodeId,
                    nodeDescription: response.nodeDescription,
                },
            });
        },
        [generateNodeDescription, reducerState.journeyPayload, isEditable],
    );

    const closeControlPanel = useCallback(() => {
        const nodeId = reducerState.controlPanel.isOpen
            ? reducerState.controlPanel.type === ControlPanel.BLOCK_CONFIG
                ? reducerState.controlPanel.nodeId
                : ''
            : '';
        void callGenerateNodeDescription(nodeId);
        dispatch({ type: ActionType.CLOSE_CONTROL_PANEL });
    }, [callGenerateNodeDescription, reducerState.controlPanel]);

    const addTriggerNode = useCallback(
        (
            payload: Pick<
                BaseTrigger,
                'eventName' | 'eventSource' | 'isBusinessEventTrigger'
            >,
        ) => {
            dispatch({ type: ActionType.ADD_TRIGGER_NODE, payload: payload });
        },
        [],
    );

    const addPlaceholderNode = useCallback((nodeId?: string) => {
        dispatch({
            type: ActionType.ADD_PLACEHOLDER_NODE,
            payload: { nodeId },
        });
    }, []);

    const updateNodeActionConfig = useCallback(
        (nodeId: string, updatedAction: JourneyAction) => {
            dispatch({
                type: ActionType.UPDATE_NODE_ACTION_CONFIG,
                payload: { nodeId, updatedAction },
            });
        },
        [],
    );

    const removePlaceholderNodes = useCallback(() => {
        dispatch({ type: ActionType.REMOVE_PLACEHOLDER_NODES });
    }, []);

    const addPlaceholderNodeBetween = useCallback(
        (edgeId: string) => {
            removePlaceholderNodes();
            dispatch({
                type: ActionType.ADD_PLACEHOLDER_NODE_BETWEEN,
                payload: { edgeId },
            });
        },
        [removePlaceholderNodes],
    );

    const updateJourneyPayload = useCallback((payload: Partial<Journey>) => {
        dispatch({ type: ActionType.UPDATE_JOURNEY_PAYLOAD, payload });
    }, []);

    const setExitTriggers = useCallback(
        (payload: JourneyTriggerConfig['exit']) => {
            dispatch({ type: ActionType.SET_EXIT_TRIGGERS, payload: payload });
        },
        [],
    );

    const updateExitNode = useCallback(
        (payload: { id: string; payload: Partial<BaseTrigger> }) => {
            dispatch({ type: ActionType.UPDATE_EXIT_NODE, payload: payload });
        },
        [],
    );

    const setNodes = useCallback((nodes: Node<JourneyNodeData>[]) => {
        dispatch({ type: ActionType.SET_NODES, payload: nodes });
    }, []);

    const setNodesUnselected = useCallback((nodes: Node<JourneyNodeData>[]) => {
        dispatch({ type: ActionType.SET_NODES_UNSELECTED, payload: nodes });
    }, []);

    const setGoals = useCallback((payload: Partial<ConversionTrigger>) => {
        dispatch({ type: ActionType.SET_GOALS, payload: payload });
    }, []);
    const setEntryLogic = useCallback((payload: Partial<JourneyEntryLogic>) => {
        dispatch({ type: ActionType.SET_ENTRY_LOGIC, payload });
    }, []);
    const addEdgeWithGhostNode = useCallback(
        (
            nodeId: string,
            branch: Branch | undefined,
            edgeName: string | undefined,
        ) => {
            dispatch({
                type: ActionType.ADD_EDGE_WITH_GHOST_NODE,
                payload: { nodeId, branch, edgeName },
            });
        },
        [],
    );

    const callCreateJourney = useCallback(
        async (redirectOnSuccess: boolean = true) => {
            //Info: Do not allow journey creation if journey uuid is already present
            if (uuid) return;

            const response = await mutateAsyncCreateJourney(
                reducerState.journeyPayload,
            );
            const journeyUuid = response.id;
            if (redirectOnSuccess)
                void navigate(
                    `/projects/${projectUuid}/journeys/${journeyUuid}/${JourneyBuilderMode.EDIT}`,
                );
            return response;
        },
        [
            uuid,
            mutateAsyncCreateJourney,
            reducerState.journeyPayload,
            navigate,
            projectUuid,
        ],
    );

    const callUpdateJourney = useCallback(async () => {
        if (!uuid) return;
        const data = await mutateAsyncUpdateJourney(
            reducerState.journeyPayload,
        );
        await queryClient.invalidateQueries({
            queryKey: [QueryKeys.GET_JOURNEY_BY_ID, uuid],
        });
        return data;
    }, [
        uuid,
        mutateAsyncUpdateJourney,
        reducerState.journeyPayload,
        queryClient,
    ]);

    const mutateAsyncJourney = useCallback(
        async (redirectOnSuccess: boolean) => {
            const trigger = reducerState.journeyPayload.triggers?.entry[0];

            if (!trigger || (trigger && hasTriggerNodeError(trigger))) {
                showToastError({
                    title: t('journey_builder.trigger_node_error'),
                });
                return;
            }

            //Info: If journey uuid is present, update the journey, otherwise create a new journey
            if (uuid) {
                const res = await callUpdateJourney();
                return res;
            }

            const res = await callCreateJourney(redirectOnSuccess);
            return res;
        },
        [
            callCreateJourney,
            callUpdateJourney,
            reducerState.journeyPayload,
            showToastError,
            t,
            uuid,
        ],
    );

    const mutateActivateJourney = useCallback(
        async (payload: JourneyPublishConfig) => {
            const data = await mutateAsyncJourney(false);

            if (data) {
                await activateJourney({
                    data: payload,
                    uuid: data && data.id,
                });
                void navigate(`/projects/${projectUuid}/journeys`);
            }
        },
        [activateJourney, navigate, mutateAsyncJourney, projectUuid],
    );

    const canSave = useCallback((): boolean => {
        if (!reducerState.journeyPayload.name) {
            return false;
        }
        const triggerBlock = reducerState.journeyPayload.triggers?.entry[0];
        if (!triggerBlock || hasTriggerNodeError(triggerBlock)) {
            return false;
        }
        return true;
    }, [
        reducerState.journeyPayload.name,
        reducerState.journeyPayload.triggers?.entry,
    ]);

    const canLaunch = useCallback(() => {
        if (!canSave()) return false;
        if (
            reducerState.journeyPayload.entryLogic &&
            hasEntryLogicError(reducerState.journeyPayload.entryLogic)
        ) {
            return false;
        }
        if (
            reducerState.journeyPayload.config &&
            reducerState.journeyPayload.config.nodes.some((node) =>
                hasNodeError(
                    node.actions,
                    reducerState.journeyPayload.config?.nodes,
                    node.id,
                ),
            )
        ) {
            return false;
        }
        return true;
    }, [
        canSave,
        reducerState.journeyPayload.config,
        reducerState.journeyPayload.entryLogic,
    ]);

    const updateTriggerNode = useCallback((payload: Partial<BaseTrigger>) => {
        dispatch({ type: ActionType.UPDATE_TRIGGER_NODE, payload });
    }, []);

    const updateNodeConfig = useCallback(
        (payload: JourneyNodeUpdatePayload) => {
            dispatch({
                type: ActionType.UPDATE_NODE_CONFIG,
                payload: payload,
            });
        },
        [],
    );

    const deleteNode = useCallback(
        (nodeId: string) => {
            dispatch({
                type: ActionType.DELETE_NODE,
                payload: { nodeId },
            });
            closeControlPanel();
        },
        [closeControlPanel],
    );

    const updateBranchConfig = useCallback(
        (nodeId: string, updatedBranchConfig: BranchConfig) => {
            dispatch({
                type: ActionType.UPDATE_BRANCH_CONFIG,
                payload: { nodeId, updatedBranchConfig },
            });
        },
        [],
    );
    const updateSplitActiveFields = useCallback(
        (
            nodeId: string,
            activeField: FilterableField | undefined,
            isJourneyField: boolean,
        ) => {
            dispatch({
                type: ActionType.UPDATE_SPLIT_ACTIVE_FIELDS,
                payload: { nodeId, activeField, isJourneyField },
            });
        },
        [],
    );
    const deleteAllChildBranches = useCallback((nodeId: string) => {
        dispatch({
            type: ActionType.DELETE_ALL_CHILD_BRANCHES,
            payload: { nodeId },
        });
    }, []);
    const updateBranchingEdgeLabel = useCallback(
        (edgeId: string, label: string) => {
            dispatch({
                type: ActionType.UPDATE_BRANCHING_EDGE_LABEL,
                payload: { edgeId, label },
            });
        },
        [],
    );

    const createEveryOneElsePath = useCallback(
        (nodeId: string, blockId: string) => {
            dispatch({
                type: ActionType.CREATE_EVERY_ONE_ELSE_PATH,
                payload: { nodeId, blockId },
            });
        },
        [],
    );
    const addGhostNodeWithBranchingEdge = useCallback(
        (nodeId: string, blockId: string) => {
            dispatch({
                type: ActionType.ADD_GHOST_NODE_WITH_BRANCHING_EDGE,
                payload: { nodeId, blockId },
            });
        },
        [],
    );

    const fetchJourneyAnalytics = useCallback(async () => {
        const data = await mutateJourneyAnalytics();
        setJourneyAnalytics(
            processJourneyAnalytics(data.rows, reducerState.journeyPayload),
        );
    }, [mutateJourneyAnalytics, reducerState.journeyPayload]);

    const getTriggerType = useCallback(() => {
        const triggerEventName =
            reducerState.journeyPayload.triggers?.entry[0]?.eventName;
        const triggerEventSource =
            reducerState.journeyPayload.triggers?.entry[0]?.eventSource;
        const triggerEvent =
            journeyEvents &&
            journeyEvents.find(
                (event) =>
                    event.eventName === triggerEventName &&
                    event.source === triggerEventSource,
            );
        if (
            triggerEvent?.mapperSchema &&
            ReservedEventColumns.USER_ID in triggerEvent?.mapperSchema
        )
            return TriggerType.USER_ACTION;
        if (
            triggerEvent?.mapperSchema &&
            !(ReservedEventColumns.USER_ID in triggerEvent?.mapperSchema)
        )
            return TriggerType.BUSINESS_EVENT;
    }, [journeyEvents, reducerState.journeyPayload.triggers?.entry]);

    useEffect(() => {
        if (
            uuid &&
            (reducerState.journeyStatus === JourneyStatus.ACTIVE ||
                reducerState.journeyStatus === JourneyStatus.CANCELED)
        ) {
            void fetchJourneyAnalytics();
        }
    }, [uuid, fetchJourneyAnalytics, reducerState.journeyStatus]);

    const state: JourneyBuilderState = useMemo(
        () => ({
            ...reducerState,
            isLoading:
                isCreatingJourney || isUpdatingJourney || isActivatingJourney,
            uuid,
            initialJourneyPayload: initialState.journeyPayload,
            isEditable,
            journeyEvents,
            journeyAnalytics,
            isLoadingJourneyAnalytics,
        }),
        [
            reducerState,
            isCreatingJourney,
            isUpdatingJourney,
            isActivatingJourney,
            uuid,
            initialState.journeyPayload,
            isEditable,
            journeyEvents,
            journeyAnalytics,
            isLoadingJourneyAnalytics,
        ],
    );

    const actions = useMemo(
        () => ({
            addNode,
            openControlPanel,
            closeControlPanel,
            addTriggerNode,
            addPlaceholderNode,
            updateNodeActionConfig,
            removePlaceholderNodes,
            addPlaceholderNodeBetween,
            updateJourneyPayload,
            setExitTriggers,
            updateExitNode,
            setNodes,
            setNodesUnselected,
            setGoals,
            setEntryLogic,
            mutateAsyncJourney,
            updateTriggerNode,
            mutateActivateJourney,
            updateNodeConfig,
            deleteNode,
            canSave,
            canLaunch,
            addEdgeWithGhostNode,
            updateBranchConfig,
            updateSplitActiveFields,
            deleteAllChildBranches,
            updateBranchingEdgeLabel,
            createEveryOneElsePath,
            addGhostNodeWithBranchingEdge,
            fetchJourneyAnalytics,
            getTriggerType,
        }),
        [
            addNode,
            openControlPanel,
            closeControlPanel,
            addTriggerNode,
            addPlaceholderNode,
            updateNodeActionConfig,
            removePlaceholderNodes,
            addPlaceholderNodeBetween,
            updateJourneyPayload,
            setExitTriggers,
            updateExitNode,
            setNodes,
            setNodesUnselected,
            setGoals,
            setEntryLogic,
            mutateAsyncJourney,
            updateTriggerNode,
            mutateActivateJourney,
            updateNodeConfig,
            deleteNode,
            canSave,
            canLaunch,
            addEdgeWithGhostNode,
            updateBranchConfig,
            updateSplitActiveFields,
            deleteAllChildBranches,
            updateBranchingEdgeLabel,
            createEveryOneElsePath,
            addGhostNodeWithBranchingEdge,
            fetchJourneyAnalytics,
            getTriggerType,
        ],
    );

    const value = useMemo(
        () => ({
            state,
            actions,
        }),
        [actions, state],
    );

    return (
        <JourneyBuilderProviderContext.Provider value={value}>
            {children}
        </JourneyBuilderProviderContext.Provider>
    );
};

export default JourneyBuilderProvider;
