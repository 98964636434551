import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { type JourneyProperty } from '@components/Journeys/Builder/JourneyFilters/types';
import {
    DifferenceOperator,
    DimensionType,
    type UpdateNumberConfig,
    type UpdateTraitBaseConfig,
    type UpdateTraitConfig,
} from '@lightdash/common';
import { Flex } from '@mantine/core';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { useCallback, useMemo } from 'react';
import { getUpdateTraitConfig } from '../utils';
import SelectValueMenu from './SelectValueMenu';
import TraitValueInput from './TraitValueInput';

interface UpdateTraitValueFormProps {
    propertyItems: PropertySelectListType<JourneyProperty>[];
    traitConfig: UpdateTraitConfig;
    updateTraits: any;
    onUpdateConfigChange: (config: UpdateTraitConfig) => void;
}

const UpdateTraitValueForm: React.FC<UpdateTraitValueFormProps> = ({
    propertyItems,
    traitConfig,
    updateTraits,
    onUpdateConfigChange,
}) => {
    const { isEditable } = useJourneyBuilderContext((context) => context.state);
    const selectedTrait = useMemo(() => {
        if (!traitConfig?.length) return undefined;
        return updateTraits?.config.fieldConfig[traitConfig[0].name];
    }, [traitConfig, updateTraits?.config.fieldConfig]);
    const handleChangeOperator = useCallback(
        (value: { operator?: DifferenceOperator }) => {
            const newConfig = getUpdateTraitConfig({
                type: selectedTrait?.type as DimensionType,
                value: (traitConfig[0].updateConfig as UpdateNumberConfig)
                    .config.value,
                differenceValue: (
                    traitConfig[0].updateConfig as UpdateNumberConfig
                ).config.value,
                operator: value.operator || DifferenceOperator.ADD,
            });
            onUpdateConfigChange([
                {
                    name: traitConfig[0].name,
                    updateConfig: newConfig,
                },
            ]);
        },
        [onUpdateConfigChange, selectedTrait?.type, traitConfig],
    );
    const handleChangeValue = useCallback(
        (value: { value: any }) => {
            const newConfig = getUpdateTraitConfig({
                type: selectedTrait.type as DimensionType,
                value: value,
                differenceValue: value,
                operator: (traitConfig[0].updateConfig as UpdateNumberConfig)
                    .config.diffrence?.operator,
            });
            onUpdateConfigChange([
                {
                    name: traitConfig[0].name,
                    updateConfig: newConfig,
                },
            ]);
        },
        [onUpdateConfigChange, selectedTrait?.type, traitConfig],
    );
    const inputValue = useMemo(() => {
        if (
            selectedTrait?.type === DimensionType.NUMBER &&
            (traitConfig[0].updateConfig as UpdateNumberConfig).config.diffrence
                ?.value
        ) {
            return (traitConfig[0].updateConfig as UpdateNumberConfig).config
                .diffrence?.value;
        }
        return (traitConfig[0].updateConfig as UpdateTraitBaseConfig).config
            .value;
    }, [selectedTrait?.type, traitConfig]);

    return (
        <Flex gap={4} align={'center'} className={'text-gray-600'}>
            <SelectValueMenu
                operator={
                    (traitConfig[0].updateConfig as UpdateNumberConfig).config
                        .diffrence?.operator
                }
                type={selectedTrait?.type}
                onChange={handleChangeOperator}
                isDisabled={!isEditable}
            />
            <TraitValueInput
                propertyItems={propertyItems}
                value={inputValue}
                onChange={handleChangeValue}
                type={selectedTrait?.type}
            />
        </Flex>
    );
};

export default UpdateTraitValueForm;
