import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import UnsavedChangesModal from '@components/common/modal/UnsavedChangesModal';
import { FormContainer } from '@components/ProjectConnection/ProjectConnection.styles';
import useNotify from '@hooks/toaster/useNotify';
import { useCreateBlobMutation, useUpdateBlobMutation } from '@hooks/useBlob';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import {
    BlobStorageType,
    friendlyName,
    ProjectSettings,
    type BlobConfig,
} from '@lightdash/common';
import { Button } from '@mantine/core';
import useApp from '@providers/App/useApp';
import useProjectContext from '@providers/Project/useProjectContext';
import useTracking from '@providers/Tracking/useTracking';
import { removeEmptyParams } from '@utils/helpers';
import React, { useMemo } from 'react';
import { useForm, useWatch, type FieldErrors } from 'react-hook-form';
import {
    type SubmitErrorHandler,
    type SubmitHandler,
} from 'react-hook-form/dist/types/form';
import { useNavigate, useParams } from 'react-router';
import { EventName } from 'types/Events';
import BlobStorageForms from './BlobStorageForms';
import WarehouseStorageForms from './WarehouseStorageForms';

const useOnBlobError = (): SubmitErrorHandler<BlobConfig> => {
    const { showToastError } = useNotify();
    const { t } = useLocale();
    return async (errors: FieldErrors<BlobConfig>) => {
        if (!errors) {
            showToastError({
                title: t('blob_storage_form.form_error_title'),
                subtitle: t('blob_storage_form.form_error_subtitle'),
            });
        } else {
            const errorMessages: string[] = Object.values(errors).reduce<
                string[]
            >((acc, section) => {
                const sectionErrors = Object.entries(section || {}).map(
                    ([key, { message }]) => `${friendlyName(key)}: ${message}`,
                );
                return [...acc, ...sectionErrors];
            }, []);
            showToastError({
                title: t('blob_storage_form.form_error_title'),
                subtitle: errorMessages.join('\n\n'),
            });
        }
    };
};

export const UpdateBlobStorage: React.FC<{}> = () => {
    const { projectData } = useProjectContext();
    const { user } = useApp();
    const ability = useAbilityContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const updateMutation = useUpdateBlobMutation();
    const { mutateAsync, isLoading: isSaving } = updateMutation;
    const isDisabled =
        isSaving ||
        ability.cannot(
            'update',
            subject(ProjectSettings.blobStorage, {
                organizationUuid: user.data?.organizationUuid,
                projectUuid,
            }),
        );
    const defaultValues: BlobConfig = useMemo(() => {
        return {
            blobStorageConfig: projectData?.blobConnection?.blobStorageConfig,
            warehouseConfig: projectData?.blobConnection?.warehouseConfig,
        } as BlobConfig;
    }, [projectData]);
    const methods = useForm<BlobConfig>({
        defaultValues,
    });
    const { control, handleSubmit, reset } = methods;
    const watchFields = useWatch<BlobConfig>({
        control,
    });
    const hasFormChanged = useIsEqual(
        removeEmptyParams(defaultValues),
        removeEmptyParams(watchFields),
    );
    const { track } = useTracking();
    const submitForm: SubmitHandler<BlobConfig> = async (formData) => {
        track({
            name: EventName.UPDATE_BLOB_CONFIG_BUTTON_CLICKED,
        });
        await mutateAsync(formData);
    };
    const onSubmit = () => {
        void handleSubmit(submitForm)();
    };

    return (
        <>
            <FormContainer
                name="update_blob_storage"
                id="update_blob_storage"
                onSubmit={onSubmit}
                methods={methods}
            >
                <BlobStorageForms
                    disabled={isDisabled}
                    selectedBlob={
                        projectData?.blobConnection?.blobStorageConfig
                            ?.blobStorageType ?? BlobStorageType.S3
                    }
                />
                <WarehouseStorageForms
                    disabled={isDisabled}
                    selectedWarehouse={
                        projectData?.blobConnection?.warehouseConfig
                            ?.warehouseType
                    }
                />
            </FormContainer>
            <UnsavedChangesModal
                opened={hasFormChanged}
                secondaryActionButtonClick={() => reset()}
                disableButtons={isSaving}
                form="update_blob_storage"
                type="submit"
            />
        </>
    );
};

interface CreateBlobStorageProps {
    selectedBlob: BlobStorageType;
}

const CreateBlobStorage: React.FC<CreateBlobStorageProps> = ({
    selectedBlob,
}) => {
    const { t } = useLocale();
    const { projectData } = useProjectContext();
    const { isLoading: isSaving, mutateAsync } = useCreateBlobMutation();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const navigate = useNavigate();
    const onError = useOnBlobError();
    const methods = useForm<BlobConfig>({
        defaultValues: {
            blobStorageConfig: {},
            warehouseConfig: {},
        },
    });

    const onSubmit = async ({
        blobStorageConfig,
        warehouseConfig,
    }: BlobConfig) => {
        const payload: BlobConfig = {
            blobStorageConfig,
            warehouseConfig,
        };

        await mutateAsync(payload, {
            onSuccess: () => {
                void navigate(`/projects/${projectUuid}/relations/create`);
            },
        });
        // window.location.href = '/';
    };

    return (
        <FormContainer
            name="create_blob_storage"
            methods={methods}
            onSubmit={onSubmit}
            onError={onError}
        >
            <BlobStorageForms disabled={false} selectedBlob={selectedBlob} />
            <WarehouseStorageForms
                disabled={false}
                selectedWarehouse={projectData?.warehouseConnection?.type}
            />
            <Button
                sx={{ alignSelf: 'start' }}
                type="submit"
                loading={isSaving}
            >
                {t('blob_storage_form.compile_button_text')}
            </Button>
        </FormContainer>
    );
};

export default CreateBlobStorage;
