import { useLocale } from '@hooks/useLocale';
import { usePasswordResetLinkMutation } from '@hooks/usePasswordReset';
import { validateEmail } from '@lightdash/common';
import { Anchor, Button, Center, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import useApp from '@providers/App/useApp';
import { type FC } from 'react';
import { Link } from 'react-router';

type RecoverPasswordForm = { email: string };

export const PasswordRecoveryForm: FC = () => {
    const { t } = useLocale();
    const { health } = useApp();
    const form = useForm<RecoverPasswordForm>({
        initialValues: {
            email: '',
        },
        validate: {
            email: (value: string) =>
                validateEmail(value) ? null : 'Your email address is not valid',
        },
    });

    const { isLoading, isSuccess, mutate, reset } =
        usePasswordResetLinkMutation();

    return (
        <div className="w-full">
            {!isSuccess ? (
                <>
                    <form
                        name="password-recovery"
                        onSubmit={form.onSubmit((values) => mutate(values))}
                    >
                        <Stack>
                            <TextInput
                                label={t('common.email_label')}
                                name="email"
                                placeholder={t('common.email_placeholder')}
                                required
                                {...form.getInputProps('email')}
                                disabled={isLoading || isSuccess}
                                withAsterisk={false}
                            />

                            <Button
                                type="submit"
                                loading={isLoading}
                                className="py-3 rounded-lg"
                            >
                                {t('password_recovery.submit_button')}
                            </Button>
                            {!health.data?.isAuthenticated && (
                                <Center>
                                    <Anchor
                                        component={Link}
                                        to="/login"
                                        className="text-sm font-medium text-gray-600"
                                    >
                                        {t('password_recovery.back_button')}
                                    </Anchor>
                                </Center>
                            )}
                        </Stack>
                    </form>
                </>
            ) : (
                <div className="flex flex-col items-center gap-3">
                    <p className="p-4 text-sm text-center text-gray-800 bg-shade-2 border-base">
                        {t('password_recovery.sent_message')}{' '}
                        <span className="font-semibold">
                            {form.values.email}
                        </span>
                    </p>

                    <Anchor
                        component={Link}
                        to="/login"
                        className="w-full hover:no-underline"
                    >
                        <Button
                            type="submit"
                            className="w-full py-3 rounded-lg"
                            onClick={() => {
                                reset();
                                form.reset();
                            }}
                        >
                            {t('password_recovery.back_to_login')}
                        </Button>
                    </Anchor>
                </div>
            )}
        </div>
    );
};
