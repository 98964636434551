import LoadingState from '@components/common/LoadingState';
import TimeFilterMenu from '@components/common/TimeFilterMenu';
import useNotify from '@hooks/toaster/useNotify';
import { useCampaigns } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { useGetUserHistory } from '@hooks/useProfile';
import {
    ReservedCampaignEventColumns,
    ReservedEventColumns,
    type ApiSqlQueryResults,
} from '@lightdash/common';
import {
    Accordion,
    Box,
    Button,
    Flex,
    Input,
    Loader as LoaderIcon,
    Stack,
    Text,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import {
    CalendarBlank,
    CaretDown,
    Copy,
    Info,
    MagnifyingGlass,
    PaperPlaneTilt,
} from '@phosphor-icons/react';
import {
    PROFILE_ACTIVITY_PAGE_SIZE,
    SEARCH_INPUT_DEBOUNCE_TIME,
} from '@utils/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AceEditor from 'react-ace';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { ButtonVariant } from '../../../../mantineTheme';
import {
    formatDate,
    getEventTableName,
    getFormattedTime,
    getUserHistoryFilter,
    INNER_CONTAINER_CLASS,
} from '../utils';

const ProfileActivity = () => {
    const { showToastSuccess } = useNotify();
    const { t } = useLocale();

    const [activeAccordion, setActiveAccordion] = useState<string | null>(null);

    const [offset, setOffset] = useState<number>(0);
    const [searchString, setSearchString] = useState<string>('');
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
    const [debouncedSearchString] = useDebouncedValue(
        searchString,
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [timeFilter, setTimeFilter] = useState<{
        startTimestamp?: string;
        endTimestamp?: string;
    }>({});
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { profileId } = useParams<{ profileId: string }>();
    const [accumulatedUserHistory, setAccumulatedUserHistory] = useState<
        ApiSqlQueryResults | undefined
    >(undefined);
    const { data: campaigns, isLoading: isCampaignsLoading } = useCampaigns({
        perPage: 9999,
        currentPage: 1,
        polling: false,
    });

    const filterForProfileSearch = useMemo(
        () =>
            getUserHistoryFilter(
                projectUuid,
                profileId,
                debouncedSearchString,
                timeFilter.startTimestamp,
                timeFilter.endTimestamp,
            ),
        [projectUuid, profileId, debouncedSearchString, timeFilter],
    );

    const {
        data: userHistory,
        isLoading,
        isFetching,
    } = useGetUserHistory(projectUuid, {
        limit: PROFILE_ACTIVITY_PAGE_SIZE,
        offset,
        filters: filterForProfileSearch ?? undefined,
        sorts: [
            {
                fieldId: `${getEventTableName(projectUuid)}_${
                    ReservedEventColumns.SERVER_TIMESTAMP
                }`,
                descending: true,
            },
        ],
    });

    useEffect(() => {
        if (userHistory) {
            setAccumulatedUserHistory((prev) => ({
                ...prev,
                rows: [...(prev?.rows ?? []), ...userHistory.rows],
                fields: prev?.fields ?? userHistory.fields,
            }));
        }
    }, [userHistory]);

    const prepareVisibleActivities = useCallback(() => {
        return accumulatedUserHistory?.rows.map((item, index, array) => {
            const currentDate = new Date(
                item[
                    `${getEventTableName(projectUuid)}_${
                        ReservedEventColumns.SERVER_TIMESTAMP
                    }` as string
                ] as string,
            ).toLocaleDateString();
            const previousDate =
                index > 0
                    ? new Date(
                          array[index - 1][
                              `${getEventTableName(projectUuid)}_${
                                  ReservedEventColumns.SERVER_TIMESTAMP
                              }` as string
                          ] as string,
                      ).toLocaleDateString()
                    : null;
            const showDateHeader = index === 0 || currentDate !== previousDate;
            const campainId = item[
                `${getEventTableName(projectUuid)}_${
                    ReservedCampaignEventColumns.CUSTOM_ID
                }` as string
            ] as string;
            const campaignName = campaigns?.data?.find(
                (campaign) => campaign.id === campainId,
            )?.name;

            return {
                id: uuidv4(),
                timestamp: item[
                    `${getEventTableName(projectUuid)}_${
                        ReservedEventColumns.SERVER_TIMESTAMP
                    }` as string
                ] as string,
                name: item[
                    `${getEventTableName(projectUuid)}_${
                        ReservedEventColumns.EVENT_NAME
                    }` as string
                ] as string,
                payload: JSON.parse(
                    item[
                        `${getEventTableName(projectUuid)}_${
                            ReservedEventColumns.RAW_PAYLOAD
                        }` as string
                    ] as string as string,
                ),
                showDateHeader,
                currentDate,
                campaignName,
            };
        });
    }, [projectUuid, accumulatedUserHistory, campaigns?.data]);

    const visibleActivities = useMemo(
        () => prepareVisibleActivities(),
        [prepareVisibleActivities],
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
        setOffset(0);
        setAccumulatedUserHistory(undefined);
        setIsLoadingMore(false);
        setActiveAccordion(null);
    };

    const handleTimeFilterChange = useCallback(
        (newTimeFilter: { startTimestamp?: number; endTimestamp?: number }) => {
            setTimeFilter({
                startTimestamp: newTimeFilter.startTimestamp
                    ? new Date(newTimeFilter.startTimestamp).toISOString()
                    : undefined,
                endTimestamp: newTimeFilter.endTimestamp
                    ? new Date(newTimeFilter.endTimestamp).toISOString()
                    : undefined,
            });
            setOffset(0);
            setAccumulatedUserHistory(undefined);
            setIsLoadingMore(false);
            setActiveAccordion(null);
        },
        [],
    );

    const toggleAccordion = (id: string) => {
        setActiveAccordion((prev) => (prev === id ? null : id));
    };

    const loadMoreActivities = () => {
        if (
            userHistory &&
            userHistory.rows.length === PROFILE_ACTIVITY_PAGE_SIZE
        ) {
            setOffset((prevOffset) => prevOffset + PROFILE_ACTIVITY_PAGE_SIZE);
            setIsLoadingMore(true);
            setActiveAccordion(null);
        }
    };

    return (
        <Box className={INNER_CONTAINER_CLASS}>
            <Stack>
                <Box>
                    <Flex
                        gap={0}
                        justify="space-between"
                        align="center"
                        className="p-3 pb-2"
                    >
                        <Input
                            placeholder={t(
                                'profiles_event_history.search_placeholder',
                            )}
                            icon={
                                <MagnifyingGlass
                                    weight="duotone"
                                    color="rgb(var(--color-gray-500))"
                                />
                            }
                            size="xs"
                            value={searchString}
                            onChange={handleSearchChange}
                        />

                        <TimeFilterMenu
                            onTimeFilterChange={handleTimeFilterChange}
                        />
                    </Flex>
                    <Box className="p-3 pb-2 font-semibold border-b border-shade-6">
                        <Flex
                            gap={4}
                            align="center"
                            className="p-2 text-sm text-gray-800 border rounded-lg border-shade-6"
                        >
                            <Info
                                weight="duotone"
                                color="rgb(var(--color-gray-800))"
                            />
                            <Text className="">
                                {t('profiles_event_history.info_text')}
                            </Text>
                        </Flex>
                    </Box>
                </Box>
                <Box className="p-3 !h-[65vh] overflow-auto">
                    {(isLoading || isFetching || isCampaignsLoading) &&
                    !isLoadingMore ? (
                        <LoadingState title={''} />
                    ) : accumulatedUserHistory?.rows.length === 0 ? (
                        <Text className="flex items-center justify-center h-full text-center text-gray-500">
                            {t('profiles_event_history.no_events')}
                        </Text>
                    ) : (
                        <>
                            <Accordion
                                variant="filled"
                                styles={{
                                    item: {
                                        backgroundColor:
                                            'transparent !important',
                                        '&[data-active]': {
                                            border: '1px solid rgb(var(--color-gray-200)) !important',
                                        },
                                    },
                                }}
                                chevron={null}
                            >
                                {visibleActivities?.map((item, index) => (
                                    <Box key={item.id}>
                                        {item.showDateHeader && (
                                            <Flex
                                                align="center"
                                                gap={4}
                                                className={`mb-2 text-sm font-medium text-gray-800 ${
                                                    index !== 0
                                                        ? 'border-t pt-2'
                                                        : ''
                                                } border-shade-6`}
                                            >
                                                <CalendarBlank
                                                    weight="duotone"
                                                    color="rgb(var(--color-gray-800))"
                                                />
                                                <Text>
                                                    {formatDate(
                                                        item.currentDate,
                                                    )}
                                                </Text>
                                            </Flex>
                                        )}
                                        <Accordion.Item
                                            value={item.id.toString()}
                                        >
                                            <Accordion.Control
                                                className="hover:bg-shade-4"
                                                onClick={() =>
                                                    toggleAccordion(
                                                        item.id.toString(),
                                                    )
                                                }
                                            >
                                                <Flex gap={12} align="center">
                                                    <Text className="text-sm text-gray-500">
                                                        {getFormattedTime(
                                                            item.timestamp,
                                                        )}
                                                    </Text>
                                                    <Text className="text-sm font-medium text-gray-800">
                                                        {item.name}
                                                    </Text>
                                                    {item.campaignName && (
                                                        <Flex
                                                            gap={4}
                                                            align="center"
                                                        >
                                                            <PaperPlaneTilt
                                                                weight="duotone"
                                                                color="rgb(var(--color-gray-400))"
                                                            />
                                                            <Text className="text-sm font-medium text-gray-400">
                                                                {
                                                                    item.campaignName
                                                                }
                                                            </Text>
                                                        </Flex>
                                                    )}
                                                    <CaretDown
                                                        weight="duotone"
                                                        className={`transition-transform duration-300 ${
                                                            activeAccordion ===
                                                            item.id.toString()
                                                                ? 'rotate-180'
                                                                : ''
                                                        }`}
                                                    />
                                                </Flex>
                                            </Accordion.Control>
                                            {activeAccordion ===
                                                item.id.toString() && (
                                                <Accordion.Panel className="ps-[4.875rem]">
                                                    <Box className="w-full border rounded border-shade-6 ">
                                                        <Box className="flex justify-between px-3.5 border-b border-shade-6 items-center h-[2.8rem]">
                                                            <Text className="text-sm font-medium">
                                                                {t(
                                                                    'common.payload',
                                                                )}
                                                            </Text>
                                                            <Button
                                                                leftIcon={
                                                                    <Copy
                                                                        weight="duotone"
                                                                        color="rgb(var(--color-blu-800))"
                                                                    />
                                                                }
                                                                variant={
                                                                    ButtonVariant.SUBTLE_ACCENTED
                                                                }
                                                                onClick={() => {
                                                                    void navigator.clipboard.writeText(
                                                                        JSON.stringify(
                                                                            item.payload,
                                                                            null,
                                                                            2,
                                                                        ),
                                                                    );
                                                                    showToastSuccess(
                                                                        {
                                                                            title: t(
                                                                                'common.copied',
                                                                            ),
                                                                            autoClose: 1000,
                                                                        },
                                                                    );
                                                                }}
                                                            >
                                                                {t(
                                                                    'common.copy_json',
                                                                )}
                                                            </Button>
                                                        </Box>
                                                        <Box className="px-2 py-2">
                                                            <AceEditor
                                                                value={JSON.stringify(
                                                                    item.payload,
                                                                    null,
                                                                    2,
                                                                )}
                                                                readOnly
                                                                width="100%"
                                                                minLines={1}
                                                                maxLines={100}
                                                                setOptions={{
                                                                    tabSize: 2,
                                                                    lineHeight: 19,
                                                                }}
                                                                mode="json"
                                                                theme="textmate"
                                                                name="json-editor"
                                                                showGutter={
                                                                    false
                                                                }
                                                                highlightActiveLine={
                                                                    false
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Accordion.Panel>
                                            )}
                                        </Accordion.Item>
                                    </Box>
                                ))}
                            </Accordion>
                            {userHistory &&
                                userHistory.rows.length ===
                                    PROFILE_ACTIVITY_PAGE_SIZE && (
                                    <Button
                                        onClick={loadMoreActivities}
                                        variant={ButtonVariant.SUBTLE_ACCENTED}
                                        className="mt-4"
                                        leftIcon={
                                            isLoadingMore &&
                                            (isLoading || isFetching) ? (
                                                <LoaderIcon
                                                    color="rgb(var(--color-blu-800))"
                                                    size={14}
                                                />
                                            ) : null
                                        }
                                        disabled={
                                            isLoadingMore &&
                                            (isLoading || isFetching)
                                        }
                                    >
                                        {isLoadingMore &&
                                        (isLoading || isFetching)
                                            ? t('common.loading')
                                            : t('common.load_more')}
                                    </Button>
                                )}
                        </>
                    )}
                </Box>
            </Stack>
        </Box>
    );
};

export default ProfileActivity;
