import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type ApiOrganizationMemberProfiles,
    type KnexPaginateArgs,
    type OrganizationMemberProfile,
    type OrganizationMemberProfileUpdate,
} from '@lightdash/common';
import {
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
    type UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import Fuse from 'fuse.js';
import { QueryKeys } from 'types/UseQuery';
import useApp from '../providers/App/useApp';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';
import useQueryError from './useQueryError';

const getOrganizationUsersQuery = async (
    includeGroups?: number,
    paginateArgs?: KnexPaginateArgs,
    searchQuery?: string,
    projectUuid?: string,
) => {
    const urlParams = new URLSearchParams({
        ...(paginateArgs
            ? {
                  page: String(paginateArgs.page),
                  pageSize: String(paginateArgs.pageSize),
              }
            : {}),
        ...(includeGroups ? { includeGroups: String(includeGroups) } : {}),
        ...(searchQuery ? { searchQuery } : {}),
        ...(projectUuid ? { projectUuid } : {}),
    }).toString();

    return sortmentApi<ApiOrganizationMemberProfiles['results']>({
        url: `/org/users${urlParams ? `?${urlParams}` : ''}`,
        method: 'GET',
        body: undefined,
    });
};

const deleteUserQuery = async (id: string) =>
    sortmentApi<null>({
        //no response from backended
        url: `/org/user/${id}`,
        method: 'DELETE',
        body: undefined,
    });

const updateUser = async (id: string, data: OrganizationMemberProfileUpdate) =>
    sortmentApi<null>({
        // no response from backened
        url: `/org/users/${id}`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });
const getOrganizationUserByIdQuery = async (id: string) => {
    return sortmentApi<OrganizationMemberProfile>({
        url: `/org/users/${id}`,
        method: 'GET',
        body: undefined,
    });
};

export const useOrganizationUserById = (id: string) => {
    const setErrorResponse = useQueryError();
    return useQuery<OrganizationMemberProfile, ApiError>({
        queryKey: [QueryKeys.ORGANIZATION_USER_ONE],

        queryFn: () => getOrganizationUserByIdQuery(id),
        onError: (result) => setErrorResponse(result),
        enabled: !!id,
    });
};
export const useOrganizationUsers = (params?: {
    searchInput?: string;
    includeGroups?: number;
}) => {
    const setErrorResponse = useQueryError();
    return useQuery<ApiOrganizationMemberProfiles['results']['data'], ApiError>(
        {
            queryKey: [QueryKeys.ORGANIZATION_USERS, params?.includeGroups],
            queryFn: async () => {
                return (await getOrganizationUsersQuery(params?.includeGroups))
                    .data;
            },
            onError: (result) => setErrorResponse(result),
            select: (data) => {
                if (params?.searchInput) {
                    return new Fuse(Object.values(data), {
                        keys: [
                            QueryKeys.FIRSTNAME,
                            QueryKeys.LASTNAME,
                            QueryKeys.EMAIL,
                            QueryKeys.ROLE,
                        ],
                        ignoreLocation: true,
                        threshold: 0.3,
                    })
                        .search(params.searchInput)
                        .map((result) => result.item);
                }
                return data;
            },
        },
    );
};

export const usePaginatedOrganizationUsers = ({
    searchInput,
    includeGroups,
    paginateArgs,
}: {
    searchInput?: string;
    includeGroups?: number;
    paginateArgs?: KnexPaginateArgs;
}) => {
    const setErrorResponse = useQueryError();
    return useQuery<ApiOrganizationMemberProfiles['results'], ApiError>({
        queryKey: [
            'organization_users',
            includeGroups,
            paginateArgs,
            searchInput,
        ],
        queryFn: () =>
            getOrganizationUsersQuery(includeGroups, paginateArgs, searchInput),
        onError: (result) => setErrorResponse(result),
    });
};

export const useInfiniteOrganizationUsers = (
    {
        searchInput,
        includeGroups,
        pageSize,
        projectUuid,
    }: {
        searchInput?: string;
        includeGroups?: number;
        projectUuid?: string;
        pageSize: number;
    },
    infinityQueryOpts: UseInfiniteQueryOptions<
        ApiOrganizationMemberProfiles['results'],
        ApiError
    > = {},
) => {
    const setErrorResponse = useQueryError();
    return useInfiniteQuery<ApiOrganizationMemberProfiles['results'], ApiError>(
        {
            queryKey: [
                'organization_users',
                includeGroups,
                pageSize,
                searchInput,
                projectUuid,
            ],
            queryFn: ({ pageParam }) => {
                return getOrganizationUsersQuery(
                    includeGroups,
                    {
                        pageSize: pageSize,
                        page: pageParam ?? 1,
                    },
                    searchInput,
                    projectUuid,
                );
            },
            onError: (result) => setErrorResponse(result),
            getNextPageParam: (lastPage) => {
                if (lastPage.pagination) {
                    return lastPage.pagination.page <
                        lastPage.pagination.totalPageCount
                        ? lastPage.pagination.page + 1
                        : undefined;
                }
            },
            ...infinityQueryOpts,
        },
    );
};

export const useDeleteOrganizationUserMutation = () => {
    const queryClient = useQueryClient();
    const { t } = useLocale();
    const { showToastSuccess, showToastApiError } = useNotify();
    return useMutation<null, ApiError, string>(deleteUserQuery, {
        mutationKey: [QueryKeys.ORGANIZATION_USERS_DELETE],
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKeys.ORGANIZATION_USERS]);
            showToastSuccess({
                title: t('organization_settings.delete_success_toast'),
            });
        },
        onError: ({ error }) => {
            showToastApiError({
                title: t('organization_settings.delete_error_toast'),
                apiError: error,
            });
        },
    });
};

export const useUpdateUserMutation = (userUuid: string) => {
    const queryClient = useQueryClient();
    const { user } = useApp();
    const { t } = useLocale();
    const { showToastSuccess, showToastApiError } = useNotify();
    return useMutation<null, ApiError, OrganizationMemberProfileUpdate>(
        (data) => {
            if (userUuid) {
                return updateUser(userUuid, data);
            }
            throw new Error(
                t('organization_settings.update_id_notfound_error'),
            );
        },
        {
            mutationKey: [QueryKeys.ORGANIZATION_MEMBERSHIP_ROLES],
            onSuccess: async () => {
                if (user.data?.userUuid === userUuid) {
                    await queryClient.refetchQueries([QueryKeys.USER]);
                }
                await queryClient.refetchQueries([
                    QueryKeys.ORGANIZATION_USERS,
                ]);
                showToastSuccess({
                    title: t('organization_settings.update_success_toast'),
                });
            },
            onError: ({ error }) => {
                showToastApiError({
                    title: t('organization_settings.update_error_toast'),
                    apiError: error,
                });
            },
        },
    );
};
