import { useLocale } from '@hooks/useLocale';
import { Box, Group, Stack, Text } from '@mantine/core';
import { PlayCircle } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { TriggerType } from '../../types';
import EventsList from './EventsList';
import TriggerList from './TriggerList';

const TriggerPanel: React.FC<{}> = () => {
    const { t } = useLocale();
    const [showEventsList, setShowEventsList] = useState<boolean>(false);
    const [triggerType, setTriggerType] = useState<TriggerType>(
        TriggerType.BUSINESS_EVENT,
    );

    return (
        <Stack className="relative h-full gap-0">
            <Stack className="w-full gap-1 p-3 border-b border-b-gray-200">
                <Group className="gap-1.5">
                    <PlayCircle color={'rgb(var(--color-blu-800))'} />
                    <Text className="text-sm font-medium text-gray-800">
                        {t('journey_builder.trigger_block_title')}
                    </Text>
                </Group>
                <Text className="text-sm font-medium text-gray-600">
                    {t('journey_builder.trigger_block_description')}
                </Text>
            </Stack>

            {!showEventsList && (
                <TriggerList
                    customClass="flex flex-col"
                    setShowEventsList={setShowEventsList}
                    setTriggerType={setTriggerType}
                />
            )}
            {showEventsList && (
                <Box className="flex-grow px-1.5 py-2 overflow-hidden">
                    <EventsList
                        triggerType={triggerType}
                        setShowEventsList={setShowEventsList}
                    />
                </Box>
            )}
        </Stack>
    );
};

export default React.memo(TriggerPanel);
