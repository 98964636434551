import Checkbox from '@components/common/Checkbox';
import TimeInputWithOptions from '@components/common/Time/TimeInputWithOptions';
import { useLocale } from '@hooks/useLocale';
import {
    PeriodType as PeriodTypeEnum,
    type PeriodType,
} from '@lightdash/common';
import { Box, Group, Stack, Text } from '@mantine/core';
import { SealCheck } from '@phosphor-icons/react';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

enum FormFields {
    onSwitch = 'onSwitch',
    Duration = 'duration',
    Granularity = 'granularity',
}

interface ConcludeExperimentProps {
    duration: number | undefined;
    granularity: PeriodType | undefined;
    onDurationChange: (duration: number, granularity: PeriodType) => void;
    onSwitchChange: (checked: boolean) => void;
    isEditable: boolean;
}

const ConcludeExperiment: React.FC<ConcludeExperimentProps> = ({
    isEditable,
    duration,
    granularity,
    onDurationChange,
    onSwitchChange,
}) => {
    const { t } = useLocale();

    const isConclusionEnabled = useMemo(() => {
        return (
            duration !== undefined && !Number.isNaN(duration) && duration > 0
        );
    }, [duration]);

    const {
        control,
        watch,
        formState: { errors },
        setError,
        setValue,
    } = useForm({
        defaultValues: {
            onSwitch: isConclusionEnabled,
            duration: duration,
            granularity: granularity,
        },
    });

    useEffect(() => {
        setValue(FormFields.Duration, duration);
        if (!isConclusionEnabled) return;
        if (duration === null) {
            setError(FormFields.Duration, {
                type: 'manual',
                message: t('common.error.empty_text_input'),
            });
            return;
        }
        if (duration === 0) {
            setError(FormFields.Duration, {
                type: 'manual',
                message: t('duration_delay.enter_a_valid_value'),
            });
            return;
        }
        setError(FormFields.Duration, {
            type: 'manual',
            message: '',
        });
    }, [duration, setError, setValue, t, isConclusionEnabled]);

    const onSwitch = watch(FormFields.onSwitch);

    return (
        <Stack className="gap-2">
            <Group className="gap-1">
                <SealCheck size={14} color="rgb(var(--color-gray-500))" />
                <Text className="text-xs font-medium text-gray-500 uppercase">
                    {t(
                        'journey_builder.experiment.conversion_tracking_section_header',
                    )}
                </Text>
            </Group>
            <Box>
                <Controller
                    name="onSwitch"
                    control={control}
                    render={({ field }) => (
                        <Checkbox
                            label={t(
                                'journey_builder.experiment.conversion_tracking_section_conclusion_config',
                            )}
                            checked={field.value}
                            onChange={() => {
                                field.onChange(!field.value);
                                onSwitchChange(!field.value);
                            }}
                            disabled={!isEditable}
                        />
                    )}
                />
            </Box>

            {onSwitch && (
                <Stack className={`gap-1`}>
                    <Controller
                        name={FormFields.Duration}
                        control={control}
                        render={({ field }) => (
                            <TimeInputWithOptions
                                selectedDuration={field.value}
                                onDurationChange={(
                                    newDuration,
                                    newGranularity,
                                ) => {
                                    field.onChange(newDuration);
                                    onDurationChange(
                                        newDuration,
                                        newGranularity,
                                    );
                                    if (Number.isNaN(newDuration)) {
                                        setError(FormFields.Duration, {
                                            type: 'manual',
                                            message: t(
                                                'common.error.empty_text_input',
                                            ),
                                        });
                                        return;
                                    }
                                    setError(FormFields.Duration, {
                                        type: 'manual',
                                        message: '',
                                    });
                                }}
                                selectedGranularity={
                                    granularity ?? PeriodTypeEnum.DAY
                                }
                                granularityOptions={[
                                    PeriodTypeEnum.MINUTE,
                                    PeriodTypeEnum.HOUR,
                                    PeriodTypeEnum.DAY,
                                    PeriodTypeEnum.WEEK,
                                ]}
                                error={errors.duration?.message}
                                focusOnMount={true}
                                onBlur={(value) => {
                                    if (Number.isNaN(value)) {
                                        field.onChange(null);
                                        onDurationChange(
                                            null as unknown as number,
                                            granularity ?? PeriodTypeEnum.DAY,
                                        );
                                    }
                                }}
                                disabled={!isEditable}
                            />
                        )}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default ConcludeExperiment;
