import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import { useGetCustomMetrics } from '@hooks/useCustomMetric';
import { useCanCreateCustomMetric } from '@hooks/useGetRelatedTables';
import { useLocale } from '@hooks/useLocale';
import { CommonReservedTags, type UserTraits } from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { CreateCustomMetricMenu } from '@pages/CustomMetricManager';
import useApp from '@providers/App/useApp';
import { useEffect, useMemo, useState } from 'react';
import { AlertCircle } from 'react-feather';
import { useParams } from 'react-router';
import useRelationContext from '../../providers/Relation/useRelationContext';
import CreateCustomMetric from './CreateCustomMetric';
import CustomMetricTable from './Table/CustomMetricTable';
import { CustomMetricTab } from './type';

const labelBox = (tabLabel: string, tabCount: number) => (
    <Box className="flex gap-1">
        <Text>{tabLabel}</Text>
        <Text className="text-sm text-gray-600">{tabCount}</Text>
    </Box>
);

const getTabConfig = (
    t: (key: string) => string,
    calculatedCount: number,
    dynamicCount: number,
) => [
    {
        key: CustomMetricTab.CALCULATED.toString(),
        label: labelBox(t('custom_metric.tabs.calculated'), calculatedCount),
        value: CustomMetricTab.CALCULATED.toString(),
    },
    {
        key: CustomMetricTab.DYNAMIC.toString(),
        label: labelBox(t('custom_metric.tabs.dynamic'), dynamicCount),
        value: CustomMetricTab.DYNAMIC.toString(),
    },
];

const CustomMetricManagerContent = ({
    openBaseTable,
    setOpenBaseTable,
    updateTraits,
    openUpdateTraitsModal,
}: {
    openBaseTable: boolean;
    setOpenBaseTable: React.Dispatch<React.SetStateAction<boolean>>;
    updateTraits: UserTraits | undefined;
    openUpdateTraitsModal: () => void;
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const ability = useAbilityContext();
    const { t } = useLocale();
    const { user } = useApp();
    const [selectedTab, setSelectedTab] = useState<CustomMetricTab>(
        CustomMetricTab.CALCULATED,
    );
    const {
        data: hiddenCustomMetrics,
        isLoading: isHiddenCustomMetricsLoading,
    } = useGetCustomMetrics({
        query: `includesTags=${CommonReservedTags.HIDDEN}`,
    });

    const canCreateCustomMetricAbility = ability.can(
        'create',
        subject('CustomMetric', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const { activeRelation } = useRelationContext();
    const [isHiddenActive, setIsHiddenActive] = useState<boolean>(false);
    const { data: customMetrics, isLoading: isCustomMetricsLoading } =
        useGetCustomMetrics({
            query: isHiddenActive
                ? `includesTags=${CommonReservedTags.HIDDEN}`
                : `excludesTags=${CommonReservedTags.HIDDEN}`,
        });

    useEffect(() => {
        if ((hiddenCustomMetrics?.length ?? 0) === 0) {
            setIsHiddenActive(false);
        }
    }, [hiddenCustomMetrics]);
    const canCreateCustomMetric = useCanCreateCustomMetric(activeRelation);
    const metrics = useMemo(() => {
        return {
            activePill: selectedTab,
            calculatedMetrics: customMetrics ?? [],
            dynamicMetrics: updateTraits?.config?.fieldConfig
                ? Object.entries(updateTraits.config.fieldConfig).map(
                      ([key, value]) => ({
                          ...value,
                          name: key,
                      }),
                  )
                : [],
        };
    }, [customMetrics, selectedTab, updateTraits]);
    const hasMetrics = useMemo(
        () =>
            (customMetrics?.length ?? 0) > 0 ||
            (hiddenCustomMetrics?.length ?? 0) > 0 ||
            (Object.keys(updateTraits?.config.fieldConfig ?? {}).length ?? 0) >
                0,
        [customMetrics, hiddenCustomMetrics, updateTraits],
    );
    if (!canCreateCustomMetric)
        return (
            <Flex
                justify="start"
                gap="xs"
                className="p-2 m-2 rounded-lg border border-shade-6 mx-3"
            >
                <Flex justify="center" align="center">
                    <AlertCircle
                        size={13}
                        strokeWidth={2.5}
                        color={'rgb(var(--color-gray-600))'}
                    />
                </Flex>
                <Flex direction="column">
                    <Text className="block text-gray-800">
                        {t('custom_metric.cannot_create_message')}
                    </Text>
                </Flex>
            </Flex>
        );
    if (isCustomMetricsLoading || isHiddenCustomMetricsLoading) {
        return <SuboptimalState loading />;
    }
    return (
        <>
            {Boolean(canCreateCustomMetricAbility && !hasMetrics) && (
                <CreateCustomMetricMenu
                    setOpenBaseTable={setOpenBaseTable}
                    openUpdateTraitsModal={openUpdateTraitsModal}
                />
            )}

            <CreateCustomMetric
                openBaseTable={openBaseTable}
                onModalClose={() => {
                    setOpenBaseTable(false);
                }}
                tableName={undefined}
                isDuplicate={undefined}
                isViewMode={undefined}
                customMetricData={undefined}
                customMetricId={undefined}
                disableTableChange={undefined}
            />

            {hasMetrics && (
                <CustomMetricTable
                    customMetrics={metrics.calculatedMetrics ?? []}
                    isHiddenActive={isHiddenActive}
                    onHiddenChange={setIsHiddenActive}
                    hideHiddenButton={(hiddenCustomMetrics?.length ?? 0) === 0}
                    tabs={getTabConfig(
                        t,
                        metrics.calculatedMetrics.length,
                        Object.keys(updateTraits?.config.fieldConfig ?? {})
                            .length,
                    )}
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    updateTraits={updateTraits}
                />
            )}
        </>
    );
};
export default CustomMetricManagerContent;
