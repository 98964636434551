import { type ContentStepComponentProps } from '@components/Campaigns/Builder/types';
import {
    getNextContentStep,
    getPreviousContentStep,
} from '@components/Campaigns/Builder/utils';
import Checkbox from '@components/common/Checkbox';
import { useUpdateCampaign } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    CampaignFieldType,
    CommunicationChannel,
    ContentMappingSection,
    type ContentMapping,
    type ExternalTemplate,
    type ParsedVariable,
    type Template,
} from '@lightdash/common';
import { Box, Divider, Group, Text } from '@mantine/core';
import { type TrackedLinks } from '@providers/Campaign/types';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { isEqual } from 'lodash';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    type SyntheticEvent,
} from 'react';
import { useParams } from 'react-router';
import EmailLinkTracking from './EmailLinkTracking';
import SmsLinkTracking from './SmsLinkTracking';
import WhatsappLinkTracking from './WhatsappLinkTracking';

const getUpdatedMappings = (
    contentMapping: ContentMapping,
    enabled: boolean,
) => {
    return Object.entries(contentMapping).reduce((acc, [key, variable]) => {
        return {
            ...acc,
            [key]: { ...variable, enableTracking: enabled },
        };
    }, {});
};

const LinkTracking: React.FC<ContentStepComponentProps> = ({
    templateMetadata,
    setActiveContentStep,
    activeContentStep,
}) => {
    const initialData = useRef<any>(null);
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const { t } = useLocale();
    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload } = state;
    const {
        setCurrentStepCallback,
        setPreviousStepCallback,
        setTrackedLinks,
        setContentMappings,
    } = actions;

    const { mutate: updateCampaign } = useUpdateCampaign(campaignUuid ?? '');

    useEffect(() => {
        if (initialData.current === null) {
            initialData.current = campaignPayload;
        }
    }, [campaignPayload]);

    const links = useMemo(
        () =>
            (campaignPayload?.channel === CommunicationChannel.WHATSAPP
                ? (templateMetadata as ExternalTemplate)?.variables
                : (templateMetadata as Template)?.contents[0]
                      .parsedVariables) as unknown as ParsedVariable[],
        [campaignPayload?.channel, templateMetadata],
    );

    const getTrackedLinksPayload = useCallback(
        (enableTracking: boolean) => {
            if (links) {
                const payload = links.reduce((accum, curr) => {
                    if (accum) {
                        return {
                            ...accum,
                            [curr.key]: {
                                value: curr.value,
                                defaultValue: '',
                                type: CampaignFieldType.STATIC,
                                enableTracking,
                            },
                        };
                    }
                    return {
                        [curr.key]: {
                            value: curr.value,
                            defaultValue: '',
                            type: CampaignFieldType.STATIC,
                            enableTracking,
                        },
                    };
                }, {});
                return payload;
            }
            return {};
        },
        [links],
    );

    const trackedLinks: TrackedLinks = useMemo(() => {
        if (campaignPayload?.contentMappings) {
            return Object.keys(
                campaignPayload?.contentMappings?.[
                    ContentMappingSection.LINK_TRACKING
                ] ?? {},
            )
                .filter(
                    (mapping) =>
                        campaignPayload?.contentMappings?.[
                            ContentMappingSection.LINK_TRACKING
                        ]?.[mapping]?.enableTracking !== undefined,
                )
                .reduce((accum, curr) => {
                    if (accum) {
                        return {
                            ...accum,
                            [curr]: campaignPayload?.contentMappings?.[
                                ContentMappingSection.LINK_TRACKING
                            ]?.[curr],
                        };
                    }
                    return {
                        [curr]: campaignPayload?.contentMappings?.[
                            ContentMappingSection.LINK_TRACKING
                        ]?.[curr],
                    };
                }, {});
        }
        return [];
    }, [campaignPayload?.contentMappings]);

    const handleTrackAll = (e: SyntheticEvent) => {
        if (
            campaignPayload.channel === CommunicationChannel.SMS &&
            'checked' in e.target
        ) {
            if (
                campaignPayload.contentMappings &&
                campaignPayload.contentMappings[ContentMappingSection.BODY]
            ) {
                const newMappings = getUpdatedMappings(
                    campaignPayload.contentMappings[ContentMappingSection.BODY],
                    e.target.checked as boolean,
                );
                setContentMappings({
                    ...campaignPayload.contentMappings,
                    [ContentMappingSection.BODY]: newMappings,
                });
            }
            return;
        }
        if (
            campaignPayload.channel === CommunicationChannel.WHATSAPP &&
            'checked' in e.target
        ) {
            let contentMappings = { ...campaignPayload.contentMappings };
            if (
                campaignPayload.contentMappings &&
                campaignPayload.contentMappings[ContentMappingSection.HEADER]
            ) {
                const newMappings = getUpdatedMappings(
                    campaignPayload.contentMappings[
                        ContentMappingSection.HEADER
                    ],
                    e.target.checked as boolean,
                );
                contentMappings = {
                    ...contentMappings,
                    [ContentMappingSection.HEADER]: newMappings,
                };
            }
            if (
                campaignPayload.contentMappings &&
                campaignPayload.contentMappings[ContentMappingSection.BODY]
            ) {
                const newMappings = getUpdatedMappings(
                    campaignPayload.contentMappings[ContentMappingSection.BODY],
                    e.target.checked as boolean,
                );
                contentMappings = {
                    ...contentMappings,
                    [ContentMappingSection.BODY]: newMappings,
                };
            }
            if (
                campaignPayload.contentMappings &&
                campaignPayload.contentMappings[ContentMappingSection.BUTTONS]
            ) {
                const newMappings = getUpdatedMappings(
                    campaignPayload.contentMappings[
                        ContentMappingSection.BUTTONS
                    ],
                    e.target.checked as boolean,
                );
                contentMappings = {
                    ...contentMappings,
                    [ContentMappingSection.BUTTONS]: newMappings,
                };
            }
            setContentMappings(contentMappings);
        }
        if (campaignPayload.channel === CommunicationChannel.EMAIL) {
            if ('checked' in e.target && e.target.checked && links) {
                const payload = getTrackedLinksPayload(true);
                setTrackedLinks({ ...trackedLinks, ...payload });
                return;
            }
            const payload = getTrackedLinksPayload(false);
            setTrackedLinks({ ...trackedLinks, ...payload });
        }
    };

    const nextStep = useMemo(
        () =>
            getNextContentStep(
                activeContentStep,
                campaignPayload?.channel,
                templateMetadata,
            ),
        [activeContentStep, campaignPayload?.channel, templateMetadata],
    );

    const prevStep = useMemo(
        () =>
            getPreviousContentStep(
                activeContentStep,
                campaignPayload?.channel,
                templateMetadata,
            ),
        [activeContentStep, campaignPayload?.channel, templateMetadata],
    );

    const nextStepCallback = useCallback(() => {
        if (nextStep) {
            setActiveContentStep(nextStep);
            if (
                campaignUuid &&
                !isEqual(
                    campaignPayload.contentMappings,
                    initialData.current.contentMappings,
                )
            ) {
                updateCampaign({
                    contentMappings: campaignPayload.contentMappings,
                });
            }
        }
    }, [
        nextStep,
        setActiveContentStep,
        campaignUuid,
        updateCampaign,
        campaignPayload.contentMappings,
    ]);

    const prevStepCallback = useCallback(() => {
        if (prevStep) {
            setActiveContentStep(prevStep);
        }
    }, [prevStep, setActiveContentStep]);

    useEffect(() => {
        setCurrentStepCallback({
            callback: nextStepCallback,
            skipExecutionAfterCallback: Boolean(nextStep),
        });
    }, [nextStepCallback, nextStep, setCurrentStepCallback]);

    useEffect(() => {
        setPreviousStepCallback({
            callback: prevStepCallback,
            skipExecutionAfterCallback: Boolean(prevStep),
        });
    }, [prevStepCallback, prevStep, setPreviousStepCallback]);

    useEffect(() => {
        if (links && campaignPayload.channel === CommunicationChannel.EMAIL) {
            const payload = getTrackedLinksPayload(true);
            setTrackedLinks({ ...trackedLinks, ...payload });
        }
        // INFO - Added this because we want to call this hook only at the first render
        // and not any sub sequent update of trackedLinks
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTrackedLinksPayload, links, setTrackedLinks]);

    const isAllLinksTracked = useMemo(() => {
        if (campaignPayload.channel === CommunicationChannel.SMS) {
            if (
                campaignPayload.contentMappings &&
                campaignPayload.contentMappings[ContentMappingSection.BODY]
            ) {
                return Object.values(
                    campaignPayload.contentMappings[ContentMappingSection.BODY],
                ).every((variable) => variable.enableTracking);
            }
            return false;
        }
        if (campaignPayload.channel === CommunicationChannel.EMAIL) {
            if (
                campaignPayload.contentMappings &&
                campaignPayload.contentMappings[
                    ContentMappingSection.LINK_TRACKING
                ]
            ) {
                return Object.values(
                    campaignPayload.contentMappings[
                        ContentMappingSection.LINK_TRACKING
                    ],
                ).every((variable) => variable.enableTracking);
            }
            return false;
        }
        if (campaignPayload.channel === CommunicationChannel.WHATSAPP) {
            let allVariablesTracked = true;
            if (
                campaignPayload.contentMappings &&
                campaignPayload.contentMappings[ContentMappingSection.HEADER]
            ) {
                allVariablesTracked = Object.values(
                    campaignPayload.contentMappings[
                        ContentMappingSection.HEADER
                    ],
                ).every((variable) => variable.enableTracking);
                if (!allVariablesTracked) {
                    return false;
                }
            }
            if (
                campaignPayload.contentMappings &&
                campaignPayload.contentMappings[ContentMappingSection.BODY]
            ) {
                allVariablesTracked = Object.values(
                    campaignPayload.contentMappings[ContentMappingSection.BODY],
                ).every((variable) => variable.enableTracking);
                if (!allVariablesTracked) {
                    return false;
                }
            }
            if (
                campaignPayload.contentMappings &&
                campaignPayload.contentMappings[ContentMappingSection.BUTTONS]
            ) {
                allVariablesTracked = Object.values(
                    campaignPayload.contentMappings[
                        ContentMappingSection.BUTTONS
                    ],
                ).every((variable) => variable.enableTracking);
                if (!allVariablesTracked) {
                    return false;
                }
            }
            return allVariablesTracked;
        }
        return false;
    }, [campaignPayload.channel, campaignPayload.contentMappings]);

    const renderLinks = useCallback(() => {
        switch (campaignPayload.channel) {
            case CommunicationChannel.SMS:
                return (
                    <SmsLinkTracking
                        contentMappings={campaignPayload.contentMappings ?? {}}
                        setContentMappings={setContentMappings}
                    />
                );
            case CommunicationChannel.WHATSAPP:
                return (
                    <WhatsappLinkTracking
                        contentMappings={campaignPayload.contentMappings ?? {}}
                        setContentMappings={setContentMappings}
                    />
                );
            case CommunicationChannel.EMAIL:
            default:
                return (
                    <EmailLinkTracking templateMetadata={templateMetadata} />
                );
        }
    }, [
        campaignPayload.channel,
        campaignPayload.contentMappings,
        setContentMappings,
        templateMetadata,
    ]);

    return (
        <Box>
            <Text>{t('campaigns_builder.tracking.link_tracking')}</Text>
            <Text className="mt-1 text-xs font-medium text-gray-600">
                {t('campaigns_builder.tracking.link_tracking_description')}
            </Text>
            <Box className="border-base p-[6px] mt-3 max-h-[550px] overflow-scroll">
                <Group className="py-[6px] px-3">
                    <Checkbox
                        checked={isAllLinksTracked}
                        onClick={handleTrackAll}
                    />
                    <Text>All links</Text>
                </Group>
                <Divider size="md" className="mt-2 mb-2 border-t-shade-2" />
                {renderLinks()}
            </Box>
        </Box>
    );
};

export default LinkTracking;
