import { useLocale } from '@hooks/useLocale';
import { Box, Button, Menu } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { CalendarBlank, CaretLeft } from '@phosphor-icons/react';
import { useCallback, useMemo, useState } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import {
    SegmentedTimeFilterOptions,
    SegmentedTimeFilterOptionsValues,
} from '../SegmentedTimeFilter/types';
import { getTimestampForOption } from '../SegmentedTimeFilter/utils';

interface TimeFilterMenuProps {
    onTimeFilterChange: (
        timeFilter: {
            startTimestamp: number | undefined;
            endTimestamp: number | undefined;
        },
        newFilter?: SegmentedTimeFilterOptions,
    ) => void;
    filter?: SegmentedTimeFilterOptions;
}

const TimeFilterMenu: React.FC<TimeFilterMenuProps> = ({
    onTimeFilterChange,
    filter,
}) => {
    const { t } = useLocale();
    const [isCustomTimeFilterOpened, setIsCustomTimeFilterOpened] =
        useState(false);
    const [selectedRange, setSelectedRange] = useState<
        [Date | null, Date | null]
    >([null, null]);
    const [opened, { close, open }] = useDisclosure(false);
    const [selectedFilter, setSelectedFilter] =
        useState<SegmentedTimeFilterOptions | null>(filter || null);

    const handleTimeFilterChange = useCallback(
        (timeFilter: SegmentedTimeFilterOptions) => {
            const timestampRange = getTimestampForOption(timeFilter);
            onTimeFilterChange(
                {
                    startTimestamp: timestampRange?.[0],
                    endTimestamp: timestampRange?.[1],
                },
                timeFilter,
            );
            setSelectedRange([null, null]);
            setSelectedFilter(timeFilter);
            close();
        },
        [onTimeFilterChange, close, setSelectedRange],
    );

    const handleDateRangeChange = (range: [Date | null, Date | null]) => {
        setSelectedRange(range);
        if (range[0] && range[1]) {
            onTimeFilterChange(
                {
                    startTimestamp: range[0].getTime(),
                    endTimestamp: range[1].getTime(),
                },
                SegmentedTimeFilterOptions.CUSTOM,
            );
            setSelectedFilter(SegmentedTimeFilterOptions.CUSTOM);
            close();
        }
    };
    const isCustomTimeFilterSelected = useMemo(() => {
        if (!selectedFilter) return false;
        if (selectedFilter === SegmentedTimeFilterOptions.CUSTOM)
            return selectedRange[0] !== null && selectedRange[1] !== null;
        return selectedFilter !== SegmentedTimeFilterOptions.ALL_TIME;
    }, [selectedFilter, selectedRange]);

    const formatDateRange = (range: [Date | null, Date | null]) => {
        if (!range[0] || !range[1]) return '';
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        };
        const startDate = range[0].toLocaleDateString(undefined, options);
        const endDate = range[1].toLocaleDateString(undefined, options);
        return `${startDate} - ${endDate}`;
    };

    return (
        <Menu
            onClose={() => {
                close();
            }}
            opened={opened}
        >
            <Menu.Target>
                <Button
                    variant={ButtonVariant.OUTLINED}
                    leftIcon={<CalendarBlank weight="duotone" />}
                    onClick={() => open()}
                >
                    {isCustomTimeFilterSelected &&
                    selectedFilter === SegmentedTimeFilterOptions.CUSTOM
                        ? formatDateRange(selectedRange)
                        : isCustomTimeFilterSelected && selectedFilter
                        ? SegmentedTimeFilterOptionsValues[selectedFilter]
                        : t('common.all_time')}
                </Button>
            </Menu.Target>
            <Menu.Dropdown>
                {isCustomTimeFilterOpened ? (
                    <Box>
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={() => setIsCustomTimeFilterOpened(false)}
                            leftIcon={<CaretLeft />}
                        >
                            {t('common.back')}
                        </Button>
                        <DatePicker
                            type="range"
                            value={selectedRange}
                            onChange={handleDateRangeChange}
                        />
                    </Box>
                ) : (
                    <>
                        <Box
                            onClick={() => setIsCustomTimeFilterOpened(true)}
                            className="cursor-pointer py-1.5 px-3 text-sm text-gray-800 hover:bg-shade-6 font-medium rounded"
                        >
                            {
                                SegmentedTimeFilterOptionsValues[
                                    SegmentedTimeFilterOptions.CUSTOM
                                ]
                            }
                        </Box>
                        {Object.values(SegmentedTimeFilterOptions)
                            .filter(
                                (option) =>
                                    option !==
                                    SegmentedTimeFilterOptions.CUSTOM,
                            )
                            .map((option) => (
                                <Menu.Item
                                    key={option}
                                    onClick={() =>
                                        handleTimeFilterChange(option)
                                    }
                                    className={`hover:bg-shade-6 ${
                                        selectedFilter === option
                                            ? 'bg-shade-6'
                                            : ''
                                    }`}
                                >
                                    {SegmentedTimeFilterOptionsValues[option]}
                                </Menu.Item>
                            ))}
                    </>
                )}
            </Menu.Dropdown>
        </Menu>
    );
};

export default TimeFilterMenu;
