import {
    type AllIntegrationChannels,
    type Integration,
} from '@lightdash/common';

export const CHANNEL_SETUP_STATES = {
    SELECT_PROVIDER: 'SELECT_PROVIDER',
    ADD_CHANNEL_DETAILS: 'ADD_CHANNEL_DETAILS',
    UPDATE_CHANNEL_DETAILS: 'UPDATE_CHANNEL_DETAILS',
};

export interface ChannelContextProps {
    state: {
        title: string;
        subtitle: string | undefined;
        showProviderConfigModal: boolean;
        showSetupModal: boolean;
        allChannels: AllIntegrationChannels | undefined;
        selectedChannel: string;
        providers: Integration[] | undefined;
        selectedProvider: Integration | undefined;
        searchText: string | undefined;
        allowChannelSetup: boolean;
        channelSetupState: string;
    };
    actions: {
        handleUpdateAllChannels: (data: AllIntegrationChannels) => void;
        handleShowProviderConfigModal: (show: boolean) => void;
        handleShowSetUpModal: (show: boolean) => void;
        handleIntegratedProvider: (data: Integration[]) => void;
        handleProviderSelect: (
            data: Integration | undefined,
            callback: boolean,
        ) => void;
        handleSearchKeyText: (key: string) => void;
        handleShowSetupFlow: (show: boolean) => void;
        updateChannelSetupStateAction: (val: string) => void;
    };
}

export enum ActionType {
    SET_SHOW_PROVIDER_CONFIG_MODAL,
    SET_SHOW_SETUP_MODAL,
    SET_ALL_CHANNELS,
    SET_INTEGRATED_PROVIDER,
    SET_SELECTED_PROVIDER,
    SET_SEARCH_KEY,
    SET_SHOW_SETUP,
    UPDATE_CHANNEL_SETUP_STATE,
}

export type ChannelProviderData = {
    title: string;
    subtitle: string | undefined;
    showProviderConfigModal: boolean;
    showSetupModal: boolean;
    allChannels: AllIntegrationChannels | undefined;
    selectedChannel: string;
    providers: Integration[] | undefined;
    selectedProvider: Integration | undefined;
    searchText: string | undefined;
    allowChannelSetup: boolean;
    handleProviderSelectCallback: undefined | ((obj: Integration) => void);
    channelSetupState: string;
};
