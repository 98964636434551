import { useLocale } from '@hooks/useLocale';
import { type UserTraitFieldConfig, type UserTraits } from '@lightdash/common';
import { Badge, Flex, Menu } from '@mantine/core';
import { CaretDown } from '@phosphor-icons/react';
import UpdateTraitListItem from './UpdateTraitValueForm/UpdateTraitListItem';
type UpdateTraitMenuProps = {
    selectedTrait: UserTraitFieldConfig;
    onSelectTrait: (trait: UserTraitFieldConfig) => void;
    userTraits: UserTraits;
    isDisabled: boolean;
    isCreatedOnFly: boolean;
};

const UpdateTraitMenu = ({
    selectedTrait,
    onSelectTrait,
    userTraits,
    isDisabled,
    isCreatedOnFly,
}: UpdateTraitMenuProps) => {
    const { t } = useLocale();
    return (
        <Menu>
            <Menu.Target>
                <button
                    className="px-2 !w-fit !h-9"
                    style={{
                        borderRadius: '8px',
                        background: 'white',
                        border: '1px solid',
                        borderColor: 'rgba(0, 0, 0, 0.06)',
                        boxShadow: `0px -1px 4px 0px rgba(0, 0, 0, 0.06) inset`,
                        fontSize: '14px',
                        color: 'rgb(var(--color-gray-600))',
                        width: 'wrap-content !important',
                        cursor: isDisabled ? 'auto' : 'pointer',
                        minWidth: '1rem',
                        minHeight: '2rem',
                    }}
                    form="some_random"
                    disabled={isDisabled}
                >
                    <Flex
                        justify="space-between"
                        align="center"
                        key={selectedTrait?.label}
                    >
                        {isCreatedOnFly && (
                            <Badge className="p-1 m-0 rounded leading-4 bg-blu-800 text-white">
                                {t('event_create.properties_create_new_label')}
                            </Badge>
                        )}
                        <UpdateTraitListItem
                            trait={selectedTrait}
                            onSelectTrait={undefined}
                        />
                        <CaretDown
                            color={'rgb(var(--color-gray-600)'}
                            weight={'regular'}
                        />
                    </Flex>
                </button>
            </Menu.Target>
            <Menu.Dropdown style={{ maxHeight: '300px', overflow: 'scroll' }}>
                {Object.entries(userTraits.config.fieldConfig).map(
                    ([key, trait]) => (
                        <Menu.Item
                            key={key}
                            onClick={() => onSelectTrait(trait)}
                        >
                            <UpdateTraitListItem
                                trait={trait}
                                onSelectTrait={undefined}
                            />
                        </Menu.Item>
                    ),
                )}
            </Menu.Dropdown>
        </Menu>
    );
};

export default UpdateTraitMenu;
