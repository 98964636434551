import { PeriodType } from '@lightdash/common';
const millisecondsInMinute = 60000;
const millisecondsInHour = 3600000;
const millisecondsInDay = 86400000;
const millisecondsInWeek = 604800000;
const millisecondsInMonth = 2629746000;

/**
 * Converts a duration in milliseconds to a specific period type value.
 *
 * @param {number} milliseconds - The duration in milliseconds.
 * @param {PeriodType} type - The period type to convert to (e.g., minute, hour, day, week, month).
 * @returns {number} - The duration value in the specified period type.
 */
export const getDurationValue = (
    milliseconds: number,
    type: PeriodType,
): number => {
    switch (type) {
        case PeriodType.MINUTE:
            return milliseconds / millisecondsInMinute;
        case PeriodType.HOUR:
            return milliseconds / millisecondsInHour;
        case PeriodType.DAY:
            return milliseconds / millisecondsInDay;
        case PeriodType.WEEK:
            return milliseconds / millisecondsInWeek;
        case PeriodType.MONTH:
            return milliseconds / millisecondsInMonth;
        default:
            return milliseconds;
    }
};

/**
 * Converts a duration value to milliseconds based on the specified period type.
 *
 * @param {number} value - The duration value to convert.
 * @param {PeriodType} type - The period type (e.g., minute, hour, day, week, month).
 * @returns {number} - The duration in milliseconds.
 */
export const getMilliseconds = (value: number, type: PeriodType): number => {
    switch (type) {
        case PeriodType.MINUTE:
            return value * millisecondsInMinute;
        case PeriodType.HOUR:
            return value * millisecondsInHour;
        case PeriodType.DAY:
            return value * millisecondsInDay;
        case PeriodType.WEEK:
            return value * millisecondsInWeek;
        case PeriodType.MONTH:
            return value * millisecondsInMonth;

        default:
            return value;
    }
};
