import { useContextSelector } from 'use-context-selector';
import CampaignProviderContext from './context';
import { type CampaignContext } from './types';

function useCampaignContext<Selected>(
    selector: (value: CampaignContext) => Selected,
) {
    return useContextSelector(CampaignProviderContext, (context) => {
        if (context === undefined) {
            throw new Error(
                'useCampaign must be used within a CampaignProvider',
            );
        }
        return selector(context);
    });
}

export default useCampaignContext;
