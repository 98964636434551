import { Box, List as MantineList } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import React, { useCallback, useRef } from 'react';
import { VariableSizeList } from 'react-window';

interface CustomListItemProps<T> {
    icon?: React.ReactNode;
    onClick?: (data: T) => void;
    data: T;
    style?: React.CSSProperties;
}

export const CustomListItem = <T,>({
    style,
    icon = <CaretRight weight="bold" />,
    children,
    onClick,
    data,
}: React.PropsWithChildren<CustomListItemProps<T>>) => {
    return (
        <Box
            sx={{
                position: 'relative',
                '&:hover .icon': {
                    opacity: 1,
                },
            }}
            className="w-full rounded cursor-pointer hover:bg-gray-50 p-2.5"
            onClick={() => onClick && onClick(data)}
            style={style}
        >
            {children}

            <Box
                className="icon"
                sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    opacity: 0,
                    transition: 'opacity 0.1s ease-in-out',
                }}
            >
                {icon}
            </Box>
        </Box>
    );
};

interface CustomListProps<T> {
    items: Array<T>;
    itemTemplate: (item: T) => React.ReactNode;
    listHoverIcon?: React.ReactNode;
    onItemClick?: (data: T) => void;
    itemSize?: number;
}

const List = <T,>({
    items,
    itemTemplate,
    listHoverIcon,
    onItemClick,
    itemSize,
    ...props
}: CustomListProps<T>) => {
    const listRef = useRef<VariableSizeList>(null);

    const EachItem = useCallback(
        ({ index, style }: { index: number; style: React.CSSProperties }) => {
            const item = items[index];
            return (
                <CustomListItem
                    key={index}
                    style={style}
                    icon={listHoverIcon}
                    onClick={onItemClick}
                    data={item}
                >
                    {itemTemplate(item)}
                </CustomListItem>
            );
        },
        [items, itemTemplate, listHoverIcon, onItemClick],
    );

    return (
        <MantineList {...props} className="w-full">
            <VariableSizeList
                ref={listRef}
                itemCount={items.length}
                height={300}
                itemSize={() => itemSize ?? 30}
                width="100%"
                className="List !overflow-x-hidden"
            >
                {EachItem}
            </VariableSizeList>
        </MantineList>
    );
};

export default List;
