import FieldListItem from '@components/Audience/Filters/FieldListItem';
import { convertFieldsIntoPropertySelectListType } from '@components/Audience/Filters/FieldListItem/utils';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import { useLocale } from '@hooks/useLocale';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import useCustomMetricContext from '@providers/CustomMetric/useCustomMetricContext';
import { ButtonVariant } from '../../mantineTheme';

interface CustomMetricFilterPropertySelectProps {
    fields: FieldWithSuggestions[];
    onSubmit: (field: FieldWithSuggestions) => void;
    isLoading: boolean;
}

const CustomMetricFilterPropertySelect: React.FC<
    CustomMetricFilterPropertySelectProps
> = ({ fields, onSubmit, isLoading }) => {
    const { t } = useLocale();
    const [opened, { open, close }] = useDisclosure();
    const { selectedDimension, viewMode } = useCustomMetricContext(
        (context) => context.reducerState,
    );
    const filters = useCustomMetricContext(
        (context) => context.reducerState.filters,
    );
    return (
        <PropertySelect<
            FieldWithSuggestions & AddditionalPropertySelectListProps
        >
            items={convertFieldsIntoPropertySelectListType(fields, true)}
            showGroup={true}
            headerRightSection={null}
            onSubmit={(items: FieldWithSuggestions[]) => {
                if (!items[0]) return;
                close();
                onSubmit(items[0]);
            }}
            itemTemplate={({ item }) => {
                return (
                    <FieldListItem
                        item={item}
                        checked={item.isChecked ?? false}
                        showCheckbox={false}
                        disabled={item.isDisabled ?? false}
                    />
                );
            }}
            opened={opened}
            close={close}
            open={open}
            targetButton={
                <Button
                    variant={ButtonVariant.UNSTYLED}
                    leftIcon={<PlusCircle />}
                    className={`px-0 py-3  border-none ${
                        filters && Object.keys(filters).length >= 1
                            ? 'ml-20'
                            : ''
                    }`}
                    disabled={
                        isLoading ||
                        viewMode ||
                        (selectedDimension ? false : true)
                    }
                >
                    {t('custom_metric.dimension_modal.filter_label')}
                </Button>
            }
            showSearch={true}
            searchKeys={['label', 'tableLabel']}
            searchPlaceholder={t('audience_filters.search_filter_label')}
            allowMultipleSelection={false}
            withinPortal={true}
            disabled={viewMode || isLoading}
        />
    );
};
export default CustomMetricFilterPropertySelect;
