/*
This PasswordInput component should be wrapped in Controller if it is used
reat-hook-form. For the reference see packages/sortfe/src/components/ProjectConnection/WarehouseForms/SnowflakeForm.tsx
*/
import InputErrorText from '@components/common/InputErrorText';
import {
    PasswordInput as MantinePasswordInput,
    Stack,
    Text,
    type PasswordInputProps as MantinePasswordInputProps,
} from '@mantine/core';
import React from 'react';
import { Eye, EyeOff } from 'react-feather';
const PasswordInput: React.FC<MantinePasswordInputProps> = ({
    error,
    description,
    ...props
}) => {
    return (
        <Stack className="gap-1.5">
            <MantinePasswordInput
                visibilityToggleIcon={({ reveal }) =>
                    reveal ? (
                        <Eye
                            size={13}
                            strokeWidth={2.5}
                            color={'rgb(var(--color-gray-600))'}
                            className="selected__item--icon-selected"
                        />
                    ) : (
                        <EyeOff
                            size={13}
                            strokeWidth={2.5}
                            color={'rgb(var(--color-gray-600))'}
                            className="selected__item--icon-selected"
                        />
                    )
                }
                {...props}
            />
            {error && <InputErrorText value={error} />}
            {description && (
                <Text className="text-sm font-medium text-gray-500">
                    {description}
                </Text>
            )}
        </Stack>
    );
};
export default React.memo(PasswordInput);
