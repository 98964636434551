import { useLocale } from '@hooks/useLocale';
import { useContextSelector } from 'use-context-selector';
import CustomMetricContext from './context';
import { type CustomMetricContextType } from './types';

function useCustomMetricContext<Selected>(
    selector: (value: CustomMetricContextType) => Selected,
) {
    const { t } = useLocale();
    return useContextSelector(CustomMetricContext, (context) => {
        if (context === undefined) {
            throw new Error(t('error.campaign_context_not_found'));
        }
        return selector(context);
    });
}

export default useCustomMetricContext;
