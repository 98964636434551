import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import { useOrganization } from '@hooks/organization/useOrganization';
import { useCreateInviteLinkMutation } from '@hooks/useInviteLink';
import { useLocale } from '@hooks/useLocale';
import {
    useDeleteOrganizationUserMutation,
    useUpdateUserMutation,
} from '@hooks/useOrganizationUsers';
import useLogoutMutation from '@hooks/user/useUserLogoutMutation';
import {
    OrganizationMemberRole,
    type OrganizationMemberProfile,
} from '@lightdash/common';
import { Box, Button, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import useApp from '@providers/App/useApp';
import { type Row } from '@tanstack/react-table';
import posthog from 'posthog-js';
import { useCallback, useMemo } from 'react';
import { ChevronRight, Send, UserMinus, Users } from 'react-feather';
import { ButtonVariant } from '../../../mantineTheme';
import Modal from '../../common/modal/Modal';
import { ChangeMemberRole } from '../ChangeOrganizationMemberRoleModal';

interface OrganisationMenuItemProps {
    rowData: Row<OrganizationMemberProfile>;
}
export const OrganisationMenuItem: React.FC<OrganisationMenuItemProps> = ({
    rowData,
}) => {
    const { user } = useApp();
    const { data: orgData } = useOrganization();
    const { t } = useLocale();
    const [
        openedChangeMemberRole,
        { open: openChangeMemberRole, close: closeChangeMemberRole },
    ] = useDisclosure(false);
    const [
        confirmRemoveMember,
        { open: openConfirmRemoveMember, close: closeConfirmRemoveMember },
    ] = useDisclosure(false);
    const [
        confirmLeaveOrganization,
        {
            open: openConfirmLeaveOrganization,
            close: closeConfirmLeaveOrganization,
        },
    ] = useDisclosure(false);
    const [
        confirmRevokeInvite,
        { open: openConfirmRevokeInvite, close: closeConfirmRevokeInvite },
    ] = useDisclosure(false);
    const [
        confirmResendInvite,
        { open: openConfirmResendInvite, close: closeConfirmResendInvite },
    ] = useDisclosure(false);
    const { mutate: updateUser } = useUpdateUserMutation(
        rowData?.original?.userUuid,
    );
    const { mutate: deleteUser } = useDeleteOrganizationUserMutation();

    const { mutateAsync } = useCreateInviteLinkMutation();
    const { mutate: logout } = useLogoutMutation({
        onSuccess: () => {
            posthog.reset();
            window.location.href = '/login';
        },
    });

    const createLeaveOrganizationItem = useCallback(
        () => ({
            leftSection: (
                <UserMinus
                    size={14}
                    color={'rgb(var(--color-gray-500))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('organization_settings.admin_leave_organization'),
            onClick: () => openConfirmLeaveOrganization(),
        }),
        [openConfirmLeaveOrganization, t],
    );
    const createRevokeInviteItem = useCallback(
        () => ({
            leftSection: (
                <UserMinus
                    size={14}
                    color={'rgb(var(--color-gray-500))'}
                    strokeWidth={2.5}
                />
            ),
            children: ` ${t(
                'organization_settings.admin_revokes_member_invite',
            )}`,
            onClick: () => openConfirmRevokeInvite(),
        }),
        [openConfirmRevokeInvite, t],
    );
    const createResendInviteItem = useCallback(
        () => ({
            leftSection: (
                <Send
                    size={14}
                    color={'rgb(var(--color-gray-500))'}
                    strokeWidth={2.5}
                />
            ),
            children: ` ${t(
                'organization_settings.admin_resend_member_invite',
            )}`,
            onClick: async (values: {
                email: string;
                role: OrganizationMemberRole;
            }) => {
                try {
                    const data = {
                        email: values.email,
                        role: values.role || OrganizationMemberRole.MEMBER,
                    };
                    openConfirmResendInvite();
                    switch (values.role) {
                        case OrganizationMemberRole.ADMIN:
                            data.role = OrganizationMemberRole.ADMIN;
                            break;
                        case OrganizationMemberRole.MANAGER:
                            data.role = OrganizationMemberRole.MANAGER;
                            break;
                        case OrganizationMemberRole.MEMBER:
                            data.role = OrganizationMemberRole.MEMBER;
                            break;
                    }
                    await mutateAsync({ email: data.email, role: data.role });
                    updateUser({ role: data.role });
                } catch (_err) {}
            },
        }),
        [openConfirmResendInvite, t, mutateAsync, updateUser],
    );
    const createChangeMemberRoleItem = useCallback(
        () => ({
            leftSection: (
                <Users
                    size={14}
                    color={'rgb(var(--color-gray-500))'}
                    strokeWidth={2.5}
                />
            ),
            children: (
                <div>{t('organization_settings.admin_change_member_role')}</div>
            ),
            onClick: () => openChangeMemberRole(),
        }),
        [t, openChangeMemberRole],
    );

    const createRemoveMemberItem = useCallback(
        () => ({
            leftSection: (
                <UserMinus
                    size={14}
                    color={'rgb(var(--color-gray-500))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('organization_settings.admin_remove_member'),
            onClick: () => openConfirmRemoveMember(),
        }),
        [openConfirmRemoveMember, t],
    );

    const menuItems = useMemo(() => {
        let items: CustomMenuItemProps<OrganizationMemberProfile>[] = [];

        if (user?.data?.userUuid === rowData.original.userUuid) {
            items = [createLeaveOrganizationItem()];
        } else if (user?.data?.role === OrganizationMemberRole.ADMIN) {
            if (
                !rowData.original.isActive &&
                !rowData.original.isInviteExpired
            ) {
                items = [createRevokeInviteItem()];
            } else if (
                !rowData.original.isActive &&
                rowData.original.isInviteExpired
            ) {
                items = [createResendInviteItem()];
            } else {
                items = [
                    createChangeMemberRoleItem(),
                    createRemoveMemberItem(),
                ];
            }
        }

        return items;
    }, [
        user.data,
        rowData,
        createLeaveOrganizationItem,
        createChangeMemberRoleItem,
        createResendInviteItem,
        createRevokeInviteItem,
        createRemoveMemberItem,
    ]);

    return (
        <>
            <CustomMenu<OrganizationMemberProfile>
                menuItems={menuItems}
                data={rowData.original}
            />
            {
                <ChangeMemberRole
                    data={rowData.original}
                    opened={openedChangeMemberRole}
                    onClose={closeChangeMemberRole}
                />
            }
            <Modal
                opened={confirmRemoveMember}
                onClose={closeConfirmRemoveMember}
                className="modal__close--posChange-top"
                title={
                    <Box>
                        {t('organization_settings.admin_remove_member')}
                        <Text className="text-gray-300 ">
                            {t(
                                'organization_settings.admin_remove_member_modal_from',
                            )}{' '}
                            <Box className="inline text-gray-500">
                                {orgData?.name}
                            </Box>{' '}
                        </Text>
                    </Box>
                }
                footerRightSection={
                    <Group spacing="xs">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={closeConfirmRemoveMember}
                        >
                            {t(
                                'organization_settings.admin_remove_member_modal_back_button',
                            )}
                        </Button>

                        <Button
                            form="add_user_to_project"
                            type="submit"
                            variant={ButtonVariant.FILLED_DESTRUCTIVE}
                            rightIcon={
                                <ChevronRight size={13} strokeWidth={2.5} />
                            }
                            onClick={() => {
                                deleteUser(rowData.original.userUuid);
                                closeConfirmRemoveMember();
                            }}
                        >
                            {t(
                                'organization_settings.admin_remove_member_modal_okay_button',
                            )}
                        </Button>
                    </Group>
                }
                closeButtonProps={{
                    className: 'absolute top-4 right-3',
                }}
            >
                {t(
                    'organization_settings.admin_remove_member_modal_description',
                )}
            </Modal>
            <Modal
                opened={confirmLeaveOrganization}
                onClose={closeConfirmLeaveOrganization}
                title={t('organization_settings.admin_leave_organization')}
                footerRightSection={
                    <Group spacing="xs">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={closeConfirmLeaveOrganization}
                        >
                            {t(
                                'organization_settings.admin_remove_member_modal_back_button',
                            )}
                        </Button>

                        <Button
                            form="add_user_to_project"
                            type="submit"
                            variant={ButtonVariant.FILLED_DESTRUCTIVE}
                            rightIcon={
                                <ChevronRight size={13} strokeWidth={2.5} />
                            }
                            onClick={async () => {
                                await deleteUser(rowData.original.userUuid);
                                closeConfirmLeaveOrganization();
                                logout();
                            }}
                        >
                            {t(
                                'organization_settings.admin_leave_modal_confirm_button',
                            )}
                        </Button>
                    </Group>
                }
            >
                {t('organization_settings.admin_leave_modal_description')}
            </Modal>
            <Modal
                opened={confirmRevokeInvite}
                onClose={closeConfirmRevokeInvite}
                title={t('organization_settings.admin_revokes_member_invite')}
                footerRightSection={
                    <Group spacing="xs">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={closeConfirmRevokeInvite}
                        >
                            {t(
                                'organization_settings.admin_remove_member_modal_back_button',
                            )}
                        </Button>

                        <Button
                            form="add_user_to_project"
                            type="submit"
                            rightIcon={
                                <ChevronRight size={13} strokeWidth={2.5} />
                            }
                            onClick={() => {
                                deleteUser(rowData.original.userUuid);
                                closeConfirmRevokeInvite();
                            }}
                        >
                            {t(
                                'organization_settings.admin_revokes_member_invite_modal_confirm_button',
                            )}
                        </Button>
                    </Group>
                }
            >
                {t(
                    'organization_settings.admin_revokes_member_invite_modal_description',
                )}
            </Modal>
            <Modal
                title={t(
                    'organization_settings.admin_resend_member_invite_modal_title',
                )}
                opened={confirmResendInvite}
                onClose={() => {
                    closeConfirmResendInvite();
                }}
                footerRightSection={
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={() => {
                            closeConfirmResendInvite();
                        }}
                    >
                        {t(
                            'workspace_settings.data_connection_request_modal_button',
                        )}
                    </Button>
                }
            >
                {t(
                    'organization_settings.admin_resend_member_invite_modal_description',
                )}
            </Modal>
        </>
    );
};
