import { type JourneyEvent } from '@components/Journeys/Builder/JourneyFilters/types';
import { useGetJourneyEvents } from '@hooks/useJourney';
import {
    CommunicationChannel,
    DimensionType,
    FieldType,
    FynoEventNames,
    RelationTableType,
    Sources,
} from '@lightdash/common';
import {
    CAMPAIGN_EVENT_EMAIL_DELIVERED,
    CAMPAIGN_EVENT_EMAIL_LINK_CLICKED,
    CAMPAIGN_EVENT_EMAIL_OPENED,
    CAMPAIGN_EVENT_SMS_DELIVERED,
    CAMPAIGN_EVENT_TABLE_NAME,
    CAMPAIGN_EVENT_WHATSAPP_DELIVERED,
    CAMPAIGN_EVENT_WHATSAPP_READ,
    CAMPAIGN_EVENT_EMAIL_SENT,
    CAMPAIGN_EVENT_WHATSAPP_SENT,
    CAMPAIGN_EVENT_SMS_SENT,
    CAMPAIGN_EVENT_SMS_LINK_CLICKED,
    CAMPAIGN_EVENT_EMAIL_BOUNCED,
} from '@utils/constants';
import { t as translate } from 'i18next';
import { useMemo } from 'react';

const getFynoEventLabel = (eventName: string, sourceLabel: string) => {
    if (sourceLabel === CommunicationChannel.EMAIL) {
        switch (eventName) {
            case FynoEventNames.OPEN:
                return CAMPAIGN_EVENT_EMAIL_OPENED;
            case FynoEventNames.DELIVERED:
                return CAMPAIGN_EVENT_EMAIL_DELIVERED;
            case FynoEventNames.CLICKED:
                return CAMPAIGN_EVENT_EMAIL_LINK_CLICKED;
            case FynoEventNames.SENT:
                return CAMPAIGN_EVENT_EMAIL_SENT;
            case FynoEventNames.BOUNCE:
                return CAMPAIGN_EVENT_EMAIL_BOUNCED;
            default:
                return eventName;
        }
    }
    if (sourceLabel === CommunicationChannel.SMS) {
        switch (eventName) {
            case FynoEventNames.DELIVERED:
                return CAMPAIGN_EVENT_SMS_DELIVERED;
            case FynoEventNames.CLICKED:
                return CAMPAIGN_EVENT_SMS_LINK_CLICKED;
            case FynoEventNames.SENT:
                return CAMPAIGN_EVENT_SMS_SENT;
            default:
                return eventName;
        }
    }
    if (sourceLabel === CommunicationChannel.WHATSAPP) {
        switch (eventName) {
            case FynoEventNames.DELIVERED:
                return CAMPAIGN_EVENT_WHATSAPP_DELIVERED;
            case FynoEventNames.READ:
                return CAMPAIGN_EVENT_WHATSAPP_READ;
            case FynoEventNames.SENT:
                return CAMPAIGN_EVENT_WHATSAPP_SENT;
            default:
                return eventName;
        }
    }
    return eventName;
};

const convertToDimension = (event: JourneyEvent) => ({
    type: DimensionType.STRING,
    tableType: RelationTableType.EVENT,
    fieldType: FieldType.DIMENSION as const,
    name: event.eventName,
    tableLabel: translate('common.campaigns'),
    table: CAMPAIGN_EVENT_TABLE_NAME,
    label: getFynoEventLabel(event.eventName, event.sourceLabel ?? ''),
    suggestions: [],
    uuid: event.uuid,
    sql: '',
    hidden: false,
    //Here we use the sourceLabel as the uniqueIdentifier
    //because the sourceLabel is the channel and i need channel in creation of addinonal metric and labeling in the selector.

    uniqueIdentifier: event.sourceLabel,
    fieldReference: event.eventName,
});

export const useFieldsWithCampaignEvents = () => {
    const { data: eventData, isLoading } = useGetJourneyEvents();
    const internalFynoEvents = useMemo(() => {
        if (!eventData) return [];
        const fynoEvents = eventData.filter(
            (event) => event.isInternal && event.source === Sources.FYNO,
        );
        return fynoEvents.map(convertToDimension);
    }, [eventData]);

    return { internalFynoEvents, isLoading };
};
