import { useContextSelector } from 'use-context-selector';
import JourneyBuilderProviderContext from './context';
import { type JourneyBuilderContext } from './types';

function useJourneyBuilderContext<Selected>(
    selector: (value: JourneyBuilderContext) => Selected,
): Selected {
    return useContextSelector(JourneyBuilderProviderContext, (context) => {
        if (context === undefined) {
            throw new Error(
                'useContext must be used within Journey Builder Provider',
            );
        }
        return selector(context);
    });
}

export default useJourneyBuilderContext;
