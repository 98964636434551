import TextArea from '@components/common/Inputs/TextArea';
import TextInput from '@components/common/Inputs/TextInput';
import Modal from '@components/common/modal/Modal';
import TagsMultiSelect from '@components/common/Tags/TagsMultiSelect';
import { useLocale } from '@hooks/useLocale';
import { Button, Stack } from '@mantine/core';
import _ from 'lodash';
import { useCallback, useMemo, useState, type FC } from 'react';
import { ButtonVariant } from '../../mantineTheme';

interface TemplateTitleModalProps {
    isEditMode: boolean;
    tags: string[];

    name: string;

    description: string;
    handleTitleChange: (
        name: string,
        description: string,
        tags: string[],
    ) => void;
    opened: boolean;
    close: () => void;
    open: () => void;
}
const TemplateTitleModal: FC<TemplateTitleModalProps> = ({
    isEditMode,
    tags,
    name,
    description,
    handleTitleChange,
    opened,
    close,
}) => {
    const { t } = useLocale();
    const [tempName, setTempName] = useState(name);
    const [tempDescription, setTempDescription] = useState(description);
    const [tempTags, setTempTags] = useState(tags);

    const hasChanges = useMemo(() => {
        return (
            tempName.trim() !== name ||
            tempDescription.trim() !== description ||
            !_.isEqual(tempTags, tags)
        );
    }, [tempName, tempDescription, tempTags, name, description, tags]);

    const handleSave = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();

            e.preventDefault();
            handleTitleChange(tempName, tempDescription, tempTags);
            close();
        },
        [handleTitleChange, close, tempName, tempDescription, tempTags],
    );

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => {
                    setTempName(name);
                    setTempDescription(description);
                    setTempTags(tags);
                    close();
                }}
                title={t('template_create.modal_title')}
                footerRightSection={
                    <Button
                        onClick={handleSave}
                        variant={ButtonVariant.PRIMARY}
                        disabled={!hasChanges}
                    >
                        {t('common.okay')}
                    </Button>
                }
                size="lg"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Stack spacing={12}>
                    <TextInput
                        placeholder={t(
                            'template_create.modal_name_placeholder',
                        )}
                        label={t('template_create.name_label')}
                        value={tempName}
                        onChange={(e) => {
                            setTempName(e.target.value);
                        }}
                        disabled={!isEditMode}
                    />
                    <TextArea
                        placeholder={t(
                            'template_create.modal_description_placeholder',
                        )}
                        label={t('template_create.modal_description_label')}
                        value={tempDescription}
                        onChange={(e) => {
                            setTempDescription(e.target.value);
                        }}
                        disabled={!isEditMode}
                    />
                    <TagsMultiSelect
                        tags={tempTags}
                        onChange={setTempTags}
                        isDisabled={!isEditMode}
                    />
                </Stack>
            </Modal>
        </>
    );
};

export default TemplateTitleModal;
