import { SortmentLogo } from '@assets/icons/Core';
import { PasswordRecoveryForm } from '@components/Auth/PasswordRecoveryForm';
import Page from '@components/Page/Page';
import PageSpinner from '@components/PageSpinner';
import { useLocale } from '@hooks/useLocale';
import useApp from '@providers/App/useApp';
import React from 'react';
import { Navigate } from 'react-router';

interface PasswordRecoveryProps {}

const PasswordRecovery: React.FC<
    React.PropsWithChildren<PasswordRecoveryProps>
> = ({}) => {
    const { t } = useLocale();
    const { health } = useApp();

    if (health.isInitialLoading) {
        return <PageSpinner />;
    }

    if (health.status === 'success' && health.data?.isAuthenticated) {
        return <Navigate to={{ pathname: '/' }} />;
    }

    return (
        <>
            <Page
                title="Recover password"
                withCenteredContent
                withFullHeight
                backgroundColor="gray"
            >
                <section className="w-[300px] px-3 pt-6 pb-3 bg-white flex flex-col items-center border-base gap-3 shadow-card rounded-3xl">
                    <div className="flex flex-col items-center w-full gap-2">
                        <SortmentLogo />
                        <p className="text-sm font-medium text-gray-600">
                            {t('password_recovery.header')}
                        </p>
                    </div>

                    <PasswordRecoveryForm />
                </section>
            </Page>
        </>
    );
};

export default PasswordRecovery;
