import {
    CommunicationChannel,
    type ReachabilityConfig,
    type TableConfig,
} from '@lightdash/common';
import _ from 'lodash';

/**
 * Returns a default reachability config with all communication channels set to undefined
 */
export const defaultReachabilityConfig = (
    activePill: CommunicationChannel,
    primaryConfig: TableConfig | undefined,
    secondaryConfig: TableConfig[],
): ReachabilityConfig => {
    return _.reduce(
        CommunicationChannel,
        (acc, channel) => ({
            ...acc,
            [channel]: {
                primary: channel === activePill ? primaryConfig : undefined,
                secondary: channel === activePill ? secondaryConfig : [],
            },
        }),
        {} as ReachabilityConfig,
    );
};
