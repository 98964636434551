export const filterSuperAdmin = <
    T extends { isInternal?: boolean; userUuid: string },
>(
    users: T[],
    userUuid: string,
) => {
    return users.filter(
        (eachUser) => !eachUser.isInternal || eachUser.userUuid === userUuid,
    );
};
