import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import { useMultipleRelationCacheConfigs } from '@hooks/useRelation';
import {
    DimensionType,
    RelationTableType,
    type CachedColumnValues,
    type CompiledRelation,
    type CompiledRelationTableEvent,
} from '@lightdash/common';

import { getTablesByTableType } from '@utils/relation';
import { useMemo } from 'react';

const convertToDimension = (
    eventsTable: CompiledRelationTableEvent | undefined,
    eventsCached: CachedColumnValues[],
): FieldWithSuggestions[] => {
    if (!eventsTable || !eventsCached) return [];
    const eventNameDimension =
        eventsTable?.dimensions?.[eventsTable?.eventNameColumn];
    const eventsDimension: FieldWithSuggestions[] = eventsCached.map(
        (event) => {
            const dimension: FieldWithSuggestions = {
                ...eventNameDimension,
                hidden: false,
                label: event.label,
                type: DimensionType.EVENT,
                fieldReference: event.name,
                tableType: eventsTable.type,
            };
            return dimension;
        },
    );

    return eventsDimension;
};

interface FieldsWithEvents {
    relationData: CompiledRelation | undefined;
    activeRelationUuid: string;
    projectUuid: string | undefined;
}

export const useFieldsWithEvents = ({
    relationData,
    activeRelationUuid,
    projectUuid,
}: FieldsWithEvents) => {
    const eventTables = useMemo(() => {
        if (!relationData || !projectUuid) return [];
        return getTablesByTableType<CompiledRelationTableEvent>({
            tables: relationData.tables,
            tableType: RelationTableType.EVENT,
        }).filter((table) => !table.isReserved);
    }, [relationData, projectUuid]);

    const queriesConfig = useMemo(() => {
        return eventTables.map((table) => ({
            relationUuid: activeRelationUuid,
            tableName: table.name,
            columnName: table.dimensions?.[table.eventNameColumn]?.name ?? '',
            projectUuid,
        }));
    }, [eventTables, activeRelationUuid, projectUuid]);

    const queryResults = useMultipleRelationCacheConfigs(queriesConfig);

    const events = useMemo(() => {
        if (!queryResults || queryResults.length === 0) return [];

        const allEvents = queryResults.flatMap((query, index) => {
            const table = eventTables[index];
            if (query.data?.cachedColumnValues) {
                return convertToDimension(table, query.data.cachedColumnValues);
            }
            return [];
        });

        return allEvents;
    }, [queryResults, eventTables]);

    const isLoading = queryResults.some((query) => query.isLoading);

    return {
        data: events,
        isLoading,
        eventsTableNames: eventTables.map((table) => table.name),
    };
};
