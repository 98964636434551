import Modal from '@components/common/modal/Modal';
import SyncedWhatsappTemplates from '@components/TemplateManager/Table/SyncedWhatsappTemplates';
import TemplateTable from '@components/TemplateManager/Table/TemplateTable';
import { useTemplates } from '@hooks/useGetTemplate';
import { useLocale } from '@hooks/useLocale';
import {
    CommonReservedTags,
    CommunicationChannel,
    type Pagination,
    type ProviderTemplateDetails,
    type ReducedTemplate,
} from '@lightdash/common';
import { Box, Button, Group } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { ArrowDownRight } from '@phosphor-icons/react';
import useTemplateBuilderContext from '@providers/TemplateBuilder/useTemplateBuilderContext';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import React, { useCallback, useMemo, useState } from 'react';
import { ButtonVariant } from '../../../../mantineTheme';

interface TemplateLibraryModalProps {
    channel: CommunicationChannel;
    integrationId: string;
}

const TemplateLibraryModal: React.FC<TemplateLibraryModalProps> = ({
    channel,
    integrationId,
}) => {
    const [searchValue, setSearchValue] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = useLocale();
    const { openLibraryModal } = useTemplateBuilderContext(
        (context) => context.state,
    );
    const { data: templates, isFetching } = useTemplates(
        channel,
        8,
        currentPage,
        searchValue,
        channel !== CommunicationChannel.WHATSAPP,
        `excludesTags=${CommonReservedTags.HIDDEN}`,
    );
    const { setOpenLibraryModal, handleTemplateSelect, setOpenImportModal } =
        useTemplateBuilderContext((context) => context.actions);

    const handleImportClick = useCallback(() => {
        setOpenImportModal(true);
        setOpenLibraryModal(false);
    }, [setOpenImportModal, setOpenLibraryModal]);

    const handleSearchInput = useCallback(
        (value: string) => {
            setSearchValue(value);
            setCurrentPage(1);
        },
        [setSearchValue, setCurrentPage],
    );

    const titleComponent = useMemo(() => {
        return (
            <Group position="apart" className="w-[100%] pr-3">
                <Box>{t('campaigns.import_template.select_from_library')}</Box>
                {channel !== CommunicationChannel.WHATSAPP && (
                    <Button
                        onClick={handleImportClick}
                        leftIcon={
                            <ArrowDownRight
                                weight="regular"
                                color="rgb(var(--color-blu-800))"
                            />
                        }
                        variant={ButtonVariant.OUTLINED_ACCENTED}
                    >
                        {t('templates_manager.import_from_integration')}
                    </Button>
                )}
            </Group>
        );
    }, [t, channel, handleImportClick]);

    const renderTemplateTable = useMemo(() => {
        if (channel === CommunicationChannel.WHATSAPP) {
            return (
                <SyncedWhatsappTemplates
                    handleRowClick={(val) => {
                        const payload = {
                            id: undefined,
                            name: val.name,
                            description: '',
                        };
                        handleTemplateSelect(
                            payload as unknown as ProviderTemplateDetails,
                        );
                        setOpenLibraryModal(false);
                    }}
                    integrationId={integrationId}
                />
            );
        }
        return (
            <TemplateTable
                onPageChange={async (newPage) => {
                    setCurrentPage(newPage);
                }}
                pagination={templates?.pagination as Pagination}
                templates={templates?.data as ReducedTemplate[]}
                isDataFetching={isFetching}
                handleRowPress={(id: string) => {
                    const selectedTemplate = templates?.data?.find(
                        (template) => template?.id === id,
                    );
                    handleTemplateSelect(
                        selectedTemplate as unknown as ProviderTemplateDetails,
                    );
                    setOpenLibraryModal(false);
                }}
                tableBackgroundColor="white"
                withBorder
                searchValue={searchValue}
                handleSearchInput={handleSearchInput}
                activeTab={channel}
                isManager={false}
            />
        );
    }, [
        channel,
        templates?.pagination,
        templates?.data,
        isFetching,
        searchValue,
        handleSearchInput,
        integrationId,
        handleTemplateSelect,
        setOpenLibraryModal,
    ]);

    return (
        <Modal
            styles={(theme, _params) => ({
                inner: {
                    paddingTop: '5rem !important',
                },
                content: {
                    top: 0,
                    borderRadius: theme.radius.xl,
                    maxHeight: '90vh !important',
                    minHeight: '50vh',
                    minWidth: '80vw',
                },
                body: {
                    padding: '2rem',
                },
                title: {
                    width: '100%',
                },
            })}
            opened={openLibraryModal}
            onClose={() => setOpenLibraryModal(false)}
            title={titleComponent}
            size="lg"
        >
            {renderTemplateTable}
        </Modal>
    );
};

export default React.memo(TemplateLibraryModal);
