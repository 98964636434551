import { ContentMappingSection, type ContentMappings } from '@lightdash/common';
import { Stack } from '@mantine/core';
import { capitalize } from 'lodash';
import React from 'react';
import LinkComponent from './LinkComponent';

const WhatsappLinkTracking = ({
    contentMappings,
    setContentMappings,
}: {
    contentMappings: ContentMappings;
    setContentMappings: (val: ContentMappings) => void;
}) => {
    const handleTrackLink = (
        checked: boolean,
        key: string,
        section?: ContentMappingSection,
    ) => {
        const sec = section ?? ContentMappingSection.BODY;
        if (contentMappings && contentMappings[sec]) {
            setContentMappings({
                ...contentMappings,
                [sec]: {
                    ...contentMappings?.[sec],
                    [key]: {
                        ...contentMappings?.[sec]?.[key],
                        enableTracking: checked,
                    },
                },
            });
        }
    };

    return (
        <Stack>
            {Object.entries(
                contentMappings?.[ContentMappingSection.HEADER] ?? {},
            ).length ? (
                <LinkComponent
                    header={capitalize(ContentMappingSection.HEADER)}
                    mappings={
                        contentMappings?.[ContentMappingSection.HEADER] ?? {}
                    }
                    handleChange={(checked, key) =>
                        handleTrackLink(
                            checked,
                            key,
                            ContentMappingSection.HEADER,
                        )
                    }
                />
            ) : null}
            {Object.entries(contentMappings?.[ContentMappingSection.BODY] ?? {})
                .length ? (
                <LinkComponent
                    header={capitalize(ContentMappingSection.BODY)}
                    mappings={
                        contentMappings?.[ContentMappingSection.BODY] ?? {}
                    }
                    handleChange={(checked, key) =>
                        handleTrackLink(
                            checked,
                            key,
                            ContentMappingSection.BODY,
                        )
                    }
                />
            ) : null}
            {Object.entries(
                contentMappings?.[ContentMappingSection.BUTTONS] ?? {},
            ).length ? (
                <LinkComponent
                    header={capitalize(ContentMappingSection.BUTTONS)}
                    mappings={
                        contentMappings?.[ContentMappingSection.BUTTONS] ?? {}
                    }
                    handleChange={(checked, key) =>
                        handleTrackLink(
                            checked,
                            key,
                            ContentMappingSection.BUTTONS,
                        )
                    }
                />
            ) : null}
        </Stack>
    );
};

export default WhatsappLinkTracking;
