import InputErrorText from '@components/common/InputErrorText';
import {
    FileInput as MantineFileInput,
    Stack,
    Text,
    type FileInputProps as MantineFileInputProps,
} from '@mantine/core';

interface FileInputProps extends MantineFileInputProps {}
const FileInput = (props: FileInputProps) => {
    const { description, error, ...rest } = props;
    return (
        <Stack spacing="xs">
            <MantineFileInput {...rest} />
            {error && <InputErrorText value={error} />}
            {description && (
                <Text className="text-sm font-medium text-gray-500">
                    {description}
                </Text>
            )}
        </Stack>
    );
};

export default FileInput;
