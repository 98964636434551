import { ChartType } from '@lightdash/common';

export const metricQueryDefaultState = {
    tableName: '',
    metricQuery: {
        exploreName: '',
        dimensions: [],
        metrics: [],
        filters: {},
        sorts: [],
        limit: 500,
        tableCalculations: [],
        additionalMetrics: [],
    },
    pivotConfig: undefined,
    tableConfig: {
        columnOrder: [],
    },
    chartConfig: {
        type: ChartType.CARTESIAN,
        config: undefined,
    },
};
