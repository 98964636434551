import { useCampaignInitialData } from '@components/Campaigns/useCampaignInitialData';
import {
    CommunicationChannel,
    FieldType,
    JOURNEY_DYNAMIC_VARIABLE_PREFIX,
    TemplateType,
    type CommunicationDetails,
    type ContentMappings,
    type ExternalCampaignTriggerRequest,
    type Integration,
} from '@lightdash/common';
import { Divider, Stack } from '@mantine/core';
import CampaignProvider from '@providers/Campaign/CampaignProvider';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import CampaignActionChannel from './CampaignActionChannel';
import CampaignActionContent from './CampaignActionContent';

/**
 * @function encodeContentDetails
 * @description Modifies the content mappings by adding a '$' to the value if not already present, only for non-static fields
 * @param {ContentMappings} contentMappings - The content mappings to be modified.
 * @returns {ContentMappings} - The modified content mappings.
 */
const encodeContentDetails = (
    contentMappings: ContentMappings,
): ContentMappings => {
    const modMappings = _.cloneDeep(contentMappings);
    return _.mapValues(modMappings, (mapping) => {
        return _.mapValues(mapping, (val) => {
            if (val && val.value) {
                return {
                    ...val,
                    value: val.value.startsWith(JOURNEY_DYNAMIC_VARIABLE_PREFIX)
                        ? val.value
                        : `${JOURNEY_DYNAMIC_VARIABLE_PREFIX}${val.value}`,
                };
            }
            return val;
        });
    });
};

/**
 * @function decodeContentDetails
 * @description Modifies the content mappings by removing the '$' from the value if present.
 * @param {ContentMappings} contentMappings - The content mappings to be modified.
 * @returns {ContentMappings} - The modified content mappings.
 */
const decodeContentDetails = (
    contentMappings: ContentMappings,
): ContentMappings => {
    return _.mapValues(contentMappings, (mapping) => {
        return _.mapValues(mapping, (val) => {
            if (val && val.value) {
                val.value = val.value.startsWith(
                    JOURNEY_DYNAMIC_VARIABLE_PREFIX,
                )
                    ? val.value.slice(JOURNEY_DYNAMIC_VARIABLE_PREFIX.length)
                    : val.value;
            }
            return val;
        });
    });
};

export interface SendCampaignProps {
    messageConfig: ExternalCampaignTriggerRequest;
    onCampaignDetailsChange: (
        messageConfig: ExternalCampaignTriggerRequest,
    ) => void;
    nodeId: string;
}

const SendCampaign: React.FC<SendCampaignProps> = ({
    messageConfig,
    onCampaignDetailsChange,
    nodeId,
}) => {
    const setJourneyChannelPayload = (channel: Integration | null) => {
        if (!channel) return;
        onCampaignDetailsChange({
            ...messageConfig,
            communicationDetails: {
                eventId: '',
                id: channel?.integrationId || '',
                providerId: channel?.providerId || '',
            },
            templateDetails: {
                id: '',
                version: 1,
                type: TemplateType.INTERNAL,
            },
            channel: channel?.channelName as CommunicationChannel,
            contentMappings: {},
            sendTo: {
                type: FieldType.DIMENSION,
            },
            contentDetails: undefined,
        });
    };
    const setJourneyContentPayload = useCallback(
        (payload: ExternalCampaignTriggerRequest) => {
            if (!payload) {
                return;
            }

            let modifiedCommunicationDetails = payload.communicationDetails;

            let encodedContentMappings = {
                ...messageConfig.contentMappings,
            };

            let modifiedSendTo = messageConfig.sendTo;

            if (payload.communicationDetails) {
                const channel = payload.channel;
                if (channel) {
                    const sendTo = payload.sendTo?.[channel];
                    if (sendTo) {
                        // Info: Backend expects the variable to be prefixed with $
                        modifiedSendTo = {
                            ...messageConfig.sendTo,
                            [channel]: sendTo.startsWith(
                                JOURNEY_DYNAMIC_VARIABLE_PREFIX,
                            )
                                ? sendTo
                                : `${JOURNEY_DYNAMIC_VARIABLE_PREFIX}${sendTo}`,
                            type: payload.sendTo?.type ?? FieldType.DIMENSION,
                        };
                    }
                }
            }

            if (payload.contentMappings) {
                encodedContentMappings = encodeContentDetails({
                    ...payload.contentMappings,
                    ...messageConfig.contentMappings,
                });
            }

            if (payload.channel === CommunicationChannel.WHATSAPP) {
                payload.contentDetails = undefined;
            }

            onCampaignDetailsChange({
                ...messageConfig,
                ...payload,
                communicationDetails: modifiedCommunicationDetails,
                contentMappings: encodedContentMappings,
                sendTo: modifiedSendTo,
            });
        },
        [messageConfig, onCampaignDetailsChange],
    );

    const modifiedSentTo = useMemo(() => {
        const channel = messageConfig.channel;
        if (!channel) return messageConfig.sendTo;
        const sendTo = messageConfig.sendTo?.[channel];
        if (!sendTo) return messageConfig.sendTo;
        //Info: Remove $ if it starts with $, this is to ensure backward compatibility with the Campaign Builder
        const modifiedSendTo = sendTo.startsWith(
            JOURNEY_DYNAMIC_VARIABLE_PREFIX,
        )
            ? sendTo.slice(JOURNEY_DYNAMIC_VARIABLE_PREFIX.length)
            : sendTo;

        return {
            ...messageConfig.sendTo,
            [channel]: modifiedSendTo,
            type: messageConfig.sendTo?.type ?? FieldType.DIMENSION,
        };
    }, [messageConfig]);

    const modifiedTemplateDetails = useMemo(() => {
        const contentMappings = _.cloneDeep(messageConfig.contentMappings);
        if (!contentMappings) return contentMappings;
        return decodeContentDetails(contentMappings);
    }, [messageConfig.contentMappings]);

    const initialState = useCampaignInitialData(undefined);
    return (
        <CampaignProvider
            isNewMode={true}
            isEditMode={true}
            initialState={{
                ...initialState,
                showFooterButtons: { next: true, back: true },
                campaignPayload: {
                    ...initialState.campaignPayload,
                    communicationDetails:
                        (messageConfig.communicationDetails as CommunicationDetails) ??
                        initialState.campaignPayload.communicationDetails,
                    templateDetails:
                        messageConfig.templateDetails ??
                        initialState.campaignPayload.templateDetails,
                    contentMappings: modifiedTemplateDetails,
                    channel:
                        messageConfig.channel ??
                        initialState.campaignPayload.channel,
                    sendTo: modifiedSentTo,
                    contentDetails:
                        messageConfig.contentDetails ??
                        initialState.campaignPayload.contentDetails,
                    subscriptionGroupId:
                        messageConfig.subscriptionGroupId ??
                        initialState.campaignPayload.subscriptionGroupId,
                },
            }}
            uuid={''}
        >
            <Stack className="w-[30vw]">
                <CampaignActionChannel
                    communicationDetails={messageConfig.communicationDetails}
                    setJourneyChannelPayload={setJourneyChannelPayload}
                    channel={
                        messageConfig.channel ?? CommunicationChannel.EMAIL
                    }
                />
                <Divider className="border-t-gray-200" />
                <CampaignActionContent
                    messageConfig={{
                        ...messageConfig,
                        contentMappings: modifiedTemplateDetails,
                        sendTo: modifiedSentTo,
                    }}
                    setJourneyContentPayload={setJourneyContentPayload}
                    nodeId={nodeId}
                />
            </Stack>
        </CampaignProvider>
    );
};

export default React.memo(SendCampaign);
