import { useLocale } from '@hooks/useLocale';
import { DifferenceOperator, DimensionType } from '@lightdash/common';
import { Button, Menu, Text } from '@mantine/core';
import { Clock, Equals, Minus, Plus } from '@phosphor-icons/react';
import { useMemo, useState } from 'react';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import { DifferenceLabel, SetValueStringInUpdateTrait } from '../utils';

interface SelectValueMenuProps {
    type: DimensionType;
    onChange: (value: { operator?: DifferenceOperator }) => void;
    isDisabled: boolean;
    operator?: DifferenceOperator;
}

const SelectValueMenu = ({
    type,
    onChange,
    isDisabled,
    operator,
}: SelectValueMenuProps) => {
    const { t } = useLocale();
    const initialValue = useMemo(() => {
        if (!operator) {
            return SetValueStringInUpdateTrait[type];
        }
        if (operator === DifferenceOperator.ADD) {
            return DifferenceLabel.INCREASE_BY;
        }
        if (operator === DifferenceOperator.SUBTRACT) {
            return DifferenceLabel.DECREASE_BY;
        }
        return SetValueStringInUpdateTrait[type];
    }, [operator, type]);
    const [selectedValue, setSelectedValue] = useState<string>(initialValue);

    const icon = useMemo(() => {
        if (selectedValue === DifferenceLabel.INCREASE_BY) {
            return <Plus weight="duotone" />;
        } else if (selectedValue === DifferenceLabel.DECREASE_BY) {
            return <Minus weight="duotone" />;
        }
        return type === DimensionType.NUMBER ? (
            <Equals weight="duotone" />
        ) : (
            <Clock weight="duotone" />
        );
    }, [selectedValue, type]);

    const handleValueChange = (newValue: string) => {
        setSelectedValue(newValue);

        if (newValue === DifferenceLabel.INCREASE_BY) {
            onChange({ operator: DifferenceOperator.ADD });
        } else if (newValue === DifferenceLabel.DECREASE_BY) {
            onChange({ operator: DifferenceOperator.SUBTRACT });
        } else {
            onChange({});
        }
    };

    // For non-number and non-date types, return simple text
    if (type !== DimensionType.NUMBER) {
        return (
            <>
                <Equals weight="duotone" />
                <Text>{SetValueStringInUpdateTrait[type]}</Text>
            </>
        );
    }

    return (
        <Menu>
            <Menu.Target>
                <Button
                    variant={ButtonVariant.OUTLINED}
                    leftIcon={icon}
                    disabled={isDisabled}
                >
                    {selectedValue}
                </Button>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item
                    icon={
                        type === DimensionType.NUMBER ? (
                            <Equals weight="duotone" />
                        ) : (
                            <Clock weight="duotone" />
                        )
                    }
                    onClick={() =>
                        handleValueChange(SetValueStringInUpdateTrait[type])
                    }
                >
                    {SetValueStringInUpdateTrait[type]}
                </Menu.Item>
                <Menu.Item
                    icon={<Plus weight="duotone" />}
                    onClick={() =>
                        handleValueChange(t('update_traits.value.increase_by'))
                    }
                >
                    {t('update_traits.value.increase_by')}
                </Menu.Item>
                <Menu.Item
                    icon={<Minus weight="duotone" />}
                    onClick={() =>
                        handleValueChange(t('update_traits.value.decrease_by'))
                    }
                >
                    {t('update_traits.value.decrease_by')}
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};

export default SelectValueMenu;
