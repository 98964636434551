import MultiSelect, {
    CreationLabel,
} from '@components/common/Select/MultiSelect';
import { useLocale } from '@hooks/useLocale';
import { useCreateTag, useTags } from '@hooks/useTags';
import { Flex, Text, type MultiSelectProps } from '@mantine/core';
import { useCallback } from 'react';

interface TagsMultiSelectProps extends Omit<MultiSelectProps, 'data'> {
    tags: string[];
    onChange: (newTags: string[]) => void;
    isDisabled?: boolean;
}

const TagsMultiSelect: React.FC<TagsMultiSelectProps> = ({
    tags,
    onChange,
    isDisabled = false,
    maxDropdownHeight = 200,
    ...props
}) => {
    const { t } = useLocale();
    const { mutate: createTag, isLoading: isCreatingTag } = useCreateTag();
    const { data: tagsData, isLoading: isLoadingTags } = useTags();
    const handleCreateTag = useCallback(
        (query: string) => {
            const formattedQuery = query.trim();
            if (formattedQuery.length <= 0) {
                return null;
            }
            createTag(
                { name: formattedQuery },
                {
                    onSuccess: () => {
                        onChange([...(tags || []), formattedQuery]);
                    },
                },
            );
            return formattedQuery;
        },
        [createTag, tags, onChange],
    );
    return (
        <MultiSelect
            placeholder={t('audience_create.modal_tags_placeholder')}
            label={
                <Flex gap={6}>
                    <Text className="text-sm font-medium text-gray-800">
                        {t('common.tags')}
                    </Text>
                    <Text className="text-sm font-medium text-gray-600">
                        {t(
                            'custom_metric.dimension_modal_description_label_optional',
                        )}
                    </Text>
                </Flex>
            }
            value={tags}
            onChange={onChange}
            data={
                tagsData?.map((tag) => ({
                    value: tag.name,
                    label: tag.name,
                })) || []
            }
            searchable
            creatable
            getCreateLabel={(query) => (
                <CreationLabel query={query} isCreating={isCreatingTag} />
            )}
            onCreate={handleCreateTag}
            maxDropdownHeight={maxDropdownHeight}
            disabled={isLoadingTags || isCreatingTag || isDisabled}
            disableSelectedItemFiltering
            styles={{
                dropdown: {
                    zIndex: 1000,
                },
            }}
            {...props}
        />
    );
};

export default TagsMultiSelect;
