import { subject } from '@casl/ability';
import PageSpinner from '@components/PageSpinner';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import { ContentType, LightdashMode } from '@lightdash/common';
import { Button, Flex, Group, Menu, Stack, Text } from '@mantine/core';
import {
    CaretLeft,
    ChartLine,
    GridFour,
    PlusCircle,
} from '@phosphor-icons/react';

import { useState, type FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import ErrorState from '../components/common/ErrorState';
import DashboardCreateModal from '../components/common/modal/DashboardCreateModal';
import Page from '../components/common/Page/Page';
import InfiniteResourceTable from '../components/common/ResourceView/InfiniteResourceTable';
import SuboptimalState from '../components/common/SuboptimalState/SuboptimalState';
import AddResourceToSpaceModal from '../components/Explorer/SpaceBrowser/AddResourceToSpaceModal';
import CreateResourceToSpace from '../components/Explorer/SpaceBrowser/CreateResourceToSpace';
import { AddToSpaceResources } from '../components/Explorer/SpaceBrowser/types';
import ForbiddenPanel from '../components/ForbiddenPanel';
import { useDashboards } from '../hooks/dashboard/useDashboards';
import { useChartSummaries } from '../hooks/useChartSummaries';
import { ButtonVariant } from '../mantineTheme';
import useApp from '../providers/App/useApp';
import { useSpace } from '@hooks/useSpaces';

const Space: FC = () => {
    const { t } = useLocale();
    const [searchValue, setSearchValue] = useState<string>('');
    const { projectUuid, spaceUuid } = useParams<{
        projectUuid: string;
        spaceUuid: string;
    }>() as {
        projectUuid: string;
        spaceUuid: string;
    };
    const {
        data: space,
        isInitialLoading,
        error,
    } = useSpace(projectUuid, spaceUuid);
    /**
    const { data: allItems, isLoading: isContentLoading } = useContent(
        {
            projectUuids: [projectUuid],
            spaceUuids: [spaceUuid],
            pageSize: Number.MAX_SAFE_INTEGER,
        },
        {
            select: (d): ResourceViewItem[] =>
                contentToResourceViewItems(d.data),
        },
    );

    const [dashboards, charts] = useMemo(() => {
        if (allItems) {
            return [
                allItems.filter(
                    (item) => item.type === ResourceViewItemType.DASHBOARD,
                ),
                allItems.filter(
                    (item) => item.type === ResourceViewItemType.CHART,
                ),
            ];
        }

        return [[], []];
    }, [allItems]);
    const { mutate: pinSpace } = useSpacePinningMutation(projectUuid);
    */
    const { /* data: dashboards = [],*/ isInitialLoading: dashboardsLoading } =
        useDashboards(projectUuid);
    const { /*data: savedCharts = [],*/ isInitialLoading: chartsLoading } =
        useChartSummaries(projectUuid);
    // const { mutate: pinSpace } = useSpacePinningMutation(projectUuid);
    const { user, health } = useApp();

    const isDemo = health.data?.mode === LightdashMode.DEMO;
    const navigate = useNavigate();
    // const location = useLocation();

    // const [updateSpace, setUpdateSpace] = useState<boolean>(false);
    // const [deleteSpace, setDeleteSpace] = useState<boolean>(false);
    const [isCreateDashboardOpen, setIsCreateDashboardOpen] =
        useState<boolean>(false);
    const [addToSpace, setAddToSpace] = useState<AddToSpaceResources>();
    const [createToSpace, setCreateToSpace] = useState<AddToSpaceResources>();

    // const handlePinToggleSpace = useCallback(
    //     (spaceId: string) => pinSpace(spaceId),
    //     [pinSpace],
    // );

    // const allItems = useMemo(() => {
    //     const dashboardsInSpace = space?.dashboards;
    //     const chartsInSpace = space?.queries;
    //     const result = [];
    //     if (dashboardsInSpace) {
    //         result.push(
    //             ...wrapResourceView(
    //                 dashboardsInSpace,
    //                 ResourceViewItemType.DASHBOARD,
    //             ),
    //         );
    //     }
    //     if (chartsInSpace) {
    //         result.push(
    //             ...wrapResourceView(chartsInSpace, ResourceViewItemType.CHART),
    //         );
    //     }
    //     return result;
    // }, [space]);

    // const filteredItems = useMemo(() => {
    //     const fuse = new Fuse(allItems, {
    //         keys: ['data.name'],
    //         threshold: 0.3,
    //     });
    //     if (searchValue) {
    //         return fuse.search(searchValue).map((value) => value.item);
    //     }
    //     return allItems;
    // }, [searchValue, allItems]);

    if (isInitialLoading || chartsLoading || dashboardsLoading) {
        return <PageSpinner />;
        // return <LoadingState title="Loading space" />;
    }

    if (error) {
        return <ErrorState error={error.error} />;
    }

    if (space === undefined) {
        return (
            <div style={{ marginTop: '20px' }}>
                <SuboptimalState
                    title="Space does not exist"
                    description={`We could not find space with uuid ${spaceUuid}`}
                />
            </div>
        );
    }

    if (user.data?.ability?.cannot('view', subject('Space', { ...space }))) {
        return <ForbiddenPanel />;
    }

    const userCanCreateDashboards = user.data?.ability?.can(
        'create',
        subject('Dashboard', { ...space }),
    );

    const userCanCreateCharts = user.data?.ability?.can(
        'create',
        subject('SavedChart', { ...space }),
    );

    return (
        <Page
            title={space?.name}
            withCenteredRoot
            withCenteredContent
            withXLargePaddedContent
            withLargeContent
        >
            <Stack spacing="xxl" w="100%">
                <Group position="apart">
                    <Flex align={'center'} gap={'sm'}>
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            className="h-9 w-9 p-0"
                            onClick={() =>
                                navigate(`/projects/${projectUuid}/spaces`)
                            }
                        >
                            <CaretLeft weight="duotone" size={14} />
                        </Button>
                        <Text className="text-lg text-gray-800">
                            {space.name}
                        </Text>
                    </Flex>
                    {/* <PageBreadcrumbs
                        items={[
                            {
                                title: 'Spaces',
                                to: `/projects/${projectUuid}/spaces`,
                            },
                            {
                                title: space.name,
                                active: true,
                            },
                        ]}
                    /> */}

                    <Group spacing="xs">
                        <SearchInput
                            placeholder="Search"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            size="xs"
                        />
                        {!isDemo &&
                            (userCanCreateDashboards ||
                                userCanCreateCharts) && (
                                <Menu
                                    position="bottom-end"
                                    shadow="md"
                                    closeOnItemClick
                                    // withArrow
                                    arrowPosition="center"
                                >
                                    <Menu.Target>
                                        {/* <Box>
                                            <ActionIcon
                                                size={36}
                                                color="blue"
                                                variant="filled"
                                            >
                                                <MantineIcon
                                                    icon={IconPlus}
                                                    size="lg"
                                                />
                                            </ActionIcon>
                                        </Box> */}
                                        <Button
                                            leftIcon={
                                                <PlusCircle color="white" />
                                            }
                                            size="sm"
                                        >
                                            {t('common.new')}
                                        </Button>
                                    </Menu.Target>

                                    <Menu.Dropdown>
                                        {userCanCreateDashboards ? (
                                            <>
                                                {/* <Menu.Label>
                                                    Add dashboard
                                                </Menu.Label> */}

                                                {/* {dashboards.length > 0 ? (
                                                    <Menu.Item
                                                        icon={
                                                            <MantineIcon
                                                                icon={
                                                                    IconSquarePlus
                                                                }
                                                            />
                                                        }
                                                        onClick={() => {
                                                            setAddToSpace(
                                                                AddToSpaceResources.DASHBOARD,
                                                            );
                                                        }}
                                                    >
                                                        Add existing dashboard
                                                    </Menu.Item>
                                                ) : null} */}
                                                <Menu.Item
                                                    icon={
                                                        <GridFour
                                                            size={14}
                                                            weight="duotone"
                                                        />
                                                    }
                                                    onClick={() => {
                                                        setIsCreateDashboardOpen(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Dashboard
                                                </Menu.Item>
                                            </>
                                        ) : null}

                                        {/* {userCanCreateDashboards &&
                                            userCanCreateCharts && (
                                                <Menu.Divider />
                                            )} */}

                                        {userCanCreateCharts ? (
                                            <>
                                                {/* <Menu.Label>
                                                    Add chart
                                                </Menu.Label> */}

                                                {/* {charts.length > 0 ? (
                                                    <Menu.Item
                                                        icon={
                                                            <MantineIcon
                                                                icon={
                                                                    IconSquarePlus
                                                                }
                                                            />
                                                        }
                                                        onClick={() => {
                                                            setAddToSpace(
                                                                AddToSpaceResources.CHART,
                                                            );
                                                        }}
                                                    >
                                                        Add existing chart
                                                    </Menu.Item>
                                                ) : null} */}

                                                <Menu.Item
                                                    icon={
                                                        <ChartLine
                                                            size={14}
                                                            weight="duotone"
                                                        />
                                                    }
                                                    onClick={() => {
                                                        setCreateToSpace(
                                                            AddToSpaceResources.CHART,
                                                        );
                                                    }}
                                                >
                                                    Chart
                                                </Menu.Item>
                                            </>
                                        ) : null}
                                    </Menu.Dropdown>
                                </Menu>
                            )}
                        {/**<Can I="manage" this={subject('Space', space)}>
                            {!!space && (
                                <ShareSpaceModal
                                    space={space}
                                    projectUuid={projectUuid}
                                />
                            )}
                            <SpaceBrowserMenu
                                onRename={() => setUpdateSpace(true)}
                                onDelete={() => setDeleteSpace(true)}
                                onTogglePin={() =>
                                    handlePinToggleSpace(space?.uuid)
                                }
                                isPinned={!!space?.pinnedListUuid}
                            >
                                <ActionIcon variant="default" size={36}>
                                    <MantineIcon icon={IconDots} size="lg" />
                                </ActionIcon>
                            </SpaceBrowserMenu>
                            {updateSpace && (
                                <SpaceActionModal
                                    projectUuid={projectUuid}
                                    spaceUuid={space?.uuid}
                                    actionType={ActionType.UPDATE}
                                    title="Update space"
                                    confirmButtonLabel="Update"
                                    icon={IconFolderCog}
                                    onClose={() => setUpdateSpace(false)}
                                />
                            )}
                            {deleteSpace && (
                                <SpaceActionModal
                                    projectUuid={projectUuid}
                                    spaceUuid={space?.uuid}
                                    actionType={ActionType.DELETE}
                                    title="Delete space"
                                    confirmButtonLabel="Delete"
                                    confirmButtonColor="red"
                                    icon={IconFolderX}
                                    onSubmitForm={() => {
                                        if (
                                            location.pathname.includes(
                                                space?.uuid,
                                            )
                                        ) {
                                            //Redirect to home if we are on the space we are deleting
                                            void navigate(
                                                `/projects/${projectUuid}/home`,
                                            );
                                        }
                                    }}
                                    onClose={() => {
                                        setDeleteSpace(false);
                                    }}
                                />
                            )}
                        </Can> */}
                    </Group>
                </Group>

                <InfiniteResourceTable
                    filters={{
                        projectUuid,
                        spaceUuids: [spaceUuid],
                    }}
                    contentTypeFilter={{
                        defaultValue: ContentType.DASHBOARD,
                        options: [ContentType.DASHBOARD, ContentType.CHART],
                    }}
                />

                {addToSpace && (
                    <AddResourceToSpaceModal
                        resourceType={addToSpace}
                        onClose={() => setAddToSpace(undefined)}
                    />
                )}

                {createToSpace && (
                    <CreateResourceToSpace resourceType={createToSpace} />
                )}

                <DashboardCreateModal
                    projectUuid={projectUuid}
                    defaultSpaceUuid={space.uuid}
                    opened={isCreateDashboardOpen}
                    onClose={() => setIsCreateDashboardOpen(false)}
                    onConfirm={(dashboard) => {
                        void navigate(
                            `/projects/${projectUuid}/dashboards/${dashboard.uuid}/edit`,
                        );

                        setIsCreateDashboardOpen(false);
                    }}
                />
            </Stack>
        </Page>
    );
};

export default Space;
