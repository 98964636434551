import InputErrorText from '@components/common/InputErrorText';
import { useLocale } from '@hooks/useLocale';
import {
    Badge,
    Box,
    Flex,
    Loader,
    MultiSelect as MantineMultiSelect,
    Stack,
    Text,
    type MultiSelectProps as MantineMultiSelectProps,
} from '@mantine/core';
import { CaretDown, Check, X } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    label: string;
}
interface CreationLabelProps {
    query: string;
    isCreating?: boolean;
}

export const CreationLabel: React.FC<CreationLabelProps> = ({
    query,
    isCreating = false,
}) => {
    const { t } = useLocale();

    return (
        <Flex align="center" gap={8}>
            {isCreating ? (
                <Loader size="xs" />
            ) : (
                <Flex gap={4} align="center">
                    <Badge
                        size="lg"
                        variant="filled"
                        className="rounded-[4px] px-1 py-0.5 text-sm normal-case"
                    >
                        {t('common.new_tag')}
                    </Badge>
                    <Text className="text-sm font-medium text-gray-800">
                        {query}
                    </Text>
                </Flex>
            )}
        </Flex>
    );
};

const MultiSelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, ...others }: ItemProps, ref) => (
        <Box ref={ref} {...others}>
            <Box className="flex justify-between items-center gap-1">
                <Text className="text-sm font-medium text-gray-800">
                    {label}
                </Text>
                <Check
                    size={13}
                    strokeWidth={2.5}
                    color={'rgb(var(--color-gray-600))'}
                    className="selected__item--icon-selected"
                />
            </Box>
        </Box>
    ),
);

interface MultiSelectProps extends MantineMultiSelectProps {
    readOnlyClasses?: string;
    newOptionLabel?: string;
    clearable?: boolean;
}

const MultiSelect = React.forwardRef<HTMLInputElement, MultiSelectProps>(
    (
        {
            error,
            readOnly,
            readOnlyClasses,
            newOptionLabel,
            clearable,
            data,
            ...rest
        },
        ref,
    ) => {
        const { t } = useLocale();

        return (
            <Stack spacing={'xs'}>
                <MantineMultiSelect
                    rightSection={
                        clearable && rest.value?.length ? (
                            <X
                                color={'rgb(var(--color-gray-600))'}
                                className="cursor-pointer selected__item--icon-selected"
                                weight="bold"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    rest.onChange?.([]);
                                }}
                            />
                        ) : rest.disabled ? null : (
                            <CaretDown
                                size={13}
                                strokeWidth={2.5}
                                color={'rgb(var(--color-gray-600))'}
                                className="selected__item--icon-selected"
                            />
                        )
                    }
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'auto' } }}
                    itemComponent={MultiSelectItem}
                    ref={ref}
                    getCreateLabel={(query) => {
                        if (!query) return null;
                        return (
                            <Flex>
                                <Flex
                                    justify={'center'}
                                    align={'center'}
                                    className="h-5 px-1 mr-1 text-xs text-white bg-blu-800 border rounded-md"
                                >
                                    {newOptionLabel ?? t('common.new')}
                                </Flex>
                                <Text>{query}</Text>
                            </Flex>
                        );
                    }}
                    data={
                        data?.length
                            ? data
                            : [
                                  {
                                      value: '',
                                      label: t('common.empty_data_select'),
                                      disabled: true,
                                  },
                              ]
                    }
                    {...rest}
                />
                {error && <InputErrorText value={error} />}
            </Stack>
        );
    },
);

export default React.memo(MultiSelect);
