import {
    type CampaignBuilderStep,
    type CampaignContentStep,
} from '@components/Campaigns/Builder/types';
import {
    type Audience,
    type AudienceType,
    type CampaignScheduleType,
    type CampaignStatus,
    type CampaignThrottleConfig,
    type CampaignType,
    type CampaignUpdateRequest,
    type CommunicationChannel,
    type ContentMappings,
    type CSVUploadDetailsType,
    type FieldType,
    type Integration,
    type MaterializationFieldType,
    type RecurringDetails,
    type SenderDetails,
    type TemplateContentDetails,
    type TemplateDetails,
} from '@lightdash/common';
import { type Editor } from 'grapesjs';

export type TrackedLinks = Record<
    string,
    {
        value: string;
        defaultValue: string;
        type: MaterializationFieldType;
        enableTracking?: boolean;
    }
>;

export interface CurrentStepCallbackProps {
    callback: (() => void) | null;
    skipExecutionAfterCallback?: boolean;
}

export interface CampaignContext {
    state: CampaignState;
    actions: {
        setCampaignAudience: (audience: Audience | null | undefined) => void;
        setCampaignName: (value: string) => void;
        setCampaignDescription: (value: string) => void;
        setCampaignScheduleType: (value: CampaignScheduleType) => void;
        setCurrentStep: (value: CampaignBuilderStep) => void;
        setSchedulerExecutionTime: (value: Date) => void;
        setCurrentStepCallback: ({
            callback,
            skipExecutionAfterCallback,
        }: CurrentStepCallbackProps) => void;
        setPreviousStepCallback: ({
            callback,
            skipExecutionAfterCallback,
        }: CurrentStepCallbackProps) => void;
        setCampaignChannelPayload: (channel: Integration | null) => void;
        setCampaignContentPayload: (
            content: TemplateContentDetails | null,
        ) => void;
        setShowFooterButtons: (value: { next: boolean; back: boolean }) => void;
        setSendToVariableMapping: (value: {
            fieldKey: string;
            fieldType: FieldType;
        }) => void;
        setSubscriptionGroup: (val: string) => void;
        setAudienceCsvData: (data: unknown[]) => void;
        setGrapesPreviewInstance: (editor: Editor) => void;
        setTrackedLinks: (link: TrackedLinks) => void;
        setUtmTracking: (utmObject: Record<string, string>) => void;
        setAudienceCsvUploadData: (
            payload: CSVUploadDetailsType | undefined,
        ) => void;
        setCampaignType: (type: AudienceType) => void;
        setCampaignChannel: (channel: CommunicationChannel) => void;
        setSchedulerEndTime: (value: Date | null) => void;
        setCron: (value: string) => void;
        setRecurringDetails: (value: RecurringDetails | undefined) => void;
        setCampaignRunType: (value: CampaignType) => void;
        setTemplateDetails: (value: TemplateDetails) => void;
        setContentDetails: (value: TemplateContentDetails) => void;
        setContentMappings: (value: ContentMappings) => void;
        resetContentMappings: () => void;
        setSenderDetails: (value: SenderDetails) => void;
        setThrottleConfig: (value: CampaignThrottleConfig | undefined) => void;
        setCampaignTags: (tags: string[]) => void;
    };
}

export enum ActionType {
    SET_AUDIENCE_PAYLOAD,
    SET_CHANNEL_PAYLOAD,
    SET_CONTENT_PAYLOAD,
    SET_SCHEDULER_EXECUTION_TIME,
    SET_CAMPAIGN_NAME,
    SET_CAMPAIGN_DESCRIPTION,
    SET_CURRENT_STEP,
    SET_SCHEDULE_TYPE,
    SET_EXECUTION_TYPE,
    SET_CURRENT_STEP_CALLBACK,
    SET_PREVIOUS_STEP_CALLBACK,
    SET_SHOW_FOOTER_BUTTONS,
    SET_SEND_TO_VARIABLE_MAPPING,
    SET_SUBSCRIPTION_GROUP,
    SET_AUDIENCE_UPLOAD_LINK,
    SET_AUDIENCE_CSV_DATA,
    SET_GRAPES_PREVIEW_INSTANCE,
    SET_TRACKED_LINKS,
    SET_UTM_TRACKING,
    SET_AUDIENCE_CSV_UPLOAD_DATA,
    SET_CAMPAIGN_TYPE,
    SET_CAMPAIGN_CHANNEL,
    SET_SCHEDULER_END_TIME,
    SET_CRON,
    SET_RECURRING_DETAILS,
    SET_RECURRING_TIME,
    SET_CAMPAIGN_RUN_TYPE,
    SET_TEMPLATE_DETAILS,
    SET_CONTENT_DETAILS,
    SET_CONTENT_MAPPINGS,
    RESET_CONTENT_MAPPINGS,
    RESET_STATE,
    SET_SENDER_DETAILS,
    SET_THROTTLE_CONFIG,
    SET_CAMPAIGN_TAGS,
}

export interface CampaignReducerState {
    campaignPayload: CampaignUpdateRequest;
    currentStep: CampaignBuilderStep;
    currentStepCallback: (() => boolean) | null;
    previousStepCallback: (() => boolean) | null;
    showFooterButtons: {
        next: boolean;
        back: boolean;
    };
    audienceCsvData?: unknown[];
    editor: Editor | undefined;
    status: CampaignStatus | null;
}

export interface CampaignState extends CampaignReducerState {
    isEditMode: boolean | undefined;
    uuid: string | undefined;
    isNewMode: boolean | undefined;
    isValidStep: (step: CampaignBuilderStep | CampaignContentStep) => boolean;
}
