import TextArea from '@components/common/Inputs/TextArea';
import TextInput from '@components/common/Inputs/TextInput';
import Modal from '@components/common/modal/Modal';
import TagsMultiSelect from '@components/common/Tags/TagsMultiSelect';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Stack, Text } from '@mantine/core';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import _ from 'lodash';
import { useCallback, useMemo, useState, type FC } from 'react';
import { ButtonVariant } from '../../../../mantineTheme';

interface JourneyTitleModalProps {
    isEditMode: boolean;
    opened: boolean;
    open: () => void;
    close: () => void;
}
const JourneyTitleModal: FC<JourneyTitleModalProps> = ({
    isEditMode,
    opened,
    close,
}) => {
    const { t } = useLocale();
    const { journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );
    const { updateJourneyPayload } = useJourneyBuilderContext(
        (context) => context.actions,
    );
    const [tempName, setTempName] = useState(journeyPayload.name);
    const [tempDescription, setTempDescription] = useState(
        journeyPayload.description,
    );
    const [tempTags, setTempTags] = useState(journeyPayload.tags);

    const handleSave = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            e.preventDefault();
            updateJourneyPayload({
                ...journeyPayload,
                name: tempName,
                description: tempDescription,
                tags: tempTags,
            });
            close();
        },
        [
            tempName,
            tempDescription,
            tempTags,
            updateJourneyPayload,
            close,
            journeyPayload,
        ],
    );

    const hasChanges = useMemo(() => {
        return (
            tempName?.trim() !== journeyPayload.name ||
            tempDescription?.trim() !== journeyPayload.description ||
            !_.isEqual(tempTags, journeyPayload.tags)
        );
    }, [tempName, tempDescription, tempTags, journeyPayload]);

    return (
        <Box>
            {isEditMode && (
                <Text className="cursor-pointer text-blu-800 font-medium text-sm">
                    {t('common.edit')}
                </Text>
            )}
            <Modal
                opened={opened}
                onClose={() => {
                    setTempName(journeyPayload.name);
                    setTempDescription(journeyPayload.description);
                    setTempTags(journeyPayload.tags);
                    close();
                }}
                title={t('journey_create.modal_title')}
                footerRightSection={
                    <Button
                        onClick={handleSave}
                        variant={ButtonVariant.PRIMARY}
                        disabled={!hasChanges || !isEditMode}
                    >
                        {t('common.okay')}
                    </Button>
                }
                size="lg"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Stack spacing={12}>
                    <TextInput
                        placeholder={t('journey_create.modal_name_placeholder')}
                        label={t('journey_create.modal_name_label')}
                        value={tempName}
                        onChange={(e) => setTempName(e.target.value)}
                        readOnly={!isEditMode}
                    />
                    <TextArea
                        placeholder={t(
                            'journey_create.modal_description_placeholder',
                        )}
                        label={t('journey_create.modal_description_label')}
                        value={tempDescription}
                        onChange={(e) => setTempDescription(e.target.value)}
                        readOnly={!isEditMode}
                    />
                    <TagsMultiSelect
                        tags={tempTags || []}
                        onChange={setTempTags}
                        isDisabled={!isEditMode}
                    />
                </Stack>
            </Modal>
        </Box>
    );
};

export default JourneyTitleModal;
