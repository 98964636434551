import { useParams } from 'react-router';

import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type InsertUserTraits,
    type UpdateUserTraits,
    type UserTraits,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const getUpdateTraits = async (projectUuid: string | undefined) =>
    sortmentApi<UserTraits>({
        url: `/projects/${projectUuid}/user-traits`,
        method: 'GET',
        body: undefined,
    });

export const useUpdateTraits = (projectUuid: string | undefined) => {
    return useQuery<UserTraits, ApiError>({
        queryKey: [QueryKeys.GET_UPDATE_TRAITS, projectUuid],
        queryFn: () => getUpdateTraits(projectUuid),
        enabled: !!projectUuid,
    });
};

const createUpdateTrait = async (projectUuid: string, data: InsertUserTraits) =>
    sortmentApi<UserTraits>({
        url: `/projects/${projectUuid}/user-traits`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useCreateUpdateTraitMutation = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: InsertUserTraits) =>
            createUpdateTrait(projectUuid, data),
        onSuccess: async () => {
            showToastSuccess({
                title: t('update_traits.create_success_msg'),
            });
            await queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_UPDATE_TRAITS, projectUuid],
            });
        },
        onError: () => {
            showToastError({
                title: t('update_traits.create_error_msg'),
            });
        },
    });
};

const updateUpdateTrait = async (projectUuid: string, data: UpdateUserTraits) =>
    sortmentApi<UserTraits>({
        url: `/projects/${projectUuid}/user-traits`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useUpdateUpdateTraitMutation = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: UpdateUserTraits) =>
            updateUpdateTrait(projectUuid ?? '', data),
        onSuccess: async () => {
            showToastSuccess({
                title: t('update_traits.update_success_msg'),
            });
            await queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_UPDATE_TRAITS, projectUuid],
            });
        },
        onError: () => {
            showToastError({
                title: t('update_traits.update_error_msg'),
            });
        },
    });
};
