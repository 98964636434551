import Checkbox from '@components/common/Checkbox';
import TextInput from '@components/common/Inputs/TextInput';
import TextInputVariableWidth from '@components/common/Inputs/TextInputVariableWidth';
import { useLocale } from '@hooks/useLocale';
import { type ExperimentVariant } from '@lightdash/common';
import { Button, Grid, Group, Stack, Text } from '@mantine/core';
import { DiceFive, Percent, PlusCircle, X } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface VariantDistributionProps {
    variantsConfig: ExperimentVariant[];
    onVariantsConfigChange: (config: ExperimentVariant[]) => void;
    nodeId: string;
}

interface VariantTileProps {
    variant: ExperimentVariant;
    handleVariantChange: (variant: ExperimentVariant) => void;
    handleRemoveVariant: (variantId: string) => void;
}

const VariantTile: React.FC<VariantTileProps> = React.memo(
    ({ variant, handleVariantChange, handleRemoveVariant }) => {
        return (
            <Stack
                className={`items-center justify-between w-full p-3 rounded-lg min-h-24 relative ${
                    variant.isControl ? 'bg-gray-100' : 'bg-pink-800/15'
                }`}
            >
                <TextInput
                    h={36}
                    rightSection={<Percent />}
                    w={64}
                    value={variant.distributionPercentage}
                    onChange={(event) => {
                        handleVariantChange({
                            ...variant,
                            distributionPercentage: Number(event.target.value),
                        });
                    }}
                />
                <TextInputVariableWidth
                    h={36}
                    styles={() => ({
                        input: {
                            backgroundColor: 'transparent',
                            border: 'none !important',
                            boxShadow: 'none !important',
                        },
                        root: {
                            ':hover': {
                                backgroundColor:
                                    'rgb(var(--color-gray-500)/0.2) !important',
                                borderRadius: '0.5rem !important',
                            },
                            '&:focus-within': {
                                backgroundColor:
                                    'rgb(var(--color-gray-500)/0.2) !important',
                                borderRadius: '0.5rem !important',
                            },
                        },
                    })}
                    value={variant.variantName}
                    onChange={(event) => {
                        handleVariantChange({
                            ...variant,
                            variantName: event.target.value,
                        });
                    }}
                />
                <X
                    size={14}
                    color="rgb(var(--color-gray-700))"
                    weight="bold"
                    className="absolute cursor-pointer right-3"
                    onClick={() => handleRemoveVariant(variant.variantId)}
                />
            </Stack>
        );
    },
);

const VariantDistribution: React.FC<VariantDistributionProps> = ({
    variantsConfig,
    onVariantsConfigChange,
    nodeId,
}) => {
    const { t } = useLocale();
    const { addEdgeWithGhostNode } = useJourneyBuilderContext(
        (context) => context.actions,
    );

    const handleAddVariant = useCallback(() => {
        const newVariant: ExperimentVariant = {
            variantId: uuidv4(),
            variantName: `Path ${variantsConfig.length + 1}`,
            distributionPercentage: 0,
            isControl: false,
            nodeId: '',
            winningVariant: false,
        };
        onVariantsConfigChange([...variantsConfig, newVariant]);
        addEdgeWithGhostNode(nodeId, undefined, undefined);
    }, [variantsConfig, onVariantsConfigChange, addEdgeWithGhostNode, nodeId]);

    /**
     * Distributes 100% equally among all variants.
     * Each variant receives an integer percentage.
     * Any remainder from the division is added to the last variant to ensure the total is 100%.
     */
    const handleDistributePathsEvenly = useCallback(() => {
        const totalVariants = variantsConfig.length;
        if (totalVariants === 0) return;

        const basePercentage = Math.floor(100 / totalVariants);
        const remainder = 100 % totalVariants;

        const updatedVariants = variantsConfig.map((variant, index) => ({
            ...variant,
            distributionPercentage:
                basePercentage + (index === totalVariants - 1 ? remainder : 0),
        }));

        onVariantsConfigChange(updatedVariants);
    }, [variantsConfig, onVariantsConfigChange]);

    const handleAddControlGroup = useCallback(() => {
        const newVariant: ExperimentVariant = {
            variantId: uuidv4(),
            variantName: 'Control',
            distributionPercentage: 0,
            isControl: true,
            nodeId: '',
            winningVariant: false,
        };
        onVariantsConfigChange([...variantsConfig, newVariant]);
        addEdgeWithGhostNode(nodeId, undefined, 'Control');
    }, [onVariantsConfigChange, variantsConfig, addEdgeWithGhostNode, nodeId]);

    const onControlGroupChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const isChecked = event.currentTarget.checked;
            if (isChecked) {
                handleAddControlGroup();
                return;
            }
            onVariantsConfigChange(
                variantsConfig.filter((variant) => !variant.isControl),
            );
        },
        [handleAddControlGroup, variantsConfig, onVariantsConfigChange],
    );

    const containsControlGroup = useMemo(
        () => variantsConfig.some((variant) => variant.isControl),
        [variantsConfig],
    );

    const handleVariantChange = useCallback(
        (updatedVariant: ExperimentVariant) => {
            onVariantsConfigChange(
                variantsConfig.map((variant) =>
                    variant.variantId === updatedVariant.variantId
                        ? updatedVariant
                        : variant,
                ),
            );
        },
        [variantsConfig, onVariantsConfigChange],
    );

    const handleRemoveVariant = useCallback(
        (variantId: string) => {
            onVariantsConfigChange(
                variantsConfig.filter(
                    (variant) => variant.variantId !== variantId,
                ),
            );
        },
        [variantsConfig, onVariantsConfigChange],
    );

    return (
        <Stack className="gap-3">
            <Group className="gap-1">
                <DiceFive size={14} color="rgb(var(--color-gray-500))" />
                <Text className="text-xs font-medium text-gray-500 uppercase">
                    {t(
                        'journey_builder.experiment.variant_distribution_section_header',
                    )}
                </Text>
            </Group>
            <Text className="text-sm font-medium text-gray-800">
                {t(
                    'journey_builder.experiment.variant_distribution_section_description',
                )}
            </Text>
            <Grid grow>
                {variantsConfig.map((variant) => (
                    <Grid.Col span={4} key={variant.variantId}>
                        <VariantTile
                            variant={variant}
                            handleVariantChange={handleVariantChange}
                            handleRemoveVariant={handleRemoveVariant}
                        />
                    </Grid.Col>
                ))}
            </Grid>

            <Group className="gap-2">
                <Button
                    variant="subtle"
                    size="md"
                    leftIcon={
                        <PlusCircle
                            size={14}
                            color="rgb(var(--color-gray-700))"
                        />
                    }
                    className="font-semibold text-gray-700 w-fit"
                    onClick={handleAddVariant}
                >
                    {t(
                        'journey_builder.experiment.variant_distribution_section_add_path',
                    )}
                </Button>
                <Button
                    variant="subtle"
                    size="md"
                    className="font-semibold text-gray-700 w-fit"
                    onClick={handleDistributePathsEvenly}
                >
                    {t(
                        'journey_builder.experiment.variant_distribution_section_distribute_paths_evenly',
                    )}
                </Button>

                <Checkbox
                    label={t(
                        'journey_builder.experiment.variant_distribution_section_add_control_group',
                    )}
                    checked={containsControlGroup}
                    onChange={onControlGroupChange}
                />
            </Group>
        </Stack>
    );
};

export default React.memo(VariantDistribution);
