import { ClockCountdown, DoorOpen, FlagCheckered } from '@phosphor-icons/react';
import React from 'react';
import ExitTriggers from '../ExitTriggers';
import Goals from '../Goals';
import OverView from '../OverviewPanel';
import Scheduling from '../SchedulingPanel';
import { JourneySettingType } from './types';

export interface SettingListItemType {
    label: string;
    children: React.ReactNode;
    leftSection?: React.ReactNode;
    type: JourneySettingType;
}

const JourneySettingsMenuGroups: SettingListItemType[][] = [
    [
        {
            label: 'Overview',
            type: JourneySettingType.OVERVIEW,
            children: <OverView />,
        },
    ],
    [
        {
            label: 'Scheduling',
            type: JourneySettingType.SCHEDULING,
            children: <Scheduling />,
            leftSection: <ClockCountdown size={14} weight="duotone" />,
        },
        {
            label: 'Exit Triggers',
            type: JourneySettingType.EXIT_TRIGGERS,
            children: <ExitTriggers />,
            leftSection: <DoorOpen size={14} weight="duotone" />,
        },
        {
            label: 'Goals',
            type: JourneySettingType.GOALS,
            children: <Goals />,
            leftSection: <FlagCheckered size={14} weight="duotone" />,
        },
    ],
];
export default JourneySettingsMenuGroups;
