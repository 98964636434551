import useNotify from '@hooks/toaster/useNotify';
import { useGenerateAudienceDescriptionWithAi } from '@hooks/useAudience';
import { useLocale } from '@hooks/useLocale';
import {
    AIMessageContext,
    AudienceStatus,
    QueryGenerationStrategy,
    type ApiSqlQueryResults,
    type Audience,
    type AudiencePreviewConfig,
    type AudiencePreviewPayload,
    type DimensionType,
    type SqlAudienceDescriptionRequest,
    type VisualAudienceDescriptionRequest,
} from '@lightdash/common';
import { Button, Select } from '@mantine/core';
import useAudienceContext from '@providers/Audience/useAudienceContext';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ButtonVariant } from '../../mantineTheme';
import useRelationContext from '../../providers/Relation/useRelationContext';
import AudiencePreviewModal from './AudiencePreview/AudiencePreviewData';
const UserSelectionWithPreview: React.FC<{
    opened: boolean;
    open: () => void;
    close: () => void;
    previewData: ApiSqlQueryResults['rows'];
    fields: Record<string, { type: DimensionType }>;
    audienceData: Audience | undefined;
    getAudiencePayload: AudiencePreviewPayload;
    initialColumns?: AudiencePreviewConfig;
    handlePreview: (fields: string[]) => Promise<void>;
}> = ({
    opened,
    close,
    previewData,
    fields,
    audienceData,
    getAudiencePayload,
    initialColumns,
    handlePreview,
}) => {
    const navigate = useNavigate();
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();
    const { t } = useLocale();
    const { actions, state } = useAudienceContext((context) => context);
    const {
        initialAudiencePayload,
        audiencePayload,
        isValidQuery,
        isCreatingAudience,
        isPublishingAudience,
        isUpdatingAudience,
    } = state;
    const { showToastError } = useNotify();
    const {
        createActivateAudience,
        updateActivateAudience,
        setInitialAudienceData,
        setAudiencePreviewConfigData,
        setUserAlias,
    } = actions;
    const { userAlias } = state.audiencePayload;
    const generationStrategy = state.audiencePayload.generationStrategy;
    const location = useLocation();
    const isNewMode = location.pathname.includes('create');
    const { activeRelationUuid } = useRelationContext();
    const [handleSaveClicked, setHandleSaveClicked] = useState(false);
    const { mutateAsync: mutateAsyncGenerateAudienceDescription } =
        useGenerateAudienceDescriptionWithAi({
            relationUuid: activeRelationUuid,
        });
    useEffect(() => {
        if (handleSaveClicked) {
            void navigate(`/projects/${projectUuid}/audiences`);
        }
    }, [navigate, initialAudiencePayload, projectUuid, handleSaveClicked]);

    const handleSaveAndPublish = useCallback(async () => {
        if (audienceData?.status === AudienceStatus.DRAFT && !isNewMode) {
            await updateActivateAudience(audienceData?.id);
            setHandleSaveClicked(true);
        } else {
            const response = await createActivateAudience();
            setInitialAudienceData();
            close();
            setHandleSaveClicked(true);
            if (!response.description) {
                let payload = undefined;
                if (
                    audiencePayload.generationStrategy ===
                        QueryGenerationStrategy.AUDIENCE_BUILDER &&
                    audiencePayload.nestedMetricQuery
                ) {
                    payload = {
                        audienceId: response.id,
                        context: AIMessageContext.VISUAL_AUDIENCE,
                        nestedMetricQuery: audiencePayload.nestedMetricQuery,
                    } as VisualAudienceDescriptionRequest;
                } else {
                    payload = {
                        audienceId: response.id,
                        sqlQuery: audiencePayload.sqlQuery,
                        context: AIMessageContext.SQL_AUDIENCE,
                    } as SqlAudienceDescriptionRequest;
                }
                await mutateAsyncGenerateAudienceDescription({
                    payload,
                });
            }
        }
    }, [
        audienceData?.status,
        audienceData?.id,
        isNewMode,
        updateActivateAudience,
        createActivateAudience,
        setInitialAudienceData,
        close,
        mutateAsyncGenerateAudienceDescription,
        audiencePayload.generationStrategy,
        audiencePayload.nestedMetricQuery,
        audiencePayload.sqlQuery,
    ]);

    return (
        <AudiencePreviewModal
            getAudiencePayload={getAudiencePayload}
            opened={opened}
            close={close}
            data={previewData}
            fields={fields}
            initialColumns={initialColumns}
            generationStrategy={audiencePayload.generationStrategy}
            isEditMode={previewData ? true : false}
            onColumnChange={(columns: string[]) => {
                if (isValidQuery) {
                    setAudiencePreviewConfigData(columns);
                }
            }}
            handlePreview={handlePreview}
            isValidQuery={isValidQuery}
            showPropertySelect={true}
            footerRightSection={
                <Button
                    disabled={
                        generationStrategy === QueryGenerationStrategy.MANUAL &&
                        !userAlias
                    }
                    loading={
                        isCreatingAudience ||
                        isPublishingAudience ||
                        isUpdatingAudience
                    }
                    variant={ButtonVariant.PRIMARY}
                    onClick={async () => {
                        if (Object.keys(fields).length === 0) {
                            showToastError({
                                title: t(
                                    'audience_builder.cant_create_audience_with_zero_count',
                                ),
                            });
                            return;
                        }
                        close();
                        await handleSaveAndPublish();
                    }}
                >
                    {t('audience_publish.modal_button')}
                </Button>
            }
            bottomSection={
                previewData?.length > 0 &&
                (generationStrategy === QueryGenerationStrategy.AI ||
                    generationStrategy === QueryGenerationStrategy.MANUAL) && (
                    <>
                        <br />
                        <Select
                            withinPortal={true}
                            value={userAlias}
                            label={t(
                                'audience_builder.user_selection_description',
                            )}
                            placeholder={t(
                                'audience_builder.user_selection_placeholder',
                            )}
                            data={Object.keys(previewData?.[0]).map(
                                (column) => ({
                                    value: column,
                                    label: column,
                                }),
                            )}
                            onChange={setUserAlias}
                        />
                    </>
                )
            }
        />
    );
};
export default React.memo(UserSelectionWithPreview);
