import useSidebarResize from '@hooks/useSidebarResize';
import {
    Card,
    Flex,
    Stack,
    Transition,
    type FlexProps,
    type MantineTransition,
} from '@mantine/core';
import {
    SIDEBAR_DEFAULT_WIDTH,
    SIDEBAR_MAX_WIDTH,
    SIDEBAR_MIN_WIDTH,
} from '@utils/constants';
import { type FC } from 'react';
import { SidebarPosition } from './types';
type Props = {
    isOpen?: boolean;
    containerProps?: FlexProps;
    children?: React.ReactNode;
    sidebarLeftPadding?: number;
};

const Sidebar: FC<Props> = ({
    isOpen = true,
    containerProps,
    children,
    sidebarLeftPadding = 200,
}) => {
    const { sidebarRef, sidebarWidth } = useSidebarResize({
        defaultWidth: SIDEBAR_DEFAULT_WIDTH,
        minWidth: SIDEBAR_MIN_WIDTH,
        position: SidebarPosition.LEFT,
    });

    const transition: MantineTransition = {
        in: {
            opacity: 1,
            marginLeft: 0,
        },
        out: {
            opacity: 0,
            marginLeft: -sidebarWidth,
        },
        transitionProperty: 'opacity, margin',
    };

    return (
        <Flex
            ref={sidebarRef}
            direction="column"
            pos="relative"
            h="100%"
            mah="100%"
            {...containerProps}
        >
            <Transition mounted={isOpen} duration={500} transition={transition}>
                {(style) => (
                    <>
                        <Card
                            component={Stack}
                            display="flex"
                            radius="unset"
                            w={sidebarWidth} //TODO: Can make this responsive based on screen size
                            style={style}
                            sx={{ flexGrow: 1 }}
                            miw={SIDEBAR_MIN_WIDTH}
                            maw={SIDEBAR_MAX_WIDTH}
                            bg="rgb(var(--color-black)/0.02)"
                            pt={32}
                            pr={12}
                            pl={sidebarLeftPadding}
                        >
                            {children}
                        </Card>
                    </>
                )}
            </Transition>
        </Flex>
    );
};

export default Sidebar;
