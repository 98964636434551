import { type ContentStepComponentProps } from '@components/Campaigns/Builder/types';
import Checkbox from '@components/common/Checkbox';
import {
    CampaignFieldType,
    CommunicationChannel,
    ContentMappingSection,
    type ExternalTemplate,
    type ParsedVariable,
    type Template,
} from '@lightdash/common';
import { Box, Group, Menu, Stack, Text } from '@mantine/core';
import { type TrackedLinks } from '@providers/Campaign/types';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import React, { useMemo } from 'react';

const EmailLinkTracking = ({
    templateMetadata,
}: {
    templateMetadata: ContentStepComponentProps['templateMetadata'];
}) => {
    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload, editor } = state;
    const { setTrackedLinks } = actions;

    const links = useMemo(
        () =>
            (campaignPayload?.channel === CommunicationChannel.WHATSAPP
                ? (templateMetadata as ExternalTemplate)?.variables
                : (templateMetadata as Template)?.contents[0]
                      .parsedVariables) as unknown as ParsedVariable[],
        [campaignPayload?.channel, templateMetadata],
    );

    const linkChildren = useMemo(() => {
        let result: {
            [key: string]: {
                tagName: string;
                imageUrl: string;
                content: string;
            };
        } = {};

        if (!editor) return result;

        const rootComponent = editor?.DomComponents.getWrapper();

        if (!rootComponent) return result;

        const linkComponents = rootComponent?.findType('link');
        linkComponents.forEach((comp) => {
            const childrenComponents = comp.components();
            let childrenComponentType = '';
            let imageUrl: string = '';
            childrenComponents.forEach((component, index) => {
                if (component.attributes.type && index === 0) {
                    childrenComponentType = component.attributes.type;
                    if (childrenComponentType === 'image') {
                        imageUrl = component.attributes.src;
                    }
                }
            });
            const child = comp?.getChildAt(0);
            const id = comp?.attributes?.attributes?.['data-srt-id'];
            if (id) {
                result = {
                    ...result,
                    [id]: {
                        tagName:
                            (childrenComponentType === 'textnode'
                                ? comp?.attributes?.type
                                : childrenComponentType) || '',
                        content:
                            childrenComponentType !== 'image'
                                ? child?.content || comp?.getInnerHTML()
                                : '',
                        imageUrl,
                    },
                };
            }
        });
        return result;
    }, [editor]);

    const trackedLinks: TrackedLinks = useMemo(() => {
        if (campaignPayload?.contentMappings) {
            return Object.keys(
                campaignPayload?.contentMappings?.[
                    ContentMappingSection.LINK_TRACKING
                ] ?? {},
            )
                .filter(
                    (mapping) =>
                        campaignPayload?.contentMappings?.[
                            ContentMappingSection.LINK_TRACKING
                        ]?.[mapping]?.enableTracking !== undefined,
                )
                .reduce((accum, curr) => {
                    if (accum) {
                        return {
                            ...accum,
                            [curr]: campaignPayload?.contentMappings?.[
                                ContentMappingSection.LINK_TRACKING
                            ]?.[curr],
                        };
                    }
                    return {
                        [curr]: campaignPayload?.contentMappings?.[
                            ContentMappingSection.LINK_TRACKING
                        ]?.[curr],
                    };
                }, {});
        }
        return [];
    }, [campaignPayload?.contentMappings]);

    const handleTrackLink = (val: boolean, link: ParsedVariable) => {
        if (val) {
            setTrackedLinks({
                ...trackedLinks,
                [link.key]: {
                    value: link.value,
                    type: CampaignFieldType.STATIC,
                    defaultValue: '',
                    enableTracking: true,
                },
            });
            return;
        }
        setTrackedLinks({
            ...trackedLinks,
            [link.key]: {
                ...trackedLinks[link.key],
                enableTracking: false,
            },
        });
    };

    if (!links) return null;

    return (
        <Box>
            {links?.map((link) => (
                <Stack className="px-3 py-2" spacing={4} key={link.key}>
                    <Group>
                        <Checkbox
                            checked={trackedLinks?.[link.key]?.enableTracking}
                            onChange={(e) =>
                                handleTrackLink(e.target.checked, link)
                            }
                        />
                        <Text>{link.value}</Text>
                    </Group>
                    {linkChildren && (
                        <Text className="flex gap-1 text-gray-600 underline">
                            <Menu
                                trigger="hover"
                                position="top"
                                disabled={!linkChildren?.[link.key]?.imageUrl}
                            >
                                <Menu.Target>
                                    <span className="capitalize">
                                        {linkChildren?.[link.key]?.tagName}{' '}
                                    </span>
                                </Menu.Target>
                                <Menu.Dropdown className="p-2 rounded-lg bg-gray-850">
                                    <Box>
                                        <img
                                            src={
                                                linkChildren?.[link.key]
                                                    ?.imageUrl
                                            }
                                            alt="something"
                                            width={500}
                                        />
                                    </Box>
                                </Menu.Dropdown>
                            </Menu>

                            {linkChildren?.[link.key]?.content ? (
                                <Text
                                    className="truncate w-[450px]"
                                    dangerouslySetInnerHTML={{
                                        __html: linkChildren?.[link.key]
                                            .content,
                                    }}
                                />
                            ) : null}
                        </Text>
                    )}
                </Stack>
            ))}
        </Box>
    );
};

export default EmailLinkTracking;
