import { useLocale } from '@hooks/useLocale';
import { Group, Text } from '@mantine/core';
import { Desktop, DeviceMobile } from '@phosphor-icons/react';
import { useCallback, useState, type FC } from 'react';
import { Devices, Theme } from './types';

interface IDeviceSwitcherProps {
    theme: Theme;
    onSwitch: (mode: Devices) => void;
}

const DeviceSwitcher: FC<IDeviceSwitcherProps> = ({
    theme,
    onSwitch,
}: IDeviceSwitcherProps) => {
    const { t } = useLocale();
    const [mode, setMode] = useState(Devices.DESKTOP);
    const toggleMode = (value: Devices) => {
        setMode(value);
        onSwitch(value);
    };

    const getIconColor = useCallback(
        (device: Devices) => {
            if (theme === Theme.LIGHT && mode === device) {
                return 'rgb(var(--color-gray-800))';
            }

            if (theme === Theme.DARK && mode === device) {
                return 'white';
            }

            return 'rgb(var(--color-gray-600))';
        },
        [theme, mode],
    );

    return (
        <Group spacing={0} className="mr-2 cursor-pointer">
            <Group
                spacing="xs"
                className={`rounded-s-full px-2.5 border py-1 items-center font-semibold ${
                    mode === Devices.DESKTOP
                        ? 'text-gray-800 bg-shade-6 dark:bg-gray-700 dark:text-white border-shade-0 dark:border-gray-700'
                        : 'text-gray-600 bg-white dark:bg-gray-850 border-shade-6 dark:border-gray-700'
                }`}
                onClick={() => toggleMode(Devices.DESKTOP)}
            >
                <Desktop color={getIconColor(Devices.DESKTOP)} />
                <Text>{t('campaigns_builder_details.device_desktop')}</Text>
            </Group>
            <Group
                spacing="xs"
                className={`rounded-e-full border px-2.5 py-1 items-center  font-semibold ${
                    mode === Devices.MOBILE
                        ? 'text-gray-800 bg-shade-6 dark:bg-gray-700 dark:text-white border-shade-0 dark:border-gray-700'
                        : 'text-gray-600 bg-white dark:bg-gray-850 border-shade-6 dark:border-gray-700'
                }`}
                onClick={() => toggleMode(Devices.MOBILE)}
            >
                <DeviceMobile color={getIconColor(Devices.MOBILE)} />
                <Text>{t('campaigns_builder_details.device_mobile')}</Text>
            </Group>
        </Group>
    );
};

export default DeviceSwitcher;
