import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import {
    type CompiledDimension,
    type CompiledRelationTable,
    type CustomAttribute,
    type InsertCustomAttribute,
    type MetricFilterRule,
    type MetricQuery,
    type MetricType,
} from '@lightdash/common';

export enum ActionType {
    OPEN_CUSTOM_METRIC_BASE_TABLE_MODAL,
    CLOSE_CUSTOM_METRIC_BASE_TABLE_MODAL,
    OPEN_CUSTOM_METRIC_MANAGER_MODAL,
    CLOSE_CUSTOM_METRIC_MANAGER_MODAL,
    SELECT_TABLE,
    SELECT_DIMENSION,
    SET_FILTERS,
    ADD_BASE_TABLE_DETAILS_TO_PAYLOAD,
    ADD_DIMENSION_DETAILS_TO_PAYLOAD,
    ADD_CUSTOM_METRIC_NAME_DETAILS,
    ADD_CUSTOM_METRIC_DESCRIPTION_DETAILS,
    ADD_FILTERS_TO_CUSTOM_METRIC,
    ADD_CUSTOM_METRIC_TYPE_DETAIL,
    SET_CUSTOM_METRIC,
    TOGGLE_IS_DUPLICATE_METRIC,
    TOGGLE_IS_VIEW_MODE,
    TOGGLE_IS_BACK_BUTTON_DISABILITY,
    RESET,
}

export interface CustomMetricReducerState {
    customAttributePayload: InsertCustomAttribute;
    initialCustomAttributePayload: InsertCustomAttribute;
    showBaseTableModal: boolean;
    showMetricManagerModal: boolean;
    duplicatedMetric: boolean;
    viewMode: boolean;
    selectedTable: CompiledRelationTable | undefined;
    selectedDimension: CompiledDimension | FieldWithSuggestions | undefined;
    filters: MetricQuery['filters'] | undefined;
    disableBackButton: boolean;
}

export type CustomMetricContextType = {
    reducerState: CustomMetricReducerState;
    actions: {
        openCustomMetricBaseTableModal: () => void;
        closeCustomMetricBaseTableModal: () => void;
        openCustomMetricManagerModal: () => void;
        closeCustomMetricManagerModal: () => void;
        selectTable: (selectedTable: CompiledRelationTable | undefined) => void;
        selectDimension: (
            selectedDimension:
                | CompiledDimension
                | FieldWithSuggestions
                | undefined,
        ) => void;
        setFilters: (filters: MetricQuery['filters']) => void;
        addBaseTableDetails: (
            selectedTable: CompiledRelationTable | undefined,
        ) => void;
        addSelectedDimensionDetails: (
            selectedDimension:
                | CompiledDimension
                | FieldWithSuggestions
                | undefined,
        ) => void;
        addNameDetailsToPayload: (name: string) => void;
        addDescriptionDetailsToPayload: (description: string) => void;
        addMetricTypeDetailsToPayload: (metric: MetricType) => void;
        addFilttersToCustomMetric: (
            filters: MetricFilterRule[] | undefined,
        ) => void;
        setCustomMetricData: (data: CustomAttribute) => void;
        toogleIsDuplicated: (canDuplicate: boolean) => void;
        toggleIsViewMode: (onlyView: boolean) => void;
        toggleIsBackButtonDisabled: (disableBackButton: boolean) => void;
        resetTheCustometricContext: (data: CustomMetricReducerState) => void;
    };
};
