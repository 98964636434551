import { Box } from '@mantine/core';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React from 'react';
import {
    BaseEdge,
    EdgeLabelRenderer,
    getBezierPath,
    type EdgeProps,
} from 'reactflow';
import { getNodeAnalytics } from '../../utils';
import EdgeAnalyticsLabel from './EdgeAnalyticsLabel';
// import AddNodeButton from '../AddNodeButton';

const BlockEdge: React.FC<EdgeProps> = ({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    source,
    // id,
}) => {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    // const { addPlaceholderNodeBetween } = useJourneyBuilderContext(
    //     (context) => context.actions,
    // );
    // const { isEditable } = useJourneyBuilderContext((context) => context.state);

    const { journeyAnalytics } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const sourceNodeAnalytics =
        journeyAnalytics && getNodeAnalytics(journeyAnalytics, source);

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <Box
                    style={{
                        transform: `translate(-1%, -50%) translate(${labelX}px,${labelY}px)`,
                    }}
                    className="absolute pointer-events-auto"
                >
                    {sourceNodeAnalytics && (
                        <EdgeAnalyticsLabel
                            nodeAnalytics={sourceNodeAnalytics}
                        />
                    )}
                </Box>
                {/* {isEditable && (
                    <Box
                        style={{
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        }}
                        className="absolute pointer-events-auto"
                    >
                        <Box className="bg-white">
                            <AddNodeButton
                                onAddNodeClick={() => {
                                    addPlaceholderNodeBetween(id);
                                }}
                            />
                        </Box>
                    </Box>
                )} */}
            </EdgeLabelRenderer>
        </>
    );
};

export default BlockEdge;
