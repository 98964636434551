import { sortmentApi } from '@api/index';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import {
    type ApiError,
    type ApiResponse,
    type ApiSuccessEmpty,
} from '@lightdash/common';
import {
    type SubscriptionCategory,
    type SubscriptionCategoryCreateRequest,
    type SubscriptionCategoryUpdateRequest,
} from '@lightdash/common/src/types/subscriptionCategory';
import {
    type CategoriesWithGroups,
    type PartialCategoriesWithGroups,
    type SubscriptionGroup,
    type SubscriptionGroupCreateRequest,
    type SubscriptionGroupUpdateRequest,
} from '@lightdash/common/src/types/subscriptionGroup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';

const getAllSubscriptionGroups = async (projectId: string, channel: string) => {
    return sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/subscription-groups/channel/${channel}`,
        method: 'GET',
        body: undefined,
    });
};

export const useGetAllSubscriptionGroups = ({
    channel,
}: {
    channel: string;
}) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const allSubscriptionGroups = useQuery<
        ApiResponse,
        ApiError,
        CategoriesWithGroups
    >({
        queryKey: [QueryKeys.GET_SUBSCRIPTION_GROUPS, channel, projectUuid],
        queryFn: () => getAllSubscriptionGroups(projectUuid, channel),
    });
    return allSubscriptionGroups;
};

const getSubscriptionGroupById = async (projectId: string, groupId: string) => {
    return sortmentApi<ApiResponse>({
        url: `/user-subscription-preferences/subscription-group?subscriptionGroupId=${groupId}&projectId=${projectId}`,
        method: 'GET',
        body: undefined,
    });
};

// in this function we are using projectUuid where there is projectId in url, but in subscription preference we have projectId as queryParams
// so in order to call the api in preferences the projectId is passed  as prop here
export const useGetSubscriptionGroupById = ({
    groupId,
    projectId,
}: {
    groupId: string;
    projectId?: string;
}) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const subscriptionGroup = useQuery<
        ApiResponse,
        ApiError,
        SubscriptionGroup
    >({
        queryKey: [QueryKeys.GET_SUBCRIPTION_GROUP_BY_ID, groupId, projectUuid],
        queryFn: () => {
            if (projectId) return getSubscriptionGroupById(projectId, groupId);
            return getSubscriptionGroupById(projectUuid, groupId);
        },
    });
    return subscriptionGroup;
};

const getAllSubscriptionCategories = async (projectId: string) => {
    return sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/subscription-categories`,
        method: 'GET',
        body: undefined,
    });
};

export const useGetAllSubscriptionCategories = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const allSubscriptionGroups = useQuery<
        ApiResponse,
        ApiError,
        SubscriptionCategory[]
    >({
        queryKey: [QueryKeys.GET_ALL_SUBSCRIPTION_CATEGORY, projectUuid],
        queryFn: () => getAllSubscriptionCategories(projectUuid),
    });
    return allSubscriptionGroups;
};

const createSubscriptionCategory = async (
    data: SubscriptionCategoryCreateRequest,
    projectId: string,
) =>
    sortmentApi<SubscriptionCategory>({
        url: `/projects/${projectId}/subscription-categories`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useCreateSubscriptionCategory = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    return useMutation<
        SubscriptionCategory,
        ApiError,
        { payload: SubscriptionCategoryCreateRequest }
    >(({ payload }) => createSubscriptionCategory(payload, projectUuid), {
        mutationKey: [QueryKeys.CREATE_SUBSCRIPTION_CATEGORY, projectUuid],
        onSuccess: async () => {
            showToastSuccess({
                title: t('subscription_category.create_success'),
            });
        },
        onError: () => {
            showToastError({
                title: t('subscription_category.create_error'),
            });
        },
    });
};

const createSubscriptionGroup = async (
    data: SubscriptionGroupCreateRequest,
    projectId: string,
) =>
    sortmentApi<SubscriptionGroup>({
        url: `/projects/${projectId}/subscription-groups`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useCreateSubscriptionGroup = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    return useMutation<
        SubscriptionGroup,
        ApiError,
        { payload: SubscriptionGroupCreateRequest }
    >(({ payload }) => createSubscriptionGroup(payload, projectUuid), {
        mutationKey: [QueryKeys.CREATE_SUBSCRIPTION_GROUP, projectUuid],
        onSuccess: async () => {
            showToastSuccess({
                title: t('subscription_group.create_success'),
            });
        },
        onError: () => {
            showToastError({
                title: t('subscription_group.create_error'),
            });
        },
    });
};

const updateSubscriptionGroup = async (
    data: SubscriptionGroupUpdateRequest,
    projectId: string,
    groupId: string,
) =>
    sortmentApi<SubscriptionGroup>({
        url: `/projects/${projectId}/subscription-groups/${groupId}`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useUpdateSubscriptionGroup = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    return useMutation<
        SubscriptionGroup,
        ApiError,
        { groupId: string; payload: SubscriptionGroupUpdateRequest }
    >(
        ({ payload, groupId }) =>
            updateSubscriptionGroup(payload, projectUuid, groupId),
        {
            mutationKey: [QueryKeys.UPDATE_SUBSCRIPTION_GROUP, projectUuid],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('subscription_group.update_success'),
                });
            },
            onError: () => {
                showToastError({
                    title: t('subscription_group.update_error'),
                });
            },
        },
    );
};

const deleteSubscriptionGroup = async (groupId: string, projectUuid: string) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/subscription-groups/${groupId}`,
        method: 'DELETE',
        body: undefined,
    });

export const useDeleteSubscriptionGroup = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useMutation<ApiSuccessEmpty, ApiError, { groupId: string }>(
        ({ groupId }) => deleteSubscriptionGroup(groupId, projectUuid),
        {
            mutationKey: [QueryKeys.DELETE_SUBSCRIPTION_GROUP, projectUuid],
        },
    );
};

const getSubscriptionCategoryById = async (
    projectId: string,
    categoryId: string,
) => {
    return sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/subscription-categories/${categoryId}`,
        method: 'GET',
        body: undefined,
    });
};

export const useGetSubscriptionCategoryById = ({
    categoryId,
}: {
    categoryId: string;
}) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const subscriptionCategory = useQuery<
        ApiResponse,
        ApiError,
        SubscriptionCategory
    >({
        queryKey: [
            QueryKeys.GET_SUBCRIPTION_CATEGORY_BY_ID,
            categoryId,
            projectUuid,
        ],
        queryFn: () => getSubscriptionCategoryById(projectUuid, categoryId),
    });
    return subscriptionCategory;
};

const updateSubscriptionCategory = async (
    data: SubscriptionCategoryUpdateRequest,
    projectId: string,
    categoryId: string,
) =>
    sortmentApi<SubscriptionCategory>({
        url: `/projects/${projectId}/subscription-categories/${categoryId}`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useUpdateSubscriptionCategory = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useMutation<
        SubscriptionCategory,
        ApiError,
        { categoryId: string; payload: SubscriptionCategoryUpdateRequest }
    >(
        ({ payload, categoryId }) =>
            updateSubscriptionCategory(payload, projectUuid, categoryId),
        {
            mutationKey: [
                QueryKeys.UPDATE_SUBCRIPTION_CATEGORY_BY_ID,
                projectUuid,
            ],
        },
    );
};

const deleteSubscriptionCategory = async (
    categoryId: string,
    projectUuid: string,
) =>
    sortmentApi<null>({
        url: `/projects/${projectUuid}/subscription-categories/${categoryId}`,
        method: 'DELETE',
        body: undefined,
    });

export const useDeleteSubscriptionCategory = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useMutation<null, ApiError, { catgoryId: string }>(
        ({ catgoryId }) => deleteSubscriptionCategory(catgoryId, projectUuid),
        {
            mutationKey: [QueryKeys.DELETE_SUBSCRIPTION_CATEORY, projectUuid],
        },
    );
};

const updateTree = async (
    data: PartialCategoriesWithGroups,
    projectId: string,
) =>
    sortmentApi<string>({
        url: `/projects/${projectId}/subscription-groups/update-weights`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useUpdateTree = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useMutation<
        string,
        ApiError,
        { payload: PartialCategoriesWithGroups }
    >(({ payload }) => updateTree(payload, projectUuid), {
        mutationKey: [QueryKeys.UPDATE_TREE, projectUuid],
    });
};

const getAllDefaultSubscriptionGroups = async (
    projectId: string,
    channel: string,
) => {
    return sortmentApi<SubscriptionGroup>({
        url: `/projects/${projectId}/subscription-groups/channel-default/${channel}`,
        method: 'GET',
        body: undefined,
    });
};

export const useGetAllDefaultSubscriptionGroups = ({
    channel,
}: {
    channel: string;
}) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const allSubscriptionGroups = useQuery<SubscriptionGroup, ApiError>({
        queryKey: [
            QueryKeys.GET_DEFAULT_SUBSCRIPTION_GROUPS,
            channel,
            projectUuid,
        ],
        queryFn: () => getAllDefaultSubscriptionGroups(projectUuid, channel),
    });
    return allSubscriptionGroups;
};
