import type { ApiErrorDetail } from '@lightdash/common';
import { Box, Button, Flex, type ButtonProps } from '@mantine/core';
import { notifications, type NotificationProps } from '@mantine/notifications';
import { type PolymorphicComponentProps } from '@mantine/utils';
import {
    CheckCircle,
    Info,
    Warning,
    WarningCircle,
} from '@phosphor-icons/react';
import { type Icon } from '@tabler/icons-react';

import { useCallback, useRef, type ReactNode } from 'react';
import { v4 as uuid } from 'uuid';
import { ButtonVariant } from '../../mantineTheme';

type NotificationData = Omit<
    Parameters<typeof notifications.show>[0],
    'message' | 'key'
> & {
    key?: string;
    subtitle?: string | ReactNode;
    action?: PolymorphicComponentProps<'button', ButtonProps> & {
        icon?: Icon;
    };
    optionalAction?: PolymorphicComponentProps<'button', ButtonProps> & {
        icon?: Icon;
    };
    info?: string | ReactNode;
};

const useNotify = () => {
    const openedKeys = useRef(new Set<string>());
    const showToast = useCallback(
        ({
            key = uuid(),
            subtitle,
            action,
            color: toastColor,
            autoClose = 5000,
            optionalAction,
            info,
            ...rest
        }: NotificationData) => {
            const commonProps = {
                autoClose,
                styles: {
                    title: {
                        color: 'rgb(var(--color-gray-800))',
                        fontWeight: 600,
                        marginBottom: '0.5rem',
                    },
                    closeButton: {
                        ':hover': {
                            background: 'rgb(--color-shade-4)',
                        },
                        color: 'rgb(var(--color-gray-700))',
                        display: 'flex',
                        height: '1.1rem',
                        borderRadius: '1.1rem',
                    },
                    icon: {
                        backgroundColor: 'white',
                        marginRight: 0,
                        height: '1.25rem',
                    },

                    root: {
                        border: '1px solid',
                        borderColor: 'rgba(0, 0, 0, 0.06)',
                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06) inset',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'start',
                        padding: '1rem',
                        paddingBottom: '0.5rem',
                        maxHeight: '100% !important',
                    },
                    loader: {
                        marginRight: '0.4rem !important',
                        width: '1rem !important',
                        height: '1.25rem !important',
                    },
                },

                message: (
                    <Flex
                        direction="column"
                        align="flex-start"
                        gap={8}
                        className={
                            !subtitle && !info && !action && !optionalAction
                                ? ''
                                : 'pb-2'
                        }
                    >
                        {subtitle && (
                            <Box className="text-xs text-gray-600">
                                {subtitle}
                            </Box>
                        )}

                        {info && (
                            <Box className="p-2 text-xs text-gray-600 bg-gray-100 rounded-lg">
                                {info}
                            </Box>
                        )}
                        <Flex gap={12} align="center">
                            {action && (
                                <Button
                                    {...action}
                                    variant={ButtonVariant.SUBTLE_ACCENTED}
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>,
                                    ) => {
                                        notifications.hide(key);
                                        action.onClick?.(e);
                                    }}
                                />
                            )}
                            {optionalAction && (
                                <Button
                                    {...optionalAction}
                                    variant={ButtonVariant.SUBTLE_ACCENTED}
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>,
                                    ) => {
                                        notifications.hide(key);
                                        optionalAction.onClick?.(e);
                                    }}
                                />
                            )}
                        </Flex>
                    </Flex>
                ),
                onClose: (props: NotificationProps) => {
                    rest.onClose?.(props);
                    if (props.id) openedKeys.current.delete(props.id);
                },
            };

            const method = openedKeys.current.has(key) ? 'update' : 'show';

            if (method === 'show') {
                openedKeys.current.add(key);
            }
            notifications[method]({
                id: key,
                ...commonProps,
                ...rest,
            });
        },
        [],
    );

    const showToastSuccess = useCallback(
        (props: NotificationData) => {
            showToast({
                color: '',
                icon: (
                    <CheckCircle
                        color="rgb(var(--color-green))"
                        weight="duotone"
                    />
                ),
                ...props,
            });
        },
        [showToast],
    );
    const showToastError = useCallback(
        (props: NotificationData) => {
            showToast({
                icon: (
                    <WarningCircle
                        color="rgb(var(--color-halt-800))"
                        weight="duotone"
                    />
                ),
                autoClose: 60000,
                ...props,
            });
        },
        [showToast],
    );

    const showToastApiError = useCallback(
        (
            props: Omit<NotificationData, 'subtitle'> & {
                apiError: ApiErrorDetail;
            },
        ) => {
            const title: ReactNode | undefined = props.title ?? 'Error';

            showToast({
                icon: (
                    <WarningCircle
                        color="rgb(var(--color-halt-800))"
                        weight="duotone"
                    />
                ),
                autoClose: 60000,
                title,
                info: props.apiError?.message,
                ...props,
            });
        },
        [showToast],
    );

    const showToastInfo = useCallback(
        (props: NotificationData) => {
            showToast({
                icon: (
                    <Info color="rgb(var(--color-gray-600))" weight="duotone" />
                ),
                ...props,
            });
        },
        [showToast],
    );

    const showToastWarning = useCallback(
        (props: NotificationData) => {
            showToast({
                icon: (
                    <Warning
                        color="rgb(var(--color-mustard-800))"
                        weight="duotone"
                    />
                ),
                ...props,
            });
        },
        [showToast],
    );
    const closeToast = useCallback((key: string) => {
        notifications.hide(key);
    }, []);
    return {
        showToastSuccess,
        showToastApiError,
        showToastError,
        showToastInfo,
        showToastWarning,
        closeToast,
    };
};

export default useNotify;
