import { getDurationValue } from '@components/common/Time/utils';
import TruncatedText from '@components/common/TruncateAtMiddleText';
import {
    ControlPanel,
    JourneyEdgeEnum,
    JourneyNodeEnum,
    type JourneyBlockConfigType,
    type JourneyGroupConfigType,
    type JourneyNodeData,
    type JourneyNodeEveryOneElseData,
} from '@components/Journeys/Builder/types';
import {
    ActionType,
    CommunicationChannel,
    DifferenceOperator,
    FieldType,
    JourneyEventColumns,
    JourneyEventNames,
    JourneyGroup,
    PeriodType,
    ReservedEventColumns,
    TimeConfigType,
    UpdateTraitType,
    type ActionTypeConfigMap,
    type ActionTypeWithConfig,
    type ApiAction,
    type ApiActionConfig,
    type BaseTrigger,
    type ExperimentAction,
    type ExperimentActionConfig,
    type ExternalCampaignTriggerRequest,
    type FilterAction,
    type FilterActionConfig,
    type JourneyAction,
    type JourneyBlocksList,
    type JourneyCreatePayload,
    type JourneyEntryLogic,
    type JourneyNode,
    type SendMessageAction,
    type SendMessageActionConfig,
    type SetAction,
    type SetActionConfig,
    type SplitAction,
    type SplitActionConfig,
    type UpdateTraitAction,
    type UpdateTraitBaseConfig,
    type UpdateTraitConfig,
    type WaitAction,
    type WaitActionConfig,
    type WaitUntilAction,
    type WaitUntilActionConfig,
} from '@lightdash/common';
import { Group, Text } from '@mantine/core';
import {
    type AnalyticsMap,
    type JourneyAnalytics,
} from '@pages/JourneyBuilder';
import {
    Alarm,
    ArrowsCounterClockwise,
    ArrowsSplit,
    ChatCircleText,
    CursorClick,
    Envelope,
    Flask,
    FunnelSimple,
    Hourglass,
    HourglassHigh,
    PaperPlaneTilt,
    PauseCircle,
    PlayCircle,
    Terminal,
    WhatsappLogo,
} from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { durationTypeOptions } from '@utils/constants';
import { generateShortUUID } from '@utils/helpers';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { type Edge, type Node } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import { isSplitBlock } from './ControlPanel/Actions/Split/utils';
import { useJourneyNodeParams } from './ControlPanel/useJourneyNodeParams';
import { getJourneyEventPropertySelectList } from './JourneyFilters/useJourneyProperties';
import { getEdgeLabel } from './ReactFlow/utils';

export const journeyGroupConfig: JourneyGroupConfigType = {
    [JourneyGroup.TRIGGER]: {
        title: 'Triggers',
        description: undefined,
        iconColor: 'rgb(var(--color-blu-800))',
    },
    [JourneyGroup.ACTION]: {
        title: 'Actions',
        description: undefined,
        iconColor: 'rgb(var(--color-green))',
    },
    [JourneyGroup.FLOW_CONTROL]: {
        title: 'Flow Control',
        description: undefined,
        iconColor: 'rgb(var(--color-halt-850))',
    },
    [JourneyGroup.DELAY]: {
        title: 'Delays',
        description: undefined,
        iconColor: 'rgb(var(--color-mustard-800))',
    },
};

export const journeyBlockConfig: JourneyBlockConfigType = {
    [ActionType.FILTER]: {
        getIcon: (color) => <FunnelSimple color={color} />,
    },
    [ActionType.SPLIT]: {
        getIcon: (color) => <ArrowsSplit color={color} />,
    },
    [ActionType.WAIT]: {
        getIcon: (color) => <Hourglass color={color} />,
    },
    [ActionType.WAIT_UNTIL]: {
        getIcon: (color) => <PauseCircle color={color} />,
    },
    [ActionType.API]: {
        getIcon: () => (
            <Terminal weight="duotone" color={'rgb(var(--color-purple)'} />
        ),
    },
    [ActionType.SEND_MESSAGE]: {
        getIcon: (color) => <PaperPlaneTilt color={color} />,
    },
    [ActionType.SET]: {
        getIcon: (color) => <PaperPlaneTilt color={color} />,
    },
    [ActionType.UPDATE_TRAIT]: {
        getIcon: () => (
            <ArrowsCounterClockwise color={'rgb(var(--color-gray-700))'} />
        ),
    },
    [ActionType.EXPERIMENT]: {
        getIcon: () => <Flask color={'rgb(var(--color-pink-800))'} />,
    },
};

export const getBlockIcon = ({
    type,
    actions,
    group,
    iconColor,
}: {
    type: JourneyNodeEnum;
    actions: (ActionTypeWithConfig<ActionType> & {
        payload?: JourneyAction;
    })[];
    group: JourneyGroup;
    iconColor?: string;
}) => {
    if (!type || !actions || !group) return null; //Can be defaulted to a default icon
    if (type === JourneyNodeEnum.TRIGGER) {
        return <PlayCircle color={iconColor ?? 'rgb(var(--color-blu-800))'} />;
    }
    if (
        type === JourneyNodeEnum.BLOCK &&
        actions.length &&
        actions[0].actionType
    ) {
        const color =
            journeyGroupConfig[group].iconColor ?? 'rgb(var(--color-blu-800))';

        if (actions[0].actionType === ActionType.WAIT) {
            switch ((actions[0].config as WaitActionConfig)?.type) {
                case TimeConfigType.DURATION:
                    return <HourglassHigh color={color} />;
                case TimeConfigType.DATE_TIME:
                    return <Alarm color={color} />;
            }
        }

        if (actions[0].actionType === ActionType.SEND_MESSAGE) {
            const channelType =
                (actions[0].config as SendMessageActionConfig)?.channelType ??
                (actions[0].payload?.config as SendMessageAction['config'])
                    ?.messageConfig?.channel;

            switch (channelType) {
                case CommunicationChannel.EMAIL:
                    return <Envelope color={iconColor ?? color} />;
                case CommunicationChannel.SMS:
                    return <ChatCircleText color={iconColor ?? color} />;
                default:
                    return <WhatsappLogo color={iconColor ?? color} />;
            }
        }

        return journeyBlockConfig[actions[0].actionType]?.getIcon(
            iconColor ?? color,
        ); //Defaulting to first action type
    }
};

export const getControlPanelType = (
    nodeData: JourneyNodeData,
): ControlPanel => {
    switch (nodeData.type) {
        case JourneyNodeEnum.BLOCK:
            return ControlPanel.BLOCK_CONFIG;
        case JourneyNodeEnum.TRIGGER:
            return ControlPanel.TRIGGER_CONFIG;
        case JourneyNodeEnum.PLACEHOLDER:
            return nodeData.placeHolderType === JourneyNodeEnum.TRIGGER
                ? ControlPanel.TRIGGERS
                : ControlPanel.BLOCKS_LIST;
        default:
            throw new Error('Unknown JourneyNodeEnum type');
    }
};

export const getEdgeId = (sourceId: string, targetId: string) => {
    return `e-${sourceId}-${targetId}`;
};

type ActionSeedDataFunction<T extends JourneyAction, U extends ActionType> = (
    config?: ActionTypeConfigMap[U],
) => T;

const getWaitActionSeedData: ActionSeedDataFunction<
    WaitAction,
    ActionType.WAIT
> = (config?: WaitActionConfig) => {
    if (config && config.type === TimeConfigType.DURATION) {
        return {
            type: ActionType.WAIT,
            config: {
                timeConfig: {
                    timeConfigType: TimeConfigType.DURATION,
                    duration: 60000,
                },
                responseConfig: {},
            },
            uiConfig: {
                timeGranularity: PeriodType.MINUTE,
            },
            actionConfig: config,
        };
    }
    if (config && config.type === TimeConfigType.DATE_TIME) {
        return {
            type: ActionType.WAIT,
            config: {
                timeConfig: {
                    timeConfigType: TimeConfigType.DATE_TIME,
                    dateTime: {
                        value: '',
                        time: '00:00:00',
                    },
                    timeDifference: {
                        value: 0,
                        operator: DifferenceOperator.ADD,
                    },
                },
                responseConfig: {},
            },
            actionConfig: config,
        };
    }

    return {
        type: ActionType.WAIT,
        config: {
            timeConfig: {
                timeConfigType: TimeConfigType.DURATION,
                duration: 0,
            },
            responseConfig: {},
        },
        uiConfig: {
            timeGranularity: PeriodType.HOUR,
        },
        actionConfig: config,
    };
};

const getWaitUntilActionSeedData: ActionSeedDataFunction<
    WaitUntilAction,
    ActionType.WAIT_UNTIL
> = (config?: WaitUntilActionConfig) => {
    return {
        type: ActionType.WAIT_UNTIL,
        config: {
            timeConfig: {
                timeConfigType: TimeConfigType.DURATION,
                duration: 0,
            },
            eventConfig: {
                eventName: '',
                eventSource: '',
            },
            responseConfig: {},
        },
        uiConfig: {
            timeGranularity: PeriodType.HOUR,
        },
        actionConfig: config,
    };
};

const getFilterActionSeedData: ActionSeedDataFunction<
    FilterAction,
    ActionType.FILTER
> = (config?: FilterActionConfig) => ({
    type: ActionType.FILTER,
    config: {
        filterConfig: {
            id: '',
            journeyFilters: undefined,
            audienceFilters: undefined,
        },
        responseConfig: {},
    },
    actionConfig: config,
});

const getSplitActionSeedData: ActionSeedDataFunction<
    SplitAction,
    ActionType.SPLIT
> = (config?: SplitActionConfig) => ({
    type: ActionType.SPLIT,
    config: {
        responseConfig: {},
    },
    actionConfig: config,
});

const getSetActionSeedData: ActionSeedDataFunction<
    SetAction,
    ActionType.SET
> = (config?: SetActionConfig) => ({
    type: ActionType.SET,
    config: {
        payloadMapper: {},
        responseConfig: {},
    },
    actionConfig: config,
});

const getSendMessageActionSeedData: ActionSeedDataFunction<
    SendMessageAction,
    ActionType.SEND_MESSAGE
> = (config?: SendMessageActionConfig) => ({
    type: ActionType.SEND_MESSAGE,
    config: {
        messageConfig: {
            communicationDetails: undefined,
            channel: config?.channelType ?? CommunicationChannel.EMAIL,
            contentMappings: {},
            sendTo: {
                type: FieldType.DIMENSION,
            },
        } as ExternalCampaignTriggerRequest,
        responseConfig: {},
    },
    actionConfig: config,
});

const getApiActionSeedData: ActionSeedDataFunction<
    ApiAction,
    ActionType.API
> = (config?: ApiActionConfig) => ({
    type: ActionType.API,
    config: {
        apiConfig: {
            payloadMapper: '',
            httpConfig: {
                url: '',
                method: 'GET',
                headers: '',
            },
        },
        responseConfig: {},
    },
    warehouseFields: [],
    actionConfig: config,
});

const getUpdateTraitActionSeedData: ActionSeedDataFunction<
    UpdateTraitAction,
    ActionType.UPDATE_TRAIT
> = (config?: UpdateTraitConfig) => ({
    type: ActionType.UPDATE_TRAIT,
    config: {
        updateTraitConfig: [
            {
                name: '',
                updateConfig: {
                    updateTraitType: UpdateTraitType.BASE,
                    config: {
                        value: '',
                    },
                } as UpdateTraitBaseConfig,
            },
        ],
        responseConfig: {},
    },
    actionConfig: config,
});

const getExperimentActionSeedData: ActionSeedDataFunction<
    ExperimentAction,
    ActionType.EXPERIMENT
> = () => ({
    type: ActionType.EXPERIMENT,
    config: {
        experimentConfig: {
            experimentId: uuidv4(),
            experimentName: '',
            conversionEvent: {
                id: '',
                eventName: '',
                eventSource: '',
                deadline: 604800000, //Defaulting to 1 week
                uiConfig: {
                    granularity: PeriodType.DAY,
                },
            },
            conclusionConfig: {
                time: 0,
                granularity: PeriodType.DAY,
            },
            variantConfig: [
                {
                    variantId: uuidv4(),
                    variantName: 'Path 1',
                    distributionPercentage: 50,
                    nodeId: '',
                    isControl: false,
                    winningVariant: false,
                },
                {
                    variantId: uuidv4(),
                    variantName: 'Path 2',
                    distributionPercentage: 50,
                    nodeId: '',
                    isControl: false,
                    winningVariant: false,
                },
            ],
        },
        responseConfig: {},
    },
});

function getSeedDataByType<T extends ActionType>(
    actionType: T,
    config: ActionTypeConfigMap[T],
): JourneyAction | null {
    switch (actionType) {
        case ActionType.WAIT:
            return getWaitActionSeedData(config as WaitActionConfig);
        case ActionType.WAIT_UNTIL:
            return getWaitUntilActionSeedData(config as WaitUntilActionConfig);
        case ActionType.FILTER:
            return getFilterActionSeedData(config as FilterActionConfig);
        case ActionType.SPLIT:
            return getSplitActionSeedData(config as SplitActionConfig);
        case ActionType.SET:
            return getSetActionSeedData(config as SetActionConfig);
        case ActionType.SEND_MESSAGE:
            return getSendMessageActionSeedData(
                config as SendMessageActionConfig,
            );
        case ActionType.API:
            return getApiActionSeedData(config as ApiActionConfig);
        case ActionType.UPDATE_TRAIT:
            return getUpdateTraitActionSeedData(config as UpdateTraitConfig);
        case ActionType.EXPERIMENT:
            return getExperimentActionSeedData(
                config as ExperimentActionConfig,
            );
        default:
            return null;
    }
}

/**
 * Generates seed data for given block actions.
 *
 * @param {ActionType[]} blockActions - An array of action types.
 * @returns {AllowedActionTypes[]} - An array of seed data objects corresponding to the action types.
 */
export const getActionSeedData = (
    blockActions: ActionTypeWithConfig<ActionType>[],
): JourneyAction[] => {
    return blockActions
        .map((actionType) => {
            return getSeedDataByType(
                actionType.actionType,
                actionType.config as ActionTypeConfigMap[typeof actionType.actionType],
            );
        })
        .filter((action) => action !== null) as JourneyAction[];
};

type ExtractNodeDataFunction<T extends JourneyAction> = (
    actionConfig: T,
) => React.ReactNode;

type ExtractNodeDataMap = {
    [key in ActionType]: ExtractNodeDataFunction<any>;
};

export const extractWaitNodeValue: ExtractNodeDataFunction<
    WaitAction | WaitUntilAction
> = (actionConfig) => {
    let time;
    if (
        actionConfig.config.timeConfig?.timeConfigType ===
        TimeConfigType.DURATION
    ) {
        time = actionConfig.config.timeConfig?.duration as number; //FIXME: This is a temporary solution to ensure the time is a number
    }
    if (!time) {
        return null;
    }
    const timeGranularity = actionConfig.uiConfig?.timeGranularity;

    let timeGranularityString = 'seconds';
    if (timeGranularity) {
        const granularity = durationTypeOptions.find(
            (option) => option.value === timeGranularity,
        );
        timeGranularityString = granularity?.label ?? 'seconds';
        time = getDurationValue(time, timeGranularity);
    } else {
        time = time / 1000; //Defaulting to seconds
    }

    return (
        <Text>
            {time} {timeGranularityString}
        </Text>
    );
};

const extractWaitUntilNodeValue: ExtractNodeDataFunction<WaitUntilAction> = (
    actionConfig,
) => {
    if (
        !actionConfig?.config?.eventConfig?.eventName ||
        !actionConfig?.config?.eventConfig?.eventSource
    )
        return null;

    const nodeValueStr = `${
        actionConfig.uiConfig?.eventLabel ??
        actionConfig.config.eventConfig.eventName
    }`;
    return (
        <Group className="gap-1">
            <CursorClick size={14} />

            <Text className="truncate max-w-40">{nodeValueStr}</Text>
        </Group>
    );
};

const extractFilterNodeValue: ExtractNodeDataFunction<FilterAction> = () =>
    // actionConfig,
    {
        return null;
    };

const extractSplitNodeValue: ExtractNodeDataFunction<SplitAction> = () => {
    return <div>Split</div>;
};

const extractSetNodeValue: ExtractNodeDataFunction<SetAction> = (
    actionConfig,
) => {
    return <div>Set {JSON.stringify(actionConfig.config.payloadMapper)}</div>;
};

const extractSendMessageNodeValue: ExtractNodeDataFunction<
    SendMessageAction
> = () => {
    return null;
};

const extractApiNodeValue: ExtractNodeDataFunction<ApiAction> = (
    actionConfig,
) => {
    const displayUrl = actionConfig.config.apiConfig.httpConfig.url
        .split('&')
        .map((eachChunk) => {
            if (eachChunk.includes('$.')) {
                return '..';
            }
            return eachChunk.slice(1, -1);
        })
        .join('');
    return <TruncatedText text={displayUrl} maxWidth={105} />;
};

const extractUpdateTraitNodeValue: ExtractNodeDataFunction<
    UpdateTraitAction
> = () => {
    return null;
};

const extractExperimentNodeValue: ExtractNodeDataFunction<
    ExperimentAction
> = () => {
    return <div>Experiment</div>;
};

const extractNodeDataMap: ExtractNodeDataMap = {
    [ActionType.WAIT]: extractWaitNodeValue,
    [ActionType.WAIT_UNTIL]: extractWaitUntilNodeValue,
    [ActionType.FILTER]: extractFilterNodeValue,
    [ActionType.SPLIT]: extractSplitNodeValue,
    [ActionType.SET]: extractSetNodeValue,
    [ActionType.SEND_MESSAGE]: extractSendMessageNodeValue,
    [ActionType.API]: extractApiNodeValue,
    [ActionType.UPDATE_TRAIT]: extractUpdateTraitNodeValue,
    [ActionType.EXPERIMENT]: extractExperimentNodeValue,
};

/**
 * Extracts and returns the node value for a given action configuration.
 *
 * @param {JourneyAction[]} actionConfig - An array of action configurations.
 * @returns {React.ReactNode} - The extracted node value as a React component.
 */

export const extractNodeValue = (
    actionConfig: JourneyAction[],
): React.ReactNode => {
    const nodeData = actionConfig.map((action) => {
        const extractNodeData = extractNodeDataMap[action.type];
        return extractNodeData ? extractNodeData(action) : null;
    });

    return (
        <Group>
            {nodeData.map((node, index) => (
                <React.Fragment key={index}>
                    {node}
                    {index < nodeData.length - 1 && <span> · </span>}
                </React.Fragment>
            ))}
        </Group>
    );
};

type CheckNodeErrorFunction<T extends JourneyAction> = (
    actionConfig: T,
    journeyNodes?: JourneyNode[],
    nodeId?: string,
) => boolean;

type CheckNodeErrorMap = {
    [key in ActionType]: CheckNodeErrorFunction<any>;
};

/**
 * Checks if the wait action configuration has an error.
 *
 * @param {WaitAction} actionConfig - The wait action configuration.
 * @returns {boolean} - True if the time is missing, otherwise false.
 */
const checkWaitNodeError: CheckNodeErrorFunction<WaitAction> = (
    actionConfig,
) => {
    if (!actionConfig.config.timeConfig) return false;

    return (
        (actionConfig.config.timeConfig.timeConfigType ===
            TimeConfigType.DURATION &&
            (actionConfig.config.timeConfig.duration === undefined ||
                actionConfig.config.timeConfig.duration === null ||
                actionConfig.config.timeConfig.duration === 0)) ||
        (actionConfig.config.timeConfig.timeConfigType ===
            TimeConfigType.DATE_TIME &&
            (actionConfig.config.timeConfig.dateTime.value === undefined ||
                actionConfig.config.timeConfig.dateTime.value === ''))
    );
};

/**
 * Checks if the wait until action configuration has an error.
 *
 * @param {WaitUntilAction} actionConfig - The wait until action configuration.
 * @returns {boolean} - True if the event name or event source is missing, or the time is missing, otherwise false.
 */
const checkWaitUntilNodeError: CheckNodeErrorFunction<WaitUntilAction> = (
    actionConfig,
) => {
    return (
        !actionConfig.config.eventConfig.eventName ||
        !actionConfig.config.eventConfig.eventSource ||
        (actionConfig.config.timeConfig.timeConfigType ===
            TimeConfigType.DURATION &&
            (actionConfig.config.timeConfig.duration === undefined ||
                actionConfig.config.timeConfig.duration === null))
    );
};

/**
 * Checks if the filter action configuration has an error.
 *
 * @param {FilterAction} actionConfig - The filter action configuration.
 * @returns {boolean} - True if the filter configuration is empty, otherwise false.
 */
const checkFilterNodeError: CheckNodeErrorFunction<FilterAction> = (
    actionConfig,
) => {
    return (
        isEmpty(actionConfig.config.filterConfig.journeyFilters) &&
        isEmpty(actionConfig.config.filterConfig.audienceFilters)
    );
};

/**
 * Checks if the split action configuration has an error.
 *
 * @param {SplitAction} actionConfig - The split action configuration.
 * @returns {boolean} - Always returns false as no error condition is assumed for SplitAction.
 */
const checkSplitNodeError: CheckNodeErrorFunction<SplitAction> = (
    actionConfig,
    journeyNodes,
    nodeId,
) => {
    const journeyNode = journeyNodes?.find((node) => node.id === nodeId);
    if (!journeyNode) return true;

    const childNodeIds =
        journeyNode.branchConfig?.children.branches
            .filter((branch) => !branch.isDefault)
            .map((branch) => branch.destination) || [];

    const journeyNodeIds = journeyNodes?.map((node) => node.id) || [];
    return !childNodeIds.every((childNodeId) =>
        journeyNodeIds.includes(childNodeId),
    );
};

/**
 * Checks if the set action configuration has an error.
 *
 * @param {SetAction} actionConfig - The set action configuration.
 * @returns {boolean} - Always returns false as no error condition is assumed for SetAction.
 */
const checkSetNodeError: CheckNodeErrorFunction<SetAction> = () => {
    return false;
};

/**
 * Checks if the send message action configuration has an error.
 *
 * @param {SendMessageAction} actionConfig - The send message action configuration.
 * @returns {boolean} - True if the template ID or communication details ID is missing, otherwise false.
 */
const checkSendMessageNodeError: CheckNodeErrorFunction<SendMessageAction> = (
    actionConfig,
) => {
    return (
        !actionConfig.config.messageConfig.templateDetails?.id ||
        !actionConfig.config.messageConfig.communicationDetails?.id
    );
};

/**
 * Checks if the API action configuration has an error.
 *
 * @param {ApiAction} actionConfig - The API action configuration.
 * @returns {boolean} - True if the API URL is missing, otherwise false.
 */
const checkApiNodeError: CheckNodeErrorFunction<ApiAction> = (actionConfig) => {
    return !actionConfig.config.apiConfig.httpConfig.url;
};

const checkUpdateTraitNodeError: CheckNodeErrorFunction<UpdateTraitAction> = (
    actionConfig,
) => {
    return !actionConfig.config.updateTraitConfig;
};

const checkExperimentNodeError: CheckNodeErrorFunction<ExperimentAction> = (
    actionConfig,
) => {
    return !actionConfig.config.experimentConfig.experimentId;
};

/**
 * Checks if any action in the actionConfig has an error.
 *
 * @param {JourneyAction[]} actionConfig - An array of action configurations.
 * @returns {boolean} - True if any action has an error, otherwise false.
 */
const checkNodeErrorMap: CheckNodeErrorMap = {
    [ActionType.WAIT]: checkWaitNodeError,
    [ActionType.WAIT_UNTIL]: checkWaitUntilNodeError,
    [ActionType.FILTER]: checkFilterNodeError,
    [ActionType.SPLIT]: checkSplitNodeError,
    [ActionType.SET]: checkSetNodeError,
    [ActionType.SEND_MESSAGE]: checkSendMessageNodeError,
    [ActionType.API]: checkApiNodeError,
    [ActionType.UPDATE_TRAIT]: checkUpdateTraitNodeError,
    [ActionType.EXPERIMENT]: checkExperimentNodeError,
};

/**
 * Checks if any action in the actionConfig has an error.
 *
 * @param {JourneyAction[]} actionConfig - An array of action configurations.
 * @returns {boolean} - True if any action has an error, otherwise false.
 */
export const hasNodeError = (
    actionConfig: JourneyAction[],
    journeyNodes?: JourneyNode[],
    nodeId?: string,
): boolean => {
    return actionConfig.some((action) => {
        const checkNodeError = checkNodeErrorMap[action.type];
        return checkNodeError
            ? checkNodeError(action, journeyNodes, nodeId)
            : false;
    });
};

/**
 * Checks if the trigger node has an error.
 *
 * @param {BaseTrigger} triggerConfig - The trigger configuration.
 * @returns {boolean} - True if the event name or event source is missing, otherwise false.
 */
export const hasTriggerNodeError = (triggerConfig: BaseTrigger) => {
    return !triggerConfig.eventName || !triggerConfig.eventSource;
};

/**
 * Checks if the entry logic has an error.
 *
 * @param {JourneyEntryLogic} entryLogic - The entry logic configuration.
 * @returns {boolean} - True if the cooldown is missing, otherwise false.
 */
export const hasEntryLogicError = (entryLogic: JourneyEntryLogic) => {
    return entryLogic.cooldown === null || entryLogic.cooldown === 0;
};

/**
 * Checks if the actionConfig has the given action type.
 *
 * @param {JourneyAction[]} actionConfig - An array of action configurations.
 * @param {ActionType} actionType - The action type to check for.
 * @returns {boolean} - True if the action type is present, otherwise false.
 */
export const hasAction = (
    actionConfig: JourneyAction[],
    actionType: ActionType,
) => {
    return actionConfig.some((action) => action.type === actionType);
};

/**
 * Finds the parent node of the given node.
 *
 * @param {string} nodeId - The ID of the node to find the parent for.
 * @returns {string | null} - The ID of the parent node, or null if no parent is found.
 */
export const findParentNode = (
    nodeId: string,
    edges: Edge[],
): string | null => {
    const parentEdge = edges.find((edge) => edge.target === nodeId);
    return parentEdge ? parentEdge.source : null;
};

/**
 * update the placeholder node with ghost node when placeholder node is child of split block
 *@param {Node<JourneyNodeData>[]} nodes - The nodes in the journey.
 *@param {Edge[]} edges - The edges in the journey.
 *@param {JourneyCreatePayload} journeyPayload - The journey payload.
 *@param {string} targetNodeId - The ID of the node to update.
 *@param {JourneyNodeEnum} newType - The type of the new node.
 *@param {Partial<JourneyNodeData>} newData - The data of the new node.
 *@returns {Node<JourneyNodeData>[]} - The updated nodes.
 */
export const updatePlaceholdersToGhostNodes = ({
    nodes,
    edges,
    journeyPayload,
    targetNodeId,
}: {
    nodes: Node[];
    edges: Edge[];
    journeyPayload: JourneyCreatePayload;
    targetNodeId: string;
}) => {
    return nodes.map((node) => {
        if (node.id === targetNodeId) {
            return {
                ...node,
                type: JourneyNodeEnum.PLACEHOLDER,
                data: {
                    type: JourneyNodeEnum.PLACEHOLDER,
                    placeHolderType: JourneyNodeEnum.BLOCK,
                },
            };
        }
        if (node.type === JourneyNodeEnum.PLACEHOLDER) {
            const edge = edges.find((eachEdge) => eachEdge.target === node.id);
            const parentNodeData = journeyPayload?.config?.nodes.find(
                (eachNode: JourneyNode) => eachNode.id === edge?.source,
            );
            if (isSplitBlock(parentNodeData)) {
                return {
                    ...node,
                    type: JourneyNodeEnum.GHOSTNODE,
                    data: {
                        type: JourneyNodeEnum.GHOSTNODE,
                        nodeId: node.id,
                    },
                };
            }
        }
        return node;
    });
};

/**
 * Hook to get the journey event property select list for a given node.
 * @param {string} nodeId - The ID of the node to get the journey event property select list for.
 * @returns {PropertySelectListType<JourneyProperty>[]} - The journey event property select list.
 */
export const useJourneyProperty = (nodeId: string) => {
    const { edges, journeyPayload, nodes } = useJourneyBuilderContext(
        (context) => context.state,
    );
    const {
        isLoading: isJourneyNodeParamsLoading,
        eventsData,
        journeyDataSchema,
    } = useJourneyNodeParams(nodeId);

    const journeyEventsPropertySelectList = useMemo(() => {
        if (isJourneyNodeParamsLoading) return [];
        if (!eventsData || !eventsData.length || !journeyDataSchema) return [];
        return getJourneyEventPropertySelectList({
            journeyDataSchema,
            nodes,
            edges,
            nodeId,
            journeyNodes: journeyPayload.config?.nodes ?? [],
        });
    }, [
        eventsData,
        journeyDataSchema,
        nodes,
        edges,
        nodeId,
        journeyPayload.config?.nodes,
        isJourneyNodeParamsLoading,
    ]);

    return journeyEventsPropertySelectList;
};

/**this function is used to get all the child node ids of a given node
 * @param {string} eachNodeId - The ID of the node to get the child node ids for.
 * @param {Edge[]} edges - The edges in the journey.
 * @returns {string[]} - The IDs of the child nodes.
 */
export const getAllChildNodeIds = (
    eachNodeId: string,
    edges: Edge[],
): string[] => {
    const childNodeIds = edges
        .filter((edge) => edge.source === eachNodeId)
        .map((edge) => edge.target);
    return childNodeIds.reduce(
        (acc: string[], id) => acc.concat(id, getAllChildNodeIds(id, edges)),
        [] as string[],
    );
};
/**
 * this function is to get the ghost node
 * @param {string} ghostNodeId - The ID of the ghost node.
 * @returns {Node<JourneyNodeData>} - The ghost node.
 */
export const getGhostNode = (ghostNodeId: string): Node<JourneyNodeData> => {
    return {
        id: ghostNodeId,
        position: {
            x: 100,
            y: 0,
        },
        type: JourneyNodeEnum.GHOSTNODE,
        data: {
            type: JourneyNodeEnum.EVERY_ONE_ELSE,
            nodeId: ghostNodeId,
            // Add any additional properties required by JourneyNodeEveryOneElseData
        } as JourneyNodeEveryOneElseData, // Ensure the correct type is used here
    };
};

/**
 * this function is to add the additional ghost nodes and edges to the journey
 * @param {JourneyCreatePayload} journeyPayload - The journey payload.
 * @returns {Node<JourneyNodeData>[]} - The additional ghost nodes.
 * @returns {Edge[]} - The additional ghost edges.
 */
export const getAdditionalGhostNodeAndEdges = (
    journeyPayload: JourneyCreatePayload,
    blocksList: JourneyBlocksList,
) => {
    const additionalGhostNodes: Node<JourneyNodeData>[] = [];
    const additionalEdges: Edge[] = [];

    const createGhostNodeAndEdge = (
        nodeId: string,
        edgeType: JourneyEdgeEnum,
        label: string,
    ) => {
        const ghostNodeId = generateShortUUID();
        const ghostNode = getGhostNode(ghostNodeId);
        const edge = {
            id: getEdgeId(nodeId, ghostNodeId),
            source: nodeId,
            target: ghostNodeId,
            type: edgeType,
            label,
        };
        additionalGhostNodes.push(ghostNode);
        additionalEdges.push(edge);
    };

    journeyPayload.config?.nodes.forEach((node) => {
        const block = blocksList.find(
            (eachBlock) => eachBlock.id === node.metadata?.blockId,
        );

        if (block?.actions.some((action) => action.config?.allowEveryoneElse)) {
            const branches = node?.branchConfig?.children.branches;
            const hasEveryOneElse = branches?.some(
                (branch) => branch.isDefault,
            );
            const hasAtLeastNonEveryOneElse = branches?.some(
                (branch) => !branch.isDefault,
            );

            if (!hasAtLeastNonEveryOneElse && !isSplitBlock(node)) {
                createGhostNodeAndEdge(
                    node.id,
                    JourneyEdgeEnum.BRANCHING,
                    `1 ${getEdgeLabel(block?.actions)?.ifLabel}`,
                );
            }
            if (!hasEveryOneElse && !isSplitBlock(node)) {
                createGhostNodeAndEdge(
                    node.id,
                    JourneyEdgeEnum.DEFAULT,
                    `2 ${getEdgeLabel(block?.actions)?.everyoneElseLabel}`,
                );
            }
            if (
                isSplitBlock(node) &&
                !hasEveryOneElse &&
                hasAtLeastNonEveryOneElse
            ) {
                createGhostNodeAndEdge(
                    node.id,
                    JourneyEdgeEnum.DEFAULT,
                    `${branches?.length} ${
                        getEdgeLabel(block?.actions)?.everyoneElseLabel
                    }`,
                );
            }
        } else if (node.branchConfig?.children.branches.length === 0) {
            createGhostNodeAndEdge(node.id, JourneyEdgeEnum.BRANCHING, '');
        }
    });

    return { additionalGhostNodes, additionalEdges };
};

export const getNodeAnalytics = (
    journeyAnalytics: JourneyAnalytics[],
    nodeId: string,
) => {
    return journeyAnalytics.find(
        (eachAnalytics) => eachAnalytics.nodeId === nodeId,
    );
};

const isBlockWithoutEveryoneElse = (nodeId: string, nodes: JourneyNode[]) => {
    const nodeData = nodes.find((node) => node.id === nodeId);
    const hasEveryoneElse = nodeData?.branchConfig?.children.branches.some(
        (branch) => branch.isDefault,
    );
    return !hasEveryoneElse;
};

const isBlockTypeSelected = (
    nodeId: string,
    nodes: JourneyNode[],
    blockActionType: ActionType,
) => {
    const nodeData = nodes.find((node) => node.id === nodeId);
    return nodeData?.actions[0].type === blockActionType;
};

export const processJourneyAnalytics = (
    events: Record<string, unknown>[],
    journeyPayload: JourneyCreatePayload,
) => {
    const analyticsMap: AnalyticsMap = {};
    const entryNodeId = journeyPayload.triggers?.entry[0].id ?? '';
    const nodes = journeyPayload.config?.nodes ?? [];
    events.forEach((event: Record<string, unknown>) => {
        let nodeId: string = '';
        let eventName: string = '';
        let count: number = 0;

        Object.keys(event).forEach((key) => {
            if (key.includes(JourneyEventColumns.JOURNEY_NODE_ID)) {
                nodeId = event[key] as string;
            } else if (key.includes(ReservedEventColumns.EVENT_NAME)) {
                eventName = event[key] as string;
            } else if (key.includes('srt_journey_event_name_count')) {
                count = event[key] as number;
            }
        });

        if (eventName === JourneyEventNames.JOURNEY_ENTERED) {
            nodeId = entryNodeId;
        }

        if (!analyticsMap[nodeId]) {
            analyticsMap[nodeId] = {
                nodeId: nodeId,
                entryUserCount: 0,
                exitUserCount: 0,
                earlyExitUserCount: 0,
                waitingUserCount: 0,
                failedUserCount: 0,
            };
        }

        switch (eventName) {
            case JourneyEventNames.JOURNEY_ENTERED:
                analyticsMap[nodeId].entryUserCount = count;
                analyticsMap[nodeId].exitUserCount = count;
                break;
            case JourneyEventNames.JOUNREY_NODE_ENTERED:
                analyticsMap[nodeId].entryUserCount = count;
                break;
            case JourneyEventNames.JOURNEY_NODE_EXITED:
                analyticsMap[nodeId].exitUserCount = count;
                break;
            case JourneyEventNames.JOURNEY_NODE_FAILED:
                analyticsMap[nodeId].failedUserCount = count;
                break;
            case JourneyEventNames.JOURNEY_NODE_TIMEDOUT:
                analyticsMap[nodeId].earlyExitUserCount = count;
                break;
        }
    });

    nodes.forEach((node) => {
        if (!analyticsMap[node.id]) {
            return;
        }
        if (isBlockTypeSelected(node.id, nodes, ActionType.FILTER)) {
            analyticsMap[node.id].earlyExitUserCount =
                analyticsMap[node.id].entryUserCount -
                analyticsMap[node.id].exitUserCount -
                analyticsMap[node.id].failedUserCount;
            return;
        }
        analyticsMap[node.id].waitingUserCount =
            analyticsMap[node.id].entryUserCount -
            analyticsMap[node.id].exitUserCount -
            analyticsMap[node.id].failedUserCount -
            analyticsMap[node.id].earlyExitUserCount;
        if (!isBlockWithoutEveryoneElse(node.id, nodes)) {
            analyticsMap[node.id].earlyExitUserCount = 0;
        }
    });
    return Object.values(analyticsMap);
};
