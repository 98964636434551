import RouterSidebarLink from '@components/Router/RouterSidebarLink';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Divider, Flex, Stack, Title } from '@mantine/core';
import React, { useCallback } from 'react';
import { ChevronLeft } from 'react-feather';
import { useNavigate } from 'react-router';
import { ButtonVariant } from '../../../mantineTheme';
import { settingsListGroups, type SettingListItemType } from './menuItems';

const SettingsGroup = ({
    settingsGroup,
    groupIndex,
}: {
    settingsGroup: SettingListItemType[];
    groupIndex: number;
}) => (
    <>
        <Stack key={groupIndex} className="gap-1.5 py-3.5">
            {settingsGroup.map((item, itemIndex) => (
                <RouterSidebarLink
                    key={`item-${item.label || itemIndex}`}
                    label={item.label}
                    exact
                    to={item.redirectTo}
                    icon={item.leftSection}
                />
            ))}
        </Stack>
        {groupIndex < settingsListGroups().length - 1 && (
            <Divider className="ml-2.5 mr-5 border-t-shade-4" />
        )}
    </>
);

interface AccountSettingsSidebarProps {}

const AccountSettingsSidebar: React.FC<AccountSettingsSidebarProps> = ({}) => {
    const { t } = useLocale();
    const navigate = useNavigate();

    const onBackButtonClick = useCallback(() => {
        void navigate(-1);
    }, [navigate]);

    const renderedSettingsGroups = settingsListGroups().map((group, index) => (
        <React.Fragment key={`settings-group-${index}`}>
            <SettingsGroup settingsGroup={group} groupIndex={index} />
        </React.Fragment>
    ));

    return (
        <Stack sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Stack sx={{ flexGrow: 1, overflow: 'auto' }} spacing={'none'}>
                <Flex className="items-center pr-2.5 gap-1.5 pb-3.5">
                    <Button
                        variant={ButtonVariant.UNSTYLED}
                        className="w-5 h-5 p-1 hover:bg-shade-2 hover:rounded"
                        onClick={onBackButtonClick}
                    >
                        <ChevronLeft
                            size={13}
                            strokeWidth={2.5}
                            color={'rgb(var(--color-gray-600))'}
                        />
                    </Button>
                    <Title className="text-sm font-semibold text-gray-800">
                        {t('account_settings.sidebar_title')}
                    </Title>
                </Flex>

                <Divider className="ml-2.5 mr-5 border-t-shade-4" />
                <Box>{renderedSettingsGroups}</Box>
            </Stack>
        </Stack>
    );
};

export default React.memo(AccountSettingsSidebar);
