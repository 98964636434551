import Modal from '@components/common/modal/Modal';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import { useLocale } from '@hooks/useLocale';
import { Box, Flex, Input, Text } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { MagnifyingGlass } from '@phosphor-icons/react';
import useEventContext from '@providers/Events/useEventContext';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import { useMemo } from 'react';
import AddEventKeysCard from './AddEventKeysCard';

interface AddEventKeysProps {
    opened: boolean;
    close: () => void;
    addData: () => void;
}

interface ModalInputTitleProps {
    setSearch: (value: string) => void;
    title: string;
    inputPlaceholder: string;
}

export const ModalInputTitle: React.FC<ModalInputTitleProps> = ({
    setSearch,
    title,
    inputPlaceholder,
}) => (
    <Flex direction="column" gap={12}>
        <Text>{title}</Text>
        <Input
            icon={<MagnifyingGlass weight="duotone" />}
            placeholder={inputPlaceholder}
            width="100%"
            size="xs"
            radius="md"
            styles={{
                wrapper: {
                    width: '100%',
                },
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
            }
        />
    </Flex>
);

const AddEventKeysModal: React.FC<AddEventKeysProps> = ({
    opened,
    close,
    addData,
}) => {
    const { t } = useLocale();
    const jsonPayloadKeys = useEventContext(
        (context) => context.state.jsonPayloadKeys,
    );
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );

    const filterItems = useMemo(() => {
        let filteredKeys = jsonPayloadKeys;
        if (searchString !== '') {
            filteredKeys = jsonPayloadKeys.filter((item) =>
                item.key
                    .toLowerCase()
                    .includes(searchString?.toLowerCase() ?? ''),
            );
        }
        return filteredKeys.filter(
            (item, index, self) =>
                self.findIndex((obj) => obj.key === item.key) === index,
        );
    }, [jsonPayloadKeys, searchString]);

    return (
        <Modal
            opened={opened}
            onClose={() => {
                setSearchString('');
                close();
            }}
            title={
                <ModalInputTitle
                    setSearch={setSearchString}
                    title={t('event_create.properties_add_keys_modal_title')}
                    inputPlaceholder={t('profiles_view.search_placeholder')}
                />
            }
            closeButtonProps={{ className: 'absolute top-4 right-3' }}
            size={1000}
            footerRightSection={
                <ButtonGroup
                    primaryButtonLabel={t('common.save')}
                    secondaryButtonLabel={undefined}
                    handlePrimaryButtonClick={() => {
                        addData();
                        setSearchString('');
                        close();
                    }}
                    handleSecondaryButtonClick={close}
                    isUpdating={false}
                />
            }
        >
            <Box>
                <AddEventKeysCard keys={filterItems} />
            </Box>
        </Modal>
    );
};

export default AddEventKeysModal;
