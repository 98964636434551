import BuilderContainer from '@components/common/BuilderContainer';
import PageSpinner from '@components/PageSpinner';
import { useActiveProjectUuid } from '@hooks/useActiveProject';
import {
    useDuplicateCampaign,
    useGetCampaignById,
    useUpdateCampaign,
} from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { useProject } from '@hooks/useProject';
import useTimestamp from '@hooks/useTimestamp';
import {
    AudienceType,
    CommunicationChannel,
    CommunicationChannelName,
    Timezones,
    type CampaignUpdateRequest,
} from '@lightdash/common';
import { Button, Group, Skeleton, Text } from '@mantine/core';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { ChevronRight, Clock, Copy, Mail, Users } from 'react-feather';
import { useNavigate, useParams } from 'react-router';
import { ButtonVariant, PageLoaderColor } from '../../../mantineTheme';
import { toCampaignAnalyticsTimeFormat } from './utils';

enum PropertyType {
    Name = 'name',
    Description = 'description',
}

interface HandleChangesProps {
    payload: {
        name?: string;
        description?: string;
    };
    property: PropertyType;
}

type DashboardHeaderProps = {
    campaignUuid: string;
};

const CampaignAnalyticsHeader = ({ campaignUuid }: DashboardHeaderProps) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    const { mutateAsync: updateCampaign, isLoading: isUpdatingDashboard } =
        useUpdateCampaign(campaignUuid, false);

    const navigate = useNavigate();

    const { data: campaignData, isInitialLoading: isCampaignDataLoading } =
        useGetCampaignById(campaignUuid);

    const { mutate: duplicateCampaign } = useDuplicateCampaign({
        id: campaignData?.id ?? '',
        name: campaignData?.name ?? '',
        channel: campaignData?.channel ?? CommunicationChannel.EMAIL,
        description: campaignData?.description ?? '',
    });

    const campaignName = campaignData?.name || '';
    const campaignDescription = campaignData?.description || '';

    const { getTimestamp } = useTimestamp();

    const { activeProjectUuid } = useActiveProjectUuid();
    const { data: projectData } = useProject(activeProjectUuid);
    const projectTimezone = projectData?.timezone ?? Timezones.UTC;

    const offset = moment().tz(projectTimezone).format('Z');

    const handleChanges = useCallback(
        async ({
            payload: { name, description },
            property,
        }: HandleChangesProps) => {
            if (
                property === PropertyType.Name &&
                !name &&
                name === campaignName
            ) {
                return;
            } else if (
                property === PropertyType.Description &&
                description === campaignDescription
            ) {
                return;
            }

            const payload: CampaignUpdateRequest = {
                name: name || campaignName,
                description: description || campaignDescription,
                // audienceType: campaignData?.audienceType as AudienceType,
            };
            await updateCampaign(payload);
        },
        [
            // campaignData?.audienceType,
            campaignDescription,
            campaignName,
            updateCampaign,
        ],
    );

    const handleDuplicate = useCallback(() => {
        duplicateCampaign();
    }, [duplicateCampaign]);

    const handleViewCampaign = useCallback(() => {
        void navigate(
            `/projects/${projectUuid}/campaigns/${campaignUuid}/preview`,
        );
    }, [navigate, projectUuid, campaignUuid]);

    const renderBuilderContainerRightSection = useMemo(() => {
        if (!campaignUuid) return null;

        return (
            <Group className="gap-1">
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={handleDuplicate}
                    leftIcon={<Copy size={13} strokeWidth={2.5} />}
                >
                    {t('campaign_analytics.duplicate_campaign_cta')}
                </Button>

                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={handleViewCampaign}
                    rightIcon={<ChevronRight size={13} strokeWidth={2.5} />}
                >
                    {t('campaign_analytics.view_campaign_cta')}
                </Button>
            </Group>
        );
    }, [campaignUuid, handleDuplicate, t, handleViewCampaign]);

    const renderBuilderContainerLeftSection = useMemo(() => {
        if (isCampaignDataLoading) {
            return (
                <Group className="gap-1">
                    <Skeleton h={15} w={100} />
                    <span className="text-gray-600">·</span>
                    <Skeleton h={15} w={100} />
                    <span className="text-gray-600">·</span>
                    <Skeleton h={15} w={100} />
                </Group>
            );
        }

        const audienceName =
            campaignData?.audienceType === AudienceType.WAREHOUSE
                ? campaignData?.audienceName
                : campaignData?.csvUploadDetails?.fileName;
        const communicationChannel = campaignData?.channel;

        const lastRunAtEpoch =
            campaignData?.communicationDetails?.runDetails?.[0]?.runAt || 0;
        const lastRunAtDate = new Date(lastRunAtEpoch * 1000);
        const lastRunAt = getTimestamp(lastRunAtEpoch);

        return (
            <Group className="text-sm font-medium text-gray-600 gap-1.5 items-center">
                {audienceName && (
                    <Group className="gap-1.5 items-center justify-center">
                        <Users
                            size={13}
                            strokeWidth={2.5}
                            color={'rgb(var(--color-gray-600))'}
                        />
                        <Text className="truncate max-w-40">
                            {audienceName}
                        </Text>
                    </Group>
                )}

                <span className="text-gray-600">·</span>
                {communicationChannel && (
                    <>
                        <Group className="gap-1.5 items-center justify-center">
                            <Mail
                                size={13}
                                strokeWidth={2.5}
                                color={'rgb(var(--color-gray-600))'}
                            />
                            <Text>
                                {CommunicationChannelName[communicationChannel]}
                            </Text>
                        </Group>
                        <span className="text-gray-600">·</span>
                    </>
                )}

                {lastRunAtDate && (
                    <Group className="gap-1.5 items-center justify-center">
                        <Clock
                            size={13}
                            strokeWidth={2.5}
                            color={'rgb(var(--color-gray-600))'}
                        />
                        <Text>{`Ran at ${toCampaignAnalyticsTimeFormat(
                            lastRunAt,
                        )}`}</Text>
                        <Text className="text-gray-600 text-sm font-normal">
                            {`(GMT${offset})`}
                        </Text>
                    </Group>
                )}
            </Group>
        );
    }, [campaignData, getTimestamp, isCampaignDataLoading, offset]);

    return (
        <>
            <BuilderContainer
                isLoading={isCampaignDataLoading}
                isEditable={true}
                title={campaignData?.name || ''}
                onTitleChange={(value) =>
                    handleChanges({
                        payload: { name: value },
                        property: PropertyType.Name,
                    })
                }
                titlePlaceholder={t(
                    'campaign_analytics.dashboard_name_placeholder',
                )}
                description={campaignData?.description || ''}
                descriptionPlaceholder={t(
                    'audience_builder.description_placeholder',
                )}
                onDescriptionChange={(value) =>
                    handleChanges({
                        payload: { description: value },
                        property: PropertyType.Description,
                    })
                }
                withContentPadding={false}
                rightSection={renderBuilderContainerRightSection}
                leftSection={renderBuilderContainerLeftSection}
            />

            <PageSpinner
                isVisible={isUpdatingDashboard}
                withOverlay
                loaderColor={PageLoaderColor.GRAY}
            />
        </>
    );
};

export default CampaignAnalyticsHeader;
