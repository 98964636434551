import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider/FiltersProvider';
import FilterSelectWrapper from '@components/common/Filters/FilterInputs/FilterSelectWrapper';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { useLocale } from '@hooks/useLocale';
import {
    addFilterRule,
    isAndNestedMetricQuery,
    JourneyParamsNames,
    RelationTableType,
    ReservedEventColumns,
    type Filters,
    type JourneyFilterGroup,
    type JourneyFiltersConfig,
    type NestedMetricQueryGroup,
} from '@lightdash/common';
import { Button, Flex, Stack, Text } from '@mantine/core';
import { CaretDown, Plus } from '@phosphor-icons/react';
import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { type JourneyProperty } from '../types';
import { isCachedField } from '../utils';
import FiltersPropertySelector from './FiltersPropertySelector';

import { getUnsavedChartVersion } from '@components/Audience/utils';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { ButtonVariant } from '../../../../../mantineTheme';
import JourneyFiltersGroup from './JourneyFiltersGroup';
import useJourneyFiltersContext from './JourneyFiltersProvider/useJourneyFiltersContext';
import WarehouseFiltersGroup from './WarehouseFiltersGroup';

type Props = {
    filters: JourneyFiltersConfig;
    setFilters: (value: JourneyFiltersConfig) => void;
};

/**
 * Creates a new field rule based on whether the field is cached or not.
 * @param {JourneyProperty} field - The field to create a rule for.
 * @param {boolean} isNew - Indicates if the rule is new.
 * @returns {JourneyFiltersConfig} - The updated filters configuration.
 */
const createNewFieldRule = (
    field: JourneyProperty,
    isNew: boolean,
    journeyDataSchema: any,
    filters: JourneyFiltersConfig,
): JourneyFiltersConfig | undefined => {
    if (!journeyDataSchema) return;
    const isCached = isCachedField(field, journeyDataSchema);
    let newFilterRule;
    let audienceFilters: JourneyFiltersConfig['audienceFilters'] = isNew
        ? undefined
        : filters.audienceFilters;
    let journeyFilters: JourneyFiltersConfig['journeyFilters'] = isNew
        ? undefined
        : filters.journeyFilters;

    let fieldValue;
    //INFO: This is a proxy to config the filter rule for field of the type of Audience.
    // The fieldId is JourneyParamsNames.CACHED_AUDIENCE:srt_user_id and the value is the audience id that is stored in the table.
    if (field.tableType === RelationTableType.AUDIENCE) {
        field.name = `${JourneyParamsNames.CACHED_AUDIENCE}:${ReservedEventColumns.USER_ID}`;
        fieldValue = field.table;
    }

    if (isCached) {
        newFilterRule = addFilterRule({
            filters: isNew
                ? {}
                : ({ dimensions: filters.journeyFilters } as Filters),
            field: field,
            value: fieldValue,
        });
        const dimensions = newFilterRule.dimensions;
        if (!dimensions) return;
        journeyFilters = dimensions as JourneyFilterGroup;
    } else {
        const unsavedChartVersion = getUnsavedChartVersion(
            filters.audienceFilters?.filterConfig,
        );

        const warehouseFilters =
            unsavedChartVersion && unsavedChartVersion.length
                ? unsavedChartVersion[0].metricQuery.filters
                : {};

        newFilterRule = addFilterRule({
            filters: isNew ? {} : warehouseFilters,
            field: field,
            value: fieldValue,
        });
        const nestedMetricQuery: NestedMetricQueryGroup = {
            id: uuidv4(),
            and: [
                {
                    id: uuidv4(),
                    exploreName: '',
                    dimensions: [],
                    metrics: [],
                    filters: newFilterRule,
                    sorts: [],
                    limit: 0,
                    tableCalculations: [],
                },
            ],
        };
        const condition = isAndNestedMetricQuery(nestedMetricQuery)
            ? 'and'
            : 'or';
        audienceFilters = {
            filterConfig: nestedMetricQuery,
            filterJoinType: condition,
            compiledAudienceId: undefined,
        };
    }

    return {
        id: uuidv4(),
        journeyFilters: journeyFilters,
        audienceFilters: audienceFilters,
    };
};

const FiltersForm: React.FC<Props> = ({ filters, setFilters }) => {
    const { t } = useLocale();
    const { isEditable } = useJourneyBuilderContext((context) => context.state);
    const {
        isLoading,
        journeyDataSchema,
        warehouseFieldsMap,
        eventsData,
        eventsTableNames,
        audienceData,
    } = useJourneyFiltersContext();

    /**
     * Adds a field rule to the filters configuration.
     * @param {JourneyProperty} field - The field to add a rule for.
     * @param {boolean} isNew - Indicates if the rule is new.
     */
    const addFieldRule = useCallback(
        (field: JourneyProperty, isNew: boolean) => {
            const newFilters = createNewFieldRule(
                field,
                isNew,
                journeyDataSchema,
                filters,
            );
            if (newFilters) {
                setFilters(newFilters);
            }
        },
        [journeyDataSchema, filters, setFilters],
    );

    const { projectUuid } = useParams<{ projectUuid: string }>();

    const { audienceFilters, journeyFilters } = filters;

    const handleJourneyFiltersChange = (
        value: JourneyFiltersConfig['journeyFilters'],
    ) => {
        setFilters({ ...filters, journeyFilters: value });
    };

    const handleAudienceFiltersChange = (
        value: JourneyFiltersConfig['audienceFilters'],
    ) => {
        setFilters({ ...filters, audienceFilters: value });
    };

    if (isLoading) {
        return <SkeletonLoader height={50} />;
    }

    if (!audienceFilters && !journeyFilters) {
        return (
            <FiltersPropertySelector
                targetElement={
                    <FilterSelectWrapper isEditMode={isEditable}>
                        <Flex className="items-center justify-between !w-full">
                            <Text className="text-sm font-normal text-gray-500">
                                {t(
                                    'journey_builder.journey_filters_select_field',
                                )}
                            </Text>
                            <CaretDown
                                color={'rgb(var(--color-gray-500)'}
                                weight={'regular'}
                            />
                        </Flex>
                    </FilterSelectWrapper>
                }
                onSubmit={(item) => addFieldRule(item, true)}
                showAudienceFields={true}
                showEventsFields={true}
                disabled={!isEditable}
            />
        );
    }

    return (
        <FiltersProvider
            projectUuid={projectUuid}
            fieldsMap={warehouseFieldsMap}
            eventsMap={eventsData}
            eventTables={eventsTableNames}
            audienceData={audienceData}
            isLoading={isLoading}
        >
            <Stack>
                {/* Info: All the caches fields and journey params filter groups are rendered in JourneyFilterGroup*/}
                <JourneyFiltersGroup
                    journeyFilters={journeyFilters}
                    setJourneyFilters={handleJourneyFiltersChange}
                />

                {/* Info: All the warehouse fields filter groups are rendered in WarehouseFiltersGroup*/}
                <WarehouseFiltersGroup
                    warehouseFilters={audienceFilters}
                    setFilters={handleAudienceFiltersChange}
                />

                {/* Info: Field selector to add a new filter rule */}
                <FiltersPropertySelector
                    targetElement={
                        <Button
                            variant={ButtonVariant.SUBTLE}
                            leftIcon={<Plus weight={'bold'} size={13} />}
                        >
                            {t('custom_metric.dimension_modal.filter_label')}
                        </Button>
                    }
                    onSubmit={(item) => addFieldRule(item, false)}
                    showAudienceFields={true}
                    showEventsFields={true}
                    disabled={!isEditable}
                />
            </Stack>
        </FiltersProvider>
    );
};

export default React.memo(FiltersForm);
