import Page from '@components/Page/Page';
import { useLocale } from '@hooks/useLocale';
import { Box, Text } from '@mantine/core';
import useSchemaContext from '@providers/Schema/useSchemaContext';
import React, { useMemo } from 'react';
import StepTitle from './Steps/StepTitle';
import { SchemaBuilderSteps } from './utils';

interface SchemaBuilderPageProps {}

const SchemaBuilderPage: React.FC<SchemaBuilderPageProps> = () => {
    const { t } = useLocale();
    const { currentBuilderStep } = useSchemaContext((context) => context.state);

    const CurrentStepComponent = useMemo(
        () =>
            SchemaBuilderSteps.find((step) => step.key === currentBuilderStep)
                ?.component,
        [currentBuilderStep],
    );

    if (!CurrentStepComponent) {
        return null;
    }

    return (
        <Page
            withFullHeight={false}
            withPaddedContent
            backgroundColor="white"
            withFixedContent
            withNavbar={false}
        >
            <Box>
                <StepTitle title={t('schema_builder.table_chooser.title')} />
                <Text color={'rgb(var(--color-gray-600))'}>
                    {t('schema_builder.table_chooser.sub_title')}
                </Text>

                <CurrentStepComponent />
            </Box>
        </Page>
    );
};

export default SchemaBuilderPage;
