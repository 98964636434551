import UnsavedChangesModal from '@components/common/modal/UnsavedChangesModal';
import TimeInputWithOptions from '@components/common/Time/TimeInputWithOptions';
import BaseTriggerEventFilter from '@components/Journeys/Builder/ControlPanel/Actions/BaseTriggerEventFilter';
import { useLocale } from '@hooks/useLocale';
import { JourneyTriggerType, PeriodType } from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import { FlagCheckered } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { useCallback, useState } from 'react';
import SettingsTitle from '../SettingsTitle';
const Goals: React.FC = () => {
    const { t } = useLocale();
    const { journeyPayload, initialJourneyPayload, isEditable } =
        useJourneyBuilderContext((context) => context.state);

    const { setGoals, mutateAsyncJourney } = useJourneyBuilderContext(
        (context) => context.actions,
    );
    const { triggers } = journeyPayload;
    const { conversion } = triggers!;
    const { triggers: initialTriggers } = initialJourneyPayload;
    const { conversion: initialConversion } = initialTriggers!;
    const [isDataChanged, setIsDataChanged] = useState(false);

    const handleDurationChange = useCallback(
        (duration: number, granularity: PeriodType) => {
            setIsDataChanged(true);
            setGoals({
                deadline: duration,
                uiConfig: {
                    granularity,
                },
            });
        },
        [setGoals],
    );

    const handleSave = useCallback(async () => {
        setIsDataChanged(false);
        await mutateAsyncJourney(true);
    }, [mutateAsyncJourney]);

    const handleSecondaryClick = useCallback(() => {
        setIsDataChanged(false);
        if (initialConversion) {
            setGoals(initialConversion[0]);
        }
    }, [initialConversion, setGoals]);
    return (
        <Box>
            <SettingsTitle
                icon={<FlagCheckered size={14} weight="duotone" />}
                title={t('journey_goals.title')}
                subtitle={t('journey_goals.subtitle')}
            />
            <Box className="p-3">
                <Text>{t('journey_goals.conversion_event')}</Text>
                <Box className="mt-1.5">
                    <BaseTriggerEventFilter
                        nodeId={JourneyTriggerType.CONVERSION}
                        eventConfig={{
                            eventName:
                                (conversion && conversion[0].eventName) ?? '',
                            eventSource:
                                (conversion && conversion[0].eventSource) ?? '',
                        }}
                        setEvent={(eventName, eventSource) => {
                            setIsDataChanged(true);
                            setGoals({
                                eventName,
                                eventSource,
                            });
                        }}
                        filters={conversion && conversion[0].filterConfig}
                        setFilters={(filters) => {
                            setIsDataChanged(true);
                            setGoals({
                                filterConfig: filters,
                            });
                        }}
                    />
                </Box>
                <Text className="mt-3">{t('journey_goals.goal_deadline')}</Text>
                <TimeInputWithOptions
                    selectedDuration={
                        conversion ? conversion[0].deadline : undefined
                    }
                    onDurationChange={handleDurationChange}
                    selectedGranularity={
                        conversion &&
                        conversion[0].uiConfig &&
                        conversion[0].uiConfig.granularity
                    }
                    granularityOptions={[
                        PeriodType.DAY,
                        PeriodType.HOUR,
                        PeriodType.WEEK,
                    ]}
                    disabled={!isEditable}
                />
                <Text className="text-gray-500 mt-1.5">
                    {t('journey_goals.maximum_time')}
                </Text>
            </Box>
            <UnsavedChangesModal
                opened={isDataChanged}
                secondaryActionButtonClick={() => handleSecondaryClick()}
                disableButtons={false}
                primaryActionButtonClick={() => handleSave()}
            />
        </Box>
    );
};
export default Goals;
