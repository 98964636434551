import { useLocale } from '@hooks/useLocale';
import { Box, Group, Text } from '@mantine/core';
import { type JourneyAnalytics } from '@pages/JourneyBuilder';
import { DoorOpen } from '@phosphor-icons/react';
import { useCallback } from 'react';

interface EdgeAnalyticsLabelProps {
    nodeAnalytics: JourneyAnalytics;
    customClass?: string;
    isLeafNode?: boolean;
}

const EdgeAnalyticsLabel = ({
    nodeAnalytics,
    customClass,
    isLeafNode,
}: EdgeAnalyticsLabelProps) => {
    const { t } = useLocale();

    const analyticsGroup = useCallback(
        ({
            count,
            labelKey,
            customClassData,
        }: {
            count: number;
            labelKey: string;
            customClassData: string;
        }) => {
            return (
                <Group
                    className={`${customClassData} items-center justify-center w-full gap-1`}
                >
                    <Box className="-mt-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                        >
                            <path
                                d="M1.5 1C1.5 0.723858 1.27614 0.5 1 0.5C0.723858 0.5 0.5 0.723858 0.5 1H1.5ZM15.3534 10.3537C15.5488 10.1585 15.5489 9.84192 15.3537 9.64659L12.173 6.46336C11.9778 6.26802 11.6612 6.26789 11.4659 6.46308C11.2705 6.65827 11.2704 6.97485 11.4656 7.17019L14.2929 9.99972L11.4634 12.827C11.268 13.0222 11.2679 13.3388 11.4631 13.5341C11.6583 13.7295 11.9748 13.7296 12.1702 13.5344L15.3534 10.3537ZM8.99686 9.99765L8.99706 9.49765L8.99686 9.99765ZM0.5 1V1.99764H1.5V1H0.5ZM8.99667 10.4976L14.9998 10.5L15.0002 9.5L8.99706 9.49765L8.99667 10.4976ZM0.5 1.99764C0.5 6.69076 4.30355 10.4958 8.99667 10.4976L8.99706 9.49765C4.85607 9.49602 1.5 6.13863 1.5 1.99764H0.5Z"
                                fill="#DBDBDB"
                            />
                        </svg>
                    </Box>
                    <DoorOpen size={12} />
                    <Text className="text-xs font-normal text-gray-600">
                        <span className="font-medium text-gray-800">
                            {count}
                        </span>{' '}
                        {t(labelKey)}
                    </Text>
                </Group>
            );
        },
        [t],
    );

    return (
        <Box>
            {nodeAnalytics.earlyExitUserCount > 0 &&
                analyticsGroup({
                    count: nodeAnalytics.earlyExitUserCount,
                    labelKey: 'block_edge.exited_early',
                    customClassData: customClass ?? '',
                })}
            {nodeAnalytics.failedUserCount > 0 &&
                analyticsGroup({
                    count: nodeAnalytics.failedUserCount,
                    labelKey: 'journey_analytics.failed',
                    customClassData: customClass ?? '',
                })}
            {isLeafNode &&
                nodeAnalytics.exitUserCount > 0 &&
                analyticsGroup({
                    count: nodeAnalytics.exitUserCount,
                    labelKey: 'journey_analytics.completed',
                    customClassData: customClass ?? '',
                })}
        </Box>
    );
};

export default EdgeAnalyticsLabel;
