import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import TextArea from '@components/common/Inputs/TextArea';
import TextInput from '@components/common/Inputs/TextInput';
import FieldSelect from '@components/common/Select/FieldSelect';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import { useGenerateTableDescription } from '@hooks/useRelation';
import {
    useFetchRelations,
    useRelationTable,
    useTableMetadata,
    useUpdateTableDetail,
} from '@hooks/useSchemaBuilder';
import {
    RelationTableType,
    type CreateRelationTableRequest,
    type RelationSchemaColumn,
    type WarehouseTableColumn,
} from '@lightdash/common';
import { Box, Button, Checkbox, Flex, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { CaretUpDown, Clock, CursorClick, Key } from '@phosphor-icons/react';
import { Table } from '@phosphor-icons/react/dist/ssr';
import useRelationContext from '@providers/Relation/useRelationContext';
import useSchemaContext from '@providers/Schema/useSchemaContext';
import { useQueryClient } from '@tanstack/react-query';
import { AI_BANNER_SESSION_KEY } from '@utils/constants';
import { t as translate } from 'i18next';
import { capitalize } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../../../mantineTheme';
import { SchemaBuilderStep } from '../../types';
import { SchemaBuilderSteps } from '../../utils';

export type TableConfigurationForm = {
    name: string;
    label: string;
    description: string | undefined;
    userId: string;
    primaryKey: string;
};

const getValidators = () => {
    const labelValidator = {
        label: (value: string | undefined) => {
            return !value || !value.length ? translate('required_field') : null;
        },
    };

    const primaryKeyValidator = {
        primaryKey: (value: string | undefined) => {
            return !value || !value.length ? translate('required_field') : null;
        },
    };

    return { ...labelValidator, primaryKeyValidator };
};

const PrimaryTableConfiguration: React.FC<{}> = () => {
    const { t } = useLocale();
    const [userIdSameAsPrimaryKey, setUserIdSameAsPrimaryKey] =
        useState<boolean>(true);
    const { tables } = useRelationContext();
    const {
        schemaPayload,
        activeProject,
        isEditMode,
        currentBuilderStep,
        isDrawerOpen,
    } = useSchemaContext((context) => context.state);
    const { setCurrentBuilderStep, toggleDrawer } = useSchemaContext(
        (context) => context.actions,
    );
    const [aiGeneratedTableName, setAiGeneratedTableName] =
        useState<string>('');
    const [aiGeneratedTableDescription, setAiGeneratedTableDescription] =
        useState<string>('');
    const [primaryKey, setPrimaryKey] = useState<FieldWithSuggestions>();
    const [userId, setUserId] = useState<FieldWithSuggestions>();
    const [timestampColumn, setTimestampColumn] =
        useState<FieldWithSuggestions>();
    const [eventNameColumn, setEventNameColumn] =
        useState<FieldWithSuggestions>();
    const queryClient = useQueryClient();

    const { data: relations = [] } =
        useFetchRelations(activeProject?.projectUuid) ?? {};
    const { mutateAsync: createRelationTable, isLoading: isCreatingRelation } =
        useRelationTable(relations[0]?.uuid, activeProject?.needsRelation);
    const { showToastSuccess } = useNotify();
    const { mutateAsync: updateTableDetail, isLoading: isUpdatingTableDetail } =
        useUpdateTableDetail(relations[0]?.uuid);
    const {
        mutateAsync: generateTableDescription,
        isLoading: isAiGeneratingTableDescription,
    } = useGenerateTableDescription();

    const sourceTable = useMemo(
        () => tables?.find((table) => table.name === schemaPayload.name),
        [tables, schemaPayload],
    );

    useEffect(() => {
        if (
            !schemaPayload.database ||
            !schemaPayload.schema ||
            !schemaPayload.name
        ) {
            setCurrentBuilderStep(SchemaBuilderStep.SETUP);
        }

        if (schemaPayload.primaryKey) {
            const col = schemaPayload.columns.find(
                (column: RelationSchemaColumn) =>
                    column.name === schemaPayload.primaryKey,
            );
            setPrimaryKey(col);
        }

        if (schemaPayload.userId) {
            const col = schemaPayload.columns.find(
                (column: RelationSchemaColumn) =>
                    column.name === schemaPayload.userId,
            );
            setUserId(col);
            setUserIdSameAsPrimaryKey(false);
        }

        if (schemaPayload.userId === schemaPayload.primaryKey) {
            setUserIdSameAsPrimaryKey(true);
        }

        if (schemaPayload.eventTimestampColumn) {
            const col = schemaPayload.columns.find(
                (column: RelationSchemaColumn) =>
                    column.name === schemaPayload.eventTimestampColumn,
            );
            setTimestampColumn(col);
        }

        if (schemaPayload.eventNameColumn) {
            const col = schemaPayload.columns.find(
                (column: RelationSchemaColumn) =>
                    column.name === schemaPayload.eventNameColumn,
            );
            setEventNameColumn(col);
        }
    }, [schemaPayload, setCurrentBuilderStep]);

    const form = useForm({
        initialValues: {
            label: schemaPayload.label ?? '',
            description: schemaPayload.description ?? '',
            userId: schemaPayload.userId ?? '',
            primaryKey: schemaPayload.primaryKey ?? undefined,
        },
        validate: getValidators(),
        initialErrors: {
            label: '',
            primaryKey: '',
            userId: '',
        },
    });

    const { data: tableMetadata, isLoading: isTableMetadataLoading } =
        useTableMetadata({
            database: schemaPayload.database,
            schema: schemaPayload.schema,
            table: schemaPayload.name,
        });
    useEffect(() => {
        if (tableMetadata && !isEditMode) {
            generateTableDescription({
                columns: tableMetadata.columns,
                database: schemaPayload.database,
                schema: schemaPayload.schema,
                table: schemaPayload.name,
            })
                .then((response) => {
                    setAiGeneratedTableDescription(response.tableDescription);
                    setAiGeneratedTableName(response.tableLabel);
                    form.setFieldValue(
                        'description',
                        response.tableDescription,
                    );
                    form.setFieldValue('label', response.tableLabel);
                })
                .catch((error) => {
                    console.log(error, 'error');
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        generateTableDescription,
        schemaPayload.name,
        isEditMode,
        tableMetadata,
    ]);

    const updateDetail = useCallback(
        async (field: string, value: any) => {
            if (!isEditMode) return;

            // Update table
            await updateTableDetail({
                tableName: schemaPayload.name,
                payload: {
                    type: schemaPayload.type,
                    [field]: value,
                },
            });
            await queryClient.invalidateQueries([QueryKeys.RELATION_SCHEMA]);
        },
        [isEditMode, schemaPayload, updateTableDetail, queryClient],
    );

    const handleSubmit = useCallback(
        async (
            values: Pick<
                CreateRelationTableRequest,
                'label' | 'description' | 'primaryKey'
            > & { userId: string },
        ) => {
            if (!values.label?.trim()) {
                form.setErrors({
                    ...form.errors,
                    label: t('common.error.empty_text_input'),
                });
                return;
            }
            if (!values.primaryKey) {
                form.setErrors({
                    ...form.errors,
                    primaryKey: t('common.error.empty_select_input'),
                });
                return;
            }
            if (schemaPayload.type === RelationTableType.PRIMARY) {
                if (!userIdSameAsPrimaryKey && !values.userId) {
                    form.setErrors({
                        ...form.errors,
                        userId: t('common.error.empty_select_input'),
                    });
                    return;
                }
            }
            try {
                // Validation checks

                const payload: CreateRelationTableRequest = {
                    ...schemaPayload,
                    ...values,
                };

                if (
                    'userId' in payload &&
                    userIdSameAsPrimaryKey &&
                    payload.type === RelationTableType.PRIMARY
                ) {
                    payload.userId = values.primaryKey ?? '';
                }
                if (payload.label) {
                    payload.label = payload.label.trim();
                }

                payload.dimensions = tableMetadata?.columns ?? [];

                if (!isEditMode) {
                    await createRelationTable({ payload });
                    showToastSuccess({
                        title: t(
                            'relation_provider.relation_created_successfully',
                            {
                                type: capitalize(schemaPayload.type),
                            },
                        ),
                    });

                    // Column description and AI banner updates
                    if (tableMetadata?.columns) {
                        try {
                            const oldAiBanner = JSON.parse(
                                sessionStorage.getItem(AI_BANNER_SESSION_KEY) ||
                                    '{}',
                            );
                            const newAiBanner = {
                                ...oldAiBanner,
                                [schemaPayload.name]: true,
                            };
                            sessionStorage.setItem(
                                AI_BANNER_SESSION_KEY,
                                JSON.stringify(newAiBanner),
                            );
                        } catch {}
                    }
                }
            } catch {
            } finally {
                await queryClient.invalidateQueries([
                    QueryKeys.RELATION_SCHEMA,
                    activeProject?.projectUuid,
                    relations[0]?.uuid,
                ]);
                toggleDrawer();
            }
        },
        [
            activeProject?.projectUuid,
            createRelationTable,
            form,
            isEditMode,
            queryClient,
            relations,
            schemaPayload,
            showToastSuccess,
            t,
            tableMetadata?.columns,
            toggleDrawer,
            userIdSameAsPrimaryKey,
        ],
    );

    const fieldWithSuggestionInArray = useMemo(() => {
        return tableMetadata?.columns?.map((item: WarehouseTableColumn) => {
            return {
                ...item,
                label: item.name,
                name: item.name,
                type: item.type,
            } as FieldWithSuggestions;
        });
    }, [tableMetadata?.columns]);

    const getPreviousStep = useCallback(() => {
        const currentStepIndex = SchemaBuilderSteps.findIndex(
            (step) => step.key === currentBuilderStep,
        );
        return SchemaBuilderSteps[currentStepIndex - 1]?.key;
    }, [currentBuilderStep]);

    const handlePrev = useCallback(() => {
        if (isEditMode || isCreatingRelation || isUpdatingTableDetail) return;

        const previousStep = getPreviousStep();
        if (previousStep) {
            setCurrentBuilderStep(previousStep as SchemaBuilderStep);
        }
    }, [
        getPreviousStep,
        setCurrentBuilderStep,
        isEditMode,
        isCreatingRelation,
        isUpdatingTableDetail,
    ]);

    return (
        <Box className="w-8/12 mt-4 pb-14">
            <form id="table_config_form" name="table_config_form">
                <Stack className="gap-6">
                    <Stack
                        className={`gap-1.5 ${
                            !isEditMode ? 'cursor-pointer' : ''
                        }`}
                        onClick={handlePrev}
                    >
                        <Text className="text-sm font-medium text-gray-800">
                            {t('schema_builder.table_chooser.selected_table')}
                        </Text>
                        <Flex
                            justify={'space-between'}
                            className="p-2 border-gray-300 rounded-lg border-base"
                        >
                            <Flex gap={4} align={'center'}>
                                <Text className="text-gray-600">
                                    {t(
                                        'schema_builder.table_chooser.selected_table_desc',
                                    )}
                                </Text>
                                <Table color="gray.8" />
                                <Text className="text-gray-800">
                                    {schemaPayload?.name}
                                </Text>
                            </Flex>

                            {!isEditMode && <CaretUpDown />}
                        </Flex>
                    </Stack>

                    {!isEditMode && (
                        <>
                            <Stack className="gap-1.5">
                                <TextInput
                                    data-autofocus
                                    label={
                                        <Text className="text-gray-800">
                                            {t(
                                                'primary_table_config.title_field_label',
                                            )}
                                        </Text>
                                    }
                                    placeholder={t(
                                        'schema_builder.table_chooser.table_name.placeholder',
                                    )}
                                    className="!w-[272px]"
                                    aiGeneratedData={aiGeneratedTableName}
                                    isAiGeneratingData={
                                        isAiGeneratingTableDescription ||
                                        isTableMetadataLoading
                                    }
                                    disabled={isCreatingRelation}
                                    {...form.getInputProps('label')}
                                />

                                <Text className="text-sm font-medium text-gray-500">
                                    {t(
                                        'schema_builder.table_chooser.table_name.help_text',
                                    )}
                                </Text>
                            </Stack>

                            <Stack className="gap-1.5">
                                <TextArea
                                    label={
                                        <Box>
                                            <Text className="inline me-2">
                                                {t(
                                                    'schema_builder.table_chooser.description.label',
                                                )}
                                            </Text>
                                        </Box>
                                    }
                                    placeholder={t(
                                        'schema_builder.table_chooser.description.placeholder',
                                    )}
                                    aiGeneratedData={
                                        aiGeneratedTableDescription
                                    }
                                    isAiGeneratingData={
                                        isAiGeneratingTableDescription ||
                                        isTableMetadataLoading
                                    }
                                    disabled={isCreatingRelation}
                                    {...form.getInputProps('description')}
                                />
                            </Stack>
                        </>
                    )}

                    <Stack className="gap-1.5">
                        <Text className="text-sm font-medium text-gray-800">
                            <Flex align="center">
                                <Key
                                    className="mr-1"
                                    color={
                                        isDrawerOpen
                                            ? 'rgb(var(--color-green)'
                                            : 'rgb(var(--color-blu-800))'
                                    }
                                />
                                {`${t(
                                    'schema_builder.table_chooser.primary_key.label',
                                )} `}
                            </Flex>
                        </Text>

                        <FieldSelect
                            size="sm"
                            hideTableLabel
                            item={primaryKey}
                            items={fieldWithSuggestionInArray || []}
                            placeholder={t(
                                'schema_builder.table_chooser.primary_key.label',
                            )}
                            onChange={async (value) => {
                                setPrimaryKey(value);
                                form.setFieldValue('primaryKey', value?.name);
                                await updateDetail('primaryKey', value?.name);
                            }}
                            error={form.getInputProps('primaryKey').error}
                            isDisabled={
                                isCreatingRelation || isUpdatingTableDetail
                            }
                        />

                        <Text className="text-sm font-medium text-gray-500">
                            {t(
                                'schema_builder.table_chooser.primary_key.help_text',
                            )}
                        </Text>
                    </Stack>

                    {schemaPayload.type === RelationTableType.PRIMARY && (
                        <Stack className="gap-1.5">
                            <Text className="text-sm font-medium text-gray-800">
                                {t(
                                    'schema_builder.table_chooser.unique_uid.label',
                                )}
                            </Text>

                            <Checkbox
                                label={t(
                                    'schema_builder.table_chooser.unique_uid.same',
                                )}
                                checked={userIdSameAsPrimaryKey}
                                onChange={async (event) => {
                                    setUserIdSameAsPrimaryKey(
                                        event.currentTarget.checked,
                                    );
                                    if (event.currentTarget.checked) {
                                        await updateDetail(
                                            'userId',
                                            primaryKey?.name,
                                        );
                                    }
                                }}
                                disabled={
                                    isCreatingRelation || isUpdatingTableDetail
                                }
                            />

                            <Text className="text-sm font-medium text-gray-500">
                                {t(
                                    'schema_builder.table_chooser.unique_uid.help_text',
                                )}
                            </Text>
                        </Stack>
                    )}

                    {!userIdSameAsPrimaryKey && (
                        <Stack className="gap-1.5">
                            <FieldSelect
                                item={userId}
                                items={fieldWithSuggestionInArray || []}
                                {...form.getInputProps('userId')}
                                placeholder={t(
                                    'schema_builder.table_chooser.unique_uid.label',
                                )}
                                size={'sm'}
                                hideTableLabel
                                onChange={async (value) => {
                                    setUserId(value);
                                    form.setFieldValue('userId', value?.name);
                                    await updateDetail('userId', value?.name);
                                }}
                                isDisabled={
                                    isCreatingRelation || isUpdatingTableDetail
                                }
                            />
                        </Stack>
                    )}

                    {schemaPayload.type === RelationTableType.EVENT && (
                        <>
                            <Stack className="gap-1.5">
                                <Text className="text-sm font-medium text-gray-800">
                                    <Flex align="center">
                                        <Clock
                                            className="mr-1"
                                            color={
                                                'rgb(var(--color-mustard-800))'
                                            }
                                        />
                                        Timestamp
                                    </Flex>
                                </Text>
                                <FieldSelect
                                    size="sm"
                                    placeholder="Timestamp"
                                    hideTableLabel
                                    item={timestampColumn}
                                    items={
                                        (isEditMode
                                            ? sourceTable.columns
                                            : fieldWithSuggestionInArray) || []
                                    }
                                    onChange={async (value) => {
                                        setTimestampColumn(value);
                                        form.setFieldValue(
                                            'eventTimestampColumn',
                                            value?.name,
                                        );
                                        await updateDetail(
                                            'eventTimestampColumn',
                                            value?.name,
                                        );
                                    }}
                                    isDisabled={
                                        isCreatingRelation ||
                                        isUpdatingTableDetail
                                    }
                                />
                            </Stack>

                            <Stack className="gap-1.5">
                                <Text className="text-sm font-medium text-gray-800">
                                    <Flex align="center">
                                        <CursorClick
                                            className="mr-1"
                                            color={
                                                'rgb(var(--color-mustard-800))'
                                            }
                                        />
                                        Event name
                                    </Flex>
                                </Text>
                                <FieldSelect
                                    size="sm"
                                    placeholder="Event name"
                                    hideTableLabel
                                    item={eventNameColumn}
                                    items={
                                        (isEditMode
                                            ? sourceTable.columns
                                            : fieldWithSuggestionInArray) || []
                                    }
                                    onChange={async (value) => {
                                        setEventNameColumn(value);
                                        form.setFieldValue(
                                            'eventNameColumn',
                                            value?.name,
                                        );
                                        await updateDetail(
                                            'eventNameColumn',
                                            value?.name,
                                        );
                                    }}
                                    isDisabled={
                                        isCreatingRelation ||
                                        isUpdatingTableDetail
                                    }
                                />
                            </Stack>
                        </>
                    )}
                </Stack>
            </form>
            {!isEditMode && (
                <Box className="fixed bottom-0 left-0 w-full bg-white border-t-2">
                    <Flex className="px-4 my-4" justify={'flex-end'}>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            onClick={async () => {
                                await handleSubmit(form.values);
                            }}
                            loading={isCreatingRelation}
                            disabled={isCreatingRelation}
                        >
                            {t(
                                schemaPayload.type === RelationTableType.PRIMARY
                                    ? 'schema_builder.table_chooser.looks_good'
                                    : 'common.save',
                            )}
                        </Button>
                    </Flex>
                </Box>
            )}
        </Box>
    );
};

export default React.memo(PrimaryTableConfiguration);
