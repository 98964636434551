import SearchInput from '@components/SearchInput';
import { Box, Flex, Menu, Text } from '@mantine/core';
import { CaretDown, Table } from '@phosphor-icons/react';
import React, { useMemo, useState } from 'react';

const MenuItem = ({ name, onClick }: { name: string; onClick: () => void }) => {
    return (
        <Menu.Item
            className="p-2 hover:bg-gray-100 rounded-md cursor-pointer"
            onClick={onClick}
        >
            <Flex gap={4} align="center">
                <Table />
                <Text className="font-medium text-gray-800 truncate max-w-[200px]">
                    {name}
                </Text>
            </Flex>
        </Menu.Item>
    );
};

const SelectWithSearch = ({
    value,
    options,
    onChange,
    placeholder,
}: {
    value: string;
    options: { label: string; value: string }[];
    onChange: (value: string) => void;
    placeholder?: string;
}) => {
    const [search, setSearch] = useState<string>('');

    const filteredOptions = useMemo(
        () =>
            options.filter((option) =>
                option.label.toLowerCase().includes(search.toLowerCase()),
            ),
        [options, search],
    );

    return (
        <Menu position="bottom-start" withinPortal>
            <Menu.Target>
                <Flex
                    justify="space-between"
                    align="center"
                    className={`w-full h-9 border rounded-lg p-2 hover:cursor-pointer bg-white`}
                >
                    {value ? (
                        <Text className="truncate">
                            {
                                options.find((option) => option.value === value)
                                    ?.label
                            }
                        </Text>
                    ) : (
                        <Text className="truncate text-gray-500">
                            {placeholder ?? 'Select'}
                        </Text>
                    )}
                    <CaretDown weight="regular" />
                </Flex>
            </Menu.Target>
            <Menu.Dropdown>
                <Box className="w-[260px] max-h-[300px] overflow-auto">
                    <Box className="p-1 border-b border-b-gray-200 sticky top-0 bg-white mb-2">
                        <SearchInput
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search"
                        />
                    </Box>
                    <Box>
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    name={option.label}
                                    onClick={() => onChange(option.value)}
                                />
                            ))
                        ) : (
                            <Text className="text-gray-500 p-2">
                                No options found
                            </Text>
                        )}
                    </Box>
                </Box>
            </Menu.Dropdown>
        </Menu>
    );
};

export default SelectWithSearch;
