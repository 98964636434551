import { JourneyEdgeEnum } from '@components/Journeys/Builder/types';
import { getEdgeId } from '@components/Journeys/Builder/utils';
import { useLocale } from '@hooks/useLocale';
import {
    ActionType,
    BranchConcurrencyTypes,
    BranchConditionalTypes,
    type Branch,
    type FilterRule,
    type JourneyFilterRule,
} from '@lightdash/common';
import { Button, Flex, Text } from '@mantine/core';
import { ArrowsSplit, PlusCircle } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { useCallback, useMemo } from 'react';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import {
    getFilterRuleFromConditionsInSplit,
    updateFilterRuleInJourneyFilterConfig,
} from '../utils';
import EachSplitPath from './EachSplitPath';

interface SplitPathProps {
    nodeId: string;
}

const SplitPath: React.FC<SplitPathProps> = ({ nodeId }) => {
    const { t } = useLocale();
    const {
        addEdgeWithGhostNode,
        updateBranchConfig,
        deleteAllChildBranches,
        deleteNode,
        updateBranchingEdgeLabel,
        createEveryOneElsePath,
    } = useJourneyBuilderContext((context) => context.actions);
    const { journeyPayload, splitActiveFields, edges, blocksList } =
        useJourneyBuilderContext((context) => context.state);
    const splitBlockId = useMemo(() => {
        return blocksList?.find((b) =>
            b.actions.some((a) => a.actionType === ActionType.SPLIT),
        )?.id;
    }, [blocksList]);
    const branchConfig = useMemo(() => {
        return journeyPayload.config?.nodes.find((node) => node.id === nodeId)
            ?.branchConfig;
    }, [journeyPayload.config, nodeId]);

    const handleChange = useCallback(
        (filterRule: JourneyFilterRule | FilterRule, destination: string) => {
            updateBranchConfig(nodeId, {
                type: branchConfig?.type ?? BranchConditionalTypes.IFIF,
                children: {
                    type:
                        branchConfig?.children.type ??
                        BranchConcurrencyTypes.PARALLEL,
                    branches:
                        branchConfig?.children?.branches?.map(
                            (branch: Branch) => {
                                if (
                                    branch.conditions &&
                                    branch.destination === destination
                                ) {
                                    return {
                                        ...branch,
                                        conditions:
                                            updateFilterRuleInJourneyFilterConfig(
                                                branch.conditions,
                                                filterRule,
                                                branch.conditions.journeyFilters
                                                    ? true
                                                    : false,
                                            ),
                                    };
                                }
                                return branch;
                            },
                        ) ?? [],
                },
            });
        },
        [branchConfig, nodeId, updateBranchConfig],
    );

    const handleDelete = useCallback(
        (destination: string) => {
            deleteAllChildBranches(destination);
            deleteNode(destination);
            branchConfig?.children.branches
                .filter(
                    (branch) =>
                        branch.destination !== destination && !branch.isDefault,
                )
                .map((branch, index) => {
                    updateBranchingEdgeLabel(
                        getEdgeId(nodeId, branch.destination),
                        `${index + 1} ${t('common.path', {
                            index: index + 1,
                        })}`,
                    );
                });

            const parentNodeId = edges.find(
                (eachEdge) => eachEdge.target === destination,
            )?.source;
            const everyoneElseNodeId = edges.find(
                (eachEdge) =>
                    eachEdge.source === parentNodeId &&
                    eachEdge.type === JourneyEdgeEnum.DEFAULT,
            )?.target;
            if (!everyoneElseNodeId || !branchConfig?.children.branches.length)
                return;
            if (
                journeyPayload.config?.nodes.find(
                    (node) => node.id === everyoneElseNodeId,
                )
            ) {
                updateBranchingEdgeLabel(
                    getEdgeId(nodeId, everyoneElseNodeId),
                    `${branchConfig?.children.branches.length - 1} ${t(
                        'journey_builder.split_every_one_else_label',
                    )}`,
                );
            } else {
                updateBranchingEdgeLabel(
                    getEdgeId(nodeId, everyoneElseNodeId),
                    `${branchConfig?.children.branches.length} ${t(
                        'journey_builder.split_every_one_else_label',
                    )}`,
                );
            }
        },
        [
            branchConfig,
            deleteAllChildBranches,
            deleteNode,
            t,
            updateBranchingEdgeLabel,
            nodeId,
            edges,
            journeyPayload.config?.nodes,
        ],
    );
    const handleAddPath = useCallback(() => {
        if (branchConfig?.children.branches.length === 0) {
            createEveryOneElsePath(nodeId, splitBlockId ?? '');
        }
        addEdgeWithGhostNode(nodeId, undefined, undefined);
    }, [
        addEdgeWithGhostNode,
        nodeId,
        splitBlockId,
        branchConfig,
        createEveryOneElsePath,
    ]);

    return (
        <Flex direction="column" gap={6}>
            <Flex gap={4} align="center" className="p-3">
                <ArrowsSplit weight="duotone" />
                <Text className="text-sm font-medium text-gray-500 uppercase">
                    {t('common.paths')}
                </Text>
            </Flex>

            {!splitActiveFields?.[nodeId] && (
                <Text className="text-sm text-gray-500">
                    {t('journey_builder.split_path_no_properties')}
                </Text>
            )}
            {branchConfig?.children.branches.map((branch, index) => {
                return (
                    <EachSplitPath
                        key={branch.destination}
                        filterRule={
                            getFilterRuleFromConditionsInSplit(
                                branch.conditions,
                            ) as FilterRule
                        }
                        onChange={(filterRule) => {
                            handleChange(filterRule, branch.destination);
                        }}
                        nodeId={nodeId}
                        index={index}
                        onDelete={() => {
                            handleDelete(branch.destination);
                        }}
                        destination={branch.destination}
                    />
                );
            })}
            {splitActiveFields?.[nodeId] && (
                <Button
                    variant={ButtonVariant.SUBTLE}
                    leftIcon={<PlusCircle weight="duotone" />}
                    className="w-[6.5rem]"
                    onClick={handleAddPath}
                >
                    {t('journey_builder.split_path_add_path')}
                </Button>
            )}
        </Flex>
    );
};

export default SplitPath;
