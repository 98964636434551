import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import useFiltersContext from '@components/Audience/Filters/FiltersProvider/useFiltersContext';
import {
    isDimension,
    isMetric,
    type FilterableDimension,
    type Metric,
} from '@lightdash/common';
import { useMemo } from 'react';

export const useFilterFields = () => {
    const { fieldsMap, eventsMap, audienceMap } = useFiltersContext();

    const [fields, dimensions, metrics, events] = useMemo<
        [
            FieldWithSuggestions[],
            FilterableDimension[],
            Metric[],
            FieldWithSuggestions[],
        ]
    >(() => {
        const allFields = Object.values(fieldsMap);
        return [
            allFields,
            allFields.filter(isDimension),
            allFields.filter(isMetric),
            eventsMap,
        ];
    }, [fieldsMap, eventsMap]);

    return {
        fields,
        dimensions,
        metrics,
        events,
        audienceMap,
    };
};
