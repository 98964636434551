import CampaignPreviewContainer from '@components/Campaigns/Preview/CampaignPreviewContainer';
import LinkButton from '@components/common/LinkButton';
import NavBar from '@components/NavBar';
import Page from '@components/Page/Page';
import { useLocale } from '@hooks/useLocale';
import { type Campaign } from '@lightdash/common';
import { CaretLeft } from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import React from 'react';
import { useParams } from 'react-router';
import { ButtonVariant } from '../../../mantineTheme';

interface PreviewCampaignProps {
    campaignData: Campaign;
}

const PreviewCampaign: React.FC<PreviewCampaignProps> = ({ campaignData }) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{
        projectUuid: string;
        audienceUuid: string;
        mode?: string;
    }>();
    const { campaignPayload } = useCampaignContext((context) => context.state);

    return (
        <Page
            title={campaignPayload.name}
            withFullHeight
            withPaddedContent
            withNavbar
            backgroundColor="gray"
            withFixedContent
        >
            <NavBar />
            <LinkButton
                variant={ButtonVariant.SUBTLE}
                href={`/projects/${projectUuid}/campaigns`}
                className="flex items-center gap-1.5 text-sm text-gray-700 font-semibold w-fit"
                leftIcon={<CaretLeft size={14} weight="duotone" />}
            >
                {t('campaigns.all_campaigns')}
            </LinkButton>
            <CampaignPreviewContainer campaignData={campaignData} />
        </Page>
    );
};

export default PreviewCampaign;
