import { campaignEventFieldId } from '@components/Audience/utils';
import {
    CommunicationChannel,
    DimensionType,
    FieldType,
    getItemId,
    RelationTableType,
    ReservedAudienceKeys,
    type AdditionalMetric,
    type Audience,
    type CompiledRelation,
    type FilterableDimension,
    type FilterableField,
    type FilterOperator,
    type FilterRule,
} from '@lightdash/common';
import { t as translate } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

/**
 * Checks if a given filter rule is an audience filter rule.
 *
 * @param filterRule - The filter rule to check.
 * @param fields - The fields to check against.
 * @returns True if the filter rule's field ID matches the base table's primary field ID and the value starts with "srt_audience_"; otherwise, false.
 */
export const isAudienceFilterRule = (
    filterRule: FilterRule,
    fields: FilterableField[],
): boolean => {
    const filterRuleValue = filterRule.values?.[0];

    if (!filterRuleValue || typeof filterRuleValue !== 'string') return false;

    return (
        fields.find((field) => {
            return (
                field &&
                'tableType' in field &&
                field.tableType === RelationTableType.AUDIENCE &&
                field.table === filterRuleValue.split(':')[1]
            );
        }) !== undefined
    );
};

/**
 * Checks if a given filterable field is from tableType audience.
 *
 * @param field - The filterable field to check.
 * @returns True if the field's table matches an audience table; otherwise, false.
 */
export const isAudienceField = (field: FilterableField) => {
    if (
        'tableType' in field &&
        field.tableType === RelationTableType.AUDIENCE
    ) {
        return true;
    }

    return false;
};

/**
 * Retrieves the User ID of the base table from the compiled relation.
 *
 * @param relation - The compiled relation containing the tables.
 * @returns The User ID of the base table in the format "baseTableId_userId".
 * @throws Error if the base table does not exist or if the userId is undefined.
 */
export const getBaseTableUserFieldId = (relation: CompiledRelation) => {
    const baseTableId: string = relation.baseTable;

    // Check if the base table exists in the relation
    const baseTable = relation.tables[baseTableId];
    if (!baseTable) {
        throw new Error(
            translate('filter_rule.no_base_table_error', {
                baseTableId,
            }),
        );
    }

    let userFieldId: string | undefined;

    if (baseTable.type === RelationTableType.PRIMARY) {
        userFieldId = baseTable.userId;
    }

    // Check if the userId is defined
    if (userFieldId === undefined) {
        throw new Error(
            translate('filter_rule.no_user_id_error', {
                baseTableId,
            }),
        );
    }

    return `${baseTableId}_${userFieldId}`;
};

/**
 * Checks if a given filter rule is an audience filter rule.
 *
 * @param filterRule - The filter rule to check, which includes the target field ID and values.
 * @param relation - The compiled relation containing the tables to inspect.
 * @returns True if the filter rule's field ID matches the base table's primary field ID and the value starts with "srt_audience_"; otherwise, false.
 */

export const isFilterRuleAudience = (
    filterRule: FilterRule,
    relation: CompiledRelation,
) => {
    const filterRuleValue = filterRule.values?.[0];
    const filterRuleFieldId = filterRule.target.fieldId;
    const baseTablePrimaryFieldId =
        relation && getBaseTableUserFieldId(relation);

    return (
        baseTablePrimaryFieldId === filterRuleFieldId &&
        typeof filterRuleValue === 'string' &&
        filterRuleValue.startsWith(ReservedAudienceKeys.SRT_AUDIENCE)
    );
};

/**
 * Converts an array of Audience objects to an array of FilterableDimension objects.
 *
 * @param {AudienceList} audience - The list of audiences to convert.
 * @returns {FilterableDimension[]} An array of FilterableDimension objects representing the audiences.
 *
 */
export const convertAudienceToFilterableDimension = (
    audience: Audience[],
): FilterableDimension[] => {
    return audience.map((aud) => ({
        fieldType: FieldType.DIMENSION,
        type: DimensionType.STRING,
        name: aud.name,
        label: aud.name,
        table: aud.id, //Considering the audience id as the table name
        tableLabel: aud.name,
        sql: `SELECT * FROM audience WHERE id = '${aud.id}'`,
        description: aud.description,
        hidden: false,
        tableType: RelationTableType.AUDIENCE,
    }));
};

interface IsFilterRuleEventNameMetricProps {
    filterRule: FilterRule;
    additionalMetrics: AdditionalMetric[];
    activeRelation: CompiledRelation;
}
export const isFilterRuleEventNameMetric = ({
    filterRule,
    additionalMetrics,
    activeRelation,
}: IsFilterRuleEventNameMetricProps) => {
    const matchedAdditionalMetric = additionalMetrics.find(
        (am) => getItemId(am) === filterRule.target.fieldId,
    );
    if (!matchedAdditionalMetric) return false;
    const matchedRelationTable =
        activeRelation.tables[matchedAdditionalMetric.table];

    if (
        !matchedRelationTable ||
        matchedRelationTable.type !== RelationTableType.EVENT
    )
        return false;
    return true;
};
export interface CreateFilterRuleForCampaignEventProps {
    dimension: string;
    value: string;
    projectUuid: string;
    operator: FilterOperator;
}
/**
 * create a filterRule for campaign event provides projectUuid
 * @param dimension
 * @param value
 * @param projectUuid
 * @param operator
 * @returns
 */
export const createFilterRuleForCampaignEvent = ({
    dimension,
    value,
    projectUuid,
    operator,
}: CreateFilterRuleForCampaignEventProps) => {
    return {
        id: uuidv4(),
        target: {
            fieldId: campaignEventFieldId(projectUuid, dimension),
        },
        operator,
        values: [value],
    };
};

export const CampaignEventChannelLabel = {
    [CommunicationChannel.EMAIL]: translate(
        'communication.channel_email_label_in_selector',
    ),
    [CommunicationChannel.SMS]: translate(
        'communication.channel_sms_label_in_selector',
    ),
    [CommunicationChannel.WHATSAPP]: translate(
        'communication.channel_whatsapp_label_in_selector',
    ),
    [CommunicationChannel.SLACK]: translate(
        'communication.channel_slack_label_in_selector',
    ),
    [CommunicationChannel.ANY]: translate(
        'communication.channel_any_label_in_selector',
    ),
    [CommunicationChannel.UNKNOWN]: translate(
        'communication.channel_unknown_label_in_selector',
    ),
};
