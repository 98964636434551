import Timestamp from '@components/common/Timestamp';
import TemplateGridItem from '@components/TemplateGridItem';
import { useLocale } from '@hooks/useLocale';
import { CommonReservedTags, type ReducedTemplate } from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import { Archive } from '@phosphor-icons/react';
import { type ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ButtonVariant } from '../../../mantineTheme';
import TemplateMenuItem from './TemplateMenuItem';

const TEMPLATE_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const TEMPLATE_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;
const TEMPLATE_TABLE_CELL_DESCRIPTION = `text-xs font-normal text-gray-600 truncate !w-56`;

export const useTemplateColumns = () => {
    const [columns, setColumns] = useState<ColumnDef<ReducedTemplate>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('templates'),
                    cell: ({ row, getValue }) => (
                        <Box className={TEMPLATE_TABLE_CELL_CLASSNAME}>
                            <Button variant={ButtonVariant.UNSTYLED}>
                                <Flex align={'center'} gap={6}>
                                    {row.original.tags?.includes(
                                        CommonReservedTags.HIDDEN,
                                    ) && <Archive weight="duotone" />}
                                    <Text className={TEMPLATE_TABLE_CELL_NAME}>
                                        {getValue<string>()}
                                    </Text>
                                </Flex>
                            </Button>
                            {row.original.description && (
                                <Text
                                    className={TEMPLATE_TABLE_CELL_DESCRIPTION}
                                >
                                    {row.original.description}
                                </Text>
                            )}
                        </Box>
                    ),
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`by ${row.original.createdBy.firstName || ''}`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'updatedAt',
                    header: t('common.modified_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />

                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`${
                                    row.original.updatedBy?.firstName
                                        ? 'by'
                                        : ''
                                } ${row.original.updatedBy?.firstName || ''}`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => <TemplateMenuItem rowData={row} />,
                },
            ]);
        }
    }, [projectUuid, t]);

    return columns;
};

export const useTemplateGrid = ({
    handleClick,
}: {
    handleClick: (id: string) => void;
}) => {
    {
        const [gridColumns, setGridColumns] =
            useState<ColumnDef<ReducedTemplate>[]>();
        const { projectUuid } = useParams<{ projectUuid: string }>();
        const { t } = useLocale();

        useEffect(() => {
            if (projectUuid) {
                setGridColumns([
                    {
                        accessorKey: 'name',
                        header: t('templates'),
                        cell: ({ row }) => {
                            const { original } = row;
                            return (
                                <TemplateGridItem
                                    channel={original.channel}
                                    template={original}
                                    handleTemplateSelect={() =>
                                        handleClick(original?.id)
                                    }
                                />
                            );
                        },
                    },
                ]);
            }
        }, [projectUuid, t, handleClick]);

        return gridColumns;
    }
};
