import ContentSetupStrip from '@components/Journeys/Builder/ControlPanel/Actions/SendCampaign/ContentSetupStrip';
import DeviceSwitcher from '@components/Templates/HTMLManager/HTMLPreview/DeviceSwitcher';
import ThemeSwitcher from '@components/Templates/HTMLManager/HTMLPreview/ThemeSwitcher';
import { useIsTruncated } from '@hooks/useIsTruncated';
import { useLocale } from '@hooks/useLocale';
import { Avatar, Box, Group, Stack, Text, Tooltip } from '@mantine/core';
import { CaretDown } from '@phosphor-icons/react';
import { NewspaperClipping } from '@phosphor-icons/react/dist/ssr';
import grapesjs, { type Editor } from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
    type FC,
} from 'react';
import { FontSizes, FontWeights } from '../../../../mantineTheme';
import { type HtmlTemplateDetails } from './../types';
import {
    getPreviewConfig,
    nonEditableComponentConfig,
    previewConfig,
    themeCSS,
} from './config';
import { Devices, Theme } from './types';

interface IHTMLPreviewProps {
    templateDetail: HtmlTemplateDetails;
    subject: string;
    senderName: string;
    senderMail: string;
    handleModifyTemplate: () => void;
    setGrapesPreviewInstance: ((editor: Editor) => void) | undefined;
    grapesContainerId: string;
    showDeviceAndThemeSwitcher: boolean;
    showModifyButton: boolean;
    isSetupComplete: boolean | undefined;
    openContentSetupManager: (() => void) | undefined;
    showSetupStrip: boolean;
    showHeader?: boolean;
}

const HTMLPreview: FC<IHTMLPreviewProps> = ({
    templateDetail,
    subject,
    senderName,
    senderMail,
    handleModifyTemplate,
    setGrapesPreviewInstance,
    grapesContainerId,
    showDeviceAndThemeSwitcher,
    showModifyButton,
    isSetupComplete,
    openContentSetupManager,
    showSetupStrip = false,
    showHeader = true,
}: IHTMLPreviewProps) => {
    const { t } = useLocale();
    const [editor, setEditor] = useState<Editor | null>(null);
    const [theme, setTheme] = useState<Theme>(Theme.LIGHT);
    const [device, setDevice] = useState<Devices>(Devices.DESKTOP);
    const [deviceWidth, setDeviceWidth] = useState<string>('');
    const [themeInitialized, setThemeInitialized] = useState<boolean>(false);
    const previewContainer = useRef<HTMLDivElement>(null);
    const { ref: truncatedRef, isTruncated } = useIsTruncated<HTMLDivElement>();

    useEffect(() => {
        const editorJs = grapesjs.init(getPreviewConfig(grapesContainerId));
        editorJs.setComponents(templateDetail?.html);

        // Disable all interactions
        editorJs.DomComponents.getWrapper()?.onAll((comp) => {
            comp.set(nonEditableComponentConfig);
        });
        if (setGrapesPreviewInstance) {
            setGrapesPreviewInstance(editorJs);
        }
        setEditor(editorJs);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateDetail?.html]);

    const injectThemeStyles = useCallback((iframeDoc: Document | null) => {
        if (!iframeDoc) return;

        const linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        linkElement.setAttribute(
            'href',
            'data:text/css;charset=UTF-8,' + encodeURIComponent(themeCSS),
        );

        const head = iframeDoc.querySelector('head');
        if (!head) return;

        head.appendChild(linkElement);
    }, []);

    const injectDarkModeLayer = useCallback((iframeDoc: Document | null) => {
        if (!iframeDoc) return;

        const layer = document.createElement('div');
        layer.id = 'layer';
        layer.classList.add('darkmode-layer');

        const body = iframeDoc.querySelector('body');
        if (!body) return;

        body.insertBefore(layer, body.firstChild);
    }, []);

    const initTheme = useCallback(
        (iframeDoc: Document | null) => {
            if (themeInitialized || !iframeDoc) return;

            injectThemeStyles(iframeDoc);
            injectDarkModeLayer(iframeDoc);
            setThemeInitialized(true);
        },
        [injectDarkModeLayer, injectThemeStyles, themeInitialized],
    );

    const injectDarkModeStyling = useCallback(
        (iframeDoc: Document | null) => {
            if (!iframeDoc) return;
            initTheme(iframeDoc);

            const body = iframeDoc.querySelector('body');
            if (!body) return;

            body.querySelector('#layer')?.classList.add(
                'darkmode-layer--simple',
            );
        },
        [initTheme],
    );

    const revertDarkModeStyling = useCallback((iframeDoc: Document | null) => {
        if (!iframeDoc) return;

        const body = iframeDoc.querySelector('body');
        if (!body) return;

        body.querySelector('#layer')?.classList.remove(
            'darkmode-layer--simple',
        );
    }, []);

    const changeMode = useCallback(
        (mode: Theme) => {
            setTheme(mode);
            previewContainer.current?.classList.toggle(Theme.DARK);
            const iframe: HTMLIFrameElement | null | undefined =
                previewContainer.current?.querySelector('iframe');
            if (!iframe) return;

            const iframeDoc: Document | null = iframe.contentDocument;
            if (!iframeDoc) return;

            if (mode === Theme.DARK) injectDarkModeStyling(iframeDoc);
            else revertDarkModeStyling(iframeDoc);
        },
        [injectDarkModeStyling, revertDarkModeStyling],
    );

    const changeDevice = useCallback(
        (mode: Devices) => {
            if (!editor) return;

            editor.setDevice(mode);
            setDevice(mode);
        },
        [editor],
    );

    useEffect(() => {
        const activeDevice = previewConfig.deviceManager.devices.find(
            (d) => d.id === device,
        );
        let width = '';
        if (activeDevice && activeDevice.width) {
            width = `calc(${activeDevice.width} + 60px)`;
        } else {
            width = 'calc(100% + 1px)';
        }

        setDeviceWidth(width);
    }, [device]);

    return (
        <>
            <Box
                className={`border-base rounded-xl animate-ease ${
                    theme === Theme.DARK ? 'dark' : ''
                } dark:bg-gray-900`}
                style={{
                    width: deviceWidth,
                    transition: 'width 0.5s ease',
                }}
            >
                {showDeviceAndThemeSwitcher && (
                    <Group className="border-b-[1px] p-3 justify-between items-center rounded-t-xl dark:bg-gray-900 dark:border-shade-2 border-black/6">
                        <Text
                            size={FontSizes.base}
                            weight={FontWeights.semibold}
                            className="text-gray-800 dark:text-white"
                        >
                            {t(
                                'campaigns_builder_details.campaign_preview_title',
                            )}
                        </Text>

                        <Group spacing={4}>
                            <DeviceSwitcher
                                theme={theme}
                                onSwitch={changeDevice}
                            />
                            <ThemeSwitcher onSwitch={changeMode} />
                        </Group>
                    </Group>
                )}

                {showSetupStrip && (
                    <ContentSetupStrip
                        isSetupComplete={isSetupComplete ?? false}
                        openContentSetupManager={
                            openContentSetupManager ?? (() => {})
                        }
                    />
                )}

                <Box className="flex items-center justify-center px-3 py-2 bg-gray-50 dark:bg-gray-850 border-b-[1px] border-black/6">
                    <Text className="text-sm font-medium text-gray-500">
                        {t('template_preview.disclaimer')}
                    </Text>
                </Box>

                {!templateDetail?.unsubscribeBlock && (
                    <Box className="px-[13px] py-[9px] bg-halt-600/25">
                        <Text className="text-center text-halt-800">
                            {t('campaign.content.unsubscribe_variable_missing')}
                        </Text>
                    </Box>
                )}

                {showHeader && (
                    <Group
                        position="apart"
                        className="px-3 py-3 text-gray-600 dark:bg-gray-900  border-shade/2 border-b-[1px] dark:border-white/10"
                    >
                        <Text className="text-sm font-medium text-gray-500">
                            {t('template_preview.content')}
                        </Text>
                        <Group spacing={6}>
                            <NewspaperClipping />
                            <Tooltip
                                withinPortal
                                variant="xs"
                                label={templateDetail?.name}
                                disabled={!isTruncated}
                            >
                                <Text
                                    className="text-sm text-gray-600 truncate max-w-[25rem]"
                                    ref={truncatedRef}
                                >
                                    {templateDetail?.name}
                                </Text>
                            </Tooltip>
                            {showModifyButton && (
                                <Text
                                    className="font-medium cursor-pointer text-blu-800"
                                    onClick={handleModifyTemplate}
                                >
                                    Modify
                                </Text>
                            )}
                        </Group>
                    </Group>
                )}

                <Box className="relative z-0 !h-[600px] border-base rounded shadow-[0_1px_4px_0px_rgba(0,0,0,0.13)] m-4 p-4 dark:bg-gray-900 dark:border-white/10">
                    <Stack className="mb-3.5">
                        <Text className="text-gray-850 dark:text-white">
                            {t('template_preview.subject', {
                                subject: subject,
                            })}
                        </Text>
                        <Group className="gap-3">
                            <Avatar
                                data-testid="user-avatar"
                                variant="filled"
                                size={40}
                                color="rgb(var(--color-blu-800))"
                                radius={100}
                                styles={{
                                    placeholder: {
                                        fontSize: '1.5rem',
                                        fontWeight: 'normal',
                                    },
                                }}
                            >
                                {senderName?.[0]}
                            </Avatar>

                            <Stack className="gap-0">
                                <Text className="text-gray-850 dark:text-white">
                                    {senderName}{' '}
                                    <span className="text-xs text-gray-600">
                                        {'<'}
                                        {senderMail}
                                        {'>'}
                                    </span>
                                </Text>
                                <Group className="gap-1">
                                    <Text className="text-xs text-gray-600">
                                        to me
                                    </Text>
                                    <CaretDown weight="regular" size={12} />
                                </Group>
                            </Stack>
                        </Group>
                    </Stack>

                    <Box
                        id={grapesContainerId}
                        ref={previewContainer}
                        className="!h-[480px] overflow-scroll rounded-xl html-preview"
                    ></Box>
                </Box>
            </Box>
        </>
    );
};

export default React.memo(HTMLPreview);
