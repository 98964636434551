import {
    AudienceType,
    CampaignStatus,
    CommunicationChannel,
    type CampaignUpdateRequest,
    type CommunicationDetails,
    type EmailTemplateContentDetails,
    type Integration,
} from '@lightdash/common';
import { CampaignBuilderStep, CampaignContentStep } from './Builder/types';

export const createIntegratedProviderObject = (
    providersInChannels: Integration[],
    integratedChannels: Integration[],
    isTemplateImportAllowedCheck: boolean = false,
) => {
    let finalObject: Integration[] = [];
    integratedChannels.forEach((integratedChannel: Integration) => {
        let findOriginalProviderObject = providersInChannels.find(
            (providersInChannel: Integration) => {
                return (
                    providersInChannel.providerId ===
                    integratedChannel.providerId
                );
            },
        );
        if (!isTemplateImportAllowedCheck) {
            finalObject.push({
                ...integratedChannel,
                ...findOriginalProviderObject,
            });
        } else {
            if (findOriginalProviderObject?.isTemplateImportAllowed) {
                finalObject.push({
                    ...integratedChannel,
                    ...findOriginalProviderObject,
                });
            }
        }
    });
    return finalObject.length > 0 ? finalObject : undefined;
};

// INFO - Kept the props as an object to make this more scalable for adding more props in future if required
/**
 * Function to check whether to allow edits for a particular campaign or not
 * @param param0 - Object containing executionTime and status of the campaign.
 * @param {string | undefined} param0.status - The current status of the campaign, which can be of type CampaignStatus or undefined.
 * @returns {boolean} - Returns true if edits are allowed, false otherwise.
 */
export const allowCampaignEdits = ({
    status,
}: {
    executionTime: Date | string | null | undefined;
    status: CampaignStatus | undefined;
}): boolean => {
    return status === CampaignStatus.DRAFT;
    // INFO - Keeping the old code in case we need it again
    // if (!executionTime && CampaignStatus.READY !== status) return false;

    // const currentDateTime = moment(new Date()).utc();
    // const savedTime = moment(executionTime);
    // // INFO - We are not allowing campaign edits if the status is not in draft/scheduled
    // // In case of scheduled we'll allow edits only if the scheduled time is greater than 15mins from the currentTime
    // // NOTE - Both the times here are compared in UTC
    // if (status) {
    //     if (CampaignStatus.READY === status) {
    //         return true;
    //     }
    //     if (CampaignStatus.SCHEDULED === status) {
    //         return (
    //             savedTime.valueOf() - currentDateTime.valueOf() >
    //             CAMPAIGN_EDIT_DELTA_GAP
    //         );
    //     }
    // }
    // return false;
};

/**
 * This function extracts the subject variables from the subject during campaign creation.
 * @param {string} - string containing subject.
 * @returns {TemplateVariableDetails} - Returns an object containing headerVariables,bodyVariables,footerVariables.
 */

export const extractSubjectVariables = (str: string) => {
    const regex = /{{(.*?)}}/g;
    const variables: string[] = [];
    let match;
    while ((match = regex.exec(str)) !== null) {
        variables.push(match[1].trim());
    }

    return variables.length ? variables : undefined;
};

const isChannelStepComplete = (
    communicationDetails: CommunicationDetails | undefined,
) => {
    if (!communicationDetails) return false;
    return Boolean(communicationDetails?.providerId);
};

const isAudienceStepComplete = (campaign: CampaignUpdateRequest) => {
    if (campaign.audienceType === AudienceType.WAREHOUSE) {
        return Boolean(campaign.audienceId);
    }
    return Boolean(campaign.csvUploadDetails?.uploadId);
};

const isSetupStepComplete = (campaign: CampaignUpdateRequest) => {
    if (campaign.channel === CommunicationChannel.EMAIL) {
        if (!campaign.subscriptionGroupId) return false;
        if (!(campaign?.contentDetails as EmailTemplateContentDetails)?.subject)
            return false;
        return true;
    }
    return true;
};

const isPersonaliseStepComplete = (campaign: CampaignUpdateRequest) => {
    if (campaign.sendTo && campaign.channel) {
        return Boolean(campaign.sendTo[campaign.channel]);
    }
    return false;
};

export const areCurrentAndPreviousStepsComplete = ({
    campaign,
    step,
}: {
    campaign: CampaignUpdateRequest;
    step: CampaignBuilderStep | CampaignContentStep;
}) => {
    switch (step) {
        case CampaignBuilderStep.CHANNEL:
            return isChannelStepComplete(campaign.communicationDetails);
        case CampaignBuilderStep.AUDIENCE: {
            if (!isChannelStepComplete(campaign.communicationDetails))
                return false;
            return isAudienceStepComplete(campaign);
        }
        case CampaignBuilderStep.CONTENT: {
            if (!isChannelStepComplete(campaign.communicationDetails))
                return false;
            if (!isAudienceStepComplete(campaign)) return false;
            return true;
        }
        case CampaignContentStep.SETUP: {
            if (!isChannelStepComplete(campaign.communicationDetails))
                return false;
            if (!isAudienceStepComplete(campaign)) return false;
            return isSetupStepComplete(campaign);
        }
        case CampaignContentStep.PERSONALISE: {
            if (!isChannelStepComplete(campaign.communicationDetails))
                return false;
            if (!isAudienceStepComplete(campaign)) return false;
            if (!isSetupStepComplete(campaign)) return false;
            return isPersonaliseStepComplete(campaign);
        }
        case CampaignContentStep.TRACK:
        case CampaignContentStep.TEST:
            return true;
        case CampaignContentStep.UTM_PARAMETERS: {
            if (campaign.campaignTrackingParameters) {
                return Object.keys(campaign.campaignTrackingParameters).some(
                    (source) => !campaign.campaignTrackingParameters?.[source],
                );
            }
            return true;
        }
        default:
            return true;
    }
};
