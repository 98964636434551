import { FilterFieldSelectItem } from '@components/Audience/Filters/FilterInputs/FilterFieldSelect';
import Checkbox from '@components/common/Checkbox';
import { type Variable } from '@lightdash/common';
import { Box, Group, Text } from '@mantine/core';
import { CaretLeft } from '@phosphor-icons/react';
import useGetFieldById from './useGetFieldById';

const LinkComponent = ({
    mappings,
    header,
    handleChange,
}: {
    mappings: { [key: string]: Variable };
    header: string | undefined;
    handleChange: (checked: boolean, key: string) => void;
}) => {
    const { getFieldById } = useGetFieldById();

    return (
        <Box>
            {header && (
                <Text className="text-gray-500 font-medium ml-3">{header}</Text>
            )}
            {Object.entries(mappings)?.map(([key, variable]) => {
                return (
                    <Group
                        key={key}
                        className="py-2 px-3"
                        spacing={8}
                        align="center"
                    >
                        <Checkbox
                            checked={variable.enableTracking}
                            onChange={(e) =>
                                handleChange(e.target.checked, key)
                            }
                        />
                        <Text>{`{{${key}}}`}</Text>
                        <CaretLeft />
                        <Text className="max-w-[400px] truncate">
                            {variable.value ? (
                                <FilterFieldSelectItem
                                    activeRelation={undefined}
                                    activeField={getFieldById(variable.value)}
                                    isEditMode={false}
                                    showFieldSource
                                    showHoverIcon={false}
                                    showRelatedTableLabelOnly={false}
                                />
                            ) : (
                                variable.defaultValue
                            )}
                        </Text>
                    </Group>
                );
            })}
        </Box>
    );
};

export default LinkComponent;
