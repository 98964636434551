import {
    JourneyEdgeEnum,
    JourneyNodeEnum,
    type JourneyNodeData,
} from '@components/Journeys/Builder/types';
import {
    getAdditionalGhostNodeAndEdges,
    getEdgeId,
} from '@components/Journeys/Builder/utils';
import {
    JourneyStatus,
    type BaseTrigger,
    type JourneyAndVersionConfig,
    type JourneyBlocksList,
    type JourneyNode,
} from '@lightdash/common';
import { t as translate } from 'i18next';
import { useMemo } from 'react';
import { type Edge, type Node } from 'reactflow';
import { isSplitBlock } from './ControlPanel/Actions/Split/utils';
import { getEdgeLabel } from './ReactFlow/utils';

/**
 * Hook to generate Nodes and Edges from journeyPayload
 * @param journeyPayload - The journey payload containing the configuration nodes
 * @returns An object containing the generated nodes and edges
 */
export const useJourneyNodesAndEdges = (
    journeyPayload: JourneyAndVersionConfig | undefined,
    blocksList: JourneyBlocksList,
) => {
    return useMemo(() => {
        if (!journeyPayload) return { nodes: [], edges: [] };
        const nodes: Node<JourneyNodeData>[] = [];
        const edges: Edge[] = [];
        const nodesIdInJourney = journeyPayload.config?.nodes.map(
            (node) => node.id,
        );

        // Add trigger node
        const triggerNode: BaseTrigger | undefined =
            journeyPayload.triggers?.entry[0];
        if (triggerNode) {
            nodes.push({
                id: triggerNode.id,
                position: { x: 0, y: 0 }, // Default position, can be updated later
                type: JourneyNodeEnum.TRIGGER,
                data: {
                    type: JourneyNodeEnum.TRIGGER,
                    nodeId: triggerNode.id,
                    blockId: triggerNode.metadata?.metadata?.blockId ?? '',
                },
            });
        }

        // Add journey nodes and edges
        journeyPayload.config!.nodes.forEach(
            (journeyNode: JourneyNode, index: number) => {
                const block = blocksList.find(
                    (eachBlock) =>
                        eachBlock.id === journeyNode.metadata?.blockId,
                );
                nodes.push({
                    id: journeyNode.id,
                    position: { x: 0, y: 0 }, // Default position, can be updated later
                    type: JourneyNodeEnum.BLOCK,
                    data: {
                        type: JourneyNodeEnum.BLOCK,
                        nodeId: journeyNode.id,
                        blockId: journeyNode.metadata?.blockId ?? '',
                    },
                });

                // Add edge from trigger node to the first journey node
                if (index === 0 && triggerNode) {
                    edges.push({
                        id: getEdgeId(triggerNode.id, journeyNode.id),
                        source: triggerNode.id,
                        target: journeyNode.id,
                        type: JourneyEdgeEnum.BLOCK,
                    });
                }

                // Add edges based on branchConfig
                if (isSplitBlock(journeyNode)) {
                    const branches =
                        journeyNode.branchConfig?.children?.branches;
                    branches?.forEach((branch, eachIndex) => {
                        if (branch.isDefault) {
                            edges.push({
                                id: getEdgeId(
                                    journeyNode.id,
                                    branch.destination,
                                ),
                                source: journeyNode.id,
                                target: branch.destination,
                                type: JourneyEdgeEnum.DEFAULT,
                                label: `${
                                    branches.length > 1
                                        ? branches.length - 1
                                        : 1
                                } ${translate(
                                    'journey_builder.split_every_one_else_label',
                                )}`,
                            });
                        } else {
                            edges.push({
                                id: getEdgeId(
                                    journeyNode.id,
                                    branch.destination,
                                ),
                                source: journeyNode.id,
                                target: branch.destination,
                                label: `${eachIndex + 1} ${translate(
                                    'common.path',
                                    { index: eachIndex + 1 },
                                )}`,
                                type: JourneyEdgeEnum.BRANCHING,
                            });
                        }
                        if (!nodesIdInJourney?.includes(branch.destination)) {
                            nodes.push({
                                id: branch.destination,
                                position: { x: 0, y: 0 }, // Default position, can be updated later
                                type: JourneyNodeEnum.GHOSTNODE,
                                data: {
                                    type: JourneyNodeEnum.BLOCK,
                                    nodeId: branch.destination,
                                    blockId: '',
                                },
                            });
                        }
                    });
                } else {
                    journeyNode.branchConfig?.children.branches.forEach(
                        (branch) => {
                            if (branch.isDefault) {
                                edges.push({
                                    id: getEdgeId(
                                        journeyNode.id,
                                        branch.destination,
                                    ),
                                    source: journeyNode.id,
                                    target: branch.destination,
                                    type: JourneyEdgeEnum.DEFAULT,
                                    label: `2 ${
                                        getEdgeLabel(block?.actions)
                                            ?.everyoneElseLabel
                                    }`,
                                });
                            } else {
                                const hasEveryOneElse = block?.actions?.some(
                                    (action) =>
                                        action.config?.allowEveryoneElse,
                                );
                                edges.push({
                                    id: getEdgeId(
                                        journeyNode.id,
                                        branch.destination,
                                    ),
                                    source: journeyNode.id,
                                    target: branch.destination,
                                    type: hasEveryOneElse
                                        ? JourneyEdgeEnum.BRANCHING
                                        : JourneyEdgeEnum.BLOCK,
                                    label: hasEveryOneElse
                                        ? `1 ${
                                              getEdgeLabel(block?.actions)
                                                  ?.ifLabel
                                          }`
                                        : '',
                                });
                            }
                        },
                    );
                }
            },
        );

        const { additionalGhostNodes, additionalEdges } =
            getAdditionalGhostNodeAndEdges(journeyPayload, blocksList);
        const isActiveOrActivating = journeyPayload
            ? journeyPayload.status === JourneyStatus.ACTIVATING ||
              journeyPayload.status === JourneyStatus.ACTIVE
            : false;
        const ghostNodes = isActiveOrActivating
            ? []
            : [...additionalGhostNodes];
        const addOnEdges = isActiveOrActivating ? [] : [...additionalEdges];

        return {
            nodes: [...nodes, ...ghostNodes],
            edges: [...edges, ...addOnEdges],
        };
    }, [journeyPayload, blocksList]);
};
