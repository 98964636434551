import ButtonInput from '@components/common/Inputs/ButtonInput';
import LinkButton from '@components/common/LinkButton';
import DeactivateJourneyModal from '@components/Journeys/Settings/DeactivateJourneyModal';
import LaunchErrorModal from '@components/Journeys/Settings/ErrorModal';
import LaunchModal from '@components/Journeys/Settings/LaunchModal';
import SettingsModal from '@components/Journeys/Settings/SettingsModal';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import useSearchParams from '@hooks/useSearchParams';
import { JourneyStatus } from '@lightdash/common';
import { ActionIcon, Box, Button, Group, Loader, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    // ArrowUUpLeft,
    // ArrowUUpRight,
    CaretLeft,
    CopySimple,
    GearSix,
    PauseCircle,
    Record,
} from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
    ButtonVariant,
    FontSizes,
    FontWeights,
} from '../../../../mantineTheme';
import JourneyTitleModal from './JourneyTitleModal';

const JourneyBuilderHeader: React.FC<{}> = () => {
    const { t } = useLocale();
    const navigate = useNavigate();
    const { showToastSuccess, showToastError } = useNotify();

    const journeyTemplateId = useSearchParams('templateId');
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [showSettings, { open: openSettings, close: closeSettings }] =
        useDisclosure(false);
    const [showLaunch, { open: openLaunch, close: closeLaunch }] =
        useDisclosure(false);
    const [showErrorModal, { open: openErrorModal, close: closeErrorModal }] =
        useDisclosure(false);
    const [titleModalOpened, { open: titleModalOpen, close: titleModalClose }] =
        useDisclosure(false);
    const [
        showDeactivateModal,
        { open: openDeactivateModal, close: closeDeactivateModal },
    ] = useDisclosure(false);

    const { journeyPayload, isLoading, journeyStatus, uuid, isEditable } =
        useJourneyBuilderContext((context) => context.state);

    const { mutateAsyncJourney, updateJourneyPayload, canSave, canLaunch } =
        useJourneyBuilderContext((context) => context.actions);

    const [isSaving, setIsSaving] = useState(false);

    const handleSave = useCallback(async () => {
        if (!journeyPayload.name || journeyPayload.name.trim() === '') {
            showToastError({
                title: t('journey_builder.journey_name_empty_error'),
            });
            return;
        }
        setIsSaving(true);
        await mutateAsyncJourney(true).then(() => {
            setIsSaving(false);
        });
    }, [journeyPayload.name, mutateAsyncJourney, showToastError, t]);

    const handleLaunch = useCallback(() => {
        if (!journeyPayload.name || journeyPayload.name.trim() === '') {
            showToastError({
                title: t('journey_builder.journey_name_empty_error'),
            });
            return;
        }
        if (!canSave() || !canLaunch()) {
            openErrorModal();
            return;
        }

        openLaunch();
    }, [
        journeyPayload.name,
        canSave,
        canLaunch,
        openLaunch,
        showToastError,
        t,
        openErrorModal,
    ]);

    const handleDeactivate = useCallback(async () => {
        openDeactivateModal();
    }, [openDeactivateModal]);

    const handleClone = useCallback(() => {
        void navigate(
            `/projects/${projectUuid}/journeys/create?templateId=${uuid}`,
        );
        showToastSuccess({
            title: t('journeys_clone.toast.title'),
            subtitle: t('journeys_clone.toast.subtitle'),
        });
    }, [navigate, projectUuid, showToastSuccess, t, uuid]);

    const headerButtons = useMemo(() => {
        if (journeyTemplateId) {
            return (
                <Group>
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        loading={isLoading}
                        onClick={handleSave}
                    >
                        {t('journey_header.save_draft')}
                    </Button>
                    <Button
                        onClick={() => handleLaunch()}
                        variant={ButtonVariant.PRIMARY}
                        loading={isLoading}
                    >
                        {t('journey_header.launch')}
                    </Button>
                </Group>
            );
        }
        switch (journeyStatus) {
            case JourneyStatus.DRAFT:
            case JourneyStatus.SCHEDULED:
                return (
                    <Group>
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={handleSave}
                        >
                            {isSaving && isLoading ? (
                                <Box className="flex items-center gap-2">
                                    <Loader size={14} />
                                    <Text>Saving journey...</Text>
                                </Box>
                            ) : (
                                t('journey_header.save_draft')
                            )}
                        </Button>
                        <Button
                            onClick={() => handleLaunch()}
                            variant={ButtonVariant.PRIMARY}
                        >
                            {t('journey_header.launch')}
                        </Button>
                    </Group>
                );
            case JourneyStatus.ACTIVE:
                return (
                    <Group>
                        <Button
                            className="text-halt-800"
                            variant={ButtonVariant.OUTLINED}
                            loading={isLoading}
                            onClick={() => handleDeactivate()}
                            leftIcon={
                                <PauseCircle
                                    color="rgb(var(--color-halt-800))"
                                    size={14}
                                    weight="duotone"
                                />
                            }
                        >
                            {t('journey_header.deactivate')}
                        </Button>
                        <Button
                            onClick={() => handleClone()}
                            variant={ButtonVariant.OUTLINED}
                            leftIcon={<CopySimple size={14} weight="duotone" />}
                        >
                            {t('journey_header.clone')}
                        </Button>
                    </Group>
                );
            default:
                return (
                    <Button
                        onClick={() => handleClone()}
                        variant={ButtonVariant.OUTLINED}
                        loading={isLoading}
                        leftIcon={<CopySimple size={14} weight="duotone" />}
                    >
                        {t('journey_header.clone')}
                    </Button>
                );
        }
    }, [
        handleClone,
        handleDeactivate,
        handleLaunch,
        handleSave,
        isLoading,
        isSaving,
        journeyStatus,
        journeyTemplateId,
        t,
    ]);

    return (
        <>
            <Group className="items-center justify-between w-full">
                <Group>
                    <ActionIcon>
                        <LinkButton
                            href={`/projects/${projectUuid}/journeys`}
                            variant={ButtonVariant.UNSTYLED}
                        >
                            <CaretLeft color={'rgb(var(--color-gray-700))'} />
                        </LinkButton>
                    </ActionIcon>

                    <Box className="cursor-pointer" onClick={titleModalOpen}>
                        <ButtonInput
                            defaultValue={journeyPayload.name}
                            fontSize={FontSizes.lg}
                            fontWeight={FontWeights.semibold}
                            // onBlur={onTitleChange}
                            placeholder={t(
                                'journey_builder.header_title_placeholder',
                            )}
                            className="inline-block "
                            onChange={(val) => {
                                updateJourneyPayload({ name: val });
                            }}
                            rightSection={
                                <JourneyTitleModal
                                    isEditMode={isEditable}
                                    opened={titleModalOpened}
                                    open={titleModalOpen}
                                    close={titleModalClose}
                                />
                            }
                            rightSectionProps={{
                                style: {
                                    cursor: 'pointer',
                                    pointerEvents: 'auto',
                                },
                            }}
                            readOnly
                        />
                    </Box>
                    {journeyStatus === JourneyStatus.ACTIVE && (
                        <Box className="flex flex-row items-center justify-start gap-1">
                            <Record
                                color={'rgb(var(--color-blu-800))'}
                                size={14}
                                weight="duotone"
                            />
                            <Text className="text-sm font-normal text-blu-800">
                                {t('journey_manager.tr_status_active')}
                            </Text>
                        </Box>
                    )}
                </Group>
                <Group>
                    {/* <ActionIcon>
                        <ArrowUUpLeft color={'rgb(var(--color-gray-700))'} />
                    </ActionIcon>
                    <ActionIcon>
                        <ArrowUUpRight color={'rgb(var(--color-gray-700))'} />
                    </ActionIcon> */}
                    <ActionIcon onClick={() => openSettings()}>
                        <GearSix color={'rgb(var(--color-gray-700))'} />
                    </ActionIcon>
                    {headerButtons}
                </Group>
            </Group>
            <SettingsModal
                showSettings={showSettings}
                onClose={() => closeSettings()}
            />
            <LaunchModal
                showLaunch={showLaunch}
                onClose={() => closeLaunch()}
            />
            <LaunchErrorModal
                showErrorModal={showErrorModal}
                onClose={() => closeErrorModal()}
            />
            {uuid && (
                <DeactivateJourneyModal
                    uuid={uuid}
                    endDate={journeyPayload.endDate}
                    journeyStatus={journeyStatus}
                    showDeactivateModal={showDeactivateModal}
                    onClose={() => closeDeactivateModal()}
                />
            )}
        </>
    );
};

export default React.memo(JourneyBuilderHeader);
