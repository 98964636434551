import { useLocale } from '@hooks/useLocale';
import { type NestedMetricQueryGroup } from '@lightdash/common';
import { Box, Button } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import useAudienceContext from '@providers/Audience/useAudienceContext';
import isEmpty from 'lodash/isEmpty';
import { memo, useEffect, useMemo, useRef, type FC } from 'react';
import { ButtonVariant } from '../../mantineTheme';
import FilterGroupOperatorCondition from './Filters/FiltersCard/FilterGroupOperatorCondition';
import FiltersCard from './Filters/FiltersCard/FiltersCard';

interface VisualBuilderProps {
    isEditMode: boolean;
    onUpdateMetricQuery:
        | ((metricQuery: NestedMetricQueryGroup) => void)
        | undefined;
}

const VisualBuilder: FC<VisualBuilderProps> = memo(
    ({ isEditMode, onUpdateMetricQuery }) => {
        const { t } = useLocale();
        const addNewGroup = useAudienceContext(
            (context) => context.actions.addNewGroup,
        );
        const { audiencePayload } = useAudienceContext(
            (context) => context.state,
        );
        const metricQueryGroups = useAudienceContext(
            (context) => context.state.unsavedChartVersion,
        );

        const setGlobalFilterCondition = useAudienceContext(
            (context) => context.actions.setGlobalFilterCondition,
        );

        const globalFilterCondition = useAudienceContext(
            (context) => context.state.globalFilterCondition,
        );

        const previousMetricQuery = useRef<NestedMetricQueryGroup | undefined>(
            audiencePayload.nestedMetricQuery,
        );

        useEffect(() => {
            if (
                audiencePayload.nestedMetricQuery &&
                onUpdateMetricQuery &&
                audiencePayload.nestedMetricQuery !==
                    previousMetricQuery.current
            ) {
                onUpdateMetricQuery(audiencePayload.nestedMetricQuery);
                previousMetricQuery.current = audiencePayload.nestedMetricQuery;
            }
        }, [audiencePayload.nestedMetricQuery, onUpdateMetricQuery]);

        const renderAddGroupButton = useMemo(() => {
            const currentLastGroupFilters =
                metricQueryGroups[metricQueryGroups.length - 1]?.metricQuery
                    ?.filters;

            if (
                isEmpty(currentLastGroupFilters) ||
                (isEmpty(currentLastGroupFilters.dimensions) &&
                    isEmpty(currentLastGroupFilters.metrics)) ||
                !isEditMode
            )
                return null;
            return (
                <Button
                    variant={ButtonVariant.OUTLINED}
                    leftIcon={<PlusCircle />}
                    onClick={addNewGroup}
                    className="w-fit"
                >
                    {t('visual_builder.group')}
                </Button>
            );
        }, [metricQueryGroups, isEditMode, addNewGroup, t]);

        return (
            <Box className="flex flex-col gap-3.5">
                <Box>
                    {metricQueryGroups.map((group, index) => (
                        <Box key={index} className="flex flex-col w-full">
                            <FiltersCard index={index} />
                            {metricQueryGroups.length - 1 !== index && (
                                <FilterGroupOperatorCondition
                                    toggleFilterCondition={
                                        setGlobalFilterCondition
                                    }
                                    initialFilterCondition={
                                        globalFilterCondition
                                    }
                                    isEditMode={isEditMode}
                                />
                            )}
                        </Box>
                    ))}
                </Box>

                {renderAddGroupButton}
            </Box>
        );
    },
);

export default VisualBuilder;
