import { Button } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { useCallback } from 'react';
import { Handle, Position, type NodeProps } from 'reactflow';
import { ButtonVariant } from '../../../../../mantineTheme';
import { ControlPanel, type JourneyNodeGhostData } from '../../types';
import { updatePlaceholdersToGhostNodes } from '../../utils';

const GhostNode: React.FC<NodeProps<JourneyNodeGhostData>> = ({ data }) => {
    const { nodeId } = data;
    const { nodes, edges, journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );
    const { openControlPanel, setNodes, removePlaceholderNodes } =
        useJourneyBuilderContext((context) => context.actions);

    const handleChangeButtonClick = useCallback(() => {
        if (!nodeId) return;

        removePlaceholderNodes();
        setNodes(
            updatePlaceholdersToGhostNodes({
                nodes,
                edges,
                journeyPayload,
                targetNodeId: nodeId,
            }),
        );
        openControlPanel({
            type: ControlPanel.BLOCKS_LIST,
            reactFlowNodeId: nodeId,
        });
    }, [
        nodeId,
        openControlPanel,
        nodes,
        setNodes,
        removePlaceholderNodes,
        edges,
        journeyPayload,
    ]);
    return (
        <Button
            variant={ButtonVariant.SUBTLE}
            onClick={handleChangeButtonClick}
        >
            <PlusCircle
                color={'rgb(var(--color-blu-800))'}
                className="w-4 h-4"
            />
            <Handle
                type="source"
                position={Position.Bottom}
                isConnectable={false}
                className="w-0 h-0 !bg-transparent !border-none"
            />
            <Handle
                type="target"
                position={Position.Top}
                isConnectable={false}
                className="w-0 h-0 !bg-transparent !border-none"
            />
        </Button>
    );
};

export default GhostNode;
