import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Modal from '@components/common/modal/Modal';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import useNotify from '@hooks/toaster/useNotify';
import {
    useActivateJourney,
    useDeleteJourney,
    useUpdateJourneyTags,
} from '@hooks/useJourney';
import { useLocale } from '@hooks/useLocale';
import {
    CommonReservedTags,
    JourneyStatus,
    type Journey,
    type JourneyAndExecutionCount,
} from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    Archive,
    ArrowBendDownLeft,
    ArrowCounterClockwise,
    CaretRight,
    CopySimple,
    PauseCircle,
    PencilSimpleLine,
    Play,
    Trash,
} from '@phosphor-icons/react';
import { type Row } from '@tanstack/react-table';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ButtonVariant } from '../../../mantineTheme';

interface JourneyMenuItemProps {
    rowData: Row<JourneyAndExecutionCount>;
    openDeactivateModal: () => void;
    setJourneyData: (data: Journey) => void;
}

const JourneyMenuItem: React.FC<JourneyMenuItemProps> = ({
    rowData,
    openDeactivateModal,
    setJourneyData,
}) => {
    const { t } = useLocale();
    const navigate = useNavigate();
    const { showToastSuccess, showToastError } = useNotify();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { mutateAsync: mutateDeleteJourney } = useDeleteJourney();
    const { mutateAsync: activateJourney } = useActivateJourney();
    const [
        openedHideConfirmModal,
        { open: openHideConfirmModal, close: closeHideConfirmModal },
    ] = useDisclosure();
    const {
        mutateAsync: mutateAsyncUpdateJourneyTags,
        isLoading: isUpdatingJourneyTags,
    } = useUpdateJourneyTags(rowData.original.id ?? '');
    const handleClone = useCallback(
        (id: string) => {
            void navigate(
                `/projects/${projectUuid}/journeys/create?templateId=${id}`,
            );
            showToastSuccess({
                title: t('journeys_clone.toast.title'),
                subtitle: t('journeys_clone.toast.subtitle'),
            });
        },
        [navigate, projectUuid, showToastSuccess, t],
    );

    const handleView = useCallback(
        (id: string) => {
            void navigate(`/projects/${projectUuid}/journeys/${id}/view`);
        },
        [navigate, projectUuid],
    );

    const handleEdit = useCallback(
        (id: string) => {
            void navigate(`/projects/${projectUuid}/journeys/${id}/edit`);
        },
        [navigate, projectUuid],
    );

    const handleDelete = useCallback(
        async (id: string) => {
            await mutateDeleteJourney({ journeyId: id });
        },
        [mutateDeleteJourney],
    );

    const handleLaunchNow = useCallback(
        async (id: string) => {
            await activateJourney({
                data: {
                    status: JourneyStatus.ACTIVE,
                },
                uuid: id,
            });
        },
        [activateJourney],
    );

    const handleDeactivate = useCallback(
        async (values: Journey) => {
            openDeactivateModal();
            setJourneyData(values);
        },
        [openDeactivateModal, setJourneyData],
    );

    const editItem = useCallback(
        () => ({
            leftSection: (
                <PencilSimpleLine
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                    weight="duotone"
                />
            ),
            children: t('audience_manager.menu_item_edit'),
            onClick: (values: Journey) => handleEdit(values.id),
        }),
        [handleEdit, t],
    );

    const viewItem = useCallback(
        () => ({
            leftSection: (
                <ArrowBendDownLeft
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                    weight="duotone"
                />
            ),
            children: t('audience_manager.menu_item_view'),
            onClick: (values: Journey) => handleView(values.id),
        }),
        [handleView, t],
    );

    const cloneJourneyItem = useCallback(
        (isDivider: boolean) => ({
            leftSection: (
                <CopySimple
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                    weight="duotone"
                />
            ),
            children: t('journey_manager.menu_item_clone_journey'),
            isDivider: isDivider,
            onClick: (values: Journey) => handleClone(values.id),
        }),
        [handleClone, t],
    );

    const deactivateItem = useCallback(
        () => ({
            leftSection: (
                <PauseCircle
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                    weight="duotone"
                />
            ),
            children: t('journey_manager.menu_item_deactivate'),
            onClick: (values: Journey) => handleDeactivate(values),
        }),
        [handleDeactivate, t],
    );

    const launchNowItem = useCallback(
        () => ({
            leftSection: (
                <Play
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                    weight="duotone"
                />
            ),
            children: t('journey_manager.menu_item_launch_now'),
            onClick: (values: Journey) => handleLaunchNow(values.id),
        }),
        [handleLaunchNow, t],
    );

    const deleteItem = useCallback(
        () => ({
            leftSection: (
                <Trash
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                    weight="duotone"
                />
            ),
            children: t('journey_manager.menu_item_delete'),
            onClick: (values: Journey) => handleDelete(values.id),
        }),
        [handleDelete, t],
    );
    const hideItem = useMemo(
        () => ({
            leftSection: (
                <Archive
                    weight="duotone"
                    color={'rgb(var(--color-halt-800))'}
                />
            ),
            children: (
                <Text className={'text-halt-800'}>{t('common.hide')}</Text>
            ),
            customClass: 'hover:bg-halt-800/6',
            onClick: () => openHideConfirmModal(),
        }),
        [t, openHideConfirmModal],
    );
    const restoreItem = useMemo(
        () => ({
            leftSection: <ArrowCounterClockwise weight="duotone" />,
            children: t('common.restore'),
            onClick: async () => {
                await mutateAsyncUpdateJourneyTags(
                    {
                        tags: [
                            ...(rowData.original.tags ?? []).filter(
                                (tag) => tag !== CommonReservedTags.HIDDEN,
                            ),
                        ],
                    },
                    {
                        onSuccess: () => {
                            showToastSuccess({
                                title: t('common.hidden_restore_success', {
                                    entityName: t('common.journey'),
                                }),
                            });
                        },
                        onError: (error) => {
                            showToastError({
                                title: t('common.hidden_restore_error', {
                                    entityName: t('common.journey'),
                                }),
                                subtitle: error.error.message,
                            });
                        },
                    },
                );
            },
        }),
        [
            t,
            mutateAsyncUpdateJourneyTags,
            rowData.original,
            showToastError,
            showToastSuccess,
        ],
    );
    const menuItems = useMemo(() => {
        let items: CustomMenuItemProps<JourneyAndExecutionCount>[] = [];
        const status = rowData.original.status;
        switch (status) {
            case JourneyStatus.DRAFT:
                items = [editItem(), cloneJourneyItem(false), deleteItem()];
                break;
            case JourneyStatus.SCHEDULED:
                items = [
                    editItem(),
                    cloneJourneyItem(true),
                    launchNowItem(),
                    deactivateItem(),
                ];
                break;
            case JourneyStatus.ACTIVE:
                items = [viewItem(), cloneJourneyItem(true), deactivateItem()];
                break;
            //this default is for status type cancelled, but in UI cancelled status type will be visible as Stopped
            default:
                items = [viewItem(), cloneJourneyItem(true)];
                break;
        }
        if (rowData.original.tags?.includes(CommonReservedTags.HIDDEN)) {
            items.push(restoreItem);
        } else {
            items.push(hideItem);
        }
        return items;
    }, [
        cloneJourneyItem,
        deactivateItem,
        deleteItem,
        editItem,
        launchNowItem,
        rowData.original.status,
        viewItem,
        hideItem,
        restoreItem,
        rowData.original.tags,
    ]);
    return (
        <>
            <CustomMenu<JourneyAndExecutionCount>
                menuItems={menuItems}
                data={rowData.original}
            />
            <Modal
                opened={openedHideConfirmModal}
                onClose={closeHideConfirmModal}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                title={
                    <Box>
                        <Text>{t('journeys_table.hide_modal_title')}</Text>
                        <Text className="text-sm font-normal text-gray-600">
                            {rowData.original.name}
                        </Text>
                    </Box>
                }
                footerRightSection={
                    <ButtonGroup
                        primaryButtonLabel={t(
                            'journeys_table.hide_modal_confirm_button',
                        )}
                        secondaryButtonLabel={t('common.no')}
                        isUpdating={isUpdatingJourneyTags}
                        handlePrimaryButtonClick={async () => {
                            await mutateAsyncUpdateJourneyTags(
                                {
                                    tags: [
                                        ...(rowData.original.tags ?? []),
                                        CommonReservedTags.HIDDEN,
                                    ],
                                },
                                {
                                    onSuccess: () => {
                                        showToastSuccess({
                                            title: t('common.hidden_success', {
                                                entityName: t('common.journey'),
                                            }),
                                        });
                                    },
                                    onError: (error) => {
                                        showToastError({
                                            title: t('common.hidden_error', {
                                                entityName: t('common.journey'),
                                            }),
                                            subtitle: error.error.message,
                                        });
                                    },
                                },
                            );
                            closeHideConfirmModal();
                        }}
                        handleSecondaryButtonClick={closeHideConfirmModal}
                        primaryButtonVariant={ButtonVariant.FILLED_DESTRUCTIVE}
                        primaryButtonRightIcon={<CaretRight color="white" />}
                    />
                }
                closeButtonProps={{
                    className: 'absolute top-3 right-3',
                }}
            >
                <Flex direction={'column'} gap={12}>
                    <Text className="text-sm font-medium text-gray-800">
                        {t('journeys_table.hide_modal_confirm_header')}
                    </Text>
                </Flex>
            </Modal>
        </>
    );
};

export default JourneyMenuItem;
