import {
    useAudienceActivateMutation,
    useAudienceCreateMutation,
    useAudienceUpdation,
} from '@hooks/useAudience';
import {
    type useChartVersionResultsMutation,
    type useQueryResults,
} from '@hooks/useAudienceQueryResults';
import {
    assertUnreachable,
    FilterGroupOperator,
    isAndNestedMetricQuery,
    QueryGenerationStrategy,
    type AdditionalMetric,
    type AudienceInsights,
    type CreateSavedChartVersion,
    type FilterGroupOperatorType,
    type MetricQuery,
    type NestedMetricQueryGroup,
    type SavedChart,
} from '@lightdash/common';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useCallback, useEffect, useMemo, useReducer, type FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AudienceProviderContext from './context';
import {
    ActionType,
    type AudienceContext,
    type AudienceReduceState,
} from './types';
import { metricQueryDefaultState } from './utils';

interface AddUnsavedAudienceFilter {
    filterGroup: CreateSavedChartVersion;
    groupIndex: number;
}

type Action =
    | { type: ActionType.SET_FETCH_RESULTS_FALSE }
    | {
          type: ActionType.SET_FILTERS;
          payload: MetricQuery['filters'];
      }
    | {
          type: ActionType.ADD_NEW_GROUP;
      }
    | {
          type: ActionType.SET_GLOBAL_FILTER_CONDITION;
          payload: FilterGroupOperatorType;
      }
    | {
          type: ActionType.SET_AUDIENCE_NAME;
          payload: string;
      }
    | {
          type: ActionType.SET_AUDIENCE_DESCRIPTION;
          payload: string;
      }
    | {
          type: ActionType.SET_SQL_QUERY;
          payload: string;
      }
    | {
          type: ActionType.SET_AI_PROMPT;
          payload: string;
      }
    | {
          type: ActionType.SET_GENERATION_STRATEGY;
          payload: QueryGenerationStrategy;
      }
    | {
          type: ActionType.SET_UNSAVED_AUDIENCE_FILTER;
          payload: CreateSavedChartVersion[];
      }
    | {
          type: ActionType.SET_USER_ALIAS;
          payload: string;
      }
    | {
          type: ActionType.ADD_UNSAVED_AUDIENCE_FILTER;
          payload: AddUnsavedAudienceFilter;
      }
    | {
          type: ActionType.SET_INITIAL_AUDIENCE_DATA;
      }
    | {
          type: ActionType.REMOVE_UNSAVED_AUDIENCE_FILTER;
          payload: number;
      }
    | {
          type: ActionType.SET_AUDIENCE_PREVIEW_CONFIG;
          payload: string[];
      }
    | {
          type: ActionType.SET_ADDITIONAL_METRICS;
          payload: AdditionalMetric[];
      }
    | {
          type: ActionType.SET_INSIGHTS_BREAKDOWN_PAYLOAD;
          payload: AudienceInsights['breakdownFilters'];
      }
    | {
          type: ActionType.SET_INSIGHTS_OVERLAP_PAYLOAD;
          payload: AudienceInsights['overlapFilters'];
      }
    | {
          type: ActionType.SET_AUDIENCE_TAGS;
          payload: string[];
      };

const defaultState: AudienceReduceState = {
    shouldFetchResults: false,
    previouslyFetchedState: undefined,
    unsavedChartVersion: [metricQueryDefaultState],
    modals: {
        additionalMetric: {
            isOpen: false,
        },
        customDimension: {
            isOpen: false,
        },
    },
    globalFilterCondition: FilterGroupOperator.and,
    audiencePayload: {
        name: '',
        description: '',
        generationStrategy: QueryGenerationStrategy.AUDIENCE_BUILDER,
        nestedMetricQuery: undefined,
        sqlQuery: undefined,
        userAlias: undefined,
        aiPrompt: undefined,
        previewConfig: {
            previewFields: [],
        },
        tags: undefined,
    },
    initialAudiencePayload: {
        name: '',
        description: '',
        generationStrategy: QueryGenerationStrategy.AUDIENCE_BUILDER,
        nestedMetricQuery: undefined,
        sqlQuery: '',
        userAlias: undefined,
        aiPrompt: '',
        previewConfig: {
            previewFields: [],
        },
        tags: undefined,
    },
};

function reducer(
    state: AudienceReduceState,
    action: Action & {
        options?: { shouldFetchResults: boolean; index: number };
    },
): AudienceReduceState {
    state = {
        ...state,
        shouldFetchResults:
            action.options?.shouldFetchResults || state.shouldFetchResults,
    };

    switch (action.type) {
        case ActionType.ADD_NEW_GROUP: {
            return {
                ...state,
                unsavedChartVersion: [
                    ...state.unsavedChartVersion,
                    metricQueryDefaultState,
                ],
            };
        }
        case ActionType.SET_FETCH_RESULTS_FALSE: {
            return { ...state, shouldFetchResults: false };
        }

        case ActionType.SET_GLOBAL_FILTER_CONDITION: {
            const metricQueries = state.unsavedChartVersion.map(
                (metric) => metric.metricQuery,
            );

            let modifiedNestedMetricQuery: NestedMetricQueryGroup = {
                id: uuidv4(),
                and: metricQueries,
            };
            if (action.payload === 'or') {
                modifiedNestedMetricQuery = {
                    id: state.audiencePayload.nestedMetricQuery?.id ?? uuidv4(),
                    or: metricQueries,
                };
            }

            return {
                ...state,
                globalFilterCondition: action.payload,
                audiencePayload: {
                    ...state.audiencePayload,
                    nestedMetricQuery: modifiedNestedMetricQuery,
                    aiPrompt: undefined,
                },
            };
        }
        case ActionType.SET_FILTERS: {
            const indexToModify = action.options?.index ?? 0;
            const modifiedUnsavedChartVersion = state.unsavedChartVersion.map(
                (chartVersion, index) => {
                    if (index === indexToModify) {
                        return {
                            ...chartVersion,
                            metricQuery: {
                                ...chartVersion.metricQuery,
                                filters: action.payload,
                            },
                        };
                    }
                    return chartVersion;
                },
            );

            const metricQueries = modifiedUnsavedChartVersion.map(
                (metric) => metric.metricQuery,
            );

            let modifiedNestedMetricQuery: NestedMetricQueryGroup = {
                id: state.audiencePayload.nestedMetricQuery?.id ?? uuidv4(),
                and: metricQueries,
            };

            if (state.globalFilterCondition === 'or') {
                modifiedNestedMetricQuery = {
                    id: state.audiencePayload.nestedMetricQuery?.id ?? uuidv4(),
                    or: metricQueries,
                };
            }

            return {
                ...state,
                unsavedChartVersion: modifiedUnsavedChartVersion,
                audiencePayload: {
                    ...state.audiencePayload,
                    nestedMetricQuery: modifiedNestedMetricQuery,
                    aiPrompt: undefined,
                },
            };
        }
        case ActionType.SET_AUDIENCE_NAME: {
            return {
                ...state,
                audiencePayload: {
                    ...state.audiencePayload,
                    name: action.payload,
                },
            };
        }
        case ActionType.SET_AUDIENCE_PREVIEW_CONFIG: {
            return {
                ...state,
                audiencePayload: {
                    ...state.audiencePayload,
                    previewConfig: {
                        previewFields: action.payload,
                    },
                },
            };
        }
        case ActionType.SET_AUDIENCE_DESCRIPTION: {
            return {
                ...state,
                audiencePayload: {
                    ...state.audiencePayload,
                    description: action.payload,
                },
            };
        }
        case ActionType.SET_GENERATION_STRATEGY: {
            return {
                ...state,
                audiencePayload: {
                    ...state.audiencePayload,
                    generationStrategy: action.payload,
                },
            };
        }
        case ActionType.SET_SQL_QUERY: {
            return {
                ...state,
                audiencePayload: {
                    ...state.audiencePayload,
                    sqlQuery: action.payload,
                },
            };
        }
        case ActionType.SET_AI_PROMPT: {
            return {
                ...state,
                audiencePayload: {
                    ...state.audiencePayload,
                    aiPrompt: action.payload,
                },
            };
        }
        case ActionType.SET_UNSAVED_AUDIENCE_FILTER: {
            const modifiedUnsavedChartVersion = [...action.payload];
            const metricQueries = modifiedUnsavedChartVersion.map(
                (metric) => metric.metricQuery,
            );

            let modifiedNestedMetricQuery: NestedMetricQueryGroup = {
                id: state.audiencePayload.nestedMetricQuery?.id ?? uuidv4(),
                and: metricQueries,
            };

            if (state.globalFilterCondition === 'or') {
                modifiedNestedMetricQuery = {
                    id: state.audiencePayload.nestedMetricQuery?.id ?? uuidv4(),
                    or: metricQueries,
                };
            }

            return {
                ...state,
                unsavedChartVersion: [...action.payload],
                audiencePayload: {
                    ...state.audiencePayload,
                    nestedMetricQuery: modifiedNestedMetricQuery,
                },
            };
        }
        case ActionType.SET_USER_ALIAS: {
            return {
                ...state,
                audiencePayload: {
                    ...state.audiencePayload,
                    userAlias: action.payload,
                },
            };
        }
        case ActionType.ADD_UNSAVED_AUDIENCE_FILTER: {
            const unsavedChartVersion = [...state.unsavedChartVersion];
            const modifiedUnsavedChartVersion = [
                ...unsavedChartVersion.slice(0, action.payload.groupIndex),
                action.payload.filterGroup,
                ...unsavedChartVersion.slice(action.payload.groupIndex),
            ];

            return {
                ...state,
                unsavedChartVersion: [...modifiedUnsavedChartVersion],
            };
        }
        case ActionType.REMOVE_UNSAVED_AUDIENCE_FILTER: {
            const unsavedChartVersion = [...state.unsavedChartVersion];
            const modifiedUnsavedChartVersion = unsavedChartVersion.filter(
                (_, index) => index !== action.payload,
            );

            return {
                ...state,
                unsavedChartVersion: [...modifiedUnsavedChartVersion],
            };
        }
        case ActionType.SET_INITIAL_AUDIENCE_DATA: {
            return {
                ...state,
                initialAudiencePayload: state.audiencePayload,
            };
        }
        case ActionType.SET_ADDITIONAL_METRICS: {
            const indexToModify = action.options?.index ?? 0;
            const modifiedUnsavedChartVersion = state.unsavedChartVersion.map(
                (chartVersion, index) => {
                    if (index === indexToModify) {
                        return {
                            ...chartVersion,
                            metricQuery: {
                                ...chartVersion.metricQuery,
                                additionalMetrics: action.payload,
                                metrics: action.payload.map(
                                    (metric) =>
                                        `${metric.table}_${metric.name}`,
                                ),
                            },
                        };
                    }
                    return chartVersion;
                },
            );

            const metricQueries = modifiedUnsavedChartVersion.map(
                (metric) => metric.metricQuery,
            );

            let modifiedNestedMetricQuery: NestedMetricQueryGroup = {
                id: state.audiencePayload.nestedMetricQuery?.id ?? uuidv4(),
                and: metricQueries,
            };

            if (state.globalFilterCondition === 'or') {
                modifiedNestedMetricQuery = {
                    id: state.audiencePayload.nestedMetricQuery?.id ?? uuidv4(),
                    or: metricQueries,
                };
            }

            return {
                ...state,
                unsavedChartVersion: modifiedUnsavedChartVersion,
                audiencePayload: {
                    ...state.audiencePayload,
                    nestedMetricQuery: modifiedNestedMetricQuery,
                    aiPrompt: undefined,
                },
            };
        }
        case ActionType.SET_INSIGHTS_OVERLAP_PAYLOAD: {
            return {
                ...state,
                audiencePayload: {
                    ...state.audiencePayload,
                    insights: {
                        ...state.audiencePayload.insights,
                        overlapFilters: action.payload,
                        reachabilityFilters:
                            state.audiencePayload.insights?.reachabilityFilters,
                        breakdownFilters:
                            state.audiencePayload.insights?.breakdownFilters,
                    },
                },
            };
        }
        case ActionType.SET_INSIGHTS_BREAKDOWN_PAYLOAD: {
            return {
                ...state,
                audiencePayload: {
                    ...state.audiencePayload,
                    insights: {
                        ...state.audiencePayload.insights,
                        breakdownFilters: action.payload,
                        overlapFilters:
                            state.audiencePayload.insights?.overlapFilters,
                        reachabilityFilters:
                            state.audiencePayload.insights?.reachabilityFilters,
                    },
                },
            };
        }
        case ActionType.SET_AUDIENCE_TAGS: {
            return {
                ...state,
                audiencePayload: {
                    ...state.audiencePayload,
                    tags: action.payload,
                },
            };
        }
        default: {
            return assertUnreachable(
                action,
                'Unexpected action in audience reducer',
            );
        }
    }
}

const AudienceProvider: FC<
    React.PropsWithChildren<{
        isEditMode?: boolean;
        initialState?: AudienceReduceState;
        savedChart?: SavedChart;
        queryResults: ReturnType<
            typeof useQueryResults | typeof useChartVersionResultsMutation
        >;
    }>
> = ({
    isEditMode = false,
    initialState,
    savedChart,
    children,
    queryResults,
}) => {
    const [reducerState, dispatch] = useReducer(
        reducer,
        initialState || defaultState,
    );

    const { unsavedChartVersion } = reducerState;

    // const parser = useMemo(() => new Parser(), []);

    const setFilters = useCallback(
        (
            filters: MetricQuery['filters'],
            shouldFetchResults: boolean,
            index: number,
        ) => {
            dispatch({
                type: ActionType.SET_FILTERS,
                payload: filters,
                options: {
                    shouldFetchResults,
                    index,
                },
            });
        },
        [],
    );

    const addNewGroup = useCallback(() => {
        dispatch({
            type: ActionType.ADD_NEW_GROUP,
        });
    }, []);

    const setGlobalFilterCondition = useCallback(
        (condition: FilterGroupOperatorType) => {
            dispatch({
                type: ActionType.SET_GLOBAL_FILTER_CONDITION,
                payload: condition,
            });
        },
        [],
    );

    const setAudienceName = useCallback((name: string) => {
        dispatch({
            type: ActionType.SET_AUDIENCE_NAME,
            payload: name,
        });
    }, []);

    const setAudiencePreviewConfigData = useCallback(
        (audiencePreviewConfig: string[]) => {
            dispatch({
                type: ActionType.SET_AUDIENCE_PREVIEW_CONFIG,
                payload: audiencePreviewConfig,
            });
        },
        [],
    );

    const setAudienceDescription = useCallback((description: string) => {
        dispatch({
            type: ActionType.SET_AUDIENCE_DESCRIPTION,
            payload: description,
        });
    }, []);

    const setGenerationStategy = useCallback(
        (strategy: QueryGenerationStrategy) => {
            dispatch({
                type: ActionType.SET_GENERATION_STRATEGY,
                payload: strategy,
            });
        },
        [],
    );

    const setSqlQuery = useCallback((sqlQuery: string) => {
        dispatch({
            type: ActionType.SET_SQL_QUERY,
            payload: sqlQuery,
        });
    }, []);

    const setAIPrompt = useCallback((prompt: string) => {
        dispatch({
            type: ActionType.SET_AI_PROMPT,
            payload: prompt,
        });
    }, []);

    const setUnsavedAudienceFilter = useCallback(
        (chartVersionPayload: CreateSavedChartVersion[]) => {
            dispatch({
                type: ActionType.SET_UNSAVED_AUDIENCE_FILTER,
                payload: chartVersionPayload,
            });
        },
        [],
    );

    const addUnsavedAudienceFilter = useCallback(
        (filterGroup: CreateSavedChartVersion, groupIndex: number) => {
            dispatch({
                type: ActionType.ADD_UNSAVED_AUDIENCE_FILTER,
                payload: { filterGroup, groupIndex },
            });
        },
        [],
    );

    const removeUnsavedAudienceFilter = useCallback((index: number) => {
        dispatch({
            type: ActionType.REMOVE_UNSAVED_AUDIENCE_FILTER,
            payload: index,
        });
    }, []);

    const setUserAlias = useCallback((userAlias: string) => {
        dispatch({
            type: ActionType.SET_USER_ALIAS,
            payload: userAlias,
        });
    }, []);
    const setInitialAudienceData = useCallback(() => {
        dispatch({
            type: ActionType.SET_INITIAL_AUDIENCE_DATA,
        });
    }, []);
    const setAdditionalMetrics = useCallback(
        (
            additionalMetrics: AdditionalMetric[],
            shouldFetchResults: boolean,
            index: number,
        ) => {
            dispatch({
                type: ActionType.SET_ADDITIONAL_METRICS,
                payload: additionalMetrics,
                options: {
                    shouldFetchResults,
                    index,
                },
            });
        },
        [],
    );

    const setInsightsOverlapPayload = useCallback(
        (payload: AudienceInsights['overlapFilters']) => {
            dispatch({
                type: ActionType.SET_INSIGHTS_OVERLAP_PAYLOAD,
                payload,
            });
        },
        [],
    );

    const setInsightsBreakdownPayload = useCallback(
        (payload: AudienceInsights['breakdownFilters']) => {
            dispatch({
                type: ActionType.SET_INSIGHTS_BREAKDOWN_PAYLOAD,
                payload,
            });
        },
        [],
    );

    const isValidName = useMemo(() => {
        const { name } = reducerState.audiencePayload;
        if (!name.trim()) {
            return false;
        }
        return true;
    }, [reducerState.audiencePayload]);

    // const validateSqlQuery = useCallback(
    //     (sqlQuery: string) => {
    //         try {
    //             parser.parse(sqlQuery);
    //             return true;
    //         } catch (e) {
    //             return false;
    //         }
    //     },
    //     [parser],
    // );

    const isValidQuery = useMemo(() => {
        const { nestedMetricQuery, generationStrategy } =
            reducerState.audiencePayload;

        if (generationStrategy === QueryGenerationStrategy.AUDIENCE_BUILDER) {
            if (!nestedMetricQuery) return false;
            const queries = isAndNestedMetricQuery(nestedMetricQuery)
                ? nestedMetricQuery.and
                : nestedMetricQuery.or;
            return queries.length > 0;
        }
        //TODO: add sql query validation for warehouse

        // if (
        //     generationStrategy === QueryGenerationStrategy.AI ||
        //     generationStrategy === QueryGenerationStrategy.MANUAL
        // ) {
        //     if (!sqlQuery || !validateSqlQuery(sqlQuery)) return false;
        //     return true;
        // }
        return true;
    }, [reducerState.audiencePayload]);

    const { mutateAsync: mutateAsyncQuery } = queryResults;
    const { mutateAsync: mutateAsyncCreate, isLoading: isCreatingAudience } =
        useAudienceCreateMutation();
    const {
        mutateAsync: mutateAsyncActivate,
        isLoading: isPublishingAudience,
    } = useAudienceActivateMutation();

    const { activeRelationUuid } = useRelationContext();
    const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdatingAudience } =
        useAudienceUpdation();

    const state = useMemo(
        () => ({
            ...reducerState,
            isEditMode,
            savedChart,
            isValidQuery,
            isValidName,
            isCreatingAudience,
            isUpdatingAudience,
            isPublishingAudience,
        }),
        [
            isEditMode,
            reducerState,
            savedChart,
            isValidQuery,
            isValidName,
            isCreatingAudience,
            isPublishingAudience,
            isUpdatingAudience,
        ],
    );

    const mutateAsync = useCallback(async () => {
        const metriQueries: MetricQuery[] = unsavedChartVersion.map(
            (version) => {
                return version.metricQuery;
            },
        );
        try {
            const result = await mutateAsyncQuery(
                activeRelationUuid ?? '',
                metriQueries,
                state.globalFilterCondition,
            );

            return result;
        } catch (e) {
            console.error(e);
        }
    }, [
        mutateAsyncQuery,
        activeRelationUuid,
        unsavedChartVersion,
        state.globalFilterCondition,
    ]);

    useEffect(() => {
        if (!state.shouldFetchResults) return;

        void mutateAsync().then(() => {
            dispatch({
                type: ActionType.SET_FETCH_RESULTS_FALSE,
            });
        });
    }, [mutateAsync, state.shouldFetchResults]);

    const fetchResults = useCallback(() => {
        void mutateAsync();
    }, [mutateAsync]);

    const createAudience = useCallback(async () => {
        const result = await mutateAsyncCreate({
            payload: state.audiencePayload,
            relationUuid: activeRelationUuid,
        });
        return result;
    }, [mutateAsyncCreate, state.audiencePayload, activeRelationUuid]);
    const updateAudience = useCallback(
        async (audienceUuid: string) => {
            await mutateAsyncUpdate({
                payload: state.audiencePayload,
                relationUuid: activeRelationUuid,
                audienceId: audienceUuid,
            });
        },
        [mutateAsyncUpdate, state.audiencePayload, activeRelationUuid],
    );

    const activateAudience = useCallback(
        async (audienceUuid: string) => {
            await mutateAsyncActivate({
                audienceUuid,
                relationUuid: activeRelationUuid,
            });
        },
        [mutateAsyncActivate, activeRelationUuid],
    );

    const createActivateAudience = useCallback(async () => {
        const result = await createAudience();
        await activateAudience(result.id);
        return result;
    }, [createAudience, activateAudience]);
    const updateActivateAudience = useCallback(
        async (audienceId: string) => {
            await mutateAsyncUpdate({
                payload: state.audiencePayload,
                relationUuid: activeRelationUuid,
                audienceId,
            });
            await activateAudience(audienceId);
        },
        [
            activateAudience,
            mutateAsyncUpdate,
            activeRelationUuid,
            state.audiencePayload,
        ],
    );
    const setAudienceTags = useCallback((tags: string[]) => {
        dispatch({
            type: ActionType.SET_AUDIENCE_TAGS,
            payload: tags,
        });
    }, []);
    const actions = useMemo(
        () => ({
            setFilters,
            fetchResults,
            addNewGroup,
            setGlobalFilterCondition,
            createAudience,
            activateAudience,
            setAudienceName,
            setAudienceDescription,
            setGenerationStategy,
            setSqlQuery,
            setAIPrompt,
            createActivateAudience,
            setUnsavedAudienceFilter,
            setUserAlias,
            addUnsavedAudienceFilter,
            removeUnsavedAudienceFilter,
            setInitialAudienceData,
            setAudiencePreviewConfigData,
            updateActivateAudience,
            updateAudience,
            setAdditionalMetrics,
            setInsightsOverlapPayload,
            setInsightsBreakdownPayload,
            setAudienceTags,
        }),
        [
            setFilters,
            fetchResults,
            addNewGroup,
            setGlobalFilterCondition,
            createAudience,
            activateAudience,
            setAudienceName,
            setAudienceDescription,
            setGenerationStategy,
            setSqlQuery,
            setAIPrompt,
            createActivateAudience,
            setUnsavedAudienceFilter,
            setUserAlias,
            addUnsavedAudienceFilter,
            removeUnsavedAudienceFilter,
            setInitialAudienceData,
            setAudiencePreviewConfigData,
            updateActivateAudience,
            updateAudience,
            setAdditionalMetrics,
            setInsightsOverlapPayload,
            setInsightsBreakdownPayload,
            setAudienceTags,
        ],
    );

    const value: AudienceContext = useMemo(
        () => ({
            state,
            queryResults,
            actions,
        }),
        [actions, queryResults, state],
    );
    return (
        <AudienceProviderContext.Provider value={value}>
            {children}
        </AudienceProviderContext.Provider>
    );
};

export default AudienceProvider;
