import SkeletonLoader from '@components/common/SkeletonLoader';
import { Stack, TextInput } from '@mantine/core';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import Fuse from 'fuse.js';
import React, { useMemo, useState } from 'react';
import { X } from 'react-feather';
import { useDebounce } from 'react-use';
import { type ListSelectProps } from 'types/ListSelect';

const ListSelect: React.FC<ListSelectProps> = ({
    options,
    placeholder,
    onOptionSelect,
    rightSection,
    leftSection,
    selectedOption,
    withDivider = false,
    loading = false,
}) => {
    const [search, setSearch] = useState<string>('');
    const [searchDebounce, setSearchDebounce] = useState<string>('');
    useDebounce(() => setSearchDebounce(search), SEARCH_INPUT_DEBOUNCE_TIME, [
        search,
    ]);
    const filteredOptions = useMemo(() => {
        const validSearch = searchDebounce ? searchDebounce.toLowerCase() : '';
        if (options) {
            let fields = Object.values(options);
            if (validSearch !== '') {
                fields = new Fuse(Object.values(options), {
                    keys: ['value', 'uuid'],
                    threshold: 0.3,
                })
                    .search(validSearch)
                    .map((res) => res.item);
            }
            return fields;
        }
        return [];
    }, [options, searchDebounce]);

    const renderRightSection = useMemo(() => {
        if (search) {
            return (
                <X
                    size={13}
                    color={'rgb(var(--color-gray-500))'}
                    className="cursor-pointer"
                    onClick={() => setSearch('')}
                />
            );
        }

        return rightSection;
    }, [rightSection, search, setSearch]);

    const renderContent = () => {
        if (loading) {
            return (
                <Stack spacing={'sm'} ml={'sm'} mt={'sm'}>
                    <SkeletonLoader height={20} width={220} />
                    <SkeletonLoader height={20} width={220} />
                    <SkeletonLoader height={20} width={220} />
                    <SkeletonLoader height={20} width={220} />
                    <SkeletonLoader height={20} width={220} />
                </Stack>
            );
        }
        return filteredOptions?.map((item) => (
            <li
                key={item.uuid ?? item.key}
                className={`py-2.5
                    ${
                        selectedOption?.uuid === item.uuid
                            ? 'hover:rounded bg-blu-800 text-white px-2'
                            : 'hover:bg-shade-2 rounded px-2'
                    }
                `}
                onClick={() => !item.disabled && onOptionSelect(item)}
                style={{ cursor: item.disabled ? 'default' : 'pointer' }}
            >
                {item.renderComponent}
            </li>
        ));
    };

    return (
        <div className="h-full">
            <TextInput
                icon={leftSection}
                rightSection={renderRightSection}
                placeholder={placeholder}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sx={{
                    input: {
                        height: '2.825rem',
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                        paddingLeft: '2rem !important',
                    },
                }}
            />

            <ul
                className={`overflow-scroll border-t-0 rounded-t-none border-base h-[calc(100%-2.825rem)] shadow-card py-1   ${
                    withDivider ? 'divide-y divide-shade-6' : ''
                }`}
            >
                {renderContent()}
            </ul>
        </div>
    );
};

export default React.memo(ListSelect);
