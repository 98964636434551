import SubscriptionComponent from '@components/SubscriptionGroups';
import { DEFAULT_PROVIDER } from '@components/SubscriptionGroups/utils';
import { useUserSubscriptionPreferencesForChannel } from '@hooks/subscriptionGroups/useSubscriptionPreference';
import { type CommunicationChannel } from '@lightdash/common';
import { Box } from '@mantine/core';
import { useState } from 'react';
import { useParams } from 'react-router';

const ProfileEngagement = () => {
    const { projectUuid, profileId } = useParams<{
        projectUuid: string;
        profileId: string;
    }>();

    const [activeTab, setActiveTab] =
        useState<CommunicationChannel>(DEFAULT_PROVIDER);

    const {
        data: userSubscriptionPreference,
        isLoading: isSubscriptionPreferenceLoading,
    } = useUserSubscriptionPreferencesForChannel({
        channel: activeTab,
        userId: profileId,
        projectId: projectUuid,
    });

    return (
        <Box className="mx-3">
            <SubscriptionComponent
                showTitle={false}
                panelWidth={600}
                isViewOnly={true}
                allGroups={
                    userSubscriptionPreference?.categoryWisePreference ?? []
                }
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isFetchingData={isSubscriptionPreferenceLoading}
                globalPreference={
                    userSubscriptionPreference?.globalPreference ?? false
                }
                showGlobalPreference={true}
            />
        </Box>
    );
};

export default ProfileEngagement;
