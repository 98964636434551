import AccountSettings from '@pages/AccountSettings';
import CreateOrganization from '@pages/CreateOrganization';
import CreateProject from '@pages/CreateProject';
import CreateWorkspace from '@pages/CreateWorkspace';
// import CreateWorkspace from '@pages/CreateWorkspace';
import Invite from '@pages/Invite';
import JoinOrganization from '@pages/JoinOrganization';
import Login from '@pages/Login';
import OrganizationSettings from '@pages/OrganizationSettings';
import PasswordRecovery from '@pages/PasswordRecovery';
import PasswordReset from '@pages/PasswordReset';
import Projects from '@pages/Projects';
import Register from '@pages/Register';
import { type FC } from 'react';
import { Navigate, Outlet, useParams, type RouteObject } from 'react-router';
import ForbiddenPanel from './components/ForbiddenPanel';
import JobDetailsDrawer from './components/JobDetailsDrawer';
import NavBar from './components/NavBar';
import ProjectRoute from './components/Router/ProjectRoute';
import AuthPopupResult from './pages/AuthPopupResult';
import Catalog from './pages/Catalog';
import ChartHistory from './pages/ChartHistory';
// import CreateProjectSettings from './pages/CreateProjectSettings';
import Dashboard from './pages/Dashboard';
import Explorer from './pages/Explorer';
// import Home from './pages/Home';
// import MinimalDashboard from './pages/MinimalDashboard';
// import MinimalSavedExplorer from './pages/MinimalSavedExplorer';
import MetricsCatalog from './pages/MetricsCatalog';
import MinimalDashboard from './pages/MinimalDashboard';
import MinimalSavedExplorer from './pages/MinimalSavedExplorer';
import SavedDashboards from './pages/SavedDashboards';
import SavedExplorer from './pages/SavedExplorer';
import SavedQueries from './pages/SavedQueries';
import Space from './pages/Space';
import Spaces from './pages/Spaces';
import SqlRunner from './pages/SqlRunner';
// import UserActivity from './pages/UserActivity';
import VerifyEmailPage from './pages/VerifyEmail';
import AppRoute from '@components/Router/AppRouter';
import PrivateRoute from '@components/Router/PrivateRoute';
import { Stack } from '@mantine/core';
import SubscriptionPreferences from '@pages/SubscriptionPreferences';
import SqlRunnerNew from './pages/SqlRunnerNew';
import ViewSqlChart from './pages/ViewSqlChart';
import { TrackPage } from './providers/Tracking/TrackingProvider';
import { PageName } from './types/Events';
import UserCompletionModal from '@components/UserCompletionModal';
import CreateProjectSettings from '@pages/CreateProjectSettings';
import { Settings } from 'react-feather';
import ShareRedirect from '@pages/ShareRedirect';
import SemanticViewerEditorPage from '@pages/SemanticViewerEdit';
import SemanticViewerViewPage from '@pages/SemanticViewerView';
import UserActivity from '@pages/UserActivity';
import BlobStorage from '@pages/BlobStorage';
import CreateSchema from '@pages/CreateSchema';
import Relations from '@pages/Relations';
import AudienceBuilder from '@pages/AudienceBuilder';
import AudiencesManager from '@pages/AudiencesManager';
import TemplateManager from '@pages/TemplateManager';
import ProfilesBuilder from '@pages/ProfilesBuilder';
import ProfilesManager from '@pages/ProfilesManager';
import CampaignBuilder from '@pages/CampaignBuilder';
import EventsBuilder from '@pages/EventsBuillder';
import CampaignAnalyticsDashboard from '@pages/CampaignAnalyticsDashboard';
import CampaignManager from '@pages/CampaignManager';
import EventsManager from '@pages/EventsManager';
import CustomMetricManager from '@pages/CustomMetricManager';
import ProjectSettings from '@pages/ProjectSettings';
import JourneyBuilder from '@pages/JourneyBuilder';
import { JourneyBuilderMode } from '@components/Journeys/Builder/types';
import JourneysManager from '@pages/JourneysManager';
import OverviewPanel from '@components/OrganizationSettings/OverviewPanel';
import MembersPanel from '@components/OrganizationSettings/MembersPanel';
import ProfilePanel from '@components/AccountSettings/ProfilePanel';
import Overview from '@components/ProjectSettings/OverviewPanel';
import DataConnection from '@components/ProjectSettings/DataConnectionPanel';
import EventSourcePanel from '@components/ProjectSettings/EventSourcePanel';
import BlobSettings from '@components/ProjectSettings/BlobPanel';
import AuditLogs from '@components/ProjectSettings/AuditLogPanel';
import SyncSchedule from '@components/ProjectSettings/SyncSchedulePanel';
import Channels from '@components/ProjectSettings/ChannelPanel';
import SubscriptionGroupPanel from '@components/ProjectSettings/SubscriptionGroupPanel';
import ReachabilityVariables from '@components/ProjectSettings/ReachabilityVariables';
import DeliveryControls from '@components/ProjectSettings/DeliveryControls';
import WorkspaceMembers from '@components/ProjectSettings/MembersPanel';
import UnsubscribeGroup from '@pages/UnsubscribeGroup';
import AlertSettings from '@components/ProjectSettings/AlertSettings';

const DashboardPageWrapper: FC<{ keyParam: 'dashboardUuid' | 'tabUuid' }> = ({
    keyParam,
}) => {
    const params = useParams<{ dashboardUuid?: string; tabUuid?: string }>();
    return (
        <>
            <NavBar />
            <TrackPage name={PageName.DASHBOARD}>
                <Dashboard key={params[keyParam]} />
            </TrackPage>
        </>
    );
};

const FALLBACK_ROUTE: RouteObject = {
    path: '*',
    element: <Navigate to="/projects" />,
};

const PUBLIC_ROUTES: RouteObject[] = [
    {
        path: '/auth/popup/:status',
        element: <AuthPopupResult />,
    },
    {
        path: '/register',
        element: (
            <TrackPage name={PageName.REGISTER}>
                <Register />
            </TrackPage>
        ),
    },
    {
        path: '/login',
        element: (
            <TrackPage name={PageName.LOGIN}>
                <Login isSuperAdminLogin={false} />
            </TrackPage>
        ),
    },
    {
        path: '/super-admin-login',
        element: (
            <TrackPage name={PageName.LOGIN}>
                <Login isSuperAdminLogin={true} />
            </TrackPage>
        ),
    },
    {
        path: '/recover-password',
        element: (
            <TrackPage name={PageName.PASSWORD_RECOVERY}>
                <PasswordRecovery />
            </TrackPage>
        ),
    },
    {
        path: '/reset-password/:code',
        element: (
            <TrackPage name={PageName.PASSWORD_RESET}>
                <PasswordReset />
            </TrackPage>
        ),
    },
    {
        path: '/invite/:inviteCode',
        element: (
            <TrackPage name={PageName.SIGNUP}>
                <Invite />
            </TrackPage>
        ),
    },
    {
        path: '/verify-email',
        element: (
            <TrackPage name={PageName.VERIFY_EMAIL}>
                <VerifyEmailPage />
            </TrackPage>
        ),
    },
    {
        path: '/subscription-preferences/channel',
        element: (
            <TrackPage name={PageName.SUBSCRIPTION_PREFERENCES}>
                <SubscriptionPreferences />
            </TrackPage>
        ),
    },
    {
        path: '/unsubscribe',
        element: (
            <TrackPage name={PageName.UNSUBSCRIBE}>
                <UnsubscribeGroup />
            </TrackPage>
        ),
    },
    {
        path: '/join-organization',
        element: (
            <TrackPage name={PageName.JOIN_ORGANIZATION}>
                <JoinOrganization />
            </TrackPage>
        ),
    },
];

const MINIMAL_ROUTES: RouteObject[] = [
    {
        path: '/minimal',
        children: [
            {
                path: '/minimal/projects/:projectUuid/saved/:savedQueryUuid',
                element: (
                    <Stack p="lg" h="100vh">
                        <MinimalSavedExplorer />
                    </Stack>
                ),
            },
            {
                path: '/minimal/projects/:projectUuid/dashboards/:dashboardUuid',
                element: <MinimalDashboard />,
            },
        ],
    },
];

const CHART_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/saved',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.SAVED_QUERIES}>
                    <SavedQueries />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/saved/:savedQueryUuid',
        element: (
            <>
                <NavBar />
                <Outlet />
            </>
        ),
        children: [
            {
                path: '/projects/:projectUuid/saved/:savedQueryUuid/history',
                element: (
                    <TrackPage name={PageName.CHART_HISTORY}>
                        <ChartHistory />
                    </TrackPage>
                ),
            },
            {
                path: '/projects/:projectUuid/saved/:savedQueryUuid/:mode?',
                element: (
                    <TrackPage name={PageName.SAVED_QUERY_EXPLORER}>
                        <SavedExplorer />
                    </TrackPage>
                ),
            },
        ],
    },
];

const DASHBOARD_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/dashboards',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.SAVED_DASHBOARDS}>
                    <SavedDashboards />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/dashboards/:dashboardUuid',
        children: [
            {
                path: '/projects/:projectUuid/dashboards/:dashboardUuid/:mode?',
                element: <DashboardPageWrapper keyParam={'dashboardUuid'} />,
            },
            {
                path: '/projects/:projectUuid/dashboards/:dashboardUuid/:mode/tabs/:tabUuid?',
                element: <DashboardPageWrapper keyParam={'tabUuid'} />,
            },
        ],
    },
];

const SQL_RUNNER_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/sqlRunner', // Legacy route
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.SQL_RUNNER}>
                    <SqlRunner />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/sql-runner',
        element: (
            <>
                <NavBar />
                <Outlet />
            </>
        ),
        children: [
            {
                path: '/projects/:projectUuid/sql-runner',
                element: <SqlRunnerNew />,
            },
            {
                path: '/projects/:projectUuid/sql-runner/:slug',
                element: <ViewSqlChart />,
            },
            {
                path: '/projects/:projectUuid/sql-runner:slug/edit',
                element: <SqlRunnerNew isEditMode />,
            },
        ],
    },
];

const SEMANTIC_VIEWER_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/semantic-viewer',
        element: (
            <>
                <NavBar />
                <Outlet />
            </>
        ),
        children: [
            {
                path: '/projects/:projectUuid/semantic-viewer',
                element: (
                    <TrackPage name={PageName.SEMANTIC_VIEWER_EDIT}>
                        <SemanticViewerEditorPage />
                    </TrackPage>
                ),
            },
            {
                path: '/projects/:projectUuid/semantic-viewer/new',
                element: (
                    <TrackPage name={PageName.SEMANTIC_VIEWER_EDIT}>
                        <SemanticViewerEditorPage />
                    </TrackPage>
                ),
            },
            {
                path: '/projects/:projectUuid/semantic-viewer/:savedSemanticViewerChartSlug',
                element: (
                    <TrackPage name={PageName.SEMANTIC_VIEWER_VIEW}>
                        <SemanticViewerViewPage />
                    </TrackPage>
                ),
            },
            {
                path: '/projects/:projectUuid/semantic-viewer/:savedSemanticViewerChartSlug/edit',
                element: (
                    <TrackPage name={PageName.SEMANTIC_VIEWER_EDIT}>
                        <SemanticViewerEditorPage />
                    </TrackPage>
                ),
            },
        ],
    },
];

const TABLES_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/tables',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.EXPLORE_TABLES}>
                    <Explorer />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/tables/:tableId',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.EXPLORER}>
                    <Explorer />
                </TrackPage>
            </>
        ),
    },
];

const SPACES_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/spaces',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.SPACES}>
                    <Spaces />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/spaces/:spaceUuid',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.SPACE}>
                    <Space />
                </TrackPage>
            </>
        ),
    },
];

const METRICS_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/metrics',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.METRICS_CATALOG}>
                    <MetricsCatalog />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/metrics/peek/:tableName/:metricName',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.METRICS_CATALOG}>
                    <MetricsCatalog />
                </TrackPage>
            </>
        ),
    },
];

const ONBOARDING_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/warehouse',
        element: (
            <>
                <NavBar
                    showMenuItems={false}
                    showRelation={false}
                    showCustomMetric={false}
                    showEventsManager={false}
                />
                <TrackPage name={PageName.CREATE_PROJECT}>
                    <CreateProject />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/blob',
        element: (
            <>
                <NavBar
                    showMenuItems={false}
                    showRelation={false}
                    showCustomMetric={false}
                    showEventsManager={false}
                />
                <TrackPage name={PageName.BLOB_CONFIGURATION}>
                    <BlobStorage />
                </TrackPage>
            </>
        ),
    },
];

const SCHEMA_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/relations/create',
        element: (
            <>
                <NavBar showMenuItems={false} />
                <TrackPage name={PageName.CREATE_RELATIONS}>
                    <CreateSchema />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/relations',
        element: (
            <>
                <NavBar showMenuItems={true} />
                <TrackPage name={PageName.VIEW_RELATIONS}>
                    <Relations />
                </TrackPage>
            </>
        ),
    },
];

const AUDIENCE_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/audiences/create',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.CREATE_AUDIENCE}>
                    <AudienceBuilder />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/audiences/:audienceUuid/:mode?',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.VIEW_AUDIENCE}>
                    <AudienceBuilder />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/audiences',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.AUDIENCE_MANAGER}>
                    <AudiencesManager />
                </TrackPage>
            </>
        ),
    },
];

const TEMPLATES_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/templates',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.TEMPLATE_MANAGER}>
                    <TemplateManager />
                </TrackPage>
            </>
        ),
    },
];

const PROFILE_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/profiles/:profileId',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.PROFILE_BUILDER}>
                    <ProfilesBuilder />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/profiles',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.PROFILE_MANAGER}>
                    <ProfilesManager />
                </TrackPage>
            </>
        ),
    },
];

const CAMPAIGNS_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/campaigns/create',
        element: (
            <>
                <TrackPage name={PageName.CREATE_CAMPAIGN}>
                    <CampaignBuilder />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/campaigns/:campaignUuid/:mode?',
        element: (
            <>
                <TrackPage name={PageName.VIEW_CAMPAIGN}>
                    <CampaignBuilder />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/campaigns/dashboards/:dashboardUuid/:mode?',
        element: (
            <>
                <TrackPage name={PageName.DASHBOARD}>
                    <CampaignAnalyticsDashboard />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/campaigns',
        element: (
            <>
                <TrackPage name={PageName.CAMPAIGN_MANAGER}>
                    <NavBar />
                    <CampaignManager />
                </TrackPage>
            </>
        ),
    },
];

const EVENTS_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/events/create',
        element: (
            <>
                <TrackPage name={PageName.CREATE_EVENTS}>
                    <EventsBuilder />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/events/:eventsUuid/:source/:mode?',
        element: (
            <>
                <TrackPage name={PageName.VIEW_EVENTS}>
                    <EventsBuilder />
                </TrackPage>
            </>
        ),
    },
    {
        path: '/projects/:projectUuid/events',
        element: (
            <>
                <TrackPage name={PageName.EVENTS_MANAGER}>
                    <NavBar />
                    <EventsManager />
                </TrackPage>
            </>
        ),
    },
];

const TRAITS_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/traits',
        element: (
            <>
                <TrackPage name={PageName.TRAITS_MANAGER}>
                    <NavBar />
                    <CustomMetricManager />
                </TrackPage>
            </>
        ),
    },
];

const JOURNEY_ROUTES: RouteObject[] = [
    {
        path: `/projects/:projectUuid/journeys/${JourneyBuilderMode.CREATE}`,
        element: (
            <>
                <TrackPage name={PageName.CREATE_JOURNEY}>
                    <JourneyBuilder />
                </TrackPage>
            </>
        ),
    },
    {
        path: `/projects/:projectUuid/journeys/:journeyUuid/:mode?`,
        element: (
            <>
                <TrackPage name={PageName.VIEW_JOURNEY}>
                    <JourneyBuilder />
                </TrackPage>
            </>
        ),
    },
    {
        path: `/projects/:projectUuid/journeys`,
        element: (
            <>
                <TrackPage name={PageName.JOURNEY_MANAGER}>
                    <NavBar />
                    <JourneysManager />
                </TrackPage>
            </>
        ),
    },
];

const WORKSPACE_SETTINGS_ROUTES: RouteObject[] = [
    {
        path: '/projects/:projectUuid/settings/:tab?',
        element: (
            <>
                <NavBar />
                <TrackPage name={PageName.WORKSPACE_SETTINGS}>
                    <ProjectSettings />
                </TrackPage>
            </>
        ),
        children: [
            {
                path: 'overview',
                element: <Overview />,
            },
            {
                path: 'data-connection',
                element: <DataConnection />,
            },
            {
                path: 'blob',
                element: <BlobSettings />,
            },
            {
                path: 'event-sources',
                element: <EventSourcePanel />,
            },
            {
                path: 'sync-schedule',
                element: <SyncSchedule />,
            },
            {
                path: 'channels',
                element: <Channels />,
            },
            {
                path: 'audit-logs',
                element: <AuditLogs />,
            },
            {
                path: 'subscription',
                element: <SubscriptionGroupPanel />,
            },
            {
                path: 'variables',
                element: <ReachabilityVariables />,
            },
            {
                path: 'delivery-controls',
                element: <DeliveryControls />,
            },
            {
                path: 'members',
                element: <WorkspaceMembers />,
            },
            {
                path: 'alert',
                element: <AlertSettings />,
            },
        ],
    },
];

const APP_ROUTES: RouteObject[] = [
    {
        path: '/projects',
        element: (
            <AppRoute>
                <Outlet />
            </AppRoute>
        ),
        children: [
            {
                path: '/projects',
                element: <Projects />,
            },
            {
                path: '/projects/:projectUuid',
                element: (
                    <ProjectRoute>
                        <Outlet />
                    </ProjectRoute>
                ),
                children: [
                    ...ONBOARDING_ROUTES,
                    ...TABLES_ROUTES,
                    ...SQL_RUNNER_ROUTES,
                    ...CHART_ROUTES,
                    ...DASHBOARD_ROUTES,
                    ...SEMANTIC_VIEWER_ROUTES,
                    ...SPACES_ROUTES,
                    ...METRICS_ROUTES,
                    ...SCHEMA_ROUTES,
                    ...AUDIENCE_ROUTES,
                    ...TEMPLATES_ROUTES,
                    ...PROFILE_ROUTES,
                    ...CAMPAIGNS_ROUTES,
                    ...EVENTS_ROUTES,
                    ...TRAITS_ROUTES,
                    ...WORKSPACE_SETTINGS_ROUTES,
                    ...JOURNEY_ROUTES,
                    {
                        path: '/projects/:projectUuid/home',
                        element: (
                            <>
                                <NavBar />
                                <TrackPage name={PageName.HOME}>
                                    {/* <Home /> */}
                                    <Relations />
                                </TrackPage>
                            </>
                        ),
                    },
                    {
                        path: '/projects/:projectUuid/user-activity',
                        element: (
                            <>
                                <NavBar />
                                <TrackPage name={PageName.USER_ACTIVITY}>
                                    <UserActivity />
                                </TrackPage>
                            </>
                        ),
                    },
                    {
                        path: '/projects/:projectUuid/catalog',
                        element: (
                            <>
                                <NavBar />
                                <TrackPage name={PageName.CATALOG}>
                                    <Catalog />
                                </TrackPage>
                            </>
                        ),
                    },
                ],
            },
        ],
    },
];

const PRIVATE_ROUTES: RouteObject[] = [
    {
        path: '/',
        element: (
            <PrivateRoute>
                <UserCompletionModal />
                <JobDetailsDrawer />
                <Outlet />
            </PrivateRoute>
        ),
        children: [
            ...MINIMAL_ROUTES,
            ...APP_ROUTES,
            {
                path: '/',
                element: <Navigate to="/projects" replace />,
            },
            {
                path: '/create-organization',
                element: (
                    <>
                        <TrackPage name={PageName.CREATE_ORGANIZATION}>
                            <CreateOrganization />
                        </TrackPage>
                    </>
                ),
            },
            {
                path: '/create-workspace',
                element: (
                    <>
                        <TrackPage name={PageName.CREATE_WORKSPACE}>
                            <CreateWorkspace />
                        </TrackPage>
                    </>
                ),
            },
            {
                path: '/createProject/:method?',
                element: (
                    <>
                        <NavBar />
                        <TrackPage name={PageName.CREATE_PROJECT}>
                            <CreateProject />
                        </TrackPage>
                    </>
                ),
            },
            {
                path: '/account',
                element: (
                    <>
                        <NavBar />
                        <TrackPage name={PageName.ACCOUNT_SETTINGS}>
                            <AccountSettings />
                        </TrackPage>
                    </>
                ),
                children: [
                    {
                        path: 'profile',
                        element: <ProfilePanel />,
                    },
                ],
            },
            {
                path: '/organization',
                element: (
                    <>
                        <TrackPage name={PageName.ORGANIZATION_SETTINGS}>
                            <OrganizationSettings />
                        </TrackPage>
                    </>
                ),
                children: [
                    {
                        path: 'overview', // Relative to /organization
                        element: <OverviewPanel />,
                    },
                    {
                        path: 'members', // Relative to /organization
                        element: <MembersPanel />,
                    },
                ],
            },
            {
                path: '/createProjectSettings/:projectUuid',
                element: (
                    <>
                        <NavBar />
                        <TrackPage name={PageName.CREATE_PROJECT_SETTINGS}>
                            <CreateProjectSettings />
                        </TrackPage>
                    </>
                ),
            },
            {
                path: '/generalSettings/*',
                element: (
                    <>
                        <NavBar />
                        <TrackPage name={PageName.GENERAL_SETTINGS}>
                            <Settings />
                        </TrackPage>
                    </>
                ),
            },
            {
                path: '/no-access',
                element: (
                    <>
                        <NavBar />
                        <TrackPage name={PageName.NO_ACCESS}>
                            <ForbiddenPanel />
                        </TrackPage>
                    </>
                ),
            },
            {
                path: '/no-project-access',
                element: (
                    <>
                        <NavBar />
                        <TrackPage name={PageName.NO_PROJECT_ACCESS}>
                            <ForbiddenPanel subject="project" />
                        </TrackPage>
                    </>
                ),
            },
            {
                path: '/share/:shareNanoid',
                element: (
                    <>
                        <NavBar />
                        <TrackPage name={PageName.SHARE}>
                            <ShareRedirect />
                        </TrackPage>
                    </>
                ),
            },
        ],
    },
];

const WebAppRoutes = [...PUBLIC_ROUTES, ...PRIVATE_ROUTES, FALLBACK_ROUTE];
export default WebAppRoutes;
