import BuilderStepTitle from '@components/common/BuilderContainer/BuilderStepTitle';
import { EventBuilderStep } from '@components/EventsManager/types';
import {
    EvenTableRequiredColumns,
    EventBuilderSteps,
    isValidStepForEventKeyBuilder,
} from '@components/EventsManager/utils';
import { Box, Flex } from '@mantine/core';
import useEventContext from '@providers/Events/useEventContext';
import { useEffect } from 'react';
import EventKeySelect from './EventKeySelect';

const EventBuilderKeys: React.FC<{}> = ({}) => {
    const { state, actions } = useEventContext((context) => context);
    const { jsonPayloadKeys } = state;
    const { setShowFooterButtons } = actions;
    const title = EventBuilderSteps.find(
        (step) => step.key === EventBuilderStep.KEYS,
    )?.pageHeader;
    useEffect(() => {
        if (isValidStepForEventKeyBuilder(jsonPayloadKeys)) {
            setShowFooterButtons({
                next: true,
                disableNext: false,
                back: true,
            });
        } else {
            setShowFooterButtons({
                next: false,
                disableNext: true,
                back: true,
            });
        }
    }, [jsonPayloadKeys, setShowFooterButtons]);

    return (
        <Box>
            <BuilderStepTitle title={title || ''} />
            <Flex direction={'column'} gap={16} className="pt-4">
                {EvenTableRequiredColumns.map((column) => {
                    return (
                        <EventKeySelect key={column.value} column={column} />
                    );
                })}
            </Flex>
        </Box>
    );
};
export default EventBuilderKeys;
