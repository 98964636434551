import { useContextSelector } from 'use-context-selector';
import EventsProviderContext from './context';
import { type EventContext } from './types';

function useEventContext<Selected>(
    selector: (value: EventContext) => Selected,
) {
    return useContextSelector(EventsProviderContext, (context) => {
        if (context === undefined) {
            throw new Error(
                'useEventContext must be used within an EventProvider',
            );
        }
        return selector(context);
    });
}

export default useEventContext;
