import {
    LightdashRequestMethodHeader,
    RequestMethod,
    type ApiError,
    type ApiResponse,
} from '@lightdash/common';
import { startSpan, spanToTraceHeader } from '@sentry/react';
import fetch from 'isomorphic-fetch';

export const BASE_API_URL =
    import.meta.env.VITEST === 'true'
        ? `http://test.lightdash/`
        : import.meta.env.BASE_URL;

const defaultHeaders = {
    'Content-Type': 'application/json',
    [LightdashRequestMethodHeader]: RequestMethod.WEB_APP,
};

const handleError = (err: any): ApiError => {
    if (err.error?.statusCode && err.error?.name) return err;
    return {
        status: 'error',
        error: {
            name: 'NetworkError',
            statusCode: 500,
            message: `Could not connect to Sortment server. Please verify your network connection and server configuration.`,
            data: err,
        },
    };
};

type SortmentApiProps = {
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
    url: string;
    body: BodyInit | null | undefined;
    headers?: Record<string, string> | undefined | null;
    version?: 'v1' | 'v2';
    stream?: boolean;
};

export const sortmentApi = async <
    T extends ApiResponse['results'] | ReadableStream<Uint8Array>,
>({
    method,
    url,
    body,
    headers,
    version = 'v1',
    stream = false,
}: SortmentApiProps): Promise<T> => {
    const apiPrefix = `${BASE_API_URL}api/${version}`;
    let sentryTrace: string | undefined;
    // Manually create a span for the fetch request to be able to trace it in Sentry. This also enables Distributed Tracing.
    startSpan(
        {
            op: 'http.client',
            name: `API Request: ${method} ${url}`,
            attributes: {
                'http.method': method,
                'http.url': url,
                type: 'fetch',
                url,
                method,
            },
        },
        (s) => {
            sentryTrace = spanToTraceHeader(s);
        },
    );

    const finalHeaders =
        headers === null
            ? {}
            : {
                  ...defaultHeaders,
                  ...headers,
                  ...(sentryTrace ? { 'sentry-trace': sentryTrace } : {}),
              };

    const response = await fetch(`${apiPrefix}${url}`, {
        method,
        headers: finalHeaders,
        body,
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw handleError(errorData);
    }

    if (stream) {
        if (!response.body) {
            throw new Error('ReadableStream not supported in this browser.');
        }
        return response.body as T;
    }

    return response.json().then((d: ApiResponse | ApiError) => {
        if (d.status === 'ok') {
            // make sure we return null instead of undefined
            // otherwise react-query will crash
            return (d.results ?? null) as T;
        } else {
            throw d;
        }
    });
};

/**
 * Fetches a stream from the given URL and processes each chunk.
 * @param url - The URL to fetch the stream from.
 * @param onChunk - Callback function to handle each chunk.
 * @param onComplete - Callback function to handle the completion of the stream.
 * @param onError - Callback function to handle errors.
 */
export async function handleStream(
    url: string,
    onChunk: (chunk: any) => void,
    onComplete: () => void,
    onError: (error: Error) => void,
    body: any,
    method: 'GET' | 'POST' = 'GET',
): Promise<void> {
    try {
        const stream = await sortmentApi<ReadableStream<Uint8Array>>({
            method,
            url,
            body: JSON.stringify(body),
            stream: true,
        });

        const reader = stream.getReader();
        const decoder = new TextDecoder('utf-8');

        let buffer = '';
        let done = false;
        while (!done) {
            const { value, done: readerDone } = await reader.read();
            done = readerDone;
            if (value) {
                buffer = decoder.decode(value, { stream: true });
                const chunk = JSON.parse(buffer);
                onChunk(chunk);
            }
        }

        onComplete();
    } catch (error) {
        onError(error);
    }
}
