import LightdashVisualization from '@components/LightdashVisualization';
import VisualizationProvider from '@components/LightdashVisualization/VisualizationProvider';
import { useQueryResults } from '@hooks/useQueryResults';
import { type ChartConfig, type MetricQuery } from '@lightdash/common';
import { Skeleton } from '@mantine/core';
import useRelationContext from '@providers/Relation/useRelationContext';
import React, { useEffect } from 'react';

interface AiChartProps {
    metricQuery: MetricQuery;
    chartConfig: ChartConfig;
}
const AiChart: React.FC<AiChartProps> = ({ metricQuery, chartConfig }) => {
    const { activeRelation } = useRelationContext();

    const {
        mutateAsync: getAiGeneratedChartData,
        isLoading,
        data,
    } = useQueryResults();

    useEffect(() => {
        void getAiGeneratedChartData(activeRelation?.name ?? '', metricQuery);
    }, [activeRelation?.name, getAiGeneratedChartData, metricQuery]);

    if (isLoading) {
        return <Skeleton height={20} width={100} />;
    }
    return (
        <VisualizationProvider
            isLoading={false}
            initialPivotDimensions={[]}
            resultsData={data}
            chartConfig={chartConfig}
            columnOrder={[]}
            pivotTableMaxColumnLimit={5}
            colorPalette={[
                '#0195ff',
                '#fc8452',
                '#91cc75',
                '#fac858',
                '#ee6666',
                '#73c0de',
                '#3ba272',
                '#9a60b4',
                '#ea7ccc',
            ]}
        >
            <LightdashVisualization
                className="sentry-block ph-no-capture !h-[300px] !w-[500px] overflow-x-scroll"
                data-testid="visualization"
            />
        </VisualizationProvider>
    );
};

export default AiChart;
