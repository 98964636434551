import { type CommunicationChannel } from './communications';

export type BaseFynoResponse = {
    readonly status: 'success' | 'error';
    readonly _message: string;
};
export type Throttle = {
    interval: number;
    batch: number;
    type: string;
};
export type CampaignThrottleConfig =
    | CampaignThrottleConfigBatch
    | CampaignThrottleConfigInterval;

export enum CampaignThrottleType {
    BATCH = 'batch',
    TIME_INTERVAL = 'timeInterval',
}

export type CampaignThrottleConfigBatch = {
    batch: number;
    type: CampaignThrottleType.BATCH;
};

export type CampaignThrottleConfigInterval = {
    interval: number;
    type: CampaignThrottleType.TIME_INTERVAL;
};

export type IntegrationDetails = {
    integrationId: string;
    providerId: string;
    integrationName: string;
    credentials: {
        [key: string]: string;
    };
    throttle?: Throttle;
};

export type IntegrationCreateRequest = {
    providerId: string;
    customName: string;
    metadata: {
        [key: string]: string;
    };
    channel: CommunicationChannel;
    throttle?: Omit<Throttle, 'type'>;
};

export type IntegrationCreateResponse = BaseFynoResponse & {
    readonly id: string;
};

export type UpdateIntegrationRequest = {
    customName: string;
    integrationId: string;
    providerId: string;
    config: {
        [key: string]: string;
    };
    editedEncKeys?: Array<string>;
    throttle?: Omit<Throttle, 'type'>;
};

export const ThrottleBatchSize = {
    WA: 2000,
    Email: 2000,
    SMS: 2000,
    UNKNOWN: 2000,
};
