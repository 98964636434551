import Select from '@components/common/Select';
import SelectWithSearch from '@components/common/Select/SelectWithSearch';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import {
    Box,
    Button,
    Divider,
    Flex,
    Menu,
    Text,
    TextInput,
} from '@mantine/core';
import {
    ArrowRight,
    GridFour,
    Info,
    Plugs,
    PlusCircle,
    Table,
    X,
} from '@phosphor-icons/react';
import React, { useEffect, useMemo, useState } from 'react';
import { ButtonVariant } from '../../../../../mantineTheme';
// import useNotify from '@hooks/toaster/useNotify';
import { useUpdateTableDetail } from '@hooks/useSchemaBuilder';
import {
    JoinType,
    RelationTableType,
    type CompiledDimension,
    type JoinResponse,
} from '@lightdash/common';
import useRelationContext from '@providers/Relation/useRelationContext';
import useSchemaContext from '@providers/Schema/useSchemaContext';
import { useQueryClient } from '@tanstack/react-query';
import { t as translate } from 'i18next';
import { QueryKeys } from 'types/UseQuery';

const JOIN_TYPE_OPTIONS = [
    {
        label: translate('custom_edge.one_one'),
        value: JoinType.one_one,
    },
    {
        label: translate('custom_edge.one_many'),
        value: JoinType.one_many,
    },
    {
        label: translate('custom_edge.many_one'),
        value: JoinType.many_one,
    },
    {
        label: translate('custom_edge.many_many'),
        value: JoinType.many_many,
    },
];

const MenuItem = ({ name, onClick }: { name: string; onClick: () => void }) => {
    return (
        <Menu.Item
            className="p-2 rounded-md cursor-pointer hover:bg-gray-100"
            onClick={onClick}
        >
            <Flex gap={4} align="center">
                <Table />
                <Text className="font-medium text-gray-800 truncate max-w-[200px]">
                    {name}
                </Text>
            </Flex>
        </Menu.Item>
    );
};

const getFormattedRelationshipsForForm = (relationships: JoinResponse[]) => {
    return relationships.reduce(
        (
            accum: {
                [key: string]: JoinResponse[];
            },
            curr,
        ) => {
            if (accum[curr.target.table]) {
                const tmp = { ...curr };
                return {
                    ...accum,
                    [curr.target.table]: [...accum[curr.target.table], tmp],
                };
            }
            const tmp = { ...curr };
            return {
                ...accum,
                [curr.target.table]: [tmp],
            };
        },
        {},
    );
};

const getFormattedRelationshipsPayload = (relationships: {
    [key: string]: JoinResponse[];
}) => {
    let payload: JoinResponse[] = [];
    Object.keys(relationships).forEach((key) => {
        payload = [...payload, ...relationships[key]];
    });
    return payload;
};

const getSelectDataFromDimensions = (dimensions: CompiledDimension[]) => {
    return dimensions.map((item) => ({
        label: item.label,
        name: item.name,
        type: item.type,
        hidden: item.hidden,
        value: item.name,
    }));
};

const RelationshipManager = () => {
    const { t } = useLocale();
    const queryClient = useQueryClient();
    // const { showToastError } = useNotify();
    const [search, setSearch] = useState<string>('');
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const [relations, setRelations] = useState<{
        [key: string]: any[];
    }>({});
    const { schemaPayload, activeProject } = useSchemaContext(
        (context) => context.state,
    );
    const { mutateAsync: updateTableDetail } =
        useUpdateTableDetail(activeRelationUuid);
    const { mutateAsync: updateCacheColumn } = useUpdateTableDetail(
        activeRelationUuid,
        false,
    );

    // const existingAliases = useMemo(() => {
    //     return Object.keys(activeRelation?.tables ?? {}).map((key) =>
    //         key.toLowerCase(),
    //     );
    // }, [activeRelation?.tables]);

    const tables = useMemo(
        () =>
            Object.values(activeRelation?.tables ?? {}).map(
                ({
                    database,
                    dimensions,
                    label,
                    name,
                    schema,
                    type,
                    isConfigured,
                }) => {
                    return {
                        database,
                        columns: Object.values(dimensions).map((item) => {
                            return {
                                label: item.label,
                                name: item.name,
                                type: item.type,
                                hidden: item.hidden,
                                value: item.name,
                            };
                        }),
                        label,
                        tableLabel: label,
                        name,
                        schema,
                        type,
                        isConfigured,
                    };
                },
            ),
        [activeRelation?.tables],
    );

    useEffect(() => {
        setRelations(
            getFormattedRelationshipsForForm(schemaPayload.relationships),
        );
    }, [schemaPayload.relationships]);

    const orphanTables = useMemo(() => {
        return tables.filter((table) => !table.isConfigured);
    }, [tables]);

    const filteredOrphanTables = useMemo(() => {
        return orphanTables.filter((table) =>
            table.name.toLowerCase().includes(search.toLowerCase()),
        );
    }, [orphanTables, search]);

    const joinedTables = useMemo(() => {
        return tables.filter(
            (table) => table.isConfigured && schemaPayload.name !== table.name,
        );
    }, [tables, schemaPayload.name]);

    const filteredJoinedTables = useMemo(() => {
        return joinedTables.filter((table) =>
            table.name.toLowerCase().includes(search.toLowerCase()),
        );
    }, [joinedTables, search]);

    const sourceTable: any = tables?.find(
        (table) => table.name === schemaPayload.name,
    );

    const handleAddUseCaseClick = (key: string) => {
        setRelations((prev) => ({
            ...prev,
            [key]: [
                ...prev[key],
                {
                    source: {
                        field: null,
                    },
                    target: {
                        table: key,
                        field: null,
                    },
                    joinType: null,
                },
            ],
        }));
    };

    const handleSourceColumnChange = (payload: {
        value: string;
        key: string;
        index: number;
    }) => {
        const { value, key, index } = payload;
        const newRelations = { ...relations };
        newRelations[key][index].source = {
            ...newRelations[key][index].source,
            field: value,
        };
        setRelations({ ...newRelations });
    };

    const handleJoinTypeChange = (payload: {
        value: string;
        key: string;
        index: number;
    }) => {
        const { value, key, index } = payload;
        const newRelations = { ...relations };
        newRelations[key][index].joinType = value;
        setRelations({ ...newRelations });
    };

    const handleTargetColumnChange = (payload: {
        value: string;
        key: string;
        index: number;
    }) => {
        const { value, key, index } = payload;
        const newRelations = { ...relations };
        newRelations[key][index].target = {
            ...newRelations[key][index].target,
            field: value,
        };
        setRelations({ ...newRelations });
    };

    const handleAliasChange = (payload: {
        value: string;
        key: string;
        index: number;
    }) => {
        const { value, key, index } = payload;
        const newRelations = { ...relations };
        if (newRelations[key][index].target?.alias) {
            newRelations[key][index].target.alias = value;
        } else {
            newRelations[key][index].target = {
                ...newRelations[key][index].target,
                alias: value,
            };
        }
        setRelations({ ...newRelations });
    };

    const handleDeleteRelation = (key: string, index: number) => {
        const newRelations = { ...relations };
        if (newRelations[key].length > 1) {
            newRelations[key].splice(index, 1);
            setRelations({ ...newRelations });
        } else {
            delete newRelations[key];
            setRelations({ ...newRelations });
        }
    };

    const handleConnectTable = (name: string) => {
        setRelations((prev) => ({
            ...prev,
            [name]: prev[name]
                ? [
                      ...prev[name],
                      {
                          source: {
                              field: null,
                          },
                          target: {
                              table: name,
                              field: null,
                          },
                          joinType: null,
                      },
                  ]
                : [
                      {
                          source: {
                              field: null,
                          },
                          target: {
                              table: name,
                              field: null,
                          },
                          joinType: null,
                      },
                  ],
        }));
    };

    const handleSave = async () => {
        const joinedTablesInRelations = Object.keys(relations);
        const payload = getFormattedRelationshipsPayload(relations);
        const eventTables = Object.values(activeRelation?.tables ?? {}).filter(
            (table) =>
                joinedTablesInRelations.includes(table.name) &&
                table.type === RelationTableType.EVENT,
        );

        await updateTableDetail({
            tableName: schemaPayload.name,
            payload: {
                relationships: payload,
                type: schemaPayload.type,
            },
        });
        if (eventTables.length) {
            const updates = eventTables.map((table) => {
                if ('eventNameColumn' in table && table.eventNameColumn) {
                    return updateCacheColumn({
                        tableName: table.name,
                        payload: {
                            type: table.type,
                            dimensions: {
                                [table.eventNameColumn]: { cached: true },
                            },
                        },
                    });
                }
            });
            await Promise.all(updates);
        }
        await queryClient.invalidateQueries([
            QueryKeys.RELATION_SCHEMA,
            activeProject?.projectUuid,
            activeRelationUuid,
        ]);
    };

    return (
        <Box>
            <Flex align={'center'} gap={4} className="mb-3">
                <Info color="rgb(var(--color-gray-600))" />
                <Text className="text-gray-600">
                    {t('schema_builder.relations.info')}
                </Text>
            </Flex>
            {Object.keys(relations).map((key) => {
                const relationItems = relations[key];
                const targetTable = activeRelation?.tables[key];
                // const isOrphanTable = !targetTable?.isConfigured;
                return (
                    <>
                        <Box key={key} className="mb-3">
                            <Flex gap={8} align={'center'} className="mb-3">
                                <Flex gap={4} align={'center'}>
                                    <GridFour
                                        size={12}
                                        color="rgb(var(--color-green))"
                                    />
                                    <Text>{sourceTable.label}</Text>
                                </Flex>
                                <ArrowRight
                                    weight="regular"
                                    color="rgb(var(--color-gray-800))"
                                />
                                <Flex gap={4} align={'center'}>
                                    <Table
                                        size={12}
                                        color="rgb(var(--color-gray-800))"
                                    />
                                    <Text className="font-medium text-gray-800">
                                        {key}
                                    </Text>
                                </Flex>
                            </Flex>
                            {relationItems.map((item, index) => (
                                <Flex
                                    key={JSON.stringify(item)}
                                    gap={12}
                                    align="baseline"
                                >
                                    <Box className="rounded-xl bg-gray-50 p-[3px] mb-1 w-[210px]">
                                        <SelectWithSearch
                                            value={item.source.field}
                                            options={sourceTable.columns}
                                            onChange={(value) =>
                                                handleSourceColumnChange({
                                                    value: value ?? '',
                                                    key,
                                                    index,
                                                })
                                            }
                                            placeholder={`Column in ${schemaPayload.label}`}
                                        />
                                    </Box>
                                    <Box>
                                        <Select
                                            value={item.joinType}
                                            placeholder="Join type"
                                            data={JOIN_TYPE_OPTIONS}
                                            className="!max-w-[130px]"
                                            onChange={(value) =>
                                                handleJoinTypeChange({
                                                    value: value ?? '',
                                                    key,
                                                    index,
                                                })
                                            }
                                        />
                                    </Box>
                                    <Box className="rounded-xl bg-gray-50 p-[3px] mb-1 w-[210px]">
                                        <SelectWithSearch
                                            value={item.target.field}
                                            options={getSelectDataFromDimensions(
                                                Object.values(
                                                    targetTable?.dimensions ??
                                                        {},
                                                ),
                                            )}
                                            onChange={(value) =>
                                                handleTargetColumnChange({
                                                    value: value ?? '',
                                                    key,
                                                    index,
                                                })
                                            }
                                            placeholder={`Column in ${key}`}
                                        />
                                    </Box>
                                    <Box>
                                        <TextInput
                                            defaultValue={
                                                index === 0
                                                    ? targetTable?.name
                                                    : item.target?.alias
                                            }
                                            onBlur={(e) =>
                                                handleAliasChange({
                                                    value: e.target.value,
                                                    key,
                                                    index,
                                                })
                                            }
                                            placeholder={targetTable?.label}
                                            disabled={index === 0}
                                        />
                                    </Box>
                                    <Flex
                                        align="center"
                                        justify="center"
                                        className="rounded-lg border-base h-9 w-9"
                                    >
                                        <X
                                            onClick={() =>
                                                handleDeleteRelation(key, index)
                                            }
                                            weight="regular"
                                            color="rgb(var(--color-gray-700))"
                                            className="cursor-pointer"
                                        />
                                    </Flex>
                                </Flex>
                            ))}
                            <Button
                                className="mt-2"
                                leftIcon={<PlusCircle />}
                                variant={ButtonVariant.OUTLINED}
                                onClick={() => handleAddUseCaseClick(key)}
                            >
                                Add use-case
                            </Button>
                        </Box>
                        <Divider size="md" className="mb-3 border-t-shade-2" />
                    </>
                );
            })}
            <Menu withinPortal position="bottom-start">
                <Menu.Target>
                    <Button
                        leftIcon={<Plugs />}
                        variant={ButtonVariant.OUTLINED}
                    >
                        Connect Table
                    </Button>
                </Menu.Target>
                <Menu.Dropdown>
                    <Box className="w-[260px] max-h-[400px] overflow-auto">
                        <Box className="sticky top-0 p-1 bg-white border-b border-b-gray-200">
                            <SearchInput
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search"
                            />
                        </Box>
                        <Box>
                            {filteredOrphanTables.length === 0 &&
                            filteredJoinedTables.length === 0 ? (
                                <Text className="pl-2 mt-3 mb-1 text-xs font-medium text-gray-600">
                                    No results found
                                </Text>
                            ) : null}
                            {filteredOrphanTables.length ? (
                                <>
                                    {joinedTables.length ? (
                                        <Text className="pl-2 mt-3 mb-1 text-xs font-medium text-gray-600">
                                            New
                                        </Text>
                                    ) : null}
                                    {filteredOrphanTables.map((table) => (
                                        <MenuItem
                                            key={table.name}
                                            name={table.name}
                                            onClick={() =>
                                                handleConnectTable(table.name)
                                            }
                                        />
                                    ))}
                                </>
                            ) : null}
                            {filteredOrphanTables.length &&
                            filteredJoinedTables.length ? (
                                <Divider
                                    size="md"
                                    className="my-2 border-t-shade-2"
                                />
                            ) : null}
                            {filteredJoinedTables.length ? (
                                <>
                                    {orphanTables.length ? (
                                        <Text className="pl-2 mb-1 text-xs font-medium text-gray-600">
                                            Existing
                                        </Text>
                                    ) : null}
                                    {filteredJoinedTables.map((table) => (
                                        <MenuItem
                                            key={table.name}
                                            name={table.name}
                                            onClick={() =>
                                                handleConnectTable(table.name)
                                            }
                                        />
                                    ))}
                                </>
                            ) : null}
                        </Box>
                    </Box>
                </Menu.Dropdown>
            </Menu>
            <Flex
                justify="flex-end"
                gap={8}
                className="fixed bottom-0 w-full p-4 my-0 -ml-3 bg-white border-t-2"
            >
                <Button variant={ButtonVariant.OUTLINED}>Reset Changes</Button>
                <Button onClick={handleSave}>Save</Button>
            </Flex>
        </Box>
    );
};

export default RelationshipManager;
