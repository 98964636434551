import SchemaBuilderPage from '@components/SchemaBuilder/Builder/SchemaBuilderPage';
import { useSchemaInitialData } from '@components/SchemaBuilder/useSchemaInitialData';
import { RelationTableType } from '@lightdash/common';
import useProjectContext from '@providers/Project/useProjectContext';
import SchemaProvider from '@providers/Schema/SchemaProvider';
import { useNavigate } from 'react-router';

const CreateSchema = () => {
    const navigate = useNavigate();
    const { projectData: activeProject } = useProjectContext();
    const initialState = useSchemaInitialData(activeProject);
    initialState.schemaPayload.type = RelationTableType.PRIMARY;

    if (!activeProject?.needsRelation) {
        void navigate(`/projects/${activeProject?.projectUuid}/relations`);
        return null;
    }

    return (
        <SchemaProvider initialState={initialState}>
            <SchemaBuilderPage />
        </SchemaProvider>
    );
};

export default CreateSchema;
