import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { JoinType } from '@lightdash/common';
import useRelationContext from '@providers/Relation/useRelationContext';
import { filterTablesFromRelation } from '@utils/relation';
import { useMemo } from 'react';

const useGetFieldById = () => {
    const { getTableRelation, activeRelation } = useRelationContext();

    const campaignRelationData = useMemo(() => {
        if (activeRelation) {
            const allowedRelationTables = getTableRelation([
                JoinType.one_one,
                JoinType.many_one,
            ]);
            if (!allowedRelationTables) return;
            const tableIds = allowedRelationTables.map((table) => table.name);
            const filteredRelation = filterTablesFromRelation(
                activeRelation,
                tableIds,
            );
            if (!filteredRelation) return;
            return filteredRelation;
        }
    }, [activeRelation, getTableRelation]);

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: campaignRelationData,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });

    const fieldWithSuggestionInArray = useMemo(() => {
        return Object.keys(fieldsWithSuggestions)?.map((fieldKey: string) => {
            return {
                ...fieldsWithSuggestions?.[fieldKey],
                uniqueIdentifier:
                    fieldsWithSuggestions?.[fieldKey].uniqueIdentifier ??
                    fieldKey,
            };
        });
    }, [fieldsWithSuggestions]);

    const getFieldById = (uniqueIdentifier: string) => {
        return fieldWithSuggestionInArray.find(
            (field) => field.uniqueIdentifier === uniqueIdentifier,
        );
    };

    return {
        getFieldById,
    };
};

export default useGetFieldById;
