import AccountSettingsSidebar from '@components/AccountSettings/Sidebar';
import Page from '@components/Page/Page';
import { useLocale } from '@hooks/useLocale';
import React from 'react';
import { Outlet } from 'react-router';

const AccountSettings: React.FC<{}> = ({}) => {
    const { t } = useLocale();

    return (
        <Page
            withFullHeight
            withPaddedContent
            title={t('project_settings.page_title')}
            sidebar={<AccountSettingsSidebar />}
        >
            <Outlet />
        </Page>
    );
};

export default AccountSettings;
