import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import useNotify from '@hooks/toaster/useNotify';
import {
    usePublishCampaign,
    useStopCampaign,
    useUpdateCampaign,
} from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    CampaignScheduleType,
    CampaignStatus,
    type Campaign,
    type Schedule,
} from '@lightdash/common';
import { Button } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import { StopCircle } from '@phosphor-icons/react/dist/ssr';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../../../mantineTheme';
import CampaignScheduler from './CampaignScheduler';

interface CampaignSchedulerModalProps {
    showSchedulerModal: boolean;
    schedulerModalClose: () => void;
    showRadio: boolean;
}

const CampaignSchedulerModal: React.FC<CampaignSchedulerModalProps> = ({
    showSchedulerModal,
    schedulerModalClose,
    showRadio,
}) => {
    const { campaignUuid, projectUuid } = useParams<{
        campaignUuid: string;
        projectUuid: string;
    }>();
    const navigate = useNavigate();
    const { t } = useLocale();
    const initialData = useRef<Partial<Campaign>>({});
    const { showToastError } = useNotify();
    const queryClient = useQueryClient();

    const { state } = useCampaignContext((context) => context);

    const { campaignPayload, isNewMode, status } = state;

    const { mutate: mutateStopCampaign } = useStopCampaign();
    const { mutateAsync: updateCampaign, isLoading: isUpdateLoading } =
        useUpdateCampaign(campaignUuid);
    const { mutateAsync: publishCampaign, isLoading: isPublishLoading } =
        usePublishCampaign();

    useEffect(() => {
        if (Object.keys(initialData.current).length === 0) {
            initialData.current = campaignPayload;
        }
    }, [campaignPayload]);

    const handlePublish = useCallback(async () => {
        try {
            const payload = { ...campaignPayload.schedule };
            if (payload.jobId) {
                payload.jobId = undefined;
            }
            await updateCampaign({
                schedule: payload as Schedule,
            });
            if (status === CampaignStatus.DRAFT) {
                if (!campaignUuid) {
                    showToastError({
                        title: t('common.campaign_id_required'),
                    });
                    return;
                }
                await publishCampaign({
                    campaignId: campaignUuid,
                });
            }
            schedulerModalClose();
            void navigate(`/projects/${projectUuid}/campaigns`);
        } catch (error) {
            showToastError({
                title:
                    error.error.message ??
                    t('journeys_hook.something_went_wrong'),
            });
        }
    }, [
        updateCampaign,
        campaignPayload.schedule,
        status,
        schedulerModalClose,
        navigate,
        projectUuid,
        publishCampaign,
        campaignUuid,
        showToastError,
        t,
    ]);

    const handleStopCampaign = useCallback(async () => {
        if (!campaignUuid) {
            showToastError({
                title: t('common.campaign_id_required'),
            });
            return;
        }
        await mutateStopCampaign({ campaignId: campaignUuid });
        schedulerModalClose();
        if (!showRadio) {
            void navigate(`/projects/${projectUuid}/campaigns`);
            void queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
        }
    }, [
        mutateStopCampaign,
        campaignUuid,
        schedulerModalClose,
        showRadio,
        navigate,
        projectUuid,
        queryClient,
        showToastError,
        t,
    ]);

    const leftSection = useMemo(() => {
        if (
            !isNewMode &&
            initialData.current?.schedule?.type === CampaignScheduleType.DELAYED
        ) {
            const buttonText =
                campaignPayload.schedule?.executionTime &&
                new Date() < new Date(campaignPayload.schedule.executionTime)
                    ? t('campaign_scheduler_modal.remove_schedule')
                    : t('campaign_scheduler_modal.stop_campaign');

            return (
                <Button
                    onClick={() => handleStopCampaign()}
                    variant={ButtonVariant.OUTLINED}
                    className="text-sm font-semibold text-halt-800"
                    leftIcon={<StopCircle size={14} weight="duotone" />}
                >
                    {buttonText}
                </Button>
            );
        }

        return null;
    }, [
        campaignPayload.schedule?.executionTime,
        handleStopCampaign,
        isNewMode,
        t,
    ]);

    return (
        <Modal
            opened={showSchedulerModal}
            onClose={() => schedulerModalClose()}
            keepMounted={false}
            title={
                isNewMode
                    ? t('campaign_scheduler_modal.publish_campaign')
                    : t('campaign_scheduler_modal.modify_schedule')
            }
            size="lg"
            footerRightSection={
                <ModalFooter
                    showSecondaryButton={true}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    secondaryButtonClick={() => schedulerModalClose()}
                    secondaryText={t(
                        'custom_metric.dimension_modal_cancel_button',
                    )}
                    showPrimaryButton={true}
                    primaryButtonClick={() => handlePublish()}
                    primaryText={
                        isNewMode
                            ? t('campaign_scheduler_modal.publish')
                            : t('campaign_scheduler_modal.save')
                    }
                    primaryLeftIcon={undefined}
                    isLoading={isUpdateLoading || isPublishLoading}
                    primaryRightIcon={
                        <CaretRight size={14} weight="duotone" color="white" />
                    }
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={undefined}
                    primaryButtonDisabled={false}
                    primaryButtonCustomClass={undefined}
                />
            }
            footerLeftSection={leftSection}
        >
            <CampaignScheduler showRadio={showRadio} />
        </Modal>
    );
};

export default CampaignSchedulerModal;
