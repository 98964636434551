import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { type JourneyProperty } from '@components/Journeys/Builder/JourneyFilters/types';
import { type JourneyDataSchema } from '@lightdash/common';

export type FieldsDataForMentions = {
    fieldIds: string[];
    journeyProperties: PropertySelectListType<JourneyProperty>[];
    warehouseFieldsMap: FieldsWithSuggestions;
    journeyDataSchema: JourneyDataSchema;
};

export enum BorderColor {
    VALID = 'border-blu-800',
    INVALID = 'border-halt-800',
    DEFAULT = 'border-shade-4',
}

export type CallAPIFieldAndSchemaType<T> = {
    value: T;
    journeyDataSchema: JourneyDataSchema;
};
