import {
    ChatContainer,
    Message,
    MessageInput,
    MessageList,
    TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
    useAiAnalystFeedback,
    useAiAnalystPrompts,
    useAiAnalystThread,
} from '@hooks/useAiAnalyst';
import { useLocale } from '@hooks/useLocale';
import { useStreamedData } from '@hooks/useStreamedData';
import {
    AIMessageContext,
    AiMessageTypes,
    AudienceBuilderHashParams,
    Author,
    type AgentJsonContent,
    type AgentMessage,
    type Chart,
    type SuggestedPrompt,
} from '@lightdash/common';
import { Box, Button, Modal, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Plus, Sparkle, ThumbsDown, ThumbsUp } from '@phosphor-icons/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Star } from 'react-feather';
import { useNavigate, useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { ButtonVariant } from '../../mantineTheme';
import AiChart from './AiChart';
import { AiWidgetWrapper, TypingIndicatorStyled } from './AIStyle';
import { MessageValue } from './types';

const StreamingText: React.FC<{
    stream: boolean;
    html: string;
    streamedMessages: number[];
    setStreamedMessages: (value: number[]) => void;
    index: number;
}> = ({ stream, html, streamedMessages, setStreamedMessages, index }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [streamIndex, setStreamIndex] = useState(0);

    useEffect(() => {
        if (stream && !streamedMessages.includes(index)) {
            if (streamIndex < html.length) {
                const timeoutId = setTimeout(() => {
                    setDisplayedText((prev) => prev + html[streamIndex]);
                    setStreamIndex((prev) => prev + 1);
                }, 20); // Adjust the speed of the animation here
                return () => clearTimeout(timeoutId);
            } else {
                setStreamedMessages([...streamedMessages, index]);
            }
        } else {
            setDisplayedText(html);
        }
    }, [
        streamIndex,
        html,
        stream,
        setStreamedMessages,
        streamedMessages,
        index,
    ]);

    return <span dangerouslySetInnerHTML={{ __html: displayedText }} />;
};

const createUnorderedList = (text: string) => {
    if (!/^-\s(.*)$/gm.test(text)) {
        return text; // Return the original text if no matches are found
    }
    let html = text.replace(/^- (.*)$/gm, '<li>$1<ul>');
    html = html.replace(/^\s+- (.*)$/gm, '<li>$1</li>');
    html = html.replace(/<\/li>\n(?=<li>)/g, '</li></ul>');
    html = html.trim() + '</ul></li>';
    return html;
};

const replaceAsterisksWithBold = (text: string) => {
    return text.replace(/\*\*(.*?)\*\*/gm, '<b>$1</b>');
};

function AIWidget({
    onMessageChange,
    context,
}: {
    onMessageChange: (jsonContent: AgentJsonContent) => void;
    context: AIMessageContext;
}) {
    const navigate = useNavigate();
    const { projectUuid } = useParams<{ projectUuid?: string }>();
    const [opened, { open, close }] = useDisclosure(false);
    const [messages, setMessages] = useState<AgentMessage[]>([]);
    const [feedbackGiven, setFeedbackGiven] = useState<{
        [messageId: string]: boolean;
    }>({});
    const { t } = useLocale();
    const { fetchData, isLoading, latestMessage } =
        useStreamedData<AgentMessage>({
            url: `/projects/${projectUuid}/ai/agents`,
            method: 'POST',
        });
    const { mutateAsync: mutateAsyncCreateAiAnalystThread } =
        useAiAnalystThread();
    const { mutateAsync: mutateAsyncAnalystFeedback } = useAiAnalystFeedback();
    const [msgInputValue, setMsgInputValue] = useState<string>('');
    const inputElementRef = useRef<HTMLInputElement | null>(null);
    const processedMessageRef = useRef<string | null>(null);
    const [streamedMessages, setStreamedMessages] = useState<number[]>([]);
    const { data: prompts, isLoading: isLoadingPrompts } = useAiAnalystPrompts({
        context: context,
    });
    const [threadId, setThreadId] = useState<string>('');

    useEffect(() => {
        if (!threadId) {
            void mutateAsyncCreateAiAnalystThread().then((res) => {
                setThreadId(res.threadId);
            });
        }
    }, [mutateAsyncCreateAiAnalystThread, threadId]);

    useEffect(() => {
        if (
            latestMessage &&
            latestMessage[latestMessage.length - 1]?.messageId !==
                processedMessageRef.current
        ) {
            setMessages((prevMessages) => [
                ...prevMessages,
                ...latestMessage.filter(
                    (item) =>
                        item.content.type === AiMessageTypes.TEXT ||
                        (item.context === AIMessageContext.CHART &&
                            (item.content.value as Chart).context ===
                                AIMessageContext.CHART),
                ),
            ]);
            processedMessageRef.current =
                latestMessage[latestMessage.length - 1]?.messageId;

            const jsonMessage = latestMessage.filter(
                (item) => item.content.type === AiMessageTypes.JSON,
            );

            if (jsonMessage.length <= 0) {
                return;
            }
            const jsonContent = jsonMessage[0].content as AgentJsonContent;
            onMessageChange(jsonContent);
            close();
        }
    }, [onMessageChange, navigate, latestMessage, projectUuid, close]);

    const sanitizeInputText = useCallback((inputText: string) => {
        const temporaryElement = document.createElement('div');
        temporaryElement.innerHTML = inputText;
        return temporaryElement.textContent || temporaryElement.innerText || '';
    }, []);

    const handleInputChange = useCallback(
        (innerText: string) => {
            const sanitizedValue = sanitizeInputText(innerText);
            setMsgInputValue(sanitizedValue);
        },
        [sanitizeInputText],
    );

    const setInputRef = (element: HTMLInputElement | null) => {
        inputElementRef.current = element;
    };

    const handleSend = useCallback(
        (inputText: string) => {
            const newMessage: AgentMessage = {
                threadId: threadId,
                author: Author.USER,
                messageId: uuidv4(),
                context:
                    window.location.hash === `#${AudienceBuilderHashParams.SQL}`
                        ? AIMessageContext.SQL_AUDIENCE
                        : AIMessageContext.VISUAL_AUDIENCE,
                content: {
                    type: AiMessageTypes.TEXT,
                    value: inputText,
                },
            };

            setMessages((prevMessages) => [...prevMessages, newMessage]);
            setMsgInputValue('');
            fetchData(newMessage);
        },
        [fetchData, threadId],
    );

    const ChatMessageInput: React.FC<{}> = useCallback(() => {
        if (isLoading) return null;
        return (
            <MessageInput
                attachButton={false}
                value={msgInputValue}
                onChange={handleInputChange}
                placeholder={t('aiwidget.input_placeholder')}
                onSend={handleSend}
                ref={setInputRef}
            />
        );
    }, [isLoading, msgInputValue, handleInputChange, t, handleSend]);

    const handlePromptClick = useCallback(
        (prompt: SuggestedPrompt) => {
            setMsgInputValue(prompt.prompt);
            inputElementRef.current?.focus();
        },
        [inputElementRef],
    );

    const getPromptClassName = useCallback(
        (index: number) => {
            let classes = '';
            if (index === 0) {
                classes += 'rounded-t-lg ';
            }
            if (prompts && index === prompts.length - 1) {
                classes += 'rounded-b-lg border-b-1';
                return classes.trim();
            }
            classes += 'border-b-0';
            return classes.trim();
        },
        [prompts],
    );

    const renderPrompts = useCallback(() => {
        if (!messages.length && !isLoadingPrompts) {
            return (
                <Box>
                    {prompts?.map((prompt, index) => (
                        <Box
                            className={`p-3 flex items-center cursor-pointer border border-gray-250 hover:bg-gray-100 ${getPromptClassName(
                                index,
                            )}`}
                            key={prompt.prompt}
                            onClick={() => handlePromptClick(prompt)}
                        >
                            <Box className="p-2 border border-gray-250 rounded-md">
                                <Sparkle size={18} weight="duotone" />
                            </Box>
                            <Box className="ml-3">
                                <Text className="text-sm font-semibold text-gray-800">
                                    {prompt.prompt}
                                </Text>
                                <Text className="text-xs font-normal text-gray-600">
                                    {prompt.example}
                                </Text>
                            </Box>
                        </Box>
                    ))}
                </Box>
            );
        }
        return null;
    }, [
        messages.length,
        isLoadingPrompts,
        prompts,
        getPromptClassName,
        handlePromptClick,
    ]);

    const handleFeedbackClick = useCallback(
        async (messageId: string, sentiment: boolean) => {
            await mutateAsyncAnalystFeedback({
                threadId,
                messageId,
                sentiment,
                metadata: {},
            });
            setFeedbackGiven({
                ...feedbackGiven,
                [messageId]: sentiment,
            });
        },
        [feedbackGiven, mutateAsyncAnalystFeedback, threadId],
    );

    const renderFeedback = useCallback(
        (item: AgentMessage) => {
            if (item.author !== Author.SYSTEM) return null;
            return (
                <Box className="mt-2 flex items-center gap-2">
                    {!(item.messageId in feedbackGiven) && (
                        <Box className="flex items-center gap-2">
                            <ThumbsUp
                                size={14}
                                weight="duotone"
                                className="cursor-pointer text-gray-700 hover:text-gray-800"
                                onClick={() =>
                                    handleFeedbackClick(item.messageId, true)
                                }
                            />
                            <ThumbsDown
                                size={14}
                                weight="duotone"
                                className="cursor-pointer text-gray-700 hover:text-gray-800"
                                onClick={() =>
                                    handleFeedbackClick(item.messageId, false)
                                }
                            />
                        </Box>
                    )}
                    {item.messageId in feedbackGiven &&
                        (feedbackGiven[item.messageId] ? (
                            <ThumbsUp
                                size={14}
                                weight="duotone"
                                className="text-gray-500"
                            />
                        ) : (
                            <ThumbsDown
                                size={14}
                                weight="duotone"
                                className="text-gray-500"
                            />
                        ))}
                </Box>
            );
        },
        [feedbackGiven, handleFeedbackClick],
    );

    const getFormattedHtml = (html: string) => {
        let formattedText = replaceAsterisksWithBold(html);
        formattedText = createUnorderedList(formattedText);
        return formattedText;
    };

    return (
        <>
            <Modal
                styles={(_params) => ({
                    header: {
                        borderBottom:
                            '3px solid rgb(var(--color-black)/0.02) !important',
                    },
                    title: {
                        display: 'flex !important',
                        alignItems: 'center !important',
                        gap: '6px !important',
                    },
                    content: {
                        maxHeight: 'unset !important',
                    },
                    inner: {
                        paddingBottom: '2rem !important',
                        top: 'unset !important',
                    },
                    body: {
                        padding: '0px !important',
                    },
                })}
                size={'lg'}
                opened={opened}
                onClose={close}
                closeOnClickOutside={false}
                title={
                    <>
                        <Box className="flex items-center justify-between w-[35rem]">
                            <Box className="flex items-center gap-2">
                                <Sparkle color="rgb(var(--color-purple))" />{' '}
                                {t('aiwidget.ai_assist')}
                            </Box>
                            <Box
                                className="p-1 hover:bg-gray-50 cursor-pointer"
                                onClick={async () => {
                                    await mutateAsyncCreateAiAnalystThread().then(
                                        (res) => {
                                            setThreadId(res.threadId);
                                        },
                                    );
                                    setMessages([]);
                                    setMsgInputValue('');
                                }}
                            >
                                <Plus
                                    className="cursor-pointer"
                                    size={14}
                                    weight="bold"
                                />
                            </Box>
                        </Box>
                    </>
                }
                transitionProps={{
                    transition: 'slide-up',
                    duration: 200,
                    timingFunction: 'linear',
                }}
            >
                <AiWidgetWrapper
                    style={{
                        height: '500px',
                    }}
                >
                    <ChatContainer>
                        <MessageList
                            typingIndicator={
                                isLoading && (
                                    <TypingIndicator
                                        className="!p-3.5"
                                        content={t('aiwidget.ai_is_thinking')}
                                    />
                                )
                            }
                        >
                            {!messages.length && (
                                <Box className="text-2xl font-normal text-gray-800 mb-6">
                                    <Text className="text-gray-800 font-semibold mb-1">
                                        {t('aiwidget.hello_there')}
                                    </Text>
                                    <Text className="text-gray-600 font-medium text-sm">
                                        {t(
                                            'aiwidget.what_do_you_want_to_do_today',
                                        )}
                                    </Text>
                                </Box>
                            )}

                            {renderPrompts()}

                            {!!messages.length &&
                                messages.map((item, index) => {
                                    if (
                                        item.context ===
                                            AIMessageContext.CHART &&
                                        (item.content.value as Chart)
                                            .context === AIMessageContext.CHART
                                    ) {
                                        return (
                                            <Message
                                                key={item.messageId}
                                                model={{
                                                    sender: MessageValue.SYSTEM,
                                                    direction:
                                                        MessageValue.INCOMING_DIRECTION,
                                                    position:
                                                        MessageValue.POSITION,
                                                }}
                                                className={'ai-chat-message'}
                                            >
                                                <Message.CustomContent className="">
                                                    <AiChart
                                                        metricQuery={
                                                            (
                                                                item.content
                                                                    .value as Chart
                                                            ).metricQuery
                                                        }
                                                        chartConfig={
                                                            (
                                                                item.content
                                                                    .value as Chart
                                                            ).chartConfig
                                                        }
                                                    />
                                                    {!isLoading &&
                                                        index ===
                                                            messages.length -
                                                                1 &&
                                                        renderFeedback(item)}
                                                </Message.CustomContent>
                                            </Message>
                                        );
                                    }

                                    return (
                                        <Message
                                            key={item.messageId}
                                            model={{
                                                sender:
                                                    item.author === Author.USER
                                                        ? MessageValue.USER
                                                        : MessageValue.SYSTEM,
                                                direction:
                                                    item.author === Author.USER
                                                        ? MessageValue.OUTGOING_DIRECTION
                                                        : MessageValue.INCOMING_DIRECTION,
                                                position: MessageValue.POSITION,
                                            }}
                                            className={`${
                                                item.author === Author.SYSTEM
                                                    ? 'ai-chat-message'
                                                    : 'chat-message'
                                            }`}
                                        >
                                            <Message.CustomContent>
                                                <StreamingText
                                                    stream={
                                                        item.author ===
                                                        Author.SYSTEM
                                                    }
                                                    streamedMessages={
                                                        streamedMessages
                                                    }
                                                    setStreamedMessages={
                                                        setStreamedMessages
                                                    }
                                                    index={index}
                                                    html={getFormattedHtml(
                                                        item.content
                                                            .value as any,
                                                    )}
                                                />
                                                {!isLoading &&
                                                    index ===
                                                        messages.length - 1 &&
                                                    renderFeedback(item)}
                                            </Message.CustomContent>
                                        </Message>
                                    );
                                })}
                        </MessageList>
                        {ChatMessageInput({})}
                    </ChatContainer>
                </AiWidgetWrapper>
            </Modal>
            <div>
                <Button
                    onClick={open}
                    className="rounded-lg !bg-white border-shade-6 text-gray-500 p-2.5 w-64 flex justify-start h-9 text-sm font-normal hover:border-blu-800"
                    variant={ButtonVariant.OUTLINED}
                >
                    {isLoading ? (
                        <TypingIndicatorStyled>
                            <TypingIndicator
                                content={t('aiwidget.ai_is_thinking')}
                            />
                        </TypingIndicatorStyled>
                    ) : (
                        <>
                            <Star className="!w-3.5 !h-3.5 text-blu-800 pr-1.5 " />
                            <div className="text-sm font-normal text-gray-500">
                                {t('aiwidget.ai_assist')}
                            </div>
                        </>
                    )}
                </Button>
            </div>
        </>
    );
}

export default AIWidget;
