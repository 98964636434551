import { useContext } from 'react';
import RelationProviderContext from './context';
import { type RelationContext } from './types';

function useRelationContext(): RelationContext {
    const context = useContext(RelationProviderContext);
    if (context === undefined) {
        throw new Error(
            'useRelationContext must be used within a RelationProvider',
        );
    }
    return context;
}

export default useRelationContext;
