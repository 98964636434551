import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
// import BeeFreeEditor from '@components/Templates/BeeFreeEditor';
import BeeFreeEditor from '@components/Templates/BeeFreeEditor';
import HTMLEditor from '@components/Templates/HTMLManager/HTMLEditor';
import SMSEditor from '@components/Templates/SMSManager/SMSEditor';
import TemplateOptionsModal from '@components/Templates/TemplateOptionsModal';
import { Action as ActionEnum, type Action } from '@components/Templates/types';
import {
    useCreateTemplate,
    useGetTemplateById,
    useUpdateTemplateContent,
    useUpdateTemplateDetails,
} from '@hooks/useTemplate';
import {
    CommunicationChannel as CommunicationChannelEnum,
    type CreateTemplateRequest,
    type EmailTemplateContentDetails,
    type Integration,
    type ProviderTemplateDetails,
    type SmsTemplateContentDetails,
} from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import { EmailBuilderType } from '@providers/TemplateBuilder/types';
import useTemplateBuilderContext from '@providers/TemplateBuilder/useTemplateBuilderContext';
import { useQueryClient } from '@tanstack/react-query';
import juice from 'juice';
import { isEqual } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { QueryKeys } from 'types/UseQuery';
import ConfirmTemplateSaveModal from '../modals/ConfirmTemplateSaveModal';
import NewTemplateNameModal from '../modals/NewTemplateNameModal';
import { useTemplateBuilderInitialData } from '../useTemplateBuilderInitialData';

interface TemplateBuilderProps {
    integration: Integration;
    integrationId: string;
    onClose: (() => void) | undefined;
    // handleTemplateSelect: (obj: ProviderTemplateDetails) => void;
}

const TemplateBuilder: React.FC<TemplateBuilderProps> = ({
    integration,
    integrationId,
    onClose,
    // handleTemplateSelect,
}) => {
    const queryClient = useQueryClient();
    // TODO - Handle versioning when we support multiple template versions
    const {
        templatePayload,
        templateId,
        initialPayload,
        version,
        emailBuilderType,
    } = useTemplateBuilderContext((context) => context.state);

    const {
        setTemplatePayload,
        setTemplateId,
        setOpenCreateTemplateModal,
        handleTemplateSelect,
        setEmailBuilderType,
    } = useTemplateBuilderContext((context) => context.actions);

    const {
        mutateAsync: mutateAsyncCreateTemplate,
        isLoading: isCreatingTemplate,
        isSuccess: templateCreateSuccess,
    } = useCreateTemplate();
    const query = useQueryClient();

    const {
        mutateAsync: mutateAsyncUpdateTemplate,
        isLoading: isUpdatingTemplate,
        isSuccess: templateUpdateSuccess,
    } = useUpdateTemplateContent(templateId, version);

    const { mutateAsync: updateTemplateDetails } = useUpdateTemplateDetails();

    const [selectedTemplateId, setSelectedTemplateId] = useState<
        string | undefined
    >(undefined);

    const { channel } = useTemplateBuilderContext((context) => context.state);

    const {
        data: templateMetadata,
        isFetching,
        isSuccess,
    } = useGetTemplateById(
        selectedTemplateId ?? '',
        channel !== CommunicationChannelEnum.WHATSAPP,
    );

    useEffect(() => {
        if (!isSuccess || !templateMetadata) return;

        const payload: CreateTemplateRequest = {
            name: templateMetadata.name,
            description: templateMetadata.description,
            channel: templateMetadata.channel,
            consideredInLibrary: templateMetadata.consideredInLibrary,
            content: templateMetadata.contents[0].content,
            previewUrl: '',
            tags: templateMetadata.tags,
        };
        setTemplatePayload(payload);
        setTemplateId(templateMetadata.id);
    }, [isSuccess, templateMetadata, setTemplatePayload, setTemplateId]);

    const [
        confirmTemplateSaveModalOpened,
        {
            open: openConfirmTemplateSaveModal,
            close: closeConfirmTemplateSaveModal,
        },
    ] = useDisclosure(false);

    const [
        newTemplateNameModalOpened,
        { open: openNewTemplateNameModal, close: closeNewTemplateNameModal },
    ] = useDisclosure(false);

    const [opened, { open, close }] = useDisclosure(false);

    useEffect(() => {
        if (templateUpdateSuccess || templateCreateSuccess) {
            closeConfirmTemplateSaveModal();
            setOpenCreateTemplateModal(false);
            (async () => {
                if (onClose && typeof onClose === 'function') onClose();
                await query.refetchQueries([
                    QueryKeys.GET_TEMPLATE,
                    templateId,
                ]);
            })();
        }
    }, [
        templateUpdateSuccess,
        closeConfirmTemplateSaveModal,
        close,
        closeNewTemplateNameModal,
        setOpenCreateTemplateModal,
        query,
        templateId,
        templateCreateSuccess,
        onClose,
    ]);

    const onTitleChange = useCallback(
        (value: string) => {
            setTemplatePayload({
                ...templatePayload,
                name: value,
            });
        },
        [setTemplatePayload, templatePayload],
    );

    const onDescriptionChange = useCallback(
        (value: string) => {
            setTemplatePayload({
                ...templatePayload,
                description: value,
            });
        },
        [setTemplatePayload, templatePayload],
    );

    const onTagsChange = useCallback(
        (value: string[]) => {
            setTemplatePayload({
                ...templatePayload,
                tags: value,
            });
        },
        [setTemplatePayload, templatePayload],
    );

    const handleTemplateSave = useCallback(
        async (
            content:
                | EmailTemplateContentDetails
                | SmsTemplateContentDetails
                | undefined,
        ) => {
            const updatedPayload = {
                ...templatePayload,
                ...(content && { content }),
            };

            if (isEqual(updatedPayload, initialPayload)) {
                setOpenCreateTemplateModal(false);
                return;
            }

            setTemplatePayload(updatedPayload);

            if (!templateId) {
                if (!templatePayload.name) {
                    openNewTemplateNameModal();
                    return;
                }
                let payload: CreateTemplateRequest = {
                    ...templatePayload,
                    ...(content && content),
                };
                if (content && Object.keys(content).includes('html')) {
                    payload = {
                        ...payload,
                        content,
                    };
                }
                const response = await mutateAsyncCreateTemplate(payload);
                await queryClient.invalidateQueries([QueryKeys.GET_TEMPLATE]);
                const newTemplateObj: ProviderTemplateDetails = {
                    id: response.id,
                    name: response.name,
                    description: response.description,
                };
                handleTemplateSelect(newTemplateObj);
                setOpenCreateTemplateModal(false);
                return;
            }

            openConfirmTemplateSaveModal();
        },
        [
            templatePayload,
            initialPayload,
            setTemplatePayload,
            templateId,
            openConfirmTemplateSaveModal,
            setOpenCreateTemplateModal,
            mutateAsyncCreateTemplate,
            queryClient,
            handleTemplateSelect,
            openNewTemplateNameModal,
        ],
    );

    const handleTemplateUpdate = useCallback(async () => {
        if (!templateId) return;

        if (
            initialPayload.name !== templatePayload.name ||
            initialPayload.description !== templatePayload.description
        ) {
            await updateTemplateDetails({
                templateId,
                body: {
                    name: templatePayload.name,
                    description: templatePayload.description,
                    tags: templatePayload.tags,
                },
            });
        }
        await mutateAsyncUpdateTemplate(templatePayload.content);
    }, [
        templateId,
        templatePayload,
        mutateAsyncUpdateTemplate,
        initialPayload,
        updateTemplateDetails,
    ]);

    const handleLoadTemplate = useCallback(() => {
        open();
    }, [open]);

    const handleDuplicateTemplate = useCallback(() => {
        setTemplatePayload({
            ...templatePayload,
            name: `Copy of ${templatePayload.name}`,
        });
        setTemplateId('');
    }, [setTemplateId, setTemplatePayload, templatePayload]);

    const initialState = useTemplateBuilderInitialData({
        templatePayload: undefined,
        channel,
        templateId: '',
    });

    const handleNewTemplate = useCallback(() => {
        setTemplatePayload(initialState.templatePayload);
        setTemplateId('');
    }, [setTemplateId, setTemplatePayload, initialState]);

    const onHeaderAction = useMemo(
        () =>
            (action: Action): void => {
                if (action === ActionEnum.RESET) {
                    handleNewTemplate();
                    return;
                }

                if (action === ActionEnum.LOAD_TEMPLATE) {
                    handleLoadTemplate();
                    return;
                }

                if (action === ActionEnum.DUPLICATE) {
                    handleDuplicateTemplate();
                    return;
                }
            },
        [handleDuplicateTemplate, handleLoadTemplate, handleNewTemplate],
    );

    const renderTemplateBuilder = useMemo(() => {
        switch (channel) {
            case CommunicationChannelEnum.EMAIL: {
                if (
                    (templatePayload.content as EmailTemplateContentDetails)
                        .json ||
                    emailBuilderType === EmailBuilderType.BEEFREE
                ) {
                    if (emailBuilderType !== EmailBuilderType.BEEFREE) {
                        setEmailBuilderType(EmailBuilderType.BEEFREE);
                    }
                    return (
                        <BeeFreeEditor
                            templateDetail={{
                                id: templateId,
                                name: templatePayload.name,
                                html: (
                                    templatePayload.content as EmailTemplateContentDetails
                                )?.html,
                                unsubscribeBlock: false,
                                json: (
                                    templatePayload.content as EmailTemplateContentDetails
                                )?.json,
                            }}
                        />
                    );
                }
                return (
                    <HTMLEditor
                        templateDetail={{
                            id: templateId,
                            name: templatePayload.name,
                            html: (
                                templatePayload.content as EmailTemplateContentDetails
                            )?.html,
                            unsubscribeBlock: false,
                            description: templatePayload.description,
                            tags: templatePayload.tags,
                        }}
                        onSave={async (value) => {
                            await handleTemplateSave({
                                html: juice(
                                    value.html
                                        .replaceAll('gjs-selected', '')
                                        .replaceAll('min-height: 100vh;', ''),
                                ),
                                subject: '',
                            });
                        }}
                        onClose={() => {
                            if (onClose) onClose();
                            setOpenCreateTemplateModal(false);
                        }}
                        onTitleChange={onTitleChange}
                        onDescriptionChange={onDescriptionChange}
                        onTagsChange={onTagsChange}
                        onHeaderAction={onHeaderAction}
                    />
                );
            }
            case CommunicationChannelEnum.SMS:
                return (
                    <SMSEditor
                        onTitleChange={onTitleChange}
                        onHeaderAction={onHeaderAction}
                        handleTemplateSave={() => handleTemplateSave(undefined)}
                    />
                );
            default:
                return null;
        }
    }, [
        channel,
        onTitleChange,
        onHeaderAction,
        templatePayload.content,
        templatePayload.name,
        emailBuilderType,
        templateId,
        setEmailBuilderType,
        handleTemplateSave,
        onClose,
        setOpenCreateTemplateModal,
        templatePayload.description,
        templatePayload.tags,
        onDescriptionChange,
        onTagsChange,
    ]);

    const handleLoadTemplateSelect = useCallback(
        (obj: ProviderTemplateDetails) => {
            if (!obj) return;
            setSelectedTemplateId(obj.id);
        },
        [],
    );

    const handleSaveAsNewTemplate = useCallback(() => {
        setTemplatePayload({
            ...templatePayload,
            name: `Copy of ${templatePayload.name}`,
            tags: [],
        });
        setTemplateId('');
        closeConfirmTemplateSaveModal();
        openNewTemplateNameModal();
    }, [
        closeConfirmTemplateSaveModal,
        setTemplatePayload,
        templatePayload,
        openNewTemplateNameModal,
        setTemplateId,
    ]);

    return (
        <>
            {isFetching && <SuboptimalState loading />}

            {renderTemplateBuilder}

            <ConfirmTemplateSaveModal
                opened={confirmTemplateSaveModalOpened}
                onClose={closeConfirmTemplateSaveModal}
                handleSave={handleTemplateUpdate}
                handleSaveAsNewTemplate={handleSaveAsNewTemplate}
                isLoading={isCreatingTemplate || isUpdatingTemplate}
            />
            <NewTemplateNameModal
                opened={newTemplateNameModalOpened}
                onClose={closeNewTemplateNameModal}
                onSave={() => handleTemplateSave(undefined)}
                isLoading={isCreatingTemplate || isUpdatingTemplate}
            />

            <TemplateOptionsModal
                opened={opened}
                onClose={close}
                providerMetadata={integration}
                integrationId={integrationId}
                handleTemplateSelect={handleLoadTemplateSelect}
                channel={channel}
                showCreateEditTemplate={false}
                templateId={undefined}
                isTemplateLibrary={false}
                editMode={false}
            />
        </>
    );
};

export default React.memo(TemplateBuilder);
