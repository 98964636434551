import {
    FieldType,
    type CompiledRelationTable,
    type CustomAttribute,
    type InsertCustomAttribute,
} from '@lightdash/common';
import { type CustomMetricReducerState } from '@providers/CustomMetric/types';
import { useMemo } from 'react';

export interface CreateCustomMetricProps {
    openBaseTable: boolean | undefined;
    tableName: CompiledRelationTable | undefined;
    isDuplicate: boolean | undefined;
    isViewMode: boolean | undefined;
    customMetricData: CustomAttribute | undefined;
    onModalClose: () => void;
    customMetricId: string | undefined;
    disableTableChange: boolean | undefined;
}

export const useCustomMetricInitialData = (
    customMetricData: CustomAttribute | undefined,
    relationUuid: string,
): CustomMetricReducerState => {
    return useMemo(() => {
        return {
            customAttributePayload: {
                name: customMetricData?.name || '',
                description: customMetricData?.description || '',
                relationUuid: relationUuid,
                srcTable: customMetricData?.srcTable || '',
                definition: customMetricData?.definition || {
                    sql: '',
                    name: '',
                    baseDimensionName: '',
                    type: '',
                    label: '',
                    table: '',
                    filters: [],
                    fieldType: '',
                    tableLabel: '',
                    description: '',
                    isAutoGenarated: false,
                    tablesReferences: [],
                },
                type: FieldType.METRIC,
                isActive: true,
                groupByColumn: null,
            } as InsertCustomAttribute,
            initialCustomAttributePayload: {
                name: customMetricData?.name || '',
                description: customMetricData?.description || '',
                relationUuid: relationUuid,
                srcTable: customMetricData?.srcTable || '',
                definition: customMetricData?.definition || {
                    sql: '',
                    name: '',
                    baseDimensionName: '',
                    type: '',
                    label: '',
                    table: '',
                    filters: [],
                    fieldType: '',
                    tableLabel: '',
                    description: '',
                    isAutoGenarated: false,
                    tablesReferences: [],
                },
                type: FieldType.METRIC,
                isActive: true,
                groupByColumn: null,
            } as InsertCustomAttribute,
            showBaseTableModal: false,
            showMetricManagerModal: false,
            selectedTable: undefined,
            selectedDimension: undefined,
            filters: undefined,
            duplicatedMetric: false,
            viewMode: false,
            disableBackButton: false,
        };
    }, [customMetricData, relationUuid]);
};
