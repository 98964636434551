import ListSelect from '@components/common/Select/ListSelect';
import { useLocale } from '@hooks/useLocale';
import { useRefreshTables, useWarehouseTables } from '@hooks/useSchemaBuilder';
import { type WarehouseTableInfoBase } from '@lightdash/common';
import { Box, Button, Flex, Group, Loader, Text } from '@mantine/core';
import { ArrowsClockwise, MagnifyingGlass, Table } from '@phosphor-icons/react';
import useRelationContext from '@providers/Relation/useRelationContext';
import useSchemaContext from '@providers/Schema/useSchemaContext';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { type ListSelectOptionsProp } from 'types/ListSelect';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../../../mantineTheme';
import { type SchemaBuilderStep } from '../../types';
import { SchemaBuilderSteps } from '../../utils';
import DataSource from './DataSource';

interface ItemComponentProps extends React.ComponentPropsWithoutRef<'div'> {
    item: WarehouseTableInfoBase;
    label: string;
    disabled?: boolean;
}

export const ItemComponent = ({ label, disabled }: ItemComponentProps) => {
    return (
        <Group className="items-center gap-1.5">
            <Table
                color={
                    disabled
                        ? 'rgb(var(--color-gray-400)'
                        : 'rgb(var(--color-pink-800)'
                }
            />
            <Text
                className={`text-sm font-medium ${
                    disabled ? 'text-gray-400' : 'text-gray-800'
                }`}
            >
                {label}
            </Text>
        </Group>
    );
};

const PrimaryTableSetup: React.FC<{}> = ({}) => {
    const queryClient = useQueryClient();
    const { t } = useLocale();
    const { activeProject, currentBuilderStep, isDrawerOpen } =
        useSchemaContext((context) => context.state);
    const navigate = useNavigate();
    const location = useLocation();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { activeRelation } = useRelationContext();
    const { setDatabase, setSchema, setTableName, setCurrentBuilderStep } =
        useSchemaContext((context) => context.actions);

    const { data: tables, isLoading, isFetching } = useWarehouseTables();
    const { mutateAsync: refreshTables, isLoading: isRefreshLoading } =
        useRefreshTables();

    const handleRefresh = () => {
        refreshTables()
            .then(() => {
                void queryClient.invalidateQueries([
                    QueryKeys.WAREHOUSE_TABLES,
                ]);
            })
            .catch(() => {});
    };

    const getNextStep = useCallback(() => {
        const currentStepIndex = SchemaBuilderSteps.findIndex(
            (step) => step.key === currentBuilderStep,
        );

        if (currentStepIndex === SchemaBuilderSteps.length - 1) return;

        return SchemaBuilderSteps[currentStepIndex + 1]?.key;
    }, [currentBuilderStep]);

    const handleNext = useCallback(() => {
        const nextStep = getNextStep();
        if (!nextStep) return;

        setCurrentBuilderStep(nextStep as SchemaBuilderStep);
    }, [getNextStep, setCurrentBuilderStep]);

    const createdListItemObject = useCallback(
        (obj: WarehouseTableInfoBase): ListSelectOptionsProp => {
            return {
                uuid: `${obj?.database}|${obj?.schema}|${obj?.table}`,
                key: obj?.schema,
                value: obj?.table,
                disabled: !!activeRelation?.tables[obj?.table],
                renderComponent: (
                    <Box className="px-1">
                        <ItemComponent
                            label={obj?.table}
                            item={obj}
                            disabled={!!activeRelation?.tables[obj?.table]}
                        />
                    </Box>
                ),
            };
        },
        [activeRelation],
    );

    const options: ListSelectOptionsProp[] = useMemo(() => {
        return (
            tables?.map((obj: WarehouseTableInfoBase) => {
                return createdListItemObject(obj);
            }) ?? []
        );
    }, [tables, createdListItemObject]);

    const handleFieldSelect = (selectedOption: ListSelectOptionsProp) => {
        const { uuid, key, value } = selectedOption;
        const database =
            uuid
                ?.toString()
                ?.substring(0, uuid?.toString()?.indexOf(`|${key}|${value}`)) ??
            '';
        setDatabase(database);
        setSchema(key);
        setTableName(value);
        handleNext();
    };
    const isCreateFlow = useMemo(() => {
        const createPathPattern = new RegExp(
            `/projects/${projectUuid}/relations/create`,
        );
        return createPathPattern.test(location.pathname);
    }, [projectUuid, location.pathname]);
    const handleSkip = useCallback(() => {
        void navigate(`/projects/${projectUuid}/relations`);
    }, [navigate, projectUuid]);

    return (
        <Box className="w-8/12 ">
            {!isDrawerOpen && (
                <>
                    <Text className="mt-4 mb-1 text-sm font-medium text-gray-800">
                        {t('schema_builder.table_chooser.connected_to')}
                    </Text>

                    <DataSource dataSource={activeProject} />
                </>
            )}

            <Text className="mt-4 mb-1 text-sm font-medium text-gray-800">
                {t(
                    isDrawerOpen
                        ? 'schema_builder.add_table.title'
                        : 'schema_builder.table_chooser.where_are_users',
                )}
            </Text>

            <Box className="h-96">
                <ListSelect
                    leftSection={<MagnifyingGlass />}
                    placeholder={t(
                        'schema_builder.table_chooser.search.placeholder',
                    )}
                    options={options}
                    onOptionSelect={handleFieldSelect}
                    withDivider
                    loading={isLoading}
                    rightSection={
                        isFetching || isRefreshLoading ? (
                            <>
                                <Loader
                                    size={13}
                                    color={'rgb(var(--color-gray-500))'}
                                />
                            </>
                        ) : (
                            <>
                                <ArrowsClockwise
                                    weight="regular"
                                    onClick={handleRefresh}
                                    className={`${
                                        isFetching
                                            ? 'pointer-events-none'
                                            : 'cursor-pointer'
                                    } `}
                                />
                            </>
                        )
                    }
                />
            </Box>
            {isCreateFlow && (
                <Box className="fixed bottom-0 left-0 w-full bg-white border-t-2">
                    <Flex className="px-4 my-4" justify={'flex-end'}>
                        <Button
                            variant={ButtonVariant.DEFAULT}
                            onClick={handleSkip}
                        >
                            {t('common.skip_for_now')}
                        </Button>
                    </Flex>
                </Box>
            )}
        </Box>
    );
};

export default PrimaryTableSetup;
