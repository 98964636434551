import PageSpinner from '@components/PageSpinner';
import RouterSidebarLink from '@components/Router/RouterSidebarLink';
import { useOrganization } from '@hooks/organization/useOrganization';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Divider, Flex, Stack, Title } from '@mantine/core';
import { SearchParams } from '@utils/constants';
import React, { useCallback } from 'react';
import { ChevronLeft } from 'react-feather';
import { useNavigate, useLocation } from 'react-router';
import { ButtonVariant } from '../../../mantineTheme';
import {
    organizationSettingsMenuItems,
    type SettingListItemType,
} from './organizationSettingsMenuItems';

const SettingsGroup = ({
    settingsGroup,
    groupIndex,
}: {
    settingsGroup: SettingListItemType[];
    groupIndex: number;
}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get(SearchParams.REDIRECT);

    return (
        <>
            <Stack key={groupIndex} className="gap-1.5 py-3.5 ">
                {settingsGroup.map((item, itemIndex) => (
                    <RouterSidebarLink
                        key={`item-${item.label || itemIndex}`}
                        label={item.label}
                        to={item.redirectTo}
                        icon={item.leftSection}
                        exact
                    />
                ))}
            </Stack>
            {groupIndex <
                organizationSettingsMenuItems(redirect).length - 1 && (
                <Divider className="ml-2.5 mr-5 border-t-shade-4" />
            )}
        </>
    );
};

interface OrganizationSettingsSidebarProps {}

const OrganizationSettingsSidebar: React.FC<
    OrganizationSettingsSidebarProps
> = ({}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get(SearchParams.REDIRECT);

    const { isLoading: isOrgLoading, data: organization } = useOrganization();

    const { t } = useLocale();
    const navigate = useNavigate();

    const onBackButtonClick = useCallback(() => {
        if (redirect) {
            void navigate(decodeURIComponent(redirect));
        } else {
            void navigate('/');
        }
    }, [navigate, redirect]);

    const renderedSettingsGroups = organizationSettingsMenuItems(redirect).map(
        (group, index) => (
            <React.Fragment key={`organization-settings-group-${index}`}>
                <SettingsGroup settingsGroup={group} groupIndex={index} />
            </React.Fragment>
        ),
    );

    if (isOrgLoading) return <PageSpinner />;

    return (
        <Stack sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Stack sx={{ flexGrow: 1, overflow: 'auto' }} spacing={'none'}>
                <Flex className="items-start pr-2.5 gap-1.5 pb-3.5">
                    <Button
                        variant={ButtonVariant.UNSTYLED}
                        className="w-5 h-5 p-1 hover:bg-shade-2 hover:rounded"
                        onClick={onBackButtonClick}
                    >
                        <ChevronLeft
                            size={13}
                            strokeWidth={2.5}
                            color={'rgb(var(--color-gray-600))'}
                        />
                    </Button>
                    <Stack className="gap-0.5">
                        <Title className="text-sm font-semibold text-gray-800">
                            {t('organization_settings.sidebar_title')}
                        </Title>
                        <Title className="text-sm font-normal text-gray-600">
                            {organization?.name}
                        </Title>
                    </Stack>
                </Flex>

                <Divider className="ml-2.5 mr-5 border-t-shade-4" />
                <Box>{renderedSettingsGroups}</Box>
            </Stack>
        </Stack>
    );
};

export default React.memo(OrganizationSettingsSidebar);
