import {
    PeriodType,
    type ConversionTrigger,
    type ExperimentConfig,
    type ExperimentVariant,
} from '@lightdash/common';
import { Divider, Stack } from '@mantine/core';
import React, { useCallback } from 'react';
import ConcludeExperiment from './ConcludeExperiment';
import ConversionTracking from './ConversionTracking';
import VariantDistribution from './VariantDistribution';

export interface ExperimentActionProps {
    nodeId: string;
    experimentConfig: ExperimentConfig;
    onExperimentConfigChange: (config: ExperimentConfig) => void;
}

const ExperimentAction: React.FC<ExperimentActionProps> = ({
    nodeId,
    experimentConfig,
    onExperimentConfigChange,
}) => {
    const handleVariantConfigChange = useCallback(
        (config: ExperimentVariant[]) => {
            onExperimentConfigChange({
                ...experimentConfig,
                variantConfig: config,
            });
        },
        [onExperimentConfigChange, experimentConfig],
    );

    const handleConversionTrackingChange = useCallback(
        (config: ConversionTrigger) => {
            onExperimentConfigChange({
                ...experimentConfig,
                conversionEvent: config,
            });
        },
        [onExperimentConfigChange, experimentConfig],
    );

    const handleConclusionConfigChange = useCallback(
        (time: number, granularity: PeriodType) => {
            onExperimentConfigChange({
                ...experimentConfig,
                conclusionConfig: {
                    time,
                    granularity,
                },
            });
        },
        [onExperimentConfigChange, experimentConfig],
    );

    return (
        <Stack className="gap-4">
            <VariantDistribution
                variantsConfig={experimentConfig.variantConfig}
                onVariantsConfigChange={handleVariantConfigChange}
                nodeId={nodeId}
            />
            <Divider className="border-t-gray-200 " />
            <ConversionTracking
                nodeId={nodeId}
                conversionEvent={experimentConfig.conversionEvent}
                onConversionTrackingChange={handleConversionTrackingChange}
            />
            <Divider className="border-t-gray-200 " />
            <ConcludeExperiment
                duration={experimentConfig.conclusionConfig?.time}
                granularity={experimentConfig.conclusionConfig?.granularity}
                onDurationChange={handleConclusionConfigChange}
                onSwitchChange={(checked) => {
                    if (checked) {
                        handleConclusionConfigChange(
                            1036800000, //Defaulting this to 12 days
                            PeriodType.DAY,
                        );
                        return;
                    }
                    handleConclusionConfigChange(0, PeriodType.DAY);
                }}
                isEditable={true}
            />
        </Stack>
    );
};

export default ExperimentAction;
