import {
    assertUnreachable,
    type AllIntegrationChannels,
    type Integration,
} from '@lightdash/common';
import React, { useMemo, useReducer } from 'react';
import ChannelProviderContext from './context';
import {
    ActionType,
    CHANNEL_SETUP_STATES,
    type ChannelContextProps,
    type ChannelProviderData,
} from './types';

type Action =
    | { type: ActionType.SET_SHOW_PROVIDER_CONFIG_MODAL; payload: boolean }
    | { type: ActionType.SET_SHOW_SETUP_MODAL; payload: boolean }
    | { type: ActionType.SET_ALL_CHANNELS; payload: AllIntegrationChannels }
    | { type: ActionType.SET_INTEGRATED_PROVIDER; payload: Integration[] }
    | {
          type: ActionType.SET_SELECTED_PROVIDER;
          payload: Integration | undefined;
      }
    | { type: ActionType.SET_SEARCH_KEY; payload: string }
    | { type: ActionType.SET_SHOW_SETUP; payload: boolean }
    | { type: ActionType.UPDATE_CHANNEL_SETUP_STATE; payload: string };

function reducer(
    state: ChannelProviderData,
    action: Action,
): ChannelProviderData {
    switch (action.type) {
        case ActionType.SET_SHOW_PROVIDER_CONFIG_MODAL: {
            return {
                ...state,
                showProviderConfigModal: action.payload,
            };
        }

        case ActionType.SET_SHOW_SETUP_MODAL: {
            return {
                ...state,
                showSetupModal: action.payload,
            };
        }
        case ActionType.SET_ALL_CHANNELS: {
            return {
                ...state,
                allChannels: action.payload,
            };
        }
        case ActionType.SET_INTEGRATED_PROVIDER: {
            return {
                ...state,
                providers: action.payload,
            };
        }
        case ActionType.SET_SELECTED_PROVIDER: {
            return {
                ...state,
                selectedProvider: action.payload,
            };
        }
        case ActionType.SET_SEARCH_KEY: {
            return {
                ...state,
                searchText: action.payload,
            };
        }
        case ActionType.SET_SHOW_SETUP: {
            return {
                ...state,
                allowChannelSetup: action.payload,
            };
        }
        case ActionType.UPDATE_CHANNEL_SETUP_STATE: {
            return {
                ...state,
                channelSetupState: action.payload,
            };
        }

        default: {
            return assertUnreachable(
                action,
                'Unexpected action in explore reducer',
            );
        }
    }
}

const defaultChannelData: ChannelProviderData = {
    title: '',
    subtitle: undefined,
    showProviderConfigModal: true,
    showSetupModal: false,
    allChannels: undefined,
    providers: undefined,
    selectedProvider: undefined,
    searchText: undefined,
    allowChannelSetup: true,
    handleProviderSelectCallback: undefined,
    selectedChannel: '',
    channelSetupState: CHANNEL_SETUP_STATES.SELECT_PROVIDER,
};

interface ChannelProviderProps {
    initialState: ChannelProviderData;
    children: React.ReactNode;
}

function ChannelProvider({ children, initialState }: ChannelProviderProps) {
    const [reducerState, dispatch] = useReducer(
        reducer,
        initialState || defaultChannelData,
    );

    const handleUpdateAllChannels = React.useCallback(
        (data: AllIntegrationChannels): void =>
            dispatch({
                type: ActionType.SET_ALL_CHANNELS,
                payload: data,
            }),
        [],
    );

    const handleShowProviderConfigModal = React.useCallback(
        (show: boolean): void =>
            dispatch({
                type: ActionType.SET_SHOW_PROVIDER_CONFIG_MODAL,
                payload: show,
            }),
        [],
    );

    const handleShowSetUpModal = React.useCallback(
        (show: boolean): void =>
            dispatch({
                type: ActionType.SET_SHOW_SETUP_MODAL,
                payload: show,
            }),
        [],
    );

    const handleIntegratedProvider = React.useCallback(
        (data: Integration[]): void => {
            return dispatch({
                type: ActionType.SET_INTEGRATED_PROVIDER,
                payload: data,
            });
        },
        [],
    );

    const handleProviderSelect = React.useCallback(
        (data: Integration | undefined, callback: boolean): void => {
            dispatch({
                type: ActionType.SET_SELECTED_PROVIDER,
                payload: data,
            });
            if (callback && data && initialState.handleProviderSelectCallback) {
                initialState?.handleProviderSelectCallback(data);
            }
        },
        [initialState],
    );

    const handleSearchKeyText = React.useCallback(
        (searchKey: string): void =>
            dispatch({
                type: ActionType.SET_SEARCH_KEY,
                payload: searchKey,
            }),
        [],
    );

    const handleShowSetupFlow = React.useCallback(
        (show: boolean): void =>
            dispatch({
                type: ActionType.SET_SHOW_SETUP,
                payload: show,
            }),
        [],
    );

    const updateChannelSetupStateAction = React.useCallback(
        (val: string): void =>
            dispatch({
                type: ActionType.UPDATE_CHANNEL_SETUP_STATE,
                payload: val,
            }),
        [],
    );

    const value: ChannelContextProps = useMemo(
        () => ({
            state: reducerState,
            actions: {
                handleUpdateAllChannels,
                handleShowProviderConfigModal,
                handleShowSetUpModal,
                handleIntegratedProvider,
                handleProviderSelect,
                handleSearchKeyText,
                handleShowSetupFlow,
                updateChannelSetupStateAction,
            },
        }),
        [
            reducerState,
            handleUpdateAllChannels,
            handleShowProviderConfigModal,
            handleShowSetUpModal,
            handleIntegratedProvider,
            handleProviderSelect,
            handleSearchKeyText,
            handleShowSetupFlow,
            updateChannelSetupStateAction,
        ],
    );
    return (
        <ChannelProviderContext.Provider value={value}>
            {children}
        </ChannelProviderContext.Provider>
    );
}

export default ChannelProvider;
