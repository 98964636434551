import { rankItem } from '@tanstack/match-sorter-utils';
import { type FilterFn } from '@tanstack/react-table';
import { type TableResultRow } from './type';

export const fuzzyFilter: FilterFn<TableResultRow> = (
    row,
    columnId,
    value,
    addMeta,
) => {
    const itemRank = rankItem(row.getValue(columnId), value);

    addMeta({
        itemRank,
    });

    return itemRank.passed;
};
