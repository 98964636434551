import { subject } from '@casl/ability';
import { useFieldsWithEvents } from '@components/Audience/Filters/FiltersCard/useFieldsWithEvents';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider/FiltersProvider';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import CustomMetricManagerContent from '@components/CustomMetric/CustomMetricManagerContent';
import Page from '@components/Page/Page';
import PageSpinner from '@components/PageSpinner';
import { isProjectSetUpCompleted } from '@components/ProjectConnection/utils';
import SetupPage from '@components/SetupPage';
import UpdateTraitsModal from '@components/UpdateTraits/UpdateTraitsModal';
import { useGetCustomMetrics } from '@hooks/useCustomMetric';
import { useCanCreateCustomMetric } from '@hooks/useGetRelatedTables';
import { useLocale } from '@hooks/useLocale';
import { useUpdateTraits } from '@hooks/useUpdateTrait';
import { Button, Flex, Group, Menu, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    AsteriskSimple,
    Function,
    PlusCircle,
    Textbox,
} from '@phosphor-icons/react';
import useApp from '@providers/App/useApp';
import useProjectContext from '@providers/Project/useProjectContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { ButtonVariant, PageLoaderColor } from '../mantineTheme';

interface CreateCustomMetricMenuProps {
    setOpenBaseTable: (value: boolean) => void;
    openUpdateTraitsModal: () => void;
}

export const CreateCustomMetricMenu: React.FC<CreateCustomMetricMenuProps> = ({
    setOpenBaseTable,
    openUpdateTraitsModal,
}) => {
    const { t } = useLocale();
    const [opened, setOpened] = useState(false);

    return (
        <Menu opened={opened} onChange={setOpened}>
            <Menu.Target>
                <Button
                    className="m-3.5"
                    variant={ButtonVariant.PRIMARY}
                    leftIcon={<PlusCircle color="white" />}
                >
                    <Text className="inline text-sm font-semibold">
                        {t('custom_metric.create_custom_metric')}
                    </Text>
                </Button>
            </Menu.Target>
            <Menu.Dropdown>
                <Group className="flex flex-col">
                    <Button
                        variant={ButtonVariant.DEFAULT}
                        className="min-w-full border-none"
                        leftIcon={<Function size={13} />}
                        styles={{
                            label: {
                                justifyContent: 'left',
                            },
                        }}
                        onClick={() => {
                            setOpenBaseTable(true);
                            setOpened(false);
                        }}
                    >
                        {t('custom_metric.create_new_trait')}
                    </Button>
                    <Button
                        variant={ButtonVariant.DEFAULT}
                        leftIcon={<Textbox weight="duotone" />}
                        className="border-none"
                        onClick={() => {
                            openUpdateTraitsModal();
                            setOpened(false);
                        }}
                    >
                        {t('update_traits.create_trait')}
                    </Button>
                </Group>
            </Menu.Dropdown>
        </Menu>
    );
};

const CustomMetricBuilderContainer: React.FC<{}> = () => {
    const { t } = useLocale();
    const { data: customMetrics, isInitialLoading } = useGetCustomMetrics({});
    const [openBaseTable, setOpenBaseTable] = useState<boolean>(false);
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const ability = useAbilityContext();
    const { projectData: activeProject } = useProjectContext();
    const { user } = useApp();
    const canCreateCustomMetricAbility = ability.can(
        'create',
        subject('CustomMetric', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const { activeRelation } = useRelationContext();
    const [
        openedUpdateTraitsModal,
        { open: openUpdateTraitsModal, close: closeUpdateTraitsModal },
    ] = useDisclosure(false);

    const canCreateCustomMetric = useCanCreateCustomMetric(activeRelation);
    const { data: updateTraits, isLoading: isUpdateTraitsLoading } =
        useUpdateTraits(projectUuid ?? '');

    if (activeProject && !isProjectSetUpCompleted(activeProject)) {
        return (
            <SetupPage
                description={t('set_up_page.description', {
                    type_description: t(
                        'set_up_page.type_description.custom_properties',
                    ),
                })}
            />
        );
    }

    if (isInitialLoading) {
        return (
            <PageSpinner
                isVisible={isInitialLoading}
                withOverlay
                loaderColor={PageLoaderColor.GRAY}
            />
        );
    }
    if (isUpdateTraitsLoading) {
        return <SuboptimalState loading />;
    }
    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={
                <Flex justify="start" className="gap-1.5">
                    <Flex align="center">
                        <AsteriskSimple
                            color={'rgb(var(--color-purple))'}
                            size={20}
                        />
                    </Flex>
                    <Text>{t('custom_metric')}</Text>
                </Flex>
            }
            rightSection={
                canCreateCustomMetricAbility &&
                canCreateCustomMetric &&
                ((!!customMetrics && customMetrics?.length > 0) ||
                    (!!updateTraits &&
                        Object.values(updateTraits?.config.fieldConfig ?? {})
                            .length > 0)) ? (
                    <CreateCustomMetricMenu
                        setOpenBaseTable={setOpenBaseTable}
                        openUpdateTraitsModal={openUpdateTraitsModal}
                    />
                ) : null
            }
        >
            <CustomMetricManagerContent
                openBaseTable={openBaseTable}
                setOpenBaseTable={setOpenBaseTable}
                updateTraits={updateTraits}
                openUpdateTraitsModal={openUpdateTraitsModal}
            />
            <UpdateTraitsModal
                opened={openedUpdateTraitsModal}
                onClose={closeUpdateTraitsModal}
                items={undefined}
                updateTrait={updateTraits}
                isEditMode={true}
                updateTraitFieldConfig={undefined}
            />
        </ManagerBuilderContainer>
    );
};
const CustomMetricManager: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const { projectData } = useProjectContext();

    const { data: eventsData, eventsTableNames } = useFieldsWithEvents({
        relationData: activeRelation,
        activeRelationUuid,
        projectUuid: projectUuid ?? '',
    });
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });

    return (
        <FiltersProvider
            projectUuid={projectUuid}
            fieldsMap={fieldsWithSuggestions}
            startOfWeek={
                projectData?.warehouseConnection?.startOfWeek ?? undefined
            }
            eventsMap={eventsData}
            eventTables={eventsTableNames}
        >
            <Page
                title={t('custom_metric')}
                withFullHeight
                withNavbar
                withFixedContent
                withPaddedContent
            >
                <CustomMetricBuilderContainer />
            </Page>
        </FiltersProvider>
    );
};
export default React.memo(CustomMetricManager);
