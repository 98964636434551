import {
    DimensionType,
    JOURNEY_DYNAMIC_VARIABLE_PREFIX,
    type JourneyDataSchema,
    type ResponseConfig,
} from '@lightdash/common';
import { type HeadersType } from './Headers';
import { type ResponseTableType } from './ResponsePayloadMapper';

// Define all regex patterns as constants
const TIME_REGEX = /^([01]?\d|2[0-3]):([0-5]?\d):?([0-5]?\d)?$/;
const ISO_TIMESTAMP_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
const FIELD_REGEX = /\$\.(\w+?)(?=[&",}])/g;
const HANDLEBARS_PATTERN = /{{([^{}]+)}}/g;
const QUOTED_CONTENT_REGEX = /["'](.*?)["']/g;
const JSON_FIX_REGEX = /:\s*([^",{}\[\]]+|&[^&]+&|\$.+?)(?=[,}])/g;
const TRIM_QUOTES_REGEX = /^["']|["']$/g;
const SPLIT_VARIABLES_REGEX = /(&[^&]+&)/;

// Enum for API methods
export enum CallAPIMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

/**
 * Recursively traverses the JSON object to build a response table.
 * @param {Record<string, any>} json - The JSON object to traverse.
 * @param {ResponseTableType[]} responseTable - The response table to build.
 * @param {number} arrayCount - The count of arrays.
 * @param {string} parentKey - The parent key.
 * @returns {void}
 */
const recursiveTraverse = (
    json: Record<string, any>,
    responseTable: ResponseTableType[],
    arrayCount: { count: number },
    parentKey: string,
) => {
    Object.entries(json).forEach(([key, value]) => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;

        if (Array.isArray(value)) {
            if (
                value[0] &&
                typeof value[0] === 'object' &&
                !Array.isArray(value[0])
            ) {
                recursiveTraverse(value[0], responseTable, arrayCount, newKey);
            } else {
                arrayCount.count++;
            }
            return;
        }

        if (typeof value === 'object') {
            recursiveTraverse(value, responseTable, arrayCount, newKey);
            return;
        }

        if (typeof value === 'string') {
            responseTable.push({
                key: newKey,
                type:
                    TIME_REGEX.test(value) || ISO_TIMESTAMP_REGEX.test(value)
                        ? DimensionType.TIMESTAMP
                        : typeof value,
            });
            return;
        }

        responseTable.push({ key: newKey, type: typeof value });
    });
};

/**
 * Converts the JSON string into a response table with key-value types and counts arrays.
 * @param {string} jsonString - The JSON string input.
 * @returns {{responseTable: ResponseTableType[], arrayCount: number}} The response table and the count of arrays other than arrays of objects.
 */
export const getResponseTable = (jsonString: string) => {
    const responseTable: ResponseTableType[] = [];
    const arrayCount = { count: 0 };
    const json = JSON.parse(jsonString);
    recursiveTraverse(json, responseTable, arrayCount, '');
    return { responseTable, arrayCount: arrayCount.count };
};

/**
 * Filters out empty headers and reduces the headers to a key-value object.
 * @param {HeadersType[]} headers - The headers to filter and reduce.
 * @returns {Record<string, string>} The filtered and reduced headers.
 */
export const filterAndReduceHeaders = (
    headers: HeadersType[],
): Record<string, string> => {
    return headers
        .filter((header) => header.key && header.value)
        .reduce((acc: Record<string, string>, header) => {
            acc[header.key] = header.value;
            return acc;
        }, {});
};

/**
 * Converts the headers object to an array of headers.
 * @param {Record<string, string>} headers - The headers object to convert.
 * @returns {HeadersType[]} The array of headers.
 */
export const convertHeadersToObject = (
    headers: Record<string, string>,
): HeadersType[] => {
    return Object.entries(headers).map(([key, value]) => ({ key, value }));
};

/**
 * Converts the response config to a response table.
 * @param {Record<string, any>} responseConfig - The response config to convert.
 * @returns {ResponseTableType[]} The response table.
 */
export const convertResponseConfigToResponseTable = (
    responseConfig: ResponseConfig,
): ResponseTableType[] => {
    if (!responseConfig.responseMapper) return [];
    return Object.entries(responseConfig.responseMapper).map(([key, type]) => ({
        key: key.replaceAll(TRIM_QUOTES_REGEX, '').slice(2),
        type: type as string,
    }));
};

/**
 * Converts the response table to a response config.
 * @param {ResponseTableType[]} responseTable - The response table to convert.
 * @returns {Record<string, any>} The response config.
 */
export const convertResponseTableToResponseConfig = (
    responseTable: ResponseTableType[],
): Record<string, any> => {
    const responseMapper = responseTable
        .filter((item) => item.key && item.type)
        .reduce((acc: Record<string, any>, item) => {
            acc[`${JOURNEY_DYNAMIC_VARIABLE_PREFIX}${item.key}`] = item.type;
            return acc;
        }, {});
    return { type: 'sync', responseMapper };
};

/**
 * Converts the input object to a string with variables.
 * @param {object} input - The object to convert.
 * @returns {string} The string with variables.
 */
export const stringifyObjectWithVariables = (input: object): string => {
    // Convert the object to a JSON string
    const stringifiedObject = JSON.stringify(input);

    // Regex to match the entire content within quotes
    return stringifiedObject.replace(QUOTED_CONTENT_REGEX, (match, content) => {
        let hasHandlebars = false;

        // Replace Handlebars expressions with &$.{expression}&
        const transformedContent = content.replace(
            HANDLEBARS_PATTERN,
            (m: string, expr: string) => {
                hasHandlebars = true;
                return `&${JOURNEY_DYNAMIC_VARIABLE_PREFIX}${expr}&`;
            },
        );

        // If there was at least one Handlebars expression, wrap strings in ''
        if (hasHandlebars) {
            // Split the transformed content by the & delimiter to handle text and Handlebars separately
            let result = transformedContent
                .split(SPLIT_VARIABLES_REGEX)
                .map((part: string) => {
                    if (part.startsWith('&') && part.endsWith('&')) {
                        // It's a Handlebars expression, return as is
                        return part;
                    } else if (part.trim()) {
                        // It's a regular string, wrap in single quotes
                        return `'${part}'`;
                    }
                    return part;
                })
                .filter(Boolean) // Remove any empty strings
                .join('');

            if (result[0] === '&') {
                result = result.slice(1);
            }
            if (result[result.length - 1] === '&') {
                result = result.slice(0, -1);
            }
            return result;
        }

        // Return the original match if no Handlebars were found
        return match;
    });
};

/**
 * Reverts a string with variables back into an object with handlebars-style variables.
 * Converts text wrapped in single quotes and handlebars expressions wrapped in &...& back to their original form.
 * Ensures all values are properly quoted in JSON format.
 * @param {string} input - The string with variables.
 * @returns {object} The original object with handlebars variables.
 */
export const parseStringWithVariablesToObject = (input: string) => {
    // Handle special cases with Handlebars and single quotes, and ensure all values are properly quoted
    let fixedInput = input.replace(JSON_FIX_REGEX, ': "$1"');

    // Attempt to parse the fixed JSON to check if it's valid
    try {
        const parsedInput = JSON.parse(fixedInput);
        return Object.entries(parsedInput).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
                if (
                    typeof value === 'string' &&
                    value.includes(JOURNEY_DYNAMIC_VARIABLE_PREFIX)
                ) {
                    acc[key] = value
                        .split('&')
                        .map((eachChunk) => {
                            if (
                                eachChunk.includes(
                                    JOURNEY_DYNAMIC_VARIABLE_PREFIX,
                                )
                            ) {
                                return `{{${eachChunk.replace(
                                    JOURNEY_DYNAMIC_VARIABLE_PREFIX,
                                    '',
                                )}}}`;
                            }
                            return eachChunk.slice(1, -1);
                        })
                        .join('');
                } else {
                    acc[key] = value as string; // Type assertion to specify 'value' as a string
                }
                return acc;
            },
            {} as Record<string, string>,
        );
    } catch (e) {
        return {}; // Return an empty object in case of error
    }
};
/**
 * remove trailling '' and & from the string
 */
export const removeTrailingLeadingQuotesAndAnd = (input: string) => {
    /*
        remove leading/trailing &
        remove leading/trailing ''
        remove leading/trailing &
        /^&|&$/g - remove leading/trailing &
        /^''|''$/g - remove leading/trailing ''
    */
    return input
        .replace(/^&|&$/g, '') // Remove leading/trailing &
        .replace(/^''|''$/g, '')
        .replace(/^&|&$/g, '');
};
/**
 * Converts a string with variables in handlebars to a formatted string.
 * @param {string} input - The string with variables in the format `Hello {{name}}!`
 * @returns {string} The formatted string.
 */
export const stringifyStringWithVariables = (input: string): string => {
    // Replace handlebars variables with &variable&
    const replacedVariables = input.replaceAll(
        HANDLEBARS_PATTERN,
        `&${JOURNEY_DYNAMIC_VARIABLE_PREFIX}$1&`,
    );

    // Wrap the entire string in single quotes if it's not a variable
    let result = replacedVariables
        .split(SPLIT_VARIABLES_REGEX) // Split by variables to isolate literals
        .map((part) => (part.startsWith('&') ? part : `'${part}'`)) // Wrap non-variable parts in single quotes
        .join('');

    // Clean up the string by removing leading/trailing & and empty quotes
    result = removeTrailingLeadingQuotesAndAnd(result);

    return result;
};

/**
 * Reverts a formatted string back to the original handlebars format.
 * @param {string} input - The formatted string with variables in the format `&variable&` and literals wrapped in single quotes.
 * @returns {string} The reverted string with handlebars variables.
 */
export const revertStringifyStringWithVariables = (input: string): string => {
    const result = input.split('&').map((eachChunk) => {
        if (eachChunk.includes(JOURNEY_DYNAMIC_VARIABLE_PREFIX)) {
            return `{{${eachChunk.replace(
                JOURNEY_DYNAMIC_VARIABLE_PREFIX,
                '',
            )}}}`;
        }
        return eachChunk.slice(1, -1);
    });
    return result.join('');
};

/**
 * Extracts field IDs from a given string using a regex pattern.
 * @param {string} input - The input string to extract field IDs from.
 * @returns {string[]} The extracted field IDs.
 */
const extractFieldIds = (input: string): string[] => {
    return input.match(FIELD_REGEX)?.map((field) => field.slice(2)) || [];
};

/**
 * Extracts journey field IDs from the journey data schema.
 * @param {JourneyDataSchema | undefined} journeyDataSchema - The journey data schema.
 * @returns {string[]} The journey field IDs.
 */
export const extractJourneyFieldIds = (
    journeyDataSchema: JourneyDataSchema | undefined,
): string[] => {
    if (!journeyDataSchema) return [];
    return Object.entries(journeyDataSchema.tables).flatMap(
        ([tableName, table]) =>
            Object.values(table.dimensions).map(
                (dimension) => `${tableName}_${dimension.name}`,
            ),
    );
};

/**
 * Updates the warehouse fields based on the input string.
 * @param {UpdateWarehouseFieldsParams} params - The parameters including url, headers, payloadMapper, and journeyDataSchema.
 * @returns {string[]} The updated warehouse fields.
 */
type UpdateWarehouseFieldsParams = {
    url: string;
    headers: string;
    payloadMapper: string;
    journeyDataSchema: JourneyDataSchema | undefined;
};

export const updateWarehouseFields = ({
    url,
    headers,
    payloadMapper,
    journeyDataSchema,
}: UpdateWarehouseFieldsParams): string[] => {
    const fieldIdsInUrl = extractFieldIds(url);
    const fieldIdsInHeaders = extractFieldIds(headers);
    const fieldIdsInPayloadMapper = extractFieldIds(payloadMapper);

    const newFieldIds = new Set([
        ...fieldIdsInUrl,
        ...fieldIdsInHeaders,
        ...fieldIdsInPayloadMapper,
    ]);

    const journeyFieldIds = extractJourneyFieldIds(journeyDataSchema);

    return Array.from(newFieldIds).filter(
        (fieldId) => !journeyFieldIds.includes(fieldId),
    );
};

/**
 * this object returns the default journeydataschema
 */
export const defaultJourneyDataSchema: JourneyDataSchema = {
    tables: {},
    baseTable: '',
    journeyId: '',
    userId: '',
    contextId: '',
    relationTables: {},
};

export const addQuotesToJsonStingValues = (input: string) => {
    /*
       Breakdown of the regex:
    :           - Matches a colon
    \s*         - Matches zero or more whitespace characters
    (?!")       - Negative lookahead: ensures what follows isn't already starting with a quote
    (           - Start capturing group
        (?:     - Start non-capturing group
            [^,}]     - Match any character that's not a comma or closing brace
            |         - OR
            "[^"]*"   - Match a quoted string: quote + any non-quote chars + quote
        )+      - End non-capturing group, repeat one or more times
    )           - End capturing group
    (?=[,}])    - Positive lookahead: must be followed by comma or closing brace
    /g          - Global flag: match all occurrences

    Examples of what it matches:
    {key: value}              → {key:"value"}
    {key: "value"}            → {key:"value"} (unchanged)
    {key: some value}         → {key:"some value"}
    {key1: val1, key2: val2}  → {key1:"val1", key2:"val2"}
    */
    return input.replace(/:\s*(?!")((?:[^,}]|"[^"]*")+)(?=[,}])/g, ':"$1"');
};
